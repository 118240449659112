import React from 'react';
import axios from 'axios';
import { Card,Avatar} from 'antd';
import { Form, Input, Button,Select,Spin } from 'antd';
import {
    Col,
    Row,
  } from 'reactstrap';
import { UserOutlined,LoadingOutlined } from '@ant-design/icons';
import * as serverconfig from '../serverconn'
const FormItem=Form.Item;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''

class AccountTypeDetail extends React.Component{

    state={
        accountype: {},
        account_type_name:'',
        minimum_account_balance:0,
        deposit_charges:0,
        minimum_account_deposit:0,
        withdraw_charges:0,
        withdrawals_per_month:0,
        account_fine_fee:0,
        account_transfer_charge:0,
        account_monthly_charge:0,
        account_monthly_interest_rate:0,
        account_statement_fee:0,
        cheque_deposit_fee:0,

        account_reactivation_charge_afterdormancy:0,
        days_intodormantstate:0,
        days_todeactivate_dormantaccounts:0,
        datarequested:true,
        minimum_accnt_bal_forintrest:0,
        withholdingtax_percentage_oninterest:0,
        minimum_shares_foraccountactibation:0,
        monthlymandatorysaving:0,
        monthlymandatorysaving_fine:0,
        nooftimes_finedbfdormancy:0,

        setmonthsharestarget:0,
        maximumshares_bysetmonthsharestarget:0,
        maximumshare_percentage:0,
        agelimit_months_cap:0,
        nonrefund_membership:0


    }
    


    componentDidMount(){
        if(localStorage.getItem("token")){
          token= localStorage.getItem("token")
        }else{
          token= ''
        }

        axios.defaults.headers={
          "Content-Type":"application/json",
          Authorization:`Token ${token}`
        }

        const accountTypeID=this.props.match.params.accountTypeID;
        axios.get(`${serverconfig.backendserverurl}/api/accounttypes/${accountTypeID}`)
        .then(res => {  
            this.setState({accountype: res.data})

            this.setState({account_type_name: res.data.account_type_name})
            this.setState({minimum_account_balance: res.data.minimum_account_balance})
            this.setState({minimum_account_deposit: res.data.minimum_account_deposit})
            this.setState({withdraw_charges: res.data.withdraw_charges})
            this.setState({account_monthly_interest_rate: res.data.account_monthly_interest_rate})
            this.setState({account_reactivation_charge_afterdormancy: res.data.account_reactivation_charge_afterdormancy})

            this.setState({minimum_accnt_bal_forintrest: res.data.minimum_accnt_bal_forintrest})
            this.setState({withholdingtax_percentage_oninterest: res.data.withholdingtax_percentage_oninterest})
            this.setState({minimum_shares_foraccountactibation: res.data.minimum_shares_foraccountactibation})
            this.setState({monthlymandatorysaving: res.data.monthlymandatorysaving})
            this.setState({monthlymandatorysaving_fine: res.data.monthlymandatorysaving_fine})
            this.setState({nooftimes_finedbfdormancy: res.data.nooftimes_finedbfdormancy})

            this.setState({setmonthsharestarget: res.data.setmonthsharestarget})
            this.setState({maximumshares_bysetmonthsharestarget: res.data.maximumshares_bysetmonthsharestarget})
            this.setState({maximumshare_percentage: res.data.maximumshare_percentage})
            this.setState({agelimit_months_cap: res.data.agelimit_months_cap})
            this.setState({nonrefund_membership: res.data.nonrefund_membership})

            this.setState({datarequested:false})

        })
    }

    //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})

    const accountTypeID=this.props.match.params.accountTypeID;
    let form_data = new FormData();

    form_data.append('account_type_name', this.state.account_type_name);
    form_data.append('minimum_account_balance', this.state.minimum_account_balance);
    form_data.append('deposit_charges', this.state.deposit_charges);
    form_data.append('minimum_account_deposit', this.state.minimum_account_deposit);
    form_data.append('withdraw_charges', this.state.withdraw_charges);
    form_data.append('withdrawals_per_month', this.state.withdrawals_per_month);
    form_data.append('account_fine_fee', this.state.account_fine_fee);
    form_data.append('account_transfer_charge', this.state.account_transfer_charge);
    form_data.append('account_monthly_charge', this.state.account_monthly_charge);
    form_data.append('account_monthly_interest_rate', this.state.account_monthly_interest_rate);
    form_data.append('account_statement_fee', this.state.account_statement_fee);
    form_data.append('cheque_deposit_fee', this.state.cheque_deposit_fee);
    form_data.append('account_reactivation_charge_afterdormancy', this.state.account_reactivation_charge_afterdormancy);
    form_data.append('days_todeactivate_dormantaccounts', this.state.days_todeactivate_dormantaccounts);
    form_data.append('days_intodormantstate', this.state.days_intodormantstate);
    form_data.append('minimum_accnt_bal_forintrest', this.state.minimum_accnt_bal_forintrest);
    form_data.append('withholdingtax_percentage_oninterest', this.state.withholdingtax_percentage_oninterest);
    form_data.append('minimum_shares_foraccountactibation', this.state.minimum_shares_foraccountactibation);
    form_data.append('monthlymandatorysaving', this.state.monthlymandatorysaving);
    form_data.append('monthlymandatorysaving_fine', this.state.monthlymandatorysaving_fine);
    form_data.append('nooftimes_finedbfdormancy', this.state.nooftimes_finedbfdormancy);

    form_data.append('setmonthsharestarget', this.state.setmonthsharestarget);
    form_data.append('maximumshares_bysetmonthsharestarget', this.state.maximumshares_bysetmonthsharestarget);
    form_data.append('maximumshare_percentage', this.state.maximumshare_percentage);
    form_data.append('agelimit_months_cap', this.state.agelimit_months_cap);
    form_data.append('nonrefund_membership', this.state.nonrefund_membership);

    axios.put(serverconfig.backendserverurl+`/api/accounttypes/${accountTypeID}/`, form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  .then(res =>this.setState({datarequested:false}),window.location.reload(false)

  )
  .catch(error => console.log(error))

  }



  handleaccount_type_nameChange= (e) => this.setState({ account_type_name: e.target.value});
  handleminimum_account_balanceChange= (e) => this.setState({ minimum_account_balance: e.target.value});
  handledeposit_chargesChange= (e) => this.setState({ deposit_charges: e.target.value});
  handleminimum_account_depositChange= (e) => this.setState({ minimum_account_deposit: e.target.value});
  handlewithdraw_chargesChange= (e) => this.setState({ withdraw_charges: e.target.value});
  handlewithdrawals_per_monthChange= (e) => this.setState({ withdrawals_per_month: e.target.value});
  handleaccount_fine_feeChange= (e) => this.setState({ account_fine_fee: e.target.value});
  handleaccount_transfer_chargeChange= (e) => this.setState({ account_transfer_charge: e.target.value});
  handleaccount_monthly_chargeChange= (e) => this.setState({ account_monthly_charge: e.target.value});
  handleaccount_monthly_interest_rateChange= (e) => this.setState({ account_monthly_interest_rate: e.target.value});
  handleaccount_statement_feeChange= (e) => this.setState({ account_statement_fee: e.target.value});
  handlecheque_deposit_feeChange= (e) => this.setState({ cheque_deposit_fee: e.target.value});


    render(){

        if(this.state.datarequested===true){
            return(
              <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin indicator={antIcon} />
              </div>
            )
      
          }else{

            return(
                <div>
        
        <Row>
        <Col xs="12" sm="6" lg="6">

        <Card 
          style={{padding:10}}
          hoverable>
            <h4>Account Type Details / Update</h4>
            <Form
                onFinish={(event) => this.handleFormSubmit(event)} 
                >
              <FormItem label="Account Type Name">
              <Input name="account_type_name"  placeholder="Put a name here." value={this.state.account_type_name} onChange={this.handleaccount_type_nameChange} />
            </FormItem>

            <FormItem label="Minimum Account Balance">
              <Input name="minimum_account_balance"  placeholder="0.00" value={this.state.minimum_account_balance} onChange={this.handleminimum_account_balanceChange} />
            </FormItem>
  
            <FormItem label="Minimum Account Deposit">
              <Input name="minimum_account_deposit"  placeholder="0.00" value={this.state.minimum_account_deposit} onChange={this.handleminimum_account_depositChange} />
            </FormItem>


            <FormItem label="Withdraw Charges">
              <Input name="withdraw_charges"  placeholder="0.00" value={this.state.withdraw_charges} onChange={this.handlewithdraw_chargesChange} />
            </FormItem>


            <FormItem label="Account Monthly Interest Rate"
            >
              <Input name="account_monthly_interest_rate"  placeholder="0.00" value={this.state.account_monthly_interest_rate} onChange={this.handleaccount_monthly_interest_rateChange} />
            </FormItem>

            <FormItem label="Withholding tax percentage"         
            >
              <Input name="withholdingtax_percentage_oninterest"  placeholder="0.00" value={this.state.withholdingtax_percentage_oninterest} onChange={(val)=>{this.setState({withholdingtax_percentage_oninterest:val.target.value})}} />
            </FormItem>

            <FormItem label="Minimum Account Balance for Savings Interest"
            >
              <Input name="minimum_accnt_bal_forintrest"  placeholder="0.00" value={this.state.minimum_accnt_bal_forintrest} onChange={(val)=>{this.setState({minimum_accnt_bal_forintrest:val.target.value})}} />
            </FormItem>

            <FormItem label="Minimum Shares for Account Activation"          
            >
              <Input name="minimum_shares_foraccountactibation"  placeholder="0.00" value={this.state.minimum_shares_foraccountactibation} onChange={(val)=>{this.setState({minimum_shares_foraccountactibation:val.target.value})}} />
            </FormItem>

            <FormItem label="Monthly Mandatory Saving"        
            >
              <Input name="monthlymandatorysaving"  placeholder="0.00" value={this.state.monthlymandatorysaving} onChange={(val)=>{this.setState({monthlymandatorysaving:val.target.value})}} />
            </FormItem>

            <FormItem label="Monthly Mandatory Saving Fine"        
            >
              <Input name="monthlymandatorysaving_fine"  placeholder="0.00" value={this.state.monthlymandatorysaving_fine} onChange={(val)=>{this.setState({monthlymandatorysaving_fine:val.target.value})}} />
            </FormItem>

            <FormItem label="No of times account fined before dormancy"        
            >
              <Input name="nooftimes_finedbfdormancy"  placeholder="0.00" value={this.state.nooftimes_finedbfdormancy} onChange={(val)=>{this.setState({nooftimes_finedbfdormancy:val.target.value})}} />
            </FormItem>

            <FormItem label="Account Reactivation Charge after dormancy"     
            >
              <Input name="account_reactivation_charge_afterdormancy"  placeholder="0.00" value={this.state.account_reactivation_charge_afterdormancy} onChange={(val)=>{this.setState({account_reactivation_charge_afterdormancy:val.target.value})}} />
            </FormItem>


            <FormItem label="Number of days for shares target"
     
            >
              <Input name="setmonthsharestarget"  placeholder="0" value={this.state.setmonthsharestarget} onChange={(val)=>{this.setState({setmonthsharestarget:val.target.value})}} />
            </FormItem>


            <FormItem label="No of shares by target days."
        
            >
              <Input name="maximumshares_bysetmonthsharestarget"  placeholder="0" value={this.state.maximumshares_bysetmonthsharestarget} onChange={(val)=>{this.setState({maximumshares_bysetmonthsharestarget:val.target.value})}} />
            </FormItem>


            <FormItem label="Account maximum share percentage"
      
            >
              <Input name="maximumshare_percentage"  placeholder="0" value={this.state.maximumshare_percentage} onChange={(val)=>{this.setState({maximumshare_percentage:val.target.value})}} />
            </FormItem>

            <FormItem label="Age limit (days)"
            >
              <Input name="agelimit_months_cap"  placeholder="number of days" value={this.state.agelimit_months_cap} onChange={(val)=>{this.setState({agelimit_months_cap:val.target.value})}} />
            </FormItem>

            <FormItem label="Non Refund Membership"        
            >
              <Input name="nonrefund_membership"  placeholder="0.00" value={this.state.nonrefund_membership} onChange={(val)=>{this.setState({nonrefund_membership:val.target.value})}} />
            </FormItem>

            <FormItem>
                <Button  type="primary" htmlType="submit">Update</Button>
            </FormItem>
            </Form>
              
            </Card>
          
        </Col>
      
        </Row>
         
        </div>
          )

        }

    }
}

export default AccountTypeDetail; 