import React from 'react';
import { Form, Input, Button,Select,Spin,Switch,Tooltip,Tabs,Card,Image, message,Result,Modal,Popconfirm,Popover,DatePicker, Divider } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined,DownloadOutlined, MailOutlined,UploadOutlined,CloseCircleFilled } from '@ant-design/icons';
import moment from 'moment';
import {
  Col,
  Row,
} from 'reactstrap';
import * as reactstrp from 'reactstrap';
import uuid from 'react-uuid'
import CurrencyFormat from 'react-currency-format';
import LoanDetailsTab from './LoanDetailsTab';


var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const dateFormat = 'DD/MM/YYYY';
const { TextArea } = Input;
const { TabPane } = Tabs;

var sacco= ''
var username=''
var token= ''

class LoanApplication_ConclusionForm extends React.Component {

  state = {
    loanapplications:[],
    date:moment().format(dateFormat).toString(),
    applicant_location:false,
    own_premises:false,
    legal_status_of_business:false,
    duration_in_business:false,
    monthly_net_profit:false,
    guarantors:false,
    consents:false,
    report_details:'',
    loan_checklist:null,
    collateral_one:'',
    collateral_one_value:0,
    collateral_one_image:null,
    datarequested:true,
    loanapplicationobject:{},
    datasubmittedsuccessfully:false,
    collateralformvisible:false,
    collaterallist:[],

    companyprofile:{},

  }

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
   
    }else{
       sacco= ''
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }


    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
      this.setState({
        companyprofile: res.data
      })

    const loanapplicationID=this.props.loanapplicationID;
    axios.get(`${serverconfig.backendserverurl}/api/loanapplications/${loanapplicationID}`)
    .then(res => {  
        this.setState({loanapplicationobject: res.data})
        this.setState({datarequested:false})
    })


  })
 
  }



  //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})

    const loanapplicationID=this.props.loanapplicationID;

    let form_data = new FormData();
    form_data.append('date', this.state.date);
    form_data.append('loan_id', loanapplicationID);
    form_data.append('report_details', this.state.report_details);
    form_data.append('user', username);
    form_data.append('sacco', sacco);
    
    axios.post(serverconfig.backendserverurl+'/customqueries/send_loanforverification', form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  .then(res =>  {
    message.info(res.data.message)
    this.setState({datarequested:false})
    this.setState({datasubmittedsuccessfully:true})
  }
  )
  .catch(error => console.log(error))

  }


  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{


      if (this.state.datasubmittedsuccessfully===true){
        return(
          <Row >
            <Col xs="12" sm="6" lg="6">
            <Card>
            <Result
            status="success"
            title="Successfully Loan Application Conclusion"
            extra={[
              <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
            ]}
            />
          </Card>
          </Col>
          </Row>
        );

      }else{

        return (
          <div style={{overflowX:'auto'}}>
             <Tabs defaultActiveKey="1" onChange={()=>{}}>

             <TabPane tab="Send for Verification Form" key="4">
            
             <Card  style={{padding:10}} hoverable>
  
                  {
                    this.state.loanapplicationobject.loan_status!="application"?
                    null
                    :
                    <Form
                      layout="inline"
                      onFinish={(event) => this.handleFormSubmit(event)} >
                  
                    <FormItem label="Remarks"
                      name="report_details"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter details',
                        },
                      ]}            
                    >
                      <TextArea
                        value={this.state.report_details}
                        onChange={(e) => this.setState({ report_details: e.target.value})}
                        placeholder="Remarks"
                        autoSize={{ minRows: 2, maxRows: 6 }}
                      />
                  
                    </FormItem>
                
                
                  <Divider></Divider>
                  <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                
                  <FormItem>
                  
                  <Tooltip title="Click here to send for verification" placement="top">
                    <Button  type="primary" htmlType="submit"> Send for verification </Button>
                  </Tooltip>

                  </FormItem>
                

                  </div>

                   </Form>
                  }

            </Card>

            </TabPane>
            </Tabs>
          </div>
        );

      }

    }

  }

}


export default LoanApplication_ConclusionForm;



