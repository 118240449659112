import React from 'react';
import axios from 'axios'
import { Table, Input,Form, Button,Select,DatePicker,Collapse,Popover,Popconfirm,message,Descriptions,Modal,Spin,Tabs, Card,Divider } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,PlusOutlined,DeleteOutlined,EditOutlined,PrinterOutlined,FundViewOutlined,WalletFilled,LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import moment from 'moment';
import * as reactstrp from 'reactstrap';
import ReactToPrint from 'react-to-print';
import {
  Col,
  Row,
} from 'reactstrap';

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { RangePicker } = DatePicker;
const FormItem=Form.Item;
const dateFormat = 'DD/MM/YYYY';

function callback(key) {
  console.log(key);
}

var token= ''
var sacco= ''
var bizuserid= ''
const { TabPane } = Tabs;
const { Option } = Select;


class QuickTransact extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    viewpaymentmodal:false,
    incomedetails:{},
    userprofile:{},
    datarequested:true,
    userrights:{},
    datasearch:false,
    dateone:'',
    datetwo:'',
    totaltobepaid:0,
    totalpaid:0,
    totalbalance:0,
    transactions:[],
    till:[],
    incomes:[],
    loanapplications:[],
    loanrepayments:[],
    members:[],
    selectedaccountobject:{},
    accountbalance:0,
    deletelist:[],
    deletemode:'',

    staffs: [],
    creditofficer:'',
    selected_loan:'',
    loanissues: [],

    transaction_amount:0,
    transaction_details:'',
    transaction_account:'',
    transaction_account_obj:{},
    transaction_type:'',
    transaction_subaccount:'',
    transaction_date:moment().format(dateFormat).toString(),

    debitaccount:'',
    creditaccount:'',
    companyaccounts: [],

    memberperiodic_balanceobj:{}


  };

  componentDidMount(){
    if(localStorage.getItem("sacco")){
      sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
   
   }else{
      sacco= ''
      bizuserid= ''
   }
   
   if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }


    axios.get(serverconfig.backendserverurl+`/api/members/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          members:res.data
        })
        //this.setState({datarequested:false})
        //this.setState({datarequested:false})
    })


    axios.get(serverconfig.backendserverurl+`/api/companyaccounts/?sacco=${sacco}`)
    .then(res => {
        this.setState({
            companyaccounts:res.data
        })
        this.setState({datarequested:false})
    })

}


closepaymentmodal = () => {
this.setState({ viewpaymentmodal: false });
}

  

getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};


  render() {

     


    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
          <Spin size="large">
            <div className="content" />
          </Spin>       
          </div>
      )

    }else{
      return(
        <div>

      <Row >

      <Col xs="12" sm="6" lg="6">
         <Card>
         <h4>Quick Transaction form</h4>

        <Form
            onFinish={(event) => {

              if (this.state.transaction_amount<=0){
                message.error("Amount cant be zero")

              }else{


                this.setState({datarequested:true})
                let form_data = new FormData();
                form_data.append('transaction_amount', this.state.transaction_amount);
                form_data.append('transaction_details',this.state.transaction_details);

                form_data.append('transaction_account', this.state.transaction_account);
                form_data.append('transaction_type', this.state.transaction_type);
                form_data.append('transaction_subaccount', this.state.transaction_subaccount);
                form_data.append('transaction_date', this.state.transaction_date);
                form_data.append('bizuserid', bizuserid);

                form_data.append('creditaccount', this.state.creditaccount);
                form_data.append('debitaccount', this.state.debitaccount);

                axios.post(serverconfig.backendserverurl+'/customqueries/quick_transact', form_data, {
                  headers: {
                    'content-type': 'multipart/form-data'
                  }
                })
              .then(res =>  {
                if(res.data.message=="success"){
                  message.info(res.data.message)
                  this.setState({datarequested:false})
            
                }else{
                  message.info("Some thing went wrong")
                }
              }       
            
              )
              .catch(error => console.log(error))

              }
            
              
            }} 
          >

            <FormItem label="transaction_date">
                <DatePicker 
                onChange={(date, dateString) => this.setState({ transaction_date: dateString})} format={dateFormat} />
            </FormItem>


            <FormItem label="Member account"
              name="transaction_account"
              rules={[
                {
                  required: true,
                  message: 'Please Select transaction_account',
                },
              ]}
            >
                <Select placeholder="transaction_account" 
                 value={this.state.transaction_account} 
                  onChange={(val)=>{

                    let form_data1 = new FormData();
                    form_data1.append('date',this.state.transaction_date); 
                    form_data1.append('member_id', val);
                    axios.post(serverconfig.backendserverurl+`/customqueries/get_runningbalancesbydate`, form_data1, {
                    headers: {
                      'content-type': 'multipart/form-data'
                    }
                    })
                    .then(res => {
                        this.setState({
                          memberperiodic_balanceobj:res.data.my_balance
                        })
                    })



                    this.setState({transaction_account:val})

                    axios.get(`${serverconfig.backendserverurl}/api/members/${val}`)
                    .then(res => {  
                        this.setState({selectedaccountobject: res.data})
                    })
                  }} 
                  showSearch
                  optionFilterProp="children"
                  >
                  {this.state.members.map(
                    (mbr)=>(
                      <Option value={mbr.id}>{mbr.FullName} | {mbr.AccountNumber}</Option>
                    ))}
                </Select>
            </FormItem>

            <FormItem label='transaction_amount'
          >
            <Input name="transaction_amount" type='number'  placeholder="transaction_amount" value={this.state.transaction_amount} onChange={(val)=>{this.setState({transaction_amount:val.target.value})}}/>
          </FormItem>

            <FormItem label='transaction_details'
            >
              <Input name="transaction_details"  placeholder="transaction_details" value={this.state.transaction_details} onChange={(val)=>{this.setState({transaction_details:val.target.value})}}/>
            </FormItem>



            <FormItem label="Transaction type"
              name="transaction_type"
              rules={[
                {
                  required: true,
                  message: 'Please Select transaction_type',
                },
              ]}
            >
                <Select placeholder="transaction_type"  value={this.state.transaction_type} onChange={(val)=>{this.setState({transaction_type:val})}} >
                  <Option value={'debit'}>Debit</Option>
                  <Option value={'credit'}>Credit</Option>
                </Select>
            </FormItem>

            <FormItem label="Select subaccount"
              name="transaction_subaccount"
              rules={[
                {
                  required: true,
                  message: 'Please Select transaction_subaccount',
                },
              ]}
            >
                <Select placeholder="transaction_subaccount" 
                value={this.state.transaction_subaccount} 
                onChange={(val)=>{this.setState({transaction_subaccount:val})}} 
                showSearch
                >
                  <Option value={'Savings'}>Savings</Option>
                  <Option value={'Shares'}>Shares</Option>
                  <Option value={'Supersave'}>Supersave</Option>
                  <Option value={'Teebagoolo'}>Teebagoolo</Option>
                  <Option value={'Deffered'}>Deffered</Option>
                  <Option value={'Loan'}>Loan</Option>
                  <Option value={'SavingsLoanSecurity'}>Saving loan security</Option>
                  <Option value={'SharesLoanSecurity'}>Shares loan security</Option>
                  <Option value={'SupersaveLoanSecurity'}>Supersave loan Security</Option>
                  <Option value={'TeebagooloLoanSecurity'}>Teebagoolo loan Security</Option>

                </Select>
            </FormItem>


            <FormItem label={'Debited account'}
                name="debitaccount"
                rules={[
                  {
                    required: true,
                    message: 'Please Select debitaccount',
                  },
                ]}
            
            >
                <Select 
                placeholder="Account" 
                style={{  }} 
                value={this.state.debitaccount}
                onChange={(val)=>{this.setState({debitaccount:val})}} 
                showSearch
                optionFilterProp="children"
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onSearch={this.onSearch}                    
                >
                  {this.state.companyaccounts.map(
                    (accnt)=>(
                      <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
                    ))}
                </Select>
            </FormItem>

              <FormItem label={'Credited account'}
                name="creditaccount"
                rules={[
                  {
                    required: true,
                    message: 'Please Select creditaccount',
                  },
                ]}
              
              >
                <Select 
                placeholder="Account" 
                style={{  }} 
                value={this.state.creditaccount}
                onChange={(val)=>{this.setState({creditaccount:val})}} 
                showSearch
                optionFilterProp="children"
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onSearch={this.onSearch}                    
                >
                  {this.state.companyaccounts.map(
                    (accnt)=>(
                      <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
                    ))}
                </Select>
            </FormItem>


        <FormItem>
          <Button  type="primary" htmlType="submit">Create transaction</Button>
        </FormItem>
      </Form>

      </Card>

      </Col>

      <Col xs="12" sm="6" lg="6">
                   <Card>
                   <h3 style={{fontWeight:'bold',color:'#154360'}}>Account Details</h3>
                    <h4>Account Number:{this.state.selectedaccountobject.AccountNumber}</h4>
                    <h4>Account Contact:{this.state.selectedaccountobject.RegisteredPhoneNumber1}</h4>
                    <h4>Account Name:{this.state.selectedaccountobject.FullName}</h4>

                    <p style={{fontWeight:'bolder',fontSize:15}}>Saving Balance:<CurrencyFormat  value={this.state.memberperiodic_balanceobj.savings_balance} displayType={'text'} thousandSeparator={true} />  </p>
                    <Divider  />
                    <p style={{fontWeight:'bolder',fontSize:15}}>Super Save Balance:<CurrencyFormat  value={this.state.memberperiodic_balanceobj.supersaver_balance} displayType={'text'} thousandSeparator={true} />  </p>
                    <Divider />

                    <p style={{fontWeight:'bolder',fontSize:15}}>Shares Balance:<CurrencyFormat  value={this.state.memberperiodic_balanceobj.shareamount_balance} displayType={'text'} thousandSeparator={true} />  </p>
                    <Divider />

                    <p style={{fontWeight:'bolder',fontSize:15}}>Shares No:{this.state.memberperiodic_balanceobj.share_numberbalance}  </p>
                    <Divider />

                    <p style={{fontWeight:'bolder',fontSize:15}}>Teeba Goolo:<CurrencyFormat  value={this.state.memberperiodic_balanceobj.teebagoolo_balance} displayType={'text'} thousandSeparator={true} />  </p>
                    <Divider />

                    <p style={{fontWeight:'bolder',fontSize:15}}>Loan:<CurrencyFormat  value={this.state.memberperiodic_balanceobj.loan_balance} displayType={'text'} thousandSeparator={true} />  </p>
                    <Divider />

                    <p style={{fontWeight:'bolder',fontSize:15}}>Savings loan security:<CurrencyFormat  value={this.state.memberperiodic_balanceobj.savingsloan_security_balance} displayType={'text'} thousandSeparator={true} />  </p>
                    <Divider />

                    <p style={{fontWeight:'bolder',fontSize:15}}>Supersave loan security:<CurrencyFormat  value={this.state.memberperiodic_balanceobj.supersaveloan_security_balance} displayType={'text'} thousandSeparator={true} />  </p>
                    <Divider />

                    <p style={{fontWeight:'bolder',fontSize:15}}>Teebagoolo loan security:<CurrencyFormat  value={this.state.memberperiodic_balanceobj.teebagoololoan_security_balance} displayType={'text'} thousandSeparator={true} />  </p>
                    <Divider />

                    <p style={{fontWeight:'bolder',fontSize:15}}>Shares loan security:<CurrencyFormat  value={this.state.memberperiodic_balanceobj.shares_security_balance} displayType={'text'} thousandSeparator={true} />  </p>
                    <Divider />

                    <p style={{fontWeight:'bolder',fontSize:15}}>Saving Interest:<CurrencyFormat  value={this.state.memberperiodic_balanceobj.saving_interestbalance} displayType={'text'} thousandSeparator={true} />  </p>
                    <Divider />

                    <p style={{fontWeight:'bolder',fontSize:15}}>Teebagoolo Interest:<CurrencyFormat  value={this.state.memberperiodic_balanceobj.teebagoolo_interestbalance} displayType={'text'} thousandSeparator={true} />  </p>
                    <Divider />

                    <p style={{fontWeight:'bolder',fontSize:15}}>Supersave Interest:<CurrencyFormat  value={this.state.memberperiodic_balanceobj.supersave_interestbalance} displayType={'text'} thousandSeparator={true} />  </p>
                    <Divider />

                    <p style={{fontWeight:'bolder',fontSize:15}}>Loan interest:<CurrencyFormat  value={this.state.memberperiodic_balanceobj.loan_interestbalance} displayType={'text'} thousandSeparator={true} />  </p>
                    <Divider />
                   </Card>

                </Col>
            </Row>

    </div>
    )

    }
    
  }
}

export default QuickTransact; 
