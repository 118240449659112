import React from 'react';
import { Form, Input, Button,Select,InputNumber,DatePicker,message,Card,Spin,Result } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import moment from 'moment';
import PhoneInput from 'react-phone-number-input'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';

import {
  Col,
  Row,
} from 'reactstrap';
var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const { TextArea } = Input;
const dateFormat = 'DD/MM/YYYY';
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;


var sacco= ''
var token= ''
var username=''

class MembershipPaymentForm extends React.Component {

  state = {
    receivingaccount:'',  
    amount:0,
    datarequested:true,
    companyaccounts: [],
    debitaccount:'',
    datasubmittedsuccessfully:false,
    creditedaccount:'',
    date:moment().format(dateFormat).toString(),
    companyprofile:{},


  }
  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)

      }else{
       sacco= ''
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/companyaccounts/?sacco=${sacco}`)
    .then(res => {
        this.setState({
            companyaccounts:res.data
        })
        this.setState({datarequested:false})
    })

    axios.get(serverconfig.backendserverurl+`/api/saccos/${sacco}`)
    .then(res => {
        this.setState({
          companyprofile:res.data
        })
    })
  }

  
  //submit button pressed
  onFinish=(event) =>{

    if (Number(this.state.debitaccount)===Number(this.state.creditedaccount)){
      message.error('Debit ledger cant be the same as credit')
    }else{
      this.setState({datarequested:true})

      const memberid=this.props.memberid;
      let form_data = new FormData();
      form_data.append('date', this.state.date);

      form_data.append('amount', event.amount);
      form_data.append('debitaccount', this.state.debitaccount);
      form_data.append('creditedaccount', this.state.creditedaccount);
  
      form_data.append('journaltotal', event.amount);
      form_data.append('user', username);
      form_data.append('sacco', sacco);
      form_data.append('memberid', memberid);
  
      ///make a post request now
        axios.post(serverconfig.backendserverurl+'/customqueries/createmembershippayment', form_data, {
          headers: {
            'content-type': 'multipart/form-data'
          }
        })
      .then(res =>{
        if(res.data.message==="success") {
          message.info('Successfully made payment')
          this.setState({datarequested:false})
  
          this.setState({datasubmittedsuccessfully:true})
  
        }else{
  
          message.info(res.data.message)
          this.setState({datarequested:false})
  
        }
  
      }     
      )
      .catch(error => console.log(error))
    }
    
    

    }

    handlepaymentmodeChange= (e) => {
       this.setState({paymentmode:e})
    }

    handledatechange= (date, dateString) => this.setState({ date: dateString});


  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{

      if (this.state.datasubmittedsuccessfully===true){
        return(
            <Card>
            <Result
            status="success"
            title="Successful membership payment "
            extra={[
              <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
            ]}
            />
            </Card>
    
        );

      }else{
        return (
          <div>
            <Card>
    
            <Form
                  name="productform"
                  onFinish={(event) => this.onFinish(event)}
              >
                  
                  {
                    this.state.companyprofile.automated_transaction_date===false?
                  <FormItem label="Date of payment"
                        name="dateofissuance"          
                     >
                   <DatePicker  onChange={(date, dateString) =>{
                    //console.log(date.format(dateFormat))
                    //this.setState({ dateofissuance: date.format(dateFormat)});
                    this.setState({date:dateString})
                  } } format={dateFormat} />
                  </FormItem>
                  :
                  null}
    
                <FormItem label="Amount"
                  name="amount"
                  rules={[
                    {
                      required: true,
                      message: 'Please input amount',
                    },
                  ]}
                  >
                    <InputNumber
                      defaultValue={0.0}
                      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={value => value.replace(/\x\s?|(,*)/g, '')}
                    />    
                                
                  </FormItem>
                  
  
                <FormItem label={<h4 style={{fontWeight:'bold'}}>Debited ledger</h4>}
                    name="debitaccount"
                    rules={[
                      {
                        required: true,
                        message: 'Please select account',
                      },
                    ]}
                  >
                        <Select 
                        placeholder="Account" 
                        style={{  }} 
                        value={this.state.debitaccount}
                        onChange={(val)=>{this.setState({debitaccount:val})}} 
                        showSearch
                        optionFilterProp="children"
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        onSearch={this.onSearch}                    
                        >
                          {this.state.companyaccounts.map(
                            (accnt)=>(
                              <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
                            ))}
                        </Select>
                  </FormItem>


                  <FormItem label={<h4 style={{fontWeight:'bold'}}>Credited ledger</h4>}
                    name="creditedaccount"
                    rules={[
                      {
                        required: true,
                        message: 'Please select account',
                      },
                    ]}
                  >
                        <Select 
                        placeholder="Account" 
                        style={{  }} 
                        value={this.state.creditedaccount}
                        onChange={(val)=>{this.setState({creditedaccount:val})}} 
                        showSearch
                        optionFilterProp="children"
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        onSearch={this.onSearch}                    
                        >
                          {this.state.companyaccounts.map(
                            (accnt)=>(
                              <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
                            ))}
                        </Select>
                  </FormItem>
  
                <FormItem>
                  <Button  type="primary" htmlType="submit">Make Payment</Button>
                </FormItem>
                </Form>
            </Card>
    
          </div>
        );
      }


    }

    
  }

}


export default MembershipPaymentForm;



