import React from 'react';
import axios from 'axios';
import { Card, Button,Form,Input,message,Spin,DatePicker,Select} from 'antd';
import IDTypeForm from '../components/IDTypeForm'
import { UserOutlined,LoadingOutlined } from '@ant-design/icons';
import * as serverconfig from '../serverconn'
import {
    Col,
    Row,
  } from 'reactstrap';
  import moment from 'moment';
  var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Meta } = Card;
const { TextArea } = Input;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';

const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''
var sacco= ''
var username=''
var bizuserid= ''

class TeebaGooloDetail extends React.Component{

    state={
        date:moment().format(dateFormat).toString(),
        teebagooloprofile: {},
        saving_period:'' ,  
        datarequested:true,
        special_instructiononmaturity:'',
        term_maturitydate:'',
        target_savingfor:'',
        savingterm:'',
        teebagoolotype_obj:{},
        teebagoolotypes:[],
        teebagoolotype:'',
        physical_form:null,
        companyprofile:{},


    }

    componentDidMount(){
        if(localStorage.getItem("token")){
          token= localStorage.getItem("token")
        }else{
          token= ''
        }

        if(localStorage.getItem("sacco")){
          sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
          username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
       
        }else{
            sacco= ''
            username=''
        }

        axios.defaults.headers={
            "Content-Type":"application/json",
            Authorization:`Token ${token}`
          }

        const profileID=this.props.match.params.profileID;
        axios.get(`${serverconfig.backendserverurl}/api/teebagooloprofile/${profileID}`)
        .then(res => {  
            this.setState({
              teebagooloprofile: res.data
            })
            this.setState({date:res.data.date})
            this.setState({special_instructiononmaturity:res.data.special_instructiononmaturity})
            this.setState({term_maturitydate:res.data.term_maturitydate})
            this.setState({target_savingfor:res.data.target_savingfor})
    
            this.setState({savingterm:res.data.saving_period})
            this.setState({teebagoolotype:res.data.teebagooltype})

          })

          axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
          .then(res => {  
              this.setState({
                companyprofile: res.data
              })
      
          })

        axios.get(serverconfig.backendserverurl+`/api/teebagoolotypes/?sacco=${sacco}`)
        .then(res => {
            this.setState({
              teebagoolotypes:res.data
            })
    
            this.setState({datarequested:false})
        })
    }

    handleIdTypeNameChange= (e) => this.setState({ idtypeName: e.target.value});
    handleIDDescriptionChange= (e) => this.setState({ idtypeDescription: e.target.value});
  

    //submit button pressed
  handleFormSubmit=() =>{
    this.setState({datarequested:true})

    const profileID=this.props.match.params.profileID;


    let form_data1 = new FormData();
    this.state.physical_form==null?
    console.log("No physical file")
    :
    form_data1.append('physical_form', this.state.physical_form, this.state.physical_form?this.state.physical_form.name:"");
  
    axios.put(serverconfig.backendserverurl+`/api/teebagooloformuploadprofile/${profileID}/`, form_data1, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
    .then(res =>{console.log("Uploaded form")}).catch(error => console.log(error))
   //uploaded file


    let form_data = new FormData();
    form_data.append('saving_period', this.state.savingterm);
    form_data.append('target_savingfor', this.state.target_savingfor);
    form_data.append('special_instructiononmaturity', this.state.special_instructiononmaturity);
    form_data.append('term_maturitydate', this.calculate_maturitydate());
    form_data.append('halfterm_date', this.calculate_halftermdate());
    form_data.append('teebagoolotype', this.state.teebagoolotype);
    form_data.append('date',this.state.date);

    axios.put(`${serverconfig.backendserverurl}/api/teebagooloprofile/${profileID}/`,form_data, {
        headers: {
        'content-type': 'multipart/form-data'
        }
    })
    .then(res =>this.setState({datarequested:false}),window.location.reload(false),message.info('Successfully updated ID'))
    .catch(error => console.log(error))

  }


      //calculate maturity date
      calculate_maturitydate=()=>{
        var months=Number(this.state.savingterm)
        var m_date=''
  
        //get the application date
        var dataSplit = this.state.date.split('/');
        var dateConverted;
        if (dataSplit[2].split(" ").length > 1) {
  
            var hora = dataSplit[2].split(" ")[1].split(':');
            dataSplit[2] = dataSplit[2].split(" ")[0];
            dateConverted = new Date(dataSplit[2], dataSplit[1]-1, dataSplit[0], hora[0], hora[1]);
  
        } else {
            dateConverted = new Date(dataSplit[2], dataSplit[1] - 1, dataSplit[0]);
        }
  
        var year = dateConverted.getFullYear();
        var month = dateConverted.getMonth();
        var date = dateConverted.getDate();
  
        var paydate=new Date(year,month+months, date).toLocaleDateString('en-GB', {day : 'numeric',month : 'numeric',year : 'numeric'}).split(' ').join('/');
  
        m_date=paydate
  
        return m_date;
      }



      //calculate maturity date
      calculate_halftermdate=()=>{
        var days_add=(this.state.savingterm*30)/2
        var m_date=''
  
        //get the application date
        var dataSplit = this.state.date.split('/');
        var dateConverted;
        if (dataSplit[2].split(" ").length > 1) {
  
            var hora = dataSplit[2].split(" ")[1].split(':');
            dataSplit[2] = dataSplit[2].split(" ")[0];
            dateConverted = new Date(dataSplit[2], dataSplit[1]-1, dataSplit[0], hora[0], hora[1]);
  
        } else {
            dateConverted = new Date(dataSplit[2], dataSplit[1] - 1, dataSplit[0]);
        }
  
        var year = dateConverted.getFullYear();
        var month = dateConverted.getMonth();
        var date = dateConverted.getDate();
  
        var paydate=new Date(year,month, date+days_add).toLocaleDateString('en-GB', {day : 'numeric',month : 'numeric',year : 'numeric'}).split(' ').join('/');
  
        m_date=paydate
  
        return m_date;
      }

      handlesavingtermChange= (e) => this.setState({ savingterm: e.target.value});
      handletransactorcontactChange= (e) => this.setState({ transactorcontact: e});
      handletransferamountChange= (e) => this.setState({ transferamount: e});


    render(){

        if(this.state.datarequested===true){
            return(
              <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin indicator={antIcon} />
              </div>
            )
      
          }else{
            return(
                <div>
                    
                    <Row>
                    <Col xs="12" sm="6" lg="6">
                    <Card 
                    style={{padding:10}}
                    hoverable>
                  <h2>Teeba Goolo details</h2>

                  <h4>Account Type Name: {this.state.teebagooloprofile.product_term}</h4>
                  <h4>Member Account: {this.state.teebagooloprofile.AccountNumber}</h4>
                  <h4>Member Fullname: {this.state.teebagooloprofile.FullName}</h4>
                  <h4>Teeba Goolo Balance: {this.state.teebagooloprofile.teebagoolo_balance}</h4>
                  <h4>Shares At Application: {this.state.teebagooloprofile.savings_atapplicatiom}</h4>
                  <h4>Savings At Application: {this.state.teebagooloprofile.shares_atapplicatiom}</h4>

              </Card>

              </Col>

                <Col xs="12" sm="6" lg="6">
                    <Card 
                    style={{padding:10}}
                    hoverable>
                  <h3>Teeba Goolo Update</h3>
                    <Form>

                    <FormItem label="Teeba Goolo Account Type">
                    <Select placeholder="Teeba Goolo Type" 
                    style={{  }} 
                    value={this.state.teebagoolotype}
                    onChange={(val)=>{
                      this.setState({teebagoolotype:val})
                    
                      //get suprsave type object
                      axios.get(`${serverconfig.backendserverurl}/api/teebagoolotypes/${val}`)
                      .then(res => {  
                          this.setState({teebagoolotype_obj: res.data})
                      })
                    
                    }}
                    showSearch
                    optionFilterProp="children"
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onSearch={this.onSearch} 
                    
                    >
                      {this.state.teebagoolotypes.map(
                        (type)=>(
                          <Option value={type.id}>{type.product_term}</Option>
                        ))}
                    </Select>
                  </FormItem>

                <FormItem label="Change date of application"
                  name='date'
                  rules={[
                    {
                      required: true,
                      message: 'Please Select date',
                    },
                  ]}
                >
                    <DatePicker value={moment()} onChange={(date, dateString)=>{this.setState({ date: dateString})}} format={dateFormat} />
                </FormItem>


                  <FormItem label={<h4 style={{fontWeight:'bold'}}>Target Saving For?</h4>} >
                    <TextArea
                        placeholder="Target Saving For"
                        autoSize={{ minRows: 2, maxRows: 8 }}
                        value={this.state.target_savingfor}
                        onChange={(val)=>{this.setState({target_savingfor:val.target.value})}}
                      />
                  </FormItem>


                    <FormItem label={<h4 style={{fontWeight:'bold'}}>Special Instructions At Maturity</h4>} >
              
                    <TextArea
                        placeholder="Special Instructions At Maturity"
                        autoSize={{ minRows: 2, maxRows: 8 }}
                        value={this.state.special_instructiononmaturity}
                        onChange={(val)=>{this.setState({special_instructiononmaturity:val.target.value})}}
                      />
                    </FormItem>


                    <FormItem label={<h4 style={{fontWeight:'bold'}}>Approved Saving Term  (Months):</h4>}
                    >
                      <Input name="savingterm" type='number' placeholder="Term" value={this.state.savingterm} onChange={this.handlesavingtermChange}/>
                    </FormItem>

                    <h3>Maturity date: {this.calculate_maturitydate()}</h3>
                    <h3>Halfterm date: {this.calculate_halftermdate()}</h3>

                  <FormItem label="Physical Application Form" style={{marginRight:3}}>
                    <Input type="file" accept=".pdf" placeholder="Physical Application Form" 
                    
                          onChange={(val)=>{
                            var kilbyes=val.target.files[0].size/1024
                            if (kilbyes>Number(this.state.companyprofile.maximum_upload_document_size)){
                              message.error("File is greater than "+String(this.state.companyprofile.maximum_upload_document_size)+" Kbs .It will not be uploaded.")
                            }else{
                              this.setState({physical_form:val.target.files[0]})
                            }
                            
                            }}/>
                    </FormItem> 
                
                    <FormItem>
                    <Button onClick={(event) => this.handleFormSubmit()} type="primary" htmlType="button">Update</Button>
                    </FormItem>
                  </Form>                      
                      
              </Card></Col>

              </Row>

              <br/>

          </div>
            )

          }
       
    }
}

export default TeebaGooloDetail; 