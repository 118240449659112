import React from 'react';
import VoucherCreateForm from '../components/VoucherCreateForm'
import axios from 'axios'
import { Table, Input, Button,Collapse,Popover,Popconfirm,Spin,message,DatePicker,Modal,Image,Form,Select,Switch } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DeleteOutlined,FundViewOutlined,PrinterOutlined,LoadingOutlined } from '@ant-design/icons';
import QRCode from 'react-qr-code';
import { Link } from 'react-router-dom';
import * as serverconfig from '../serverconn'
import Resizer from "react-image-file-resizer";
import CurrencyFormat from 'react-currency-format';
import {
  Col,
  Row,
} from 'reactstrap';
import * as reactstrp from 'reactstrap';
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import '../tablestyle.css'; // Create this CSS file for custom styles

var CryptoJS = require("crypto-js");
var converter = require('number-to-words');
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;


const { Panel } = Collapse;
const FormItem=Form.Item;
const { Option } = Select;
const { TextArea } = Input;
const dateFormat = 'DD/MM/YYYY';
const { RangePicker } = DatePicker;


var sacco= ''
var username=''
var token=''



class ReportToPrint extends React.Component {

  state = {
    companyprofile:{},
  };

  componentDidMount(){

    if(localStorage.getItem("sacco")){
      sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
      username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
   
   }else{
      sacco= ''
      username=''
   }

    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })
    })

  }

  //clactulate totla of items
  calculateTotalofItems=()=>{
    var total=0

    JSON.parse(this.props.selected_voucher.items_list).map(
      (item)=>{
        total+=Number(item.amount)
      })

    return  total.toFixed(2);  

  }


  render() {
    return (
      <div style={{padding:20}}>
      <div>
      <Row> 
      <Col xs="2" sm="2" lg="2">
                <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.sacco_logo} />
              
                  </Col>
                  <Col xs="6" sm="6" lg="6">
                  <h3>{this.state.companyprofile.sacco_name}</h3>
                  <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                  {
                  this.state.companyprofile.RegisteredPhoneNumber2==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber2
                  }
                  {
                  this.state.companyprofile.RegisteredPhoneNumber3==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber3
                  }
                  </h6>               
                  <h5>Email: {this.state.companyprofile.company_email}</h5>
                  <h5>Wesite: {this.state.companyprofile.website}</h5>
                  <h5>{this.state.companyprofile.box_number}</h5>
                
                  </Col>
       </Row>
      <h2 style={{fontWeight:'bolder',display:'flex',alignSelf:'center',alignContent:'center',justifyContent:'center'}}>PAYMENT VOUCHER</h2>

      </div>


        <reactstrp.Table bordered>



            <thead>
                <tr scope="row">
              <td>
              <h4 style={{fontWeight:'bolder'}}align='left'>{' NUMBER: '+this.props.selected_voucher.voucher_no}</h4>
              <h4 style={{fontWeight:'bolder'}}align='left'>{' DATE: '+this.props.selected_voucher.date}</h4>
              <h4 style={{fontWeight:'bolder'}}align='left'>{' PAY CHANNEL: '+this.props.selected_voucher.payment_channel}</h4>
              <h4 style={{fontWeight:'bolder'}}align='left'>{' STATUS: '+this.props.selected_voucher.status}</h4>

              </td>
              </tr>


              <tr scope="row">
                <th><h2>#</h2></th>
                <th><h2>ITEM</h2></th>
                <th><h2>AMOUNT</h2></th>
                
              </tr>
            </thead>
            <tbody>
            { JSON.parse(this.props.selected_voucher.items_list).map(
              (item)=>(
                <tr scope="row">
                  <td><h2>{item.itemnumber}</h2></td>
                  <td><h2>{item.item_name}</h2></td>
                  <td><h2>{<CurrencyFormat value={item.amount} displayType={'text'} thousandSeparator={true}/>}</h2></td>

                </tr>
              ))}
                  <tr scope="row">
                  <td></td>
                  <td></td>
                  <th>
                    <h2>
                    {<CurrencyFormat value={this.calculateTotalofItems()} displayType={'text'} thousandSeparator={true}/>}
                    </h2>
                    </th>
                </tr>


                <tr scope="row">
                  <td>
                  <h3 style={{fontWeight:'bolder'}}align='left'>{' RECEIVED BY: '+this.props.selected_voucher.received_by}</h3>
                  <h3 style={{fontWeight:'bolder'}}align='left'>{' PAY TOTAL: '} <CurrencyFormat value={this.props.selected_voucher.totalAmount} displayType={'text'} thousandSeparator={true}/> </h3>
                  <h3 style={{fontWeight:'bolder'}}align='left'>{' PAY TOTAL (IN WORDS): '} {converter.toWords(Number(this.props.selected_voucher.totalAmount))} </h3>

                  <h3 style={{fontWeight:'bolder'}}align='left'> APPROVERS: 
                  {JSON.parse(this.props.selected_voucher.approvers_list)
                  .map((item)=>
                  {
                    return (item.approver_name+" ,")
                  
                  }
                  
                  )
                  }
                  
                  </h3>                        

                  </td>

                </tr>
              
          </tbody>
          </reactstrp.Table>

          <br></br>
            <h3 style={{fontWeight:'bolder'}}>Generated by: {CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)}</h3>
            <h3 style={{fontWeight:'bolder'}}>Powered by: www.pitech.co.ug</h3>   

  </div>

    );
  }
}



function callback(key) {
  console.log(key);
}



class VouchersList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    sheetitems: [],
    productunits:[],
    currenteditablecolumn: {},
    showeditableform:false,

    product_name:0,
    unit:'',
    sellingprice:0,
    buyingprice:0,
    discount_percentage:0,
    product_online:false,
    description:'',
    Photo:null,
    photofile:null,

    dateone:moment().format(dateFormat).toString(),
    datetwo:moment().format(dateFormat).toString(),


  };

  componentDidMount(){
    if(localStorage.getItem("sacco")){
      sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
      username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
   
   }else{
      sacco= ''
      username=''
   }


    this.searchSales()

    }


       //search sales method
   searchSales=()=>{

      let form_data = new FormData();
      form_data.append('dateone', this.state.dateone);
      form_data.append('datetwo', this.state.datetwo);
      form_data.append('sacco', sacco);
        
      if(this.state.dateone===''||this.state.datetwo===''){
        alert("Dates missing")
      }else{
          this.setState({datarequested:true})
  
          //Now submit sale data to database
          axios.post(serverconfig.backendserverurl+'/customqueries/getvouchers', form_data,{
          headers: {
            'content-type': 'multipart/form-data'
          }
          })
          .then(res =>{
            this.setState({datarequested:false})
            this.setState({sheetitems:JSON.parse(res.data.report)})
          } 
          )
          .catch(error => console.log(error))   
      }
    
    }




getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};



  render() {


    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{

      const columns = [
        {
          title: 'Date',
          dataIndex: 'date',
          key: 'id',
          ...this.getColumnSearchProps('date'),
        },
  
        {
          title: 'Voucher no',
          dataIndex: 'voucher_no',
          key: 'id',
        },
        {
          title: 'Total amount',
          dataIndex: 'totalAmount',
          key: 'id',
          render:(text,record)=><p>
            <CurrencyFormat value={record.totalAmount} displayType={'text'} thousandSeparator={true}/>
          </p>
        },
        {
          title: 'Approvers',
          dataIndex: 'approvers_list',
          key: 'id',
          render:(text,record)=><p>
          {JSON.parse(record.approvers_list)
            .map((item)=>
            {
              return (item.approver_name+" ,")
            }
            
            )
            }
  
          </p>
  
        },
        {
          title: 'Received by',
          dataIndex: 'received_by',
          key: 'id',
        },
  
        {
          title: 'Items total',
          dataIndex: 'items_total',
          key: 'id',
        }
        ,
        {
          title: 'Action',
          dataIndex: 'id',
          key: 'id',
          render: (text,record) =>
          <p>
         
          <Popover content={<p>Click here to delete </p>} title="Delete">
          <Popconfirm title="Sure to delete?" onConfirm={() => {

          let form_data = new FormData();
          form_data.append('voucher_id', record.id);
          form_data.append('sacco', sacco);

          //Now submit sale data to database
          axios.post(serverconfig.backendserverurl+'/customqueries/delete_voucher', form_data,{
            headers: {
              'content-type': 'multipart/form-data'
            }
            })
            .then(res =>{
              this.setState({datarequested:false})
               window.location.reload(false)
                
            } 
            )
            .catch(error => console.log(error))   
  
          }}>
            <DeleteOutlined style={{color:'red'}}/>
           </Popconfirm>
          </Popover>
          </p>,
        }

        ];
  
  
      return(
          <div>
              <Collapse defaultActiveKey={['1']} onChange={this.callback}>
              <Panel header="PAYMENT VOUCHERS" key="1">
              
              <br></br>
                 <br></br>
    
                   <Form  layout="inline" >
             
                    <FormItem label="Date Range">
                        <RangePicker onChange={
                          (date, dateString) =>{
                            this.setState({ dateone: dateString[0]});
                            this.setState({ datetwo: dateString[1]});
                          }
                        } format={dateFormat} />
                    </FormItem>
    
                    <FormItem>
                    <Button onClick={()=>{
                        this.searchSales()
                   
                    }} type="primary" htmlType="button">Search</Button>
                    </FormItem> 
    
               </Form>
    
               <br></br>
  
              
              
              <Table  
              scroll={{ x: 1000 }} 
              columns={columns} 
              pagination={{showQuickJumper:true,showSizeChanger:true }}
              dataSource={this.state.sheetitems} 
              bordered
              
              expandable={{
                expandedRowRender: (record) =>{
                    return (
                      <div
                        style={{
                          margin: 0,
                        }}
                        >
  
                    <div style={{display: "none"}}>
                      &nbsp;&nbsp;
                      <ReportToPrint
                      selected_voucher={record} 
                      date={moment().format(dateFormat).toString()}
                      ref={el => (this.componentRef = el)} /> 
                     </div> 
  
                    <ReactToPrint
                    trigger={() => 
                    <Button 
                      type="primary"
                      shape="round"
                      icon={<PrinterOutlined />}
                      size={this.state.size}
                      >
                    Print Voucher
                    </Button>
                    }
                    content={() => this.componentRef}
                    />
  
      
                     </div>
                     );
                } ,
                rowExpandable: (record) => true,
                onExpand:(condition,record)=>{
                },
              }}
              
              
              
              
              />
              </Panel>
              </Collapse>
              <br></br>            
              <Collapse defaultActiveKey={['0']} onChange={callback}>
              <Panel header="Collapse Panel to open Form" key="1">
                <h2>Create a new voucher here</h2>
                <VoucherCreateForm 
                requestType="post"
                productID={null}
                btnText="Create"
                /> 
              </Panel>
              </Collapse>
  
          </div>
      )



    }


  }
}

export default VouchersList; 
