import React,{ useRef } from 'react';
import axios from 'axios'
import { Tooltip,Popover,Result,Table,Popconfirm,Input, Button,Collapse,Card,Avatar,Modal,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { PrinterOutlined, MinusOutlined, CloseCircleFilled, CheckCircleOutlined, UserOutlined } from '@ant-design/icons';
import { DeleteOutlined,SearchOutlined,CameraOutlined,PlusOutlined,EditOutlined,PlusSquareFilled,SaveOutlined,LoadingOutlined } from '@ant-design/icons';
import QrReader from 'react-qr-reader'
import uuid from 'react-uuid'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
import PrintTemplate from 'react-print';
import ReactToPrint from 'react-to-print';
import {
  Col,
  Row,
} from 'reactstrap';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import * as reactstrp from 'reactstrap';
import { ToWords } from 'to-words';

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { Title } = Typography;
const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

const toWords = new ToWords();

message.config({
  top: 200,
  duration: 2,
  maxCount: 3,
  rtl: true,
});


var token= ''
var sacco= ''
var username=''
var bizuserid= ''


class InterAccountTransfersSingle extends React.Component {

  state = {
    date:moment().format(dateFormat).toString(),
    selectedaccount:'',
    selectedaccountobject:{},
    members:[],
    datarequested:true,
    totalamount:0,
    voucher_number:'',
    paidinby:'',
    transactorcontact:'',
    previoustransactions:[],
    datasubmittedsuccessfully:false,
    companyprofile:{},
    accountchange:false,
    userprofile:{},
    transaction_details:'',
    tranferamount:0,
    interaccounttransfermode:'ordinarysave_supersave',
    supersave_profile:{},
    teebagoolo_profile:{},
    companyaccounts: [],
    availableloans:[],
    selectedloan:'',

    memberperiodic_balanceobj:{}


  }

  callback(key) {
    console.log(key);
  }

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    
    }else{
       sacco= ''
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
   }else{
      bizuserid= ''
   }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    let form_data1 = new FormData();
    form_data1.append('userid', bizuserid);
    
    //get the user profile here
    axios.post(serverconfig.backendserverurl+'/customqueries/get_user_profile',form_data1,{
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res => {
        this.setState({
          userprofile:res.data
        })
    })


    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })

    axios.get(serverconfig.backendserverurl+`/api/members_withaccounts/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          members:res.data
        })

        this.setState({datarequested:false})
    })


    axios.get(serverconfig.backendserverurl+`/api/companyaccounts/?sacco=${sacco}`)
    .then(res => {
        this.setState({
            companyaccounts:res.data
        })
        this.setState({datarequested:false})
    })

  }

  
  ////////////////////////////////////
  // PRODUCT SEARCH SELECT METHODS
   onBlur=()=> {
    console.log('blur');
  }
  
   onFocus=()=>{
    console.log('focus');
  }
  
  onSearch=(val)=> {
    console.log('search:', val);
  }

  //handle client change
  handleselectedaccountChange= (e) =>{

    let form_data1 = new FormData();
    form_data1.append('date',this.state.date); 
    form_data1.append('member_id', e);
    axios.post(serverconfig.backendserverurl+`/customqueries/get_runningbalancesbydate`, form_data1, {
    headers: {
      'content-type': 'multipart/form-data'
    }
    })
    .then(res => {
        this.setState({
          memberperiodic_balanceobj:res.data.my_balance
        })
    })


    this.setState({accountchange:true})
    this.setState({ selectedaccount: e});
    this.setState({supersave_profile: {}})
    this.setState({teebagoolo_profile: {}})

    //make query for last transactions
    axios.get(`${serverconfig.backendserverurl}/api/members/${e}`)
      .then(res => {  
          this.setState({selectedaccountobject: res.data})
          this.setState({accountchange:false})
      })

      axios.get(`${serverconfig.backendserverurl}/api/supersaveprofile/?member_account=${e}`)
      .then(res => {  
          this.setState({supersave_profile:res.data[0]})
      })

      axios.get(`${serverconfig.backendserverurl}/api/teebagooloprofile/?member_account=${e}`)
      .then(res => {  
          this.setState({teebagoolo_profile:res.data[0]})
      })

      ////get active loan
      axios.get(serverconfig.backendserverurl+`/customqueries/get_memberactive_loans/${e}`)
      .then(res => {
          this.setState({
            availableloans:JSON.parse(res.data.report)
          })
      })

  }

  handlepaidinbyChange= (e) => this.setState({ paidinby: e.target.value});
  handletransactorcontactChange= (e) => this.setState({ transactorcontact: e});


  //check empty objecty
  isEmptyObject=(obj)=>{
    for ( var name in obj ) {
      return false;
    }
    return true;
  }

//check super save maturity
  checksupersave_maturitydate=()=>{
    console.log(this.state.supersave_profile)

    const matuirity_str = this.state.supersave_profile.maturity_date;
    console.log("my end date"+matuirity_str)
  
    var dateMomentObjectone = moment(this.state.supersave_profile.maturity_date, "DD/MM/YYYY"); // 1st argument - string, 2nd argument - format
    var dateObjectone = dateMomentObjectone.toDate();

    var currentrtdate=new Date()

    if (currentrtdate>=dateObjectone){
      return true;
    }else{
      return false;
    }

  }

//check teeba goolo maturity
  checkteebagoolo_maturitydate=()=>{
    console.log(this.state.teebagoolo_profile)

    const matuirity_str = this.state.teebagoolo_profile.term_maturitydate;
    console.log("my end date"+matuirity_str)
  
    var dateMomentObjectone = moment(this.state.teebagoolo_profile.term_maturitydate, "DD/MM/YYYY"); // 1st argument - string, 2nd argument - format
    var dateObjectone = dateMomentObjectone.toDate();

    var currentrtdate=new Date()

    if (currentrtdate>=dateObjectone){
      return true;
    }else{
      return false;
    }

  }

    //calculate Netwithdraw
    calculateNetWithdraw=()=>{
      var netwithdraw=0
      var minimumaccountbal=0
      var loan_security=0

      if (this.isEmptyObject(this.state.selectedaccountobject)===false){
         minimumaccountbal=Number(this.state.selectedaccountobject.minimum_account_balance)
         loan_security=Number(this.state.memberperiodic_balanceobj.savingsloan_security_balance)
         netwithdraw=Number(this.state.memberperiodic_balanceobj.savings_balance)-(minimumaccountbal+loan_security)
      }

      return netwithdraw;
    }


//else if (this.state.interaccounttransfermode==="supersave_ordinarysave" && this.checksupersave_maturitydate()===false){
 // message.error("Supersave not yet mature. maturirty date is "+String(this.state.supersave_profile.maturity_date))
//}
//
//else if (this.state.interaccounttransfermode==="teebagoolo_ordinarysave" && this.checkteebagoolo_maturitydate()===false){
 // message.error("Teeba goolo not yet mature. maturirty date is "+String(this.state.teebagoolo_profile.term_maturitydate))
//
//}


  //submit the deposit now
  handleFormSubmit=(event) =>{
  console.log(this.state.supersave_profile)

    if (this.state.interaccounttransfermode==="supersave_ordinarysave" && this.isEmptyObject(this.state.supersave_profile)===true){
        message.error("Member has no super save profile")
    }else if (this.state.interaccounttransfermode==="teebagoolo_ordinarysave" && this.isEmptyObject(this.state.teebagoolo_profile)===true){
      message.error("Member has no teebagoolo profile")
    }else if(this.state.interaccounttransfermode==="ordinarysave_supersave" &&Number(this.state.tranferamount)>this.calculateNetWithdraw()){
      message.error("Ordinary Savings Amount is less")
    }
   
   
      else if(this.state.interaccounttransfermode==="ordinarysave_shares" &&Number(this.state.tranferamount)>this.calculateNetWithdraw()){
      message.error("Ordinary Savings Amount is less")
    }else if (this.state.interaccounttransfermode==="teebagoolo_ordinarysave" && Number(this.state.tranferamount)>Number(this.state.memberperiodic_balanceobj.teebagoolo_balance)){
      message.error("Teeba goolo account balance is low")

    }
    
    
    else if (this.state.interaccounttransfermode==="supersave_ordinarysave" && Number(this.state.tranferamount)>Number(this.state.memberperiodic_balanceobj.supersaver_balance)){
      message.error("Supersave account balance is low")

    } 
   
   
      else if (this.state.interaccounttransfermode==="defsave_ordinarysave" && Number(this.state.tranferamount)>Number(this.state.memberperiodic_balanceobj.def_deposit_balance)){
      message.error("Defered account balance is low")

    }
    else if (this.state.interaccounttransfermode==="savings_toloan" && Number(this.state.tranferamount)>Number(this.calculateNetWithdraw())){
      message.error("Savings balance is low")
    }
    else if (this.state.interaccounttransfermode==="savings_toloan" && this.state.selectedloan===""){
      message.error("Loan to pay is not selected ")
    }  else{

      this.setState({datarequested:true})
      let form_data = new FormData();
      form_data.append('date',this.state.date);
      form_data.append('interaccounttransfermode',this.state.interaccounttransfermode);
      form_data.append('tranferamount', this.state.tranferamount);
      form_data.append('sacco', sacco);
      form_data.append('account', this.state.selectedaccountobject.id);
      form_data.append('username', username);
      form_data.append('userid', bizuserid);
      form_data.append('transaction_details', this.state.transaction_details);

      form_data.append('selectedloan', this.state.selectedloan);

      axios.post(serverconfig.backendserverurl+'/customqueries/memberinteraccountstransfer', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
        })
      .then(res => {
        this.setState({datarequested:false})
        message.info(res.data.message)
        if (res.data.message==="success"){
          this.setState({datasubmittedsuccessfully:true})
        }
      })
      .catch(error => console.log(error))
    }

  }


  ////////////////////////////////////////////
  // RENDERING METHOD HERE
  render() {

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      if(this.state.datasubmittedsuccessfully===true){
        return(
          <div>
           
            <Row >
                <Col xs="12" sm="6" lg="6">
                  <Card>
                  <Result
                  status="success"
                  title="Successfully inter account transfer "
                  extra={[
                    <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
                  ]}
                  />
                </Card>
                </Col>
            </Row>
          </div>
        )

      }else{
        return(
          <div>
            <div>
  
              <Row >
                  <Col xs="12" sm="8" lg="8">
                    <Card>
                    <h4 style={{fontStyle:'oblique',fontWeight:'bolder'}}>Select Member for inter-tranfers</h4>
                    <FormItem label="Account">
                        <Select 
                        placeholder="Account No | Name" 
                        style={{  }} 
                        value={this.state.selectedaccount}
                        onChange={this.handleselectedaccountChange} 
                        showSearch
                        optionFilterProp="children"
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        onSearch={this.onSearch}                    
                        >
                          {this.state.members.map(
                            (accnt)=>(
                              <Option value={accnt.id}>{accnt.AccountNumber} | {accnt.FullName}</Option>
                            ))}
                        </Select>
                    </FormItem>

                    {
                      this.state.accountchange?
                      <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
                      <Spin indicator={antIcon} />
                      </div>
                      :
                      null
                    }

                    <h3 style={{fontWeight:'bold',alignSelf:'center',color:'#154360'}}>SINGLE MEMBER INTERACCOUNT TRANSFER FORM</h3>
                  <Form
                        onFinish={(event) => this.handleFormSubmit(event)}
                    >

                  <FormItem 
                    label="Inter Account Transfer Mode"                      
                    >
                    <Select 
                    value={this.state.interaccounttransfermode} 
                    onChange={(val)=>{this.setState({interaccounttransfermode:val})}}
                    placeholder="Inter Account Tranfer Mode" >
                    <Option value='ordinarysave_supersave'>OrdinarySavings to Supersave</Option>     
                    <Option value='ordinarysave_shares'>OrdinarySavings to Shares</Option>
                    <Option value='defsave_ordinarysave'>DefSavings to Ordinary Savings</Option>
                    <Option value='supersave_ordinarysave'>Supersave to Ordinary Savings</Option>
                    <Option value='teebagoolo_ordinarysave'>Teebagoolo to Ordinary Savings</Option>
                    <Option value='savingsinterest_ordinarysave'>Savings Interest to Ordinary Savings</Option>
                    <Option value='shares_ordinarysave'>Shares to Ordinary Savings</Option>

                    </Select>
                  </FormItem>

                  {
                     this.state.companyprofile.automated_transaction_date===false?
                    <FormItem label="Date"
                    name='date'
                    rules={[
                      {
                        required: true,
                        message: 'Please Select date',
                      },
                      ]}
                    >
                        <DatePicker onChange={(date, dateString) => this.setState({ date: dateString})} format={dateFormat} />
                    </FormItem>                 
                    :
                    null
                  }


                 <FormItem label={<h6 style={{fontWeight:'bold'}}>Tranfer Amount </h6>}
                  name="tranferamount"
                  rules={[
                    {
                      required: true,
                      message: 'Please input transfer amount',
                    },
                  ]}
                  >
                      <Input name="tranferamount"   placeholder="0" value={this.state.tranferamount} onChange={(e) => this.setState({ tranferamount: e.target.value})}/>
                  </FormItem>

                  <FormItem label={<h4 style={{fontWeight:'bold',color:'#154360'}}>Total Amount </h4>}
                    >
                      <h3 style={{fontWeight:'bolder'}}><CurrencyFormat  value={this.state.tranferamount} displayType={'text'} thousandSeparator={true} /></h3>
                  </FormItem>


                  <FormItem label={<h4 style={{fontWeight:'bold'}}>Transaction details.</h4>} >
                
                  <TextArea
                      placeholder="Transaction Details"
                      autoSize={{ minRows: 2, maxRows: 8 }}
                      value={this.state.transaction_details}
                      onChange={(val)=>{this.setState({transaction_details:val.target.value})}}
                    />
                  </FormItem>


                  <FormItem>
                    <Button  type="primary" htmlType="submit">Submit</Button>
                  </FormItem>
                  </Form>
  
                  </Card>
                  </Col>

                  <Col xs="12" sm="6" lg="4">
                    <Card>
                    <h3 style={{fontWeight:'bold',color:'#154360'}}>Account Details</h3>
                    <h4>Account Number:{this.state.selectedaccountobject.AccountNumber}</h4>
                    <h4>Account Contact:{this.state.selectedaccountobject.RegisteredPhoneNumber1}</h4>
                    <h4>Account Name:{this.state.selectedaccountobject.FullName}</h4>
                    <h4>Ordinary Account Balance:<CurrencyFormat value={this.state.memberperiodic_balanceobj.savings_balance} displayType={'text'} thousandSeparator={true}  /> </h4>
                    <h4>Ordinary Interest Account Balance:<CurrencyFormat value={this.state.memberperiodic_balanceobj.saving_interestbalance} displayType={'text'} thousandSeparator={true}  /> </h4>
                    <h4>Shares Account Balance:<CurrencyFormat value={this.state.memberperiodic_balanceobj.shareamount_balance} displayType={'text'} thousandSeparator={true}  /> </h4>
                    <h4>Def Account Balance:<CurrencyFormat value={this.state.memberperiodic_balanceobj.def_deposit_balance} displayType={'text'} thousandSeparator={true}  /> </h4>
                    <h4>Supersave Account Balance:<CurrencyFormat value={this.state.memberperiodic_balanceobj.supersaver_balance} displayType={'text'} thousandSeparator={true}  /> </h4>
                    <h4>Supersave Account Interest Balance:<CurrencyFormat value={this.state.memberperiodic_balanceobj.supersave_interestbalance} displayType={'text'} thousandSeparator={true}  /> </h4>
                    <h4>Teeba Ggoolo Account Balance:<CurrencyFormat value={this.state.memberperiodic_balanceobj.teebagoolo_balance} displayType={'text'} thousandSeparator={true}  /> </h4>
                    <h4>Loan Account Balance:<CurrencyFormat value={this.state.memberperiodic_balanceobj.loan_balance} displayType={'text'} thousandSeparator={true}  /> </h4>
                    <br></br>
                    </Card>
                  </Col>
               </Row>
            </div>
      
       </div>              
                      
       )
      }
      
    }
   
  }
}

export default InterAccountTransfersSingle; 
