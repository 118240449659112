import React from 'react';
import { Form, Input, Button,Select,Spin,Result,message,Card,InputNumber,Modal,Popconfirm,Popover,Switch,Divider } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import moment from 'moment';
import { UserOutlined, LockOutlined,FundViewOutlined, LoadingOutlined,CloseCircleFilled, MailOutlined,UploadOutlined,PlusCircleOutlined,PlusCircleFilled } from '@ant-design/icons';
import {
  Col,
  Row,
} from 'reactstrap';
import * as reactstrp from 'reactstrap';
import uuid from 'react-uuid'
import CurrencyFormat from 'react-currency-format';

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const dateFormat = 'DD/MM/YYYY';

var sacco= ''
var token= ''
var bizuserid=''

class LoanApplication_FileAttachmentDetailView extends React.Component {

  state = {
    date:moment().format(dateFormat).toString(),
    loanapplic_obj:{},
    physical_form:null,
    userrights:{},

  }
  

  componentDidMount(){

    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)

      }else{
       sacco= ''
       bizuserid=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

      axios.defaults.headers={
        "Content-Type":"application/json",
        Authorization:`Token ${token}`
      }

      const loanapplicationID=this.props.loanapplicationID;

      axios.get(serverconfig.backendserverurl+`/api/loanapplications/${loanapplicationID}`)
      .then(res => {
        this.setState({loanapplic_obj:res.data})
      })


      axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
      .then(res => {  
          this.setState({
            companyprofile: res.data
          })

      })


      axios.get(serverconfig.backendserverurl+`/api/accountrights/?user=${bizuserid}`)
      .then(res => {
          this.setState({
            userrights:res.data[0]
          })

          this.setState({datarequested:false})

      })

  }




  //submit button pressed
  handleFormSubmit=(event) =>{
    const loanapplicationID=this.props.loanapplicationID;

    if (this.state.physical_form==null){
      message.error("Please select file")
    }else{

    this.setState({datarequested:true})

    let form_data = new FormData();

    this.state.physical_form==null?
      console.log("No physical file")
    :
    form_data.append('physical_form', this.state.physical_form, this.state.physical_form?this.state.physical_form.name:"");

    axios.put(serverconfig.backendserverurl+`/api/loanapplications/${loanapplicationID}/`, form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  .then(res =>         
    this.setState({datarequested:false})
,  this.setState({datasubmittedsuccessfully:true})
  )
  .catch(error => console.log(error))

    }

  }

  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{

      if (this.state.datasubmittedsuccessfully===true){
        return(
          <Row >
            <Col xs="12" sm="6" lg="6">
            <Card>
            <Result
            status="success"
            title="Successfully Uploaded file"
            extra={[
              <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
            ]}
            />
            </Card>
            </Col>
            </Row>
        );

      }else{

       
          return (
            <div>

              {
                this.state.userrights.can_register_loans===true?
                <div>
                    <h4>Attach file here</h4>

                  <Form onFinish={(event) => this.handleFormSubmit(event)}   >
                    <FormItem label="Physical Application Form" style={{marginRight:3}}>
                    <Input type="file" accept=".pdf" placeholder="Physical Application Form" 

                    onChange={(val)=>{
                      var kilbyes=val.target.files[0].size/1024
                      if (kilbyes>Number(this.state.companyprofile.maximum_upload_document_size)){
                        message.error("File is greater than "+String(this.state.companyprofile.maximum_upload_document_size)+" Kbs .It will not be uploaded.")
                      }else{
                        this.setState({physical_form:val.target.files[0]})
                      }
                      
                      }}/>
                    </FormItem> 


                    {
                      this.state.loanapplic_obj.issued===true?
                      <h3 style={{color:'green'}}>Loan Already disbursed</h3>
                      :
                      <FormItem>
                      <Button  type="primary" htmlType="submit">Update Application</Button>
                      </FormItem>
                    }

                  </Form>
                </div>
                :
                null
              }
              

            </div>
          );
       

      }
    }

  }

}

export default LoanApplication_FileAttachmentDetailView;



