import React from 'react';
import { Form, Input, Button,Select,DatePicker,Spin,Image, Card,Divider,Result, message,Modal,Popconfirm,Popover } from 'antd';
import axios from 'axios'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import moment from 'moment';
import * as serverconfig from '../serverconn'
import {
  Col,
  Row,
} from 'reactstrap';
import { UserOutlined, LockOutlined,CloseCircleFilled, LoadingOutlined,MailOutlined } from '@ant-design/icons';
import uuid from 'react-uuid'
import * as reactstrp from 'reactstrap';

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''
var sacco= ''

class GroupSignitoryDetails extends React.Component {

  state = {
    date:moment().format(dateFormat).toString(),
    accounttypes:[],
    idtypes:[],
    groupname:'' ,  
    District:'',
    subcounty:'',
    parish:'',
    village:'',
    account_type:'',
    noofmembers:0,
    nooffemales:0,
    noofmales:0,
    MembersFile:null,
    datarequested:true,
    datasubmittedsuccessfully:false,
    saccopackageobj:{},
    members:[],
    saccoboject:{},
    RegisteredPhoneNumber1:'',
    RegisteredPhoneNumber2:'',
    RegisteredPhoneNumber3:'',

    grouptype:'joint',
    signature_one:null,
    signature_two:null,
    signature_three:null,
    companyprofile:{},
    ugdistricts:[],
    signitoryform_visible:false,
    signitories:[],

    //signitory bio props
    sur_name:'' ,  
    middle_name:'',
    given_name:'',
    nationality:'',
    ID_type:'',
    ID_number:'',
    date_Of_Birth:'',
    Email_Address:'',
    P_O_Box:'',
    Area_Of_Residence:'',
    District:'',
    RegisteredPhoneNumber1:'',
    RegisteredPhoneNumber2:'',
    next_of_kin:'',
    Next_Of_Kin_Physical_Address:'',
    relationship:'',
    Photo:null,
    Signature:null,
    Thumbprint:null,

    Date_Of_Joining:'',
    account_type:'',
    date_of_appointment:'',
    status:'',
    staff_number:'',
    reference_one:'',
    Referee1PhoneNumber:'',
    datarequested:true,
    accountnumber:'',
    photofile:null,
    signaturefile:null,
    thumbprintfile:null,
    marital_status:'',
    own_residence:'',
    tribe:'',
    recommender:'',
    language1:'',
    language2:'',
    gender:'',
    any_other_account:'',
    other_account_number:'',
    other_bank:'',
    shares:0,
    title:'',
    datasubmittedsuccessfully:false,
    saccopackageobj:{},
    members:[],
    companyprofile:{},

    occupation:'',
    postaladdress:'',
    homecelllocaltion:'',
    cell:'',
    cellleader:'',
    nameofemployer_business:'',

    addressofemployer_business:'',
    refree_institutionalmember_name:'',
    refree_institutionalmember_contact:'',
    refree_cellleader_name:'',
    refree_cellleader_contact:'',
    next_of_kin_contact:'',

    child_account_school:'',
    child_account_school_class:'',

    membertype:'senior',
    pysical_form:null,
    saving_period:'',

    id_expirydate:'',
    ugdistricts:[],
    cellleader_contact:'',
    id_valid:false,
    age_valid:false,
    accounttypeobject:{},
    idtypeobject:{},
    reapersrefobj:{},
    reapersselectedref:'',
    signitory_objec:{},

  }


  componentDidMount(){
    this.setState({signitory_objec:{}})

    var signitoryid =this.props.match.params.signitoryid;

    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/group_signitories/${signitoryid}`)
    .then(res => {
        this.setState({
          signitory_objec:res.data
        })

        this.setState({sur_name:res.data.SurName})
        this.setState({middle_name:res.data.MiddleName})
        this.setState({given_name:res.data.OtherName})
        this.setState({nationality:res.data.Nationality})
        this.setState({ID_type:res.data.IDType})
        this.setState({ID_number:res.data.IDNumber})
        this.setState({date_Of_Birth:res.data.DateOfBirth})
        this.setState({Email_Address:res.data.EmailAddress})
        this.setState({P_O_Box:res.data.POBox})
        this.setState({AreaOfResidence:res.data.AreaOfResidence})
        this.setState({District:res.data.District})
        this.setState({RegisteredPhoneNumber1:res.data.RegisteredPhoneNumber1})
        this.setState({RegisteredPhoneNumber2:res.data.RegisteredPhoneNumber2})
        this.setState({next_of_kin:res.data.next_of_kin})
        this.setState({NextOfKinPhysicalAddress:res.data.NextOfKinPhysicalAddress})
        this.setState({relationship:res.data.relationship})

        this.setState({gender:res.data.gender})
        this.setState({marital_status:res.data.marital_status})
        this.setState({title:res.data.title})
        this.setState({own_residence:res.data.own_residence})
        this.setState({tribe:res.data.tribe})
        this.setState({recommender:res.data.recommender})
        this.setState({language1:res.data.language1})
        this.setState({language2:res.data.language2})
        this.setState({next_of_kin_contact:res.data.next_of_kin_contact})
        this.setState({occupation:res.data.occupation})
        this.setState({nameofemployer_business:res.data.nameofemployer_business})

        this.setState({addressofemployer_business:res.data.addressofemployer_business})
        this.setState({id_expirydate:res.data.id_expirydate})

    })


    axios.get(serverconfig.backendserverurl+`/api/accounttypes/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          accounttypes:res.data
        })
    })


    axios.get(serverconfig.backendserverurl+`/api/members/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          members:res.data
        })
    })

    axios.get(serverconfig.backendserverurl+`/api/saccos/${sacco}`)
    .then(res => {
        this.setState({
          saccoboject:res.data
        })

        //get the sacco package here
        axios.get(serverconfig.backendserverurl+`/api/packages/${res.data.package}`)
        .then(res => {
            this.setState({
              saccopackageobj:res.data
            })
        })
    })

    axios.get(serverconfig.backendserverurl+`/api/saccos/${sacco}`)
    .then(res => {
      this.setState({
        companyprofile:res.data
      })
    })

    axios.get(serverconfig.backendserverurl+`/api/idtypes/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          idtypes:res.data
        })

    })


    //get the sacco package here
    axios.get(serverconfig.backendserverurl+`/api/ugdistricts/?sacco=${sacco}`)
    .then(res => {
      this.setState({
        ugdistricts:res.data
      })
        this.setState({datarequested:false})
    })
}

//check empty object
 isEmpty=(obj)=> {
  for(var prop in obj) {
      if(obj.hasOwnProperty(prop))
          return false;
  }

  return true;
}



  //check agge validity
  checkagevalidity=(val)=>{
      var agelimit_months_cap=6570

      var dateMomentObjectone = moment(val, "DD/MM/YYYY"); // 1st argument - string, 2nd argument - format
      var dobobject = dateMomentObjectone.toDate();

      var datetoday=moment(this.state.date, "DD/MM/YYYY")

      var date1 = datetoday.toDate();
      var date2 = dobobject;

      var Difference_In_Time =  date1.getTime()-date2.getTime();
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

      console.log("diff days"+String(Difference_In_Days))

    if(Number(Difference_In_Days)>=Number(agelimit_months_cap)){
      this.setState({age_valid:true})
      console.log("Age is valid")
    }else{
      this.setState({age_valid:false})
      console.log("Age is not valid")
    }
  }

  //check agge validity
  checkidvalidity=(val)=>{
      var idvaliditydays=180

      var dateMomentObjectone = moment(val, "DD/MM/YYYY"); // 1st argument - string, 2nd argument - format
      var dobobject = dateMomentObjectone.toDate();

      var datetoday=moment(this.state.date, "DD/MM/YYYY")

      var date1 = datetoday.toDate();
      var date2 = dobobject;

      var Difference_In_Time =  date2.getTime()-date1.getTime();
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

      console.log("diff days"+String(Difference_In_Days))

    if(Number(Difference_In_Days)>=Number(idvaliditydays)){
      this.setState({id_valid:true})
      console.log("ID is valid")
    }else{
      this.setState({id_valid:false})
      console.log("ID is not valid")
    }
   }

    //submit button pressed
    handleSignitorytolist=(event) =>{
     this.handlesignitorySubmit()
    }

  //submit button pressed
  handlesignitorySubmit=() =>{
    var signitoryid =this.props.match.params.signitoryid;
  
        this.setState({datarequested:true})

        let form_data = new FormData();
        form_data.append('SurName', this.state.sur_name);
        form_data.append('MiddleName', this.state.middle_name);
        form_data.append('OtherName', this.state.given_name);
        form_data.append('Nationality', this.state.nationality);
        form_data.append('IDType', this.state.ID_type);
        form_data.append('IDNumber', this.state.ID_number);
        form_data.append('DateOfBirth', this.state.date_Of_Birth);
        form_data.append('EmailAddress', this.state.Email_Address);
        form_data.append('POBox', this.state.P_O_Box);
        form_data.append('AreaOfResidence', this.state.AreaOfResidence);
        form_data.append('District', this.state.District);
        form_data.append('RegisteredPhoneNumber1', this.state.RegisteredPhoneNumber1);
        form_data.append('RegisteredPhoneNumber2', this.state.RegisteredPhoneNumber2);
        form_data.append('next_of_kin', this.state.next_of_kin);
        form_data.append('NextOfKinPhysicalAddress', this.state.NextOfKinPhysicalAddress);
        form_data.append('relationship', this.state.relationship);
    
        form_data.append('gender', this.state.gender);
        form_data.append('marital_status',this.state.marital_status);
        form_data.append('title',this.state.title);
    
        form_data.append('own_residence',this.state.own_residence);
        form_data.append('tribe', this.state.tribe);
        form_data.append('recommender', this.state.recommender);
    
        form_data.append('language1', this.state.language1);
        form_data.append('language2', this.state.language2);
        form_data.append('sacco', sacco);
        form_data.append('next_of_kin_contact', this.state.next_of_kin_contact);
        form_data.append('occupation', this.state.occupation);
        form_data.append('nameofemployer_business', this.state.nameofemployer_business);
        form_data.append('addressofemployer_business',this.state.addressofemployer_business);
        form_data.append('id_expirydate', this.state.id_expirydate);
  
        this.state.Photo==null?
        console.log("No profile file")
        :
        form_data.append('Photo', this.state.Photo, this.state.Photo?this.state.Photo.name:"");
    
        this.state.Signature==null?
        console.log("No signature file")
        :
        form_data.append('Signature', this.state.Signature, this.state.Signature?this.state.Signature.name:"");
    
        this.state.Thumbprint==null?
        console.log("No thumb file")
        :
        form_data.append('thumbprint',this.state.Thumbprint, this.state.Thumbprint?this.state.Thumbprint.name:"");
    
        console.log(form_data)
    
        axios.put(serverconfig.backendserverurl+`/api/group_signitories/${signitoryid}/`, form_data, {
          headers: {
            'content-type': 'multipart/form-data'
          }
        })
        .then(res =>{
          console.log(res.data)   
          console.log("group signitory updated") 
          this.setState({datarequested:false})
          window.location.reload(false)

        })
        .catch(error => console.log(error))
  }


  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )
    }else{

      if (this.state.datasubmittedsuccessfully===true){
        return(
            <Card>
            <Result
            status="success"
            title="Successfully "
            subTitle="Group details will be further reviewed if need be"
            extra={[
              <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
            ]}
            />
            </Card>
        );

      }else{

        return(
              <div>
              <h4>Group Signitory Details.</h4>

              <Form
                    onFinish={(event) => this.handleSignitorytolist(event)}
                >

                 <h3>Bio Data Section</h3>
                  <FormItem label="Sur Name"        
                  >
                    <Input  placeholder="Put a name here." value={this.state.sur_name} onChange={(val)=>{this.setState({sur_name:val.target.value})}} />
                  </FormItem>
        
                  <FormItem label="Middle Name">
                    <Input  placeholder="Put a name here." value={this.state.middle_name} onChange={(val)=>{this.setState({middle_name:val.target.value})}} />
                  </FormItem>
        
                  <FormItem label="Given Name"
           
                  >
                    <Input  placeholder="Put a name here." value={this.state.given_name} onChange={(val)=>{this.setState({given_name:val.target.value})}} />
                  </FormItem>
      
                  <FormItem label="Gender"        
                  
                  >
                      <Select placeholder="Gender" style={{ width: 120 }} value={this.state.gender} onChange={(val)=>{this.setState({gender:val})}} >
                            <Option value="Male">Male</Option>
                            <Option value="Female">Female</Option>
                      </Select>
                  </FormItem>

                  <div style={{display:'flex',flexDirection:'row'}}>
                  <FormItem label="Date of Birth"
                      style={{display:'flex',margin:3}}        
                  >
                        <DatePicker onChange={(val,datstring)=>{this.setState({date_Of_Birth:datstring})
                      
                            this.checkagevalidity(datstring)

                      }} format={dateFormat} placeholder={this.state.date_Of_Birth} />
                  </FormItem>
                  {
                    this.state.age_valid===false?
                    <h6 style={{display:'flex',color:'red'}}>Member Age is not valid </h6>
                    :
                    <h6 style={{display:'flex',color:'green'}}>Member Age is valid </h6> 
                  }
                  </div>
      
        
                  <FormItem label="Nationality"          
                  >
                    <Input  placeholder="Nationality." value={this.state.nationality} onChange={(val)=>{this.setState({nationality:val.target.value})}} />
                  </FormItem>
        
        
                  <FormItem label="ID Type"         
              >
                  <Select placeholder="Id Type" style={{  }} value={this.state.ID_type} onChange={(val)=>{this.setState({ID_type:val})}} >
                    {this.state.idtypes.map(
                      (type)=>(
                        <Option value={type.id}>{type.id_type_name}</Option>
                      ))}
                  </Select>
                 </FormItem>
                  

                  <FormItem label="ID Number"              
                  
                  >
                  <Input  placeholder="ID Number." value={this.state.ID_number} onChange={(val)=>{this.setState({ID_number:val.target.value})}} />
                  </FormItem>
                  

                  <div style={{display:'flex',flexDirection:'row'}}>
                  <FormItem label="ID date of expiry"                 
                    style={{display:'flex',margin:3}} 
                    >
                        <DatePicker onChange={(date, dateString)=>{
                          this.setState({id_expirydate:dateString})
                          this.checkidvalidity(dateString)

                        }} format={dateFormat} placeholder={this.state.id_expirydate} />
                    </FormItem>
                    {
                    this.state.id_valid===false?
                    <h6 style={{display:'flex',color:'red'}}>Identity card invalid </h6>
                    :
                    <h6 style={{display:'flex',color:'green'}}>Identity card is valid </h6>
                    }
                  </div>
 

                  <FormItem label="Tribe">
                    <Input  placeholder="Tribe." value={this.state.tribe} onChange={(val)=>{this.setState({tribe:val.target.value})}} />
                  </FormItem>
      
                  <FormItem label="Language one">
                    <Input  placeholder="Language one." value={this.state.language1} onChange={(val)=>{this.setState({language1:val.target.value})}} />
                  </FormItem>
      
                  <FormItem label="Language two">
                    <Input  placeholder="Language two." value={this.state.language2} onChange={(val)=>{this.setState({language2:val.target.value})}} />
                  </FormItem>
      
                  <div style={{display:'flex',flexDirection:'row'}}>
                  <FormItem label="Member Photo" style={{marginRight:3}}>
                  <Input type="file" accept="image/png, image/jpeg" placeholder="Member Photo" 
                    onChange={(e) =>{
                      this.setState({ Photo: e.target.files[0]});
                      if(e.target.files[0]){
                        this.setState({
                          photofile: URL.createObjectURL(e.target.files[0])
                        })
                      }
                    }}/>
                  </FormItem> 
      
                  {this.state.Photo?
                  <Image
                  width={50}
                  height={50}
                  src={this.state.photofile}    
                  />
                  :
                  null
                  }
      
                  </div>
      
                    <div style={{display:'flex',flexDirection:'row'}}>
                    <FormItem label="Member Signature" style={{marginRight:3}}>
                      <Input type="file" accept="image/png, image/jpeg"
                        placeholder="Member Signature"  
                        onChange={
                          (e) =>{
                            this.setState({ Signature: e.target.files[0]});
                            if(e.target.files[0]){
                              this.setState({
                                signaturefile: URL.createObjectURL(e.target.files[0])
                              })
                            }
                          }
                        }/>

                    </FormItem> 
                    {this.state.Signature?
                      <Image
                      width={50}
                      height={50}
                      src={this.state.signaturefile}    
                      />
                      :
                      null
                      }
                    </div>

                    <div style={{display:'flex',flexDirection:'row'}}>
                    <FormItem label="Thumb Print" style={{marginRight:3}}>
                        <Input type="file" accept="image/png, image/jpeg" placeholder="Member Thumb"  onChange={(e)=>{
                          this.setState({ Thumbprint: e.target.files[0]});
                          if(e.target.files[0]){
                            this.setState({
                              thumbprintfile: URL.createObjectURL(e.target.files[0])
                            })
                          }
                        }}/>
                    </FormItem> 
                    {this.state.Thumbprint?
                      <Image
                      width={50}
                      height={50}
                      src={this.state.thumbprintfile}    
                      />
                      :
                      null
                      }
                    </div>



                  <h3>Employment Section</h3>
                  <FormItem label="Occupation"
           
                  >
                    <Input  placeholder="Occupation." value={this.state.occupation} onChange={(val)=>{this.setState({occupation:val.target.value})}} />
                  </FormItem>

                  <FormItem label="Name Of Employer/Business"
        
                  >
                    <Input  placeholder="Name." value={this.state.nameofemployer_business} onChange={(val)=>{this.setState({nameofemployer_business:val.target.value})}} />
                  </FormItem>

                  <FormItem label="Address of Employer/Business"
           
                  >
                    <Input  placeholder="Address." value={this.state.addressofemployer_business} onChange={(val)=>{this.setState({addressofemployer_business:val.target.value})}} />
                  </FormItem>


        
                  <h3>Contact Section</h3>
                  <FormItem label="Email Address"          
                  
                  >
                  <Input  type="mail" 
                          prefix={<MailOutlined className="site-form-item-icon" />} 
                          placeholder="Email"
                          value={this.state.Email_Address} 
                          onChange={(val)=>{this.setState({Email_Address:val.target.value})}} />
                  </FormItem>
        
                  <FormItem label="Postal Address (P O Box)">
                    <Input placeholder="box number." value={this.state.P_O_Box} onChange={(val)=>{this.setState({P_O_Box:val.target.value})}} />
                  </FormItem>


                  <FormItem label="Residential (Physical) Address"         
                  >
                    <Input placeholder="Area of residence." value={this.state.Area_Of_Residence} onChange={(val)=>{this.setState({Area_Of_Residence:val.target.value})}} />
                  </FormItem>
        
                  <FormItem label="District"
          
                  >
                    <Select placeholder="Ug District" style={{  }} value={this.state.District} onChange={(val)=>{this.setState({District:val})}} >
                      {this.state.ugdistricts.map(
                        (dist)=>(
                          <Option value={dist.district_name}>{dist.district_name} | {dist.region}</Option>
                        ))}
                    </Select>
                  </FormItem>
        
                <FormItem label= { <h6 style={{display:'flex'}} >* Phone One</h6>}                       
                >
                <PhoneInput
                      countrySelectProps={{ unicodeFlags: true }}
                      defaultCountry="UG"
                      placeholder="Enter phone number"
                      value={this.state.RegisteredPhoneNumber1} onChange={(val)=>{this.setState({RegisteredPhoneNumber1:val})}}/>
                  </FormItem> 
      
                  <FormItem label="Phone Two">
                  <PhoneInput
                      countrySelectProps={{ unicodeFlags: true }}
                      defaultCountry="UG"
                      placeholder="Enter phone number"
                      value={this.state.RegisteredPhoneNumber2} onChange={(val)=>{this.setState({RegisteredPhoneNumber2:val})}}/>
                  </FormItem>

               <h3>Personal Status Section</h3>
                  <FormItem label="Title"
          
                  
                  >
                      <Select placeholder="Title" style={{ width: 120 }} value={this.state.title} onChange={(val)=>{this.setState({title:val})}} >
                            <Option value="Mrs">Mrs</Option>
                            <Option value="Mr">Mr</Option>
                            <Option value="Miss">Miss</Option>
                      </Select>
                  </FormItem>
      
                  <FormItem label="Marital Status"
         
                  
                  >
                      <Select placeholder="Title" style={{ width: 120 }} value={this.state.marital_status} onChange={(val)=>{this.setState({marital_status:val})}} >
                            <Option value="Married">Married</Option>
                            <Option value="Single">Single</Option>
                      </Select>
                  </FormItem>
      
                  <FormItem label="Own Residence?"
        
                  
                  >
                      <Select placeholder="Title" style={{ width: 120 }} value={this.state.own_residence} onChange={(val)=>{this.setState({own_residence:val})}} >
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                      </Select>
                  </FormItem>
 
      
                   <h3>Refree / Next of Kin Section</h3>

                  <FormItem label="Next of kin">
                    <Input placeholder="Next of kin." value={this.state.next_of_kin} onChange={(val)=>{this.setState({next_of_kin:val.target.value})}} />
                  </FormItem>

                  <FormItem label="Next Of Kin Contact">
                   <PhoneInput
                        countrySelectProps={{ unicodeFlags: true }}
                        defaultCountry="UG"
                        placeholder="next of kin contact"
                        value={this.state.RegisteredPhoneNumber2} onChange={(val)=>{this.setState({next_of_kin_contact:val})}}/>
                   </FormItem>

        
                  <FormItem label="Next of kin Address">
                    <Input placeholder="Next of Kin Address." value={this.state.Next_Of_Kin_Physical_Address} onChange={(val)=>{this.setState({Next_Of_Kin_Physical_Address:val.target.value})}} />
                  </FormItem>
        
                  <FormItem label="Next Of Kin Relationship">
                    <Input placeholder="Next of kin Relationship." value={this.state.relationship} onChange={(val)=>{this.setState({relationship:val.target.value})}} />
                  </FormItem>
        
                  <FormItem >
                    <Button  type='primary' htmlType="submit">Update Signitory</Button>
                  </FormItem>

                </Form>

              </div>


            );
          }
    }
    
  }

}

export default GroupSignitoryDetails;

