import React from 'react';
import { message,Form, Input, Button,Select,Spin,Collapse,Popconfirm,Popover,Table, Card } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined,SearchOutlined, MailOutlined,UploadOutlined,FundViewOutlined,DeleteOutlined } from '@ant-design/icons';
import {
  Col,
  Row,
} from 'reactstrap';
import CurrencyFormat from 'react-currency-format';
import { Link } from 'react-router-dom';
import Highlighter from 'react-highlight-words';

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { Panel } = Collapse;

var token= ''
var sacco= ''

class SuperSaverSettingsForm extends React.Component {

  state = {
    datarequested:true,
    minimumbalance_ondeposit:0,
    minimumbalance_forinterest:0,
    percentagecharge_foruntimelywithdrawal:0,
    supersaversettings:[],
    account_type_name:'',
    withholdingtax_percentage_oninterest:0,

    minimum_ordinaryaccount_savingperiod:0,
    minimum_ordinaryaccount_shares:0,
    number_of_years:0,
    mandatory_interest:0,
    interest_limit:0,


  }

  componentDidMount(){

    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }


    axios.get(`${serverconfig.backendserverurl}/api/supersavertype/?sacco=${sacco}`)
    .then(res => {  
        this.setState({
          supersaversettings: res.data
        })
        this.setState({datarequested:false})
    })

}


  //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})

    let form_data = new FormData();
    
    form_data.append('account_type_name', this.state.account_type_name);
    form_data.append('minimumbalance_ondeposit', this.state.minimumbalance_ondeposit);
    form_data.append('minimumbalance_forinterest', this.state.minimumbalance_forinterest);
    form_data.append('percentagecharge_foruntimelywithdrawal', this.state.percentagecharge_foruntimelywithdrawal);
    form_data.append('sacco', sacco);
    form_data.append('withholdingtax_percentage_oninterest', this.state.withholdingtax_percentage_oninterest);

    form_data.append('minimum_ordinaryaccount_savingperiod', this.state.minimum_ordinaryaccount_savingperiod);
    form_data.append('minimum_ordinaryaccount_shares', this.state.minimum_ordinaryaccount_shares);
    form_data.append('number_of_years', this.state.number_of_years);
    form_data.append('mandatory_interest', this.state.mandatory_interest);
    form_data.append('interest_limit', this.state.interest_limit);

    
  
    axios.post(serverconfig.backendserverurl+'/api/supersavertype/', form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  .then(res =>         this.setState({datarequested:false})
,  window.location.reload(false)
  )
  .catch(error => console.log(error))

  }


getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};

  render(){

    const columns = [
      {
        title: 'Account Type Name',
        dataIndex: 'account_type_name',
        key: 'id',
        ...this.getColumnSearchProps('account_type_name'),
      },
      {
        title: 'Minimum Deposit Amount',
        dataIndex: 'minimumbalance_ondeposit',
        key: 'id',
      },
      {
        title: 'Minimum Balance For Interest',
        dataIndex: 'minimumbalance_forinterest',
        key: 'id',
      },
      {
        title: 'Percentage For Untimely Withdraw',
        dataIndex: 'percentagecharge_foruntimelywithdrawal',
        key: 'id',
      },
      {
        title: 'Withholding Tax Percentage on interest',
        dataIndex: 'withholdingtax_percentage_oninterest',
        key: 'id',
      },
      {
        title: 'Minimum saving period(months)',
        dataIndex: 'minimum_ordinaryaccount_savingperiod',
        key: 'id',
      },

      {
        title: 'Minimum ordinary account shares',
        dataIndex: 'minimum_ordinaryaccount_shares',
        key: 'id',
      },
      {
        title: 'Number of years',
        dataIndex: 'number_of_years',
        key: 'id',
      },

      {
        title: 'Action',
        dataIndex: 'id',
        key: 'id',
        render: text =>
        <p>
        <Popover content={<p>Click here to edit,view details</p>} title="Edit,View">
         <Link to={`/supersavesaccounttypes/${text}`}><FundViewOutlined style={{color:'blue'}}/></Link>
        </Popover>
        &nbsp;&nbsp;&nbsp;
        <Popover content={<p>Click here to delete client</p>} title="Delete">
        <Popconfirm title="Sure to delete?" onConfirm={() => {

          let form_data1 = new FormData();
          form_data1.append('itemid', text);
          form_data1.append('sacco', sacco);
          form_data1.append('table_model', "SuperSaverType");
        
          axios.post(`${serverconfig.backendserverurl}/customqueries/puttorecyclebin`, form_data1, {
            headers: {
              'content-type': 'multipart/form-data'
            }
            }).then(res =>{
              window.location.reload(false)
              message.info('Operation successful')
            }
            );
         }}>

          <DeleteOutlined style={{color:'red'}}/>
         </Popconfirm>
        </Popover>
        </p>,
        
      }
    ];


    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )
    }else{

      return (
        <div>

            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header="SUPER SAVE ACCOUNT TYPES" key="1">
            <Table 
             scroll={{ x: 1000 }}
             columns={columns}
             pagination={{showQuickJumper:true,showSizeChanger:true }}
             dataSource={this.state.supersaversettings} bordered/>
            </Panel>
            </Collapse>
            <br></br>

            <Card>
              <h3>Super Save Account Settings</h3>
            <Form
            onFinish={(event) => this.handleFormSubmit(event)} 
          >

        <FormItem label="Super Account Type Name"
              name="account_type_name"
              rules={[
                {
                  required: false,
                  message: 'Please enter account type',
                },
              ]}             
            >
              <Input name="account_type_name"  placeholder="Account Type Names." value={this.state.account_type_name} onChange={(val)=>{this.setState({account_type_name:val.target.value})}} />
         </FormItem>



            <FormItem label="Minimum Deposit to Supersaver"
              name="assetname"
              rules={[
                {
                  required: false,
                  message: 'Please enter Amount',
                },
              ]}             
            >
              <Input name="minimumbalance_ondeposit" type={'number'}  placeholder="Minimum deposit." value={this.state.minimumbalance_ondeposit} onChange={(val)=>{this.setState({minimumbalance_ondeposit:val.target.value})}} />
            </FormItem>
  
            <FormItem label="Minimum Account Balance For Interest"
              name="minimumbalance_forinterest"
              rules={[
                {
                  required: true,
                  message: 'Please enter value',
                },
              ]}           
            >
              <Input name="minimumbalance_forinterest" type={'number'}  placeholder="0.00" value={this.state.minimumbalance_forinterest} onChange={(val)=>{this.setState({minimumbalance_forinterest:val.target.value})}} />
            </FormItem>
  
            <FormItem label="Percentage Charge For untimely withdrawals"
              name="percentagecharge_foruntimelywithdrawal"
              rules={[
                {
                  required: true,
                  message: 'Please enter value',
                },
              ]}           
            >
              <Input name="percentagecharge_foruntimelywithdrawal" type={'number'}  placeholder="0.00" value={this.state.percentagecharge_foruntimelywithdrawal} onChange={(val)=>{this.setState({percentagecharge_foruntimelywithdrawal:val.target.value})}} />
            </FormItem>
  
            <FormItem label="Withholding tax percentage in interest"
              name="withholdingtax_percentage_oninterest"
              rules={[
                {
                  required: true,
                  message: 'Please enter value',
                },
              ]}           
            >
              <Input name="withholdingtax_percentage_oninterest" type={'number'}  placeholder="0" value={this.state.withholdingtax_percentage_oninterest} onChange={(val)=>{this.setState({withholdingtax_percentage_oninterest:val.target.value})}} />
            </FormItem>

            {/**more */}
            <FormItem label="Minimum ordinary account saving period"
              name="minimum_ordinaryaccount_savingperiod"
              rules={[
                {
                  required: true,
                  message: 'Please enter value',
                },
              ]}           
            >
              <Input name="minimum_ordinaryaccount_savingperiod" type={'number'}  placeholder="0" value={this.state.minimum_ordinaryaccount_savingperiod} onChange={(val)=>{this.setState({minimum_ordinaryaccount_savingperiod:val.target.value})}} />
            </FormItem>


            <FormItem label="Minimum ordinary account saving shares"
              name="minimum_ordinaryaccount_shares"
              rules={[
                {
                  required: true,
                  message: 'Please enter value',
                },
              ]}           
            >
              <Input name="minimum_ordinaryaccount_shares" type={'number'}  placeholder="0" value={this.state.minimum_ordinaryaccount_shares} onChange={(val)=>{this.setState({minimum_ordinaryaccount_shares:val.target.value})}} />
            </FormItem>

            <FormItem label="Number of years"
              name="number_of_years"
              rules={[
                {
                  required: true,
                  message: 'Please enter value',
                },
              ]}           
            >
              <Input name="number_of_years" type={'number'}  placeholder="0" value={this.state.number_of_years} onChange={(val)=>{this.setState({number_of_years:val.target.value})}} />
            </FormItem>


            <FormItem label="Mandatory Interest"
              name="mandatory_interest"
              rules={[
                {
                  required: true,
                  message: 'Please enter value',
                },
              ]}           
            >
              <Input name="mandatory_interest" type={'number'}  placeholder="0" value={this.state.mandatory_interest} onChange={(val)=>{this.setState({mandatory_interest:val.target.value})}} />
            </FormItem>

            <FormItem label="Interest limit"
              name="interest_limit"
              rules={[
                {
                  required: true,
                  message: 'Please enter value',
                },
              ]}           
            >
              <Input name="interest_limit" type={'number'}  placeholder="0" value={this.state.interest_limit} onChange={(val)=>{this.setState({interest_limit:val.target.value})}} />
            </FormItem>

            <FormItem>
              <Button  type="primary" htmlType="submit">Create</Button>
            </FormItem>
          </Form>

          </Card>
      </div>
      );
    }

  }

}


export default SuperSaverSettingsForm;

