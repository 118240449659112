import React from 'react';
import { Form, Input, Button,Select,Spin,Switch,Tooltip,Tabs,Card,Image, message,Result,Modal,Popconfirm,Popover,DatePicker, Divider } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined,DownloadOutlined, MailOutlined,UploadOutlined,CloseCircleFilled } from '@ant-design/icons';
import moment from 'moment';
import {
  Col,
  Row,
} from 'reactstrap';
import * as reactstrp from 'reactstrap';
import uuid from 'react-uuid'
import CurrencyFormat from 'react-currency-format';
import LoanApplicationDetails from '../containers/LoanApplicationDetails';
import MemberDetail_InTab from '../containers/MemberDetailView_InTab';
import FinancialStatementReport_Member from '../containers/FinancialStatementReportView_Member';
import LoanApplication_Securities from '../containers/LoanApplication_Securities';
import LoanApplication_FileAttachmentDetailView from '../containers/LoanApplication_FileAttachmentDetailView';
import ApplicationSchedule from '../containers/ApplicationSchedule';
import LoanDetails_Remarks from './LoanDetails_Remarks';
import LoanCertificate from './LoanCertificate';
import Item from 'antd/lib/list/Item';
var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const dateFormat = 'DD/MM/YYYY';
const { TextArea } = Input;
const { TabPane } = Tabs;

var sacco= ''
var username=''
var token= ''

class LoanDetailsTab extends React.Component {

  state = {
    loanapplications:[],
    date:moment().format(dateFormat).toString(),
    applicant_location:false,
    own_premises:false,
    legal_status_of_business:false,
    duration_in_business:false,
    monthly_net_profit:false,
    guarantors:false,
    consents:false,
    report_details:'',
    loan_checklist:null,
    collateral_one:'',
    collateral_one_value:0,
    collateral_one_image:null,
    datarequested:true,
    loanapplicationobject:{},
    datasubmittedsuccessfully:false,
    collateralformvisible:false,
    collaterallist:[],

    companyprofile:{},
    loanassessments:[],

    loanreviews:[],
    loanapprovals:[],
    concluded_applications:[],

    verified_loans:[],
    all_the_remks:[]

  }

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
   
    }else{
       sacco= ''
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }


    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
      this.setState({
        companyprofile: res.data
      })

    const loanapplicationID=this.props.loanapplicationID;
    axios.get(`${serverconfig.backendserverurl}/api/loanapplications/${loanapplicationID}`)
    .then(res => {  
        this.setState({loanapplicationobject: res.data})
        this.setState({datarequested:false})

    })

    ///////////////////////
    //get assessments
    //////////////////////
    var all_remarks=[]

    axios.get(`${serverconfig.backendserverurl}/api/loanassessments/?loan_assessed=${loanapplicationID}`)
    .then(res => {  
        this.setState({loanassessments: res.data})
        res.data.map((itm)=>{
          all_remarks.push(itm)
        })
    })

    axios.get(`${serverconfig.backendserverurl}/api/loanapprovals/?loan_id=${loanapplicationID}`)
    .then(res => {  
        this.setState({loanapprovals: res.data})
        res.data.map((itm)=>{
          all_remarks.push(itm)
        })
    })


    axios.get(`${serverconfig.backendserverurl}/api/concluded_applications/?application=${loanapplicationID}`)
    .then(res => {  
        this.setState({concluded_applications: res.data})
        res.data.map((itm)=>{
          all_remarks.push(itm)
        })
        //this.setState({datarequested:false})
    })


    axios.get(`${serverconfig.backendserverurl}/api/verified_loans/?loan_verified=${loanapplicationID}`)
    .then(res => {  
        this.setState({verified_loans: res.data})
        res.data.map((itm)=>{
          all_remarks.push(itm)
        })
        this.setState({datarequested:false})
    })

    //set remarks
    this.setState({all_the_remks:all_remarks})
    
  })

 
  }



  render(){



    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{

        return (
          <div style={{overflowX:'auto'}}>

             <Tabs defaultActiveKey="1" >
             
              <TabPane tab="Details" key="1">
                <LoanApplicationDetails 
                  loan_application_id={this.props.loanapplicationID}
                />
              </TabPane>


              <TabPane tab="Schedule" key="002">
                <ApplicationSchedule 
                application_id={this.props.loanapplicationID} 
                application_obj={this.state.loanapplicationobject}
                />
              </TabPane>


              <TabPane tab="Securities" key="2">
                <LoanApplication_Securities
                 application_obj={this.state.loanapplicationobject}
                />
              </TabPane>


              <TabPane  tab={this.state.loanapplicationobject.physical_form!=null?"Attachments ( 1 )":"Attachments ( 0 )"} key="3">
                {
                  this.state.loanapplicationobject.physical_form?
                  <Popover content={<p>Click here to preview application form</p>} 
                  title="Preview attachment">
                  <a href={this.state.loanapplicationobject.physical_form}>
                    <Button icon={<DownloadOutlined style={{color:'green',fontSize: '20px'}}/>}>Preview</Button>
                    </a> 
                  </Popover>
                  :
                  <span>No Attachment</span>
                }
                <Divider></Divider>
                <LoanApplication_FileAttachmentDetailView
                loanapplicationID={this.props.loanapplicationID}
                />

              </TabPane>


              <TabPane tab="Member BioData" key="4">
                  <MemberDetail_InTab memberID={this.state.loanapplicationobject.account} />
              </TabPane>

              <TabPane tab="Member Statement" key="5">
                 <FinancialStatementReport_Member memberID={this.state.loanapplicationobject.account} />
              </TabPane>

              <TabPane tab={"Remarks ( "+this.state.all_the_remks.length+" )"} key="6">
                <LoanDetails_Remarks
                loanapplicationID={this.props.loanapplicationID}
                />
              </TabPane>


              <TabPane tab={"Certificate"} key="7">
                <LoanCertificate
                loanapplicationID={this.props.loanapplicationID}
                />
              </TabPane>


            </Tabs>

          </div>
        );

      
    }

  }

}


export default LoanDetailsTab;



