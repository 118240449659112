import React from 'react';
import { Form, Input, Button,Select,Spin,Tabs,Popconfirm,Result,message,Tooltip,Card,Switch,InputNumber,Image,DatePicker,Divider,Table } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import moment from 'moment';
import { UserOutlined, LockOutlined, LoadingOutlined,EyeFilled, MailOutlined,UploadOutlined } from '@ant-design/icons';
import {
  Col,
  Row,
} from 'reactstrap';
import * as reactstrp from 'reactstrap';
import CurrencyFormat from 'react-currency-format';

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const dateFormat = 'DD/MM/YYYY';
const { TextArea } = Input;
const { TabPane } = Tabs;


var sacco= ''
var username=''
var token= ''

class LoanAppovalForm extends React.Component {

  state = {
    date:moment().format(dateFormat).toString(),
    approved_amount:0,
    amount_appliedfor:0,

    loan_pass:false,
    approval_report:'',
    loanapplication:{},
    loanassessment:{},
    datasubmittedsuccessfully:false,
    datarequested:true,
    collaterallist:[],
    reasonforrejection:'',
    sendrejectionsms:false,
    loantypeobject:{},
    approved_period:0,
    companyprofile:{},


    loantopup_id:'',
    previous_loanbalance:0,


    loansecurites: [],
    security_percentages:{},
    security_totals:{},


  }

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }


    const loanapplicationID=this.props.loanapplicationID;

    axios.get(`${serverconfig.backendserverurl}/api/loanapplications/${loanapplicationID}`)
    .then(res => {  
        this.setState({loanapplication: res.data})
        this.setState({amount_appliedfor:res.data.loan_amount})
        this.setState({approved_period:res.data.approved_period})


        //CHECK IF TS A LOAN TOP APP
        if (res.data.loan_topup===true){

          //query for topup loan balance
          //query the latest balance
          var form_data1 = new FormData();
          form_data1.append('loan_id', res.data.loantopup_id);
          form_data1.append('date', this.state.date);

          axios.post(serverconfig.backendserverurl+'/customqueries/queryfor_loanbalance', form_data1, {
            headers: {
              'content-type': 'multipart/form-data'
            }
          })
          .then(res => {
            this.setState({previous_loanbalance:Number(res.data.runningint_bal)+Number(res.data.running_principle)})
          })
          .catch(error => console.log(error))


          //Get attached securities
          let form_data = new FormData();
          form_data.append('loan_id', res.data.loantopup_id);

          //Now submit sale data to database
          axios.post(serverconfig.backendserverurl+'/customqueries/getloansecurities', form_data,{
            headers: {
              'content-type': 'multipart/form-data'
            }})
          .then(res =>{
            this.setState({loansecurites:JSON.parse(res.data.report)})

            this.setState({security_percentages:res.data.percentages})
            this.setState({security_totals:res.data.totals})

            
          }).catch(error => console.log(error)) 

          //update now
          this.setState({approved_amount:res.data.loan_topupamount})

        }else{
          //update now
          this.setState({approved_amount:res.data.loan_amount})
        }



    })

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })


        this.setState({datarequested:false})

    })



  }




  //submit button pressed
  handleFormSubmit=(event) =>{
      const loanapplicationID=this.props.loanapplicationID;

      this.setState({datarequested:true})
      
      let form_data = new FormData();
      form_data.append('date', this.state.date);
      form_data.append('loan_id', loanapplicationID);
      form_data.append('approved_amount', this.state.approved_amount);
      form_data.append('amount_appliedfor', this.state.loanapplication.loan_amount);
      form_data.append('approval_report', this.state.approval_report);
      form_data.append('user',username );
      form_data.append('sacco', sacco);
      form_data.append('approved_period', this.state.approved_period);

      axios.post(serverconfig.backendserverurl+'/customqueries/create_loan_approval', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
    .then(res =>  {

      message.info(res.data.message)
      this.setState({datarequested:false})
      this.setState({datasubmittedsuccessfully:true})

    })
    .catch(error => console.log(error))
    

  }

 
  handleapproved_amountChange= (e) => this.setState({ approved_amount: e.target.value});
  handleloan_passChange= (e) => this.setState({ loan_pass: e});
  handleapproval_reportChange= (e) => this.setState({ approval_report: e.target.value});



  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{

      if (this.state.datasubmittedsuccessfully===true){
        return(
          <Row >
            <Col xs="12" sm="6" lg="6">
            <Card>
            <Result
            status="success"
            title="Successfully Done loan approval "
            subTitle="If the loan is passed it will be forwarded for issuance or disburshment"
            extra={[
              <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
            ]}
            />

          </Card>
          </Col>
          </Row>
        );

      }else{


        return (

      <div style={{overflowX:'auto'}}>
    
        <Card>
          
          {
            this.state.loanapplication.loan_status==="approval"?
            <Form
              onFinish={(event) => this.handleFormSubmit(event)} 
            >

            {
            this.state.companyprofile.automated_transaction_date===false?
            <FormItem label={"Date ( "+this.state.date+" )"}
            >
                <DatePicker 
                  onChange={(date, dateString) => this.setState({ date: dateString})} 
                  format={dateFormat} />
            </FormItem>                 
            :
            null
            }
  

            <FormItem label="Final Approval remarks"
                  name="approval_report"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter remarks',
                    },
                  ]}            
                >
                  <TextArea
                    value={this.state.approval_report}
                    onChange={this.handleapproval_reportChange}
                    placeholder="Approver remarks"
                    autoSize={{ minRows: 2, maxRows: 10 }}
                  />
              
            </FormItem>

            <Divider></Divider>
                          
            <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
            <FormItem>
              
              
            <Tooltip title="Click here to pass application" placement="top">
              <Button  type="primary" htmlType="submit"> Approve for disbursement </Button>
            </Tooltip>

            </FormItem>

            <FormItem>

            <Tooltip title="Click here to reverse application" placement="top">
              <Popconfirm 
                title="Sure to reverse?" 
                onConfirm={() => {
                  if (this.state.report_details!=""){
                    const loanapplicationID=this.props.loanapplicationID;

                    let form_data = new FormData();
                    form_data.append('date', this.state.date);
                    form_data.append('loan_id', loanapplicationID);
                    form_data.append('approved_amount', this.state.approved_amount);
                    form_data.append('amount_appliedfor', this.state.loanapplication.loan_amount);
                    form_data.append('approval_report', this.state.approval_report);
                    form_data.append('user',username );
                    form_data.append('sacco', sacco);
                    form_data.append('approved_period', this.state.approved_period);

                    axios.post(serverconfig.backendserverurl+'/customqueries/reverse_loanto_review', form_data, {
                      headers: {
                        'content-type': 'multipart/form-data'
                      }
                    })
                  .then(res =>  {
                    message.info(res.data.message)
                    window.location.reload(false)
                
                  }).catch(error => console.log(error))


                  }else{
                    message.error("Please enter remarks")
                  }

                }}>
                  <Button type='default' style={{color:'red'}}>Reverse to Review</Button>

              </Popconfirm>
              </Tooltip>
            </FormItem>

            </div>

            </Form>
            :
            null

          }
          
          </Card>
  
         </div>

        );

      }     
    }
    
  }

}


export default LoanAppovalForm;



