import React from 'react';
import { Form, Input, Button,Spin,Select } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { Option } = Select;

var token= ''
var sacco= ''


class CompanyAccountForm extends React.Component {

  state = {
    account_code:'' ,  
    account_name:'',
    datarequested:true,
    chartofaccounts_subcategories:[],
    chatofaccounts_subcategory:'',
    currency:'',
    currencies: [],

  }

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/chartofaccounts_subcategories/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          chartofaccounts_subcategories:res.data
        })
        this.setState({datarequested:false})
    })

    axios.get(serverconfig.backendserverurl+`/api/currencies/?sacco=${sacco}`)
    .then(res => {
        this.setState({
            currencies:res.data
        })

    })

  }


  //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})

    let form_data = new FormData();
    form_data.append('account_code', this.state.account_code);
    form_data.append('account_name', this.state.account_name);
    form_data.append('sacco', sacco);
    form_data.append('chatofaccounts_subcategory', this.state.chatofaccounts_subcategory);
    form_data.append('currency', this.state.currency);

      axios.post(serverconfig.backendserverurl+'/api/companyaccounts/', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
    .then(res =>     this.setState({datarequested:false})
,    window.location.reload(false)
    )
    .catch(error => console.log(error))

  }

  render(){

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return (
        <div>
    <Form
        onFinish={(event) => this.handleFormSubmit(event)}
    >


      <FormItem label="Account Name"
        name="account_name"
        rules={[
          {
            required: true,
            message: 'Please input name',
          },
        ]}
      >
        <Input name="account_name"  placeholder="Put name here." value={this.state.account_name} onChange={(val)=>{this.setState({account_name:val.target.value})}} />
      </FormItem>

      <FormItem label="Account Code"
        name="account_code"
        rules={[
          {
            required: true,
            message: 'Please input desc',
          },
        ]}    
      >
        <Input name="account_code"  placeholder="Put code here" value={this.state.account_code} onChange={(val)=>{this.setState({account_code:val.target.value})}} />
      </FormItem>

     
      <FormItem label="Chatofaccounts subcategory"
          name="chatofaccounts_subcategory"
          rules={[
            {
              required: true,
              message: 'Please select chatofaccounts_subcategory',
            },
          ]}           
        >
            <Select  name="chatofaccounts_subcategory"
             placeholder="chatofaccounts_subcategory" 
             value={this.state.chatofaccounts_subcategory} 
             onChange={(val)=>{this.setState({chatofaccounts_subcategory:val})}} 
             showSearch
             optionFilterProp="children"

             >
              {this.state.chartofaccounts_subcategories.map(
                (ty_pe)=>(
                  <Option value={ty_pe.id}>{ty_pe.category_name}</Option>
                ))}
            </Select>
        </FormItem>


        <FormItem label="Currency"
          name="currency"
          rules={[
            {
              required: true,
              message: 'Please select currency',
            },
          ]}           
        >
            <Select  name="currency" placeholder="Currency"  value={this.state.currency} onChange={(val)=>{this.setState({currency:val})}} >
       
             {
              this.state.currencies.map((item)=>{
                return (<Option  value={item.currency_name}>{item.currency_name}</Option>)
              })
             }

            </Select>
        </FormItem>

     
      <FormItem>
        <Button  type="primary" htmlType="submit">Add Account</Button>
      </FormItem>
    </Form>
        </div>
      );

    }
  }

}


export default CompanyAccountForm;



