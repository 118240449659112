import React from 'react';
import axios from 'axios';
import { Card,Avatar} from 'antd';
import { Form, Input, Button,Select,Spin } from 'antd';
import {
    Col,
    Row,
  } from 'reactstrap';
import { UserOutlined,LoadingOutlined } from '@ant-design/icons';
import * as serverconfig from '../serverconn'
const FormItem=Form.Item;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''

class SuperSaverTypeDetail extends React.Component{

    state={
        type: {},
        account_type_name:'',
        minimum_deposit:0 ,  
        minimumbalance_ondeposit:0,
        minimumbalance_forinterest:0,
        percentagecharge_foruntimelywithdrawal:0,
        datarequested:true,
        withholdingtax_percentage_oninterest:0,

        minimum_ordinaryaccount_savingperiod:0,
        minimum_ordinaryaccount_shares:0,
        number_of_years:0,

        mandatory_interest:0,
        interest_limit:0,

    }


    componentDidMount(){

      if(localStorage.getItem("token")){
         token= localStorage.getItem("token")
      }else{
         token= ''
      }
      
        axios.defaults.headers={
          "Content-Type":"application/json",
          Authorization:`Token ${token}`
        }

        const typeID=this.props.match.params.typeID;
        axios.get(`${serverconfig.backendserverurl}/api/supersavertype/${typeID}`)
        .then(res => {  
            this.setState({type: res.data})
            this.setState({account_type_name: res.data.account_type_name})

            this.setState({minimumbalance_ondeposit: res.data.minimumbalance_ondeposit})
            this.setState({minimumbalance_forinterest: res.data.minimumbalance_forinterest})
            this.setState({percentagecharge_foruntimelywithdrawal: res.data.percentagecharge_foruntimelywithdrawal})
            this.setState({withholdingtax_percentage_oninterest: res.data.withholdingtax_percentage_oninterest})
   
            this.setState({minimum_ordinaryaccount_savingperiod: res.data.minimum_ordinaryaccount_savingperiod})
            this.setState({minimum_ordinaryaccount_shares: res.data.minimum_ordinaryaccount_shares})
            this.setState({number_of_years: res.data.number_of_years})

            this.setState({interest_limit: res.data.interest_limit})
            this.setState({mandatory_interest: res.data.mandatory_interest})

            this.setState({datarequested:false})
        })
    }

    //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})

    const typeID=this.props.match.params.typeID;

    let form_data = new FormData();
    form_data.append('account_type_name', this.state.account_type_name);
    form_data.append('minimumbalance_ondeposit', this.state.minimumbalance_ondeposit);
    form_data.append('minimumbalance_forinterest', this.state.minimumbalance_forinterest);
    form_data.append('percentagecharge_foruntimelywithdrawal', this.state.percentagecharge_foruntimelywithdrawal);
    form_data.append('withholdingtax_percentage_oninterest', this.state.withholdingtax_percentage_oninterest);

    form_data.append('minimum_ordinaryaccount_savingperiod', this.state.minimum_ordinaryaccount_savingperiod);
    form_data.append('minimum_ordinaryaccount_shares', this.state.minimum_ordinaryaccount_shares);
    form_data.append('number_of_years', this.state.number_of_years);

    form_data.append('mandatory_interest', this.state.mandatory_interest);
    form_data.append('interest_limit', this.state.interest_limit);

    axios.put(serverconfig.backendserverurl+`/api/supersavertype/${typeID}/`, form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  .then(res =>this.setState({datarequested:false}),window.location.reload(false)

  )
  .catch(error => console.log(error))

  }



    render(){

        if(this.state.datarequested===true){
            return(
              <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin indicator={antIcon} />
              </div>
            )
      
          }else{

            return(
                <div>
                    
                    <Row>
                    <Col xs="12" sm="6" lg="6">
    
                    <Card 
                      style={{padding:10}}
                      hoverable>
                        <h4>Super Saver Account Type Update</h4>
                        <Form
                            onFinish={(event) => this.handleFormSubmit(event)} 
                            >
                            <FormItem label="Super Account Type Name"
                                >
                                  <Input name="account_type_name"  placeholder="Account Type Names." value={this.state.account_type_name} onChange={(val)=>{this.setState({account_type_name:val.target.value})}} />
                            </FormItem>

                            <FormItem label="Minimum Deposit to Supersaver"
                            >
                              <Input name="minimumbalance_ondeposit" type={'number'}  placeholder="Minimum deposit." value={this.state.minimumbalance_ondeposit} onChange={(val)=>{this.setState({minimumbalance_ondeposit:val.target.value})}} />
                            </FormItem>
                  
                            <FormItem label="Minimum Account Balance For Interest"
                                    
                            >
                              <Input name="minimumbalance_forinterest" type={'number'}  placeholder="0.00" value={this.state.minimumbalance_forinterest} onChange={(val)=>{this.setState({minimumbalance_forinterest:val.target.value})}} />
                            </FormItem>
                  
                            <FormItem label="Percentage Charge For untimely withdrawals"
                                        
                            >
                              <Input name="percentagecharge_foruntimelywithdrawal" type={'number'}  placeholder="0.00" value={this.state.percentagecharge_foruntimelywithdrawal} onChange={(val)=>{this.setState({percentagecharge_foruntimelywithdrawal:val.target.value})}} />
                            </FormItem>

                            <FormItem label="Withholding Tax Percentage on Interest" 
                            >
                              <Input name="withholdingtax_percentage_oninterest" type={'number'}  placeholder="0.00" value={this.state.withholdingtax_percentage_oninterest} onChange={(val)=>{this.setState({withholdingtax_percentage_oninterest:val.target.value})}} />
                            </FormItem>

                            {/**more */}
                            <FormItem label="Minimum ordinary account saving period"
                        
                            >
                              <Input name="minimum_ordinaryaccount_savingperiod" type={'number'}  placeholder="0" value={this.state.minimum_ordinaryaccount_savingperiod} onChange={(val)=>{this.setState({minimum_ordinaryaccount_savingperiod:val.target.value})}} />
                            </FormItem>


                            <FormItem label="Minimum ordinary account saving shares"
                          
                            >
                              <Input name="minimum_ordinaryaccount_shares" type={'number'}  placeholder="0" value={this.state.minimum_ordinaryaccount_shares} onChange={(val)=>{this.setState({minimum_ordinaryaccount_shares:val.target.value})}} />
                            </FormItem>

                            <FormItem label="Number of years"
                          
                            >
                              <Input name="number_of_years" type={'number'}  placeholder="0" value={this.state.number_of_years} onChange={(val)=>{this.setState({number_of_years:val.target.value})}} />
                            </FormItem>

                            <FormItem label="Mandatory Interest"        
                            >
                              <Input name="mandatory_interest" type={'number'}  placeholder="0" value={this.state.mandatory_interest} onChange={(val)=>{this.setState({mandatory_interest:val.target.value})}} />
                            </FormItem>

                            <FormItem label="Interest limit"          
                            >
                              <Input name="interest_limit" type={'number'}  placeholder="0" value={this.state.interest_limit} onChange={(val)=>{this.setState({interest_limit:val.target.value})}} />
                            </FormItem>

                            <FormItem>
                                <Button  type="primary" htmlType="submit">Update</Button>
                            </FormItem>
                            </Form>
                          
                          </Card>
                      
                    </Col>
                  
                   </Row>
                </div>
            )

          }

        
    }
}

export default SuperSaverTypeDetail; 