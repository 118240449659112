import React from 'react';
import MemberForm from '../components/MemberForm'
import ActivateMemberForm from '../components/ActivateMemberForm'

import axios from 'axios'
import { Table,DatePicker,Form, Input, Button,Collapse,Typography,Space,Divider,Popover,Popconfirm,message,Descriptions,Modal,Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DeleteOutlined,PrinterOutlined,DownloadOutlined,FundViewOutlined,EditOutlined,WalletFilled,LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import ReactToPrint from 'react-to-print';
import * as reactstrp from 'reactstrap';
import moment from 'moment';

import {
  Col,
  Row,
} from 'reactstrap';
import ReactExport from "@ibrahimrahmani/react-export-excel";
import '../tablestyle.css'; // Create this CSS file for custom styles


var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { Title } = Typography;
const { RangePicker } = DatePicker;
const FormItem=Form.Item;
const dateFormat = 'DD/MM/YYYY';
const monthformat = 'MM/YYYY';

function callback(key) {
  console.log(key);
}

var token= ''
var sacco= ''
var bizuserid= ''

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class ReportToPrint extends React.Component {

  state = {
    companyprofile:{},
  };

  componentDidMount(){

    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
       bizuserid= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })


  }

  render() {
    return (
      <div style={{padding:20}}>
        <Row> 
              <Col xs="2" sm="2" lg="2">
              <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.sacco_logo} />
            
                </Col>
                <Col xs="6" sm="6" lg="6">
                <h3>{this.state.companyprofile.sacco_name}</h3>
                <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                {
                this.state.companyprofile.RegisteredPhoneNumber2==""?
                "":","+this.state.companyprofile.RegisteredPhoneNumber2
                }
                {
                this.state.companyprofile.RegisteredPhoneNumber3==""?
                "":","+this.state.companyprofile.RegisteredPhoneNumber3
                }
                </h6>               
                <h5>Email: {this.state.companyprofile.company_email}</h5>
                <h5>Wesite: {this.state.companyprofile.website}</h5>
                <h5>{this.state.companyprofile.box_number}</h5>
              
                </Col>

       </Row>

           <h3 style={{display: 'flex',justifyContent:'center', alignItems:'center',fontWeight:'bolder'}}>Member Deposits Transactions Report For {this.props.period}</h3>

           <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>Account Number</th>
                          <th>Account Name</th>
                          <th>Total Savings</th>
                          <th>Total SuperSave</th>
                          <th>Total Shares</th>
                          <th>Total Teeba goolo</th>
                        </tr>
                      </thead>
                      <tbody>
                      {this.props.alldeposits.map(
                        (item)=>(
                          <tr>
                          <td>{item.AccountNumber}</td>
                          <td>{item.FullName}</td>
                          <td className="align-right">{<CurrencyFormat value={item.totalsavings} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td className="align-right">{<CurrencyFormat value={item.totalsupersave} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td className="align-right">{<CurrencyFormat value={item.totalshares} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td className="align-right">{<CurrencyFormat value={item.totalteebagoolo} displayType={'text'} thousandSeparator={true}/>}</td>

                          </tr>
                        ))}
                        <tr>
                        <th style={{fontWeight:'bolder'}}>Total</th>
                        <th></th>
                        <th className="align-right">{this.props.totalsavings}</th>
                        <th className="align-right">{this.props.totalsuper}</th>
                        <th className="align-right">{this.props.totalshares}</th>
                        <th className="align-right">{this.props.totalteeba}</th>
                        </tr>

                    </tbody>
                </reactstrp.Table>   

      </div>
    );
  }
}


class MemberDepositsReport extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    members: [],
    viewpaymentmodal:false,
    expensedetails:{},
    companyprofile:{},
    userprofile:{},
    datarequested:true,
    memberactivationformvisible:false,
    selectedmemberid:'',
    alldeposits:[],
    period:moment().format(monthformat).toString(),
    action:'getcurrentmonthtransactions'

  };

  componentDidMount(){
      if(localStorage.getItem("sacco")){
        sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
        bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    }else{
        sacco= ''
        bizuserid= ''
    }
    
    if(localStorage.getItem("token")){
        token= localStorage.getItem("token")
    }else{
        token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })
    })

    let form_data = new FormData();
    form_data.append('sacco', sacco);
    form_data.append('action', this.state.action);
    //Now submit sale data to database
    axios.post(serverconfig.backendserverurl+'/customqueries/getdepositsreport', form_data,{
      headers: {
        'content-type': 'multipart/form-data'
      }})
    .then(res =>{
      this.setState({datarequested:false})
      this.setState({alldeposits:JSON.parse(res.data.alldeposits)})
    }).catch(error => console.log(error))  
  }

  //calculate total savings
  calculateTotalSavings=()=>{
    var Total=0
    this.state.alldeposits.map(
      (item)=>{
        Total+=Number(item.totalsavings)
      })

    return Total.toFixed(2);

  }

  calculateTotalSuper=()=>{
    var Total=0
    this.state.alldeposits.map(
      (item)=>{
        Total+=Number(item.totalsupersave)
      })

    return Total.toFixed(2);

  }

  calculateTotalShares=()=>{
    var Total=0
    this.state.alldeposits.map(
      (item)=>{
        Total+=Number(item.totalshares)
      })

    return Total.toFixed(2);

  }

  calculateTotalTeeba=()=>{
    var Total=0
    this.state.alldeposits.map(
      (item)=>{
        Total+=Number(item.totalteebagoolo)
      })

    return Total.toFixed(2);

  }

getfirstdaymonth=()=>{
  
// Get the current date
const currentDate = new Date();

// Get the first day of the current month
const firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

// Get the last day of the current month
const lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

// Format the dates as "DD/mm/YYYY"
const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
const firstDayFormatted = firstDay.toLocaleDateString('en-GB', options);
const lastDayFormatted = lastDay.toLocaleDateString('en-GB', options);

console.log("First day of the current month:", firstDayFormatted);
console.log("Last day of the current month:", lastDayFormatted);
return firstDayFormatted;

}

getlastdaymonth=()=>{
  
  // Get the current date
  const currentDate = new Date();
  
  // Get the first day of the current month
  const firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  
  // Get the last day of the current month
  const lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
  
  // Format the dates as "DD/mm/YYYY"
  const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
  const firstDayFormatted = firstDay.toLocaleDateString('en-GB', options);
  const lastDayFormatted = lastDay.toLocaleDateString('en-GB', options);
  
  console.log("First day of the current month:", firstDayFormatted);
  console.log("Last day of the current month:", lastDayFormatted);
  return lastDayFormatted;
  
  }


getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

searchSales=()=>{
  this.setState({period:" "+this.state.dateone+" To "+this.state.datetwo})

  let form_data = new FormData();
  form_data.append('dateone', this.state.dateone);
  form_data.append('datetwo', this.state.datetwo);
  form_data.append('action', this.state.action);
  form_data.append('sacco', sacco);
  
  if(this.state.dateone===''||this.state.datetwo===''){
    alert("Please select dates missing")
  }else{
      this.setState({datarequested:true})

      //Now submit sale data to database
      axios.post(serverconfig.backendserverurl+'/customqueries/getdepositsreport', form_data,{
        headers: {
          'content-type': 'multipart/form-data'
        }})
      .then(res =>{
        this.setState({datarequested:false})
        this.setState({alldeposits:JSON.parse(res.data.alldeposits)})
      }).catch(error => console.log(error))   
   }

  }

  handledatechange= (date, dateString) =>{
    this.setState({ dateone: dateString[0]});
    this.setState({ datetwo: dateString[1]});
  } 

  render() {
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return(
        <div>
            <div style={{display: "none"}}>
               &nbsp;&nbsp;
              <ReportToPrint
              totalsavings={this.calculateTotalSavings()} 
              totalshares={this.calculateTotalShares()} 
              totalsuper={this.calculateTotalSuper()} 
              totalteeba={this.calculateTotalTeeba()} 
              alldeposits={this.state.alldeposits}
              period={this.state.period}

              ref={el => (this.componentRef = el)} /> 
             </div>


            <Collapse defaultActiveKey={['1']} onChange={this.callback}>

            <Panel header={<h4 style={{fontWeight:'bolder'}}> Member Deposits Transactions Report For {this.state.period}</h4>} key="1">
           
            <div style={{display:'flex',justifyContent:'flex-start',alignSelf:'flex-start',alignItems:'flex-start'}}>
                <ReactToPrint
                  trigger={() => 
                  <Button type="primary"  shape="round" icon={<PrinterOutlined />} size={this.state.size}>
                  Print
                  </Button>
                  }
                  content={() => this.componentRef}
                  />

                &nbsp;&nbsp;&nbsp;

                <ExcelFile filename={this.state.action==="getcurrentmonthtransactions"? "Pisys - Member deposits report from "+this.getfirstdaymonth()+" to "+this.getlastdaymonth() : "Pisys - Member deposits report from "+this.state.dateone+" to "+this.state.datetwo} element={<Button type='primary' icon={<DownloadOutlined/>}>Download Excel report</Button>}>
                  <ExcelSheet data={this.state.alldeposits} name={"Member Deposits"}>
                      <ExcelColumn label="Acc No" value="AccountNumber"/>
                      <ExcelColumn label="Acc Name" value="FullName"/>
                      <ExcelColumn label="Total Savings" value="totalsavings"/>
                      <ExcelColumn label="Total Supersave" value="totalsupersave"/>
                      <ExcelColumn label="Total Shares" value="totalshares"/>
                      <ExcelColumn label="Total Teeba Goolo" value="totalteebagoolo"/>
                  </ExcelSheet>
               </ExcelFile>

               &nbsp;&nbsp;&nbsp;

               <Form  layout="inline" >
                  <FormItem label="Select period (Date Range)">
                      <RangePicker defaultValue={moment()} onChange={this.handledatechange} format={dateFormat} />
                  </FormItem>

                  <FormItem>
                  <Button onClick={()=>{
                      this.setState({action:"getperiodtransactions"})
                      this.searchSales()
                  }
                    

                    } type="primary" htmlType="button">Search</Button>
                  </FormItem> 
             </Form>

              </div>

              <br></br>

               <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>Account Number</th>
                          <th>Account Name</th>
                          <th>Total Savings</th>
                          <th>Total SuperSave</th>
                          <th>Total Shares</th>
                          <th>Total Teeba goolo</th>
                        </tr>
                      </thead>
                      <tbody>
                      {this.state.alldeposits.map(
                        (item)=>(
                          <tr>
                          <td>{item.AccountNumber}</td>
                          <td>{item.FullName}</td>
                          <td className="align-right">{<CurrencyFormat value={item.totalsavings} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td className="align-right">{<CurrencyFormat value={item.totalsupersave} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td className="align-right">{<CurrencyFormat value={item.totalshares} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td className="align-right">{<CurrencyFormat value={item.totalteebagoolo} displayType={'text'} thousandSeparator={true}/>}</td>

                          </tr>
                        ))}
                        <tr>
                        <th style={{fontWeight:'bolder'}}>Total</th>
                        <th></th>
                        <th className="align-right">{this.calculateTotalSavings()}</th>
                        <th className="align-right">{this.calculateTotalSuper()}</th>
                        <th className="align-right">{this.calculateTotalShares()}</th>
                        <th className="align-right">{this.calculateTotalTeeba()}</th>

                        </tr>

                    </tbody>
                </reactstrp.Table>           


 </Panel>
            </Collapse>

        </div>
    )

    }


  }
}

export default MemberDepositsReport; 
