import React, { Component, createRef } from 'react';

import axios from 'axios'
import { Tooltip,Popover,Result,Table,Popconfirm,Input,Tabs, Button,Collapse,Card,Avatar,Modal,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Spin,Switch } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { PrinterOutlined,SettingFilled, MinusOutlined, CloseCircleFilled, CheckCircleOutlined, UserOutlined } from '@ant-design/icons';
import { DeleteOutlined,SearchOutlined,CameraOutlined,PlusOutlined,EditOutlined,PlusSquareFilled,SaveOutlined,LoadingOutlined } from '@ant-design/icons';
import QrReader from 'react-qr-reader'
import uuid from 'react-uuid'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
import PrintTemplate from 'react-print';
import ReactToPrint from 'react-to-print';
import { useReactToPrint } from 'react-to-print';

import {
  Col,
  Row,
} from 'reactstrap';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import * as reactstrp from 'reactstrap';
import { ToWords } from 'to-words';
import JournalEntryView from './JournalEntryView'
import PrintComponent from './PrintComponent';

var converter = require('number-to-words');


var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { Title } = Typography;
const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

const toWords = new ToWords();
const { TabPane } = Tabs;

message.config({
  top: 200,
  duration: 2,
  maxCount: 3,
  rtl: true,
});


var token= ''
var sacco= ''
var username=''
var bizuserid= ''


class ReceiptToPrint extends React.Component {

  state = {
    companyprofile:{}
  };

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    
    }else{
       sacco= ''
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })
    })
  }


  render() {
    return (
      <div style={{padding:20}}>
        {
          this.props.thermalprint===true?
          <div >
          <p>*********************************************************</p>

          <h1 style={{fontWeight:'bolder',display:'flex',alignSelf:'flex-start'}}>  {this.state.companyprofile.sacco_name}</h1>
          <h2 style={{color:'black'}}>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
          {
          this.state.companyprofile.RegisteredPhoneNumber2==""?
          "":","+this.state.companyprofile.RegisteredPhoneNumber2
          }

          </h2>               
          <h2 style={{color:'black'}}>  Email: {this.state.companyprofile.company_email}</h2>
          <h2 style={{color:'black'}}>  Wesite: {this.state.companyprofile.website}</h2>
          <h2 style={{color:'black'}}>  {this.state.companyprofile.box_number}</h2>
          <h2 style={{color:'black'}}>  RECEIPT NO: {this.props.journal_number}</h2>
          <h2 style={{color:'black'}}>  DATE: {this.props.date}</h2>

          <p>*********************************************************</p>

          <h1 style={{fontWeight:'bolder',display:'flex',alignSelf:'flex-start'}}>  DEPOSIT RECEIPT</h1>

          <p>********************************</p>

          <h3 style={{color:'black'}}>
              ACC NUMBER: 
            {this.props.account.AccountNumber}
          </h3>

          <h3 style={{color:'black'}}>
              ACC NAME :
            {this.props.account.FullName}
          </h3>

          <h3 style={{color:'black'}}>
              DEPOSITED AMOUNT:
            <CurrencyFormat  value={this.props.totalamount} displayType={'text'} thousandSeparator={','} />
          </h3>

          <h3 style={{color:'black'}}>
              In Words: 
            ({ this.props.amount_inwords})
          </h3>


          <h3 style={{color:'black'}} >
              PAID IN BY: 
            {this.props.paidinby}
          </h3> 

          <h3 style={{color:'black'}} >
              CONTACT:
            {this.props.transactorcontact}
          </h3> 

          <h3 style={{color:'black'}}>
              TELLER:  
            {username}
          </h3>                 

          <h3 style={{color:'black'}}>
              PAYMENT OF: {this.props.reason}
          </h3> 


          <p>********************************</p>

          <h4 style={{color:'black'}}>  Supported by PitechUgLtd </h4>
          <h4 style={{color:'black'}}>  ( www.pitech.co.ug ) </h4>

        </div>

          :

        <div>
            <Row> 
            <Col xs="2" sm="2" lg="2">
                <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.sacco_logo} />

              </Col>
              <Col xs="6" sm="6" lg="6">
              <h3>{this.state.companyprofile.sacco_name}</h3>
              <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
              {
              this.state.companyprofile.RegisteredPhoneNumber2==""?
              "":","+this.state.companyprofile.RegisteredPhoneNumber2
              }
        
              </h6>               
              <h5>Email: {this.state.companyprofile.company_email}</h5>
              <h5>Wesite: {this.state.companyprofile.website}</h5>
              <h5>{this.state.companyprofile.box_number}</h5>

              </Col>
            </Row>
            <h3 style={{fontWeight:'bolder',display:'flex',alignSelf:'center',alignContent:'center',justifyContent:'center'}}>DEPOSIT RECEIPT</h3>

            <reactstrp.Table bordered>
          <thead>
            <tr>
            <th>  ACC NUMBER</th>
              <th>{this.props.account.AccountNumber}</th>
            </tr>

            <tr>
              <th>ACC NAME</th>
              <th>{this.props.account.FullName}</th>
            </tr>

            <tr>
              <th>DEPOSITED AMOUNT</th>
              <th><CurrencyFormat  value={this.props.totalamount} displayType={'text'} thousandSeparator={true} /></th>
            </tr>

            <tr>
              <th>In Words   </th>
              <th>({ this.props.amount_inwords})</th>
            </tr>


            <tr >
              <th >PAID IN BY</th>
              <th >{this.props.paidinby}</th>
            </tr> 

            <tr >
              <th >SLIP NUMBER</th>
              <th >{this.props.journal_number}</th>
            </tr> 

            <tr >
              <th >CONTACT</th>
              <th >{this.props.transactorcontact}</th>
            </tr> 

            <tr >
              <th >TELLER</th>
              <th >{username}</th>
            </tr>                 

            <tr >
              <th >PAYMENT OF</th>
              <th >{this.props.reason}</th>
            </tr> 

          </thead>
        </reactstrp.Table> 

        </div>
        }


          
    </div>
    );
  }
}


class AccountDepositView extends React.Component {

  state = {
    date:moment().format(dateFormat).toString(),
    selectedaccount:'',
    selectedaccountobject:{},
    members:[],
    datarequested:true,
    totalamount:0,
    paidinby:'',
    transactorcontact:'',
    previoustransactions:[],
    datasubmittedsuccessfully:false,
    companyprofile:{},
    accountchange:false,
    userprofile:{},
    transaction_details:'',
    journal_number:'',

    savingsamount:0,
    defsavings:0,
    supersavings:0,
    teebagooloamount:0,
    shares:0,
    companyaccounts: [],
    debitaccount:'',

    loan_amount:0,
    defsettings:{},
    defsett_modalvisible:false,
    noofinstallments:0,
    amountperinstallment:0,
    startingdate:'',

    availableloans:[],
    selectedloan:'',
    thermalprint:false,

    supersave_profile:{},
    teebagoolo_profile:{},
    journal_details:'',
    itemnumber:0,
    transaction_type:'',
    interest_tobepaid:0,

    current_loanproperties:{},

    extra_deposit_list:[],
    current_extra_field:'',
    print_office_copy:false,
    transaction_channel:'',

    change_receiving_account:false,
    default_debit:0,
    companyaccounts_new:[],
    membertype_object:{},
    journalno:0,
    finaldebit_account:'',

    memberperiodic_balanceobj:{},
  }
  

  callback(key) {
    console.log(key);
  }




  componentDidMount(){
    this.componentRef = createRef();

    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    
    }else{
       sacco= ''
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
   }else{
      bizuserid= ''
   }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    let form_data1 = new FormData();
    form_data1.append('userid', bizuserid);
    
    //get the user profile here
    axios.post(serverconfig.backendserverurl+'/customqueries/get_user_profile',form_data1,{
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res => {
        this.setState({
          userprofile:res.data
        })
    })


    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })
    })

    axios.get(serverconfig.backendserverurl+`/api/members_withaccounts/?sacco=${sacco}`)
    .then(res => {

      //console.log(res.data)
      this.setState({
        members:res.data
      })

    })

    axios.get(serverconfig.backendserverurl+`/api/companyaccounts/?sacco=${sacco}`)
    .then(res => {
        this.setState({
            companyaccounts:res.data
        })
        this.setState({datarequested:false})
    })

    //all company acocunts
    axios.get(serverconfig.backendserverurl+`/api/companyaccounts/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          companyaccounts_new:res.data
        })
    })


    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

        this.setState({default_debit:res.data.defaultaccount_for_officecollection_debiting})

        if (this.state.companyprofile.defaultaccount_for_officecollection_debiting!=null || this.state.companyprofile.defaultaccount_for_officecollection_debiting!=0){

          this.setState({finaldebit_account:this.state.companyprofile.defaultaccount_for_officecollection_debiting})

        }

        //set extra deposits
        this.setState({extra_deposit_list:res.data.extra_deposit_list===undefined || res.data.extra_deposit_list===null?[]:JSON.parse(res.data.extra_deposit_list) })

    })

  }



  ////////////////////////////////////
  // PRODUCT SEARCH SELECT METHODS
   onBlur=()=> {
    console.log('blur');
  }
  
   onFocus=()=>{
    console.log('focus');
  }
  
  onSearch=(val)=> {
    console.log('search:', val);
  }


  //handle client change
  handleselectedaccountChange= (e) =>{

    let form_data1 = new FormData();
    form_data1.append('date',this.state.date);
    form_data1.append('member_id', e);
    axios.post(serverconfig.backendserverurl+`/customqueries/get_runningbalancesbydate`, form_data1, {
    headers: {
      'content-type': 'multipart/form-data'
    }
    })
    .then(res => {
        this.setState({
          memberperiodic_balanceobj:res.data.my_balance
        })
    })

    
    this.setState({accountchange:true})
    this.setState({ selectedaccount: e});
    //make query for last transactions
    axios.get(`${serverconfig.backendserverurl}/api/members/${e}`)
      .then(res => {  
          this.setState({selectedaccountobject: res.data})
          this.setState({accountchange:false})

          //get membet typoe obj
          axios.get(`${serverconfig.backendserverurl}/api/accounttypes/${res.data.accountType}`)
          .then(res => {  
              this.setState({membertype_object: res.data})
          })
      })

      /*
      axios.get(`${serverconfig.backendserverurl}/api/loanissues/?account=${e}`)
      .then(res => {  
          this.setState({availableloans: res.data})
      })*/

      let form_data1_1 = new FormData();
      form_data1_1.append('date',this.state.date);
      form_data1_1.append('selectedmemberid', e);
      
      axios.post(serverconfig.backendserverurl+`/customqueries/get_memberactive_loans_bydate`, form_data1_1, {
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
      .then(res => {
          this.setState({
            availableloans:JSON.parse(res.data.report)
          })
      })

      
      //get teebagoolo and supersave profiles
      axios.get(`${serverconfig.backendserverurl}/api/supersaveprofile/?member_account=${e}`)
      .then(res => {  
          this.setState({supersave_profile: res.data})
      })

      axios.get(`${serverconfig.backendserverurl}/api/teebagooloprofile/?member_account=${e}`)
      .then(res => {  
          this.setState({teebagoolo_profile: res.data})
      })

  }



  handlepaidinbyChange= (e) => this.setState({ paidinby: e.target.value});
  handletransactorcontactChange= (e) => this.setState({ transactorcontact: e});

  calculatesharesamount=()=>{
    var tot=0
    tot=Number(this.state.shares)*Number(this.state.companyprofile.sharevalue)
    return tot;
  }

  calculatetotalamount=()=>{
    var tot=0
    tot=this.calculatesharesamount()+Number(this.state.savingsamount)+Number(this.state.defsavings)+Number(this.state.teebagooloamount)+Number(this.state.supersavings)+Number(this.state.loan_amount)
   
    //loop thrg list of list pyment
    this.state.extra_deposit_list.map((item)=>{
    if (Number(item.amount_paid)>0){
      tot+=Number(item.amount_paid)
    }
   })
    return tot;
  }



  //generate reason
  generate_reason=()=>{
    var reason=''
    if (this.state.savingsamount>0){
      reason+="SAVINGS ( "+String(this.state.savingsamount)+" ) ,"
    }

    if (this.state.defsavings>0){
      reason+="DIFFERED ( "+String(this.state.defsavings)+" ) ,"
    }


    if (this.state.teebagooloamount>0){
      reason+="TEEBAGOOLO ( "+String(this.state.teebagooloamount)+" ) ,"
    }

    if (this.state.supersavings>0){
      reason+="SUPERSAVE ( "+String(this.state.supersavings)+" ) ,"
    }

    

    if (this.calculatesharesamount()>0){
      reason+="SHARES ( "+String(this.calculatesharesamount())+" ) ,"
    }

  
    if (this.state.loan_amount>0 ){
      reason+="LOAN ( "+String(Number(this.state.loan_amount))+" ) ,"
    }


    //loop thrg list of list pyment
    this.state.extra_deposit_list.map((item)=>{
      if (Number(item.amount_paid)>0){
        reason+=" "+String(item.account_tocredit)+" ( "+String(item.amount_paid)+" ) ,"

      }else{
        reason+=''
      }
    })

    return reason
  }




  calculatesharespercentage=()=>{
    var shre=0
    shre=(Number(this.state.memberperiodic_balanceobj.shareamount_balance)/Number(this.state.companyprofile.sharecapital))*100
    return shre;
  }

  //check date of availability
  checkMembervalidity=(dateofentry)=>{

    var agelimit_months_cap=180

     var dateMomentObjectone = moment(dateofentry, "DD/MM/YYYY"); // 1st argument - string, 2nd argument - format
     var dobobject = dateMomentObjectone.toDate();

     var datetoday=moment(this.state.date, "DD/MM/YYYY")

     var date1 = datetoday.toDate();
     var date2 = dobobject;

     var Difference_In_Time =  date1.getTime()-date2.getTime();
     var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

     if(Number(Difference_In_Days)>=Number(agelimit_months_cap)){
       return true;
     }else{
       return false;
     }
 }
 

  //check empty objecty
  isEmptyObject=(obj)=>{
    for ( var name in obj ) {
      return false;
    }
    return true;
  }


  //submit the deposit now
  handleFormSubmit=(event) =>{
    
    if(Number(this.state.savingsamount)<Number(this.state.selectedaccountobject.minimum_account_deposit) && Number(this.state.savingsamount)>0){
      message.info("Minimum account deposit is: "+this.state.selectedaccountobject.minimum_account_deposit)
    }else if(Number(this.state.loan_amount)>0 && this.state.selectedloan===""){
      message.info("Please select loan for repayment")
    }else if(Number(this.state.defsavings)>0 && Object.keys(this.state.defsettings).length<=0){
      message.info("Please create def settings")
    }else if(this.state.selectedaccount===""){
      message.info("Please select member account")
    }else if (this.state.finaldebit_account===""){
      message.error("Please select debit account")
    }
    
    else{

      //get the transaction channel here
      axios.get(serverconfig.backendserverurl+`/api/companyaccounts/${this.state.finaldebit_account}`)
      .then(res => {
        this.setState({transaction_channel:res.data.account_name})
        
      })

      this.setState({datarequested:true})
      console.log(username)

      let form_data = new FormData();
      form_data.append('date',this.state.date);
      form_data.append('paidinby', this.state.paidinby);
      form_data.append('savingsamount', this.state.savingsamount);
      form_data.append('defsavings', this.state.defsavings);
      form_data.append('supersavings', this.state.supersavings);
      form_data.append('teebagooloamount', this.state.teebagooloamount);
      form_data.append('shares', this.state.shares);
      form_data.append('loan_amount', this.state.loan_amount);

      form_data.append('transactorcontact', this.state.transactorcontact);
      form_data.append('sacco', sacco);
      form_data.append('account', this.state.selectedaccountobject.id);
      form_data.append('username', username);
      form_data.append('userid', bizuserid);
      form_data.append('transaction_details', this.generate_reason());
      form_data.append('debitaccount', this.state.finaldebit_account);
      form_data.append('journaltotal', this.calculatetotalamount());

      form_data.append('defsettings', JSON.stringify(this.state.defsettings));
      form_data.append('selectedloan', this.state.selectedloan);
      form_data.append('extra_deposit_list', JSON.stringify(this.state.extra_deposit_list) );

      axios.post(serverconfig.backendserverurl+'/customqueries/createaccountdeposit', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
        })
      .then(res => {
        this.setState({journal_number:res.data.journalno})
        this.setState({datarequested:false})
        message.info(res.data.message)
        if (res.data.message==="success"){
          this.setState({datasubmittedsuccessfully:true})
        }
      })
      .catch(error => console.log(error))
    }
    
  }
  


  ////////////////////////////////////////////
  // RENDERING METHOD HERE
  render() {

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      if(this.state.datasubmittedsuccessfully===true){
        return(
          <div>
          
            <div style={{display: "none"}}>
            &nbsp;&nbsp;
            <ReceiptToPrint
            account={this.state.selectedaccountobject} 
            totalamount={this.calculatetotalamount()} 
            amount_inwords={converter.toWords(this.calculatetotalamount())}
            thermalprint={this.state.thermalprint}

            paidinby={this.state.paidinby} 
            transactorcontact={this.state.transactorcontact}
            date={this.state.date}
            reason={this.generate_reason()}
            journal_number={this.state.journal_number}
            extra_deposit_list={this.state.extra_deposit_list}
            print_office_copy={this.state.print_office_copy}
            transaction_channel={this.state.transaction_channel}

            ref={el => (this.componentRef = el)} 
            /> 
            </div> 

            <Row >
                  <Col xs="12" sm="6" lg="6">
                    <Card>
                    <Result
                    status="success"
                    title="Successfully Deposited "
                    subTitle="Please print receipt or finish off transaction"
                    extra={[
                      <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignSelf:'center'}}>
                      <FormItem >
                      <Switch
                        checked={this.state.thermalprint}
                        onChange={val=>{this.setState({thermalprint:val})}}
                        checkedChildren="Thermal print"
                        unCheckedChildren="A4 print (Click to change to thermal printer)"
                        />
                      </FormItem>

                      </div>

                      ,
                      <a>
                      <ReactToPrint
                        trigger={() =>                     
                          <div style={{}}>
                            <Tooltip title="Click to print" placement="top">
                            <p ><PrinterOutlined style={{ fontSize: '40px', color: '#08c' }}/></p>
                            <h6 >Print Receipt</h6>
                            </Tooltip>
                          </div>                          
                            }
                            content={() => this.componentRef}
                          />  
                          </a>,
                      <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
                    ]}
                    />

                  </Card>
                  </Col>
            </Row>
          </div>

        )

      }else{
        return(
          <div>
            <div>
             
                <Tabs defaultActiveKey="1" onChange={()=>{}}>

                      <TabPane tab="Member deposit form" key="1">
                      <Row >
                          <Col xs="12" sm="6" lg="6">
                            <Card>

                            

                            {
                              this.state.accountchange?
                              <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
                              <Spin indicator={antIcon} />
                              </div>
                              :
                              null
                            }

                        <h3 style={{fontWeight:'bold',alignSelf:'center',color:'#154360'}}> DEPOSIT FORM</h3>
                          <Form
                              onFinish={(event) => this.handleFormSubmit(event)}
                          >

                          {
                            this.state.companyprofile.automated_transaction_date===false?
                            <FormItem label="Date"
                            name='date'
                            rules={[
                              {
                                required: true,
                                message: 'Please Select date',
                              },
                              ]}
                            >
                                <DatePicker onChange={(date, dateString) => this.setState({ date: dateString})} format={dateFormat} />
                            </FormItem>                 
                            :
                            null
                          }


                        <FormItem label="Account">
                              <Select 
                              placeholder="Account No | Name" 
                              style={{  }} 
                              value={this.state.selectedaccount}
                              onChange={this.handleselectedaccountChange} 
                              showSearch
                              optionFilterProp="children"
                              onFocus={this.onFocus}
                              onBlur={this.onBlur}
                              onSearch={this.onSearch}                    
                              >
                                {this.state.members.map(
                                  (accnt)=>(
                                    <Option value={accnt.id}>{accnt.AccountNumber} | {accnt.FullName}</Option>
                                  ))}
                              </Select>
                          </FormItem>


                          <FormItem label={<h6 style={{fontWeight:'bold'}}>Savings Amount </h6>} >
                              <Input name="savingsamount"   placeholder="0.0" value={this.state.savingsamount} onChange={(e) => this.setState({ savingsamount: e.target.value})}/>
                          </FormItem>


                          {
                            this.calculatesharespercentage()>=Number(this.state.selectedaccountobject.maximumshare_percentage)?
                            <h6 style={{display:'flex',color:'red'}}>  Reached Shares limit of  ({this.state.selectedaccountobject.maximumshare_percentage} %)</h6>
                            :
                            <div style={{display:'flex',flexDirection:'row'}}>
                            <FormItem label={<h6 style={{fontWeight:'bold'}}>Shares </h6>}

                            >
                                <Input name="shares"   placeholder="0" value={this.state.shares} onChange={(e) => this.setState({ shares: e.target.value})}/>
                            </FormItem>
          
                            <h3>&nbsp;&nbsp;<CurrencyFormat  value={this.calculatesharesamount()} displayType={'text'} thousandSeparator={true} /></h3>
                            </div>
                          }

                        {
                          this.checkMembervalidity(this.state.selectedaccountobject.DateOfJoining)===true && this.isEmptyObject(this.state.supersave_profile)===false ?
                          <FormItem label={<h6 style={{fontWeight:'bold'}}>Super Save </h6>}
                          >
                              <Input name="supersavings"   placeholder="0.0" value={this.state.supersavings} onChange={(e) => this.setState({ supersavings: e.target.value})}/>
                          </FormItem>

                          :
                          null
                        }


                        {
                          this.checkMembervalidity(this.state.selectedaccountobject.DateOfJoining)===true && this.isEmptyObject(this.state.teebagoolo_profile)===false?

                          <FormItem label={<h6 style={{fontWeight:'bold'}}>Teeba Goolo Amount </h6>}
                          >
                              <Input name="teebagooloamount"   placeholder="0.0" value={this.state.teebagooloamount} onChange={(e) => this.setState({ teebagooloamount: e.target.value})}/>
                          </FormItem>
                          :
                          null
                        }


                          <div style={{display:'flex',flexDirection:'row'}}>
                            <FormItem label={<h6 style={{fontWeight:'bold',margin:3}}>Def Amount </h6>}
                            >
                                <Input name="defsavings"   placeholder="0.0" value={this.state.defsavings} onChange={(e) => this.setState({ defsavings: e.target.value})}/>
                            </FormItem>

                            <SettingFilled style={{ fontSize: '30px',margin:3 }} onClick={()=>{ this.setState({defsett_modalvisible:true})}}/>

                          </div>

                          {
                            this.state.defsavings>0?
                            <h5>Defered Settings: ({this.state.defsettings.noofinstallments} installments,{this.state.defsettings.amountperinstallment} per installment, starting {this.state.defsettings.startingdate} )</h5>
                            :
                            null
                          }



                          {
                            this.state.availableloans.length>0?
                            <FormItem label={<h6 style={{fontWeight:'bold'}}>Loan Amount </h6>}
                            >
                                <Input name="loan_amount"   placeholder="0.0" value={this.state.loan_amount} onChange={(e) => this.setState({ loan_amount: e.target.value})}/>
                            </FormItem>
                            :
                            null

                          }


                          {
                            this.state.loan_amount>0?
                            <div>
                            <FormItem label="Select Loan to Repay"
                                name="selectedloan"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please select loan',
                                  },
                                ]}                  
                            >
                                <Select 
                                placeholder="Select Loan to Repay" 
                                style={{  }} 
                                value={this.state.selectedloan}
                                onChange={(val)=>{
                                  this.setState({selectedloan:val})

                                  //query loan properties here
                                  let form_data = new FormData();
                                  form_data.append('loan_id', val);
                                  form_data.append('date', this.state.date);
                                 
                                  axios.post(serverconfig.backendserverurl+'/customqueries/getloanbalances_atparticulardate', form_data, {
                                    headers: {
                                      'content-type': 'multipart/form-data'
                                    }
                                    })
                                  .then(res => {
                                    this.setState({current_loanproperties:res.data.info})
                                    
                                  })
                                  .catch(error => console.log(error))


                                }} 
                                showSearch
                                optionFilterProp="children"
                                onFocus={this.onFocus}
                                onBlur={this.onBlur}
                                onSearch={this.onSearch}
                                                      
                                >
                                  {this.state.availableloans.map(
                                    (loan)=>(
                                      <Option value={loan.id}>Amount {loan.approved_amount} | Date {loan.date} | Months {loan.loan_period} | NO {loan.loan_number}</Option>
                                    ))}
                                </Select>
                              </FormItem>

                              <br></br>
                              {
                                this.isEmptyObject(this.state.current_loanproperties)===false?
                                <div >
                                <h4>Running interest balance as at {this.state.date} : <CurrencyFormat  value={this.state.current_loanproperties.runningint_balance} displayType={'text'} thousandSeparator={true} /> </h4>
                                <h4>Running principle balance as at {this.state.date} : <CurrencyFormat  value={this.state.current_loanproperties.runningprinciple_balance} displayType={'text'} thousandSeparator={true} /> </h4>
                                <h4>Total running balance as at {this.state.date} : <CurrencyFormat  value={this.state.current_loanproperties.runningint_total_bal} displayType={'text'} thousandSeparator={true} /> </h4>

                                </div>
                                :
                                null
                              }
                              <br></br>


                            </div>

                            :
                            null
                          }


                    {
                    this.state.extra_deposit_list.length>0?
                    <div>
                      {
                        this.state.extra_deposit_list.map((item)=>{

                          if(item.member_type===this.state.membertype_object.account_type_name){
   
                            return (
                              <FormItem  label={<h6 style={{fontWeight:'bold'}}>{item.account_tocredit}|Minimum {item.minimum_amount}  </h6>} >
                                  <Input  
                                   type='number' 
                                   placeholder="0.0"                             
                                     onChange={(e) =>{
                                      var itemKey=item.key
                                      var value=e.target.value
  
                                      const index = this.state.extra_deposit_list.findIndex(item => item.key === itemKey);
  
                                      if (value<Number(item.minimum_amount)){
                                        message.error("Amount cant be less than "+String(item.minimum_amount))
  
                                        if (index !== -1) {
                                          // Create a new object with the updated quantity
                                          const updatedItem = {
                                            ...this.state.extra_deposit_list[index],
                                            "amount_paid": 0,
                                          };
                        
                                          // Replace the item at the found index with the updated object
                                          const updatedShoppingList = [...this.state.extra_deposit_list];
                                          updatedShoppingList[index] = updatedItem;
                        
                                          // Update the state with the new shopping list
                                          this.setState({extra_deposit_list:updatedShoppingList});
                        
                                        }


                                      }else{
                                        if (index !== -1) {
                                          // Create a new object with the updated quantity
                                          const updatedItem = {
                                            ...this.state.extra_deposit_list[index],
                                            "amount_paid": value,
                                          };
                        
                                          // Replace the item at the found index with the updated object
                                          const updatedShoppingList = [...this.state.extra_deposit_list];
                                          updatedShoppingList[index] = updatedItem;
                        
                                          // Update the state with the new shopping list
                                          this.setState({extra_deposit_list:updatedShoppingList});
                        
                                        }
  
                                      }  
    
                                  } }/>
                              </FormItem>
  
                            )
                              
                          }

                        })
                      }


                    </div>
                    :
                    null
                   } 

                            <FormItem label={<h4 style={{fontWeight:'bold',color:'#154360'}}>Total Amount </h4>}
                            >
                              <h3 style={{fontWeight:'bolder'}}><CurrencyFormat  value={this.calculatetotalamount()} displayType={'text'} thousandSeparator={true} /></h3>
                          
                            </FormItem>
          
                            <FormItem label={<h4 style={{fontWeight:'bold'}}>Paid In By:</h4>}
                            name="paidinby"
                            rules={[
                              {
                                required: true,
                                message: 'Please input name',
                              },
                            ]}
                            >
                              <Input name="paidinby"  placeholder="Name" value={this.state.paidinby} onChange={this.handlepaidinbyChange}/>
            
                            </FormItem>
          
                            <FormItem 
                              label={<h4 style={{fontWeight:'bold'}}>Transactor Contact:</h4>}

                              >
                              <PhoneInput
                              countrySelectProps={{ unicodeFlags: true }}
                              defaultCountry="UG"
                              placeholder="Enter phone"
                              value={this.state.transactorcontact}
                              onChange={this.handletransactorcontactChange}
                              />
                          </FormItem> 

                          <br></br>


                            {
                              this.state.companyprofile.defaultaccount_for_officecollection_debiting!=0?
                            
                            <div>

                            <FormItem 
                              label={<h4 style={{fontWeight:'bold'}}>Auto Debit A/C {this.state.companyprofile.defaultaccount_for_officecollection_debiting}</h4>}
                              >

                                <Select 
                                  placeholder="Account" 
                                  style={{  }} 
                                  value={this.state.default_debit}
                                  showSearch
                                  optionFilterProp="children"
                                  onFocus={this.onFocus}
                                  onBlur={this.onBlur}
                                  onSearch={this.onSearch}                    
                                  >
                                    {this.state.companyaccounts_new.map(
                                      (accnt)=>{
                                        if (this.state.default_debit===accnt.id){
                                          return (
                                            <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
                                          );
                                        }

                                      }
                                      
                                      )}
                                  </Select>

                              </FormItem>


                              <FormItem label={<h4 style={{fontWeight:'bold'}}>Change Account</h4>} >
                              <Switch
                                checked={this.state.change_receiving_account}
                                onChange={val=>{this.setState({change_receiving_account:val})}}
                                checkedChildren="Changed to manual"
                                unCheckedChildren="Click to change"
                                />

                              </FormItem>

                              {
                              this.state.change_receiving_account?
                              <FormItem label={<h4 style={{fontWeight:'bold'}}>Receiving A/C (Debit A/C).</h4>}
                              name="debitaccount"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please select account',
                                },
                              ]}
                            >
                                  <Select 
                                  placeholder="Account" 
                                  style={{  }} 
                                  value={this.state.debitaccount}
                                  onChange={(val)=>{
                                    
                                    this.setState({debitaccount:val})

                                    this.setState({
                                      finaldebit_account: val
                                    })
                                  
                                    
                                  }} 
                                  showSearch
                                  optionFilterProp="children"
                                  onFocus={this.onFocus}
                                  onBlur={this.onBlur}
                                  onSearch={this.onSearch}                    
                                  >
                                    {this.state.companyaccounts.map(
                                      (accnt)=>(
                                        <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
                                      ))}
                                  </Select>
                              </FormItem>
                              :
                              null
                            }   

                            </div>
                            
                            
                              :
                              <FormItem label={<h4 style={{fontWeight:'bold'}}>Receiving A/C (Debit A/C).</h4>}
                              name="debitaccount"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please select account',
                                },
                              ]}
                            >
                                  <Select 
                                  placeholder="Account" 
                                  style={{  }} 
                                  value={this.state.debitaccount}
                                  onChange={(val)=>{
                                    
                                    this.setState({debitaccount:val})

                                    this.setState({
                                      finaldebit_account: val
                                    })

                                  
                                  }} 
                                  showSearch
                                  optionFilterProp="children"
                                  onFocus={this.onFocus}
                                  onBlur={this.onBlur}
                                  onSearch={this.onSearch}                    
                                  >
                                    {this.state.companyaccounts.map(
                                      (accnt)=>(
                                        <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
                                      ))}
                                  </Select>
                              </FormItem>

                            }   


                          <FormItem>
                            <Button  type="primary" htmlType="submit">Submit</Button>
                          </FormItem>
                          </Form>
                          </Card>
                          
                          </Col>



                          <Col xs="12" sm="6" lg="6">
                            <Card>
                            <h3 style={{fontWeight:'bold',color:'#154360'}}>Account Details</h3>
                            <h4>Account Number:{this.state.selectedaccountobject.AccountNumber}</h4>
                            <h4>Account Contact:{this.state.selectedaccountobject.RegisteredPhoneNumber1}</h4>
                            <h4>Account Name:{this.state.selectedaccountobject.FullName}</h4>
                          
                            <reactstrp.Table bordered>
                          <thead>
                            <tr>
                              <th>
                              <p style={{fontWeight:'bolder',fontSize:15}}>SavingBalance:<CurrencyFormat  value={Number(this.state.memberperiodic_balanceobj.savings_balance).toFixed(2)} displayType={'text'} thousandSeparator={true} />  </p>
                            <p style={{fontWeight:'bolder',fontSize:15}}>Supersavebalance:<CurrencyFormat  value={Number(this.state.memberperiodic_balanceobj.supersaver_balance).toFixed(2)} displayType={'text'} thousandSeparator={true} />  </p>

                            <p style={{fontWeight:'bolder',fontSize:15}}>Sharesbalance:<CurrencyFormat  value={Number(this.state.memberperiodic_balanceobj.shareamount_balance).toFixed(2)} displayType={'text'} thousandSeparator={true} />  </p>
                            <p style={{fontWeight:'bolder',fontSize:15}}>SharesNo:{this.state.memberperiodic_balanceobj.share_numberbalance}  </p>
                            <p style={{fontWeight:'bolder',fontSize:15}}>Teebagoolo:<CurrencyFormat  value={Number(this.state.memberperiodic_balanceobj.teebagoolo_balance).toFixed(2)} displayType={'text'} thousandSeparator={true} />  </p>
                            <p style={{fontWeight:'bolder',fontSize:15}}>Loan:<CurrencyFormat  value={Number(this.state.memberperiodic_balanceobj.loan_balance).toFixed(2)} displayType={'text'} thousandSeparator={true} />  </p>
                            <p style={{fontWeight:'bolder',fontSize:15}}>Loaninterest:<CurrencyFormat  value={Number(this.state.memberperiodic_balanceobj.loan_interestbalance).toFixed(2)} displayType={'text'} thousandSeparator={true} />  </p>

                             </th>


                            <th>

                            <p style={{fontWeight:'bolder',fontSize:15}}>Savingsloansecurity:<CurrencyFormat  value={Number(this.state.memberperiodic_balanceobj.savingsloan_security_balance).toFixed(2)} displayType={'text'} thousandSeparator={true} />  </p>
                            <p style={{fontWeight:'bolder',fontSize:15}}>Supersave loan security:<CurrencyFormat  value={Number(this.state.memberperiodic_balanceobj.supersaveloan_security_balance).toFixed(2)} displayType={'text'} thousandSeparator={true} />  </p>
                            <p style={{fontWeight:'bolder',fontSize:15}}>Teebagoolo loan security:<CurrencyFormat  value={Number(this.state.memberperiodic_balanceobj.teebagoololoan_security_balance).toFixed(2)} displayType={'text'} thousandSeparator={true} />  </p>
                            <p style={{fontWeight:'bolder',fontSize:15}}>Shares loan security:<CurrencyFormat  value={Number(this.state.memberperiodic_balanceobj.shares_security_balance).toFixed(2)} displayType={'text'} thousandSeparator={true} />  </p>

                            <p style={{fontWeight:'bolder',fontSize:15}}>Saving Interest:<CurrencyFormat  value={Number(this.state.memberperiodic_balanceobj.saving_interestbalance).toFixed(2)} displayType={'text'} thousandSeparator={true} />  </p>
                            <p style={{fontWeight:'bolder',fontSize:15}}>Teebagoolo Interest:<CurrencyFormat  value={Number(this.state.memberperiodic_balanceobj.teebagoolo_interestbalance).toFixed(2)} displayType={'text'} thousandSeparator={true} />  </p>
                            <p style={{fontWeight:'bolder',fontSize:15}}>Supersave Interest:<CurrencyFormat  value={Number(this.state.memberperiodic_balanceobj.supersave_interestbalance).toFixed(2)} displayType={'text'} thousandSeparator={true} />  </p>


                              </th>

                            </tr>
                          </thead>

                          </reactstrp.Table>
                            
                            <h4>Status:{this.state.selectedaccountobject.account_status}</h4>
                            <h4>Date of joining:{this.state.selectedaccountobject.DateOfJoining}</h4>
                            <h3>Deposit details: {this.generate_reason()}</h3>
                            <br></br>
                          </Card>
                          
                          </Col>
                      </Row>

          </TabPane>

          <TabPane tab="Multi journal entries form" key="2">
            <JournalEntryView />

        </TabPane>

          </Tabs>

               <Modal              
              visible={this.state.defsett_modalvisible}
              title={"Defered payments Settings Form"}
              onCancel={()=>{this.setState({defsett_modalvisible:false})}}
              style={{width:1000}}
              footer={[
                <Button key="back" onClick={()=>{this.setState({defsett_modalvisible:false})}}>
                  Cancel
                </Button>,
                  <Button key="ok" type='primary' 
                    onClick={()=>{
                      var intobj={
                        'noofinstallments':this.state.noofinstallments,
                        'amountperinstallment':this.state.amountperinstallment,
                        'startingdate':this.state.startingdate,
                      }

                      this.setState({defsettings:intobj})
                      this.setState({defsett_modalvisible:false})
                    }}>
                  Add Settings
                </Button>
                ]}
                >
                  
                <FormItem label="Number Of Installments" >
                    <Input name="noofinstallments"  placeholder="Number Of Installments" value={this.state.noofinstallments} onChange={(val)=>{this.setState({noofinstallments:val.target.value})}} />
                </FormItem>                  

                <FormItem label="Amount Per Installment" >
                    <Input name="amountperinstallment"  placeholder="Amount Per Installment" value={this.state.amountperinstallment} onChange={(val)=>{this.setState({amountperinstallment:val.target.value})}} />
                </FormItem> 

                <FormItem label="Starting date">
                    <DatePicker  onChange={(date, dateString)=>{this.setState({ startingdate: dateString})}} format={dateFormat} />
                </FormItem>

               </Modal> 

            </div>
       </div>              
                      
       )
      }
     
    }
   
  }
}

export default AccountDepositView; 
