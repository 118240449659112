import React from 'react';
import axios from 'axios';
import { Card, Button,Form,Input,message,Spin,Select,DatePicker} from 'antd';
import IDTypeForm from '../components/IDTypeForm'
import { UserOutlined,LoadingOutlined } from '@ant-design/icons';
import * as serverconfig from '../serverconn'
import {
    Col,
    Row,
  } from 'reactstrap';
  import moment from 'moment';

var CryptoJS = require("crypto-js");
const dateFormat = 'DD/MM/YYYY';
const { Option } = Select;
const FormItem=Form.Item;
const { Meta } = Card;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''
var sacco= ''
var username=''
var bizuserid= ''

class SuperSaveDetail extends React.Component{

    state={
        date:moment().format(dateFormat).toString(),
        supersaveprofile: {},
        saving_period:'' ,  
        datarequested:true,

        supersaveaccounttype:'',
        supersavertypes:[],
        supersavertype:'',
        supersavertype_obj:{},

        physical_form:null,
        companyprofile:{},


    }

    componentDidMount(){
        if(localStorage.getItem("token")){
          token= localStorage.getItem("token")
        }else{
          token= ''
        }

        if(localStorage.getItem("sacco")){
          sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
          username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
       
        }else{
            sacco= ''
            username=''
        }

        axios.defaults.headers={
            "Content-Type":"application/json",
            Authorization:`Token ${token}`
          }


        axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
        .then(res => {  
            this.setState({
              companyprofile: res.data
            })
    
        })


        const profileID=this.props.match.params.profileID;
        axios.get(`${serverconfig.backendserverurl}/api/supersaveprofile/${profileID}`)
        .then(res => {  
            this.setState({
              supersaveprofile: res.data
            })

            this.setState({saving_period:res.data.saving_period})
            this.setState({datarequested:false})

            this.setState({date:res.data.date})
            this.setState({supersavertype:res.data.supersavertype})
            //get suprsave type object
            axios.get(`${serverconfig.backendserverurl}/api/supersavertype/${res.data.supersavertype}`)
            .then(res => {  
                this.setState({supersavertype_obj: res.data})
            })

        })


        axios.get(serverconfig.backendserverurl+`/api/supersavertype/?sacco=${sacco}`)
        .then(res => {
            this.setState({
              supersavertypes:res.data
            })
            this.setState({datarequested:false})
        })

    }

    handleIdTypeNameChange= (e) => this.setState({ idtypeName: e.target.value});
    handleIDDescriptionChange= (e) => this.setState({ idtypeDescription: e.target.value});
  

    //submit button pressed
  handleFormSubmit=() =>{
    this.setState({datarequested:true})

    const profileID=this.props.match.params.profileID;

    let form_data1 = new FormData();
    this.state.physical_form==null?
    console.log("No physical file")
    :
    form_data1.append('physical_form', this.state.physical_form, this.state.physical_form?this.state.physical_form.name:"");
  
    axios.put(serverconfig.backendserverurl+`/api/supersaveformuploadprofile/${profileID}/`, form_data1, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
    .then(res =>{console.log("Uploaded form")}).catch(error => console.log(error))
   //uploaded file



    let form_data = new FormData();
    form_data.append('supersavertype', this.state.supersavertype);
    form_data.append('maturity_date', this.calculate_maturitydate());

    axios.put(`${serverconfig.backendserverurl}/api/supersaveprofile/${profileID}/`,form_data, {
        headers: {
        'content-type': 'multipart/form-data'
        }
    })
    .then(res =>this.setState({datarequested:false}),window.location.reload(false),message.info('Successfully updated ID'))
    .catch(error => console.log(error))




  }


    //calculate maturity date
    calculate_maturitydate=()=>{
      var days=Number(this.state.supersavertype_obj.number_of_years)*365
      var m_date=''

      //get the application date
      var dataSplit = this.state.date.split('/');
      var dateConverted;
      if (dataSplit[2].split(" ").length > 1) {

          var hora = dataSplit[2].split(" ")[1].split(':');
          dataSplit[2] = dataSplit[2].split(" ")[0];
          dateConverted = new Date(dataSplit[2], dataSplit[1]-1, dataSplit[0], hora[0], hora[1]);

      } else {
          dateConverted = new Date(dataSplit[2], dataSplit[1] - 1, dataSplit[0]);
      }

      var year = dateConverted.getFullYear();
      var month = dateConverted.getMonth();
      var date = dateConverted.getDate();

      var paydate=new Date(year,month, date+days).toLocaleDateString('en-GB', {day : 'numeric',month : 'numeric',year : 'numeric'}).split(' ').join('/');

      m_date=paydate

      return m_date;
    }



    render(){

        if(this.state.datarequested===true){
            return(
              <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin indicator={antIcon} />
              </div>
            )
      
          }else{
            return(
                <div>
                    
                    <Row>
                    <Col xs="12" sm="6" lg="6">
                    <Card 
                    style={{padding:10}}
                    hoverable>
                  <h2>Super Save details</h2>

                  <h4>Account Type Name: {this.state.supersaveprofile.account_type_name}</h4>
                  <h4>Member Account: {this.state.supersaveprofile.AccountNumber}</h4>
                  <h4>Member Fullname: {this.state.supersaveprofile.FullName}</h4>
                  <h4>SuperSave Balance: {this.state.supersaveprofile.supersaver_balance}</h4>
                  <h4>Shares At Application: {this.state.supersaveprofile.savings_atapplicatiom}</h4>
                  <h4>Savings At Application: {this.state.supersaveprofile.shares_atapplicatiom}</h4>
                  <h4>Date of application: {this.state.supersaveprofile.date}</h4>
                  <h4>Date of maturity: {this.state.supersaveprofile.maturity_date}</h4>
                  <h4>Saving period: {this.state.supersaveprofile.saving_period} years</h4>

              </Card>

              </Col>

              <Col xs="12" sm="6" lg="6">
                    <Card 
                    style={{padding:10}}
                    hoverable>
                  <h4>Super Save Update</h4>
                  <Form>
                  <h3>Super Saver Account Type</h3>


                  <FormItem label="Change date of Application"
                      name='date'
                      rules={[
                        {
                          required: true,
                          message: 'Please Select date',
                        },
                      ]}
                    >
                        <DatePicker value={moment()} onChange={(date, dateString)=>{this.setState({ date: dateString})}} format={dateFormat} />
                    </FormItem>



                  <FormItem label="SuperSaver Account Type"
                  >
                      <Select placeholder="Super Saver Type" 
                      style={{  }}
                       value={this.state.supersavertype}
                        onChange={(val)=>{
                          this.setState({supersavertype:val})
                        
                          //get suprsave type object
                          axios.get(`${serverconfig.backendserverurl}/api/supersavertype/${val}`)
                          .then(res => {  
                              this.setState({supersavertype_obj: res.data})
                          })
                        
                        }}
                        showSearch
                        optionFilterProp="children"
                        >
                        {this.state.supersavertypes.map(
                          (type)=>(
                            <Option value={type.id}>{type.account_type_name}</Option>
                          ))}
                      </Select>
                  </FormItem>
                  <h3>Maturity date: {this.calculate_maturitydate()}</h3>

                  <FormItem label="Physical Application Form" style={{marginRight:3}}>
                  <Input type="file" accept=".pdf" placeholder="Physical Application Form" 
                  
                        onChange={(val)=>{
                          var kilbyes=val.target.files[0].size/1024
                          if (kilbyes>Number(this.state.companyprofile.maximum_upload_document_size)){
                            message.error("File is greater than "+String(this.state.companyprofile.maximum_upload_document_size)+" Kbs .It will not be uploaded.")
                          }else{
                            this.setState({physical_form:val.target.files[0]})
                          }
                          
                          }}/>
                  </FormItem> 


                  
                  <FormItem>
                  <Button onClick={(event) => this.handleFormSubmit()} type="primary" htmlType="button">Update</Button>
                  </FormItem>
                  </Form>                     
                      
              </Card>
              
              </Col>
              </Row>

              <br/>

          </div>
            )

          }

       
    }
}

export default SuperSaveDetail; 