import React,{ useRef } from 'react';
import axios from 'axios'
import { Tooltip,Popover,Result,Table,Popconfirm,Input, Button,Collapse,Card,Avatar,Modal,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { PrinterOutlined, MinusOutlined, CloseCircleFilled, CheckCircleOutlined, UserOutlined } from '@ant-design/icons';
import { DeleteOutlined,SearchOutlined,CameraOutlined,PlusOutlined,EditOutlined,PlusSquareFilled,SaveOutlined,LoadingOutlined } from '@ant-design/icons';
import QrReader from 'react-qr-reader'
import uuid from 'react-uuid'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
import PrintTemplate from 'react-print';
import ReactToPrint from 'react-to-print';
import {
  Col,
  Row,
} from 'reactstrap';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import * as reactstrp from 'reactstrap';
import { ToWords } from 'to-words';
import AccountWithdrawTeebaGooloView from './AccountWithdrawTeebaGooloView'
import AccountWithdrawSuperSave from './AccountWithdrawSuperSaveView'
import AccountWithdrawView from './AccountWithdrawView'

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { Title } = Typography;
const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

const toWords = new ToWords();

message.config({
  top: 200,
  duration: 2,
  maxCount: 3,
  rtl: true,
});


var sacco= ''
var username=''
var token= ''
var bizuserid= ''

class AcWithdraw extends React.Component {

  state = {
    withdrawmode:'',
  }

  callback(key) {
    console.log(key);
  }

  componentDidMount(){
      if(localStorage.getItem("sacco")){
        sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
        username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    
    }else{
        sacco= ''
        username=''
    }
    
    if(localStorage.getItem("token")){
        token= localStorage.getItem("token")
    }else{
        token= ''
    }

    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
   }else{
      bizuserid= ''
   }
    
    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }
    
  }

  
  ////////////////////////////////////
  // PRODUCT SEARCH SELECT METHODS
   onBlur=()=> {
    console.log('blur');
  }
  
   onFocus=()=>{
    console.log('focus');
  }
  
  onSearch=(val)=> {
    console.log('search:', val);
  }



  ////////////////////////////////////////////
  // RENDERING METHOD HERE
  render() {
      return(
        <div>
        <FormItem label={<h3>Account Withdraw mode</h3>}>
        <Select 
        placeholder="Account Withdraw mode" 
        style={{  }} 
        value={this.state.withdrawmode}
        onChange={(val)=>{this.setState({withdrawmode:val})}} 
        showSearch
        optionFilterProp="children"
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        onSearch={this.onSearch}                  
        >
        <Option value={'savings'}>Savings withdraw</Option>
        <Option value={'supersave'}>Super Save - ordinary transfer</Option>
        <Option value={'teebagoolo'}>Teeba Goolo - ordinary transfer</Option>
        </Select>
       </FormItem>
       <br></br>
       {
        this.state.withdrawmode==="savings"?
        <AccountWithdrawView />

        :
        null
       }

      {
        this.state.withdrawmode==="supersave"?
        <AccountWithdrawSuperSave />
        :
        null
       }


      {
        this.state.withdrawmode==="teebagoolo"?
        <AccountWithdrawTeebaGooloView />
        :
        null
       }

        </div>
      )

  }
}

export default AcWithdraw; 
