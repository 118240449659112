import React from 'react';

import axios from 'axios'
import { Table, Input, Button,Collapse,Popover,DatePicker,Popconfirm,message,Card,Tooltip,Descriptions,Modal,Spin,Tabs,Form } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,PrinterOutlined,DeleteOutlined,FundViewOutlined,WalletFilled,DownloadOutlined,LoadingOutlined,FilePdfFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import  LoanImportForm from '../components/LoanImportForm'
import {
  Col,
  Row,
} from 'reactstrap';
import * as reactstrp from 'reactstrap';
import ReactToPrint from 'react-to-print';
import ReactExport from "@ibrahimrahmani/react-export-excel";
import '../tablestyle.css'; // Create this CSS file for custom styles
import  LoanCertificateGenerationForm from '../components/LoanCertificateGenerationForm'
import LoanExtras from './LoanExtras';
import '../../App.css'; // Create this file for custom styles


var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const { RangePicker } = DatePicker;
const dateFormat = 'DD/MM/YYYY';

const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { Search } = Input;
const FormItem=Form.Item;


function callback(key) {
  console.log(key);
}

const { TabPane } = Tabs;


var sacco= ''
var bizuserid= ''
var token= ''

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


const rowClassName = (record) => {
  return record.account_status === "inactive" ? 'highlighted-row' : '';
};

class ReceiptToPrint extends React.Component {

  state = {
    companyprofile:{}
  };

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })

  

  }

//calculate principleTotal
calculateScheduleTotal=()=>{
  var principleTotal=0
  this.props.loanschedule.map(
    (item)=>{
      principleTotal+=Number(item.principle)
    })

  return principleTotal.toFixed(2);
}

  //calculate principleTotal
  calculateInterestTotal=()=>{
    var InterestTotal=0
    this.props.loanschedule.map(
      (item)=>{
        InterestTotal+=Number(item.interest)
      })

    return InterestTotal.toFixed(2);
  }

  //calculate principleTotal
  calculateTotalPay=()=>{
    var TotalPay=0
    this.props.loanschedule.map(
      (item)=>{
        TotalPay+=Number(item.total)
      })

    return TotalPay.toFixed(2);
  }



  render() {
    return (
      <div style={{padding:20}}>
        <style>
          {`@media print {
          @page { size: landscape; }
            }`}
        </style>
            
      <Row> 
                <Col xs="2" sm="2" lg="2">
                <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.sacco_logo} />
              
                  </Col>
                  <Col xs="6" sm="6" lg="6">
                  <h3>{this.state.companyprofile.sacco_name}</h3>
                  <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                  {
                  this.state.companyprofile.RegisteredPhoneNumber2==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber2
                  }
                  {
                  this.state.companyprofile.RegisteredPhoneNumber3==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber3
                  }
                  </h6>               
                  <h5>Email: {this.state.companyprofile.company_email}</h5>
                  <h5>Wesite: {this.state.companyprofile.website}</h5>
                  <h5>{this.state.companyprofile.box_number}</h5>

                  </Col>

              </Row>
                  <h3 style={{fontWeight:'bolder',display: 'flex',justifyContent:'center', alignItems:'center'}}> LOAN SCHEDULE </h3>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> ACCOUNT NUMBER:  {this.props.loanissueobject.accountNumber} </h4>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> ACCOUNT NAME:  {this.props.loanissueobject.accountName} </h4>

                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> AMOUNT: <CurrencyFormat value={this.props.loanissueobject.approved_amount} displayType={'text'} thousandSeparator={true}/> </h4>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> PERIOD: {this.props.loanissueobject.loan_period} {this.props.loanissueobject.loan_primary_schedule==="daily"?"Days":this.props.loanissueobject.loan_primary_schedule==="weekly"?"Weeks":"Months"}</h4>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> INTEREST RATE: {this.props.interest_rate}</h4>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'end'}}> DATE OF ISSUANCE: {this.props.loanissueobject.date}</h4>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'end'}}> LOAN NUMBER: {this.props.loanissueobject.loan_number}</h4>

                  <reactstrp.Table bordered>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Date</th>
                            <th>Month</th>
                            <th>Principle Bf</th>
                            <th>Installment</th>
                            <th>Interest </th>
                            <th>Principle </th>
                            <th>Principle Bal</th>
                          </tr>
                        </thead>
                        <tbody>
                        {this.props.loanschedule.map(
                          (item)=>(
                            <tr>
                            <td>{item.repayment_number}</td>
                            <td>{item.date}</td>
                            <td>{item.payment_month}</td>
                            <td className="align-right">{<CurrencyFormat value={item.principlebroughtforward} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td className="align-right">{<CurrencyFormat value={item.total} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td className="align-right">{<CurrencyFormat value={item.interest} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td className="align-right">{<CurrencyFormat value={item.principle} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td className="align-right">{<CurrencyFormat value={item.balance} displayType={'text'} thousandSeparator={true}/>}</td>
                            </tr>
                          ))}
                          <tr>
                          <th>Total</th>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalPay()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateInterestTotal()} displayType={'text'} thousandSeparator={true}/>}</td> 
                          <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateScheduleTotal()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td></td>
                          </tr>

                      </tbody>
                  </reactstrp.Table>

    </div>
    );
  }
}


class StatementToPrint extends React.Component {

  state = {
    companyprofile:{}
  };

  componentDidMount(){
    if(localStorage.getItem("sacco")){
      sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
   }else{
      sacco= ''
   }
   
   if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })

  }

    //calculate principleTotal
    calculateTotalPay=()=>{
      var TotalPay=0
      this.state.loanschedule.map(
        (item)=>{
          TotalPay+=Number(item.total)
        })
  
      return TotalPay.toFixed(2);
    }


      //calculate principle paid
      calculateTotalInterestcharged=()=>{
      var TotalPay=0
      this.props.loanrepayments.map(
        (item)=>{
          TotalPay+=Number(item.interest_charged)
        })
  
      return TotalPay.toFixed(2);
     }
  
      //calculate Interest paid
      calculateTotalInterestPaid=()=>{
        var TotalPay=0
        this.props.loanrepayments.map(
          (item)=>{
            TotalPay+=Number(item.interest_paid)
          })
    
        return TotalPay.toFixed(2);
       }

             //calculate Total total paid
      calculateTotalprinciplepaid=()=>{
        var TotalPay=0
        this.props.loanrepayments.map(
          (item)=>{
            TotalPay+=Number(item.principle_paid)
          })
    
        return TotalPay.toFixed(2);
       }

       //calculate total fine paid
       calculateTotalFinePaid=()=>{
        var TotalPay=0
        this.props.loanrepayments.map(
          (item)=>{
            TotalPay+=Number(item.fine_paid)
          })
    
        return TotalPay.toFixed(2);
       }


  render() {
    return (
      <div style={{padding:20}}>      
      <style>
        {`@media print {
        @page { size: landscape; }
          }`}
      </style>

      <Row> 
            <Col xs="2" sm="2" lg="2">
            <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.sacco_logo} />
          
              </Col>
              <Col xs="6" sm="6" lg="6">
              <h3>{this.state.companyprofile.sacco_name}</h3>
              <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
              {
              this.state.companyprofile.RegisteredPhoneNumber2==""?
              "":","+this.state.companyprofile.RegisteredPhoneNumber2
              }
              {
              this.state.companyprofile.RegisteredPhoneNumber3==""?
              "":","+this.state.companyprofile.RegisteredPhoneNumber3
              }
              </h6>               
              <h5>Email: {this.state.companyprofile.company_email}</h5>
              <h5>Wesite: {this.state.companyprofile.website}</h5>
              <h5>{this.state.companyprofile.box_number}</h5>

              </Col>

          </Row>
              <h3 style={{fontWeight:'bolder',display: 'flex',justifyContent:'center', alignItems:'center'}}> LOAN TRACKING STATEMENT </h3>
              <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> ACCOUNT NUMBER:  {this.props.loanissueobject.accountNumber} </h4>
              <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> ACCOUNT NAME:  {this.props.loanissueobject.accountName} </h4>
              <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> AMOUNT: <CurrencyFormat value={this.props.loanissueobject.approved_amount} displayType={'text'} thousandSeparator={true}/> UGX</h4>
              <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> PERIOD: {this.props.loanissueobject.loan_period} {this.props.loanissueobject.loan_primary_schedule==="daily"?"Days":this.props.loanissueobject.loan_primary_schedule==="weekly"?"Weeks":"Months"}</h4>
              <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> INTEREST RATE: {this.props.interest_rate}</h4>
              <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'end'}}> DATE OF ISSUANCE: {this.props.loanissueobject.date}</h4>
              <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'end'}}> LOAN NUMBER: {this.props.loanissueobject.loan_number}</h4>

              <reactstrp.Table bordered>
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>No Of days</th>
                        <th>Open R/Ln Bal.</th>
                        <th>Installment Paid</th>
                        <th>Interest charged</th>
                        <th>Int Paid</th>
                        <th>Principle1 (Paid)</th>
                        <th>Unpaid Int</th>
                        <th>R/Int</th>
                        <th>R/Princ or Loan Issued</th>
                        <th>Outstanding Bal</th>
                        <th>Payment Details</th>
                      </tr>
                    </thead>
                    <tbody>
                    {this.props.loanrepayments.map(
                      (item)=>(
                        <tr>
                        <td>{item.date}</td>
                        <td>{item.no_ofdays}</td>
                        <td className="align-right">{<CurrencyFormat value={item.open_runningbal.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td className="align-right">{<CurrencyFormat value={item.installment_paid.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td className="align-right">{<CurrencyFormat value={item.interest_charged.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td className="align-right">{<CurrencyFormat value={item.interest_paid.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td className="align-right">{<CurrencyFormat value={item.principle_paid.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>

                        <td className="align-right">{<CurrencyFormat value={item.interest_unpaid.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td className="align-right">{<CurrencyFormat value={item.running_int.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                        
                        <td className="align-right">{<CurrencyFormat value={item.running_principle.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td className="align-right">{<CurrencyFormat value={item.outstanding_bal.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td>{item.description}</td>

                        </tr>
                      ))}
                      <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>

                      <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalInterestcharged()} displayType={'text'} thousandSeparator={true}/>}</td>
                      <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalInterestPaid()} displayType={'text'} thousandSeparator={true}/>}</td>
                      <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalprinciplepaid()} displayType={'text'} thousandSeparator={true}/>}</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      </tr>

                  </tbody>
              </reactstrp.Table>   
    </div>
    );
  }
}




class LoanIssuanceList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    loanissues: [],
    loanissues_placeholder: [],
    inactiveloanissues: [],
    inactiveloanissues_placeholder: [],
    fullypaid_loans: [],
    fullypaid_loans_placeholder: [],

    viewpaymentmodal:false,
    expensedetails:{},
    userprofile:{},
    datarequested:true,

    loansecurites: [],
    securitesoverroleTotal: 0,
    securitesoverroleTotal_percentage: 0,

    loanschedule:[],
    loanissueobject:{},
    loanrepayments:[],
    loandailyschedules:[],

    deletelist:[],
    totaltobepaidobject:{},
    interestpay:0,

    newreschdate:'',
    newreschamt:0,

    loansecurity_freeing:[],
    current_selected_loan:0,
    loan_particular_data_loading:false,

    dateone:'',
    datetwo:'',


  };


  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    
    }else{
       sacco= ''
       bizuserid= ''
    
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    let form_data = new FormData();
    form_data.append('userid', bizuserid);
    
    //get the user profile here
    axios.post(serverconfig.backendserverurl+'/customqueries/get_user_profile',form_data,{
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res => {
        this.setState({
          userprofile:res.data
        })
     
    })

    

    axios.get(serverconfig.backendserverurl+`/customqueries/get_allloans/${sacco}`)
    .then(res => {
        this.setState({
          loanissues:JSON.parse(res.data.report)
        })

        this.setState({
          fullypaid_loans:JSON.parse(res.data.report_fullypaid)
        })

        this.setState({
          loanissues_placeholder:JSON.parse(res.data.report)
        })

        this.setState({
          fullypaid_loans_placeholder:JSON.parse(res.data.report_fullypaid)
        })

        this.setState({datarequested:false})
    })

}




getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};



  //calculate principleTotal
  calculateScheduleTotal=()=>{
    var principleTotal=0
    this.state.loanschedule.map(
      (item)=>{
        principleTotal+=Number(item.principle)
      })

    return principleTotal.toFixed(2);
  }

    //calculate principleTotal
    calculateInterestTotal=()=>{
      var InterestTotal=0
      this.state.loanschedule.map(
        (item)=>{
          InterestTotal+=Number(item.interest)
        })
  
      return InterestTotal.toFixed(2);
    }

    //calculate principleTotal
    calculateTotalPay=()=>{
      var TotalPay=0
      this.state.loanschedule.map(
        (item)=>{
          TotalPay+=Number(item.total)
        })
  
      return TotalPay.toFixed(2);
    }


      //calculate principle paid
      calculateTotalInterestcharged=()=>{
      var TotalPay=0
      this.state.loanrepayments.map(
        (item)=>{
          TotalPay+=Number(item.interest_charged)
        })
  
      return TotalPay.toFixed(2);
     }
  
      //calculate Interest paid
      calculateTotalInterestPaid=()=>{
        var TotalPay=0
        this.state.loanrepayments.map(
          (item)=>{
            TotalPay+=Number(item.interest_paid)
          })
    
        return TotalPay.toFixed(2);
       }

             //calculate Total total paid
      calculateTotalprinciplepaid=()=>{
        var TotalPay=0
        this.state.loanrepayments.map(
          (item)=>{
            TotalPay+=Number(item.principle_paid)
          })
    
        return TotalPay.toFixed(2);
       }

       //calculate total fine paid
       calculateTotalFinePaid=()=>{
        var TotalPay=0
        this.state.loanrepayments.map(
          (item)=>{
            TotalPay+=Number(item.fine_paid)
          })
    
        return TotalPay.toFixed(2);
       }


       //calculate total fine paid
       convert_calinterest=()=>{
        var Val_u=0

        if(this.state.loanissueobject.loan_primary_schedule==="monthly"){
          Val_u=Number(this.state.loanissueobject.loan_annual_interestrate)/12
        }else if(this.state.loanissueobject.loan_primary_schedule==="weekly"){
          Val_u=Number(this.state.loanissueobject.loan_annual_interestrate)/52
        }else if(this.state.loanissueobject.loan_primary_schedule==="daily"){
          Val_u=Number(this.state.loanissueobject.loan_annual_interestrate)/365
        }

        return Val_u.toFixed(2);
       }


       //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
       //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
       // method for securities
       calculate_principle_paid_sec=()=>{
        var total=0

        this.state.loansecurity_freeing.map(
          (item)=>{
            total+=Number(item.principle_paid)
          })

        return total.toFixed(2);
       }

       calculate_savings_paid_sec=()=>{
        var total=0
        this.state.loansecurity_freeing.map(
          (item)=>{
            total+=Number(item.savings_amount_paid)
          })
        return total.toFixed(2);

       }

       calculate_savings_freed_sec=()=>{
        var total=0
        this.state.loansecurity_freeing.map(
          (item)=>{
            total+=Number(item.savings_amount_freed)
          })
        return total.toFixed(2);
       }


       calculate_supersave_paid_sec=()=>{
        var total=0
        this.state.loansecurity_freeing.map(
          (item)=>{
            total+=Number(item.supersave_amount_paid)
          })
        return total.toFixed(2);
       }

       calculate_supersave_freed_sec=()=>{
        var total=0
        this.state.loansecurity_freeing.map(
          (item)=>{
            total+=Number(item.supersave_amount_freed)
          })
        return total.toFixed(2);
       }


       calculate_teebagoolo_paid_sec=()=>{
        var total=0
        this.state.loansecurity_freeing.map(
          (item)=>{
            total+=Number(item.teebagoolo_amount_paid)
          })
        return total.toFixed(2);
       }

       calculate_teebagoolo_freed_sec=()=>{
        var total=0
        this.state.loansecurity_freeing.map(
          (item)=>{
            total+=Number(item.teebagoolo_amount_freed)
          })
        return total.toFixed(2);
       }

       calculate_shares_paid_sec=()=>{
        var total=0
        this.state.loansecurity_freeing.map(
          (item)=>{
            total+=Number(item.shares_amount_paid)
          })
        return total.toFixed(2);
       }

       calculate_shares_freed_sec=()=>{
        var total=0
        this.state.loansecurity_freeing.map(
          (item)=>{
            total+=Number(item.shares_amount_freed)
          })
        return total.toFixed(2);
       }

       /////////////////////////
      ///others start here
      ///////////////
       calculate_project_interest_paid_sec=()=>{
        var total=0
        this.state.loansecurity_freeing.map(
          (item)=>{
            total+=Number(item.project_interest_paid)
          })
        return total.toFixed(2);
       }

       calculate_project_interest_freed_sec=()=>{
        var total=0
        this.state.loansecurity_freeing.map(
          (item)=>{
            total+=Number(item.project_interest_freed)
          })
        return total.toFixed(2);
       }

       calculate_own_title_paid_sec=()=>{
        var total=0
        this.state.loansecurity_freeing.map(
          (item)=>{
            total+=Number(item.own_title_paid)
          })
        return total.toFixed(2);
       }

       calculate_own_title_freed_sec=()=>{
        var total=0
        this.state.loansecurity_freeing.map(
          (item)=>{
            total+=Number(item.own_title_freed)
          })
        return total.toFixed(2);
       }


       calculate_reapers_title_paid_sec=()=>{
        var total=0
        this.state.loansecurity_freeing.map(
          (item)=>{
            total+=Number(item.reapers_title_paid)
          })
        return total.toFixed(2);
       }

       calculate_reapers_title_freed_sec=()=>{
        var total=0
        this.state.loansecurity_freeing.map(
          (item)=>{
            total+=Number(item.reapers_title_freed)
          })
        return total.toFixed(2);
       }


       calculate_other_collateral_paid_sec=()=>{
        var total=0
        this.state.loansecurity_freeing.map(
          (item)=>{
            total+=Number(item.other_collateral_paid)
          })
        return total.toFixed(2);
       }

       calculate_other_collateral_freed_sec=()=>{
        var total=0
        this.state.loansecurity_freeing.map(
          (item)=>{
            total+=Number(item.other_collateral_freed)
          })
        return total.toFixed(2);
       }


  render() {


      var columns = [
        //Table.EXPAND_COLUMN,
        {
          title: 'Loan number',
          dataIndex: 'loan_number',
          key: 'id',
          ...this.getColumnSearchProps('loan_number'),
          render:(text,record)=>
          <div>
          <h5> {record.loan_number}</h5>

          {
            Number(record.paidtotalprinciple)>=Number(record.approved_amount)?
            <p style={{color:"green"}}>Cleared</p>
            :
            <p style={{color:record.status==="active"?"green":'red'}}>( {record.status} )</p>
          }
          </div>
        },
        {
          title: 'Loan top up?',
          dataIndex: 'loantopup',
          key: 'id',
          ...this.getColumnSearchProps('loantopup'),
          render:(text,record)=>
          <p>
            {text} | 
            {text==="Yes"?<Link to={`/loanissues/${record.loantopup_id}`}>Top up reference</Link>:null}
          </p>

        }
        ,
        {
          title: 'Loan type',
          dataIndex: 'loantypename',
          key: 'id',
          ...this.getColumnSearchProps('loantypename'),
        },

        {
          title: 'Issuance Date',
          dataIndex: 'date',
          key: 'id',
          ...this.getColumnSearchProps('date'),
        },
        {
          title: 'Maturity Date',
          dataIndex: 'maturity_date',
          key: 'id',
          ...this.getColumnSearchProps('maturity_date'),
        },
        {
          title: 'Loan Period',
          dataIndex: 'loan_period',
          key: 'id',
          render:(text,record)=><h5>{text} ({record.loan_primary_schedule})</h5>
        },
        {
          title: 'Account Number',
          dataIndex: 'accountNumber',
          key: 'id',
          ...this.getColumnSearchProps('accountNumber'),
        },
        {
          title: 'Account Name',
          dataIndex: 'accountName',
          key: 'id',
          ...this.getColumnSearchProps('accountName'),
        },
        {
          title: 'Loan Amount',
          dataIndex: 'approved_amount',
          key: 'id',
          render:text=><CurrencyFormat value={Number(text).toFixed(2)} displayType={'text'} thousandSeparator={true}  />
        },
        {
          title: 'Cycles defaulted',
          dataIndex: 'cycles_due',
          key: 'id',
        },
        {
          title: 'Principle due',
          dataIndex: 'principle_due',
          key: 'id',
          render:text=><CurrencyFormat value={Number(text).toFixed(2)} displayType={'text'} thousandSeparator={true}  />
        },
        {
          title: 'Interest due',
          dataIndex: 'intrest_due',
          key: 'id',
          render:text=><CurrencyFormat value={Number(text).toFixed(2)} displayType={'text'} thousandSeparator={true}  />
        },

        {

          title: 'Principle paid',
          dataIndex: 'paidtotalprinciple',
          key: 'id',
          render:text=><CurrencyFormat value={Number(text).toFixed(2)} displayType={'text'} thousandSeparator={true}  />
        },
        {
          title: 'Interest paid',
          dataIndex: 'paidtotalinterest',
          key: 'id',
          render:text=><CurrencyFormat value={Number(text).toFixed(2)} displayType={'text'} thousandSeparator={true}  />
        },
       

        {
          title: 'Running principle balance',
          dataIndex: 'runningprinciple_balance',
          key: 'id',
          render:text=><CurrencyFormat value={Number(text).toFixed(2)} displayType={'text'} thousandSeparator={true}  />
        },
        {
          title: 'Running interest balance',
          dataIndex: 'runningint_balance',
          key: 'id',
          render:text=><CurrencyFormat value={Number(text).toFixed(2)} displayType={'text'} thousandSeparator={true}  />
        },

        {
          title: 'Action',
          dataIndex: 'id',
          key: 'id',
          render: (text,record) =>
          <p>
          <Popover content={<p>Click here to view details(Schedule & Statement)</p>} title="View">
           <Link to={`/loanissues/${text}`}><FundViewOutlined style={{color:'blue'}}/></Link>
          </Popover>         
         
      
          </p>,
        }
  
      ];


    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )
      
    }else{
      return(
        <div>

    <div style={{display: "none"}}>
      &nbsp;&nbsp;
      <ReceiptToPrint
      loanschedule={this.state.loanschedule} 
      date={this.state.datetoday} 
      loanissueobject={this.state.loanissueobject}
      interest_rate={this.convert_calinterest()}
      ref={el => (this.componentRef = el)} /> 
      </div>

      <div style={{display: "none"}}>
       &nbsp;&nbsp;
      <StatementToPrint
      loanrepayments={this.state.loanrepayments} 
      date={this.state.datetoday} 
      loanissueobject={this.state.loanissueobject}
      interest_rate={this.convert_calinterest()}
      ref={sel => (this.componentReftwo = sel)} /> 
      </div>


      <Form layout='inline'>
        
        <FormItem label="Date Range">
           <RangePicker onChange={
              (date, dateString) =>{
                this.setState({ dateone: dateString[0]});
                this.setState({ datetwo: dateString[1]});
              }
           } format={dateFormat} />
        </FormItem>


            <FormItem>
            <Button onClick={()=>{

                let form_data = new FormData();
                form_data.append('sacco', sacco);
                form_data.append('dateone', this.state.dateone);
                form_data.append('datetwo', this.state.datetwo);
                if(this.state.dateone===''||this.state.datetwo===''){
                  message.error("Please dates are missing")
                }else{
                    
                      this.setState({datarequested:true})
              
                      //Now submit sale data to database
                      axios.post(serverconfig.backendserverurl+'/customqueries/get_allloans_bydate', form_data,{
                      headers: {
                        'content-type': 'multipart/form-data'
                      }
                      })
                      .then(res =>{
                        this.setState({
                          loanissues:JSON.parse(res.data.report)
                        })
                
                        this.setState({
                          fullypaid_loans:JSON.parse(res.data.report_fullypaid)
                        })
                
                        this.setState({
                          loanissues_placeholder:JSON.parse(res.data.report)
                        })
                
                        this.setState({
                          fullypaid_loans_placeholder:JSON.parse(res.data.report_fullypaid)
                        })
                
                        this.setState({datarequested:false})

                        })
                      .catch(error => console.log(error))   

              }

            }} type="primary" >Search</Button>
            </FormItem> 
       </Form>



       {
  /*

  ACTIVE FULLY PAID LOANS


  */



}


    <Tabs defaultActiveKey="1" onChange={callback}>
     <TabPane tab="Active Loans" key="1">
           <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header={<h4 style={{fontWeight:'bolder'}}>ALL ISSUED LOANS</h4>} key="1">
            <br></br>

            <Form layout='inline'>
            <FormItem label="Download" >
            <ExcelFile filename={"Pisys - Active loans report"}  element={<Button type='primary' icon={<DownloadOutlined/>}>Download (Excel)</Button>}>
                <ExcelSheet data={this.state.loanissues} name="Active loans">
                    <ExcelColumn label="Loan number" value="loan_number"/>
                    <ExcelColumn label="Loan topup?" value="loantopup"/>
                    <ExcelColumn label="Loan type" value="loantypename"/>
                    <ExcelColumn label="Loan duration" value="loan_period"/>
                    <ExcelColumn label="Date" value="date"/>
                    <ExcelColumn label="Maturity date" value="maturity_date"/>
                    <ExcelColumn label="Account number" value="accountNumber"/>
                    <ExcelColumn label="Account name" value="accountName"/>
                    <ExcelColumn label="Loan amount" value="approved_amount"/>
                    <ExcelColumn label="Due cycles " value="cycles_due"/>
                    <ExcelColumn label="Principle due " value="principle_due"/>
                    <ExcelColumn label="Interest due " value="intrest_due"/>
                    <ExcelColumn label="Principle paid " value="paidtotalprinciple"/>
                    <ExcelColumn label="Interest paid " value="paidtotalinterest"/>

                    <ExcelColumn label="Running principle balance " value="runningprinciple_balance"/>
                    <ExcelColumn label="Running interest balance " value="runningint_balance"/>

                </ExcelSheet>
            </ExcelFile>
            </FormItem>


            <FormItem label="Search" >
            <Search
            placeholder="AccountNumber or AccountName"
            onChange={(value) => {
              var val=value.target.value
              console.log(val)
              if (val !== '') {
                const results = this.state.loanissues.filter((request) => {
                  return request.accountName.toLowerCase().includes(val.toLowerCase());
                  // Use the toLowerCase() method to make it case-insensitive
                });
          
                if (results.length === 0) {
                  // this.setState({loanissues:this.state.loanissues_placeholder});

                  const results_2 = this.state.loanissues.filter((request) => {
                    return request.accountNumber.includes(val);
                    // Use the toLowerCase() method to make it case-insensitive
                  });

                  if (results_2.length === 0) {
                    this.setState({loanissues:this.state.loanissues_placeholder});

                  }else{
                    this.setState({loanissues:results_2});

                  }

                } else {
                  this.setState({loanissues:results});
                  console.log(results)
                }
          
              } else {
                this.setState({loanissues:this.state.loanissues_placeholder});
          
              }
          
            }}
            style={{
              width: 400,
            }}
            />
            </FormItem>

            </Form>

            
            <Table  
            size='small'
            rowkey={(record) => record.id}
            columns={columns} 
            expandable={{
              expandedRowRender: (record) =>{
                return (
                <div
                  style={{
                    margin: 0,
                  }}
                  >
                <LoanExtras loan_id={record.id} loan_issueobj={record} />
                </div>
                );
               
              } ,
              rowExpandable: (record) => record.id !== this.state.current_selected_loan.id,
              onExpand:(condition,record)=>{
              },
            }}
            scroll={{ x: 2000 }}
            dataSource={this.state.loanissues} 
            pagination={{showQuickJumper:true,showSizeChanger:true }}
            bordered
            />
            <h3>Total Loans: {this.state.loanissues.length}</h3>

            </Panel>
            </Collapse>
    </TabPane>


{
  /*

  INACTIVE FULLY PAID LOANS


  */



}



        <TabPane tab="Inactive Fully Paid" key="2">
         <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header={<h4 style={{fontWeight:'bolder'}}>ALL ISSUED LOANS</h4>} key="1">
            
            <br></br>
            <Form layout='inline'>
            <FormItem label="Download" >

             <ExcelFile filename={"Pisys - Inactive/Fully paid loans report"}  element={<Button type='primary' icon={<DownloadOutlined/>}>Download (Excel)</Button>}>
                <ExcelSheet data={this.state.fullypaid_loans} name="Cleared loans">
                    <ExcelColumn label="Loan number" value="loan_number"/>
                    <ExcelColumn label="Loan topup?" value="loantopup"/>
                    <ExcelColumn label="Loan type" value="loantypename"/>
                    <ExcelColumn label="Loan duration" value="loan_period"/>
                    <ExcelColumn label="Date" value="date"/>
                    <ExcelColumn label="Maturity date" value="maturity_date"/>
                    <ExcelColumn label="Account number" value="accountNumber"/>
                    <ExcelColumn label="Account name" value="accountName"/>
                    <ExcelColumn label="Loan amount" value="approved_amount"/>
                    <ExcelColumn label="Due cycles " value="cycles_due"/>
                    <ExcelColumn label="Principle due " value="principle_due"/>
                    <ExcelColumn label="Interest due " value="intrest_due"/>
                    <ExcelColumn label="Principle paid " value="paidtotalprinciple"/>
                    <ExcelColumn label="Interest paid " value="paidtotalinterest"/>

                    <ExcelColumn label="Running principle balance " value="runningprinciple_balance"/>
                    <ExcelColumn label="Running interest balance " value="runningint_balance"/>

                </ExcelSheet>
            </ExcelFile>

            </FormItem>


            <FormItem label="Search" >
              
            <Search
                placeholder="AccountNumber or AccountName"
                onChange={(value) => {
                  var val=value.target.value
                  console.log(val)
                  if (val !== '') {
                    const results = this.state.fullypaid_loans.filter((request) => {
                      return request.accountName.toLowerCase().includes(val.toLowerCase());
                      // Use the toLowerCase() method to make it case-insensitive
                    });
              
                    if (results.length === 0) {
                      // this.setState({loanissues:this.state.loanissues_placeholder});

                      const results_2 = this.state.fullypaid_loans.filter((request) => {
                        return request.accountNumber.includes(val);
                        // Use the toLowerCase() method to make it case-insensitive
                      });

                      if (results_2.length === 0) {
                        this.setState({fullypaid_loans:this.state.fullypaid_loans_placeholder});

                      }else{
                        this.setState({fullypaid_loans:results_2});

                      }

                    } else {
                      this.setState({fullypaid_loans:results});
                      console.log(results)
                    }
              
                  } else {
                    this.setState({fullypaid_loans:this.state.fullypaid_loans_placeholder});
              
                  }
              
                }}
                style={{
                  width: 400,
                }}
            />

            </FormItem>

            </Form>

            <br></br>
            <br></br>


            <Table  
            size='small'
            rowkey={(record) => record.id}
            columns={columns} 
            expandable={{
              expandedRowRender: (record) =>{
              return (
                <div  style={{margin: 0,}}>
                  <LoanExtras loan_id={record.id} loan_issueobj={record} />
                </div>
                );
              } ,
              rowExpandable: (record) => record.id !== this.state.current_selected_loan.id,
              onExpand:(condition,record)=>{

              },
            }}
            scroll={{ x: 2000 }}
            dataSource={this.state.fullypaid_loans} 
            pagination={{showQuickJumper:true,showSizeChanger:true }}
            bordered
            />
            <h3>Total Loans: {this.state.fullypaid_loans.length}</h3>

          </Panel>
        </Collapse>

    </TabPane>
   </Tabs>

    </div>

    )}

  }
}

export default LoanIssuanceList; 
