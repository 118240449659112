import React from 'react';
import { Route } from 'react-router-dom';
import QuickMenu from './views/containers/QuickMenu'
import IDTypeList from './views/containers/IDListView'
import IDTypeDetail from './views/containers/IDDetailView'
import StaffRoleList from './views/containers/StaffRolesListView'
import StaffRoleDetail from './views/containers/StaffRoleDetailView'
import AccountTypeList from './views/containers/AccountTypeListView'
import AccountTypeDetail from './views/containers/AccountTypeDetailView'
import LoanTypeList from './views/containers/LoanTypeListView'
import LoanTypeDetail from './views/containers/LoanTypeDetailView'
import NonWorkingDayList from './views/containers/NonWorkingDayListView'
import NonWorkingDayDetail from './views/containers/NonWorkingDayDetailView'
import MemberList from './views/containers/MemberListView'
import MemberDetail from './views/containers/MemberDetailView'
import AccountDepositView from './views/containers/AccountDepositView'
import AccountWithdrawView from './views/containers/AccountWithdrawView'
import LoanApplicationForm from './views/components/LoanApplicationForm'
import  LoanCalculator from './views/components/LoanCalculator'
import  LoanApplicationsList from './views/containers/LoanApplicationListView'
import  LoanApplicationDetail from './views/containers/LoanApplicationDetailView'
import  LoanAssessmentForm from './views/components/LoanAssessmentForm'
import  LoanAssessmentList from './views/containers/LoanAssessmentListView'
import  LoanAppovalForm from './views/components/LoanApprovalForm'
import  LoanApprovalList from './views/containers/LoanApprovalListView'
import  LoanIssuanceForm from './views/components/LoanIssuanceForm'
import  LoanIssuanceList from './views/containers/LoanIssuancesListView'
import  LoanIssueDetail from './views/containers/LoanIssueDetails'
import  LoanRepaymentView from './views/containers/LoanRepaymentView'
import LoanGuarantorsList from './views/containers/LoanGuarantorsListView'
import LoanConsentsList from './views/containers/LoanConsentsListView'
import OpeningItemList from './views/containers/AccountOpeningItemsListView'
import TillSheetReport from './views/containers/TillSheetReportView'
import TransactionReport from './views/containers/TransactionReportView'
import MemberSavingsReport from './views/containers/MemberSavingsReportView'
import IncomeStatementReport from './views/containers/IncomeStatementView'
import DayLoanReport from './views/containers/DayLoanReportView'
import OutStandingLoanReport from './views/containers/OutStandingLoanReportView'
import LoanRepaymentStatusReport from './views/containers/LoanRepaymentStatusReportView'
import LoanAgingReport from './views/containers/LoanAgingReportView'
import LoanDisbursmentReport from './views/containers/LoanDisbursmentReportView'
import LoanRepaymentsReport from './views/containers/LoanRepaymentsReportView'
import BalanceSheetReport from './views/containers/BalanceSheetView'
import ShareTransferView from './views/containers/ShareTransferView'
import SubAccountTransferView from './views/containers/SubAccountTransferView'
import FinancialStatementReport from './views/containers/FinancialStatementReportView'
import AccountList from './views/containers/AccountsListView'
import AccountDetail from './views/containers/AccountDetailView'
import SendSMSMessageView from './views/containers/SendSMSMessageView'
import GroupList from './views/containers/GroupsListView'
import GroupDetail from './views/containers/GroupDetailView'
import DashBoard from './views/containers/DashBoard'

import IncomeStatementsDividendsReport from './views/containers/IncomeStatementDividendsView'

import MemberDepositsReport from './views/containers/MemberDepositsReportView'

import SuperSaveList from './views/containers/SuperSaveListView'

import AllTransactionsActions from './views/containers/AllTransactionActionsView'

import  SuperSaverSettingsForm from './views/components/SuperSaverSettingsForm'
import SuperSaverTypeDetail from './views/containers/SuperSaverTypeDetailView'
import AccountWithdrawSuperSave from './views/containers/AccountWithdrawSuperSaveView'
import SuperSaveOpeningForm from './views/containers/SuperSaveOpeningForm'
import SuperSaveCreateInterest from './views/containers/SuperSaveCreateInterestView'
import InterAccountTransfersSingle from './views/containers/InterAccountTransferSingleView'
import SavingsCreateInterest from './views/containers/SavingsCreateInterestView'
import  LoanRepaymentDirectView from './views/containers/LoanRepaymentDirectView'

import OrdinarySavingsInterestTransfer from './views/containers/OrdinarySavingsInterestTransfer'
import savingdefaultsavingsDefaultReport from './views/containers/MemberSavingsDefaultReportView'

import  TeebaGooloSettingsForm from './views/components/TeebaGooloSettingsForm'
import TeebaGooloTypeDetail from './views/containers/TeebaGooloTypeDetailView'
import TeebaGooloOpeningForm from './views/containers/TeebaGooloOpeningForm'

import TeebaGooloList from './views/containers/TeebaGooloListView'
import SuperSaveDetail from './views/containers/SuperSaveDetailView'

import TeebaGooloDetail from './views/containers/TeebaGooloDetailView'
import AccountWithdrawTeebaGooloView from './views/containers/AccountWithdrawTeebaGooloView'
import TeebaGooloCreateInterest from './views/containers/TeebaGooloCreateInterestView '
import SupersaveInterestTransfer from './views/containers/SuperSaveInterestTransfer'
import TeebaGooloInterestTransafer from './views/containers/TeebaGooloInterestTransfer'
import CompanyAccountList from './views/containers/CompanyAccountsListView'
import AcWithdraw from './views/containers/AcWithdrawView'
import TrialBalanceReport from './views/containers/TrialBalanceView'
import LedgerReport from './views/containers/LedgerReportView'
import DistrictList from './views/containers/UgDistrictListView'
import UgDistrictDetail from './views/containers/UgDistrictDetailView'
import  LoanRejectionList from './views/containers/LoanRejectionListView'
import  DeferedScheduleReport from './views/containers/DeferedScheduleReportView'
import  SaccovaluersList from './views/containers/SaccovaluersListView'
import  GroupSignitoryDetails from './views/containers/GroupSignitoryDetails'
import  RecycleBinList from './views/containers/RecycleBinListView'
import  SystemLogList from './views/containers/SystemLogsListView'

import WithdrawRequstionForm from './views/containers/WithdrawRequistionForm'
import WithdrawRequistionsList from './views/containers/WithdrawRequistionsListView'
import WithdrawRequstionFormEdit from './views/containers/WithdrawRequistionFormEdit'
import SaccoInterAccountTransfer from './views/containers/SaccoInterAccountsTransferView'
import SendButchEMailStatementView from './views/containers/SendButchEmailStatementView'
import JournalEntryView from './views/containers/JournalEntryView'
import CompanyProfile from './views/containers/companyProfile'
import  QuickTransact from './views/containers/QuickTransact'
import InterAccountTransfers from './views/containers/InterAccountTransferView'
import  CashFlowReport from './views/containers/CashFlowReportView'
import SaccoSmsUsagestatementreport from './views/containers/saccosmsusagestatementreport'
import emailsentReport from './views/containers/emailsentreport'
import OnboardingSectionView from './views/containers/OnboardingSectionView'
import PeriodicPerformanceSummaryReport from './views/containers/PeriodicPerformanceSummaryReport'
import  VouchersList from './views/containers/VouchersListView'
import SavingsInterestPayOffForm from './views/containers/SavingsInterestPayOffForm';
import MemberPaymentsEntryView from './views/containers/MemberPaymentsEntryView'
import MembershipwithdrawalForm from './views/containers/MembershipWithdrawalForm';
import EndOfMonthLoanInterestForm from './views/containers/EndOfMonthLoanInterestForm';
import TBCheckReportView from './views/containers/TBCheckReportView';
import TxnIssuesReportView from './views/containers/TxnIssuesReportView';
import DataUploadSpecialForm from './views/components/DataUploadSpecialForm';
import LoanApplicationListView_Concluded from './views/containers/LoanApplicationListView_Concluded';
import LoanApplicationListView_ForReview from './views/containers/LoanApplicationListView_ForReview';
import LoanApplicationListView_ForApproval from './views/containers/LoanApplicationListView_ForApproval';
import LoanApplicationListView_ForDisbursement from './views/containers/LoanApplicationListView_ForDisbursement';
import TransactionsReset from './views/components/TransactionsResetForm'


const SaccoBaseRouter = () =>(
    <div>
        <Route exact path='/' component={QuickMenu}  />
        <Route exact path='/quickmenu/' component={QuickMenu}  />
        <Route exact path='/statistics/' component={DashBoard}  />
        
        <Route exact path='/supersaveaccounts/' component={SuperSaveList}  />
        <Route exact path='/supersaveaccounts/:profileID' component={SuperSaveDetail}  />

        <Route exact path='/idtypes/' component={IDTypeList}  />
        <Route exact path='/idtypes/:idtypeID' component={IDTypeDetail}  />
        <Route exact path='/staffroles/' component={StaffRoleList}  />
        <Route exact path='/staffroles/:staffroleID' component={StaffRoleDetail}  />
        <Route exact path='/accounttypes/' component={AccountTypeList}  />
        <Route exact path='/accounttypes/:accountTypeID' component={AccountTypeDetail}  />
        <Route exact path='/loantypes/' component={LoanTypeList}  />
        <Route exact path='/loantypes/:loanTypeID' component={LoanTypeDetail}  />
        <Route exact path='/nonworkingdays/' component={NonWorkingDayList}  />
        <Route exact path='/nonworkingdays/:dayID' component={NonWorkingDayDetail}  />
        <Route exact path='/members/' component={MemberList}  />
        <Route exact path='/members/:memberID' component={MemberDetail}  />
        <Route exact path='/deposit/' component={AccountDepositView}  />
        <Route exact path='/withdraw/' component={AccountWithdrawView}  />
        <Route exact path='/loanapplication/' component={LoanApplicationForm}  />
        <Route exact path='/loancalculator/' component={LoanCalculator}  />
        <Route exact path='/loanapplications/' component={LoanApplicationsList}  />
        <Route exact path='/loanapplications/:loanapplicationID' component={LoanApplicationDetail}  />
        <Route exact path='/makeassessment/:loanapplicationID' component={LoanAssessmentForm}  />
        <Route exact path='/loanassessments/' component={LoanAssessmentList}  />
        <Route exact path='/approveloan/:loanapplicationID' component={LoanAppovalForm}  />
        <Route exact path='/loanapprovals/' component={LoanApprovalList}  />
        <Route exact path='/issueloan/:loanapplicationID' component={LoanIssuanceForm}  />
        <Route exact path='/loanissues/' component={LoanIssuanceList}  />
        <Route exact path='/loanissues/:loanissueID' component={LoanIssueDetail}  />
        <Route exact path='/loanrepayment/' component={LoanRepaymentView}  />
        <Route exact path='/loanguarantors/:applicationID' component={LoanGuarantorsList}  />
        <Route exact path='/loanconsents/:applicationID' component={LoanConsentsList}  />
        <Route exact path='/accountopeningitems/:accountTypeID' component={OpeningItemList}  />
        <Route exact path='/tillsheet/' component={TillSheetReport}  />
        <Route exact path='/accounttransactions/' component={TransactionReport} />
        <Route exact path='/memberbalances/' component={MemberSavingsReport} />
        <Route exact path='/incomestatement/' component={IncomeStatementReport} />
        <Route exact path='/dayloanreport/' component={DayLoanReport} />
        <Route exact path='/loanoutstanding/' component={OutStandingLoanReport} />
        <Route exact path='/loanrepaymentstatus/' component={LoanRepaymentStatusReport} />
        <Route exact path='/loanaging/' component={LoanAgingReport} />
        <Route exact path='/loandisbursement/' component={LoanDisbursmentReport} />
        <Route exact path='/loanrepaymentreport/' component={LoanRepaymentsReport} />
        <Route exact path='/balancesheet/' component={BalanceSheetReport} />
        <Route exact path='/sharetransfer/' component={ShareTransferView} />
        <Route exact path='/subaccounttransfer/' component={SubAccountTransferView} />
        <Route exact path='/financialstatement/' component={FinancialStatementReport} />
        <Route exact path='/useraccounts/' component={AccountList} />
        <Route exact path='/useraccounts/:accountID' component={AccountDetail} />
        <Route exact path='/messaging/' component={SendSMSMessageView} />
        <Route exact path='/groups/' component={GroupList} />
        <Route exact path='/groups/:groupID' component={GroupDetail}  />

        <Route exact path='/dividendscalculator/' component={IncomeStatementsDividendsReport}  />
        <Route exact path='/memberdepositsreport/' component={MemberDepositsReport}  />
        <Route exact path='/alltrans_actions/' component={AllTransactionsActions}  />

        <Route exact path='/supersavesaccounttypes/' component={SuperSaverSettingsForm}  />
        <Route exact path='/teebagooloaccounttypes/' component={TeebaGooloSettingsForm}  />
        
        <Route exact path='/supersavesaccounttypes/:typeID' component={SuperSaverTypeDetail}  />
        <Route exact path='/teebagooloaccounttypes/:typeID' component={TeebaGooloTypeDetail}  />

        <Route exact path='/supersavewithdraw/' component={AccountWithdrawSuperSave}  />
        <Route exact path='/supersaveaccountopening/' component={SuperSaveOpeningForm}  />
        <Route exact path='/supersavecreateinterest/' component={SuperSaveCreateInterest}  />
        <Route exact path='/memberinteraccountstransfers/' component={InterAccountTransfersSingle}  />
        <Route exact path='/savingscreateinterest/' component={SavingsCreateInterest}  />
        <Route exact path='/loanrepaymentdirect/' component={LoanRepaymentDirectView}  />
        <Route exact path='/ordinarySavingsinttransfer/' component={OrdinarySavingsInterestTransfer}  />
        <Route exact path='/savingsdefaultersreport/' component={savingdefaultsavingsDefaultReport}  />
        <Route exact path='/teebagooloaccountopening/' component={TeebaGooloOpeningForm}  />

        <Route exact path='/teebagooloaccounts/' component={TeebaGooloList}  />
        <Route exact path='/teebagooloaccounts/:profileID' component={TeebaGooloDetail}  />

        <Route exact path='/teebagoolowithdraw/' component={AccountWithdrawTeebaGooloView}  />
        <Route exact path='/teebagoolocreateinterest/' component={TeebaGooloCreateInterest}  />
        <Route exact path='/supersavingsinttransfer/' component={SupersaveInterestTransfer}  />
        <Route exact path='/teebagoolointtransfer/' component={TeebaGooloInterestTransafer}  />
        <Route exact path='/companyaccounts/' component={CompanyAccountList}  />
        <Route exact path='/withdrawform/' component={AcWithdraw}  />
        <Route exact path='/trialbalance/' component={TrialBalanceReport}  />
        <Route exact path='/ledgerreport/' component={LedgerReport}  />
        <Route exact path='/ugdistricts/' component={DistrictList}  />
        <Route exact path='/ugdistricts/:districtID' component={UgDistrictDetail}  />
        <Route exact path='/rejectedloanapplications/' component={LoanRejectionList}  />
        <Route exact path='/deferedschdeules/' component={DeferedScheduleReport}  />
        <Route exact path='/saccovaluers/' component={SaccovaluersList}  />
        <Route exact path='/groupsignitorydetails/:signitoryid' component={GroupSignitoryDetails}  />

        <Route exact path='/recyclelist/' component={RecycleBinList}  />
        <Route exact path='/systemlogs/' component={SystemLogList}  />
        <Route exact path='/withdrawrequistion/' component={WithdrawRequstionForm}  />
        <Route exact path='/withdrawrequistions_list/' component={WithdrawRequistionsList}  />
        <Route exact path='/withdraw_requistion_details/:requiID' component={WithdrawRequstionFormEdit}  />
        <Route exact path='/org_interaccount_transfer/' component={SaccoInterAccountTransfer}  />
        <Route exact path='/batchemail_statementsending/' component={SendButchEMailStatementView}  />

        <Route exact path='/otherjournalentries/' component={JournalEntryView}  />
        <Route exact path='/companyprofile/' component={CompanyProfile} />
        <Route exact path='/quicktransact/' component={QuickTransact}  />
        <Route exact path='/inter_account_tranfer_betweenmembers/' component={InterAccountTransfers} />
        
        <Route exact path='/cashflow_report/' component={CashFlowReport} />
        <Route exact path='/saccosmsusagestatement/' component={SaccoSmsUsagestatementreport} />
        <Route exact path='/sentemailsreport/' component={emailsentReport}  />
        <Route exact path='/dataonboarding/' component={OnboardingSectionView} />
        <Route exact path='/periodic_performance_report/' component={PeriodicPerformanceSummaryReport}  />
       
        <Route exact path='/vouchers_list/' component={VouchersList} />
        <Route exact path='/savingsinterest_awardmanual/' component={SavingsInterestPayOffForm} />
        <Route exact path='/member_payments/' component={MemberPaymentsEntryView} />
        
        <Route exact path='/membership_withdrawal/' component={MembershipwithdrawalForm} />
        <Route exact path='/create_endofmonth_loaninterest/' component={EndOfMonthLoanInterestForm} />

        <Route exact path='/tb_checker/' component={TBCheckReportView} />
        
        <Route exact path='/txn_issuesreport/' component={TxnIssuesReportView} />
        <Route exact path='/datauploading_special/' component={DataUploadSpecialForm} />

        <Route exact path='/concluded_applications/' component={LoanApplicationListView_Concluded} />
        <Route exact path='/final_review_list/' component={LoanApplicationListView_ForReview} />
        <Route exact path='/pending_approval_list/' component={LoanApplicationListView_ForApproval} />

        <Route exact path='/pending_disbursement_list/' component={LoanApplicationListView_ForDisbursement} />
        <Route exact path='/transactions_reset/' component={TransactionsReset} />

    </div>

);

export default SaccoBaseRouter;

