import React from 'react';
import { Form, Input, Button,Select,DatePicker,Spin,Image, Card,Divider,Result, message,Modal,Popconfirm,Popover } from 'antd';
import axios from 'axios'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import moment from 'moment';
import * as serverconfig from '../serverconn'
import {
  Col,
  Row,
} from 'reactstrap';
import { UserOutlined, LockOutlined,CloseCircleFilled, LoadingOutlined,MailOutlined } from '@ant-design/icons';
import uuid from 'react-uuid'
import * as reactstrp from 'reactstrap';
import Resizer from "react-image-file-resizer";
import { number } from 'prop-types';

var CryptoJS = require("crypto-js");


const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;


var token= ''
var sacco= ''
var bizuserid= ''

class GroupForm extends React.Component {

  state = {
    date:moment().format(dateFormat).toString(),
    accounttypes:[],
    idtypes:[],
    groupname:'' ,  
    District:'',
    subcounty:'',
    parish:'',
    village:'',
    account_type:'',
    noofmembers:0,
    nooffemales:0,
    noofmales:0,
    MembersFile:null,
    datarequested:true,
    datasubmittedsuccessfully:false,
    saccopackageobj:{},
    members:[],
    saccoboject:{},
    RegisteredPhoneNumber1:'',
    RegisteredPhoneNumber2:'',
    RegisteredPhoneNumber3:'',

    grouptype:'joint',
    signature_one:null,
    signature_two:null,
    signature_three:null,
    companyprofile:{},
    ugdistricts:[],
    signitoryform_visible:false,
    signitories:[],

    //signitory bio props
    sur_name:'' ,  
    middle_name:'',
    given_name:'',
    nationality:'',
    ID_type:'',
    ID_number:'',
    date_Of_Birth:'',
    Email_Address:'',
    P_O_Box:'',
    Area_Of_Residence:'',
    District:'',
    RegisteredPhoneNumber1:'',
    RegisteredPhoneNumber2:'',
    next_of_kin:'',
    Next_Of_Kin_Physical_Address:'',
    relationship:'',
    Photo:null,
    Signature:null,
    Thumbprint:null,

    Date_Of_Joining:'',
    account_type:'',
    date_of_appointment:'',
    status:'',
    staff_number:'',
    reference_one:'',
    Referee1PhoneNumber:'',
    datarequested:true,
    accountnumber:'',
    photofile:null,
    signaturefile:null,
    thumbprintfile:null,
    marital_status:'',
    own_residence:'',
    tribe:'',
    recommender:'',
    language1:'',
    language2:'',
    gender:'',
    any_other_account:'',
    other_account_number:'',
    other_bank:'',
    shares:0,
    title:'',
    datasubmittedsuccessfully:false,
    saccopackageobj:{},
    members:[],
    companyprofile:{},

    occupation:'',
    postaladdress:'',
    homecelllocaltion:'',
    cell:'',
    cellleader:'',
    nameofemployer_business:'',

    addressofemployer_business:'',
    refree_institutionalmember_name:'',
    refree_institutionalmember_contact:'',
    refree_cellleader_name:'',
    refree_cellleader_contact:'',
    next_of_kin_contact:'',

    child_account_school:'',
    child_account_school_class:'',

    membertype:'senior',
    pysical_form:null,
    saving_period:'',

    id_expirydate:'',
    ugdistricts:[],
    cellleader_contact:'',
    id_valid:false,
    age_valid:false,
    accounttypeobject:{},
    idtypeobject:{},
    reapersrefobj:{},
    reapersselectedref:'',

    signitorytype:'existing_members',
    selected_member_signitory_object:{},
    selected_member_signitory:'',

  }


  componentDidMount(){

    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    }else{
      bizuserid= ''
    }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/accounttypes/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          accounttypes:res.data
        })
    })


    axios.get(serverconfig.backendserverurl+`/api/members/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          members:res.data
        })
    })

    axios.get(serverconfig.backendserverurl+`/api/saccos/${sacco}`)
    .then(res => {
        this.setState({
          saccoboject:res.data
        })

        //get the sacco package here
        axios.get(serverconfig.backendserverurl+`/api/packages/${res.data.package}`)
        .then(res => {
            this.setState({
              saccopackageobj:res.data
            })
        })
    })

    axios.get(serverconfig.backendserverurl+`/api/saccos/${sacco}`)
    .then(res => {
      this.setState({
        companyprofile:res.data
      })
    })

    axios.get(serverconfig.backendserverurl+`/api/idtypes/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          idtypes:res.data
        })

    })


    //get the sacco package here
    axios.get(serverconfig.backendserverurl+`/api/ugdistricts/?sacco=${sacco}`)
    .then(res => {
      this.setState({
        ugdistricts:res.data
      })
        this.setState({datarequested:false})
    })
}

onBlur=()=> {
  console.log('blur');
}

 onFocus=()=>{
  console.log('focus');
}

onSearch=(val)=> {
  console.log('search:', val);
}



//imgae resizer
resizeFile = (file) =>
new Promise((resolve) => {
  Resizer.imageFileResizer(
    file,
    300,
    400,
    "JPEG",
    50,
    0,
    (uri) => {
      resolve(uri);
    },
    "base64"
  );
});

//convert back to file
 dataURIToBlob = (dataURI) => {
  const splitDataURI = dataURI.split(",");
  const byteString =
    splitDataURI[0].indexOf("base64") >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(":")[1].split(";")[0];
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);
  return new Blob([ia], { type: mimeString });
};


//check empty object
 isEmpty=(obj)=> {
  for(var prop in obj) {
      if(obj.hasOwnProperty(prop))
          return false;
  }

  return true;
}



  //check agge validity
  checkagevalidity=(val)=>{
      var agelimit_months_cap=6570

      var dateMomentObjectone = moment(val, "DD/MM/YYYY"); // 1st argument - string, 2nd argument - format
      var dobobject = dateMomentObjectone.toDate();

      var datetoday=moment(this.state.date, "DD/MM/YYYY")

      var date1 = datetoday.toDate();
      var date2 = dobobject;

      var Difference_In_Time =  date1.getTime()-date2.getTime();
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

      console.log("diff days"+String(Difference_In_Days))

    if(Number(Difference_In_Days)>=Number(agelimit_months_cap)){
      this.setState({age_valid:true})
      console.log("Age is valid")
    }else{
      this.setState({age_valid:false})
      console.log("Age is not valid")
    }
  }

  //check agge validity
  checkidvalidity=(val)=>{
      var idvaliditydays=180

      var dateMomentObjectone = moment(val, "DD/MM/YYYY"); // 1st argument - string, 2nd argument - format
      var dobobject = dateMomentObjectone.toDate();

      var datetoday=moment(this.state.date, "DD/MM/YYYY")

      var date1 = datetoday.toDate();
      var date2 = dobobject;

      var Difference_In_Time =  date2.getTime()-date1.getTime();
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

      console.log("diff days"+String(Difference_In_Days))

    if(Number(Difference_In_Days)>=Number(idvaliditydays)){
      this.setState({id_valid:true})
      console.log("ID is valid")
    }else{
      this.setState({id_valid:false})
      console.log("ID is not valid")
    }
  }


    //submit button pressed
    handleSignitorytolist=(event) =>{
  
      if (this.state.RegisteredPhoneNumber1===''){
        message.info("Phone Number missing")
      }else if(this.state.id_valid===false){
        message.info("ID is invalid")
  
      }else if(this.state.age_valid===false){
        message.info("Age is invalid")
      }
      else{
    
        const gitem={
          key:uuid(),
          SurName:this.state.sur_name,
          MiddleName:this.state.middle_name,
          OtherName:this.state.given_name,
          Nationality:this.state.nationality,
          IDType:this.state.ID_type,
          IDNumber:this.state.ID_number,
          DateOfBirth:this.state.date_Of_Birth,
          EmailAddress:this.state.Email_Address,
          POBox:this.state.P_O_Box,
          AreaOfResidence:this.state.AreaOfResidence,
          District:this.state.District,
          RegisteredPhoneNumber1:this.state.RegisteredPhoneNumber1,
          RegisteredPhoneNumber2:this.state.RegisteredPhoneNumber2,
          next_of_kin:this.state.next_of_kin,
          relationship:this.state.relationship,
          gender:this.state.gender,
          marital_status:this.state.marital_status,
          title:this.state.title,
          own_residence:this.state.own_residence,
          tribe:this.state.tribe,
          language2:this.state.language2,
          language1:this.state.language1,
          sacco:this.state.sacco,
          bizuserid:this.state.bizuserid,
          next_of_kin_contact:this.state.next_of_kin_contact,
          occupation:this.state.occupation,
          nameofemployer_business:this.state.nameofemployer_business,
          addressofemployer_business:this.state.addressofemployer_business,
          Photo:this.state.Photo,
          Signature:this.state.Signature,
          Thumbprint:this.state.Thumbprint,
         }

        this.setState({signitories: [...this.state.signitories, gitem]});

        this.setState({signitoryform_visible:false})

      }
    }

  //submit button pressed
  handlesignitorySubmit=(groupid) =>{

      this.state.signitories.map((item)=>{

        let form_data = new FormData();
        form_data.append('group', groupid);
        form_data.append('date', item.date);
        form_data.append('SurName', item.SurName);
        form_data.append('MiddleName', item.MiddleName);
        form_data.append('OtherName', item.OtherName);
        form_data.append('Nationality', item.Nationality);
        form_data.append('IDType', item.IDType);
        form_data.append('IDNumber', item.IDNumber);
        form_data.append('DateOfBirth', item.DateOfBirth);
        form_data.append('EmailAddress', item.EmailAddress);
        form_data.append('POBox', item.POBox);
        form_data.append('AreaOfResidence', item.AreaOfResidence);
        form_data.append('District', item.District);
        form_data.append('RegisteredPhoneNumber1', item.RegisteredPhoneNumber1);
        form_data.append('RegisteredPhoneNumber2', item.RegisteredPhoneNumber2);
        form_data.append('next_of_kin', item.next_of_kin);
        form_data.append('NextOfKinPhysicalAddress', item.NextOfKinPhysicalAddress);
        form_data.append('relationship', item.relationship);
    
        form_data.append('accountType', item.accountType);
        form_data.append('gender', item.gender);
        form_data.append('marital_status', item.marital_status);
        form_data.append('title', item.title);
    
        form_data.append('own_residence', item.own_residence);
        form_data.append('tribe', item.tribe);
        form_data.append('recommender', item.recommender);
    
        form_data.append('language1', item.language1);
        form_data.append('language2', item.language2);
        form_data.append('sacco', sacco);
        form_data.append('next_of_kin_contact', item.next_of_kin_contact);
        form_data.append('occupation', item.occupation);
        form_data.append('nameofemployer_business', item.nameofemployer_business);
        form_data.append('addressofemployer_business', item.addressofemployer_business);
        form_data.append('id_expirydate', item.id_expirydate);
  
        item.Photo==null?
        console.log("No profile file")
        :
        form_data.append('Photo', item.Photo, item.Photo?item.Photo.name:"");
    
        item.Signature==null?
        console.log("No signature file")
        :
        form_data.append('Signature', item.Signature, item.Signature?item.Signature.name:"");
    
        item.Thumbprint==null?
        console.log("No thumb file")
        :
        form_data.append('thumbprint', item.Thumbprint, item.Thumbprint?item.Thumbprint.name:"");
    
        console.log(form_data)
    
        axios.post(serverconfig.backendserverurl+`/api/group_signitories/`, form_data, {
          headers: {
            'content-type': 'multipart/form-data'
          }
        })
        .then(res =>{
          console.log(res.data)   
          console.log("group signitory created")     
        })
        .catch(error => console.log(error))
      })


      let form_data2 = new FormData();
      this.state.MembersFile==null?
      console.log("No Members file")
      :
      form_data2.append('all_group_members', this.state.MembersFile, this.state.MembersFile?this.state.MembersFile.name:"");

      //now upload all members file
      axios.put(serverconfig.backendserverurl+`/api/groups/${groupid}/`, form_data2, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
      .then(res =>{
        console.log("All members file created")
      })



  }


  //submit button pressed
  handleFormSubmit=(event) =>{

      var contactone=''
      var contacttwo=''

      if (this.state.signitories.length>0){

        if (this.state.signitories.length>1){
        contactone=this.state.signitories[0].RegisteredPhoneNumber1
        }

        if (this.state.signitories.length>=2){
        contacttwo=this.state.signitories[1].RegisteredPhoneNumber1
        }

      }

      //get returned temporay number
      this.setState({datarequested:true})

      let form_data = new FormData();
      form_data.append('date', this.state.date);
      form_data.append('group_name', this.state.groupname);
      form_data.append('district', this.state.District);
      form_data.append('subcounty', this.state.subcounty);
      form_data.append('parish', this.state.parish);
      form_data.append('accountType', this.state.account_type);
      form_data.append('area_of_origin', this.state.village);
      form_data.append('number_of_members', this.state.noofmembers);
      form_data.append('number_of_females', this.state.nooffemales);
      form_data.append('number_of_males', this.state.noofmales);
      form_data.append('sacco', sacco);
      form_data.append('grouptype', this.state.grouptype);
          
      form_data.append('contactone', contactone);
      form_data.append('contacttwo', contacttwo);
      form_data.append('date_of_joining', this.state.Date_Of_Joining);

      axios.post(serverconfig.backendserverurl+'/customqueries/creategroupaccount', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
      .then(res =>{
      console.log(res.data)
      if(res.data.message==="success"){
        var groupid=Number(res.data.groupid)
        this.setState({datarequested:false})
        //reload
        window.location.reload(false)

        //submit signitory data
        if (this.state.signitories.length>0){
          this.handlesignitorySubmit(groupid)
        }
      }
    }    
    )
    .catch(error => console.log(error))
    

  }

  handlegroupnameChange= (e) => this.setState({ groupname: e.target.value});
  handleDistrictChange= (e) => this.setState({ District: e.target.value});
  handlesubcountyChange= (e) => this.setState({ subcounty: e.target.value});
  handleparishChange= (e) => this.setState({ parish: e.target.value});
  handleaccount_typeChange= (e) => this.setState({ account_type: e});
  handlevillageChange= (e) => this.setState({ village: e.target.value});
  handlenoofmembersChange= (e) => this.setState({ noofmembers: e.target.value});
  handlenooffemalesChange= (e) => this.setState({ nooffemales: e.target.value});
  handlenoofmalesChange= (e) => this.setState({ noofmales: e.target.value});
  handleMembersFileChange= (e) =>{
    this.setState({ MembersFile: e.target.files[0]});
  } 

  handleRegisteredPhoneNumber1Change= (e) => this.setState({ RegisteredPhoneNumber1: e});
  handleRegisteredPhoneNumber2Change= (e) => this.setState({ RegisteredPhoneNumber2: e});


  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{

      if (this.state.datasubmittedsuccessfully===true){
        return(
            <Card>
            <Result
            status="success"
            title="Successfully Created New Group Account "
            subTitle="Group details will be further reviewed if need be"
            extra={[
              <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
            ]}
            />
            </Card>
        );

      }else{

          if(this.state.members.length>=this.state.saccopackageobj.memberaccountslimit){
            return (
              <div>
                <h4 style={{color:'red'}}>You have reached members limit.Please contact adminstrators for upgrade of package.Thanx</h4>
              </div>
            );

          }else{

            return (
              <div>

            <Row>
            <Col xs="12" sm="8" lg="6" >
            <Form
                  onFinish={(event) => this.handleFormSubmit(event)}
              >

              <FormItem label="Group Type">
                      <Select placeholder="Type" 
                       value={this.state.grouptype} 
                       onChange={(val)=>{this.setState({grouptype:val})}} 
                       showSearch
                       optionFilterProp="children"
                       onFocus={this.onFocus}
                       onBlur={this.onBlur}
                       onSearch={this.onSearch}
                       
                       
                       >
                        <Option value="joint">Joint</Option>
                        <Option value="ministry">Ministry</Option>
                      </Select>
                </FormItem>

                <h3>Group Detail Section</h3>
                  <FormItem label="Group Name"
                      name="groupname"
                      rules={[
                        {
                          required: true,
                          message: 'Please input name',
                        },
                      ]}          
                  >
                    <Input  placeholder="Put a name here." value={this.state.groupname} onChange={this.handlegroupnameChange} />
                  </FormItem>


                  <FormItem label="District"
                     name="district"
                     rules={[
                       {
                         required: true,
                         message: 'Please enter district of residence',
                       },
                     ]}           
                  >
                    <Select placeholder="Ug District" 
                    style={{  }}
                    value={this.state.District} 
                    onChange={(val)=>{this.setState({District:val})}} 
                    showSearch
                    optionFilterProp="children"
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onSearch={this.onSearch}
                    
                    >
                      {this.state.ugdistricts.map(
                        (dist)=>(
                          <Option value={dist.district_name}>{dist.district_name} | {dist.region}</Option>
                        ))}
                    </Select>
                  </FormItem>


                  <FormItem label="Subcounty"
                    name="Subcounty"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter Subcounty'
                      },
                    ]}                   
                  >

                    <Input  placeholder="Subcounty." value={this.state.subcounty} onChange={this.handlesubcountyChange} />
                  </FormItem>
      
                  <FormItem label="Parish"
                    name="Parish"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter parish'
                      },
                    ]}                   
                  
                  >
                    <Input  placeholder="Parish." value={this.state.parish} onChange={this.handleparishChange} />
                  </FormItem>
      
                  <FormItem label="Village"
                    name="Village"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter Village'
                      },
                    ]}                   
                                    
                  >
                    <Input  placeholder="Vallage." value={this.state.village} onChange={this.handlevillageChange} />
                  </FormItem>

                  <FormItem label="Account Type"
                     name="accountype"
                     rules={[
                       {
                         required: true,
                         message: 'Please enter account Type',
                       },
                     ]}           
                  >
                      <Select placeholder="Account Type" 
                      style={{  }} value={this.state.account_type}
                       onChange={this.handleaccount_typeChange} 
                       showSearch
                       optionFilterProp="children"
                       onFocus={this.onFocus}
                       onBlur={this.onBlur}
                       onSearch={this.onSearch}
                       
                       >
                        {this.state.accounttypes.map(
                          (stf)=>(
                            <Option value={stf.id}>{stf.account_type_name}</Option>
                          ))}
                      </Select>
                  </FormItem>
                  
                  <FormItem label="No Of Members">
                    <Input placeholder="Total number of members" value={this.state.noofmembers} onChange={this.handlenoofmembersChange} />
                  </FormItem>
                   
      
                  <FormItem label="No Of Males">
                    <Input placeholder="Total Number Of Males." value={this.state.noofmales} onChange={this.handlenoofmalesChange} />
                  </FormItem>
      
                  <FormItem label="No Of Females">
                    <Input placeholder="Total Number Females." value={this.state.nooffemales} onChange={this.handlenooffemalesChange} />
                  </FormItem>

                  <FormItem label="Date of Joining"
                    name="dateofjoining"
                    rules={[
                      {
                        required: true,
                        message: 'Please select',
                      },
                    ]}  
                  >
                        <DatePicker onChange={(date, dateString)=>{this.setState({ Date_Of_Joining: dateString})}} format={dateFormat}/>
                  </FormItem>

      
                  <FormItem label="All Members Files" style={{marginRight:3}}>
                        <Input type="file" accept="*" placeholder="Members File"  onChange={this.handleMembersFileChange}/>
                  </FormItem> 

                  <FormItem >
                    <Button  type='primary' htmlType="submit">Submit</Button>
                  </FormItem>

                </Form>
            </Col>


            <Col xs="12" sm="8" lg="6" >
              <Button type='primary' onClick={()=>{this.setState({signitoryform_visible:true})}}>Add Signitory</Button>

              <br></br>
              <br></br>

              {
                  this.state.signitories.length>0?
                  <reactstrp.Table bordered>
                  <thead>
                    <tr>
                      <th>Sur Name</th>
                      <th>Other Name</th>
                      <th>Phone</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                  {this.state.signitories.map(
                    (item)=>(
                      <tr>
                      <td>{item.SurName}</td>
                      <td>{item.OtherName}</td>
                      <td>{item.RegisteredPhoneNumber1}</td>
                      <td>
                      <Popover content={<p>Click here to delete item</p>} title="Delete Item">
                      <Popconfirm title="Sure to delete?" onConfirm={() => {
                        this.setState({ signitories:  [...this.state.signitories.filter(todo => todo.key
                          !==item.key)]});
                      }}>
                      <CloseCircleFilled style={{color:'red'}} />
                      </Popconfirm>       
                      </Popover>
                      </td>
                      </tr>
                    ))}
                </tbody>
                </reactstrp.Table>  
                :
                null
              }
            </Col>
            </Row>


            <Modal              
              visible={this.state.signitoryform_visible}
              title={"Signitory Form"}
              onCancel={()=>{this.setState({signitoryform_visible:false})}}
              style={{width:1000}}
              footer={[
                <Button key="back" onClick={()=>{this.setState({signitoryform_visible:false})}}>
                  Close
                </Button>
                ]}
                >
                

                <FormItem label="Signitory type"
                  
                  >
                      <Select 
                      placeholder="Signitory type" 
                      style={{ width: 300 }} 
                      value={this.state.signitorytype} 
                      onChange={(val)=>{this.setState({signitorytype:val})}} 
                      showSearch
                      optionFilterProp="children"
                      onFocus={this.onFocus}
                      onBlur={this.onBlur}
                      onSearch={this.onSearch}
                      
                      >
                            <Option value="existing_members">From existing members </Option>
                            <Option value="nonexisting_members">Non existing members</Option>
                      </Select>
                  </FormItem>




                {
                  this.state.signitorytype==="nonexisting_members"?
                  <Form
                  onFinish={(event) => this.handleSignitorytolist(event)}
              >

              <h3>Bio Data Section</h3>
                <FormItem label="Sur Name"
                    name="surname"
                    rules={[
                      {
                        required: true,
                        message: 'Please input sur name',
                      },
                    ]}          
                >
                  <Input  placeholder="Put a name here." value={this.state.sur_name} onChange={(val)=>{this.setState({sur_name:val.target.value})}} />
                </FormItem>
      
                <FormItem label="Middle Name">
                  <Input  placeholder="Put a name here." value={this.state.middle_name} onChange={(val)=>{this.setState({middle_name:val.target.value})}} />
                </FormItem>
      
                <FormItem label="Given Name"
                    name="givenname"
                    rules={[
                      {
                        required: true,
                        message: 'Please input given name',
                      },
      
                    ]}           
                >
                  <Input  placeholder="Put a name here." value={this.state.given_name} onChange={(val)=>{this.setState({given_name:val.target.value})}} />
                </FormItem>
    
                <FormItem label="Gender"
                   name="gender"
                   rules={[
                     {
                       required: true,
                       message: 'Please select',
                     },
                   ]}           
                
                >
                    <Select 
                    placeholder="Gender" 
                    style={{ width: 120 }} 
                    value={this.state.gender} 
                    onChange={(val)=>{this.setState({gender:val})}} 
                    showSearch
                    optionFilterProp="children"
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onSearch={this.onSearch}
                    
                    
                    >
                          <Option value="Male">Male</Option>
                          <Option value="Female">Female</Option>
                    </Select>
                </FormItem>

                <div style={{display:'flex',flexDirection:'row'}}>
                <FormItem label="Date of Birth"
                    name="dateofbirth"
                    rules={[
                      {
                        required: true,
                        message: 'Please select date of birth',
                      },
                    ]}   
                    style={{display:'flex',margin:3}}        
                >
                      <DatePicker onChange={(val,datstring)=>{this.setState({date_Of_Birth:datstring})
                    
                          this.checkagevalidity(datstring)

                    }} format={dateFormat} />
                </FormItem>
                {
                  this.state.age_valid===false?
                  <h6 style={{display:'flex',color:'red'}}>Member Age is not valid </h6>
                  :
                  <h6 style={{display:'flex',color:'green'}}>Member Age is valid </h6>
                  
                }
                </div>
    
      
                <FormItem label="Nationality"
                    name="nationality"
                    rules={[
                      {
                        required: true,
                        message: 'Please input nationality',
                      },
                    ]}            
                >
                  <Input  placeholder="Nationality." value={this.state.nationality} onChange={(val)=>{this.setState({nationality:val.target.value})}} />
                </FormItem>
      
      
                <FormItem label="ID Type"
                name="idtype"
                rules={[
                  {
                    required: true,
                    message: 'Please select id type',
                  },
                ]}           
            >
                <Select placeholder="Id Type" style={{  }}
                 value={this.state.ID_type} 
                 onChange={(val)=>{this.setState({ID_type:val})}} 
                 showSearch
                 optionFilterProp="children"
                 onFocus={this.onFocus}
                 onBlur={this.onBlur}
                 onSearch={this.onSearch}
                 
                 >
                  {this.state.idtypes.map(
                    (type)=>(
                      <Option value={type.id}>{type.id_type_name}</Option>
                    ))}
                </Select>
               </FormItem>
                

                <FormItem label="ID Number"
                name="ID_number"
                rules={[
                  {
                    required: true,
                    message: 'Please enter ID Number',
                  },
                ]}                   
                
                >
                <Input  placeholder="ID Number." value={this.state.ID_number} onChange={(val)=>{this.setState({ID_number:val.target.value})}} />
                </FormItem>
                

                <div style={{display:'flex',flexDirection:'row'}}>
                <FormItem label="ID date of expiry"
                  name="id_expirydate"
                  rules={[
                    {
                      required: true,
                      message: 'Please select Id date of expiry ',
                    },
                  ]}                   
                  style={{display:'flex',margin:3}} 
                  >
                      <DatePicker onChange={(date, dateString)=>{
                        this.setState({id_expirydate:dateString})
                        this.checkidvalidity(dateString)

                      }} format={dateFormat} />
                  </FormItem>
                  {
                  this.state.id_valid===false?
                  <h6 style={{display:'flex',color:'red'}}>Identity card invalid </h6>
                  :
                  <h6 style={{display:'flex',color:'green'}}>Identity card is valid </h6>
                  }
                </div>


                <FormItem label="Tribe">
                  <Input  placeholder="Tribe." value={this.state.tribe} onChange={(val)=>{this.setState({tribe:val.target.value})}} />
                </FormItem>
    
                <FormItem label="Language one">
                  <Input  placeholder="Language one." value={this.state.language1} onChange={(val)=>{this.setState({language1:val.target.value})}} />
                </FormItem>
    
                <FormItem label="Language two">
                  <Input  placeholder="Language two." value={this.state.language2} onChange={(val)=>{this.setState({language2:val.target.value})}} />
                </FormItem>
    
                <div style={{display:'flex',flexDirection:'row'}}>
                <FormItem label="Member Photo" style={{marginRight:3}}>
                <Input type="file" accept="image/png, image/jpeg" placeholder="Member Photo" 
                  onChange={async(e) =>{
                    //this.setState({ Photo: e.target.files[0]});
                    if(e.target.files[0]){
                      this.setState({
                        photofile: URL.createObjectURL(e.target.files[0])
                      })
                    }

                    const file = e.target.files[0];
                    //send to resizer
                    const image = await this.resizeFile(file);
                    const newFile = this.dataURIToBlob(image);
                    this.setState({ Photo: newFile});


                  }}/>
                </FormItem> 
    
                {this.state.Photo?
                <Image
                width={50}
                height={50}
                src={this.state.photofile}    
                />
                :
                null
                }
    
                </div>
    
                  <div style={{display:'flex',flexDirection:'row'}}>
                  <FormItem label="Member Signature" style={{marginRight:3}}>
                    <Input type="file" accept="image/png, image/jpeg"
                      placeholder="Member Signature"  
                      onChange={
                        async(e) =>{
                          //this.setState({ Signature: e.target.files[0]});
                          if(e.target.files[0]){
                            this.setState({
                              signaturefile: URL.createObjectURL(e.target.files[0])
                            })
                          }

                          const file = e.target.files[0];
                          //send to resizer
                          const image = await this.resizeFile(file);
                          const newFile = this.dataURIToBlob(image);
                          this.setState({ Signature: newFile});

                        }
                      }/>

                  </FormItem> 
                  {this.state.Signature?
                    <Image
                    width={50}
                    height={50}
                    src={this.state.signaturefile}    
                    />
                    :
                    null
                    }
                  </div>

                  <div style={{display:'flex',flexDirection:'row'}}>
                  <FormItem label="Thumb Print" style={{marginRight:3}}>
                      <Input type="file" accept="image/png, image/jpeg" placeholder="Member Thumb" 
                       onChange={ async(e)=>{
                        //this.setState({ Thumbprint: e.target.files[0]});
                        if(e.target.files[0]){
                          this.setState({
                            thumbprintfile: URL.createObjectURL(e.target.files[0])
                          })
                        }

                        const file = e.target.files[0];
                        //send to resizer
                        const image = await this.resizeFile(file);
                        const newFile = this.dataURIToBlob(image);
                        this.setState({ Thumbprint: newFile});

                      }}/>
                  </FormItem> 
                  {this.state.Thumbprint?
                    <Image
                    width={50}
                    height={50}
                    src={this.state.thumbprintfile}    
                    />
                    :
                    null
                    }
                  </div>



                <h3>Employment Section</h3>
                <FormItem label="Occupation"
                    name="coccupation"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter occupation',
                      },
                    ]}           
                >
                  <Input  placeholder="Occupation." value={this.state.occupation} onChange={(val)=>{this.setState({occupation:val.target.value})}} />
                </FormItem>

                <FormItem label="Name Of Employer/Business"
                    name="nameofemployer_business"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter name',
                      },
                    ]}           
                >
                  <Input  placeholder="Name." value={this.state.nameofemployer_business} onChange={(val)=>{this.setState({nameofemployer_business:val.target.value})}} />
                </FormItem>

                <FormItem label="Address of Employer/Business"
                    name="addressofemployer_business"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter address',
                      },
                    ]}           
                >
                  <Input  placeholder="Address." value={this.state.addressofemployer_business} onChange={(val)=>{this.setState({addressofemployer_business:val.target.value})}} />
                </FormItem>


      
                <h3>Contact Section</h3>
                <FormItem label="Email Address"
                    name="emailaddress"
                    rules={[
                      {
                        required: false,
                        message: 'Please input mail',
                      },
                      {
                        type: 'email',
                        message: 'The input is not valid E-mail!',
                      },
                    ]}            
                
                >
                <Input  type="mail" 
                        prefix={<MailOutlined className="site-form-item-icon" />} 
                        placeholder="Email"
                        value={this.state.Email_Address} 
                        onChange={(val)=>{this.setState({Email_Address:val.target.value})}} />
                </FormItem>
      
                <FormItem label="Postal Address (P O Box)">
                  <Input placeholder="box number." value={this.state.P_O_Box} onChange={(val)=>{this.setState({P_O_Box:val.target.value})}} />
                </FormItem>


                <FormItem label="Residential (Physical) Address"
                    name="areaofresidence"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter residence',
                      },
                    ]}           
                >
                  <Input placeholder="Area of residence." value={this.state.Area_Of_Residence} onChange={(val)=>{this.setState({Area_Of_Residence:val.target.value})}} />
                </FormItem>
      
                <FormItem label="District"
                   name="district"
                   rules={[
                     {
                       required: true,
                       message: 'Please enter district of residence',
                     },
                   ]}           
                >
                  <Select
                   placeholder="Ug District"
                    style={{  }}
                     value={this.state.District} 
                     onChange={(val)=>{this.setState({District:val})}} 
                     showSearch
                     optionFilterProp="children"
                     onFocus={this.onFocus}
                     onBlur={this.onBlur}
                     onSearch={this.onSearch}
                     
                     >
                    {this.state.ugdistricts.map(
                      (dist)=>(
                        <Option value={dist.district_name}>{dist.district_name} | {dist.region}</Option>
                      ))}
                  </Select>
                </FormItem>
      
              <FormItem label= { <h6 style={{display:'flex'}} >* Phone One</h6>}                       
              >
              <PhoneInput
                    countrySelectProps={{ unicodeFlags: true }}
                    defaultCountry="UG"
                    placeholder="Enter phone number"
                    value={this.state.RegisteredPhoneNumber1} onChange={(val)=>{this.setState({RegisteredPhoneNumber1:val})}}/>
                </FormItem> 
    
                <FormItem label="Phone Two">
                <PhoneInput
                    countrySelectProps={{ unicodeFlags: true }}
                    defaultCountry="UG"
                    placeholder="Enter phone number"
                    value={this.state.RegisteredPhoneNumber2} onChange={(val)=>{this.setState({RegisteredPhoneNumber2:val})}}/>
                </FormItem>

             <h3>Personal Status Section</h3>
                <FormItem label="Title"
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: 'Please select',
                    },
                  ]}           
                
                >
                    <Select 
                    placeholder="Title"
                     style={{ width: 120 }}
                      value={this.state.title}
                       onChange={(val)=>{this.setState({title:val})}} 
                       showSearch
                       optionFilterProp="children"
                       onFocus={this.onFocus}
                       onBlur={this.onBlur}
                       onSearch={this.onSearch}
                       
                       
                       >
                          <Option value="Mrs">Mrs</Option>
                          <Option value="Mr">Mr</Option>
                          <Option value="Miss">Miss</Option>
                    </Select>
                </FormItem>
    
                <FormItem label="Marital Status"
                  name="marital_status"
                  rules={[
                    {
                      required: true,
                      message: 'Please select',
                    },
                  ]}           
                
                >
                    <Select 
                    placeholder="Title"
                     style={{ width: 120 }} 
                     value={this.state.marital_status} 
                     onChange={(val)=>{this.setState({marital_status:val})}} 
                     showSearch
                     optionFilterProp="children"
                     onFocus={this.onFocus}
                     onBlur={this.onBlur}
                     onSearch={this.onSearch}
                     
                     >
                          <Option value="Married">Married</Option>
                          <Option value="Single">Single</Option>
                    </Select>
                </FormItem>
    
                <FormItem label="Own Residence?"
                  name="own_residence"
                  rules={[
                    {
                      required: true,
                      message: 'Please select',
                    },
                  ]}           
                
                >
                    <Select placeholder="Title"
                     style={{ width: 120 }}
                      value={this.state.own_residence}
                       onChange={(val)=>{this.setState({own_residence:val})}} 
                       showSearch
                       optionFilterProp="children"
                       onFocus={this.onFocus}
                       onBlur={this.onBlur}
                       onSearch={this.onSearch}
                       
                       >
                          <Option value="Yes">Yes</Option>
                          <Option value="No">No</Option>
                    </Select>
                </FormItem>

    
                 <h3>Refree / Next of Kin Section</h3>

                <FormItem label="Next of kin">
                  <Input placeholder="Next of kin." value={this.state.next_of_kin} onChange={(val)=>{this.setState({next_of_kin:val.target.value})}} />
                </FormItem>

                <FormItem label="Next Of Kin Contact">
                 <PhoneInput
                      countrySelectProps={{ unicodeFlags: true }}
                      defaultCountry="UG"
                      placeholder="next of kin contact"
                      value={this.state.RegisteredPhoneNumber2} onChange={(val)=>{this.setState({next_of_kin_contact:val})}}/>
                 </FormItem>

      
                <FormItem label="Next of kin Address">
                  <Input placeholder="Next of Kin Address." value={this.state.Next_Of_Kin_Physical_Address} onChange={(val)=>{this.setState({Next_Of_Kin_Physical_Address:val.target.value})}} />
                </FormItem>
      
                <FormItem label="Next Of Kin Relationship">
                  <Input placeholder="Next of kin Relationship." value={this.state.relationship} onChange={(val)=>{this.setState({relationship:val.target.value})}} />
                </FormItem>
      
                <FormItem >
                  <Button  type='primary' htmlType="submit">Add Signitory</Button>
                </FormItem>

              </Form>

                  :
                  <div>

                <FormItem label={"Select member"}>
                        <Select 
                        placeholder="Account No | Name" 
                        style={{  }} 
                        value={this.state.selected_member_signitory}
                        onChange={(e)=>{
                          this.setState({ selected_member_signitory: e});

                          axios.get(`${serverconfig.backendserverurl}/api/members/${e}`)
                          .then(res => {  
                              this.setState({selected_member_signitory_object: res.data})
                          })

                        }} 
                        showSearch
                        optionFilterProp="children"
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        onSearch={this.onSearch}                    
                        >
                          {this.state.members.map(
                            (accnt)=>(
                              <Option value={accnt.id}>{accnt.AccountNumber} | {accnt.FullName}</Option>
                            ))}
                        </Select>
                    </FormItem>     

                  <FormItem >
                  <Button  type='primary' htmlType='button'
                  
                  onClick={()=>{

                    const gitem={
                      key:uuid(),
                      SurName:this.state.selected_member_signitory_object.SurName,
                      MiddleName:this.state.selected_member_signitory_object.MiddleName,
                      OtherName:this.state.selected_member_signitory_object.OtherName,
                      Nationality:this.state.selected_member_signitory_object.Nationality,
                      IDType:this.state.selected_member_signitory_object.IDType,
                      IDNumber:this.state.selected_member_signitory_object.IDNumber,
                      DateOfBirth:this.state.selected_member_signitory_object.DateOfBirth,
                      EmailAddress:this.state.selected_member_signitory_object.EmailAddress,
                      POBox:this.state.selected_member_signitory_object.POBox,
                      AreaOfResidence:this.state.selected_member_signitory_object.AreaOfResidence,
                      District:this.state.selected_member_signitory_object.District,
                      RegisteredPhoneNumber1:this.state.selected_member_signitory_object.RegisteredPhoneNumber1,
                      RegisteredPhoneNumber2:this.state.selected_member_signitory_object.RegisteredPhoneNumber2,
                      next_of_kin:this.state.selected_member_signitory_object.next_of_kin,
                      relationship:this.state.selected_member_signitory_object.relationship,
                      gender:this.state.selected_member_signitory_object.gender,
                      marital_status:this.state.selected_member_signitory_object.marital_status,
                      title:this.state.selected_member_signitory_object.title,
                      own_residence:this.state.selected_member_signitory_object.own_residence,
                      tribe:this.state.selected_member_signitory_object.tribe,
                      language2:this.state.selected_member_signitory_object.language2,
                      language1:this.state.selected_member_signitory_object.language1,
                      sacco:sacco,
                      bizuserid:bizuserid,
                      next_of_kin_contact:this.state.selected_member_signitory_object.next_of_kin_contact,
                      occupation:this.state.selected_member_signitory_object.occupation,
                      nameofemployer_business:this.state.selected_member_signitory_object.nameofemployer_business,
                      addressofemployer_business:this.state.selected_member_signitory_object.addressofemployer_business,
                      Photo:null,
                      Signature:null,
                      Thumbprint:null,
                    }
            
                    this.setState({signitories: [...this.state.signitories, gitem]});
            
                    this.setState({signitoryform_visible:false})                   
                    
                  }}
                  
                  >Add member as signitory</Button>
                   </FormItem>

                  </div>
                             
                }

                </Modal>
              </div>
            );

          }
      }

    }
    
  }

}

export default GroupForm;



