import React from 'react';
import SuperSaveOpeningForm from '../containers/SuperSaveOpeningForm'

import axios from 'axios'
import { Table, Input, Button,Collapse,Popover,Popconfirm,message,Spin } from 'antd';

import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DeleteOutlined,FundViewOutlined,LoadingOutlined,DownloadOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import * as serverconfig from '../serverconn'
import CurrencyFormat from 'react-currency-format';

import SuperSaverView from './AccountCreateSuperSaverView'
import SuperAccountAddMoneyView from './AccountAddMoneySuperSaverView'
import SuperAccountRemoveMoneyView from './AccountremoveMoneySuperSaverView'

import {
  Col,
  Row,
} from 'reactstrap';

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

function callback(key) {
  console.log(key);
}

var sacco= ''
var username=''
var token= ''
var bizuserid= ''

class SuperSaveList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    supersaveaccounts: [],
    datarequested:true,
    userrights:{},

  };

  componentDidMount(){

    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }


    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
   }else{
      bizuserid= ''
   }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/supersaveprofile/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          supersaveaccounts:res.data
        })
    })

    axios.get(serverconfig.backendserverurl+`/api/accountrights/?user=${bizuserid}`)
    .then(res => {
        this.setState({
          userrights:res.data[0]
        })

        this.setState({datarequested:false})
    })

}

 

getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};

  render() {
    const columns = [
      {
        title: 'Supersave type',
        dataIndex: 'supersavertype_name',
        key: 'id',
        ...this.getColumnSearchProps('supersavertype_name'),
      },
      {
        title: 'Account Name',
        dataIndex: 'FullName',
        key: 'id',
        ...this.getColumnSearchProps('FullName'),
      },
      {
        title: 'Account Number',
        dataIndex: 'AccountNumber',
        key: 'id',
        ...this.getColumnSearchProps('AccountNumber'),
      },
      {
        title: 'SuperSave Balance',
        dataIndex: 'supersaver_balance',
        key: 'id',
        render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
      },

      {
        title: 'Period (Years)',
        dataIndex: 'saving_period',
        key: 'id',
        render:text=><p>{text} years</p>
      },
      {
        title: 'Maturity date',
        dataIndex: 'maturity_date',
        key: 'id',
      },
      {
        title: 'Details',
        dataIndex: 'id',
        key: 'id',
        render: (text,record) =>
        <p>
        <Popover content={<p>Click here to edit,view details</p>} title="Edit,View">
         <Link to={`/supersaveaccounts/${text}`}><FundViewOutlined style={{color:'blue'}}/></Link>
        </Popover>

        &nbsp;&nbsp;
        {
        record.physical_form?
        <Popover content={<p>Click here to download physical form</p>} title="Download">
        <a href={record.physical_form}><DownloadOutlined style={{color:'green',fontSize: '20px'}}/></a> 
        </Popover>
        :
        null
        }

        </p>,
        
      } 

    ];

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return(
        <div>
            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header=" SUPERSAVE ACCOUNTS" key="1">
            <Table 
            columns={columns} 
            scroll={{ x: 1000 }}
            pagination={{showQuickJumper:true,showSizeChanger:true }}
            dataSource={this.state.supersaveaccounts} 
            bordered/>
            </Panel>
            </Collapse>
            <br></br>
            {
            this.state.userrights.can_opensupersave?
            <SuperSaveOpeningForm />
            :
            null}

        </div>
    )

    }

  }
}

export default SuperSaveList; 
