import React,{ useRef } from 'react';
import axios from 'axios'
import { Table, Input, Button,Collapse,Avatar,Modal,Tooltip,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { PrinterOutlined, MinusOutlined, CloseCircleFilled, CheckCircleOutlined, UserOutlined,DownloadOutlined } from '@ant-design/icons';
import { SearchOutlined,FundViewOutlined,CameraOutlined,PlusOutlined,LoadingOutlined } from '@ant-design/icons';
import QrReader from 'react-qr-reader'
import uuid from 'react-uuid'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
import PrintTemplate from 'react-print';
import ReactToPrint from 'react-to-print';
import * as serverconfig from '../serverconn'
import CurrencyFormat from 'react-currency-format';
import {
  Col,
  Row,
} from 'reactstrap';
import * as reactstrp from 'reactstrap';
import ReactExport from "@ibrahimrahmani/react-export-excel";

import '../tablestyle.css'; // Create this CSS file for custom styles
import LoanExtras from './LoanExtras';


var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { Title } = Typography;
const { RangePicker } = DatePicker;
const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


var sacco= ''
var bizuserid= ''
var token= ''

class ReportToPrint extends React.Component {

    state = {
      companyprofile:{},
    };
  
    componentDidMount(){
      if(localStorage.getItem("sacco")){
         sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
         bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
      
      }else{
         sacco= ''
         bizuserid= ''
      }
      
      
      if(localStorage.getItem("token")){
         token= localStorage.getItem("token")
      }else{
         token= ''
      }


      axios.defaults.headers={
        "Content-Type":"application/json",
        Authorization:`Token ${token}`
      }

      axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
      .then(res => {  
          this.setState({
            companyprofile: res.data
          })
  
      })

  
    }
  
    render() {
      return (
        <div style={{padding:20}}>
       <Row> 
                <Col xs="2" sm="2" lg="2">
                <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.sacco_logo} />
              
                  </Col>
                  <Col xs="6" sm="6" lg="6">
                  <h3>{this.state.companyprofile.sacco_name}</h3>
                  <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                  {
                  this.state.companyprofile.RegisteredPhoneNumber2==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber2
                  }
                  {
                  this.state.companyprofile.RegisteredPhoneNumber3==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber3
                  }
                  </h6>               
                  <h5>Email: {this.state.companyprofile.company_email}</h5>
                  <h5>Wesite: {this.state.companyprofile.website}</h5>
                  <h5>{this.state.companyprofile.box_number}</h5>
                
                  </Col>
       </Row>

             <h3 style={{display: 'flex',justifyContent:'center', alignItems:'center',fontWeight:'bolder'}}>LOAN DISBURSEMENT REPORT FROM {this.props.dateone} TO {this.props.datetwo} </h3>
                       
             <reactstrp.Table bordered>
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Loan number</th>
                            <th>Account Name</th>
                            <th>Account Number</th>
                            <th>Approved AMount</th>
                            <th>Loan Type</th>

                          </tr>
                        </thead>
                        <tbody>
                        {this.props.loanissues.map(
                          (item)=>(
                            <tr>
                            <td>{item.date}</td>
                            <td>{item.loan_number}</td>
                            <td>{item.accountName}</td>
                            <td>{item.accountNumber}</td>
                            <td className="align-right">{<CurrencyFormat value={item.approvedamount} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{item.loanType}</td>

                            </tr>
                          ))}
                          <tr>
                          <td style={{fontWeight:'bolder'}}>Total</td>
                          <td></td>
                          <td></td>

                          <td></td>
                          <td className="align-right" style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.props.totalofIsuances} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td></td>
                          </tr>
                      </tbody>
                  </reactstrp.Table>   

                  <br></br>
                <h3>Generated by: {CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)}</h3>
                <h3>Powered by: www.pitech.co.ug</h3>   
           
        </div>
      );
    }
  }


class LoanDisbursmentReport extends React.Component {

  state = {
    loanissues:[],
    dateone:moment().format(dateFormat).toString(),
    datetwo:moment().format(dateFormat).toString(),
    datarequested:false,
    totalofIsuances:0,

    companyprofile:{},
    staffs:[],
    creditofficer:'',
   };

   componentDidMount(){
    if(localStorage.getItem("sacco")){
      sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
   
   }else{
      sacco= ''
      bizuserid= ''
   }
   
   
   if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }


    axios.get(serverconfig.backendserverurl+`/api/staffs/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          staffs:res.data
        })
    })

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })
        this.setState({datarequested:false})
    })


   
  }
 
   handledatechange= (date, dateString) =>{
     this.setState({ dateone: dateString[0]});
     this.setState({ datetwo: dateString[1]});
   } 

 
 
   //search sales method
   searchSales=()=>{

      let form_data = new FormData();
      form_data.append('dateone', this.state.dateone);
      form_data.append('datetwo', this.state.datetwo);
      form_data.append('sacco', sacco);
      form_data.append('creditofficer', this.state.creditofficer);

      if(this.state.dateone==='' || this.state.datetwo===''){
        alert("Date one or two missing")
      }
      
      else{
          this.setState({datarequested:true})
  
          //Now submit sale data to database
          axios.post(serverconfig.backendserverurl+'/customqueries/getLoanDisbursmentReport', form_data,{
          headers: {
            'content-type': 'multipart/form-data'
          }
          })
          .then(res =>{
            this.setState({datarequested:false})
            this.setState({loanissues:JSON.parse(res.data.loanissues)})
            this.setState({totalofIsuances:res.data.totalofIssuances})
          } 
          )
          .catch(error => console.log(error)) 
         }

   

    }

   ////////////////////////////////////
  // USER SEARCH SELECT METHODS
  onBlur=()=> {
    console.log('blur');
  }
  
   onFocus=()=>{
    console.log('focus');
  }
  
  onSearch=(val)=> {
    console.log('search:', val);
  }
 

   ////////////////////////////////////////////
   // RENDERING METHOD HERE
   render() {
       
       if(this.state.datarequested===true){
        return(
          <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
          <Spin indicator={antIcon} />
          </div>
        )
  
      }else{

        return(
          <div style={{overflowX:'auto'}}>
            <div style={{display: "none"}}>
               &nbsp;&nbsp;
              <ReportToPrint
              dateone={this.state.dateone} 
              datetwo={this.state.datetwo} 
              loanissues={this.state.loanissues} 
              totalofIsuances={this.state.totalofIsuances} 
              ref={el => (this.componentRef = el)} /> 
            </div>
  
            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
              <Panel header="LOAN DISBURSEMENT REPORT" key="1">
              <div style={{display:'flex',justifyContent:'flex-start',alignSelf:'flex-start',alignItems:'flex-start'}}>
              <ReactToPrint
                  trigger={() => 
                  <Button type="primary"  shape="round" icon={<PrinterOutlined />} size={this.state.size}>
                  Print
                  </Button>
                  }
                  content={() => this.componentRef}
                  />

            &nbsp;&nbsp;&nbsp;
              <ExcelFile filename={"Pisys -Loan disbursement report from "+this.state.dateone+" to "+this.state.datetwo}  element={<Button type='primary' icon={<DownloadOutlined/>}>Download report (Excel)</Button>}>
                <ExcelSheet data={this.state.loanissues} name="Loan disbursement report">

                    <ExcelColumn label="Loan number" value="loan_number"/>
                    <ExcelColumn label="Date" value="date"/>
                    <ExcelColumn label="Account name" value="accountName"/>
                    <ExcelColumn label="Account number " value="accountNumber"/>
                    <ExcelColumn label="Loan Amount" value="approvedamount"/>
                    <ExcelColumn label="Loan Type" value="loanType"/>


                </ExcelSheet>
               </ExcelFile>

               &nbsp;&nbsp;&nbsp;

               <Form  layout="inline" >
 
 <FormItem label="Date Range">
     <RangePicker defaultValue={moment()} onChange={this.handledatechange} format={dateFormat} />
 </FormItem>

 <FormItem label="Credit Officer">
       <Select placeholder="Credit Officer" 
        value={this.state.creditofficer} 
        onChange={(val)=>{this.setState({creditofficer:val})}} >
         <Option value={''}>All Officers</Option>
         {this.state.staffs.map(
           (ctgry)=>(
             <Option value={ctgry.id}>{ctgry.full_Name}</Option>
           ))}
       </Select>
 </FormItem>


 <FormItem>
 <Button onClick={this.searchSales} type="primary" htmlType="button">Search</Button>
 </FormItem> 

 </Form>


              </div>       
          
                 <br></br>
                 <br></br>

             <reactstrp.Table bordered>
                        <thead>
                          <tr>
                            <th>Details</th>
                            <th>Date</th>
                            <th>Loan number</th>
                            <th>Account Name</th>
                            <th>Account Number</th>
                            <th>Loan Amount</th>
                            <th>Loan Type</th>

                          </tr>
                        </thead>
                        <tbody>
                        {this.state.loanissues.map(
                          (item)=>(
                            <React.Fragment key={item}>
                            <tr>
                            <td>
                            <Tooltip title="Click view loan details" placement="top">
                            <FundViewOutlined style={{color:'blue'}}
                              onClick={()=>{
                                console.log("ID: "+String(item.id))
                                this.setState({selected_id:Number(item.id)})
                                this.setState({show_details:!this.state.show_details})
                            }}
                            />
                            </Tooltip>
                            </td>


                            <td>{item.date}</td>
                            <td>{item.loan_number}</td>
                            <td>{item.accountName}</td>
                            <td>{item.accountNumber}</td>
                            <td className="align-right">{<CurrencyFormat value={item.approvedamount} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{item.loanType}</td>

                            </tr>

                            {
                              this.state.show_details===true && Number(this.state.selected_id)===Number(item.id)?
                              <tr >
                                 <td colSpan={7}>
                                  {/* Your expanded content goes here */}
                                  <div style={{overflowX:'auto'}}>
                                    
                                    {
                                    this.state.show_details===true?
                                    <LoanExtras loan_id={item.id} loan_issueobj={item} />
                                    :
                                    null
                                    }

                                  </div>
                                </td>

                              </tr>
                              :
                              null
                            }


                            </React.Fragment>
                          ))}
                          <tr>
                          <td style={{fontWeight:'bolder'}}>Total</td>
                          <td></td>
                          <td></td>

                          <td></td>
                          <td></td>

                          <td className="align-right" style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.state.totalofIsuances} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td></td>

                          </tr>

                      </tbody>
                  </reactstrp.Table> 

  
              </Panel>
              </Collapse>
  
          </div>
  
      )        

      }

   }
}

export default LoanDisbursmentReport; 
