import React from 'react';
import { Form, Input, Button,Select,InputNumber,DatePicker,message,Card,Spin,Result } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import moment from 'moment';
import PhoneInput from 'react-phone-number-input'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';

import {
  Col,
  Row,
} from 'reactstrap';
var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const { TextArea } = Input;
const dateFormat = 'DD/MM/YYYY';
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var sacco= ''
var username=''
var token= ''
var bizuserid= ''


class LoanQuickActions extends React.Component {
  state = {
    memberid:'',  
    action:'',
    datarequested:false,
    datasubmittedsuccessfully:false,
    date:moment().format(dateFormat).toString(),  
    new_loanperiod:0,
    userrights:{},
    loan_date:'',
    loan_amount:0
  }


  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    }else{
        bizuserid= ''
    }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/accountrights/?user=${bizuserid}`)
    .then(res => {
        this.setState({
          userrights:res.data[0]
        })
    })

    const memberid=this.props.memberid;
    this.setState({memberid:memberid})
  }


  //submit button pressed
  onFinish=(event) =>{
    if (this.state.date===""){
      message.error("Date is missing")
    }else if (this.state.action==="update_loanperiod" && this.state.new_loanperiod<=0){
      message.error("New loan period is invalid")
    }else{
      this.setState({datarequested:true})

      let form_data = new FormData();
      form_data.append('action', this.state.action);
      form_data.append('sacco', sacco);
      form_data.append('username', username);
      form_data.append('loan_id', this.props.loan_id);
      form_data.append('new_loanperiod', this.state.new_loanperiod);
      form_data.append('loan_amount', 0);
      form_data.append('loan_date', '');

      ///make a post request now
        axios.post(serverconfig.backendserverurl+'/customqueries/createquick_actions_loan', form_data, {
          headers: {
            'content-type': 'multipart/form-data'
          }
        })
      .then(res => {
        message.info(res.data.message)
        this.setState({datarequested:false})
        if (res.data.message==="success"){
          this.setState({datasubmittedsuccessfully:true})
        }
      })
      .catch(error => console.log(error))
    }


    }

    handleactionChange= (e) => {
       this.setState({action:e})
    }



  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{

      if (this.state.datasubmittedsuccessfully===true){
        return(
            <Card>
            <Result
            status="success"
            title="Successful"
            subTitle="Operation successful"
            extra={[
              <Button  type='primary' onClick={()=>{
                this.setState({datasubmittedsuccessfully:false})
                this.props.onRefresh()
              
              }}>Finish</Button>,
            ]}
            />
            </Card>
        );

      }else{

        return (
          <div>
            <Card>
    
            <Form
                  name="activationform"
                  onFinish={(event) => this.onFinish(event)}
              >

                <FormItem 
                      label="Action"
                      name="action"
                      rules={[
                        {
                          required: true,
                          message: 'Please select action',
                        },
                      ]}
                      >
                          <Select 
                          value={this.state.action} 
                          onChange={this.handleactionChange}
                          placeholder=" Action " >
                                <Option value='alignstatement'>Align Tracker</Option>
                                <Option value='transferoverpayments'>Transfer overPayments to savings </Option>
                                
                                {
                                 this.state.userrights.can_register_loans?
                                <Option value='update_loanperiod'>Update Loan period</Option>
                                :null
                                
                                }
                                                  
                                </Select>
                </FormItem>
               
                {
                  this.state.action==="update_loanperiod"?
                  <FormItem label="New loan period">
                    <Input name="new_loanperiod" 
                    placeholder="0.00" 
                    value={this.state.new_loanperiod} 
                    onChange={(val)=>{
                      this.setState({new_loanperiod:val.target.value})
                    }} 
                    type='number'
                    />
                  </FormItem>
                  :
                  null
                }


              { /*
                  this.state.action==="update_loanperiod"?
                  <FormItem label="New loan amount">
                    <Input name="loan_amount" 
                    placeholder="0.00" 
                    value={this.state.loan_amount} 
                    onChange={(val)=>{
                      this.setState({loan_amount:val.target.value})
                    }} 
                    type='number'
                    />
                  </FormItem>
                  :
                  null */
                }


                {/*
                  this.state.action==="update_loanperiod"?
                  <FormItem label="New loan date"
                  name="loan_date"          
                 >
                  <DatePicker 
                    onChange={
                    (date, dateString) =>{
                      //console.log(date.format(dateFormat))
                      this.setState({ loan_date: date.format(dateFormat)});
                    }} 
                    format={dateFormat} />
                  </FormItem>
                  :
                  null
                  */
                 }
    
              
                <FormItem>
                  <Button  type="primary" htmlType="submit">Submit</Button>
                </FormItem>
                </Form>
            </Card>
    
     
          </div>
        );

      }

    }

    
  }

}


export default LoanQuickActions;



