
import React from 'react'
import { connect } from 'react-redux'
import { Layout,Avatar, Menu, Breadcrumb,Button,Row, Col, Divider,Card,Dropdown,Switch,
    message, Tooltip,InputNumber,Table,Popover,Modal,Image,Form,Select,Spin,notification} from 'antd';
import { Drawer, List,Alert,Affix } from 'antd';

import {
  DesktopOutlined,
  PieChartOutlined,
  FileOutlined,
  TeamOutlined,
  UserOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  LaptopOutlined,
  NotificationOutlined,
  SettingOutlined,
  AuditOutlined,
  IdcardOutlined,
  BarsOutlined,
  ShopOutlined,
  DollarOutlined,
  PicLeftOutlined,
  LogoutOutlined,
  EditOutlined, 
  EllipsisOutlined,
  HomeOutlined,
  SnippetsOutlined,
  KeyOutlined,
  FilePdfFilled,
  RightCircleFilled,
  DollarCircleOutlined,
  AccountBookOutlined,
  FilePptOutlined,
  PayCircleOutlined,
  BarChartOutlined,
  MinusSquareOutlined,
  PlusSquareOutlined,
  BankFilled,
  AndroidOutlined,
  DeploymentUnitOutlined,
  GroupOutlined,
  AccountBookFilled,
  CalculatorFilled,
  FileFilled,
  MessageFilled,
  LoadingOutlined,
  FundViewOutlined,
  OrderedListOutlined,
  ArrowLeftOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { Badge, UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem } from 'reactstrap';
import UIfx from 'uifx';

import { NavLink } from 'react-router-dom'
import { PageHeader, Descriptions } from 'antd';
import { Link,withRouter} from 'react-router-dom';
import * as actions from '../../store/actions/auth'
import axios from 'axios'
import UserAccountUpdateForm from '../components/UserAccountUpdateForm'
import WrappedPasswordResetForm from './PasswordResetForm'
import WrappedPasswordResetForcedForm from './PasswordResetForcedForm'

import * as serverconfig from '../serverconn'
import * as serversocketconfig from '../serversocketconfig'

import logo from '../../assets/Pitech-logoconv.png';
import moment from 'moment';
import CurrencyFormat from 'react-currency-format';
import ReconnectingWebSocket from 'reconnecting-websocket';
import tickAudio from '../../assets/sounds/that-was-quick.mp3';

var CryptoJS = require("crypto-js");

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;
const style = { background: '#0092ff', padding: '8px 0' };
const { Meta } = Card;
const dateFormat = 'DD/MM/YYYY';
const FormItem=Form.Item;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

const tick = new UIfx(tickAudio,  {
  volume: 1.0, // number between 0.0 ~ 1.0
  throttleMs: 100
});

const pStyle = {
  fontSize: 16,
  lineHeight: '24px',
  display: 'block',
  marginBottom: 16,
};

var userbalancenotificationsocket = new ReconnectingWebSocket(serversocketconfig.socketurl+'/ws/userbalancenotification/');
var loanapprovalnotification_socket = new ReconnectingWebSocket(serversocketconfig.socketurl+'/ws/loanapprovalnotification/');

const DescriptionItem = ({ title, content }) => (
  <div
    className="site-description-item-profile-wrapper"
    style={{
      fontSize: 14,
      lineHeight: '22px',
      marginBottom: 7,
    }}
  >
    <p
      className="site-description-item-profile-p"
      style={{
        marginRight: 8,
        display: 'inline-block',
      }}
    >
      {title}:
    </p>
    {content}
  </div>
);

var bizuserid= ''
var sacco= ''
var token= ''

class SaccoLayout extends React.Component{

  state = {
    collapsed: false,
    theme: 'dark',
    current: '1',
    titlecolor: '#fff',
    titlevisibility:"visible",
    userprofile:{},
    companyprofile:{},
    isToggle: null,
    visible: false,
    resetmodalvisible:false,
    updateformvisible:'none',
    userrights:{},
    date_today:moment().format(dateFormat).toString(),
    dashboardlocked:false,
    userbalancemodalvisible:false,
    users:[],
    userto:'',
    transferamount:0,
    datarequested:false,
    transfermode:'',
    safemanagertransfermode:'',
    numberofnotifications:0,
    notificationinfo:'',
    selectedtransferobject:{},
    acceptancestatus:'',
    passwordchange_modal_visible:false,
    notifications:[],
    shownotificationmodal:false,
    warning_list:[]

  };

  // show the profile modal
  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  // update user profile
  showUpdateForm=()=>{
    this.state.updateformvisible=="none"?
    this.setState({
      updateformvisible: "block",
    })
    :
    this.setState({
      updateformvisible: "none",
    })    
  }


  /////////////////////////////////////////////
  //PASSWORD RESET MODAL METHODS BEGIN HERE

  showpasswordresetModal = () => {
    this.setState({
      resetmodalvisible: true,
    });
  };

  //Item addition success
  onresetdrawerClose = () => {
    this.setState({ resetmodalvisible: false });

  };


  //show user balance modal
  showuserbalancemodalvisible=()=>{
    this.setState({ userbalancemodalvisible: true });
  }

  hideuserbalancemodalvisible=()=>{
    this.setState({ userbalancemodalvisible: false });
  }




//component did mount
  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    if(localStorage.getItem("bizuserid")){
       bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    }else{
        bizuserid= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    //component mount method
    this.componentmountmethod();
    




    //observer the socket changes now
    loanapprovalnotification_socket.onopen = () => {
      console.log("onopen for loan approval notification");
    // connection opened
    };

    loanapprovalnotification_socket.onmessage = async (e) => {
        notification.destroy()

        // a message was received
        console.log("onmessage for loan approval note",e.data);
        const data = JSON.parse(e.data);

        if (Number(data.userto)===Number(bizuserid)){
          this.setState({numberofnotifications:Number(this.state.numberofnotifications)+1})

          //play notification
          tick.play();
  
          notification.open({
          top:60,
          duration:0,
          message:  data.title,
          description:data.message,
          icon: <MessageFilled style={{ color:"green" }} />,
        })
  
        }
        
    };


    //destroy all previous notifications
    notification.destroy()

    axios.get(`${serverconfig.backendserverurl}/api/usernotifications?read=${false}&user=${bizuserid}`)
    .then(res => {
      console.log(res.data)
      //now loop through the tasks
      res.data.map(
        (ntfy)=>{
          notification.open({
            top:60,
            duration:0,
            message:  String(ntfy.title),
            description:String(ntfy.message),
            icon: <MessageFilled style={{ color: "green" }} />,
          })
          
          this.setState({numberofnotifications:Number(this.state.numberofnotifications)+1})
        })

        this.setState({notifications:res.data})

    })
    
  }



//component mount method
componentmountmethod=()=>{
  
  //get the user profile here
  axios.get(serverconfig.backendserverurl+`/api/accounts/${bizuserid}/`,{
    headers: {
      'content-type': 'multipart/form-data'
    }
    })
  .then(res => {
      this.setState({
        userprofile:res.data
      })

      if(res.data.force_passwordchange===true){
        this.setState({passwordchange_modal_visible:true})
      }
   
  })

  axios.get(serverconfig.backendserverurl+`/api/saccos/${sacco}`)
  .then(res => {
      this.setState({
        companyprofile:res.data
      })

      if (res.data.activated===false){
        this.setState({dashboardlocked:true})
      }

  })


  axios.get(serverconfig.backendserverurl+`/api/accountrights/?user=${bizuserid}`)
  .then(res => {
      this.setState({
        userrights:res.data[0]
      })
  })

  axios.get(serverconfig.backendserverurl+`/api/accounts/?sacco=${sacco}`)
  .then(res => {
      this.setState({
        users:res.data
      })
    })


}


  //method for collupsing of the menu
  onCollapse = collapsed => {
    console.log(collapsed);
    this.setState({ collapsed });
    this.setState({titlevisibility: collapsed ? "hidden" :"visible"});
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });

    this.setState({titlevisibility:"hidden"});

  };


  handleusertoChange= (e) => this.setState({ userto: e});
  handletransferamountChange= (e) => this.setState({ transferamount: e});
  handletransfermodeChange= (e) => this.setState({ transfermode: e});
  handlesafemanagertransfermodeChange= (e) => this.setState({ safemanagertransfermode: e});
  handleacceptancestatusChange= (e) => this.setState({ acceptancestatus: e});


  //submit the deposit now
  handleFormSubmit=(event) =>{
    if (this.state.userto===''){
      message.info("User To cant be empty")
    }else if(Number(this.state.transferamount)>Number(this.state.userprofile.User_balance)){
      message.info("Transfer Amount cant be greater than available user balance")
    }else if(Number(this.state.userto)===Number(bizuserid)){
      message.info("You cant transfer to own account")
    }
    else{
      this.setState({datarequested:true})
  
      let form_data = new FormData();
      form_data.append('date',moment().format(dateFormat).toString());
      form_data.append('userfrom', bizuserid);
      form_data.append('userto', this.state.userto);
      form_data.append('transferAmount', this.state.transferamount);
      form_data.append('status', "pending");
      form_data.append('sacco', sacco);

      axios.post(serverconfig.backendserverurl+'/customqueries/userbalancetransfer', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
        })
      .then(res => {
        this.setState({datarequested:false})
        message.info(res.data.message)
      })
      .catch(error => console.log(error))
    }
    

  }

  handlemanagerTransferSubmit=(event) =>{
    if (this.state.safemanagertransfermode===''){
      message.info("Safe Manager Transfer Mode cant be empty")
    }else if(this.state.safemanagertransfermode==="manager_safe" &&  Number(this.state.transferamount)>Number(this.state.userprofile.User_balance)){
      message.info("Transfer Amount cant be greater than available user balance")
    }else if(this.state.safemanagertransfermode==="safe_manager" &&  Number(this.state.transferamount)>Number(this.state.companyprofile.safe_balance)){
      message.info("Transfer Amount cant be greater than available safe balance")
    }
    else{
      this.setState({datarequested:true})
  
      let form_data = new FormData();
      form_data.append('date',moment().format(dateFormat).toString());
      form_data.append('userid', bizuserid);
      form_data.append('transferAmount', this.state.transferamount);
      form_data.append('sacco', sacco);
      form_data.append('safemanagertransfermode', this.state.safemanagertransfermode);

      axios.post(serverconfig.backendserverurl+'/customqueries/managersafetransfer', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
        })
      .then(res => {
        this.setState({datarequested:false})
        message.info(res.data.message)
      })
      .catch(error => console.log(error))
    }

  }

//confirm the transfer here
  receiveUserBalance = () => {
    let form_data1 = new FormData();
    form_data1.append('transferid', this.state.selectedtransferobject.id);
    form_data1.append('sacco', sacco);
    form_data1.append('acceptancestatus', this.state.acceptancestatus);

    axios.post(`${serverconfig.backendserverurl}/customqueries/receiveuserbalance`, form_data1, {
      headers: {
        'content-type': 'multipart/form-data'
      }
      }).then(res =>{
       message.info("successfully response") 
     }
     );

  };


   render(){

    return (
      <div>
        {
         this.props.isAuthenticated ?
         <Layout style={{ minHeight: '100vh' }} >
           {
             this.state.dashboardlocked===false?
             <Sider
             collapsedWidth="0"
             collapsed={this.state.collapsed}
             onCollapse={this.onCollapse} 
             theme={this.state.theme}
             breakpoint="lg"
             onBreakpoint={broken => {
               console.log(broken);
             }}
             zeroWidthTriggerStyle={{display:'none'}}
             style={{width:400}}
             >
               <br></br>
              <div className="logo" style={{ paddingLeft: 30}} mode="inline">
              <Row>
   
                <Col span={6}>
                {/**Logo */}
                <Image
                 width={50}
                 height={50}
                 src={this.state.companyprofile.sacco_logo}    
                 />

                 {/**profile name */}
                 <h6 style={{display:'flex',color:'white',margin:3}}>{this.state.companyprofile.sacco_name}</h6> 

                </Col>
             </Row>
              <br/>
              </div>
   
              <Menu 
              theme={this.state.theme}
              onClick={this.handleClick}
              defaultSelectedKeys={['1']} 
              mode="vertical">
   
               <SubMenu
                  key="sub1"
                  title={
                    <span>
                      <HomeOutlined />
                      <span>Home</span>
                    </span>
                  }
                >
               <Menu.Item key="0" >
                 <RightCircleFilled />
                 <NavLink to='/'><span>Home</span></NavLink>
   
               </Menu.Item>
   
               <Menu.Item key="890" >
                 <RightCircleFilled />
                 <NavLink to='/quickmenu/'><span>Quick Menu</span></NavLink>
   
               </Menu.Item>

               <Menu.Item key="890122" >
                 <RightCircleFilled />
                 <NavLink to='/statistics/'><span>Statistics</span></NavLink>
               </Menu.Item>
   
               </SubMenu>

               <SubMenu
                  key="sub2"
                  title={
                    <span>
                      <UserOutlined />
                      <span>Members</span>
                    </span>
                  }
                >
                {
                  this.state.userrights.can_view_members?
                  <Menu.Item key="1" >
                  <RightCircleFilled />
                  <NavLink to='/members/'><span>Manage Accounts</span></NavLink>
                  </Menu.Item>
                  :
                  null
                }


                {
                  this.state.userrights.can_view_members?
                    this.state.companyprofile.showgroupaccounts?
                    <Menu.Item key="39" >
                    <RightCircleFilled />
                    <NavLink to='/groups/'><span>Create Groups</span></NavLink>
                    </Menu.Item>
                    :
                    null
                  :
                  null
                }  

                {
                this.state.userrights.can_view_members?
                <Menu.Item key="6755" >
                <RightCircleFilled />
                <NavLink to='/supersaveaccounts/'><span>SuperSave Accounts</span></NavLink>
                </Menu.Item>
                  :
                  null
                }

               {
                this.state.userrights.can_view_members?
                <Menu.Item key="673355" >
                <RightCircleFilled />
                <NavLink to='/teebagooloaccounts/'><span>Teeba Goolo Accounts</span></NavLink>
                </Menu.Item>
                  :
                  null
                }

              

                {
                  this.state.userrights.can_view_members?
                  <Menu.Item key="36" >
                    <RightCircleFilled />
                    <NavLink to='/financialstatement/'><span>Financial Statement</span></NavLink>
                  </Menu.Item>
                  :
                  null
                }
   


              {
                  this.state.userrights.can_view_withdraw_requistions?
                  <Menu.Item key="3928276" >
                    <RightCircleFilled />
                    <NavLink to='/withdrawrequistions_list/'><span>Withdraw requistions</span></NavLink>
                  </Menu.Item>
                  :
                  null
                }

               </SubMenu>
   
               <SubMenu
                  key="sub3"
                  title={
                    <span>
                      <AccountBookFilled />
                      <span>Loans</span>
                    </span>
                  }
                >

                {
                  this.state.userrights.can_view_loanapplications?
                  <Menu.Item key="3" >
                  <RightCircleFilled />
                  <NavLink to='/loanapplications/'><span>Applications</span></NavLink>
                 </Menu.Item>
                  :
                  null
                } 


                {
                  this.state.userrights.can_view_concluded_applications?
                  <Menu.Item key="00004" >
                  <RightCircleFilled />
                  <NavLink to='/concluded_applications/'><span>Pending Verifications</span></NavLink>
                  </Menu.Item>                  
                  :
                  null
                } 


               {
                  this.state.userrights.can_view_verified_applications?
                  <Menu.Item key="0012004" >
                  <RightCircleFilled />
                  <NavLink to='/final_review_list/'><span>Pending Reviews</span></NavLink>
                  </Menu.Item>                  
                  :
                  null
                } 


               
                {
                  this.state.userrights.can_view_loanreviews?
                  <Menu.Item key="4" >
                  <RightCircleFilled />
                  <NavLink to='/pending_approval_list/'><span>Pending Approvals</span></NavLink>
                  </Menu.Item>                  
                  :
                  null
                }    


                {
                  this.state.userrights.can_view_loanapprovals?
                  <Menu.Item key="5" >
                  <RightCircleFilled />
                  <NavLink to='/pending_disbursement_list/'><span>Pending Disbursements</span></NavLink>
                  </Menu.Item>                  
                  :
                  null
                } 

                


               {
                  this.state.userrights.can_view_rejected_loanapplications?
                  <Menu.Item key="58987" >
                  <RightCircleFilled />
                  <NavLink to='/rejectedloanapplications/'><span>Rejected Application </span></NavLink>
                  </Menu.Item>                  
                  :
                  null
                }    


               {
                  this.state.userrights.can_view_loandisbursements?
                  <Menu.Item key="6" >
                  <RightCircleFilled />
                  <NavLink to='/loanissues/'><span>Disbursement</span></NavLink>
                  </Menu.Item>                  
                  :
                  null
                }    

               </SubMenu>


                {
                  this.state.userprofile.branch_manager?
                  <SubMenu
                  key="sub1112"
                  title={
                    <span>
                      <OrderedListOutlined />
                      <span>Products</span>
                    </span>
                  }
                >
                <Menu.Item key="11" >
                    <NavLink to='/accounttypes/'><span>Ordinary </span></NavLink>
                  </Menu.Item>

                  <Menu.Item key="6775" >
                    <NavLink to='/supersavesaccounttypes/'><span>Super save </span></NavLink>
                  </Menu.Item>
        
                  <Menu.Item key="63775" >
                    <NavLink to='/teebagooloaccounttypes/'><span>Teeba goolo </span></NavLink>
                  </Menu.Item>

                  <Menu.Item key="13" >
                  <NavLink to='/loantypes/'><span>Loans </span></NavLink>
                  </Menu.Item>   

                  </SubMenu>
                  :
                  null}


               {
                  this.state.userprofile.branch_manager?
                  <SubMenu
                  key="sub8"
                  title={
                    <span>
                      <SettingOutlined />
                      <span>Settings</span>
                    </span>
                  }
                >

              <Menu.Item key="22233" >
                 <NavLink to='/companyaccounts/'><span>Chart of accounts (COA) </span></NavLink>
               </Menu.Item> 

              <Menu.Item key="11100117" >
                  <NavLink to='/companyprofile/'><span>Reapers company profile</span></NavLink>
                </Menu.Item>

               <Menu.Item key="38" >
                 <NavLink to='/useraccounts/'><span>User accounts</span></NavLink>
               </Menu.Item>
     
               <Menu.Item key="14" >
                 <NavLink to='/idtypes/'><span>ID types</span></NavLink>
               </Menu.Item> 

         
               <Menu.Item key="16" >
                 <NavLink to='/staffroles/'><span>Staff roles</span></NavLink>
               </Menu.Item> 


               <Menu.Item key="2211233" >
                 <NavLink to='/ugdistricts/'><span>Ugandan districts </span></NavLink>
               </Menu.Item> 
           
               <Menu.Item key="22778811233" >
                 <NavLink to='/saccovaluers/'><span>Sacco land valuers </span></NavLink>
               </Menu.Item>            
           
               <Menu.Item key="227722228811233" >
                 <NavLink to='/recyclelist/'><span>Recycle Bin</span></NavLink>
               </Menu.Item>    

               <Menu.Item key="227000722228811233" >
                 <NavLink to='/systemlogs/'><span>System logs</span></NavLink>
               </Menu.Item>   

               </SubMenu>
                :
                null
              } 

              
               <SubMenu
                  key="sub9"
                  title={
                    <span>
                      <FilePdfFilled />
                      <span>Financial reports</span>
                    </span>
                  }
                >

                {
                  this.state.userrights.viewtill?
                  <Menu.Item key="23" >
                  <NavLink to='/tillsheet/'><span>Journal</span></NavLink>
                  </Menu.Item>                  
                  :
                  null
                } 


                {
                  this.state.userrights.viewtill?
                  <Menu.Item key="221123" >
                  <NavLink to='/ledgerreport/'><span>Ledger</span></NavLink>
                  </Menu.Item>                  
                  :
                  null
                } 


                {
                  this.state.userrights.viewtill?
                  <Menu.Item key="22123" >
                  <NavLink to='/trialbalance/'><span>Trial Balance</span></NavLink>
                  </Menu.Item>                  
                  :
                  null
                } 


                {
                this.state.userprofile.branch_manager?
                <Menu.Item key="26" >
                <NavLink to='/incomestatement/'><span>Income Statement</span></NavLink>
                  </Menu.Item>
                :
                null
                }


              {
                  this.state.userrights.viewtill?
                  <Menu.Item key="26002" >
                  <NavLink to='/cashflow_report/'><span>Cashflow / Cashbook report</span></NavLink>
                  </Menu.Item>
              :
              null
              }

              {
                this.state.userprofile.branch_manager?
                <Menu.Item key="34" >
                <NavLink to='/balancesheet/'><span>Balance Sheet</span></NavLink>
                </Menu.Item>
                :
                null
                }

              {
                  this.state.userrights.can_view_members?
                  <Menu.Item key="25" >
                  <NavLink to='/memberbalances/'><span>Member summary report</span></NavLink>
                  </Menu.Item>               
                  :
                  null
                } 

              {
                this.state.userprofile.branch_manager ?
                <Menu.Item key="34000" >
                <NavLink to='/periodic_performance_report/'><span>Periodic performance summary report</span></NavLink>
                </Menu.Item>
                :
                null
                }


               {
                  this.state.userrights.can_view_members?
                  <Menu.Item key="787" >
                  <NavLink to='/memberdepositsreport/'><span>Member savings Report</span></NavLink>
                  </Menu.Item>               
                  :
                  null
                } 

                {
                  this.state.userrights.can_view_members?
                  <Menu.Item key="26785" >
                  <NavLink to='/savingsdefaultersreport/'><span>Savings Defaulters Report</span></NavLink>
                  </Menu.Item>               
                  :
                  null
                } 

                {
                  this.state.userrights.can_view_members?
                  <Menu.Item key="2522111" >
                  <NavLink to='/deferedschdeules/'><span>Deffered schedules</span></NavLink>
                  </Menu.Item>               
                  :
                  null
                } 


               </SubMenu>
   

               <SubMenu
                  key="sub10"
                  title={
                    <span>
                      <FilePdfFilled />
                      <span>Loan reports</span>
                    </span>
                  }
                >
             

                {
                  this.state.userrights.outstandingloans?
                  <Menu.Item key="28" >
                  <NavLink to='/loanoutstanding/'><span>Outstanding report</span></NavLink>
                  </Menu.Item>        
                  :
                  null
                } 


                {
                  this.state.userrights.loanrepaymentstatus?
                  <Menu.Item key="29" >
                  <NavLink to='/loanrepaymentstatus/'><span>Arrears report</span></NavLink>
                  </Menu.Item>       
                  :
                  null
                } 




                {
                  this.state.userrights.loandisburshmentreport?
                  <Menu.Item key="32" >
                  <NavLink to='/loandisbursement/'><span>Disbursements</span></NavLink>
                 </Menu.Item>
                  :
                  null
                } 


                </SubMenu>

                <SubMenu
                  key="sub11"
                  title={
                    <span>
                      <FilePdfFilled />
                      <span>Other reports</span>
                    </span>
                  }
                >




          {
                this.state.userprofile.branch_manager?
                <Menu.Item key="110" >
                <NavLink to='/saccosmsusagestatement/'><span>SMS Usage Report</span></NavLink>
                </Menu.Item>
                :
                null
                }


                {
                this.state.userprofile.branch_manager?
                <Menu.Item key="1110" >
                <NavLink to='/sentemailsreport/'><span>Emails sent report</span></NavLink>
                </Menu.Item>
                :
                null
                }

                {
                this.state.userprofile.branch_manager?
                <Menu.Item key="227000722228811233" >
                 <NavLink to='/systemlogs/'><span>System logs</span></NavLink>
                 </Menu.Item> 
                :
                null
                } 

              </SubMenu>

              </Menu>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
   
   
            </Sider>
             :
             null
           }
        
             <Layout className="site-layout">
   
            <PageHeader
              style={{backgroundColor: "white"}}
              ghost={true}
              tags={React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {className: 'trigger',
              onClick: this.toggle,
            })}
              extra={[

                <Badge  onClick={()=>{
                  //this.showuserbalancemodalvisible()
                  this.setState({shownotificationmodal:true})
                }}  pill color="danger">{this.state.numberofnotifications}</Badge>,


                <Avatar size={30} icon={<UserOutlined />} alt="User Pic" src={serverconfig.backendserverurl+this.state.userprofile.profile_pic} />,
                <Dropdown.Button overlay={(    <Menu >
                  <Menu.Item key="1" onClick={this.props.logout}>
                    <LogoutOutlined />
                    Log out
                  </Menu.Item>
                  <Menu.Item key="2" onClick={this.showDrawer}>
                    <UserOutlined />
                    View Profile
                  </Menu.Item>
                  <Menu.Item key="3" onClick={this.showpasswordresetModal}>
                    <KeyOutlined />
                    Change Password
                  </Menu.Item>
                  
            
                </Menu>)}>
                Hi, {this.state.userprofile.username}
                </Dropdown.Button>
              ]}
              >
          </PageHeader>

          {/*<Tooltip title="Transactions with pending issues" placement="top">
            <div style={{display:'flex',flexDirection:'column',margin:6}}>   
              <NavLink to='/txn_issuesreport/' >
              <h4 style={{color:'orange'}}>WARNING: {this.state.warning_list.length} Transactions have issues.</h4>
              </NavLink>           
            </div>
          </Tooltip> */}
        


           <Content style={{ margin: '0 16px' }}>
             <br></br>
             {
               this.state.notificationinfo===""?
               null
               :
               <NavLink >
                <Alert showIcon message={this.state.notificationinfo} type="info" onClick={()=>{this.showuserbalancemodalvisible()}} />
               </NavLink>


             }

             <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
             <br></br>
            <div style={{display:'flex',flexDirection:'row'}}>
            
            {
            this.props.location.pathname==='/'?
            null:
            <Affix>

            <Tooltip title="Go to home">
            <HomeOutlined style={{display:'flex',fontSize: 30,marginRight:30}} onClick={()=>{
              this.props.history.push('/')      
              }}/>
            </Tooltip>
            </Affix>
              }


              {
                this.props.location.pathname==='/'?
                null:
                <Affix>
                <Tooltip title="Go back">
                <ArrowLeftOutlined style={{display:'flex',fontSize: 30,marginRight:5}} onClick={()=>{
                  this.props.history.goBack()      
                  }}/>
                </Tooltip>
                </Affix>
              }

            </div>
            <br></br>
            
            
            
             {this.props.children}
             </div>
           </Content>
           <Footer style={{ textAlign: 'center' }}>System Design © 2021 Created by PI-Tech</Footer>
         </Layout>
       </Layout>
         :
         <Layout style={{ minHeight: '100vh' }}>
         <Content className="site-layout" style={{ padding: '0 50px', marginTop: 64 }}>
           <div className="site-layout-background" style={{padding: 24, minHeight: 380,display: 'flex',  justifyContent:'center', alignItems:'center' }}>
             {this.props.children}
           </div>
         </Content>
         <Footer style={{ textAlign: 'center' }}>System Design © 2021 Created by PI-Tech</Footer>
         </Layout>
        }

        <Drawer
          title="Account Profile"
          width={520}
          placement="right"
          closable={false}
          onClose={this.onClose}
          visible={this.state.visible}
        >
          <p style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>      
             <Avatar
              size={100}
              icon={<UserOutlined />}
              alt="client logo"
              src={serverconfig.backendserverurl+this.state.userprofile.profile_pic}
              />
          </p>
          <Row>
            <Col span={12}>
              <DescriptionItem title="User Name" content={this.state.userprofile.username} />
            </Col>
            <Col span={12}>
              <DescriptionItem title="Email" content={this.state.userprofile.email} />
            </Col>
          </Row>         
          <p style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>      
             <Button type="primary" onClick={this.showUpdateForm}>
               {
                 this.state.updateformvisible=="none"?
                 "Open Update Form"
                 :
                 "Close Update Form"
               }
               
               </Button>
          </p>
          
          <div style={{display:this.state.updateformvisible}}>
           <UserAccountUpdateForm accountID={this.state.userprofile.id} />       

          </div>

        </Drawer>


        <Drawer
          title="Password Change Form"
          width={520}
          placement="right"
          closable={false}
          onClose={this.onresetdrawerClose}
          visible={this.state.resetmodalvisible}
        >
            <WrappedPasswordResetForm />

        </Drawer>

   



        <Modal   
          visible={this.state.passwordchange_modal_visible}
          title="Password update form"
          //onCancel={()=>{this.setState({passwordchange_modal_visible:false})}}
      
          footer={[
           /* <Button key="back" onClick={()=>{this.setState({passwordchange_modal_visible:false})}}>
              Cancel
            </Button> */
            ]}
            >
              <h4 style={{color:'red'}}>Please update your password before you proceed any further</h4>
              <WrappedPasswordResetForcedForm />

        </Modal>  


        <Modal            
          visible={this.state.shownotificationmodal}
          title="Notifications"
          onCancel={()=>{
            this.setState({shownotificationmodal:false})

            //mark them as read
            this.state.notifications.map((item)=>{
              let form_data = new FormData();
              form_data.append('read', true);

              axios.put(serverconfig.backendserverurl+`/api/usernotifications/${item.id}/`, form_data, {
                headers: {
                  'content-type': 'multipart/form-data'
                }
              })
              .then(res => {
                console.log(res.data)
              })

            })

          }}
      
          footer={[
            <Button key="back" onClick={()=>{
              this.setState({shownotificationmodal:false})

              console.log(this.state.notifications)
              //mark them as read
              this.state.notifications.map((item)=>{
                console.log(item.id)
                let form_data = new FormData();
                form_data.append('read', true);

                axios.put(serverconfig.backendserverurl+`/api/usernotifications/${Number(item.id)}/`, form_data, {
                  headers: {
                    'content-type': 'multipart/form-data'
                  }
                })
                .then(res => {
                  console.log(res.data)
                })

              }) 

            }}>
              Cancel
            </Button>
            ]}
            >

          {this.state.notifications.map(
            (note)=>{
              return(<Alert closable style={{margin:6}} showIcon message={note.title} description={note.message} type="success" />);
            }
          )}

        </Modal>
      </div>
  
  );

  }
}


const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(
      actions.logout(),
      )
  }
}


export default withRouter(connect(null,mapDispatchToProps)(SaccoLayout)) ;

  
