import React,{ useRef } from 'react';
import axios from 'axios'
import { Tooltip,Popover,Result,Table,Popconfirm,Input, Button,Collapse,Card,Avatar,Modal,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { PrinterOutlined, MinusOutlined, CloseCircleFilled, CheckCircleOutlined, UserOutlined } from '@ant-design/icons';
import { DeleteOutlined,SearchOutlined,CameraOutlined,PlusOutlined,EditOutlined,PlusSquareFilled,SaveOutlined,LoadingOutlined } from '@ant-design/icons';
import QrReader from 'react-qr-reader'
import uuid from 'react-uuid'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
import PrintTemplate from 'react-print';
import ReactToPrint from 'react-to-print';
import {
  Col,
  Row,
} from 'reactstrap';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import * as reactstrp from 'reactstrap';
import { ToWords } from 'to-words';

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { Title } = Typography;
const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

const toWords = new ToWords();

message.config({
  top: 200,
  duration: 2,
  maxCount: 3,
  rtl: true,
});


var token= ''
var sacco= ''
var username=''
var bizuserid= ''


class SuperAccountAddMoneyView extends React.Component {

  state = {
    date:moment().format(dateFormat).toString(),
    selectedaccount:'',
    selectedaccountobject:{},
    supersaveaccounts:[],
    datarequested:true,
    totalamount:0,
    fixedrate:0,
    previoustransactions:[],
    datasubmittedsuccessfully:false,
    fixedamount:0,
    companyprofile:{},
    accountchange:false,
    userprofile:{},
    fdperiod:0,
    fixedratetype:'simple',
    supersaveid:'',
    supersaveraccountbalance:0,

  }

  callback(key) {
    console.log(key);
  }

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    
    }else{
       sacco= ''
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
   }else{
      bizuserid= ''
   }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    let form_data1 = new FormData();
    form_data1.append('userid', bizuserid);
    
    //get the user profile here
    axios.post(serverconfig.backendserverurl+'/customqueries/get_user_profile',form_data1,{
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res => {
        this.setState({
          userprofile:res.data
        })
     
    })


    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })

    axios.get(serverconfig.backendserverurl+`/api/supersaveaccounts/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          supersaveaccounts:res.data
        })
        this.setState({datarequested:false})

    })

   

  }

  

  ////////////////////////////////////
  // PRODUCT SEARCH SELECT METHODS
   onBlur=()=> {
    console.log('blur');
  }
  
   onFocus=()=>{
    console.log('focus');
  }
  
  onSearch=(val)=> {
    console.log('search:', val);
  }

  //handle client change
  handleselectedaccountChange= (e) =>{
    this.setState({accountchange:true})
    this.setState({ selectedaccount: e});
    //make query for last transactions
    axios.get(`${serverconfig.backendserverurl}/api/members/${e}`)
      .then(res => {  
          this.setState({selectedaccountobject: res.data})

          this.setState({accountchange:false});
      })

  }

  handlefixedrateChange= (e) => this.setState({ fixedrate: e.target.value});
  handlefixedamountChange= (e) => this.setState({ fixedamount: e});
 

  //submit the deposit now
  handleFormSubmit=(event) =>{

 if (this.state.fixedamount===0){
      message.info("Fixed amount cant be 0")
    }else if (this.state.selectedaccountobject.id===undefined){
      message.info("Member account cant be empty")
    }else if (Number(this.state.fixedamount)>=Number(this.state.supersaveraccountbalance)){
      message.info("Account balance less")
    }
    else{
      this.setState({datarequested:true})
      console.log(username)
  
      let form_data = new FormData();
      form_data.append('date',this.state.date);
      form_data.append('fixedamount', this.state.fixedamount);
      form_data.append('sacco', sacco);
      form_data.append('account', this.state.selectedaccountobject.id);
      form_data.append('username', username);
      form_data.append('supersaveid', this.state.supersaveid);

      axios.post(serverconfig.backendserverurl+'/customqueries/addmoneytosavingfromsupersaver', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
        })
      .then(res => {
        this.setState({datarequested:false})
        message.info(res.data.message)
        if (res.data.message==="success"){
          this.setState({datasubmittedsuccessfully:true})
        }
        
      })
      .catch(error => console.log(error))
    }
    
  }


  ////////////////////////////////////////////
  // RENDERING METHOD HERE
  render() {

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      if(this.state.datasubmittedsuccessfully===true){
        return(
          <div>

              <Card>
              <Result
              status="success"
              title="Successfully Withdrawn Money from Supersaver to savings account "
              subTitle="Finish off transaction"
              extra={[
                <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
              ]}
              />

              </Card>

          </div>

        )

      }else{
        return(
          <div>
            <div>
 
                    <Card>

                    <FormItem label="Super Saver Account">
                        <Select 
                        placeholder="Account No | Name" 
                        style={{  }} 
                        value={this.state.supersaveid}
                        onChange={
                          (val)=>{
                            this.setState({supersaveid:val})
                            axios.get(`${serverconfig.backendserverurl}/api/supersaveaccounts/${val}`)
                            .then(res => {  
                                this.setState({supersaveraccountbalance: res.data.fixedamount})
                                this.handleselectedaccountChange(res.data.member)
                            })
                          }} 
                        showSearch
                        optionFilterProp="children"
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        onSearch={this.onSearch}
                                             
                        >
                          {this.state.supersaveaccounts.map(
                            (accnt)=>(
                              <Option value={accnt.id}>{accnt.accountname} | {accnt.accountno} | {accnt.fixedamount}</Option>
                            ))}
                        </Select>
                    </FormItem>
                    {
                      this.state.accountchange?
                      <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
                      <Spin indicator={antIcon} />
                      </div>
                      :
                      null
                    }


                    <h3 style={{fontWeight:'bold',color:'#154360'}}>Account Details</h3>
                    <h4>Account Number:{this.state.selectedaccountobject.AccountNumber}</h4>
                    <h4>Account Contact:{this.state.selectedaccountobject.RegisteredPhoneNumber1}</h4>
                    <h4>Account Name:{this.state.selectedaccountobject.FullName}</h4>
                    <h4>Saving Balance:<CurrencyFormat value={this.state.selectedaccountobject.UserBalance} displayType={'text'} thousandSeparator={true}  /> UGX</h4>
                    <h4>SuperSaver Account Balance:<CurrencyFormat value={this.state.supersaveraccountbalance} displayType={'text'} thousandSeparator={true}  /> UGX</h4>

                    <br></br>
                    <h3 style={{fontWeight:'bold',alignSelf:'center',color:'#154360'}}>SUPER SAVER ACCOUNT FORM</h3>
                    <Form
                      onFinish={(event) => this.handleFormSubmit(event)}
                  >

                    <FormItem label="Date"
                      name='date'
                      rules={[
                        {
                          required: true,
                          message: 'Please Select date',
                        },
                      ]}
                    >
                        <DatePicker value={moment()} onChange={(date, dateString)=>{this.setState({ date: dateString})}} format={dateFormat} />
                    </FormItem>


                    <FormItem label='Amount (To Savings Account)'
                    name="fixedamount"
                    rules={[
                      {
                        required: false,
                        message: 'Please input amount',
                      },
                    ]}
                    >
                      <InputNumber
                        style={{ width: 200 }} 
                        defaultValue={0.0}
                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={value => value.replace(/\x\s?|(,*)/g, '')}
                        onChange={this.handlefixedamountChange}
                      />    
                                  
                    </FormItem>

                  <FormItem>
                    <Button  type="primary" htmlType="submit">Withdraw to Savings</Button>
                  </FormItem>
                  </Form>
  
                </Card>
          </div>
    
         </div>              
                      
        )
      }

    }
   
  }
}

export default SuperAccountAddMoneyView; 
