import React from 'react';
import axios from 'axios';
import { Card, Button, Avatar,Modal,Spin } from 'antd';
import AccountUpdateForm from '../components/AccountUpdateForm'
import { UserOutlined,LoadingOutlined } from '@ant-design/icons';
import { PlusOutlined } from '@ant-design/icons';
import { Drawer,Divider } from 'antd';
import * as serverconfig from '../serverconn'
import {
  Col,
  Row,
} from 'reactstrap';
import CurrencyFormat from 'react-currency-format';

const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''

class LoanApplicationDetails extends React.Component{
    state={
        datarequested:true,
        application_obj:{},
        memberperiodic_balanceobj:{}
    }

    componentDidMount(){
      if(localStorage.getItem("token")){
         token= localStorage.getItem("token")
      }else{
         token= ''
      }

      axios.defaults.headers={
        "Content-Type":"application/json",
        Authorization:`Token ${token}`
      }

      this.compo_nent_loade()

    }

    //component loaded
    compo_nent_loade=()=>{
     
      axios.get(`${serverconfig.backendserverurl}/api/loanapplications/${this.props.loan_application_id}`)
      .then(res => {  
          console.log(res.data)
          this.setState({application_obj: res.data})
          this.setState({datarequested:false})

          //get periodic balances
          let form_data1 = new FormData();
          form_data1.append('date',res.data.date);
          form_data1.append('member_id', res.data.account);
          axios.post(serverconfig.backendserverurl+`/customqueries/get_runningbalancesbydate`, form_data1, {
          headers: {
            'content-type': 'multipart/form-data'
          }
          })
          .then(res => {
            this.setState({
              memberperiodic_balanceobj:res.data.my_balance
            })

            this.setState({datarequested:false})

          })


       })


    }



  /// generate_collateral_list
  generate_collateral_list=()=>{
    var list=[]
    var item_no=1

    //Add project interest
    if(this.state.application_obj.projectinterestdetails!="{}"){
      var obj=JSON.parse(this.state.application_obj.projectinterestdetails)
      var nu_data={
        "GuarantorType":"Applicant",
        "MemberName":this.state.application_obj.accountName,
        "accountNumber":this.state.application_obj.accountNumber,
        "Security":obj?obj.projectint_proname:'',
        "Amount":obj?obj.projectint_total:0,
        "item_no":item_no,
        "Otherdetails":""

      }
      list.push(nu_data)
      item_no+=1
    }

    //other collaterals
    if(this.state.application_obj.othercollateraldetails!="{}"){
      var obj=JSON.parse(this.state.application_obj.othercollateraldetails)
      var nu_data={
        "GuarantorType":"Applicant",
        "MemberName":this.state.application_obj.accountName,
        "accountNumber":this.state.application_obj.accountNumber,
        "Security":obj?obj.othercol_details:'',
        "Amount":obj?obj.othercol_value:0,
        "item_no":item_no,
        "Otherdetails":""

      }
      list.push(nu_data)
      item_no+=1
    }


    //reapers title
    if(this.state.application_obj.reaperstitledetails!="{}"){
      var obj=JSON.parse(this.state.application_obj.reaperstitledetails)
      var nu_data={
        "GuarantorType":"Applicant",
        "MemberName":this.state.application_obj.accountName,
        "accountNumber":this.state.application_obj.accountNumber,
        "Security":obj?obj.rpt_details:'',
        "Amount":obj?obj.rpt_value:0,
        "item_no":item_no,
        "Otherdetails":""

      }
      list.push(nu_data)
      item_no+=1
    }

    //own title
    if(this.state.application_obj.owntitledetails!="{}"){
      var obj=JSON.parse(this.state.application_obj.owntitledetails)

      if (obj){
        var nu_data={
          "GuarantorType":"Applicant",
          "MemberName":this.state.application_obj.accountName,
          "accountNumber":this.state.application_obj.accountNumber,
          "Security":obj.propertydetails,
          "Amount":obj.ownproperty_value,
          "item_no":item_no,
          "Otherdetails":obj.ownproperty_valuername+" | "+obj.ownproperty_valuerphone+" | "+obj.ownproperty_valuercompany+" | "+obj.ownproperty_valuercompanyaddress
        }
        list.push(nu_data)
        item_no+=1
  
      }

    }

    //collateral set
    if(this.state.application_obj.collateral_set!="{}"){
      var obj=JSON.parse(this.state.application_obj.collateral_set)
      if (obj){

  //check savings
  if (obj.savings===true){
    var nu_data={
      "GuarantorType":"Applicant",
      "MemberName":this.state.application_obj.accountName,
      "accountNumber":this.state.application_obj.accountNumber,
      "Security":"Savings",
      "Amount":Number(this.state.memberperiodic_balanceobj.savings_balance)  ,
      "item_no":item_no,
      "Otherdetails":''
    }
    list.push(nu_data)
    item_no+=1

  }

  //supersave

  //check savings
  if (obj.supersave===true){
    var nu_data={
      "GuarantorType":"Applicant",
      "MemberName":this.state.application_obj.accountName,
      "accountNumber":this.state.application_obj.accountNumber,
      "Security":"SuperSave",
      "Amount":Number(this.state.memberperiodic_balanceobj.supersaver_balance) ,
      "item_no":item_no,
      "Otherdetails":''
    }
    list.push(nu_data)
    item_no+=1

  }


  if (obj.shares===true){
    var nu_data={
      "GuarantorType":"Applicant",
      "MemberName":this.state.application_obj.accountName,
      "accountNumber":this.state.application_obj.accountNumber,
      "Security":"Shares",
      "Amount":Number(this.state.memberperiodic_balanceobj.shareamount_balance),
      "item_no":item_no,
      "Otherdetails":''
    }
    list.push(nu_data)
    item_no+=1

  }



  if (obj.teebagoolo===true){
    var nu_data={
      "GuarantorType":"Applicant",
      "MemberName":this.state.application_obj.accountName,
      "accountNumber":this.state.application_obj.accountNumber,
      "Security":"TeebaGoolo",
      "Amount":Number(this.state.memberperiodic_balanceobj.teebagoolo_balance),
      "item_no":item_no,
      "Otherdetails":''
    }
    list.push(nu_data)
    item_no+=1

  }

      }
    
    

    }

     
    
      //////////////////////
      ////////////////////////
      //now look at the guarantors list
      //////////////////////////
      /////////////////////////
      if (this.state.application_obj.guarantorslist){
        JSON.parse(this.state.application_obj.guarantorslist).map((item)=>{
          //check savings
          if (Number(item.liguidity_items.savings)>0){
            var nu_data={
              "GuarantorType":"Guarantor",
              "MemberName":item.account_name,
              "accountNumber":item.account_no,
              "Security":"Savings",
              "Amount":Number(item.liguidity_items.savings),
              "item_no":item_no,
              "Otherdetails":''
            }
            list.push(nu_data)
            item_no+=1

          }

          //supersave

          //check savings
          if (Number(item.liguidity_items.supersave)>0){
            var nu_data={
              "GuarantorType":"Guarantor",
              "MemberName":item.account_name,
              "accountNumber":item.account_no,
              "Security":"SuperSave",
              "Amount":Number(item.liguidity_items.supersave),
              "item_no":item_no,
              "Otherdetails":''
            }
            list.push(nu_data)
            item_no+=1

          }

          //teeba goolo
          if (Number(item.liguidity_items.teebagoolo)>0){
            var nu_data={
              "GuarantorType":"Guarantor",
              "MemberName":item.account_name,
              "accountNumber":item.account_no,
              "Security":"TeebaGoolo",
              "Amount":Number(item.liguidity_items.teebagoolo),
              "item_no":item_no,
              "Otherdetails":''
            }
            list.push(nu_data)
            item_no+=1

          }

          //shares
          if (Number(item.liguidity_items.shares)>0){
            var nu_data={
              "GuarantorType":"Applicant",
              "MemberName":item.account_name,
              "accountNumber":item.account_no,
              "Security":"Shares",
              "Amount":Number(item.liguidity_items.shares),
              "item_no":item_no,
              "Otherdetails":''
            }
            list.push(nu_data)
            item_no+=1

          }

          /////////////////////////////////
          ///non liquid collaterals 
          ///////////////////////////////
          /////////////////////////////////////

          //Add project interest
          if(JSON.stringify(item.projectinterestdetails_guar)!="{}"){
            var obj=item.projectinterestdetails_guar
            var nu_data={
              "GuarantorType":"Guarantor",
              "MemberName":item.account_name,
              "accountNumber":item.account_no,
              "Security":obj.projectint_proname,
              "Amount":obj.projectint_total,
              "item_no":item_no,
              "Otherdetails":""

            }
            list.push(nu_data)
            item_no+=1
          }

          //other collaterals
          if(JSON.stringify(item.othercollateraldetails_guar)!="{}"){
            var obj=item.othercollateraldetails_guar
            var nu_data={
              "GuarantorType":"Guarantor",
              "MemberName":item.account_name,
              "accountNumber":item.account_no,
              "Security":obj.othercol_details,
              "Amount":obj.othercol_value,
              "item_no":item_no,
              "Otherdetails":""

            }
            list.push(nu_data)
            item_no+=1
          }


          //reapers title
          if(JSON.stringify(item.reaperstitledetails_guar)!="{}"){
            var obj=item.reaperstitledetails_guar
            var nu_data={
              "GuarantorType":"Guarantor",
              "MemberName":item.account_name,
              "accountNumber":item.account_no,
              "Security":obj.rpt_details,
              "Amount":obj.rpt_value,
              "item_no":item_no,
              "Otherdetails":""

            }
            list.push(nu_data)
            item_no+=1
          }

          //own title
          if(JSON.stringify(item.own_titledetails_guar)!="{}"){
            var obj=item.own_titledetails_guar
            var nu_data={
              "GuarantorType":"Guarantor",
              "MemberName":item.account_name,
              "accountNumber":item.account_no,
              "Security":obj.propertydetails,
              "Amount":obj.ownproperty_value,
              "item_no":item_no,
              "Otherdetails":obj.ownproperty_valuername+" | "+obj.ownproperty_valuerphone+" | "+obj.ownproperty_valuercompany+" | "+obj.ownproperty_valuercompanyaddress
            }
            list.push(nu_data)
            item_no+=1
          }

      })

      }

      return list
  }


  total_ofsecurities=()=>{
    var total=0
    this.generate_collateral_list().map((item)=>{
      total+=Number(item.Amount)
    })

    return total.toFixed(2)

  }



    

    render(){

      if(this.state.datarequested===true){
        return(
          <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin  size="large">
                <div className="content" />
              </Spin>      
         </div>
        )
  
      }else{
        return(
          <div>
            <Card>

              <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',flexWrap:'wrap'}}>
                
                {/**Code */}
                <span style={{display:'flex',flexDirection:'row',justifyContent:'space-between',width:'30%',flexWrap:'wrap'}}>
                  <span style={{fontSize:'16',color:'grey',margin:3}}>Account Name :</span>
                  <span style={{fontSize:'16',color:'black',margin:3}}>{this.state.application_obj.accountName}</span>
                </span>


                {/**Code */}
                <span style={{display:'flex',flexDirection:'row',justifyContent:'space-between',width:'30%',flexWrap:'wrap'}}>
                  <span style={{fontSize:'16',color:'grey',margin:3}}>Account Number :</span>
                  <span style={{fontSize:'16',color:'black',margin:3}}>{this.state.application_obj.accountNumber}</span>

                </span>

              </div>

            <Divider></Divider>

              <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',flexWrap:'wrap',flexWrap:'wrap'}}>
     
              {/**Code */}
              <span style={{display:'flex',flexDirection:'row',justifyContent:'space-between',width:'30%'}}>
                <span style={{fontSize:'16',color:'grey',margin:3}}>Loan Opening Balance :</span>
                <span style={{fontSize:'16',color:'black',margin:3}}>
                  <CurrencyFormat value={Number(this.state.application_obj.topup_loanbalance).toFixed(2)} displayType={'text'} thousandSeparator={true}  />
                  </span>
              </span>


              {/**Code */}
              <span style={{display:'flex',flexDirection:'row',justifyContent:'space-between',width:'30%',flexWrap:'wrap'}}>
                <span style={{fontSize:'16',color:'grey',margin:3}}>Amount Requested :</span>
                <span style={{fontSize:'16',color:'black',margin:3}}>
                <CurrencyFormat 
                value={Number(this.state.application_obj.loan_topupamount)>0?Number(this.state.application_obj.loan_topupamount).toFixed(2):Number(this.state.application_obj.loan_amount).toFixed(2)} 
                displayType={'text'} thousandSeparator={true}/>
                </span>
              </span>


               {/**Code */}
               <span style={{display:'flex',flexDirection:'row',justifyContent:'space-between',width:'30%',flexWrap:'wrap'}}>
                <span style={{fontSize:'16',color:'grey',margin:3}}>Total Loan Amount :</span>
                <span style={{fontSize:'16',color:'black',margin:3}}>
                  <CurrencyFormat value={Number(this.state.application_obj.loan_amount).toFixed(2)} displayType={'text'} thousandSeparator={true}  />
                </span>
              </span>


              {/**Code */}
              <span style={{display:'flex',flexDirection:'row',justifyContent:'space-between',width:'30%',flexWrap:'wrap'}}>
                <span style={{fontSize:'16',color:'grey',margin:3}}>Total Security :</span>
                <span style={{fontSize:'16',color:'black',margin:3}}>
                  <CurrencyFormat value={this.total_ofsecurities()} displayType={'text'} thousandSeparator={true}  />
                </span>
              </span>



              {/**Code */}
              <span style={{display:'flex',flexDirection:'row',justifyContent:'space-between',width:'30%',flexWrap:'wrap'}}>
                <span style={{fontSize:'16',color:'grey',margin:3}}>Application Limit :</span>
                <span style={{fontSize:'16',color:'black',margin:3}}>
                  <CurrencyFormat value={Number(this.state.application_obj.application_loan_limit).toFixed(2)} displayType={'text'} thousandSeparator={true}  />
                </span>
              </span>


                {/**Code */}
                <span style={{display:'flex',flexDirection:'row',justifyContent:'space-between',width:'30%',flexWrap:'wrap'}}>
                <span style={{fontSize:'16',color:'grey',margin:3}}>Requested Period :</span>
                <span style={{fontSize:'16',color:'black',margin:3}}>
                  {this.state.application_obj.approved_period} &nbsp;
                  {this.state.application_obj.loan_primary_schedule==="daily"?"Days":this.state.application_obj.loan_primary_schedule==="weekly"?"Weeks":"Months"}
                </span>
              </span>

              </div>

              <Divider></Divider>

              <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',flexWrap:'wrap'}}>
              
              {/**Code */}
              <span style={{display:'flex',flexDirection:'row',justifyContent:'space-between',width:'30%',flexWrap:'wrap'}}>
                <span style={{fontSize:'16',color:'grey',margin:3}}>Loan Type:</span>
                <span style={{fontSize:'16',color:'black',margin:3}}>{this.state.application_obj.loan_type_name}</span>
              </span>
              

              {/**Jurisdiction */}
              <span style={{display:'flex',flexDirection:'row',justifyContent:'space-between',width:'30%',flexWrap:'wrap'}}>
                <span style={{fontSize:'16',color:'grey',margin:3}}>Date of Application :</span>
                <span style={{fontSize:'16',color:'black',margin:3}}>{this.state.application_obj.date}</span>
              </span>

              {/**Application date */}
              <span style={{display:'flex',flexDirection:'row',justifyContent:'space-between',width:'30%',flexWrap:'wrap'}}>
                <span style={{fontSize:'16',color:'grey',margin:3}}>Loan Purpose:</span>
                <span style={{fontSize:'16',color:'black',margin:3}}>{this.state.application_obj.loan_purpose}</span>
              </span>

                {/**Name */}
                <span style={{display:'flex',flexDirection:'row',justifyContent:'space-between',width:'30%',flexWrap:'wrap'}}>
                <span style={{fontSize:'16',color:'grey',margin:3}}>Business Type:</span>
                <span style={{fontSize:'16',color:'black',margin:3}}>{this.state.application_obj.business_type}</span>
              </span>

              {/**Type */}
              <span style={{display:'flex',flexDirection:'row',justifyContent:'space-between',width:'30%',flexWrap:'wrap'}}>
                <span style={{fontSize:'16',color:'grey',margin:3}}>Applicant Location:</span>
                <span style={{fontSize:'16',color:'black',margin:3}}>{this.state.application_obj.applicant_location}</span>
              </span>

              {/**Type */}
              <span style={{display:'flex',flexDirection:'row',justifyContent:'space-between',width:'30%',flexWrap:'wrap'}}>
                <span style={{fontSize:'16',color:'grey',margin:3}}>Own promises?</span>
                <span style={{fontSize:'16',color:'black',margin:3}}>{this.state.application_obj.own_premises===true?"Yes":"No"}</span>
              </span>


              <span style={{display:'flex',flexDirection:'row',justifyContent:'space-between',width:'30%',flexWrap:'wrap'}}>
                <span style={{fontSize:'16',color:'grey',margin:3}}>Business legal Status:</span>
                <span style={{fontSize:'16',color:'black',margin:3}}>{this.state.application_obj.legal_status_of_business}</span>
              </span>


              <span style={{display:'flex',flexDirection:'row',justifyContent:'space-between',width:'30%',flexWrap:'wrap'}}>
                <span style={{fontSize:'16',color:'grey',margin:3}}>Duration in Business:</span>
                <span style={{fontSize:'16',color:'black',margin:3}}>{this.state.application_obj.duration_in_business}</span>
              </span>
              
              </div>
             
            </Card>
          </div>
      )
      }
       
    }
}

export default LoanApplicationDetails; 