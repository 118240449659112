import React from 'react';
import axios from 'axios'
import { Table, Input, Button,Popconfirm,Popover,
  Collapse,Avatar,Drawer,Descriptions,message,Modal,Spin,Switch,
  Divider} from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,FundOutlined,FundViewOutlined,DeleteOutlined,LockFilled,LockOutlined,LoadingOutlined } from '@ant-design/icons';
import { UserOutlined } from '@ant-design/icons';
import { Form,Select,Checkbox,Upload } from 'antd';
import { Link } from 'react-router-dom';
import * as serverconfig from '../serverconn'
import UserCreationForm from '../components/UserCreationForm'

var CryptoJS = require("crypto-js");
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

const { Panel } = Collapse;
const FormItem=Form.Item;

function callback(key) {
  console.log(key);
}

message.config({
  top: 200,
  duration: 2,
  maxCount: 3,
  rtl: true,
});



var sacco= ''
var username=''
var token= ''

class AccountList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    accounts: [],
    rightsmodalvisible:false,
    rights: {},
    selectedid:'',
    datarequested:true,

    can_deposit:false,
    can_withdraw:false,
    can_register_members:false,
    can_view_members:false,
    can_register_loans:false,
    can_assess_loans:false,
    can_approve_loans:false,
    can_issue_loans:false,
    can_enter_loan_repayment:false,
    can_enter_expenses:false,
    can_view_expenses:false,
    can_enter_incomes:false,
    can_view_incomes:false,
    can_enter_staff:false,
    can_view_staff:false,
    can_enter_assets:false,
    can_view_assets:false,
    viewtill:false,
    viewdaysheet:false,
    viewoutstandingloanreport:false,
    sendmessages:false,
    editaccounts:false,
    accountactivation:false,
    loandailyreport:false,
    outstandingloans:false,
    loanrepaymentstatus:false,
    loandefaultsreport:false,
    loanagingreport:false,
    loandisburshmentreport:false,
    viewdailyloanrepayments:false,
    searchloanrepayments:false,
    viewloanpaymentqueues:false, 
    can_approvemembers:false,


    can_opensupersave:false,
    can_openteebagoolo:false,
    can_makesingleaccounttransfer:false,
    can_award_osinterest:false,
    can_award_ssinterest:false,
    can_award_tbinterest:false,
    can_dobatchinteresttransfer:false,
    can_clear_members_from_preview:false,

    can_reverse_membership_approval:false,
    can_reverse_loan_process:false,

    can_view_withdraw_requistions:false,
    can_initiate_withdraw:false,
    can_review_withdraw:false,
    can_approve_withdraw:false,

    can_do_orginteraccount_transfers:false,
    can_make_journalentries:false,
    can_make_quick_transactions:false,
    can_make_transaction_reversals:false,

    //revised rights
    can_view_loanapplications:false,

    can_review_loans:false,
    can_view_loanreviews:false,

    can_view_loanapprovals:false,
    can_view_rejected_loanapplications:false,
    can_view_loandisbursements:false,

    can_verify_loans:false,
    can_view_concluded_applications:false,
    can_view_verified_applications:false



  };


  displaypasswordupdatemodal=(id)=>{
    this.setState({showpasswordupdatemodal:true})
    this.setState({selectedid:id})
  }
  
  closepasswordupdatemodal=()=>{
    this.setState({showpasswordupdatemodal:false})
  }
  
  //////////////////////////////////////
  ///// change password here
  handlePasswordChange=(values)=>{
    this.setState({datarequested:true})

    let form_data = new FormData();
    form_data.append('password1', values.new_password1);
    form_data.append('password2', values.new_password2);
    form_data.append('userid', this.state.selectedid);
  
    //Now send the post resquest
      axios.post(serverconfig.backendserverurl+'/customqueries/updatecompanyuserpassword', form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res => {
      this.setState({datarequested:false})

      message.info(res.data.message);
    }
    )
    .catch(error => console.log(error)) 
  
  }




  ///////////////////////////////////////////
  //getting simple user account rights
  showAccountRights=(text) =>{
    this.setState({ rightsmodalvisible: true });

    //make api call to get the user rights
    axios.get(`${serverconfig.backendserverurl}/api/accountrights?user=${text}`)
    .then(res => {  
        this.setState({
            rights: res.data[0]
        })
        this.setState({can_deposit:res.data[0].can_deposit})
        this.setState({can_withdraw:res.data[0].can_withdraw})
        this.setState({can_register_members:res.data[0].can_register_members})
        this.setState({can_view_members:res.data[0].can_view_members})
        this.setState({can_register_loans:res.data[0].can_register_loans})
        this.setState({can_assess_loans:res.data[0].can_assess_loans})
        this.setState({can_approve_loans:res.data[0].can_approve_loans})
        this.setState({can_issue_loans:res.data[0].can_issue_loans})
        this.setState({can_enter_loan_repayment:res.data[0].can_enter_loan_repayment})
        this.setState({can_enter_expenses:res.data[0].can_enter_expenses})
        this.setState({can_view_expenses:res.data[0].can_view_expenses})
        this.setState({can_enter_incomes:res.data[0].can_enter_incomes})
        this.setState({can_view_incomes:res.data[0].can_view_incomes})
        this.setState({can_enter_staff:res.data[0].can_enter_staff})
        this.setState({can_view_staff:res.data[0].can_view_staff})
        this.setState({can_enter_assets:res.data[0].can_enter_assets})
        this.setState({can_view_assets:res.data[0].can_view_assets})
        this.setState({viewtill:res.data[0].viewtill})
        this.setState({viewdaysheet:res.data[0].viewdaysheet})
        this.setState({viewoutstandingloanreport:res.data[0].viewoutstandingloanreport})
        this.setState({sendmessages:res.data[0].sendmessages})       
        this.setState({editaccounts:res.data[0].editaccounts})
        this.setState({accountactivation:res.data[0].accountactivation})
        this.setState({loandailyreport:res.data[0].loandailyreport})
        this.setState({outstandingloans:res.data[0].outstandingloans})
        this.setState({loanrepaymentstatus:res.data[0].loanrepaymentstatus})
        this.setState({loandefaultsreport:res.data[0].loandefaultsreport})
        this.setState({loanagingreport:res.data[0].loanagingreport})
        this.setState({loandisburshmentreport:res.data[0].loandisburshmentreport})
        this.setState({viewdailyloanrepayments:res.data[0].viewdailyloanrepayments})
        this.setState({searchloanrepayments:res.data[0].searchloanrepayments})
        this.setState({viewloanpaymentqueues:res.data[0].viewloanpaymentqueues})
        this.setState({can_approvemembers:res.data[0].can_approvemembers})
   
        this.setState({can_opensupersave:res.data[0].can_opensupersave})
        this.setState({can_openteebagoolo:res.data[0].can_openteebagoolo})
        this.setState({can_makesingleaccounttransfer:res.data[0].can_makesingleaccounttransfer})
        this.setState({can_award_osinterest:res.data[0].can_award_osinterest})
        this.setState({can_award_ssinterest:res.data[0].can_award_ssinterest})
        this.setState({can_award_tbinterest:res.data[0].can_award_tbinterest})
        this.setState({can_dobatchinteresttransfer:res.data[0].can_dobatchinteresttransfer})
        this.setState({can_clear_members_from_preview:res.data[0].can_clear_members_from_preview})

        this.setState({can_view_withdraw_requistions:res.data[0].can_view_withdraw_requistions})
        this.setState({can_initiate_withdraw:res.data[0].can_initiate_withdraw})
        this.setState({can_review_withdraw:res.data[0].can_review_withdraw})
        this.setState({can_approve_withdraw:res.data[0].can_approve_withdraw})

        this.setState({can_reverse_membership_approval:res.data[0].can_reverse_membership_approval})
        this.setState({can_reverse_loan_process:res.data[0].can_reverse_loan_process})

        this.setState({can_do_orginteraccount_transfers:res.data[0].can_do_orginteraccount_transfers})
        this.setState({can_make_journalentries:res.data[0].can_make_journalentries})
        this.setState({can_make_quick_transactions:res.data[0].can_make_quick_transactions})

        
        this.setState({can_make_transaction_reversals:res.data[0].can_make_transaction_reversals})

        //revised rights
        this.setState({can_view_loanapplications:res.data[0].can_view_loanapplications})
        
        
        this.setState({can_review_loans:res.data[0].can_review_loans})
        this.setState({can_view_loanreviews:res.data[0].can_view_loanreviews})
        this.setState({can_view_loanapprovals:res.data[0].can_view_loanapprovals})
        this.setState({can_view_rejected_loanapplications:res.data[0].can_view_rejected_loanapplications})
        this.setState({can_view_loandisbursements:res.data[0].can_view_loandisbursements})


        this.setState({can_verify_loans:res.data[0].can_verify_loans})
        this.setState({can_view_concluded_applications:res.data[0].can_view_concluded_applications})
        this.setState({can_view_verified_applications:res.data[0].can_view_verified_applications})




        


    })

  }

  //////////////////////////////////////////////////
  //handle delete here
  handleDelete = id => {

    let form_data1 = new FormData();
    form_data1.append('itemid', id);
    form_data1.append('sacco', sacco);
    form_data1.append('table_model', "UserAccount");
  
    axios.post(`${serverconfig.backendserverurl}/customqueries/puttorecyclebin`, form_data1, {
      headers: {
        'content-type': 'multipart/form-data'
      }
      }).then(res =>{
        window.location.reload(false)
        message.info('Operation successful')
      }
      );


  };


  //Item addition success
  onresetdrawerClose = () => {
    this.setState({ rightsmodalvisible: false });
  };


  //make an update now on the user rights
  updaterights=values=>{
    let form_data = new FormData();
    form_data.append('can_deposit', this.state.can_deposit)
    form_data.append('can_withdraw', this.state.can_withdraw)
    form_data.append('can_register_members', this.state.can_register_members)
    form_data.append('can_view_members', this.state.can_view_members)
    form_data.append('can_register_loans', this.state.can_register_loans)
    form_data.append('can_assess_loans', this.state.can_assess_loans)
    form_data.append('can_approve_loans', this.state.can_approve_loans)
    form_data.append('can_issue_loans', this.state.can_issue_loans)
    form_data.append('can_enter_loan_repayment', this.state.can_enter_loan_repayment)
    form_data.append('can_enter_expenses', this.state.can_enter_expenses)
    form_data.append('can_view_expenses', this.state.can_view_expenses)
    form_data.append('can_enter_incomes', this.state.can_enter_incomes)
    form_data.append('can_view_incomes', this.state.can_view_incomes)
    form_data.append('can_enter_staff', this.state.can_enter_staff)
    form_data.append('can_view_staff', this.state.can_view_staff)
    form_data.append('can_enter_assets', this.state.can_enter_assets)
    form_data.append('can_view_assets', this.state.can_view_assets)
    form_data.append('viewtill', this.state.viewtill)
    form_data.append('viewdaysheet', this.state.viewdaysheet)
    form_data.append('viewoutstandingloanreport', this.state.viewoutstandingloanreport)
    form_data.append('sendmessages', this.state.sendmessages)
    form_data.append('editaccounts', this.state.editaccounts)
    form_data.append('accountactivation', this.state.accountactivation)
    form_data.append('loandailyreport', this.state.loandailyreport)
    form_data.append('outstandingloans', this.state.outstandingloans)
    form_data.append('loanrepaymentstatus', this.state.loanrepaymentstatus)
    form_data.append('loandefaultsreport', this.state.loandefaultsreport)
    form_data.append('loanagingreport', this.state.loanagingreport)
    form_data.append('loandisburshmentreport', this.state.loandisburshmentreport)
    form_data.append('viewdailyloanrepayments', this.state.viewdailyloanrepayments)
    form_data.append('searchloanrepayments', this.state.searchloanrepayments)
    form_data.append('viewloanpaymentqueues', this.state.viewloanpaymentqueues)
    form_data.append('can_approvemembers', this.state.can_approvemembers)

    form_data.append('can_opensupersave', this.state.can_opensupersave)
    form_data.append('can_openteebagoolo', this.state.can_openteebagoolo)
    form_data.append('can_makesingleaccounttransfer', this.state.can_makesingleaccounttransfer)
    form_data.append('can_award_osinterest', this.state.can_award_osinterest)
    form_data.append('can_award_ssinterest', this.state.can_award_ssinterest)
    form_data.append('can_award_tbinterest', this.state.can_award_tbinterest)
    form_data.append('can_dobatchinteresttransfer', this.state.can_dobatchinteresttransfer)
    form_data.append('can_clear_members_from_preview', this.state.can_clear_members_from_preview)

    form_data.append('can_reverse_membership_approval', this.state.can_reverse_membership_approval)
    form_data.append('can_reverse_loan_process', this.state.can_reverse_loan_process)

    form_data.append('can_view_withdraw_requistions', this.state.can_view_withdraw_requistions)
    form_data.append('can_initiate_withdraw', this.state.can_initiate_withdraw)
    form_data.append('can_review_withdraw', this.state.can_review_withdraw)
    form_data.append('can_approve_withdraw', this.state.can_approve_withdraw)
    form_data.append('can_do_orginteraccount_transfers', this.state.can_do_orginteraccount_transfers)
    form_data.append('can_make_journalentries', this.state.can_make_journalentries)

    form_data.append('can_make_quick_transactions', this.state.can_make_quick_transactions)
    form_data.append('can_make_transaction_reversals', this.state.can_make_transaction_reversals)

    //revised roghts

    form_data.append('can_view_loanapplications', this.state.can_view_loanapplications)

    form_data.append('can_review_loans', this.state.can_review_loans)
    form_data.append('can_view_loanreviews', this.state.can_view_loanreviews)
    form_data.append('can_view_loanapprovals', this.state.can_view_loanapprovals)
    form_data.append('can_view_rejected_loanapplications', this.state.can_view_rejected_loanapplications)
    form_data.append('can_view_loandisbursements', this.state.can_view_loandisbursements)

    form_data.append('can_verify_loans', this.state.can_verify_loans)
    form_data.append('can_view_concluded_applications', this.state.can_view_concluded_applications)
    form_data.append('can_view_verified_applications', this.state.can_view_verified_applications)



    
    
    //Now send the post resquest
    axios.put(`${serverconfig.backendserverurl}/api/accountrights/${this.state.rights.id}/`, form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res => window.location.reload(false)
    )
    .catch(error => console.log(error))

  }


  componentDidMount(){

    if(localStorage.getItem("sacco")){
      sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    
    }else{
       sacco= ''
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }


    axios.get(serverconfig.backendserverurl+`/api/accounts/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          accounts:res.data
        })

        this.setState({datarequested:false})

    })

}

handlecan_depositchange=(value)=>{
  //alert(this.state.usesms)
  this.setState({can_deposit:!this.state.can_deposit})
}

handlecan_withdrawchange=(value)=>{
  //alert(this.state.usesms)
  this.setState({can_withdraw:!this.state.can_withdraw})
}

handlecan_register_memberschange=(value)=>{
  //alert(this.state.usesms)
  this.setState({can_register_members:!this.state.can_register_members})
}

handlecan_view_memberschange=(value)=>{
  //alert(this.state.usesms)
  this.setState({can_view_members:!this.state.can_view_members})
}    
handlecan_register_loanschange=(value)=>{
  //alert(this.state.usesms)
  this.setState({can_register_loans:!this.state.can_register_loans})
}    
handlecan_assess_loanschange=(value)=>{
  //alert(this.state.usesms)
  this.setState({can_assess_loans:!this.state.can_assess_loans})
}  
  handlecan_approve_loanschange=(value)=>{
  //alert(this.state.usesms)
  this.setState({can_approve_loans:!this.state.can_approve_loans})
}  

handlecan_issue_loanschange=(value)=>{
  //alert(this.state.usesms)
  this.setState({can_issue_loans:!this.state.can_issue_loans})
}

handlecan_enter_loan_repaymentchange=(value)=>{
  //alert(this.state.usesms)
  this.setState({can_enter_loan_repayment:!this.state.can_enter_loan_repayment})
}
handlecan_enter_expenseschange=(value)=>{
  //alert(this.state.usesms)
  this.setState({can_enter_expenses:!this.state.can_enter_expenses})
}
handlecan_view_expenseschange=(value)=>{
  //alert(this.state.usesms)
  this.setState({can_view_expenses:!this.state.can_view_expenses})
}
handlecan_enter_incomeschange=(value)=>{
  //alert(this.state.usesms)
  this.setState({can_enter_incomes:!this.state.can_enter_incomes})
}
handlecan_view_incomeschange=(value)=>{
  //alert(this.state.usesms)
  this.setState({can_view_incomes:!this.state.can_view_incomes})
}
handlecan_enter_staffchange=(value)=>{
  //alert(this.state.usesms)
  this.setState({can_enter_staff:!this.state.can_enter_staff})
}
handlecan_view_staffchange=(value)=>{
  //alert(this.state.usesms)
  this.setState({can_view_staff:!this.state.can_view_staff})
}
handlecan_enter_assetschange=(value)=>{
  //alert(this.state.usesms)
  this.setState({can_enter_assets:!this.state.can_enter_assets})
}
handlecan_view_assetschange=(value)=>{
  //alert(this.state.usesms)
  this.setState({can_view_assets:!this.state.can_view_assets})
}
handleviewtillchange=(value)=>{
  //alert(this.state.usesms)
  this.setState({viewtill:!this.state.viewtill})
}
handleviewdaysheetchange=(value)=>{
  //alert(this.state.usesms)
  this.setState({viewdaysheet:!this.state.viewdaysheet})
}
handleviewoutstandingloanreportchange=(value)=>{
  //alert(this.state.usesms)
  this.setState({viewoutstandingloanreport:!this.state.viewoutstandingloanreport})
}
handlesendmessageschange=(value)=>{
  //alert(this.state.usesms)
  this.setState({sendmessages:!this.state.sendmessages})
}
handleeditaccountschange=(value)=>{
  //alert(this.state.usesms)
  this.setState({editaccounts:!this.state.editaccounts})
}
handleaccountactivationchange=(value)=>{
  //alert(this.state.usesms)
  this.setState({accountactivation:!this.state.accountactivation})
}

handleloandailyreportchange=(value)=>{
  //alert(this.state.usesms)
  this.setState({loandailyreport:!this.state.loandailyreport})
}

handleoutstandingloanschange=(value)=>{
  //alert(this.state.usesms)
  this.setState({outstandingloans:!this.state.outstandingloans})
}
handleloanrepaymentstatuschange=(value)=>{
  //alert(this.state.usesms)
  this.setState({loanrepaymentstatus:!this.state.loanrepaymentstatus})
}

handleloandefaultsreportchange=(value)=>{
  //alert(this.state.usesms)
  this.setState({loandefaultsreport:!this.state.loandefaultsreport})
}

handleloanagingreportchange=(value)=>{
  //alert(this.state.usesms)
  this.setState({loanagingreport:!this.state.loanagingreport})
}

handleloandisburshmentreportchange=(value)=>{
  //alert(this.state.usesms)
  this.setState({loandisburshmentreport:!this.state.loandisburshmentreport})
}



handleviewdailyloanrepaymentschange=(value)=>{
  //alert(this.state.usesms)
  this.setState({viewdailyloanrepayments:!this.state.viewdailyloanrepayments})
}

handlesearchloanrepaymentschange=(value)=>{
  //alert(this.state.usesms)
  this.setState({searchloanrepayments:!this.state.searchloanrepayments})
}

handleviewloanpaymentqueueschange=(value)=>{
  //alert(this.state.usesms)
  this.setState({viewloanpaymentqueues:!this.state.viewloanpaymentqueues})
}


  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  render() {
    const columns = [
      {
        title: 'Profile',
        dataIndex: 'profile_pic',
        key: 'profile_pic',
        render: text => <Avatar
        size={60}
        icon={<UserOutlined />}
        alt="User Pic"
        src={text}
        />,

      },
      {
        title: 'User Name',
        dataIndex: 'username',
        key: 'username',
        ...this.getColumnSearchProps('username'),
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Actions',
        dataIndex: 'id',
        key: 'id',
        render: text =>
        <p>
        <Popover content={<p>Click here to edit,view details</p>} title="Edit,View">
         <Link to={`/useraccounts/${text}`}><FundViewOutlined style={{color:'blue'}}/></Link>
        </Popover>
       
        &nbsp;&nbsp;&nbsp;
        <Popover content={<p>Click here to edit,view user rights</p>} title="Edit,View rights">
         <UserOutlined onClick={(event) => this.showAccountRights(text)} style={{color:'blue'}}/>
        </Popover>
        &nbsp;&nbsp;&nbsp;
        <Popover content={<p>Click to change password</p>} title="Password Change">
          <LockFilled style={{color:'#154360'}} onClick={() => this.displaypasswordupdatemodal(text)}/>
        </Popover>
        </p>,
      },

    ];

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{

      return(
        <div>
           <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header="ALL ACCOUNTS" key="1">
            <Table 
             scroll={{ x: 1000 }}
             columns={columns}
             pagination={{showQuickJumper:true,showSizeChanger:true }}
             dataSource={this.state.accounts} bordered/>

            <br></br>
            <UserCreationForm />


            </Panel>
            </Collapse>



            <Drawer
                title={this.state.rights.username+" "+"Account Rights"}
                width={520}
                placement="right"
                closable={false}
                onClose={this.onresetdrawerClose}
                visible={this.state.rightsmodalvisible}
              >

                    <h3 style={{color:'red'}}>Edit User Rights</h3>

                    <Form  
                      onFinish={this.updaterights}        
                      >
                      <FormItem name="can_deposit" label="Can Make Deposit" >
                          <Switch
                              checked={this.state.can_deposit}
                              onChange={val=>{this.handlecan_depositchange(val)}}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                          
                      </FormItem>  

                      <FormItem name="can_withdraw" label="Can Make Withdraw" >
                          <Switch
                              checked={this.state.can_withdraw}
                              onChange={val=>{this.handlecan_withdrawchange(val)}}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                          
                      </FormItem>                        

                      <FormItem name="can_register_members" label="Can Register Members" >
                          <Switch
                              checked={this.state.can_register_members}
                              onChange={val=>{this.handlecan_register_memberschange(val)}}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                          
                      </FormItem>                           

                      <FormItem name="can_view_members" label="Can View Members" >
                          <Switch
                              checked={this.state.can_view_members}
                              onChange={val=>{this.handlecan_view_memberschange(val)}}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                          
                      </FormItem>  
                      <Divider></Divider>


                      <FormItem name="can_register_loans" label="Can Register Loans" >
                          <Switch
                              checked={this.state.can_register_loans}
                              onChange={val=>{this.handlecan_register_loanschange(val)}}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                          
                      </FormItem>  

                      <FormItem name="can_view_loanapplications" label="Can view Loan Applications" >
                          <Switch
                              checked={this.state.can_view_loanapplications}
                              onChange={()=>{
                                this.setState({can_view_loanapplications:!this.state.can_view_loanapplications})
                              }}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                          
                      </FormItem>  


                      <FormItem name="can_view_concluded_applications" label="Can view Concluded Applications" >
                         
                          <Switch
                              checked={this.state.can_view_concluded_applications}
                              onChange={()=>{
                                this.setState({can_view_concluded_applications:!this.state.can_view_concluded_applications})
                              }}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                          
                      </FormItem>  


                      <FormItem name="can_verify_loans" label="Can verify application" >
                         
                         <Switch
                             checked={this.state.can_verify_loans}
                             onChange={()=>{
                               this.setState({can_verify_loans:!this.state.can_verify_loans})
                             }}
                             checkedChildren="YES"
                             unCheckedChildren="NO"
                             />
                         
                     </FormItem> 



                     
                     <FormItem name="can_view_verified_applications" label="Can View Verified Applications" >
                          <Switch
                              checked={this.state.can_view_verified_applications}
                              onChange={()=>{
                                this.setState({can_view_verified_applications:!this.state.can_view_verified_applications})
                              }}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                          
                      </FormItem>  



                      <FormItem name="can_review_loans" label="Can Review Loan Applications" >
                          <Switch
                              checked={this.state.can_review_loans}
                              onChange={()=>{
                                this.setState({can_review_loans:!this.state.can_review_loans})
                              }}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                          
                      </FormItem>  



                      <FormItem name="can_view_loanreviews" label="Can View Reviewed Loans" >
                          <Switch
                              checked={this.state.can_view_loanreviews}
                              onChange={()=>{
                                this.setState({can_view_loanreviews:!this.state.can_view_loanreviews})
                              }}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                          
                      </FormItem> 

                      <FormItem name="can_approve_loans" label="Can do final Loan Approval" >
                          <Switch
                              checked={this.state.can_approve_loans}
                              onChange={val=>{this.handlecan_approve_loanschange(val)}}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                          
                      </FormItem>


                      <FormItem name="can_view_loanapprovals" label="Can Vew Approved Loans" >
                          <Switch
                              checked={this.state.can_view_loanapprovals}
                              onChange={
                                ()=>{
                                this.setState({can_view_loanapprovals:!this.state.can_view_loanapprovals})
                                }
                              }
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                      </FormItem>


                      <FormItem name="can_issue_loans" label="Can Disburse Loans" >
                          <Switch
                              checked={this.state.can_issue_loans}
                              onChange={val=>{this.handlecan_issue_loanschange(val)}}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                          
                      </FormItem>


                      <FormItem name="can_view_loandisbursements" label="Can View Loan Disbursements" >
                          <Switch
                              checked={this.state.can_view_loandisbursements}
                              onChange={
                                ()=>{
                                this.setState({can_view_loandisbursements:!this.state.can_view_loandisbursements})
                                }
                              }
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                      </FormItem>


                      <FormItem name="can_view_rejected_loanapplications" label="Can View Rejected Loan Applications" >
                          <Switch
                              checked={this.state.can_view_rejected_loanapplications}
                              onChange={
                                ()=>{
                                this.setState({can_view_rejected_loanapplications:!this.state.can_view_rejected_loanapplications})
                                }
                              }
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                      </FormItem>



                      <FormItem name="can_enter_loan_repayment" label="Can Enter Loan Repayment" >
                          <Switch
                              checked={this.state.can_enter_loan_repayment}
                              onChange={val=>{this.handlecan_enter_loan_repaymentchange(val)}}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                          
                      </FormItem>

                      <Divider></Divider>


                      <FormItem name="can_enter_expenses" label="Can Enter Expenses" >
                          <Switch
                              checked={this.state.can_enter_expenses}
                              onChange={val=>{this.handlecan_enter_expenseschange(val)}}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                          
                      </FormItem>


                      <FormItem name="can_view_expenses" label="Can View Expenses" >
                          <Switch
                              checked={this.state.can_view_expenses}
                              onChange={val=>{this.handlecan_view_expenseschange(val)}}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                          
                      </FormItem>

                      <FormItem name="can_enter_incomes" label="Can Enter Incomes" >
                          <Switch
                              checked={this.state.can_enter_incomes}
                              onChange={val=>{this.handlecan_enter_incomeschange(val)}}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                          
                      </FormItem>

                      <FormItem name="can_view_incomes" label="Can View Incomes" >
                          <Switch
                              checked={this.state.can_view_incomes}
                              onChange={val=>{this.handlecan_view_incomeschange(val)}}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                          
                      </FormItem>

                      <FormItem name="can_enter_staff" label="Can Enter Staff" >
                          <Switch
                              checked={this.state.can_enter_staff}
                              onChange={val=>{this.handlecan_enter_staffchange(val)}}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                          
                      </FormItem>

                      <FormItem name="can_view_staff" label="Can View Staff" >
                          <Switch
                              checked={this.state.can_view_staff}
                              onChange={val=>{this.handlecan_view_staffchange(val)}}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                          
                      </FormItem>

                      <FormItem name="can_enter_assets" label="Can Enter Assets" >
                          <Switch
                              checked={this.state.can_enter_assets}
                              onChange={val=>{this.handlecan_enter_assetschange(val)}}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                          
                      </FormItem>

                      <FormItem name="can_view_assets" label="Can View Assets" >
                          <Switch
                              checked={this.state.can_view_assets}
                              onChange={val=>{this.handlecan_view_assetschange(val)}}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                          
                      </FormItem>

                      <FormItem name="viewtill" label="Can View Till" >
                          <Switch
                              checked={this.state.viewtill}
                              onChange={val=>{this.handleviewtillchange(val)}}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                          
                      </FormItem>

                      <FormItem name="viewdaysheet" label="Can View Account Transactions" >
                          <Switch
                              checked={this.state.viewdaysheet}
                              onChange={val=>{this.handleviewdaysheetchange(val)}}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                          
                      </FormItem>

                      <FormItem name="viewoutstandingloanreport" label="Can View Outstanding loan report" >
                          <Switch
                              checked={this.state.viewoutstandingloanreport}
                              onChange={val=>{this.handleviewoutstandingloanreportchange(val)}}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                          
                      </FormItem>

                      <FormItem name="sendmessages" label="Can send messages(SMS)" >
                          <Switch
                              checked={this.state.sendmessages}
                              onChange={val=>{this.handlesendmessageschange(val)}}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                          
                      </FormItem>

                      <FormItem name="editaccounts" label="Can edit accounts" >
                          <Switch
                              checked={this.state.editaccounts}
                              onChange={val=>{this.handleeditaccountschange(val)}}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                          
                      </FormItem>

                      <FormItem name="accountactivation" label="Can activate accounts" >
                          <Switch
                              checked={this.state.accountactivation}
                              onChange={val=>{this.handleaccountactivationchange(val)}}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                          
                      </FormItem>

                      <FormItem name="loandailyreport" label="Can view daily loan report" >
                          <Switch
                              checked={this.state.loandailyreport}
                              onChange={val=>{this.handleloandailyreportchange(val)}}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                          
                      </FormItem>

                      <FormItem name="outstandingloans" label="view outstanding loan report" >
                          <Switch
                              checked={this.state.outstandingloans}
                              onChange={val=>{this.handleoutstandingloanschange(val)}}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                          
                      </FormItem>

                      <FormItem name="loanrepaymentstatus" label="view loan repayment status report" >
                          <Switch
                              checked={this.state.loanrepaymentstatus}
                              onChange={val=>{this.handleloanrepaymentstatuschange(val)}}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                          
                      </FormItem>

                      <FormItem name="loandefaultsreport" label="view loan defaults report" >
                          <Switch
                              checked={this.state.loandefaultsreport}
                              onChange={val=>{this.handleloandefaultsreportchange(val)}}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                          
                      </FormItem>


                      <FormItem name="loanagingreport" label="view loan aging report" >
                          <Switch
                              checked={this.state.loanagingreport}
                              onChange={val=>{this.handleloanagingreportchange(val)}}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                          
                      </FormItem>


                      <FormItem name="loandisburshmentreport" label="view loan disbursment report" >
                          <Switch
                              checked={this.state.loandisburshmentreport}
                              onChange={val=>{this.handleloandisburshmentreportchange(val)}}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                      </FormItem>


                      <FormItem name="viewdailyloanrepayments" label="view daily loan repayments" >
                          <Switch
                              checked={this.state.viewdailyloanrepayments}
                              onChange={val=>{this.handleviewdailyloanrepaymentschange(val)}}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                          
                      </FormItem>


                      <FormItem name="searchloanrepayments" label="search loan repayments" >
                          <Switch
                              checked={this.state.searchloanrepayments}
                              onChange={val=>{this.handlesearchloanrepaymentschange(val)}}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                          
                      </FormItem>

                      <FormItem name="viewloanpaymentqueues" label="view loan repayments queue" >
                          <Switch
                              checked={this.state.viewloanpaymentqueues}
                              onChange={val=>{this.handleviewloanpaymentqueueschange(val)}}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                          
                      </FormItem>

                      <FormItem name="can_approvemembers" label="Can Approve Members" >
                          <Switch
                              checked={this.state.can_approvemembers}
                              onChange={(val)=>{this.setState({can_approvemembers:val})}}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                          
                      </FormItem>

                      <FormItem name="can_opensupersave" label="Can Open Supersave" >
                          <Switch
                              checked={this.state.can_opensupersave}
                              onChange={(val)=>{this.setState({can_opensupersave:val})}}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                      </FormItem>


                      <FormItem name="can_openteebagoolo" label="Can Open TeebaGooolo" >
                          <Switch
                              checked={this.state.can_openteebagoolo}
                              onChange={(val)=>{this.setState({can_openteebagoolo:val})}}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                      </FormItem>

                      <FormItem name="can_makesingleaccounttransfer" label="Can make single account transfers" >
                          <Switch
                              checked={this.state.can_makesingleaccounttransfer}
                              onChange={(val)=>{this.setState({can_makesingleaccounttransfer:val})}}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                      </FormItem>

                      <FormItem name="can_award_osinterest" label="Can award ordinary savings interest" >
                          <Switch
                              checked={this.state.can_award_osinterest}
                              onChange={(val)=>{this.setState({can_award_osinterest:val})}}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                      </FormItem>

                      <FormItem name="can_award_ssinterest" label="Can award supersave interest" >
                          <Switch
                              checked={this.state.can_award_ssinterest}
                              onChange={(val)=>{this.setState({can_award_ssinterest:val})}}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                      </FormItem>

                      <FormItem name="can_award_tbinterest" label="Can award teebagoolo interest" >
                          <Switch
                              checked={this.state.can_award_tbinterest}
                              onChange={(val)=>{this.setState({can_award_tbinterest:val})}}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                      </FormItem>

                      <FormItem name="can_dobatchinteresttransfer" label="Can do batch interest tranfer" >
                          <Switch
                              checked={this.state.can_dobatchinteresttransfer}
                              onChange={(val)=>{this.setState({can_dobatchinteresttransfer:val})}}
                              checkedChildren="YES"
                              unCheckedChildren="NO"
                              />
                      </FormItem>


                        <FormItem name="can_clear_members_from_preview" label="Can clear member from preview" >
                            <Switch
                                checked={this.state.can_clear_members_from_preview}
                                onChange={(val)=>{this.setState({can_clear_members_from_preview:val})}}
                                checkedChildren="YES"
                                unCheckedChildren="NO"
                                />
                        </FormItem>

                        <FormItem name="can_reverse_membership_approval" label="can_reverse_membership_approval" >
                            <Switch
                                checked={this.state.can_reverse_membership_approval}
                                onChange={(val)=>{this.setState({can_reverse_membership_approval:val})}}
                                checkedChildren="YES"
                                unCheckedChildren="NO"
                                />
                        </FormItem>

                        <FormItem name="can_reverse_loan_process" label="can_reverse_loan_process" >
                            <Switch
                                checked={this.state.can_reverse_loan_process}
                                onChange={(val)=>{this.setState({can_reverse_loan_process:val})}}
                                checkedChildren="YES"
                                unCheckedChildren="NO"
                                />
                        </FormItem>
                        {/*new rights*/}
                        <FormItem name="can_view_withdraw_requistions" label="can_view_withdraw_requistions" >
                            <Switch
                                checked={this.state.can_view_withdraw_requistions}
                                onChange={(val)=>{this.setState({can_view_withdraw_requistions:val})}}
                                checkedChildren="YES"
                                unCheckedChildren="NO"
                                />
                        </FormItem>

                        <FormItem name="can_initiate_withdraw" label="can_initiate_withdraw" >
                            <Switch
                                checked={this.state.can_initiate_withdraw}
                                onChange={(val)=>{this.setState({can_initiate_withdraw:val})}}
                                checkedChildren="YES"
                                unCheckedChildren="NO"
                                />
                        </FormItem>

                        <FormItem name="can_review_withdraw" label="can_review_withdraw" >
                            <Switch
                                checked={this.state.can_review_withdraw}
                                onChange={(val)=>{this.setState({can_review_withdraw:val})}}
                                checkedChildren="YES"
                                unCheckedChildren="NO"
                                />
                        </FormItem>

                        <FormItem name="can_approve_withdraw" label="can_approve_withdraw" >
                            <Switch
                                checked={this.state.can_approve_withdraw}
                                onChange={(val)=>{this.setState({can_approve_withdraw:val})}}
                                checkedChildren="YES"
                                unCheckedChildren="NO"
                                />
                        </FormItem>


                     


                        <FormItem name="can_do_orginteraccount_transfers" label="can_do_orginteraccount_transfers" >
                            <Switch
                                checked={this.state.can_do_orginteraccount_transfers}
                                onChange={(val)=>{this.setState({can_do_orginteraccount_transfers:val})}}
                                checkedChildren="YES"
                                unCheckedChildren="NO"
                                />
                        </FormItem>


                        <FormItem name="can_make_journalentries" label="can_make_journalentries" >
                            <Switch
                                checked={this.state.can_make_journalentries}
                                onChange={(val)=>{this.setState({can_make_journalentries:val})}}
                                checkedChildren="YES"
                                unCheckedChildren="NO"
                                />
                        </FormItem>


                        <FormItem name="can_make_quick_transactions" label="can_make_quick_transactions" >
                            <Switch
                                checked={this.state.can_make_quick_transactions}
                                onChange={(val)=>{this.setState({can_make_quick_transactions:val})}}
                                checkedChildren="YES"
                                unCheckedChildren="NO"
                                />
                        </FormItem>

                        <FormItem name="can_make_transaction_reversals" label="can_make_transaction_reversals" >
                            <Switch
                                checked={this.state.can_make_transaction_reversals}
                                onChange={(val)=>{this.setState({can_make_transaction_reversals:val})}}
                                checkedChildren="YES"
                                unCheckedChildren="NO"
                                />
                        </FormItem>


                        
                        <FormItem>
                          <Button htmlType="submit" type="primary">Update</Button>
                        </FormItem>
                        
                      </Form>

              </Drawer>


              <Modal              
                visible={this.state.showpasswordupdatemodal}
                title="Password change Form"
                width={600}
                onCancel={this.closepasswordupdatemodal}

                footer={[
                  <Button key="back" onClick={this.closepasswordupdatemodal}>
                    Cancel
                  </Button>
                  ]}
                  >
              <Form
              onFinish={this.handlePasswordChange}        
              name="passwordreset"
              className="login-form">
        
              <Form.Item
                  name="new_password1"
                  label="New Password one"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your password!',
                    },
                  ]}
                  hasFeedback
                >
                  <Input.Password 
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Password one"
                  />


                </Form.Item>

          
                <Form.Item
                  name="new_password2"
                  label="Confirm New Password"
                  dependencies={['new_password1']}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Please confirm your password!',
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue('new_password1') === value) {
                          return Promise.resolve();
                        }
          
                        return Promise.reject('The two passwords that you entered do not match!');
                      },
                    }),
                  ]}
                >
                  <Input.Password 
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Confirm" />

                </Form.Item>

              <Form.Item >
                  <Button type="primary" htmlType="submit" className="login-form-button">
                    Confirm Change
                  </Button>
              </Form.Item>
            </Form>
                    
            </Modal>
        </div>
    )
    }
   
  }
}

export default AccountList; 
