import React,{ useRef } from 'react';
import axios from 'axios'
import { Table, Input,Card, Button,Collapse,Spin,Avatar,Modal,Form,DatePicker,Tooltip,Select,Space,Typography,Divider,InputNumber,message,Tabs } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { PrinterOutlined, MinusOutlined, CloseCircleFilled, CheckCircleOutlined, UserOutlined,DownloadOutlined } from '@ant-design/icons';
import { SearchOutlined,CameraOutlined,PlusOutlined,LoadingOutlined } from '@ant-design/icons';
import QrReader from 'react-qr-reader'
import uuid from 'react-uuid'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
import PrintTemplate from 'react-print';
import ReactToPrint from 'react-to-print';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import * as reactstrp from 'reactstrap';

import {
  Col,
  Row,
} from 'reactstrap';
import '../tablestyle.css'; // Create this CSS file for custom styles
import ReactExport from "@ibrahimrahmani/react-export-excel";
import TrialBalanceDateRangeView from './TrialBalanceDateRangeView';
import LedgerAccountDetails_Onboarding from "./LedgerAccountDetails_Onboarding";
import TrialBalanceBy from './TrialBalanceBy';


var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { Title } = Typography;
const { RangePicker } = DatePicker;
const { Panel } = Collapse;
const { TabPane } = Tabs;


const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


var token= ''
var sacco= ''
var bizuserid= ''

class ReportToPrint extends React.Component {

    state = {
      companyprofile:{}
    };
  
    componentDidMount(){
      if(localStorage.getItem("sacco")){
         sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
         bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
      
      }else{
         sacco= ''
         bizuserid= ''
      }
      
      if(localStorage.getItem("token")){
         token= localStorage.getItem("token")
      }else{
         token= ''
      }


      axios.defaults.headers={
        "Content-Type":"application/json",
        Authorization:`Token ${token}`
      }

      axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
      .then(res => {  
          this.setState({
            companyprofile: res.data
          })
  
      })
  
    }

    calculateTotalDebit=(list)=>{
      var total =0
      list.map((item)=>{
        total+=Number(item.debit)
      })
      return total.toFixed(2);
    }

    calculateTotalCredit=(list)=>{
      var total =0
      list.map((item)=>{
        total+=Number(item.credit)
      })
      return total.toFixed(2);
    }
    

    render() {
      return (
        <div style={{padding:20}}>
           <Row> 
                <Col xs="2" sm="2" lg="2">
                <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.sacco_logo} />
              
                  </Col>
                  <Col xs="6" sm="6" lg="6">
                  <h3>{this.state.companyprofile.sacco_name}</h3>
                  <h5>{this.state.companyprofile.companydealership}</h5>
                  <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                  {
                  this.state.companyprofile.RegisteredPhoneNumber2==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber2
                  }
                
                  </h6>               
                  <h5>Email: {this.state.companyprofile.company_email}</h5>
                  <h5>Wesite: {this.state.companyprofile.website}</h5>
                  <h5>{this.state.companyprofile.box_number}</h5>
                  </Col>
                  
           </Row>

           <h2 style={{justifyContent:'center',display:'flex',alignSelf:'center'}}>{"TRIALBALANCE REPORT AS OF "+this.props.date}</h2>
            <br></br>
            <h4 style={{justifyContent:'center',display:'flex',alignSelf:'center',color:'blue'}}>ASSETS</h4>
           
            {
              this.props.balancesheet_report_assets.map((catgry)=>{
                return (
                  <div>
                    <h3>{catgry.chatofaccounts_subcategory}</h3>

                    {
                      catgry.report.length>0?
                      <div>
                        <reactstrp.Table bordered>
                          <thead>
                            <tr>
                              <th>Account</th>
                              <th>Debit</th>
                              <th>Credit</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              catgry.report.map((item)=>(
                                <tr>
                                <td>{item.account}</td>
                                <td className="align-right">{<CurrencyFormat value={item.debit} displayType={'text'} thousandSeparator={true}/>}</td>
                                <td className="align-right">{<CurrencyFormat value={item.credit} displayType={'text'} thousandSeparator={true}/>}</td>
                                </tr>
                              ))
                            }

                            <tr>
                            <td>Total</td>
                            <td className="align-right" style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalDebit(catgry.report)} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td className="align-right" style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalCredit(catgry.report)} displayType={'text'} thousandSeparator={true}/>}</td>
                            </tr>

                            </tbody>
                           </reactstrp.Table> 
                      </div>
                      :
                      null
                    }
                  </div>
                );

              })
            }

           <reactstrp.Table bordered>
              <thead>
                <tr>
                  <th><h1 style={{color:'blue'}}>TOTAL ASSETS</h1></th>
                  <th className="align-right"><h1 style={{color:'blue',fontWeight:'bolder'}}><CurrencyFormat value={this.props.AllTotals.total_balances_asset_debit} displayType={'text'} thousandSeparator={true}/></h1></th>
                  <th className="align-right"><h1 style={{color:'blue',fontWeight:'bolder'}}><CurrencyFormat value={this.props.AllTotals.total_balances_asset_credit} displayType={'text'} thousandSeparator={true}/></h1></th>

                </tr>
              </thead>
          </reactstrp.Table>


          <h4 style={{justifyContent:'center',display:'flex',alignSelf:'center',color:'blue'}}>FINANCED BY</h4>
          <h5 style={{justifyContent:'center',display:'flex',alignSelf:'center'}}>Equity</h5>
          {
              this.props.balancesheet_report_equity.map((catgry)=>{
                return (
                  <div>
                    <h3>{catgry.chatofaccounts_subcategory}</h3>

                    {
                      catgry.report.length>0?
                      <div>
                        <reactstrp.Table bordered>
                          <thead>
                            <tr>
                              <th>Account</th>
                              <th>Debit</th>
                              <th>Credit</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              catgry.report.map((item)=>(
                                <tr>
                                <td>{item.account}</td>
                                <td className="align-right">{<CurrencyFormat value={item.debit} displayType={'text'} thousandSeparator={true}/>}</td>
                                <td className="align-right">{<CurrencyFormat value={item.credit} displayType={'text'} thousandSeparator={true}/>}</td>
                                </tr>
                              ))
                            }

                            <tr>
                            <td>Total</td>
                            <td className="align-right" style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalDebit(catgry.report)} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td className="align-right" style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalCredit(catgry.report)} displayType={'text'} thousandSeparator={true}/>}</td>
                            </tr>

                            </tbody>
                           </reactstrp.Table> 
                      </div>
                      :
                      null
                    }


                  </div>
                );

              })
            }
         <reactstrp.Table bordered>
              <thead>
                <tr>
                  <th><h2 style={{color:'green'}}>TOTAL EQUITY</h2></th>
                  <th className="align-right"><h2 style={{color:'green',fontWeight:'bolder'}}><CurrencyFormat value={this.props.AllTotals.total_balances_equity_debit} displayType={'text'} thousandSeparator={true}/></h2></th>
                  <th className="align-right"><h2 style={{color:'green',fontWeight:'bolder'}}><CurrencyFormat value={this.props.AllTotals.total_balances_equity_credit} displayType={'text'} thousandSeparator={true}/></h2></th>

                </tr>
              </thead>
          </reactstrp.Table>

          <h5 style={{justifyContent:'center',display:'flex',alignSelf:'center'}}>Liability</h5>
          {
              this.props.balancesheet_report_liability.map((catgry)=>{
                return (
                  <div>
                    <h3>{catgry.chatofaccounts_subcategory}</h3>

                    {
                      catgry.report.length>0?
                      <div>
                        <reactstrp.Table bordered>
                          <thead>
                            <tr>
                              <th>Account</th>
                              <th>Debit</th>
                              <th>Credit</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              catgry.report.map((item)=>(
                                <tr>
                                <td>{item.account}</td>
                                <td className="align-right">{<CurrencyFormat value={item.debit} displayType={'text'} thousandSeparator={true}/>}</td>
                                <td className="align-right">{<CurrencyFormat value={item.credit} displayType={'text'} thousandSeparator={true}/>}</td>
                                </tr>
                              ))
                            }

                            <tr>
                            <td>Total</td>
                            <td className="align-right" style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalDebit(catgry.report)} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td className="align-right" style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalCredit(catgry.report)} displayType={'text'} thousandSeparator={true}/>}</td>
                            </tr>

                            </tbody>
                           </reactstrp.Table> 
                      </div>
                      :
                      null
                    }


                  </div>
                );

              })
            }
         <reactstrp.Table bordered>
              <thead>
                <tr>
                  <th><h2 style={{color:'green'}}>TOTAL LIABILITY</h2></th>
                  <th className="align-right"><h2 style={{color:'green',fontWeight:'bolder'}}><CurrencyFormat value={this.props.AllTotals.total_balances_liability_debit} displayType={'text'} thousandSeparator={true}/></h2></th>
                  <th className="align-right"><h2 style={{color:'green',fontWeight:'bolder'}}><CurrencyFormat value={this.props.AllTotals.total_balances_liability_credit} displayType={'text'} thousandSeparator={true}/></h2></th>

                </tr>
              </thead>
          </reactstrp.Table>

          <Divider></Divider>

          <h5 style={{justifyContent:'center',display:'flex',alignSelf:'center',color:'blue'}}>INCOMES</h5>
          {
              this.props.balancesheet_report_incomes.map((catgry)=>{
                return (
                  <div>
                    <h3>{catgry.chatofaccounts_subcategory}</h3>

                    {
                      catgry.report.length>0?
                      <div>
                        <reactstrp.Table bordered>
                          <thead>
                            <tr>
                              <th>Account</th>
                              <th>Debit</th>
                              <th>Credit</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              catgry.report.map((item)=>(
                              <Tooltip title="Click to view details" placement="top">
                                <tr onClick={()=>{
                                  this.setState({selected_record:item})
                                  this.setState({ledeger_details_model_visible:true})
                                  }}> 
                                  <td>{item.account}</td>
                                <td className="align-right">{<CurrencyFormat value={item.debit} displayType={'text'} thousandSeparator={true}/>}</td>
                                <td className="align-right">{<CurrencyFormat value={item.credit} displayType={'text'} thousandSeparator={true}/>}</td>
                                </tr>
                                </Tooltip>
                              ))
                            }

                            <tr>
                            <td>Total</td>
                            <td className="align-right" style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalDebit(catgry.report)} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td className="align-right" style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalCredit(catgry.report)} displayType={'text'} thousandSeparator={true}/>}</td>
                            </tr>

                            </tbody>
                           </reactstrp.Table> 
                      </div>
                      :
                      null
                    }


                  </div>
                );

              })
            }


          <Divider></Divider>

          <h5 style={{justifyContent:'center',display:'flex',alignSelf:'center',color:'blue'}}>EXPENSES</h5>
          {
              this.props.balancesheet_report_expenses.map((catgry)=>{
                return (
                  <div>
                    <h3>{catgry.chatofaccounts_subcategory}</h3>

                    {
                      catgry.report.length>0?
                      <div>
                        <reactstrp.Table bordered>
                          <thead>
                            <tr>
                              <th>Account</th>
                              <th>Debit</th>
                              <th>Credit</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              catgry.report.map((item)=>(
                              <Tooltip title="Click to view details" placement="top">
                                <tr onClick={()=>{
                                  this.setState({selected_record:item})
                                  this.setState({ledeger_details_model_visible:true})
                                  }}> 
                                  <td>{item.account}</td>
                                <td className="align-right">{<CurrencyFormat value={item.debit} displayType={'text'} thousandSeparator={true}/>}</td>
                                <td className="align-right">{<CurrencyFormat value={item.credit} displayType={'text'} thousandSeparator={true}/>}</td>
                                </tr>
                                </Tooltip>
                              ))
                            }

                            <tr>
                            <td>Total</td>
                            <td className="align-right" style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalDebit(catgry.report)} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td className="align-right" style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalCredit(catgry.report)} displayType={'text'} thousandSeparator={true}/>}</td>
                            </tr>

                            </tbody>
                           </reactstrp.Table> 
                      </div>
                      :
                      null
                    }


                  </div>
                );

              })
            }

          <Divider></Divider>


          <reactstrp.Table bordered>
              <thead>
                <tr>
                  <th><h1 style={{color:'blue'}}>TOTALS</h1></th>
                  <th className="align-right"><h1 style={{color:'blue',fontWeight:'bolder'}}><CurrencyFormat value={(this.props.AllTotals.total_debit).toFixed(2)} displayType={'text'} thousandSeparator={true}/></h1></th>
                  <th className="align-right"><h1 style={{color:'blue',fontWeight:'bolder'}}><CurrencyFormat value={(this.props.AllTotals.total_credit).toFixed(2)} displayType={'text'} thousandSeparator={true}/></h1></th>

                </tr>
              </thead>
          </reactstrp.Table>
            
        </div>
      );
    }
  }


class TrialBalanceReport extends React.Component {

  state = {
   datarequested:true,
   date:moment().format(dateFormat).toString(),
   balancesheet_report_assets:[],

   balancesheet_report_liability:[],
   balancesheet_report_equity:[],
   AllTotals:{},

   ledeger_details_model_visible:false,
   selected_record:{},

   balancesheet_report_incomes:[],
   balancesheet_report_expenses:[],

   general_report:[]    



  };


  componentDidMount(){
      if(localStorage.getItem("sacco")){
        sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
        bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    }else{
        sacco= ''
        bizuserid= ''
    }
    
    if(localStorage.getItem("token")){
        token= localStorage.getItem("token")
    }else{
        token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }
    this.setState({datarequested:false})


  
}



  ////////////////////////////////////////////
  // RENDERING METHOD HERE
  render() {
    
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return(
        <div>


        <Tabs defaultActiveKey="002" onChange={()=>{}}>

        <TabPane tab="Trial balance (Periodic)" key="002" >
  <TrialBalanceDateRangeView />
</TabPane>


        <TabPane tab="Trialbalance from onboarding to specific date" key="001" >
          <TrialBalanceBy />
        
</TabPane>



</Tabs>

{
        this.state.ledeger_details_model_visible===true?

        <Modal    
              visible={this.state.ledeger_details_model_visible}
              title="Account details view"
              onCancel={()=>{this.setState({ledeger_details_model_visible:false})}}
              footer={[
                <Button key="back" onClick={()=>{this.setState({ledeger_details_model_visible:false})}}>
                  Cancel
                </Button>
                ]}
                width={1000}
                >
                    <LedgerAccountDetails_Onboarding
                    date={this.state.date}
                    account={this.state.selected_record.account}
                    />
                  
            </Modal>
              :
              null
            }


 </div>

    )

    }

  }
}

export default TrialBalanceReport; 
