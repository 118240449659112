import React from 'react';
import axios from 'axios'
import { Table, Input, Button,Collapse,Popover,Popconfirm,message,Spin,Tabs } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DeleteOutlined,FundViewOutlined,LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import * as serverconfig from '../serverconn'
import DataUploadForm from '../components/DataUploadForm'
import * as reactstrp from 'reactstrap';

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { TabPane } = Tabs;

function callback(key) {
  console.log(key);
}


var token= ''
var sacco= ''
var bizuserid= ''

class OnboardingSectionView extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    staffs: [],
    datarequested:true,
    userrights:{},
    companyprofile:{}
  };



  componentDidMount(){

    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
       bizuserid= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`https://1-be-pisaccosaasbackendcontroller.pitech.co.ug/api/data_templates/${1}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })
    })

    this.setState({datarequested:false})

 }

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  render() {
   


    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return(
        <div>
          <Tabs defaultActiveKey="1" onChange={callback}>
          <TabPane tab="Onbording Instructions" key="001" >
            <h3>STEP ONE</h3>
            <h6>1. create new users under "User Accounts" . These log into the system to carry out operations.</h6>
            <h6>2. create member types under "Member Types" . This is because you can not enter a new member or group without attaching a member type to them</h6>
            <h6>3. create ID types under "ID Types" . This is because you can not enter a new member or group without attaching an ID  type to them</h6>
            <h6>4. create Districts under "Ugandan districts" . This is because you can not enter a new member or group without attaching a district to them</h6>
            <h6>5. create Chart of accounts categories under "Institutional accounts" . This is because you can not run operations without chart of accounts . Consult your accountant</h6>
            <h6>6. create Institutional ledger accounts (Chart of accounts) under "Institutional accounts" . This is because you can not run operations without chart of accounts . Consult your accountant</h6>

            <h3>STEP TWO</h3>
            <h6>1. Make datauploads under dataupload section.  </h6>



          </TabPane>

          <TabPane tab="Data Upload" key="002" >


            <DataUploadForm />

          </TabPane>




          </Tabs>
 
        </div>
    )

    }

   
  }
}

export default OnboardingSectionView; 
