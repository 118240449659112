import React, { Component, createRef } from 'react';

import axios from 'axios'
import { Tooltip,Popover,Result,Table,Popconfirm,Input,Tabs, Button,Collapse,Card,Avatar,Modal,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Spin,Switch } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { PrinterOutlined,SettingFilled, MinusOutlined, CloseCircleFilled, CheckCircleOutlined, UserOutlined } from '@ant-design/icons';
import { DeleteOutlined,SearchOutlined,CameraOutlined,PlusOutlined,EditOutlined,PlusSquareFilled,SaveOutlined,LoadingOutlined } from '@ant-design/icons';
import QrReader from 'react-qr-reader'
import uuid from 'react-uuid'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
import PrintTemplate from 'react-print';
import ReactToPrint from 'react-to-print';
import { useReactToPrint } from 'react-to-print';

import {
  Col,
  Row,
} from 'reactstrap';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import * as reactstrp from 'reactstrap';
import { ToWords } from 'to-words';
import JournalEntryView from './JournalEntryView'
import PrintComponent from './PrintComponent';

var converter = require('number-to-words');

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { Title } = Typography;
const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

const toWords = new ToWords();
const { TabPane } = Tabs;

message.config({
  top: 200,
  duration: 2,
  maxCount: 3,
  rtl: true,
});


var token= ''
var sacco= ''
var username=''
var bizuserid= ''



class MembershipwithdrawalForm extends React.Component {

  state = {
    date:moment().format(dateFormat).toString(),
    selectedaccount:'',
    selectedaccountobject:{},
    members:[],
    datarequested:true,
    totalamount:0,
    paidinby:'',
    transactorcontact:'',
    previoustransactions:[],
    datasubmittedsuccessfully:false,
    companyprofile:{},
    accountchange:false,
    userprofile:{},
    transaction_details:'',
    journal_number:'',

    savingsamount:0,
    defsavings:0,
    supersavings:0,
    teebagooloamount:0,
    shares:0,
    companyaccounts: [],
    debitaccount:'',

    loan_amount:0,
    defsettings:{},
    defsett_modalvisible:false,
    noofinstallments:0,
    amountperinstallment:0,
    startingdate:'',

    availableloans:[],
    selectedloan:'',
    thermalprint:false,

    supersave_profile:{},
    teebagoolo_profile:{},
    journal_details:'',
    itemnumber:0,
    transaction_type:'',
    interest_tobepaid:0,

    current_loanproperties:{},

    extra_deposit_list:[],
    current_extra_field:'',
    print_office_copy:false,
    transaction_channel:'',

    change_receiving_account:false,
    default_debit:0,
    companyaccounts_new:[],
    membertype_object:{},
    journalno:0,
    finaldebit_account:'',

    loan_balanceclearance_mode:'savings',
    periodic_balanceobj:{},

    credited_account:'',
    voucher_no:0,
    withdrawcharges:0,
    bankcharges:0,
    withdrawable_figure:0

  }
  
  callback(key) {
    console.log(key);
  }



  componentDidMount(){
    this.componentRef = createRef();

    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    
    }else{
       sacco= ''
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
   }else{
      bizuserid= ''
   }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    let form_data1 = new FormData();
    form_data1.append('userid', bizuserid);
    
    //get the user profile here
    axios.post(serverconfig.backendserverurl+'/customqueries/get_user_profile',form_data1,{
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res => {
        this.setState({
          userprofile:res.data
        })
    })


    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })
    })

    axios.get(serverconfig.backendserverurl+`/api/members_withaccounts/?sacco=${sacco}`)
    .then(res => {

      //console.log(res.data)
      this.setState({
        members:res.data
      })

    })

    axios.get(serverconfig.backendserverurl+`/api/companyaccounts/?sacco=${sacco}`)
    .then(res => {
        this.setState({
            companyaccounts:res.data
        })
        this.setState({datarequested:false})
    })

    //all company acocunts
    axios.get(serverconfig.backendserverurl+`/api/companyaccounts/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          companyaccounts_new:res.data
        })
    })


    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

        this.setState({default_debit:res.data.defaultaccount_for_officecollection_debiting})

        if (this.state.companyprofile.defaultaccount_for_officecollection_debiting!=null || this.state.companyprofile.defaultaccount_for_officecollection_debiting!=0){

          this.setState({finaldebit_account:this.state.companyprofile.defaultaccount_for_officecollection_debiting})

        }

        //set extra deposits
        this.setState({extra_deposit_list:res.data.extra_deposit_list===undefined || res.data.extra_deposit_list===null?[]:JSON.parse(res.data.extra_deposit_list) })

    })


      
  }



  ////////////////////////////////////
  // PRODUCT SEARCH SELECT METHODS
   onBlur=()=> {
    console.log('blur');
  }
  
   onFocus=()=>{
    console.log('focus');
  }
  
  onSearch=(val)=> {
    console.log('search:', val);
  }


  //handle client change
  handleselectedaccountChange= (e) =>{
      this.setState({accountchange:true})
      this.setState({ selectedaccount: e});

      //make query for last transactions
      axios.get(`${serverconfig.backendserverurl}/api/members/${e}`)
      .then(res => {  
          this.setState({selectedaccountobject: res.data})
          this.setState({accountchange:false})

          //get membet typoe obj
          axios.get(`${serverconfig.backendserverurl}/api/accounttypes/${res.data.accountType}`)
          .then(res => {  
              this.setState({membertype_object: res.data})
          })

          
      })


      //get balances for particular date

      let form_data = new FormData();
      form_data.append('date',this.state.date);
      form_data.append('selectedmemberid', e);
      axios.post(serverconfig.backendserverurl+`/customqueries/get_memberactive_loans_bydate`, form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
        })
        .then(res => {
            this.setState({
              availableloans:JSON.parse(res.data.report)
            })
        })


        let form_data1 = new FormData();
        form_data1.append('date',this.state.date);
        form_data1.append('member_id', e);
        axios.post(serverconfig.backendserverurl+`/customqueries/get_runningbalancesbydate`, form_data1, {
        headers: {
          'content-type': 'multipart/form-data'
        }
        })
        .then(res => {
            this.setState({
              periodic_balanceobj:res.data.my_balance
            })
        })
    

  }


  handlepaidinbyChange= (e) => this.setState({ paidinby: e.target.value});
  handletransactorcontactChange= (e) => this.setState({ transactorcontact: e});


  //check empty objecty
  isEmptyObject=(obj)=>{
    for ( var name in obj ) {
      return false;
    }
    return true;
  }





  //get total withdrawable
  calculate_totalTransferableToSavings=()=>{
    var total =0;

    if (this.state.periodic_balanceobj.shareamount_balance>0){
      total+=Number(this.state.periodic_balanceobj.shareamount_balance)
    }

    if (this.state.periodic_balanceobj.supersaver_balance>0){
      total+=Number(this.state.periodic_balanceobj.supersaver_balance)
    }

    if (this.state.periodic_balanceobj.teebagoolo_balance>0){
      total+=Number(this.state.periodic_balanceobj.teebagoolo_balance)
    }

    if (this.state.periodic_balanceobj.saving_interestbalance>0){
      total+=Number(this.state.periodic_balanceobj.saving_interestbalance)
    }

    if (this.state.periodic_balanceobj.teebagoolo_interestbalance>0){
      total+=Number(this.state.periodic_balanceobj.teebagoolo_interestbalance)
    }

  

    if (this.state.periodic_balanceobj.def_deposit_balance>0){
      total+=Number(this.state.periodic_balanceobj.def_deposit_balance)
    }

    
   return total.toFixed(2)

  }



  calculateTotalPayabaleLoan=()=>{
    var total=0;
    this.state.availableloans.map((itm)=>{
      total+=(Number(itm.runningprinciple_balance)+Number(itm.runningint_balance))
    })
    return total.toFixed(2)
  }


  calTotalAfterTransfer=()=>{
    var total=0
    total+=Number(this.state.periodic_balanceobj.savings_balance)
    total+=Number(this.calculate_totalTransferableToSavings())
    return total
  }


  calculateTotalChargable=()=>{
    var total=0
    total+=Number(this.state.bankcharges)
    total+=Number(this.state.withdrawcharges)
    return total
  }

  cal



  calculateNetSavingsWithdrawable=()=>{

    var total=0
    total=this.calTotalAfterTransfer()
    total-=this.calculateTotalChargable()

    if (this.calculateTotalPayabaleLoan()>0 && this.state.loan_balanceclearance_mode==='savings'){
      total-=this.calculateTotalPayabaleLoan()
    }

    return total

  }



  ////////////////////////////////////////////
  // RENDERING METHOD HERE
  render() {

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      if(this.state.datasubmittedsuccessfully===true){
        return(
          <div>
         
            <Row >
                  <Col xs="12" sm="6" lg="6">
                    <Card>
                    <Result
                    status="success"
                    title="Successfully Transaction"
                    extra={[
                      <Button  type='primary'
                       onClick={()=>{window.location.reload(false)}}>
                       Finish
                       </Button>
                    ]}
                    />
                  </Card>
                  </Col>
            </Row>
          </div>

        )

              }else{
                return(
              
                    <Card>
                     <h3 style={{fontWeight:'bold',alignSelf:'center',color:'#154360'}}> MEMBERSHIP WITHDRAWAL FORM</h3>
                    
                     <FormItem label="Withdrawal Date">
                        <DatePicker 
                        onChange={(date, dateString) => this.setState({ date: dateString})} 
                        format={dateFormat} />
                     </FormItem> 

                    <FormItem label="Select member">
                        <Select 
                        placeholder="Account No | Name" 
                        style={{  }} 
                        value={this.state.selectedaccount}
                        onChange={this.handleselectedaccountChange} 
                        showSearch
                        optionFilterProp="children"
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        onSearch={this.onSearch}                    
                        >
                          {this.state.members.map(
                            (accnt)=>(
                              <Option value={accnt.id}>{accnt.AccountNumber} | {accnt.FullName}</Option>
                            ))}
                        </Select>
                    </FormItem>


                    {
                      this.state.accountchange?
                      <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
                      <Spin indicator={antIcon} />
                      </div>
                      :
                      null
                    }
        


                   <reactstrp.Table bordered>
                    <thead>

                    <tr>
                        <th>
                        <h4>Account Number:{this.state.selectedaccountobject.AccountNumber}</h4>
                        <h4>Account Contact:{this.state.selectedaccountobject.RegisteredPhoneNumber1}</h4>
                        <h4>Account Name:{this.state.selectedaccountobject.FullName}</h4>
                        </th>

                        <th>
                        <h4>Account status:{this.state.selectedaccountobject.account_status}</h4>
                        <h4>Date of joining:{this.state.selectedaccountobject.DateOfJoining}</h4>

                        </th>
                    </tr>


                    <tr>
                      <th>
                    <p style={{fontWeight:'bolder',fontSize:15}}>Saving Balance:<CurrencyFormat  value={Number(this.state.periodic_balanceobj.savings_balance).toFixed(2)} displayType={'text'} thousandSeparator={true} />  </p>
                    <p style={{fontWeight:'bolder',fontSize:15}}>Supersave Balance:<CurrencyFormat  value={Number(this.state.periodic_balanceobj.supersaver_balance).toFixed(2)} displayType={'text'} thousandSeparator={true} />  </p>

                    <p style={{fontWeight:'bolder',fontSize:15}}>Shares Balance:<CurrencyFormat  value={Number(this.state.periodic_balanceobj.shareamount_balance).toFixed(2)} displayType={'text'} thousandSeparator={true} />  </p>
                    <p style={{fontWeight:'bolder',fontSize:15}}>SharesNo:{this.state.periodic_balanceobj.share_numberbalance}  </p>
                    <p style={{fontWeight:'bolder',fontSize:15}}>Teebagoolo:<CurrencyFormat  value={Number(this.state.periodic_balanceobj.teebagoolo_balance).toFixed(2)} displayType={'text'} thousandSeparator={true} />  </p>
                    <p style={{fontWeight:'bolder',fontSize:15}}>Deffered Balance:<CurrencyFormat  value={Number(this.state.periodic_balanceobj.def_deposit_balance).toFixed(2)} displayType={'text'} thousandSeparator={true} />  </p>

                    <p style={{fontWeight:'bolder',fontSize:15}}>Loan:<CurrencyFormat  value={Number(this.state.periodic_balanceobj.loan_balance).toFixed(2)} displayType={'text'} thousandSeparator={true} />  </p>
                    <p style={{fontWeight:'bolder',fontSize:15}}>Loaninterest:<CurrencyFormat  value={Number(this.state.periodic_balanceobj.loan_interestbalance).toFixed(2)} displayType={'text'} thousandSeparator={true} />  </p>

                    </th>
                    <th>

                    <p style={{fontWeight:'bolder',fontSize:15}}>Savingsloansecurity:<CurrencyFormat  value={Number(this.state.periodic_balanceobj.savingsloan_security_balance).toFixed(2)} displayType={'text'} thousandSeparator={true} />  </p>
                    <p style={{fontWeight:'bolder',fontSize:15}}>Supersave loan security:<CurrencyFormat  value={Number(this.state.periodic_balanceobj.supersaveloan_security_balance).toFixed(2)} displayType={'text'} thousandSeparator={true} />  </p>
                    <p style={{fontWeight:'bolder',fontSize:15}}>Teebagoolo loan security:<CurrencyFormat  value={Number(this.state.periodic_balanceobj.teebagoololoan_security_balance).toFixed(2)} displayType={'text'} thousandSeparator={true} />  </p>
                    <p style={{fontWeight:'bolder',fontSize:15}}>Shares loan security:<CurrencyFormat  value={Number(this.state.periodic_balanceobj.shares_security_balance).toFixed(2)} displayType={'text'} thousandSeparator={true} />  </p>

                    <p style={{fontWeight:'bolder',fontSize:15}}>Saving Interest:<CurrencyFormat  value={Number(this.state.periodic_balanceobj.saving_interestbalance).toFixed(2)} displayType={'text'} thousandSeparator={true} />  </p>
                    <p style={{fontWeight:'bolder',fontSize:15}}>Teebagoolo Interest:<CurrencyFormat  value={Number(this.state.periodic_balanceobj.teebagoolo_interestbalance).toFixed(2)} displayType={'text'} thousandSeparator={true} />  </p>
                    <p style={{fontWeight:'bolder',fontSize:15}}>Supersave Interest:<CurrencyFormat  value={Number(this.state.periodic_balanceobj.supersave_interestbalance).toFixed(2)} displayType={'text'} thousandSeparator={true} />  </p>

                      </th>
                    </tr>
                  </thead>

                  </reactstrp.Table>


                  <Divider></Divider>

                  {
                    this.state.availableloans.length>0?
                    <reactstrp.Table bordered>
                    <thead>
                      <tr>
                        <th>
                          Date Of Issuance
                        </th>
                        <th>
                          Running Principle Balance
                        </th>
                        <th>
                          Running Interest Balance
                        </th>

                        <th>
                          Total Balance
                        </th>

                      </tr>
                    </thead>

                    <tbody>
                      {
                        this.state.availableloans.map((itm)=>{
                          return (
                           <tr>
                             <td>
                             <h3 style={{fontWeight:'bolder'}}>{itm.date}</h3>
                            </td>
                            <td>
                             <h3 style={{fontWeight:'bolder'}}><CurrencyFormat  value={Number(itm.runningprinciple_balance)} displayType={'text'} thousandSeparator={true} /></h3>
                            </td>
                            <td>
                            <h3 style={{fontWeight:'bolder'}}><CurrencyFormat  value={Number(itm.runningint_balance)} displayType={'text'} thousandSeparator={true} /></h3>
                            </td>

                            <td>
                            <h3 style={{fontWeight:'bolder'}}>
                              <CurrencyFormat  value={(Number(itm.runningprinciple_balance)+Number(itm.runningint_balance))} displayType={'text'} thousandSeparator={true} />
                            </h3>
                            </td>

                           </tr>
                          )
                        })
                      }
                    </tbody>
                    </reactstrp.Table>
                    :
                    null
                  }      


                 {
                  this.state.availableloans.length>0?
                  <FormItem label={<h4 style={{fontWeight:'bold'}}>Loan Balance Clearance Mode.</h4>}>
                        <Select 
                        placeholder="Loan Balance Clearance Mode" 
                        style={{  }} 
                        value={this.state.loan_balanceclearance_mode}
                        onChange={(val)=>{
                          this.setState({loan_balanceclearance_mode:val})
                        }} 
                        showSearch
                        optionFilterProp="children"
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        onSearch={this.onSearch}                    
                        >
                          <Option value={'savings'}>Savings</Option>
                          <Option value={'baddebt'}>Bad Debt Clearance</Option>
                        </Select>

                 </FormItem>
                 :null
                 }


              <FormItem 
                label={<h4 style={{fontWeight:'bold'}}>Savings Withdraw Pay A/C {this.state.companyprofile.defaultaccount_for_loanissuance_crediting}</h4>}
                >
                  <Select 
                    placeholder="Account" 
                    style={{  }} 
                    value={this.state.credited_account}
                    showSearch
                    optionFilterProp="children"
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onSearch={this.onSearch}     
                    onChange={(val)=>{
                      this.setState({credited_account:val})

                    }}                
                    >
                      {this.state.companyaccounts_new.map(
                        (accnt)=>{
                            return (
                              <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
                            );
                        }

                        )}
                    </Select>
                </FormItem>

                  <FormItem  label={<h4 style={{fontWeight:'bold'}}>Voucher no:</h4>}>
                    <Input name="voucher_no"
                    type='number'
                     placeholder="voucher_no."
                     value={this.state.voucher_no}
                     onChange={(val)=>{this.setState({voucher_no:val.target.value})}} />
                   </FormItem>


                  <FormItem label={<h4 style={{fontWeight:'bold'}}>Withdraw Charge:</h4>}>
                    <Input name="withdrawcharge"  
                    placeholder="0"
                    value={this.state.withdrawcharges}
                    onChange={(val)=>{this.setState({withdrawcharges:val.target.value})}}/>
                  </FormItem>
                   

                  <FormItem label={<h4 style={{fontWeight:'bold'}}>Bank Charges:</h4>}>
                    <Input name="bankcharges"  
                    placeholder="0" 
                    value={this.state.bankcharges} 
                    onChange={(val)=>{this.setState({bankcharges:val.target.value})}}/>
                  </FormItem>


                  <FormItem label={<h4 style={{fontWeight:'bold'}}>Withdrawable Amount:</h4>}>
                    <Input name="withdrawable_figure"  
                    placeholder="0" 
                    value={this.state.withdrawable_figure} 
                    onChange={(val)=>{this.setState({withdrawable_figure:val.target.value})}}/>
                  </FormItem>


                  <Divider></Divider>
                  <h3>Total Savings: <CurrencyFormat  value={this.state.periodic_balanceobj.savings_balance} displayType={'text'} thousandSeparator={true} />  </h3>
                  <h3>Total Transferable to Savings: <CurrencyFormat  value={this.calculate_totalTransferableToSavings()} displayType={'text'} thousandSeparator={true} />  </h3>
                  <h3>Total Savings After Transfer: <CurrencyFormat  value={this.calTotalAfterTransfer()} displayType={'text'} thousandSeparator={true} />  </h3>
                  <h3>Total LoanPay (Principle&Interest): <CurrencyFormat  value={this.calculateTotalPayabaleLoan()} displayType={'text'} thousandSeparator={true} />  </h3>
                  <h3>Net Withdrawable: <CurrencyFormat  value={this.calculateNetSavingsWithdrawable()} displayType={'text'} thousandSeparator={true} />  </h3>

                 <Divider></Divider>

                <FormItem>
                  <Button  
                  type="primary" 
                  htmlType="button"
                  onClick={()=>{

                     if (this.calculateNetSavingsWithdrawable()<=0){
                      message.error("Net Withdrawable savings is less or equal to zero")
                    }else if (this.state.credited_account===""){
                      message.error("Please select Paying account")
                    }else if (this.state.selectedaccount===""){
                      message.error("Please select Member")

                    }else{
                     this.setState({datarequested:true})

                    let form_data1 = new FormData();
                    form_data1.append('date',this.state.date);
                    form_data1.append('member_id', this.state.selectedaccount);
                    form_data1.append('periodic_balanceobj',JSON.stringify(this.state.periodic_balanceobj) );
                    form_data1.append('availableloans',JSON.stringify(this.state.availableloans) );
                    form_data1.append('loan_balanceclearance_mode', this.state.loan_balanceclearance_mode);
                    form_data1.append('username', username);
                    form_data1.append('sacco', sacco);
                    form_data1.append('total_payableLoan', this.calculateTotalPayabaleLoan());
                 
                    form_data1.append('credited_account', this.state.credited_account);
                    form_data1.append('voucher_no', this.state.voucher_no);
                    form_data1.append('bankcharges', this.state.bankcharges);
                    form_data1.append('withdrawcharges', this.state.withdrawcharges);
                    form_data1.append('netwithdrawable', this.state.withdrawable_figure>0?this.state.withdrawable_figure:   this.calculateNetSavingsWithdrawable());

                    axios.post(serverconfig.backendserverurl+`/customqueries/confirm_membership_withdrawal`, form_data1, {
                    headers: {
                      'content-type': 'multipart/form-data'
                    }
                    })
                    .then(res => {
                      message.info(res.data.message)

                      this.setState({bankcharges:0})
                      this.setState({withdrawable_figure:0})
                      this.setState({withdrawcharges:0})

                      this.setState({selectedaccountobject:{}})
                      this.setState({periodic_balanceobj:{}})
                      this.setState({datarequested:false})

                      this.setState({credited_account:''})
                      this.setState({selectedaccount:''})

                      this.setState({loan_balanceclearance_mode:'savings'})

                     })

                    }

                  }}
                  >
                    Confirm Withdrawal
                  </Button>
                </FormItem>

             </Card>

        )
      }
     
    }
   
  }
}

export default MembershipwithdrawalForm; 
