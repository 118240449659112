import React from 'react';
import { Form, Input, Button,Tabs,Select,Spin,Result,Popconfirm,message,Tooltip,Card,Switch,InputNumber,DatePicker, Divider } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import moment from 'moment';
import CurrencyFormat from 'react-currency-format';
import { UserOutlined, LockOutlined, LoadingOutlined,PrinterOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';
import {
  Col,
  Row,
} from 'reactstrap';
import ReactToPrint from 'react-to-print';
import * as reactstrp from 'reactstrap';
import LoanDetailsTab from './LoanDetailsTab';


var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const dateFormat = 'DD/MM/YYYY';
const { TextArea } = Input;
const { TabPane } = Tabs;


var sacco= ''
var username=''
var token= ''


class ReceiptToPrint extends React.Component {

  state = {
    companyprofile:{}
  };

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })

  }

//calculate principleTotal
calculateScheduleTotal=()=>{
  var principleTotal=0
  this.props.loanschedule.map(
    (item)=>{
      principleTotal+=Number(item.principle)
    })

  return principleTotal.toFixed(2);
}

  //calculate principleTotal
  calculateInterestTotal=()=>{
    var InterestTotal=0
    this.props.loanschedule.map(
      (item)=>{
        InterestTotal+=Number(item.interest)
      })

    return InterestTotal.toFixed(2);
  }

  //calculate principleTotal
  calculateTotalPay=()=>{
    var TotalPay=0
    this.props.loanschedule.map(
      (item)=>{
        TotalPay+=Number(item.total)
      })

    return TotalPay.toFixed(2);
  }



  render() {
    return (
      <div style={{padding:20}}>
        <style>
          {`@media print {
          @page { size: landscape; }
            }`}
          </style>
            
          <Row> 
                <Col xs="2" sm="2" lg="2">
                <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.sacco_logo} />
              
                  </Col>
                  <Col xs="6" sm="6" lg="6">
                  <h3>{this.state.companyprofile.sacco_name}</h3>
                  <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                  {
                  this.state.companyprofile.RegisteredPhoneNumber2==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber2
                  }
                  {
                  this.state.companyprofile.RegisteredPhoneNumber3==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber3
                  }
                  </h6>               
                  <h5>Email: {this.state.companyprofile.company_email}</h5>
                  <h5>Wesite: {this.state.companyprofile.website}</h5>
                  <h5>{this.state.companyprofile.box_number}</h5>
                  </Col>

              </Row>
                  <h3 style={{fontWeight:'bolder',display: 'flex',justifyContent:'center', alignItems:'center'}}> LOAN SCHEDULE </h3>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> ACCOUNT NUMBER:  {this.props.loanissueobject.accountNumber} </h4>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> AMOUNT: <CurrencyFormat value={this.props.loanissueobject.approved_amount} displayType={'text'} thousandSeparator={true}/> UGX</h4>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> PERIOD: {this.props.loanissueobject.loan_period}  {this.props.loanissueobject.loan_primary_schedule==="daily"?"Days":this.props.loanissueobject.loan_primary_schedule==="weekly"?"Weeks":"Months"}</h4>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> INTEREST RATE: {this.props.loanissueobject.interest_rate}</h4>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'end'}}> DATE OF ISSUANCE: {this.props.loanissueobject.date}</h4>

                  <reactstrp.Table bordered>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Date</th>
                            <th>Month</th>
                            <th>Principle Bf</th>
                            <th>Installment</th>
                            <th>Interest </th>
                            <th>Principle </th>
                            <th>Principle Bal</th>
                          </tr>
                        </thead>
                        <tbody>
                        {this.props.loanschedule.map(
                          (item)=>(
                            <tr>
                            <td>{item.repayment_number}</td>
                            <td>{item.date}</td>
                            <td>{item.payment_month}</td>
                            <td>{<CurrencyFormat value={item.principlebroughtforward} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.total} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.interest} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.principle} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.balance} displayType={'text'} thousandSeparator={true}/>}</td>
                            </tr>
                          ))}
                          <tr>
                          <th>Total</th>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalPay()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateInterestTotal()} displayType={'text'} thousandSeparator={true}/>}</td> 
                          <td style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateScheduleTotal()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td></td>
                          </tr>

                      </tbody>
                  </reactstrp.Table>
    </div>
    );
  }
}


class LoanIssuanceForm extends React.Component {

  state = {
    approved_amount:'',
    loan_pass:false,
    approval_report:'',
    loanapplication:{},
    loanapproval:{},
    datasubmittedsuccessfully:false,
    datarequested:true,
    interestratetype:'',
    loanperiod:0,
    dateofissuance:moment(),
    dateofissuancestring:moment().format(dateFormat).toString(),

    accounts:[],
    creditofficer:'',

    companyprofile:{},

    loanschedule:[],
    datetoday:moment().format(dateFormat).toString(),
    loanissueobject:{},
    companyaccounts: [],
    creditaccount:'',
    debitaccount:'',
    creditaccount_charge:'',
    debitaccount_charge:'',

    bankcharges:0,
    credaccountfor_bankcharge:'',
    debitaccountfor_bankcharge:'',
    current_loanproperties:{},

    companyaccounts_new:[],
    default_credit:0,
    change_paying_account:false,
    mini_statement:[],
    voucher_no:0,
    finaldebit_account:'',

  }

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }
    

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    const loanapplicationID=this.props.loanapplicationID;

    axios.get(`${serverconfig.backendserverurl}/api/loanapplications/${loanapplicationID}`)
    .then(res => {  
        this.setState({loanapplication: res.data})
        this.setState({approved_amount:res.data.loan_amount})


        //get loan balance now
        //query the latest balance
        if (res.data.loan_topup===true){

          var form_data1 = new FormData();
          form_data1.append('loan_id', res.data.loantopup_id);
          form_data1.append('date', this.state.dateofissuancestring);

          ///make a post request now
            axios.post(serverconfig.backendserverurl+'/customqueries/getloanbalances_atparticulardate', form_data1, {
              headers: {
                'content-type': 'multipart/form-data'
              }
            })
          .then(res => {
            this.setState({current_loanproperties:res.data.info})
          })
          .catch(error => console.log(error))
        }


    })


    axios.get(`${serverconfig.backendserverurl}/api/loanapprovals/?loan_id=${loanapplicationID}`)
    .then(res => {  
        this.setState({loanapproval: res.data[0]})
    })

    axios.get(serverconfig.backendserverurl+`/api/accounts/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          accounts:res.data
        })
    })

    axios.get(serverconfig.backendserverurl+`/api/saccos/${sacco}`)
    .then(res => {
        this.setState({
          companyprofile:res.data
        })
    })

    axios.get(serverconfig.backendserverurl+`/api/companyaccounts/?sacco=${sacco}`)
    .then(res => {
        this.setState({
            companyaccounts:res.data
        })
        this.setState({datarequested:false})
    })




    ////////////////////////////////////////////////
    //////new entries here
    //////////////////////////////////////

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

        this.setState({default_credit:res.data.defaultaccount_for_savingswithdraw_crediting})

        if (this.state.companyprofile.defaultaccount_for_savingswithdraw_crediting!=null || this.state.companyprofile.defaultaccount_for_savingswithdraw_crediting!=0){

          this.setState({finaldebit_account:this.state.companyprofile.defaultaccount_for_savingswithdraw_crediting})

        }
    })


      //all company acocunts
      axios.get(serverconfig.backendserverurl+`/api/companyaccounts/?sacco=${sacco}`)
      .then(res => {
          this.setState({
            companyaccounts_new:res.data
          })
        })



  }

  onBlur=()=> {
    console.log('blur');
  }
  
   onFocus=()=>{
    console.log('focus');
  }
  
  onSearch=(val)=> {
    console.log('search:', val);
  }


  //submit button pressed
  handleFormSubmit=(event) =>{

      if(this.state.companyprofile.attach_officer_to_loan===true &&this.state.creditofficer===''){
        message.info("Please select credit officer")
      }else{

      const loanapplicationID=this.props.loanapplicationID;

      this.setState({datarequested:true})
      let form_data = new FormData();
      form_data.append('dateofissuance', this.state.dateofissuancestring);
      form_data.append('loan_id', loanapplicationID);
      form_data.append('user',username );
      form_data.append('sacco', sacco);
      form_data.append('creditaccount', this.state.finaldebit_account);

      if(this.state.companyprofile.attach_officer_to_loan===true){
       form_data.append('attachedloanofficer', "true");
       form_data.append('loan_officer', this.state.creditofficer);
      }else{
        form_data.append('attachedloanofficer', "false");
        form_data.append('loan_officer', '');
      }

      form_data.append('bankcharges', this.state.bankcharges);
      form_data.append('voucher_no', this.state.voucher_no);

      axios.post(serverconfig.backendserverurl+'/customqueries/createloanissuance', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }})
     .then(res => {
      if(res.data.message=="success"){
        message.info(res.data.message)
        this.setState({datarequested:false})
        this.setState({datasubmittedsuccessfully:true})

        var loanissueID=Number(res.data.loanid)

        axios.get(`${serverconfig.backendserverurl}/api/loanissues/${loanissueID}`)
        .then(res => {  
            this.setState({loanissueobject: res.data})
        })

        axios.get(`${serverconfig.backendserverurl}/api/loanschedules/?loanissue=${loanissueID}`)
        .then(res => {  
            this.setState({loanschedule: res.data})
        })

      }else{
        message.info("Some thing went wrong")
      }
  
    })
    .catch(error => console.log(error))

    }
  }


  handleinterestratetypeChange= (e) => this.setState({ interestratetype: e});
  handleloanperiodChange= (e) => this.setState({ loanperiod: e.target.value});

  handledateofissuanceChange= (date, dateString) =>{
    //console.log(date.format(dateFormat))
    this.setState({ dateofissuance: date.format(dateFormat)});
    this.setState({dateofissuancestring:dateString})


    //get loan balance now
    //query the latest balance
    if (this.state.loanapplication.loan_topup===true){

      var form_data1 = new FormData();
      form_data1.append('loan_id', this.state.loanapplication.loantopup_id);
      form_data1.append('date', dateString);

      ///make a post request now
        axios.post(serverconfig.backendserverurl+'/customqueries/getloanbalances_atparticulardate', form_data1, {
          headers: {
            'content-type': 'multipart/form-data'
          }
        })
      .then(res => {
        this.setState({current_loanproperties:res.data.info})
      })
      .catch(error => console.log(error))
    }


  } 


    //check empty objecty
    isEmptyObject=(obj)=>{
      for ( var name in obj ) {
        return false;
      }
      return true;
    }
  


  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{

      if (this.state.datasubmittedsuccessfully===true){
        return(
          <Row >
            <Col xs="12" sm="6" lg="6">
            <Card>
            <div style={{display: "none"}}>
            &nbsp;&nbsp;
            <ReceiptToPrint
            loanschedule={this.state.loanschedule} 
            date={this.state.datetoday} 
            loanissueobject={this.state.loanissueobject}
            ref={el => (this.componentRef = el)} /> 
            </div>

            <Result
            status="success"
            title="Successfully Done Loan Disbursement Or Issuance "
            subTitle="Now Loan can be found in disbursed loans for repayments and review"
            extra={[
              <a>
              <ReactToPrint
                trigger={() =>                     
                  <div style={{}}>
                    <Tooltip title="Click to print" placement="top">
                    <p ><PrinterOutlined style={{ fontSize: '40px', color: '#08c' }}/></p>
                    <h6 >Print Loan Schedule</h6>
                    </Tooltip>
                  </div>                          
                    }
                    content={() => this.componentRef}
                  />  
                  </a>              
              ,
              <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
            ]}
            />
            </Card>
            </Col>
            </Row>
        );

      }else{
        return (
          <div style={{overflowX:'auto'}}>

                <Row>
                  <Col xs="12" sm="6" lg="6">
                    <Card>
                    <Divider></Divider>
              
                    {
                      this.state.loanapplication.issued?
                      null:
                      <Form
                      onFinish={(event) => this.handleFormSubmit(event)} 
                    >


                    <FormItem label="Date Of Issuance"
                      name="dateofissuance"          
                    >
                        <DatePicker  onChange={this.handledateofissuanceChange} format={dateFormat} />
                    </FormItem>


                    {
                      this.state.companyprofile.attach_officer_to_loan===true?
                      <FormItem label="Credit Officer"
                      name="creditofficer"
                      rules={[
                        {
                          required: false,
                          message: 'Please Select creditofficer',
                        },
                      ]}
                    >
                        <Select placeholder="Credit Officer"
                          value={this.state.creditofficer} 
                          onChange={(val)=>{this.setState({creditofficer:val})}} 
                          
                          showSearch
                          optionFilterProp="children"
                          onFocus={this.onFocus}
                          onBlur={this.onBlur}
                          onSearch={this.onSearch}    
                          
                          
                          >
                          {this.state.accounts.map(
                            (ctgry)=>(
                              <Option value={ctgry.id}>{ctgry.name}</Option>
                            ))}
                        </Select>
                    </FormItem>
                      :
                      null
                    }

                {
                  this.state.companyprofile.defaultaccount_for_savingswithdraw_crediting!=0 ?
                  
                  <div>

                <FormItem 
                  label={<h4 style={{fontWeight:'bold'}}>Auto Credit A/C {this.state.companyprofile.defaultaccount_for_savingswithdraw_crediting}</h4>}
                  >

                    <Select 
                      placeholder="Account" 
                      style={{  }} 
                      value={this.state.default_credit}
                      showSearch
                      optionFilterProp="children"
                      onFocus={this.onFocus}
                      onBlur={this.onBlur}
                      onSearch={this.onSearch}                    
                      >
                        {this.state.companyaccounts_new.map(
                          (accnt)=>{

                            if (this.state.default_credit===accnt.id){
                              return (
                                <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
                              );
                            }
                          }

                          )}
                      </Select>
                  </FormItem>


                  <FormItem label={<h4 style={{fontWeight:'bold'}}>Change Account</h4>} >
                  <Switch
                    checked={this.state.change_paying_account}
                    onChange={val=>{this.setState({change_paying_account:val})}}
                    checkedChildren="Changed to other"
                    unCheckedChildren="Click to change"
                    />

                  </FormItem>


                  {
                  this.state.change_paying_account?
                  <FormItem label={<h4 style={{fontWeight:'bold'}}>Pay A/C (Credit A/C).</h4>}
                  name="creditaccount"
                  rules={[
                    {
                      required: true,
                      message: 'Please select account',
                    },
                  ]}
                >
                      <Select 
                      placeholder="Account" 
                      style={{  }} 
                      value={this.state.creditaccount}
                      onChange={(val)=>{
                        
                        this.setState({creditaccount:val})

                        this.setState({
                          finaldebit_account: val
                      })
                  
                      
                      }} 
                      showSearch
                      optionFilterProp="children"
                      onFocus={this.onFocus}
                      onBlur={this.onBlur}
                      onSearch={this.onSearch}                    
                      >
                        {this.state.companyaccounts.map(
                          (accnt)=>(
                            <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
                          ))}
                      </Select>
                  </FormItem>
                  :
                  null
                }   


                  </div>
                  
                  
                  :
                  <FormItem label={<h4 style={{fontWeight:'bold'}}>Pay A/C (Credit A/C).</h4>}
                  name="creditaccount"
                  rules={[
                    {
                      required: true,
                      message: 'Please select account',
                    },
                  ]}
                  >
                      <Select 
                      placeholder="Account" 
                      style={{  }} 
                      value={this.state.creditaccount}
                      onChange={(val)=>{
                        
                        this.setState({creditaccount:val})

                        this.setState({
                          finaldebit_account: val
                      })
                      
                      
                      
                      }} 
                      showSearch
                      optionFilterProp="children"
                      onFocus={this.onFocus}
                      onBlur={this.onBlur}
                      onSearch={this.onSearch}                    
                      >
                        {this.state.companyaccounts.map(
                          (accnt)=>(
                            <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
                          ))}
                      </Select>
                  </FormItem>
                }

                      <FormItem label="Bank charges">
                        <Input name="bankcharges" 
                        type='number' 
                        placeholder="bankcharges." 
                        value={this.state.bankcharges}
                        onChange={(val)=>{this.setState({bankcharges:val.target.value})}} 
                        />
                      </FormItem>


                      <FormItem label="Voucher no">
                      <Input name="voucher_no"
                      type='number'
                      placeholder="voucher_no."
                      value={this.state.voucher_no}
                      onChange={(val)=>{this.setState({voucher_no:val.target.value})}} />
                    </FormItem>

                      <Divider></Divider>
                          
                          <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                          <FormItem>
                            
                            
                          <Tooltip title="Click here to disburse loan" placement="top">
                            <Button  type="primary" htmlType="submit"> Disburse Loan </Button>
                          </Tooltip>
              
                          </FormItem>
              
                          </div>

                    </Form>
                    }
                    

                  </Card>
                  </Col>


                  {
                    this.isEmptyObject(this.state.current_loanproperties)===false?
                    <Col xs="12" sm="6" lg="6">
                    <Card>
                    <div >

                      <br></br>
                      <h4 style={{fontStyle:'oblique',fontWeight:'bolder',color:'green'}}>For Loantopup. </h4>

                    <h6>Running interest balance as at {this.state.dateofissuancestring} : <CurrencyFormat  value={this.state.current_loanproperties.runningint_balance} displayType={'text'} thousandSeparator={true} /> </h6>
                    <h6>Running principle balance as at {this.state.dateofissuancestring} : <CurrencyFormat  value={this.state.current_loanproperties.runningprinciple_balance} displayType={'text'} thousandSeparator={true} /> </h6>
                    <h6>Total running balance as at {this.state.dateofissuancestring} : <CurrencyFormat  value={this.state.current_loanproperties.runningint_total_bal} displayType={'text'} thousandSeparator={true} /> </h6>

                    </div>
                    </Card>
                    </Col>
                    :
                    null
                  }

                </Row>

          </div>
        );

      }

    }

  }

}


export default LoanIssuanceForm;



