import React,{ useRef } from 'react';
import axios from 'axios'
import { Table, Input, Button,Tabs,Collapse,Avatar,Modal,Form,DatePicker,Tooltip,Select,Space,Typography,Divider,InputNumber,message,Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { PrinterOutlined, MinusOutlined, CloseCircleFilled, CheckCircleOutlined, UserOutlined } from '@ant-design/icons';
import { SearchOutlined,CameraOutlined,PlusOutlined,FundViewOutlined,LoadingOutlined,DownloadOutlined } from '@ant-design/icons';
import QrReader from 'react-qr-reader'
import uuid from 'react-uuid'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
import PrintTemplate from 'react-print';
import ReactToPrint from 'react-to-print';
import * as serverconfig from '../serverconn'
import CurrencyFormat from 'react-currency-format';
import {
  Col,
  Row,
} from 'reactstrap';
import * as reactstrp from 'reactstrap';
import ReactExport from "@ibrahimrahmani/react-export-excel";
import '../tablestyle.css'; // Create this CSS file for custom styles
import LoanExtras from './LoanExtras';
import LoanAgingStatistics from './LoanAgingStatistics';


var CryptoJS = require("crypto-js");
const { TabPane } = Tabs;

const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { Title } = Typography;
const { RangePicker } = DatePicker;
const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

const { Search } = Input;

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


var token= ''
var sacco= ''
var bizuserid= ''

class ReportToPrint extends React.Component {

    state = {
      companyprofile:{},
    };
  
    componentDidMount(){
      if(localStorage.getItem("sacco")){
         sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
         bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
      }else{
         sacco= ''
         bizuserid= ''
      }
      
      if(localStorage.getItem("token")){
         token= localStorage.getItem("token")
      }else{
         token= ''
      }

      axios.defaults.headers={
        "Content-Type":"application/json",
        Authorization:`Token ${token}`
      }

      axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
      .then(res => {  
          this.setState({
            companyprofile: res.data
          })
  
      })

  
    }

    calculate_installmentexpetedTotal=()=>{
      var  total =0
      this.props.loanissues.map((item)=>{
        total+=Number(item.installment_expected)
      })
      return total.toFixed(2);
  
    }
  
  
    calculate_actual_loan_balance=()=>{
      var  total =0
      this.props.loanissues.map((item)=>{
        total+=Number(item.actual_loan_balance)
      })
      return total.toFixed(2);
  
    }
  
  
    calculateTotal_expectedbal=()=>{
      var  total =0
      this.props.loanissues.map((item)=>{
        total+=Number(item.expected_loan_balance)
      })
      return total.toFixed(2);
  
    }
  
  
    calculateTotalofPrinciple=()=>{
      var  total=0
      this.props.loanissues.map((item)=>{
        total+=Number(item.total_loan_amount)
      })
  
      return total.toFixed(2);
    }
   
  
    calculate_arrears=()=>{
      var  total=0
      this.props.loanissues.map((item)=>{
        total+=Number(item.arrears)
      })
  
      return total.toFixed(2);
    }
  
  
    calculate_advance=()=>{
      var  total=0
      this.props.loanissues.map((item)=>{
        total+=Number(item.advance)
      })
  
      return total.toFixed(2);
    }



  calculate_interest_arrears=()=>{
    var  total=0
    this.props.loanissues.map((item)=>{
      total+=Number(item.interest_arrears)
    })

    return total.toFixed(2);
  }


  calculate_total_arrears=()=>{
    var  total=0
    this.props.loanissues.map((item)=>{
      total+=Number(item.total_arrears)
    })

    return total.toFixed(2);
  }

 
   
    render() {
      return (
        <div style={{padding:20}}>
           <style>
            {`@media print {
            @page { size: landscape; }
             }`}
          </style>
          <Row> 
                <Col xs="2" sm="2" lg="2">
                <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.sacco_logo} />
              
                  </Col>
                  <Col xs="6" sm="6" lg="6">
                  <h3>{this.state.companyprofile.sacco_name}</h3>
                  <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                  {
                  this.state.companyprofile.RegisteredPhoneNumber2==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber2
                  }
                  {
                  this.state.companyprofile.RegisteredPhoneNumber3==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber3
                  }
                  </h6>               
                  <h5>Email: {this.state.companyprofile.company_email}</h5>
                  <h5>Wesite: {this.state.companyprofile.website}</h5>
                  <h5>{this.state.companyprofile.box_number}</h5>
                
                  </Col>
       </Row>

             <h3 style={{display: 'flex',justifyContent:'center', alignItems:'center',fontWeight:'bolder'}}>LOAN ARREARS REPORT AS OF {this.props.date} </h3>
             <Divider></Divider>

             <h4>LOAN CATEGORY KEYS:  A - Advance , B - 0-29 Days , C - 30-59 Days , D - 60-89 Days , E - Above 90 Days</h4>
             <Divider></Divider>
             <reactstrp.Table bordered>
                        <thead>
                          <tr>
                            <th>Account No</th>
                            <th>Account Name</th>
                            <th>Loan ID</th>

                            <th>Loan Amount</th>
                            <th>Issuance Date</th>
                            <th>Installment expected</th>

                            <th>Expected Balance</th>
                            <th>Actual Balance</th>

                            <th>Principle Arrears</th>
                            <th>Interest Arrears</th>
                            <th>Total Arrears</th>

                            <th>Advance</th>

                            <th>Last PayDate</th>
                            <th>Expiry Date</th>

                            <th>Arrear Days</th>
                            <th>Loan Category</th>

                            <th>Loan Period</th>
                            <th>Savings Balance</th>

                          </tr>
                        </thead>
                        <tbody>
   
                        {this.props.loanissues.map(
                          (item)=>(
                            <React.Fragment key={item}>
                            <tr>
                          
                            <td>{item.account_number}</td>
                            <td>{item.account_name}</td>
                            <td>{item.loan_number}</td>

                            <td className="align-right">{<CurrencyFormat value={item.total_loan_amount} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{item.loan_date}</td>
                            <td className="align-right">{<CurrencyFormat value={item.installment_expected} displayType={'text'} thousandSeparator={true}/>}</td>

                            <td className="align-right">{<CurrencyFormat value={item.expected_loan_balance} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td className="align-right">{<CurrencyFormat value={item.actual_loan_balance} displayType={'text'} thousandSeparator={true}/>}</td>
                           
                            <td className="align-right">{<CurrencyFormat value={item.arrears} displayType={'text'} thousandSeparator={true}/>}</td>
                            
                            <td className="align-right">{<CurrencyFormat value={item.interest_arrears} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td className="align-right">{<CurrencyFormat value={item.total_arrears} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td className="align-right">{<CurrencyFormat value={item.advance} displayType={'text'} thousandSeparator={true}/>}</td>



                            <td>{item.last_paydate}</td>
                            <td>{item.expiry_due}</td>

                            <td>{item.days_in_arrears}</td>
                            <td>{item.aging_category}</td>

                            <td>{item.loan_period}</td>
                            <td className="align-right">{<CurrencyFormat value={item.savings_balance} displayType={'text'} thousandSeparator={true}/>}</td>


                            </tr>

                            
                            </React.Fragment>
                          ))}



                          {/**Totals */}
                          <tr>
                          <td style={{fontWeight:'bolder'}}>Totals</td>
                          <td></td>
                          <td></td>

                          <th className="align-right"><CurrencyFormat value={this.calculateTotalofPrinciple()} displayType={'text'} thousandSeparator={true}/></th>
                          <td></td>
                          <th className="align-right"><CurrencyFormat value={this.calculate_installmentexpetedTotal()} displayType={'text'} thousandSeparator={true}/></th>

                          <th className="align-right"><CurrencyFormat value={this.calculateTotal_expectedbal()} displayType={'text'} thousandSeparator={true}/></th>

                          <th className="align-right"><CurrencyFormat value={this.calculate_actual_loan_balance()} displayType={'text'} thousandSeparator={true}/></th>
                          <th className="align-right"><CurrencyFormat value={this.calculate_arrears()} displayType={'text'} thousandSeparator={true}/></th>
                         
                          <th className="align-right"><CurrencyFormat value={this.calculate_interest_arrears()} displayType={'text'} thousandSeparator={true}/></th>
                          <th className="align-right"><CurrencyFormat value={this.calculate_total_arrears()} displayType={'text'} thousandSeparator={true}/></th>
                          <th className="align-right"><CurrencyFormat value={this.calculate_advance()} displayType={'text'} thousandSeparator={true}/></th>

                         
                          <td></td>
                          <td></td>

                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>


                          </tr>
                          
                      </tbody>
                  </reactstrp.Table>      
           
                  <br></br>
                <h3>Generated by: {CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)}</h3>
                <h3>Powered by: www.pitech.co.ug</h3>   

        </div>
      );
    }
  }


class LoanRepaymentStatusReport extends React.Component {

  state = {
    loanissues:[],
    date:moment().format(dateFormat).toString(),
    datarequested:true,
    totalsaggregate:{},
    loanissues_placeholder:[],

    show_details:false,
    selected_id:''

   };

   componentDidMount(){
    if(localStorage.getItem("sacco")){
      sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
   
   }else{
      sacco= ''
      bizuserid= ''
   }
   
   if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    let form_data = new FormData();
    form_data.append('sacco', sacco);
    form_data.append('date', this.state.date);

    //Now submit sale data to database
    axios.post(serverconfig.backendserverurl+'/customqueries/getLoanRepaymentStatusReport_byperiod', form_data,{
    headers: {
      'content-type': 'multipart/form-data'
    }
    })
    .then(res =>{
      this.setState({datarequested:false})
      this.setState({loanissues:JSON.parse(res.data.loanissues)})

      this.setState({loanissues_placeholder:JSON.parse(res.data.loanissues)})

    } 
    )
    .catch(error => console.log(error)) 

  }
 

   ////////////////////////////////////
  // USER SEARCH SELECT METHODS
  onBlur=()=> {
    console.log('blur');
  }
  
   onFocus=()=>{
    console.log('focus');
  }
  
  onSearch=(val)=> {
    console.log('search:', val);
  }


  calculate_installmentexpetedTotal=()=>{
    var  total =0
    this.state.loanissues.map((item)=>{
      total+=Number(item.installment_expected)
    })
    return total.toFixed(2);

  }


  calculate_actual_loan_balance=()=>{
    var  total =0
    this.state.loanissues.map((item)=>{
      total+=Number(item.actual_loan_balance)
    })
    return total.toFixed(2);

  }


  calculateTotal_expectedbal=()=>{
    var  total =0
    this.state.loanissues.map((item)=>{
      total+=Number(item.expected_loan_balance)
    })
    return total.toFixed(2);

  }


  calculateTotalofPrinciple=()=>{
    var  total=0
    this.state.loanissues.map((item)=>{
      total+=Number(item.total_loan_amount)
    })

    return total.toFixed(2);
  }
 

  calculate_arrears=()=>{
    var  total=0
    this.state.loanissues.map((item)=>{
      total+=Number(item.arrears)
    })

    return total.toFixed(2);
  }


  calculate_advance=()=>{
    var  total=0
    this.state.loanissues.map((item)=>{
      total+=Number(item.advance)
    })

    return total.toFixed(2);
  }


  calculate_interest_arrears=()=>{
    var  total=0
    this.state.loanissues.map((item)=>{
      total+=Number(item.interest_arrears)
    })

    return total.toFixed(2);
  }


  calculate_total_arrears=()=>{
    var  total=0
    this.state.loanissues.map((item)=>{
      total+=Number(item.total_arrears)
    })

    return total.toFixed(2);
  }




   ////////////////////////////////////////////
   // RENDERING METHOD HERE
   render() {
      const pageStyle = `
        @media print {
          @page { size: landscape; }
        }
      `;
       
       if(this.state.datarequested===true){
        return(
          <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin size="large">
                <div className="content" />
              </Spin>             </div>
        )
  
      }else{

        return(

          <Tabs defaultActiveKey="3" onChange={()=>{}}>
  
             <TabPane tab={"Report"} key="3">
             <div>
            <div style={{display: "none",}}>
               &nbsp;&nbsp;
              <ReportToPrint
              date={this.state.date} 
              loanissues={this.state.loanissues} 
              totalsaggregate={this.state.totalsaggregate} 
              ref={el => (this.componentRef = el)} /> 
            </div>
  
            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
              <Panel header="LOAN ARREARS REPORT" key="1">

              <div style={{display:'flex',justifyContent:'flex-start',alignSelf:'flex-start',alignItems:'flex-start'}}> 
              <ReactToPrint
                  trigger={() => 
                  <Button type="primary"  shape="round" icon={<PrinterOutlined />} size={this.state.size}>
                  Print
                  </Button>
                  }
                  content={() => this.componentRef}
                  />

            &nbsp;&nbsp;&nbsp;
              <ExcelFile filename={"Pisys -Loan Arrears report by "+this.state.date}  element={<Button type='primary' icon={<DownloadOutlined/>}>Download (Excel)</Button>}>
                <ExcelSheet data={this.state.loanissues} name="Arrears report">
                    <ExcelColumn label="Account No" value="account_number"/>
                    <ExcelColumn label="Account Name" value="account_name"/>
                    <ExcelColumn label="Loan ID" value="loan_number"/>

                    <ExcelColumn label="Loan Amount" value="total_loan_amount"/>
                    <ExcelColumn label="Issuance date" value="loan_date"/>
                    <ExcelColumn label="Installment expected" value="installment_expected"/>
                    <ExcelColumn label="ExpectedLoanBalance" value="expected_loan_balance"/>
                    <ExcelColumn label="ActualLoanBalance" value="actual_loan_balance"/>

                    <ExcelColumn label="Principle Arrears" value="arrears"/>

                    <ExcelColumn label="Interest In Arrears" value="interest_arrears"/>
                    <ExcelColumn label="Total Arrears" value="total_arrears"/>
                    <ExcelColumn label="Advance" value="advance"/>


                    <ExcelColumn label="Last Pay Date" value="last_paydate"/>
                    <ExcelColumn label="Expiry Date" value="expiry_due"/>

                    <ExcelColumn label="Arrear Days" value="days_in_arrears"/>
                    <ExcelColumn label="Loan Category" value="aging_category"/>

                    <ExcelColumn label="Loan Period" value="loan_period"/>
                    <ExcelColumn label="Savings Balance" value="savings_balance"/>


                </ExcelSheet>
               </ExcelFile>


              &nbsp;&nbsp;&nbsp;
              <Form layout="inline" >

              <FormItem label={"Date"}>
              <DatePicker
               onChange={
                (date, dateString) =>{
                  this.setState({ date: dateString});
                } 
               }
               format={dateFormat} 
               />

               
              </FormItem>

              <FormItem>
              <Button onClick={()=>{

              if(this.state.date===''){
              alert("Date missing")
              }else{
              this.setState({datarequested:true})

              let form_data1 = new FormData();
              form_data1.append('sacco', sacco);
              form_data1.append('date', this.state.date);

              axios.post(serverconfig.backendserverurl+`/customqueries/getLoanRepaymentStatusReport_byperiod`, form_data1)
              .then(res => {
                this.setState({loanissues:JSON.parse(res.data.loanissues)})

                this.setState({loanissues_placeholder:JSON.parse(res.data.loanissues)})

                this.setState({datarequested:false})

              })

              }

              }} type="primary" htmlType="button">Search</Button>
              </FormItem> 
              </Form>



              </div>
              <br></br>

           
              <div style={{overflowX:'auto'}}>

            <Search
            placeholder="Input Account number or Account name"
            onChange={(value) => {
              var val=value.target.value
              console.log(val)
              if (val !== '') {
                const results = this.state.loanissues.filter((request) => {
                  return request.account_name.toLowerCase().includes(val.toLowerCase());
                  // Use the toLowerCase() method to make it case-insensitive
                });
          
                if (results.length === 0) {
                  // this.setState({loanissues:this.state.loanissues_placeholder});

                  const results_2 = this.state.loanissues.filter((request) => {
                    return request.account_number.toLowerCase().includes(val.toLowerCase());
                    // Use the toLowerCase() method to make it case-insensitive
                  });

                  if (results_2.length === 0) {
                    this.setState({loanissues:this.state.loanissues_placeholder});

                  }else{
                    this.setState({loanissues:results_2});

                  }

                } else {
                  this.setState({loanissues:results});
                  console.log(results)
                }
          
              } else {
                this.setState({loanissues:this.state.loanissues_placeholder});
          
              }
          
            }}
            style={{
              width: 400,
            }}
            />
            <br></br>
            <br></br>
            <h4>LOAN CATEGORY KEYS:  A - Advance , B - 0-29 Days , C - 30-59 Days , D - 60-89 Days , E - Above 90 Days</h4>
            <Divider></Divider>
              <reactstrp.Table bordered>
                        <thead>
                          <tr>
                            <th>Details</th>
                            <th>Status</th>
                            <th>Account No</th>
                            <th>Account Name</th>
                            <th>Loan ID</th>

                            <th>Loan Amount</th>
                            <th>Issuance Date</th>
                            <th>Installment expected</th>

                            <th>Expected Balance</th>
                            <th>Actual Balance</th>
                            <th>Principle Arrears</th>

                            <th>Interest Arrears</th>
                            <th>Total Arrears</th>
                            <th>Advance</th>


                            <th>Last PayDate</th>
                            <th>Expiry Date</th>

                            <th>Arrear Days</th>
                            <th>Loan Category</th>
                            <th>Loan Period</th>
                            <th>Savings Balance</th>


                          </tr>
                        </thead>
                        <tbody>
   
                        {this.state.loanissues.map(
                          (item)=>(
                            <React.Fragment key={item}>
                            <tr>
                            <td>
                            <Tooltip title="Click view loan details" placement="top">
                            <FundViewOutlined style={{color:'blue'}}
                              onClick={()=>{
                                console.log("ID: "+String(item.id))
                                this.setState({selected_id:Number(item.id)})
                                this.setState({show_details:!this.state.show_details})
                            }}
                            />
                            </Tooltip>
                            </td>
                            <td style={{color:item.status==='expired'?'red':'green'}}>{item.status}</td>

                            <td>{item.account_number}</td>
                            <td>{item.account_name}</td>
                            <td>{item.loan_number}</td>

                            <td className="align-right">{<CurrencyFormat value={item.total_loan_amount} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{item.loan_date}</td>
                            <td className="align-right">{<CurrencyFormat value={item.installment_expected} displayType={'text'} thousandSeparator={true}/>}</td>

                            <td className="align-right">{<CurrencyFormat value={item.expected_loan_balance} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td className="align-right">{<CurrencyFormat value={item.actual_loan_balance} displayType={'text'} thousandSeparator={true}/>}</td>
                           
                            <td className="align-right">{<CurrencyFormat value={item.arrears} displayType={'text'} thousandSeparator={true}/>}</td>
                            

                            <td className="align-right">{<CurrencyFormat value={item.interest_arrears} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td className="align-right">{<CurrencyFormat value={item.total_arrears} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td className="align-right">{<CurrencyFormat value={item.advance} displayType={'text'} thousandSeparator={true}/>}</td>


                            <td>{item.last_paydate}</td>
                            <td>{item.expiry_due}</td>

                            <td>{item.days_in_arrears}</td>
                            <td>{item.aging_category}</td>

                            <td>{item.loan_period}</td>
                            <td className="align-right">{<CurrencyFormat value={item.savings_balance} displayType={'text'} thousandSeparator={true}/>}</td>


                            </tr>

                            {
                              this.state.show_details===true && Number(this.state.selected_id)===Number(item.id)?
                              <tr >
                                 <td colSpan={20}>
                                  {/* Your expanded content goes here */}
                                  <div>
                                    
                                    {
                                    this.state.show_details===true?
                                    <LoanExtras loan_id={item.id} loan_issueobj={item} />
                                    :
                                    null
                                    }

                                  </div>
                                </td>

                              </tr>
                              :
                              null
                            }
                            </React.Fragment>
                          ))}



                          {/**Totals */}
                          <tr>
                          <td style={{fontWeight:'bolder'}}>Totals</td>
                          <td></td>
                          <td></td>

                          <td></td>
                          <td></td>

                          <th className="align-right"><CurrencyFormat value={this.calculateTotalofPrinciple()} displayType={'text'} thousandSeparator={true}/></th>
                          <td></td>
                          <th className="align-right"><CurrencyFormat value={this.calculate_installmentexpetedTotal()} displayType={'text'} thousandSeparator={true}/></th>

                          <th className="align-right"><CurrencyFormat value={this.calculateTotal_expectedbal()} displayType={'text'} thousandSeparator={true}/></th>

                          <th className="align-right"><CurrencyFormat value={this.calculate_actual_loan_balance()} displayType={'text'} thousandSeparator={true}/></th>
                          <th className="align-right"><CurrencyFormat value={this.calculate_arrears()} displayType={'text'} thousandSeparator={true}/></th>
                        
                          <th className="align-right"><CurrencyFormat value={this.calculate_interest_arrears()} displayType={'text'} thousandSeparator={true}/></th>
                          <th className="align-right"><CurrencyFormat value={this.calculate_total_arrears()} displayType={'text'} thousandSeparator={true}/></th>
                          
                          <th className="align-right"><CurrencyFormat value={this.calculate_advance()} displayType={'text'} thousandSeparator={true}/></th>

                        
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>

                          </tr>
                          
                      </tbody>
                  </reactstrp.Table>      
               </div>
                   
              </Panel>
             </Collapse>
            </div>
            </TabPane>

            <TabPane tab={"Loan Aging Summary"} key="4">
              <LoanAgingStatistics />
            </TabPane>

          </Tabs>
  
      )        

      }
   }
}

export default LoanRepaymentStatusReport; 
