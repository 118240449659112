import React,{ useRef } from 'react';
import axios from 'axios'
import { Tooltip,Popover,Result,Table,Popconfirm,Input, Button,Collapse,Card,Avatar,Modal,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { PrinterOutlined, MinusOutlined, CloseCircleFilled, CheckCircleOutlined, UserOutlined } from '@ant-design/icons';
import { DeleteOutlined,SearchOutlined,CameraOutlined,PlusOutlined,EditOutlined,PlusSquareFilled,SaveOutlined,LoadingOutlined } from '@ant-design/icons';
import QrReader from 'react-qr-reader'
import uuid from 'react-uuid'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
import PrintTemplate from 'react-print';
import ReactToPrint from 'react-to-print';
import {
  Col,
  Row,
} from 'reactstrap';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import * as reactstrp from 'reactstrap';
import { ToWords } from 'to-words';
import { array } from 'prop-types';

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { Title } = Typography;
const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

const toWords = new ToWords();

message.config({
  top: 200,
  duration: 2,
  maxCount: 3,
  rtl: true,
});


var token= ''
var sacco= ''
var username=''


class LoanRepaymentDirectView extends React.Component {

  state = {
    date:moment().format(dateFormat).toString(),
    selectedaccount:'',
    selectedaccountobject:{},
    members:[],
    datarequested:true,
    slipnumber:'',
    paidinby:'',
    transactorcontact:'',
    availableloans:[],
    datasubmittedsuccessfully:false,
    lastslipnumber:0,
    selectedloan:'',
    loanschedules:[],
    principleamount:0,
    fine:0,
    debitaccount:'',
    disabledamount:false,
    selectedloanobject:{},

    totalprincipletobepaid:0,
    totalinteresttobepaid:0,
    totalprinciplepaid:0,
    totalinterestpaid:0,

    interestamount:0,
    loanaccountcreditingmethod:'directcashcrediting',
    companyaccounts: [],
    debited_account:'',
    creditloanaccount:'no',
    waive_interest:false,


    intrest_due:0,
    principle_due:0,
    interest_balance:0,
    principle_balance:0,
    cycles_due:0,

    installment_amount:0,
    current_loanproperties:{},
    memberperiodic_balanceobj:{}


  }

  callback(key) {
    console.log(key);
  }

  componentDidMount(){
    if(localStorage.getItem("sacco")){
      sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
      username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
   
   }else{
      sacco= ''
      username=''
   }
   
   if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/members_withaccounts/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          members:res.data
        })
       // this.setState({datarequested:false})
    })


    axios.get(serverconfig.backendserverurl+`/api/companyaccounts/?sacco=${sacco}`)
    .then(res => {
        this.setState({
            companyaccounts:res.data
        })
        this.setState({datarequested:false})
    })

  }

  ////////////////////////////////////
  // PRODUCT SEARCH SELECT METHODS
   onBlur=()=> {
    console.log('blur');
  }
  
   onFocus=()=>{
    console.log('focus');
  }
  
  onSearch=(val)=> {
    console.log('search:', val);
  }

    //check empty objecty
    isEmptyObject=(obj)=>{
      for ( var name in obj ) {
        return false;
      }
      return true;
    }

  //calculate Netwithdraw
  calculateNetWithdraw=()=>{
    var netwithdraw=0
    var minimumaccountbal=Number(this.state.selectedaccountobject.minimum_account_balance)
    var loan_security=Number(this.state.memberperiodic_balanceobj.savingsloan_security_balance)

    netwithdraw=Number(this.state.memberperiodic_balanceobj.savings_balance)-(minimumaccountbal+loan_security)

    return netwithdraw;
  }



  handleselectedaccountChange= (e) =>{

    let form_data1 = new FormData();
    form_data1.append('date',this.state.date);
    form_data1.append('member_id', e);
    axios.post(serverconfig.backendserverurl+`/customqueries/get_runningbalancesbydate`, form_data1, {
    headers: {
      'content-type': 'multipart/form-data'
    }
    })
    .then(res => {
        this.setState({
          memberperiodic_balanceobj:res.data.my_balance
        })
    })


    this.setState({ selectedaccount: e});
    //make query for last transactions
    axios.get(`${serverconfig.backendserverurl}/api/members/${e}`)
      .then(res => {  
          this.setState({selectedaccountobject: res.data})
      })

      /*
      axios.get(serverconfig.backendserverurl+`/customqueries/get_memberactive_loans/${e}`)
      .then(res => {
          this.setState({
            availableloans:JSON.parse(res.data.report)
          })
      })*/

      let form_data1_1 = new FormData();
      form_data1_1.append('date',this.state.date);
      form_data1_1.append('selectedmemberid', e);
      
      axios.post(serverconfig.backendserverurl+`/customqueries/get_memberactive_loans_bydate`, form_data1_1, {
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
      .then(res => {
          this.setState({
            availableloans:JSON.parse(res.data.report)
          })
      })


  }


  //handle client change
  handleselectedloanChange= (e) =>{
    this.setState({selectedloan:e});

     //query loan properties here
     let form_data = new FormData();
     form_data.append('loan_id', e);
     form_data.append('date', this.state.date);
    
     axios.post(serverconfig.backendserverurl+'/customqueries/getloanbalances_atparticulardate', form_data, {
       headers: {
         'content-type': 'multipart/form-data'
       }
       })
     .then(res => {
       this.setState({current_loanproperties:res.data.info})
       
     })
     .catch(error => console.log(error))

  }




  //submit the deposit now
  handleFormSubmit=(event) =>{

    if(Number(this.state.installment_amount)>this.calculateNetWithdraw() ){
      message.info("Less savings balance")
     }
     /*
     else if (Number(this.state.installment_amount)>Number(this.state.current_loanproperties.runningint_total_bal)){
      message.info("Installment cant be greater than running balance")

     }
     */
     else{

      this.setState({datarequested:true})
  
      let form_data = new FormData();
      form_data.append('totalloanamount', this.state.installment_amount);
      form_data.append('sacco', sacco);
      form_data.append('username', username);
      form_data.append('loanid', this.state.selectedloan);
      form_data.append('date', this.state.date);

      axios.post(serverconfig.backendserverurl+'/customqueries/directloanrepayment', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
        })
      .then(res => {
        this.setState({datarequested:false})
        message.info(res.data.message)

        this.setState({installment_amount:0})


        if (res.data.message==="Success"){
          this.setState({datasubmittedsuccessfully:true})
        }

      })
      .catch(error => console.log(error))
     }

  }


  ////////////////////////////////////////////
  // RENDERING METHOD HERE
  render() {

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      if(this.state.datasubmittedsuccessfully===true){
        return(
          <div>
        
            <Row >
                  <Col xs="12" sm="6" lg="6">
                    <Card>
                    <Result
                    status="success"
                    title="Successfully Made Loan Repayment "
                    subTitle="Please finish off transaction"
                    extra={[
                      <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
                    
                    ]}
                    />

                  </Card>
                  </Col>

            </Row>


          </div>

        )

      }else{
        return(
          <div>
            <div>
  
              <Row >
                  <Col xs="12" sm="6" lg="12">
                    <Card>
                    <h5 style={{fontWeight:'bolder',color:'#154360'}}>Member Loan Repayment (Savings to loan transfer)</h5>

                    <FormItem label="Date" >
                        <DatePicker 
                        onChange={(date, dateString)=>{this.setState({ date: dateString})}} 
                        format={dateFormat} />
                    </FormItem>


                    <FormItem label=" Select Member Account">
                        <Select 
                        placeholder="Account No | Name" 
                        style={{  }} 
                        value={this.state.selectedaccount}
                        onChange={this.handleselectedaccountChange} 
                        showSearch
                        optionFilterProp="children"
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        onSearch={this.onSearch}
                                             
                        >
                          {this.state.members.map(
                            (accnt)=>(
                              <Option value={accnt.id}>{accnt.AccountNumber} | {accnt.FullName}</Option>
                            ))}
                        </Select>
                    </FormItem>

                    
                    <FormItem label="Select Loan to Repay">
                        <Select 
                        placeholder="Select Loan to Repay" 
                        style={{  }} 
                        value={this.state.selectedloan}
                        onChange={this.handleselectedloanChange} 
                        showSearch
                        optionFilterProp="children"
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        onSearch={this.onSearch}
                                              
                        >
                          {this.state.availableloans.map(
                            (loan)=>(
                              <Option value={loan.id}>Amount {loan.approved_amount} | Date {loan.date} | Months {loan.loan_period}</Option>
                            ))}
                        </Select>
                      </FormItem>
                      <br></br>

                    <div style={{display:'flex',flexDirection:'row'}}>
                    <div style={{display:'flex',flexDirection:'column',margin:10}}>
                      <h3 style={{fontWeight:'bold',color:'#154360'}}>Account Details</h3>
                      <h4>Account Number:{this.state.selectedaccountobject.AccountNumber}</h4>
                      <h4>Account Contact:{this.state.selectedaccountobject.RegisteredPhoneNumber1}</h4>
                      <h4>Account Name:{this.state.selectedaccountobject.FullName}</h4>
                      <h4>Savings account Balance:<CurrencyFormat value={this.state.memberperiodic_balanceobj.savings_balance} displayType={'text'} thousandSeparator={true}  /> </h4>
                      <h4>Status:{this.state.selectedaccountobject.account_status}</h4>

                      </div>

                      <div style={{display:'flex',flexDirection:'column',margin:10,borderWidth:2,borderRadius:10,borderColor:'black'}}>
                      <h3 style={{fontWeight:'bold',color:'#154360'}}>Loan Details</h3>
                      
                      {
                        this.isEmptyObject(this.state.current_loanproperties)===false?
                        <div >
                        <h4>Running interest balance as at {this.state.date} : <CurrencyFormat  value={this.state.current_loanproperties.runningint_balance} displayType={'text'} thousandSeparator={true} /> </h4>
                        <h4>Running principle balance as at {this.state.date} : <CurrencyFormat  value={this.state.current_loanproperties.runningprinciple_balance} displayType={'text'} thousandSeparator={true} /> </h4>
                        <h4>Total running balance as at {this.state.date} : <CurrencyFormat  value={this.state.current_loanproperties.runningint_total_bal} displayType={'text'} thousandSeparator={true} /> </h4>

                        </div>
                        :
                        null
                      }
                      </div>

                    </div>
  
                    <br></br>

                    <h3 style={{fontWeight:'bold',alignSelf:'center',color:'#154360'}}>LOAN REPAYMENT FORM (FROM SAVINGS)</h3>
                    <Form
                      onFinish={(event) => this.handleFormSubmit(event)}
                    >

                    <FormItem label={<h4 style={{fontWeight:'bold'}}>Installment Amount:</h4>}>
                      <Input  type='number' placeholder="Amount" value={this.state.installment_amount} 
                      
                      onChange={(val)=>{
                        console.log(val.target.value)
                        this.setState({installment_amount:val.target.value})

                      }}/>
     
                    </FormItem>


                    <FormItem>
                      <Button  type="primary" htmlType="submit">Submit</Button>
                    </FormItem>
                  </Form>
  
                  </Card>
                  
                </Col>
  
            </Row>
  
            </div>
  
       </div>              
                      
        )
      }

    }
   
  }
}

export default LoanRepaymentDirectView; 
