import React,{ useRef } from 'react';
import axios from 'axios'
import { Table, Input, Button,Collapse,Avatar,Modal,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Spin,Switch,Tabs } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { PrinterOutlined, MinusOutlined, CloseCircleFilled, CheckCircleOutlined, UserOutlined } from '@ant-design/icons';
import { SearchOutlined,CameraOutlined,PlusOutlined,LoadingOutlined,DownloadOutlined } from '@ant-design/icons';
import QrReader from 'react-qr-reader'
import uuid from 'react-uuid'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
import PrintTemplate from 'react-print';
import ReactToPrint from 'react-to-print';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import * as reactstrp from 'reactstrap';

import {
  Col,
  Row,
} from 'reactstrap';
import ReactExport from "@ibrahimrahmani/react-export-excel";

var CryptoJS = require("crypto-js");


const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { Title } = Typography;
const { RangePicker } = DatePicker;
const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { TabPane } = Tabs;
const { TextArea } = Input;

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;



var token= ''
var sacco= ''
var bizuserid= ''


class ReportToPrint extends React.Component {

    state = {
      companyprofile:{}
    };
  
    componentDidMount(){
      if(localStorage.getItem("sacco")){
         sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
         bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
      
      }else{
         sacco= ''
         bizuserid= ''
      }
      
      if(localStorage.getItem("token")){
         token= localStorage.getItem("token")
      }else{
         token= ''
      }


      axios.defaults.headers={
        "Content-Type":"application/json",
        Authorization:`Token ${token}`
      }

      axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
      .then(res => {  
          this.setState({
            companyprofile: res.data
          })
  
      })
  
    }
  
    render() {
      return (
        <div style={{padding:20}}>
      <Row> 
                <Col xs="2" sm="2" lg="2">
                <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.sacco_logo} />
              
                  </Col>
                  <Col xs="6" sm="6" lg="6">
                  <h3>{this.state.companyprofile.sacco_name}</h3>
                  <h5>{this.state.companyprofile.companydealership}</h5>
                  <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                  {
                  this.state.companyprofile.RegisteredPhoneNumber2==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber2
                  }
                  {
                  this.state.companyprofile.RegisteredPhoneNumber3==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber3
                  }
                  </h6>               
                  <h5>Email: {this.state.companyprofile.company_email}</h5>
                  <h5>Wesite: {this.state.companyprofile.website}</h5>
                  <h5>{this.state.companyprofile.box_number}</h5>

                  </Col>

           </Row>

             <h3 style={{display: 'flex',justifyContent:'center', alignItems:'center',fontWeight:'bolder'}}>DIVIDENDS REPORT FROM: {this.props.dateone}  TO: {this.props.datetwo}</h3>

             <h4>INCOMES </h4>
            <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>Income Category</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                      {this.props.incomes.map(
                        (item)=>(
                          <tr>
                          <td>{item.incomecategory}</td>
                          <td>{<CurrencyFormat value={item.totalPaid} displayType={'text'} thousandSeparator={true}/>}</td>
                          </tr>
                        ))}
                        <tr>
                        <td style={{fontWeight:'bolder'}}>Total</td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.props.totalincomes} displayType={'text'} thousandSeparator={true}/>}</td>
                        </tr>

                    </tbody>
                </reactstrp.Table>   

            <br></br> 
            <Divider style={{borderColor:'red'}} dashed />
            <h4>EXPENSES</h4>

            <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>Expense Category</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                      {this.props.expenses.map(
                        (item)=>(
                          <tr>
                          <td>{item.expensecategory}</td>
                          <td>{<CurrencyFormat value={item.totalPaid} displayType={'text'} thousandSeparator={true}/>}</td>
                          </tr>
                        ))}
                        <tr>
                        <td style={{fontWeight:'bolder'}}>Total</td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.props.totalexpenses} displayType={'text'} thousandSeparator={true}/>}</td>
                        </tr>

                        <tr>
                        <td style={{fontWeight:'bolder',fontSize:20}}>SURPLUS</td>
                        <td style={{fontWeight:'bolder',fontSize:20}}>{<CurrencyFormat value={this.props.surplus} displayType={'text'} thousandSeparator={true}/>}</td>
                        </tr>

                    </tbody>
                </reactstrp.Table> 

                <br></br>
                <h3>Generated by: {CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)}</h3>
                <h3>Powered by: www.pitech.co.ug</h3>   
        </div>
      );
    }
  }



class IncomeStatementsDividendsReport extends React.Component {

  state = {
   expenses:[],
   incomes:[],
   dateone:'',
   datetwo:'',
   totalincomes:0,
   totalexpenses:0,
   surplus:0,
   datarequested:false,
   auditednetprofit:0,
   sharedmemebers:[],
   distributionmode:'deposittosavings',

   sharablepercentageofprofit:0,
   send_email:false,
   send_sms:false,
   Payable_dividend_per_share_worked_daily:0,

   deduct_tax:false,
   tax_percentage:0,
   companyaccounts: [],

   show_txns:false,
   memberTransactions:[],
   selected_item:{},
   transaction_details:''

  };

  componentDidMount(){
      if(localStorage.getItem("sacco")){
        sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
        bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    
    }else{
        sacco= ''
        bizuserid= ''
    }
    
    if(localStorage.getItem("token")){
        token= localStorage.getItem("token")
    }else{
        token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/companyaccounts/?sacco=${sacco}`)
    .then(res => {
        this.setState({
            companyaccounts:res.data
        })
        this.setState({datarequested:false})
    })

  }

  handledatechange= (date, dateString) =>{
    this.setState({ dateone: dateString[0]});
    this.setState({ datetwo: dateString[1]});
  } 

  //search incomes method
  getstatement=()=>{

  if (this.calculateNetpayout()<=0){
    message.error("Sharable value cant be less or equal to zero")
  }else if (this.state.dateone==='' || this.state.datetwo==="")
  message.error("Please select period")
  
  else{


    let form_data = new FormData();
    form_data.append('dateone', this.state.dateone);
    form_data.append('datetwo', this.state.datetwo);
    form_data.append('sacco', sacco);
    form_data.append('sharableamount', this.calculateNetpayout());
 
    if(this.state.dateone===''||this.state.datetwo===''){
     alert("Please are dates missing")
    }else{
     this.setState({datarequested:true})
     
        //Now submit sale data to database
        axios.post(serverconfig.backendserverurl+'/customqueries/getincomestatementdividends', form_data,{
         headers: {
           'content-type': 'multipart/form-data'
         }
          })
          .then(res =>{
              this.setState({datarequested:false})
              this.setState({sharedmemebers:JSON.parse(res.data.sharedmemebers)})
              this.setState({Payable_dividend_per_share_worked_daily:res.data.PDPSWD_i})
          } 
          )
          .catch(error => console.log(error)) 
    }

  }


   }

   calculateNetpayout=()=>{
    var total=0
    total=(this.state.sharablepercentageofprofit/100)*this.state.auditednetprofit
    return total;
   }

   calculateTotalPayout=()=>{
    var total=0

    this.state.sharedmemebers.map((item)=>{
      total+=Number(item.pyout)
    })
    return total;
   }


  ////////////////////////////////////////////
  // RENDERING METHOD HERE
  render() {
      
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin size="large">
              <div className="content">
                <h3>Pisacco</h3>
              </div>
               </Spin>        
        </div>
      )

    }else{
      return(
        <div>

          <div style={{display: "none"}}>
             &nbsp;&nbsp;
            <ReportToPrint
            totalincomes={this.state.totalincomes} 
            totalexpenses={this.state.totalexpenses} 
            surplus={this.state.surplus} 
            dateone={this.state.dateone}
            datetwo={this.state.datetwo}
            incomes={this.state.incomes}
            expenses={this.state.expenses}
            ref={el => (this.componentRef = el)} /> 

          </div>

          <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header="DIVIDENDS CALCULATOR" key="1">
            <Form  layout="inline" >
                <FormItem label="Date Range (Financial Year)">
                    <RangePicker defaultValue={moment()} onChange={this.handledatechange} format={dateFormat} />
                </FormItem>

                <FormItem label="Audited Net Profit"      
                >
                    <InputNumber
                      style={{ width: 300 }} 
                      defaultValue={0.0}
                      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={value => value.replace(/\x\s?|(,*)/g, '')}
                      onChange={(val)=>{this.setState({auditednetprofit:val})}}
                    />
                </FormItem>

                <FormItem label="Sharable Percentage Of Profit"      
                >
                  <Input name="" placeholder="0.00" value={this.state.sharablepercentageofprofit}
                  
                  onChange={(val)=>{this.setState({sharablepercentageofprofit:val.target.value}) }} />
                </FormItem>

                <FormItem>
                <Button onClick={this.getstatement} type="primary" htmlType="button">Compute</Button>
                </FormItem> 

           </Form>


          <h3>Net Sharable: {this.calculateNetpayout()}</h3>
          
            <Divider style={{borderColor:'red'}} dashed />
            <h4> MEMBER SHARABLE PERCENTAGES</h4>
                <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>Account Number</th>
                          <th>Account Name</th>
                          <th>Share numbers</th>
                          <th>Pay Value</th>
                          <th>Txns</th>

                        </tr>
                      </thead>
                      <tbody>
                      {this.state.sharedmemebers.map(
                        (item)=>(
                          <tr>
                          <td>{item.accountnumber}</td>
                          <td>{item.accountname}</td>
                          <td>{item.sharesnumber_balance}</td>
                          <td>
                          <CurrencyFormat value={Number(item.pyout).toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                          </td>
                          <td><Button type='primary' onClick={()=>{
                                this.setState({memberTransactions:item.award_list})
                                this.setState({selected_item:item})
                                this.setState({show_txns:true})
                              }}>View</Button>
                          </td>
                          </tr>
                        ))}

                      <tr>
                      <td>Total</td>
                      <td></td>
                      <td></td>
                      <td>
                      <CurrencyFormat value={this.calculateTotalPayout().toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                      </td>
                      </tr>
              
                    </tbody>
                </reactstrp.Table> 
            {
              this.state.sharedmemebers.length>0?
              <h3>Payable dividend for share's work done daily:<CurrencyFormat value={this.state.Payable_dividend_per_share_worked_daily!=undefined?this.state.Payable_dividend_per_share_worked_daily.toFixed(2):0} displayType={'text'} thousandSeparator={true}/> </h3>
              :
              null
            }

          <div style={{display:'flex',justifyContent:'flex-end',alignSelf:'flex-end',alignItems:'flex-end'}}>

        <reactstrp.Table bordered>
              <thead>
                <tr>

                  <th>
                  <FormItem 
                    label="Dividends Distribution Mode"
                   
                    >
                        <Select 
                        value={this.state.distributionmode} 
                        onChange={(val)=>{this.setState({distributionmode:val})}}
                        placeholder="Dividend Distribution Mode" >
                              <Option value='deposittosavings'>Deposit to Savings</Option>
                              <Option value='deposittodividendscumulativeaccount'>Deposit to dividends cumulative account</Option>
                        </Select>
                    </FormItem>


                    <FormItem label="Send email?" >
                          <Switch
                              checked={this.state.send_email}
                              onChange={val=>{this.setState({send_email:val})}}
                              checkedChildren="True"
                              unCheckedChildren="False"
                              />
                      </FormItem>


                    <FormItem label="Send sms?" >
                          <Switch
                              checked={this.state.send_sms}
                              onChange={val=>{this.setState({send_sms:val})}}
                              checkedChildren="True"
                              unCheckedChildren="False"
                              />
                      </FormItem>

                  <FormItem label="Deduct tax? From each members interest amount" >
                      <Switch
                          checked={this.state.deduct_tax}
                          onChange={val=>{this.setState({deduct_tax:val})}}
                          checkedChildren="True"
                          unCheckedChildren="False"
                          />
                  </FormItem>

                  {
                    this.state.deduct_tax?
                    <FormItem label={<h6 style={{fontWeight:'bold'}}>Tax percentage </h6>}>
                        <Input name="tax_percentage" 
                          placeholder="0"
                           value={this.state.tax_percentage}
                          onChange={(e) =>{
                            this.setState({ tax_percentage: e.target.value})  
                          }
                        } />
                    </FormItem>
                    :
                    null
                  }

                <FormItem label={<h6 style={{fontWeight:'bold'}}>Transaction details </h6>}>
                  
                    <TextArea
                          placeholder="Transaction Details"
                          autoSize={{ minRows: 2, maxRows: 8 }}
                          value={this.state.transaction_details}
                          onChange={(e) =>{
                            this.setState({ transaction_details: e.target.value})  
                          }
                        }            
                     />

                </FormItem>


                    <FormItem>
                    <Button onClick={()=>{

                  if (this.state.sharedmemebers.length===0){
                    message.error("receivers list cant be empty")
                  } if(this.state.deduct_tax===true && this.state.tax_percentage<=0){
                    message.error("Tax percentage cant be less or equal to zero")

                  }else if (this.state.transaction_details===""){
                    message.error("Transaction details cant be blank")
                  }else{

                      let form_data = new FormData();
                      form_data.append('distributionmode', this.state.distributionmode);
                      form_data.append('sacco', sacco);
                      form_data.append('sharedmemebers',JSON.stringify(this.state.sharedmemebers));
                      form_data.append('date', moment().format(dateFormat).toString());
                      form_data.append('send_email', this.state.send_email);
                      form_data.append('bizuserid', bizuserid);
                      form_data.append('sharableamount', this.calculateNetpayout());
                      form_data.append('dateone', this.state.dateone);
                      form_data.append('datetwo', this.state.datetwo);
                      form_data.append('send_sms', this.state.send_sms);

                      form_data.append('deduct_tax', this.state.deduct_tax);
                      form_data.append('tax_percentage', this.state.tax_percentage);
                      form_data.append('transaction_details', this.state.transaction_details);

                      this.setState({datarequested:true})
                      //Now submit sale data to database
                      axios.post(serverconfig.backendserverurl+'/customqueries/dividenddistribution', form_data,{
                      headers: {
                        'content-type': 'multipart/form-data'
                      }
                        })
                        .then(res =>{
                            this.setState({datarequested:false})
                            window.location.reload(false)
                        }).catch(error => console.log(error)) 
                      }

                    }} type="primary" htmlType='button'>Distribute Dividends</Button>
                     </FormItem>

                  </th>

                </tr>
              </thead>
              <tbody>

              </tbody>
          </reactstrp.Table> 
          </div>

          </Panel>
          </Collapse>


          <Modal    
              visible={this.state.show_txns}
              title="Periodic shares transactions."
              onCancel={()=>{this.setState({show_txns:false})}}
              footer={[
                <Button key="back" onClick={()=>{this.setState({show_txns:false})}}>
                  Cancel
                </Button>
                ]}
                width={1000}
                >
                  {
                    this.state.memberTransactions.length>0?
                    <div>

                    <ExcelFile filename={"Pisacco - Cumulative dividends award for "+this.state.selected_item.accountname+" from "+this.state.dateone+" to "+this.state.datetwo}  element={<Button type='primary' icon={<DownloadOutlined/>}>Download (Excel)</Button>}>
                      <ExcelSheet data={this.state.memberTransactions} name={"Financial statement report"}>
                          <ExcelColumn label="Date" value="date"/>
                          <ExcelColumn label="Shares" value="shares_no"/>
                          <ExcelColumn label="No of days" value="no_ofdays"/>
                          <ExcelColumn label="Pay amount" value="date_pay"/>
                          <ExcelColumn label="Description" value="description"/>

                      </ExcelSheet>
                    </ExcelFile>

                    <br></br>
                    <br></br>

                      <reactstrp.Table bordered>
                          <thead>
                            <tr>
                              <th>Date</th>
                              <th>Shares</th>
                              <th>No of days</th>
                              <th>Pay amount</th>
                              <th>Description</th>

                            </tr>
                          </thead>
                          <tbody>
                          {this.state.memberTransactions.map(
                            (item)=>(
                              <tr key={item.key}>
                              <td>{item.date}</td>
                              <td>{item.shares_no}</td>
                              <td>{item.no_ofdays}</td>
                              <td>{<CurrencyFormat value={item.date_pay.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                              <td>{item.description}</td>

                              </tr>
                            ))}
                          
                        </tbody>
                        </reactstrp.Table> 

                    </div>

                    :
                    null
                  }

            </Modal>

        </div>
        )

    }

  }
}

export default IncomeStatementsDividendsReport; 
