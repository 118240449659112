import React from 'react';
import { Form, Input, Button,Select,Spin,Switch,Tooltip,Tabs,Card,Image, message,Result,Modal,Popconfirm,Popover,DatePicker, Divider } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined,DownloadOutlined, MailOutlined,UploadOutlined,CloseCircleFilled } from '@ant-design/icons';
import moment from 'moment';
import {
  Col,
  Row,
} from 'reactstrap';
import * as reactstrp from 'reactstrap';
import uuid from 'react-uuid'
import CurrencyFormat from 'react-currency-format';
import LoanApplicationDetails from '../containers/LoanApplicationDetails';
import MemberDetail_InTab from '../containers/MemberDetailView_InTab';
import FinancialStatementReport_Member from '../containers/FinancialStatementReportView_Member';
import LoanApplication_Securities from '../containers/LoanApplication_Securities';
import LoanApplication_FileAttachmentDetailView from '../containers/LoanApplication_FileAttachmentDetailView';
import ApplicationSchedule from '../containers/ApplicationSchedule';
var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const dateFormat = 'DD/MM/YYYY';
const { TextArea } = Input;
const { TabPane } = Tabs;

var sacco= ''
var username=''
var token= ''

class LoanDetails_Remarks extends React.Component {

  state = {
    loanapplications:[],
    date:moment().format(dateFormat).toString(),
    applicant_location:false,
    own_premises:false,
    legal_status_of_business:false,
    duration_in_business:false,
    monthly_net_profit:false,
    guarantors:false,
    consents:false,
    report_details:'',
    loan_checklist:null,
    collateral_one:'',
    collateral_one_value:0,
    collateral_one_image:null,
    datarequested:true,
    loanapplicationobject:{},
    datasubmittedsuccessfully:false,
    collateralformvisible:false,
    collaterallist:[],

    companyprofile:{},
    loanassessments:[],

    loanreviews:[],
    loanapprovals:[],
    concluded_applications:[],

    verified_loans:[],

    loannote_receivers:{}

  }

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
   
    }else{
       sacco= ''
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }


    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
      this.setState({
        companyprofile: res.data
      })

    const loanapplicationID=this.props.loanapplicationID;
    axios.get(`${serverconfig.backendserverurl}/api/loanapplications/${loanapplicationID}`)
    .then(res => {  
        this.setState({loanapplicationobject: res.data})
        this.setState({datarequested:false})

    })

    ///////////////////////
    //get assessments
    //////////////////////
    axios.get(`${serverconfig.backendserverurl}/api/loanassessments/?loan_assessed=${loanapplicationID}`)
    .then(res => {  
        this.setState({loanassessments: res.data})
    })

    axios.get(`${serverconfig.backendserverurl}/api/loanapprovals/?loan_id=${loanapplicationID}`)
    .then(res => {  
        this.setState({loanapprovals: res.data})
    })


    axios.get(`${serverconfig.backendserverurl}/api/concluded_applications/?application=${loanapplicationID}`)
    .then(res => {  
        this.setState({concluded_applications: res.data})
        //this.setState({datarequested:false})
    })


    axios.get(`${serverconfig.backendserverurl}/api/verified_loans/?loan_verified=${loanapplicationID}`)
    .then(res => {  
        this.setState({verified_loans: res.data})
    })


    let form_data1 = new FormData();
    form_data1.append('sacco', sacco);
    axios.post(`${serverconfig.backendserverurl}/customqueries/get_loannote_receivers`, form_data1, {
      headers: {'content-type': 'multipart/form-data'}
      }).then(res =>{
        this.setState({loannote_receivers:res.data.data})
        this.setState({datarequested:false})

     });


  })

 
  }


  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{

        return (
          <div style={{overflowX:'auto'}}>

             <Tabs defaultActiveKey="000006" >

              <TabPane tab={"Entry Remarks ( "+this.state.concluded_applications.length+" )"} key="000006">
              {
                  this.state.concluded_applications.length>0?
                  <div>

                      <reactstrp.Table bordered>
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>User</th>
                            <th>Comments</th>
                            <th>To</th>
                            <th>Time Stamp</th>
                            <th>Proceed to NextStage (Yes/No)</th>

                          </tr>
                        </thead>
                        <tbody>
                        {this.state.concluded_applications.map(
                          (item)=>(
                            <tr>
                            <td>{item.date}</td>
                            <td>{item.user}</td>
                            <td>{item.report_details}</td>
                            <td>{JSON.stringify(this.state.loannote_receivers.loan_verifiers)}</td>
                            <td>{item.time_stamp}</td>
                            <td>{item.proceed_to_next_stage?"Yes":"No"}</td>

                            </tr>
                          ))}

                      </tbody>
                  </reactstrp.Table>
                  </div>
                  :
                  null
                }
                
              </TabPane>

              <TabPane tab={"Verifications ( "+this.state.verified_loans.length+" )"} key="0006">
              {
                  this.state.verified_loans.length>0?
                  <div>

                      <reactstrp.Table bordered>
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>User</th>
                            <th>Comments</th>
                            <th>To</th>
                            <th>Time Stamp</th>
                            <th>Proceed to NextStage (Yes/No)</th>

                          </tr>
                        </thead>
                        <tbody>
                        {this.state.verified_loans.map(
                          (item)=>(
                            <tr>
                            <td>{item.date}</td>
                            <td>{item.user}</td>
                            <td>{item.report_details}</td>
                            <td>{item.proceed_to_next_stage===true?JSON.stringify(this.state.loannote_receivers.loan_reviewers):JSON.stringify(this.state.loannote_receivers.loan_applicants)}</td>
                            <td>{item.time_stamp}</td>
                            <td>{item.proceed_to_next_stage?"Yes":"No"}</td>

                            </tr>
                          ))}

                      </tbody>
                  </reactstrp.Table>
                  </div>
                  :
                  null
                }
                
              </TabPane>

              <TabPane tab={"Reviews ( "+this.state.loanassessments.length+" )"} key="6">
                
              {
                  this.state.loanassessments.length>0?
                  <div>

                      <reactstrp.Table bordered>
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>User</th>
                            <th>Comments</th>
                            <th>To</th>
                            <th>Time Stamp</th>
                            <th>Proceed to NextStage (Yes/No)</th>

                          </tr>
                        </thead>
                        <tbody>
                        {this.state.loanassessments.map(
                          (item)=>(
                            <tr>
                            <td>{item.date}</td>
                            <td>{item.user}</td>
                            <td>{item.report_details}</td>
                            <td>{item.proceed_to_next_stage===true?JSON.stringify(this.state.loannote_receivers.loan_approvers): JSON.stringify(this.state.loannote_receivers.loan_verifiers)}</td>
                            <td>{item.time_stamp}</td>
                            <td>{item.proceed_to_next_stage?"Yes":"No"}</td>

                            </tr>
                          ))}

                      </tbody>
                  </reactstrp.Table>
                  </div>
                  :
                  null
                }
               
               
              </TabPane>

              <TabPane tab={"Approvals ( "+this.state.loanapprovals.length+" )"} key="7">

              {
                  this.state.loanapprovals.length>0?
                  <div>

                      <reactstrp.Table bordered>
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>User</th>
                            <th>Comments</th>
                            <th>To</th>
                            <th>Time Stamp</th>
                            <th>Proceed to NextStage (Yes/No)</th>

                          </tr>
                        </thead>
                        <tbody>
                        {this.state.loanapprovals.map(
                          (item)=>(
                            <tr>
                            <td>{item.date}</td>
                            <td>{item.user}</td>
                            <td>{item.approval_report}</td>
                            <td>{item.proceed_to_next_stage===true?JSON.stringify(this.state.loannote_receivers.loan_issuerers): JSON.stringify(this.state.loannote_receivers.loan_reviewers) }</td>
                            <td>{item.time_stamp}</td>
                            <td>{item.proceed_to_next_stage?"Yes":"No"}</td>

                            </tr>
                          ))}

                      </tbody>
                  </reactstrp.Table>
                  </div>
                  :
                  null
                }

              </TabPane>

            </Tabs>
          </div>
        );

      
    }

  }

}


export default LoanDetails_Remarks;



