import React from 'react';
import MemberForm from '../components/MemberForm'
import GroupForm from '../components/GroupForm'

import ActivateMemberForm from '../components/ActivateMemberForm'
import MemberImportForm from '../components/MemberImportForm'
import MemberTransactionsImportForm from '../components/MemberTransactionsmportForm'
import MembershipPaymentForm from '../components/MembershipPaymentForm'
import ApproveMemberForm from '../components/ApproveMemberForm'

import axios from 'axios'
import { Table, Input, Button,Collapse,Popover,Popconfirm,message,Descriptions,Modal,Spin,Card,Tabs,Tooltip } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,RollbackOutlined,DeleteOutlined,FundViewOutlined,EditOutlined,DownloadOutlined,WalletFilled,LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import {
  Col,
  Row,
} from 'reactstrap';
import ReactExport from "@ibrahimrahmani/react-export-excel";
import MemberList_Registered from './MemberListView_Registered';
import MemberList_UnRegistered from './MemberListView_UnRegistered';

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { TabPane } = Tabs;

function callback(key) {
  console.log(key);
}

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

var sacco= ''
var bizuserid= ''
var bizuserid= ''
var token= ''

class MemberList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    members: [],
    viewpaymentmodal:false,
    expensedetails:{},
    userprofile:{},
    datarequested:true,
    memberactivationformvisible:false,
    selectedmemberid:'',
    userrights:{},

    juniormembers: [],
    dormantaccounts: [],
    pendingaccounts:[],
    membsershippaymentvisible:false,
    memberaapprovalformvisible:false,
    approvedaccounts:[],
    rejectedaccounts:[],
    jointaccounts:[],
    ministryaccounts:[],

  };

  componentDidMount(){

    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
       bizuserid= ''
    }
    
    if(localStorage.getItem("bizuserid")){
       bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
     }else{
        bizuserid= ''
     }
    
     if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

   this.componentmountactions();
}


//component mount method
componentmountactions=()=>{
  this.setState({datarequested:false})
}


  render() {

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )
    }else{
      return(
        <div>
        <Tabs defaultActiveKey="2" onChange={callback}>

           <TabPane tab="Pending Members" key="1">
            <MemberList_UnRegistered />
          </TabPane>

          <TabPane tab="Registered Members" key="2">
            <MemberList_Registered />
          </TabPane>

          </Tabs>
 
        </div>
    )

    }

  }
}

export default MemberList; 
