import React, { Component, createRef } from 'react';

import axios from 'axios'
import { Tooltip,Popover,Result,Table,Popconfirm,Input,Tabs, Button,Collapse,Card,Avatar,Modal,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Spin,Switch } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { PrinterOutlined,SettingFilled, MinusOutlined, CloseCircleFilled, CheckCircleOutlined, UserOutlined } from '@ant-design/icons';
import { DeleteOutlined,SearchOutlined,CameraOutlined,PlusOutlined,EditOutlined,PlusSquareFilled,SaveOutlined,LoadingOutlined } from '@ant-design/icons';
import QrReader from 'react-qr-reader'
import uuid from 'react-uuid'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
import PrintTemplate from 'react-print';
import ReactToPrint from 'react-to-print';
import { useReactToPrint } from 'react-to-print';

import {
  Col,
  Row,
} from 'reactstrap';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import * as reactstrp from 'reactstrap';
import { ToWords } from 'to-words';
import JournalEntryView from './JournalEntryView'
import PrintComponent from './PrintComponent';

var converter = require('number-to-words');


var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { Title } = Typography;
const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

const toWords = new ToWords();
const { TabPane } = Tabs;

message.config({
  top: 200,
  duration: 2,
  maxCount: 3,
  rtl: true,
});


var token= ''
var sacco= ''
var username=''
var bizuserid= ''


class ReceiptToPrint extends React.Component {

  state = {
    companyprofile:{}
  };

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    
    }else{
       sacco= ''
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })
    })
  }


  render() {
    return (
      <div style={{padding:20}}>
        {
          this.props.thermalprint===true?
          <div >
          <p>*********************************************************</p>

          <h1 style={{fontWeight:'bolder',display:'flex',alignSelf:'flex-start'}}>  {this.state.companyprofile.sacco_name}</h1>
          <h2 style={{color:'black'}}>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
          {
          this.state.companyprofile.RegisteredPhoneNumber2==""?
          "":","+this.state.companyprofile.RegisteredPhoneNumber2
          }

          </h2>               
          <h2 style={{color:'black'}}>  Email: {this.state.companyprofile.company_email}</h2>
          <h2 style={{color:'black'}}>  Wesite: {this.state.companyprofile.website}</h2>
          <h2 style={{color:'black'}}>  {this.state.companyprofile.box_number}</h2>
          <h2 style={{color:'black'}}>  RECEIPT NO: {this.props.journal_number}</h2>
          <h2 style={{color:'black'}}>  DATE: {this.props.date}</h2>

          <p>*********************************************************</p>

          <h1 style={{fontWeight:'bolder',display:'flex',alignSelf:'flex-start'}}>  DEPOSIT RECEIPT</h1>

          <p>********************************</p>

          <h3 style={{color:'black'}}>
              ACC NUMBER: 
            {this.props.account.AccountNumber}
          </h3>

          <h3 style={{color:'black'}}>
              ACC NAME :
            {this.props.account.FullName}
          </h3>

          <h3 style={{color:'black'}}>
              DEPOSITED AMOUNT:
            <CurrencyFormat  value={this.props.totalamount} displayType={'text'} thousandSeparator={','} />
          </h3>

          <h3 style={{color:'black'}}>
              In Words: 
            ({ this.props.amount_inwords})
          </h3>


          <h3 style={{color:'black'}} >
              PAID IN BY: 
            {this.props.paidinby}
          </h3> 

          <h3 style={{color:'black'}} >
              CONTACT:
            {this.props.transactorcontact}
          </h3> 

          <h3 style={{color:'black'}}>
              TELLER:  
            {username}
          </h3>                 

          <h3 style={{color:'black'}}>
              PAYMENT OF: {this.props.reason}
          </h3> 


          <p>********************************</p>

          <h4 style={{color:'black'}}>  Supported by PitechUgLtd </h4>
          <h4 style={{color:'black'}}>  ( www.pitech.co.ug ) </h4>

        </div>

          :

        <div>
            <Row> 
            <Col xs="2" sm="2" lg="2">
                <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.sacco_logo} />

              </Col>
              <Col xs="6" sm="6" lg="6">
              <h3>{this.state.companyprofile.sacco_name}</h3>
              <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
              {
              this.state.companyprofile.RegisteredPhoneNumber2==""?
              "":","+this.state.companyprofile.RegisteredPhoneNumber2
              }
        
              </h6>               
              <h5>Email: {this.state.companyprofile.company_email}</h5>
              <h5>Wesite: {this.state.companyprofile.website}</h5>
              <h5>{this.state.companyprofile.box_number}</h5>

              </Col>
            </Row>
            <h3 style={{fontWeight:'bolder',display:'flex',alignSelf:'center',alignContent:'center',justifyContent:'center'}}>DEPOSIT RECEIPT</h3>

            <reactstrp.Table bordered>
          <thead>
            <tr>
            <th>  ACC NUMBER</th>
              <th>{this.props.account.AccountNumber}</th>
            </tr>

            <tr>
              <th>ACC NAME</th>
              <th>{this.props.account.FullName}</th>
            </tr>

            <tr>
              <th>DEPOSITED AMOUNT</th>
              <th><CurrencyFormat  value={this.props.totalamount} displayType={'text'} thousandSeparator={true} /></th>
            </tr>

            <tr>
              <th>In Words   </th>
              <th>({ this.props.amount_inwords})</th>
            </tr>


            <tr >
              <th >PAID IN BY</th>
              <th >{this.props.paidinby}</th>
            </tr> 

            <tr >
              <th >SLIP NUMBER</th>
              <th >{this.props.journal_number}</th>
            </tr> 

            <tr >
              <th >CONTACT</th>
              <th >{this.props.transactorcontact}</th>
            </tr> 

            <tr >
              <th >TELLER</th>
              <th >{username}</th>
            </tr>                 

            <tr >
              <th >PAYMENT OF</th>
              <th >{this.props.reason}</th>
            </tr> 

          </thead>
        </reactstrp.Table> 

        </div>
        }


          
    </div>
    );
  }
}


class ReprintTransactionReceipt extends React.Component {

  state = {
    date:moment().format(dateFormat).toString(),
    selectedaccount:'',
    selectedaccountobject:{},
    datarequested:true,
    companyprofile:{},
    total_received:0,
    transaction_channel:'',
    thermalprint:false,

  }
  

  callback(key) {
    console.log(key);
  }




  componentDidMount(){
    this.componentRef = createRef();

    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    
    }else{
       sacco= ''
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
   }else{
      bizuserid= ''
   }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }


    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })

    //make query for last transactions
    axios.get(`${serverconfig.backendserverurl}/api/members/${this.props.transaction.transaction_account}`)
    .then(res => {  
        this.setState({selectedaccountobject: res.data})
        this.setState({datarequested:false})
    })



    //make qery now
    let form_data = new FormData();
    form_data.append('date',this.props.transaction.date);
    form_data.append('selectedmemberid', this.props.transaction.transaction_account);
    form_data.append('journalno', this.props.transaction.jnlno);
    form_data.append('sacco', sacco);

    axios.post(serverconfig.backendserverurl+'/customqueries/queryreversal_transactions', form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res => {
      this.setState({tobereversed_transactions:JSON.parse(res.data.txn_report)})
      this.setState({loan_txn_report:JSON.parse(res.data.loan_txn_report)})
      this.setState({general_ledger_entriesreport:JSON.parse(res.data.general_ledger_entriesreport)})

      this.setState({total_received:res.data.total_received})
      this.setState({transaction_channel:res.data.transaction_channel})




    })
    .catch(error => console.log(error))



  }



  ////////////////////////////////////////////
  // RENDERING METHOD HERE
  render() {

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
        return(
          <div>
          
            <div style={{display: "none"}}>
            &nbsp;&nbsp;
            <ReceiptToPrint
            account={this.state.selectedaccountobject} 
            totalamount={this.state.total_received} 
            amount_inwords={converter.toWords(this.state.total_received)}
            thermalprint={this.state.thermalprint}

            paidinby={this.props.transaction.transacted_by} 
            transactorcontact={this.props.transaction.transactor_contact}
            date={this.props.transaction.date}
            reason={this.props.transaction.transaction_details}
            journal_number={this.props.transaction.jnlno}
            transaction_channel={this.state.transaction_channel}

            ref={el => (this.componentRef = el)} 
            /> 
            </div> 

            <Card>
                    <Result
                    status="success"
                    title="Successfully Retrived Receipt "
                    subTitle="Please print receipt or finish off action"
                    extra={[
                      <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignSelf:'center'}}>
                      <FormItem >
                      <Switch
                        checked={this.state.thermalprint}
                        onChange={val=>{this.setState({thermalprint:val})}}
                        checkedChildren="Thermal print"
                        unCheckedChildren="A4 print (Click to change to thermal printer)"
                        />
                      </FormItem>

                      </div>

                      ,
                      <a>
                      <ReactToPrint
                        trigger={() =>                     
                          <div style={{}}>
                            <Tooltip title="Click to print" placement="top">
                            <p ><PrinterOutlined style={{ fontSize: '40px', color: '#08c' }}/></p>
                            <h6 >Print Receipt</h6>
                            </Tooltip>
                          </div>                          
                            }
                            content={() => this.componentRef}
                          />  
                          </a>,
                      <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
                    ]}
                    />

           </Card>
          </div>

        )

    }
   
  }
}

export default ReprintTransactionReceipt; 
