import React from 'react';
import { Form, Input, Button,Select,Checkbox,Upload,message,Spin } from 'antd';
import axios from 'axios'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';
import * as serverconfig from '../serverconn'
import Resizer from "react-image-file-resizer";

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''

class UserAccountUpdateForm extends React.Component {

  state = {
    userprofile:{},
    email:'',
    username:'',
    profilepic:null
  }

  componentDidMount(){
    var accountID=this.props.accountID

    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/accounts/${accountID}`)
    .then(res => {  
        this.setState({
          userprofile: res.data
        })

        this.setState({email:res.data.email})
        this.setState({username:res.data.username})

    })
 
  }


  //imgae resizer
resizeFile = (file) =>
new Promise((resolve) => {
  Resizer.imageFileResizer(
    file,
    300,
    400,
    "JPEG",
    50,
    0,
    (uri) => {
      resolve(uri);
    },
    "base64"
  );
});

//convert back to file
 dataURIToBlob = (dataURI) => {
  const splitDataURI = dataURI.split(",");
  const byteString =
    splitDataURI[0].indexOf("base64") >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(":")[1].split(";")[0];
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);
  return new Blob([ia], { type: mimeString });
};




  //submit button pressed
  onFinish=values =>{
    var accountID=this.props.accountID

    this.setState({datarequested:true})

    let form_data = new FormData();
    form_data.append('username', this.state.username);
    form_data.append('email', this.state.email);
    form_data.append('is_active', true);
    form_data.append('branch_manager', this.state.userprofile.branch_manager);
    form_data.append('is_admin', this.state.userprofile.is_admin);
    form_data.append('force_passwordchange', this.state.userprofile.force_passwordchange);
    form_data.append('is_staff', this.state.userprofile.is_staff);
    form_data.append('is_superuser', this.state.userprofile.is_superuser);

    this.state.profilepic===null?
     console.log("No profile file")
     :
     form_data.append('profile_pic', this.state.profilepic,this.state.profilepic?this.state.profilepic.name:"");

    //Now send the post resquest
      axios.put(`${serverconfig.backendserverurl}/api/accounts/${accountID}/`, form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res =>{
      this.setState({datarequested:false})
      message.info('User Profile Updated successfully');
     // localStorage.setItem('username',CryptoJS.AES.encrypt(values.username, 'my-secret-key@123').toString());

      //localStorage.setItem('username',values.username);
      window.location.reload(false);
    } 
    )
    .catch(error => console.log(error)) 

    };

     normFile = e => {
      console.log('Upload event:', e);
    
      if (Array.isArray(e)) {
        return e;
      }
    
      return e && e.fileList;
    };


    handleprofilepicChange= async(e) =>{
      const file = e.target.files[0];
      //send to resizer
      const image = await this.resizeFile(file);
      const newFile = this.dataURIToBlob(image);
      this.setState({ profilepic: newFile});
      console.log(newFile);

    } 


  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return (
        <div>
          <Form  
          onFinish={this.onFinish}        
          ref={this.formRef}>
  
            <FormItem 
            label="User Name"
            >
              <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Put a name here." value={this.state.username} onChange={(val)=>{this.setState({username:val.target.value})}} />
            </FormItem>
  
            <FormItem 
            label="Email"
            >
              <Input  type="mail" prefix={<MailOutlined className="site-form-item-icon" />}  placeholder="Put a name here." value={this.state.email} onChange={(val)=>{this.setState({email:val.target.value})}} />
            </FormItem>
  
            <FormItem label="Profile Image"
            name="profilepic">
                <Input  type="file" accept="image/png, image/jpeg" placeholder="Profile Image" onChange={this.handleprofilepicChange}/>
            </FormItem>
  
            <FormItem>
              <Button htmlType="submit" type="primary">Update</Button>
            </FormItem>
          </Form>
  
        </div>
        
      );


    }

   
  }

}

export default UserAccountUpdateForm;



