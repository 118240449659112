import React from 'react';
import { Form, Input, Button,Select,Spin,Switch,Tooltip,Tabs,Card,Image, message,Result,Modal,Popconfirm,Popover,DatePicker, Divider } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined,PrinterOutlined,DownloadOutlined, MailOutlined,UploadOutlined,CloseCircleFilled } from '@ant-design/icons';
import moment from 'moment';
import {
  Col,
  Row,
} from 'reactstrap';
import * as reactstrp from 'reactstrap';
import uuid from 'react-uuid'
import CurrencyFormat from 'react-currency-format';
import '../tablestyle.css'; // Create this CSS file for custom styles
import ReactToPrint from 'react-to-print';

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const dateFormat = 'DD/MM/YYYY';
const { TextArea } = Input;
const { TabPane } = Tabs;

var sacco= ''
var username=''
var token= ''
var bizuserid=''


class ReportToPrint extends React.Component {

  state = {
    companyprofile:{},
  };

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    
    }else{
       sacco= ''
       bizuserid= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })

  }

  calculateTotalofPrinciple=()=>{
    var  total=0

    this.props.loanissues.map((item)=>{
      total+=Number(item.principle)
    })

    return total.toFixed(2);
  }


  render() {
    return (
      <div style={{padding:20}}>
        <style>
        {`@media print {
          @page { size: portrait; }
        }`}
        </style>
        <Row> 
              <Col xs="2" sm="2" lg="2">
              <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.sacco_logo} />
            
                </Col>
                <Col xs="6" sm="6" lg="6">
                <h3>{this.state.companyprofile.sacco_name}</h3>
                <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                {
                this.state.companyprofile.RegisteredPhoneNumber2==""?
                "":","+this.state.companyprofile.RegisteredPhoneNumber2
                }
                {
                this.state.companyprofile.RegisteredPhoneNumber3==""?
                "":","+this.state.companyprofile.RegisteredPhoneNumber3
                }
                </h6>               
                <h5>Email: {this.state.companyprofile.company_email}</h5>
                <h5>Wesite: {this.state.companyprofile.website}</h5>
                <h5>{this.state.companyprofile.box_number}</h5>
              
                </Col>
     </Row>

       <h3 style={{display:'flex',alignSelf:'center',justifyContent:'center'}}>LOAN ISSUANCE CERTIFICATE</h3>
       <reactstrp.Table bordered>
                <thead>
                <tr>
                  <th>Member Details</th>
                  <th>FullName: {this.props.loanapplicationobject.accountName} , MemberID: {this.props.loanapplicationobject.accountNumber} </th>
                  </tr>

                  <tr>
                  <th>Loan Amount</th>
                  <th>
                   <CurrencyFormat value={Number(this.props.loanapplicationobject.loan_amount).toFixed(2)} displayType={'text'} thousandSeparator={true}  />

                  </th>
                  </tr>

                  <tr>
                  <th>Status</th>
                  <th> {this.props.loanapplicationobject.loan_status}</th>
                  </tr>



                  {/**Entry */}
                  <tr>
                    <th>Entry</th>
                    <th>
                    {
                    this.props.concluded_applications.length>0?
                    <div>

                      <reactstrp.Table bordered>
                        <thead>
                          <tr>
                            <th>Comments</th>
                            <th>To</th>
                            <th>Time Stamp</th>
                            <th>Proceed to NextStage (Yes/No)</th>
                            <th>Signature</th>
                          </tr>
                        </thead>
                        <tbody>
                        {this.props.concluded_applications.map(
                          (item)=>(
                            <tr>
                            <td>{item.report_details}</td>
                            <td>{JSON.stringify(this.props.loannote_receivers.loan_verifiers)}</td>
                            <td>{item.time_stamp}</td>
                            <td>{item.proceed_to_next_stage?"Yes":"No"}</td>
                            <td>{item.user}</td>

                            </tr>
                          ))}

                      </tbody>
                  </reactstrp.Table>
                  </div>
                  :
                  null
                }


                    </th>
                  </tr>

                  {/**Verification */}
                  <tr>
                    <th>Verification</th>
                    <th>
                    {
                      this.props.verified_loans.length>0?
                      <div>

                          <reactstrp.Table bordered>
                            <thead>
                              <tr>
                                <th>Comments</th>
                                <th>To</th>
                                <th>Time Stamp</th>
                                <th>Proceed to NextStage (Yes/No)</th>
                                <th>Signature</th>

                              </tr>
                            </thead>
                            <tbody>
                            {this.props.verified_loans.map(
                              (item)=>(
                                <tr>
                                <td>{item.report_details}</td>
                                <td>{item.proceed_to_next_stage===true?JSON.stringify(this.props.loannote_receivers.loan_reviewers):JSON.stringify(this.props.loannote_receivers.loan_applicants)}</td>
                                <td>{item.time_stamp}</td>
                                <td>{item.proceed_to_next_stage?"Yes":"No"}</td>
                                <td>{item.user}</td>

                                </tr>
                              ))}

                          </tbody>
                      </reactstrp.Table>
                      </div>
                      :
                      null
                    }


                    </th>
                  </tr>

                  {/** Review */}
                  <tr>
                    <th>Reviews</th>
                    <th>
                    {
                      this.props.loanassessments.length>0?
                      <div>

                          <reactstrp.Table bordered>
                            <thead>
                              <tr>
                                <th>Comments</th>
                                <th>To</th>
                                <th>Time Stamp</th>
                                <th>Proceed to NextStage (Yes/No)</th>
                                <th>Signature</th>

                              </tr>
                            </thead>
                            <tbody>
                            {this.props.loanassessments.map(
                              (item)=>(
                                <tr>
                                <td>{item.report_details}</td>
                                <td>{item.proceed_to_next_stage===true?JSON.stringify(this.props.loannote_receivers.loan_approvers): JSON.stringify(this.props.loannote_receivers.loan_verifiers)}</td>
                                <td>{item.time_stamp}</td>
                                <td>{item.proceed_to_next_stage?"Yes":"No"}</td>
                                <td>{item.user}</td>

                                </tr>
                              ))}

                          </tbody>
                      </reactstrp.Table>
                      </div>
                      :
                      null
                    }


                    </th>
                  </tr>

                  {/**Approvals */}
                  <tr>
                    <th>Approvals</th>
                    <th>
                    {
                      this.props.loanapprovals.length>0?
                      <div>

                          <reactstrp.Table bordered>
                            <thead>
                              <tr>
                                <th>Comments</th>
                                <th>To</th>
                                <th>Time Stamp</th>
                                <th>Proceed to NextStage (Yes/No)</th>
                                <th>Signature</th>

                              </tr>
                            </thead>
                            <tbody>
                            {this.props.loanapprovals.map(
                              (item)=>(
                                <tr>
                                <td>{item.approval_report}</td>
                                <td>{item.proceed_to_next_stage===true?JSON.stringify(this.props.loannote_receivers.loan_issuerers): JSON.stringify(this.props.loannote_receivers.loan_reviewers) }</td>
                                <td>{item.time_stamp}</td>
                                <td>{item.proceed_to_next_stage?"Yes":"No"}</td>
                                <td>{item.user}</td>

                                </tr>
                              ))}

                          </tbody>
                      </reactstrp.Table>
                      </div>
                      :
                      null
                    }

                    </th>
                  </tr>


                </thead>

              </reactstrp.Table>
 
         

       <br></br>
      <h3>Generated by: {CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)}</h3>
      <h3>Powered by: www.pitech.co.ug</h3>   
         
      </div>
    );
  }
}




class LoanCertificate extends React.Component {

  state = {
    loanapplications:[],
    date:moment().format(dateFormat).toString(),
    applicant_location:false,
    own_premises:false,
    legal_status_of_business:false,
    duration_in_business:false,
    monthly_net_profit:false,
    guarantors:false,
    consents:false,
    report_details:'',
    loan_checklist:null,
    collateral_one:'',
    collateral_one_value:0,
    collateral_one_image:null,
    datarequested:true,
    loanapplicationobject:{},
    datasubmittedsuccessfully:false,
    collateralformvisible:false,
    collaterallist:[],

    companyprofile:{},
    loanassessments:[],

    loanreviews:[],
    loanapprovals:[],
    concluded_applications:[],

    verified_loans:[],

    loannote_receivers:{}

  }

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
   
    }else{
       sacco= ''
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }


    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
      this.setState({
        companyprofile: res.data
      })

    const loanapplicationID=this.props.loanapplicationID;
    axios.get(`${serverconfig.backendserverurl}/api/loanapplications/${loanapplicationID}`)
    .then(res => {  
        this.setState({loanapplicationobject: res.data})
        this.setState({datarequested:false})

    })

    ///////////////////////
    //get assessments
    //////////////////////
    axios.get(`${serverconfig.backendserverurl}/api/loanassessments/?loan_assessed=${loanapplicationID}`)
    .then(res => {  
        this.setState({loanassessments: res.data})
    })

    axios.get(`${serverconfig.backendserverurl}/api/loanapprovals/?loan_id=${loanapplicationID}`)
    .then(res => {  
        this.setState({loanapprovals: res.data})
    })


    axios.get(`${serverconfig.backendserverurl}/api/concluded_applications/?application=${loanapplicationID}`)
    .then(res => {  
        this.setState({concluded_applications: res.data})
        //this.setState({datarequested:false})
    })


    axios.get(`${serverconfig.backendserverurl}/api/verified_loans/?loan_verified=${loanapplicationID}`)
    .then(res => {  
        this.setState({verified_loans: res.data})
    })


    let form_data1 = new FormData();
    form_data1.append('sacco', sacco);
    axios.post(`${serverconfig.backendserverurl}/customqueries/get_loannote_receivers`, form_data1, {
      headers: {'content-type': 'multipart/form-data'}
      }).then(res =>{
        this.setState({loannote_receivers:res.data.data})
        this.setState({datarequested:false})

     });


  })

 
  }



  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{

        return (
          <div style={{overflowX:'auto'}}>

              <div style={{display: "none",}}>
               &nbsp;&nbsp;
              <ReportToPrint
              concluded_applications={this.state.concluded_applications} 
              verified_loans={this.state.verified_loans} 
              loanassessments={this.state.loanassessments} 
              loanapprovals={this.state.loanapprovals} 
              loannote_receivers={this.state.loannote_receivers} 
              loanapplicationobject={this.state.loanapplicationobject} 

              ref={el => (this.componentRef = el)} /> 
             </div>
              <Divider></Divider>

              <ReactToPrint
                trigger={() => 
                <Button type="primary"  shape="round" icon={<PrinterOutlined />} size={this.state.size}>
                Print
                </Button>
                }
                content={() => this.componentRef}
                />


              <h3 style={{display:'flex',alignSelf:'center',justifyContent:'center'}}>LOAN ISSUANCE CERTIFICATE</h3>
              <Divider></Divider>


              <reactstrp.Table bordered>
                <thead>
                  <tr>
                  <th>Member Details</th>
                  <th>FullName: {this.state.loanapplicationobject.accountName} , MemberID: {this.state.loanapplicationobject.accountNumber} </th>
                  </tr>

                  <tr>
                  <th>Loan Amount</th>
                  <th>
                   <CurrencyFormat value={Number(this.state.loanapplicationobject.loan_amount).toFixed(2)} displayType={'text'} thousandSeparator={true}  />

                  </th>
                  </tr>

                  <tr>
                  <th>Status</th>
                  <th> {this.state.loanapplicationobject.loan_status}</th>
                  </tr>


                  {/**Entry */}
                  <tr>
                    <th>Entry</th>
                    <th>
                    {
                    this.state.concluded_applications.length>0?
                    <div>

                      <reactstrp.Table bordered>
                        <thead>
                          <tr>
                            <th>Comments</th>
                            <th>To</th>
                            <th>Time Stamp</th>
                            <th>Proceed to NextStage (Yes/No)</th>
                            <th>Signature</th>
                          </tr>
                        </thead>
                        <tbody>
                        {this.state.concluded_applications.map(
                          (item)=>(
                            <tr>
                            <td>{item.report_details}</td>
                            <td>{JSON.stringify(this.state.loannote_receivers.loan_verifiers)}</td>
                            <td>{item.time_stamp}</td>
                            <td>{item.proceed_to_next_stage?"Yes":"No"}</td>
                            <td>{item.user}</td>

                            </tr>
                          ))}

                      </tbody>
                  </reactstrp.Table>
                  </div>
                  :
                  null
                }


                    </th>
                  </tr>

                  {/**Verification */}
                  <tr>
                    <th>Verification</th>
                    <th>
                    {
                      this.state.verified_loans.length>0?
                      <div>

                          <reactstrp.Table bordered>
                            <thead>
                              <tr>
                                <th>Comments</th>
                                <th>To</th>
                                <th>Time Stamp</th>
                                <th>Proceed to NextStage (Yes/No)</th>
                                <th>Signature</th>

                              </tr>
                            </thead>
                            <tbody>
                            {this.state.verified_loans.map(
                              (item)=>(
                                <tr>
                                <td>{item.report_details}</td>
                                <td>{item.proceed_to_next_stage===true?JSON.stringify(this.state.loannote_receivers.loan_reviewers):JSON.stringify(this.state.loannote_receivers.loan_applicants)}</td>
                                <td>{item.time_stamp}</td>
                                <td>{item.proceed_to_next_stage?"Yes":"No"}</td>
                                <td>{item.user}</td>

                                </tr>
                              ))}

                          </tbody>
                      </reactstrp.Table>
                      </div>
                      :
                      null
                    }


                    </th>
                  </tr>

                  {/** Review */}
                  <tr>
                    <th>Reviews</th>
                    <th>
                    {
                      this.state.loanassessments.length>0?
                      <div>

                          <reactstrp.Table bordered>
                            <thead>
                              <tr>
                                <th>Comments</th>
                                <th>To</th>
                                <th>Time Stamp</th>
                                <th>Proceed to NextStage (Yes/No)</th>
                                <th>Signature</th>

                              </tr>
                            </thead>
                            <tbody>
                            {this.state.loanassessments.map(
                              (item)=>(
                                <tr>
                                <td>{item.report_details}</td>
                                <td>{item.proceed_to_next_stage===true?JSON.stringify(this.state.loannote_receivers.loan_approvers): JSON.stringify(this.state.loannote_receivers.loan_verifiers)}</td>
                                <td>{item.time_stamp}</td>
                                <td>{item.proceed_to_next_stage?"Yes":"No"}</td>
                                <td>{item.user}</td>

                                </tr>
                              ))}

                          </tbody>
                      </reactstrp.Table>
                      </div>
                      :
                      null
                    }


                    </th>
                  </tr>

                  {/**Approvals */}
                  <tr>
                    <th>Approvals</th>
                    <th>
                    {
                      this.state.loanapprovals.length>0?
                      <div>

                          <reactstrp.Table bordered>
                            <thead>
                              <tr>
                                <th>Comments</th>
                                <th>To</th>
                                <th>Time Stamp</th>
                                <th>Proceed to NextStage (Yes/No)</th>
                                <th>Signature</th>

                              </tr>
                            </thead>
                            <tbody>
                            {this.state.loanapprovals.map(
                              (item)=>(
                                <tr>
                                <td>{item.approval_report}</td>
                                <td>{item.proceed_to_next_stage===true?JSON.stringify(this.state.loannote_receivers.loan_issuerers): JSON.stringify(this.state.loannote_receivers.loan_reviewers) }</td>
                                <td>{item.time_stamp}</td>
                                <td>{item.proceed_to_next_stage?"Yes":"No"}</td>
                                <td>{item.user}</td>

                                </tr>
                              ))}

                          </tbody>
                      </reactstrp.Table>
                      </div>
                      :
                      null
                    }

                    </th>
                  </tr>


                </thead>

              </reactstrp.Table>


          </div>
        );

      
    }

  }

}


export default LoanCertificate;



