import React, { Component, createRef } from 'react';

import axios from 'axios'
import { Tooltip,Popover,Result,Table,Popconfirm,Input,Tabs, Button,Collapse,Card,Avatar,Modal,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Spin,Switch } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { PrinterOutlined,SettingFilled, MinusOutlined, CloseCircleFilled, CheckCircleOutlined, UserOutlined } from '@ant-design/icons';
import { DeleteOutlined,SearchOutlined,CameraOutlined,PlusOutlined,EditOutlined,PlusSquareFilled,SaveOutlined,LoadingOutlined } from '@ant-design/icons';
import QrReader from 'react-qr-reader'
import uuid from 'react-uuid'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
import PrintTemplate from 'react-print';
import ReactToPrint from 'react-to-print';
import { useReactToPrint } from 'react-to-print';

import {
  Col,
  Row,
} from 'reactstrap';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import * as reactstrp from 'reactstrap';
import { ToWords } from 'to-words';
import JournalEntryView from './JournalEntryView'
import PrintComponent from './PrintComponent';

var converter = require('number-to-words');

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { Title } = Typography;
const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

const toWords = new ToWords();
const { TabPane } = Tabs;

message.config({
  top: 200,
  duration: 2,
  maxCount: 3,
  rtl: true,
});


var token= ''
var sacco= ''
var username=''
var bizuserid= ''



class EndOfMonthLoanInterestForm extends React.Component {

  state = {
    date:moment().format(dateFormat).toString(),
    members:[],
    member_id:''
  }
  
  callback(key) {
    console.log(key);
  }



  componentDidMount(){
    this.componentRef = createRef();

    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    
    }else{
       sacco= ''
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
   }else{
      bizuserid= ''
   }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/members_withaccounts/?sacco=${sacco}`)
    .then(res => {

      //console.log(res.data)
      this.setState({
        members:res.data
      })

    })

      
  }



  ////////////////////////////////////////////
  // RENDERING METHOD HERE
  render() {

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      if(this.state.datasubmittedsuccessfully===true){
        return(
          <div>
         
            <Row >
                  <Col xs="12" sm="6" lg="6">
                    <Card>
                    <Result
                    status="success"
                    title="Successfully Transaction"
                    extra={[
                      <Button  type='primary'
                       onClick={()=>{window.location.reload(false)}}>
                       Finish
                       </Button>
                    ]}
                    />
                  </Card>
                  </Col>
            </Row>
          </div>

        )

              }else{
                return(
              
                    <Card>
                     <h3 style={{fontWeight:'bold',alignSelf:'center',color:'#154360'}}> END OF MONTH LOAN INTEREST CREATION</h3>
                    
                     <FormItem label="End of month dates">
                        <DatePicker 
                        onChange={(date, dateString) => this.setState({ date: dateString})} 
                        format={dateFormat} />
                     </FormItem> 

                     <Divider></Divider>
                     <FormItem label="Member">
                        <Select 
                        placeholder="Account No | Name" 
                        style={{  }} 
                        value={this.state.member_id}
                        onChange={(val)=>{this.setState({member_id:val})}} 
                        showSearch
                        optionFilterProp="children"
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        onSearch={this.onSearch}                    
                        >
                          {this.state.members.map(
                            (accnt)=>(
                              <Option value={accnt.id}>{accnt.AccountNumber} | {accnt.FullName}</Option>
                            ))}
                        </Select>
                    </FormItem>

                 <Divider></Divider>

                <FormItem>
                  <Button  
                  type="primary" 
                  htmlType="button"
                  onClick={()=>{

                     this.setState({datarequested:true})

                    let form_data1 = new FormData();
                    form_data1.append('date',this.state.date);
                    form_data1.append('sacco', sacco);
                    form_data1.append('member_id',this.state.member_id);

                    axios.post(serverconfig.backendserverurl+`/customqueries/calculate_endofmonthinterest`, form_data1, {
                    headers: {
                      'content-type': 'multipart/form-data'
                    }
                    })
                    .then(res => {
                      message.info(res.data.message)
                      this.setState({datarequested:false})

                     })

                    
                  }}
                  >
                    calculate Interest 
                  </Button>
                </FormItem>

             </Card>

        )
      }
     
    }
   
  }
}

export default EndOfMonthLoanInterestForm; 
