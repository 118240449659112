import React from 'react';

import axios from 'axios'
import { Table, Input, Button,Collapse,Popover,Popconfirm,message,Card,Tooltip,Descriptions,Modal,Spin,Tabs, Divider } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,PrinterOutlined,DeleteOutlined,FundViewOutlined,EyeFilled,WalletFilled,DownloadOutlined,LoadingOutlined,FilePdfFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import  LoanImportForm from '../components/LoanImportForm'
import {
  Col,
  Row,
} from 'reactstrap';
import * as reactstrp from 'reactstrap';
import ReactToPrint from 'react-to-print';
import ReactExport from "@ibrahimrahmani/react-export-excel";
import '../tablestyle.css'; // Create this CSS file for custom styles

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

function callback(key) {
  console.log(key);
}

const { TabPane } = Tabs;


var sacco= ''
var bizuserid= ''
var token= ''

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class ReceiptToPrint extends React.Component {

  state = {
    companyprofile:{}
  };

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })

  

  }

//calculate principleTotal
calculateScheduleTotal=()=>{
  var principleTotal=0
  this.props.loanschedule.map(
    (item)=>{
      principleTotal+=Number(item.principle)
    })

  return principleTotal.toFixed(2);
}

  //calculate principleTotal
  calculateInterestTotal=()=>{
    var InterestTotal=0
    this.props.loanschedule.map(
      (item)=>{
        InterestTotal+=Number(item.interest)
      })

    return InterestTotal.toFixed(2);
  }

  //calculate principleTotal
  calculateTotalPay=()=>{
    var TotalPay=0
    this.props.loanschedule.map(
      (item)=>{
        TotalPay+=Number(item.total)
      })

    return TotalPay.toFixed(2);
  }



  render() {
    return (
      <div style={{padding:20}}>
        <style>
          {`@media print {
          @page { size: landscape; }
            }`}
        </style>
            
      <Row> 
                <Col xs="2" sm="2" lg="2">
                <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.sacco_logo} />
              
                  </Col>
                  <Col xs="6" sm="6" lg="6">
                  <h3>{this.state.companyprofile.sacco_name}</h3>
                  <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                  {
                  this.state.companyprofile.RegisteredPhoneNumber2==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber2
                  }
                  {
                  this.state.companyprofile.RegisteredPhoneNumber3==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber3
                  }
                  </h6>               
                  <h5>Email: {this.state.companyprofile.company_email}</h5>
                  <h5>Wesite: {this.state.companyprofile.website}</h5>
                  <h5>{this.state.companyprofile.box_number}</h5>

                  </Col>

               </Row>
                   <h3 style={{fontWeight:'bolder',display: 'flex',justifyContent:'center', alignItems:'center'}}> LOAN SCHEDULE </h3>
                  <Divider></Divider>
                  <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-start'}}>
                      {/**Left */}
                    <div style={{width:'50%'}}>
                    <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> ACCOUNT NUMBER:  {this.props.application_obj.accountNumber} </h4>
                    <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> ACCOUNT NAME:  {this.props.application_obj.accountName} </h4>

                    <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}>LOAN OPENING BALANCE: <CurrencyFormat value={Number(this.props.application_obj.topup_loanbalance).toFixed(2)} displayType={'text'} thousandSeparator={true}/> </h4>
                    <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}>DISBURSED AMOUNT: <CurrencyFormat value={Number(this.props.application_obj.loan_topupamount)>0?Number(this.props.application_obj.loan_topupamount).toFixed(2):Number(this.props.application_obj.loan_amount).toFixed(2)} displayType={'text'} thousandSeparator={true}/> </h4>
                    <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}>TOTAL LOAN AMOUNT: <CurrencyFormat value={Number(this.props.application_obj.loan_amount).toFixed(2)} displayType={'text'} thousandSeparator={true}/> </h4>
                    </div>

                      {/**Right */}

                    <div style={{width:'50%'}}>
                      <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> PERIOD: {this.props.application_obj.approved_period} {this.props.application_obj.loan_primary_schedule==="daily"?"Days":this.props.application_obj.loan_primary_schedule==="weekly"?"Weeks":"Months"}</h4>
                     <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> ANNUAL INTEREST RATE: {this.props.application_obj.interestRate}</h4>
                     <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'end'}}> DATE OF ISSUANCE: {this.props.application_obj.date}</h4>

                    </div>

                  </div>
                  <Divider></Divider>


                  <reactstrp.Table bordered>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Date</th>
                            <th>Month</th>
                            <th>Principle Bf</th>
                            <th>Installment</th>
                            <th>Interest </th>
                            <th>Principle </th>
                            <th>Principle Bal</th>
                          </tr>
                        </thead>
                        <tbody>
                        {this.props.loanschedule.map(
                          (item)=>(
                            <tr>
                            <td>{item.repayment_number}</td>
                            <td>{item.date}</td>
                            <td>{item.payment_month}</td>
                            <td className="align-right">{<CurrencyFormat value={item.principlebroughtforward} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td className="align-right">{<CurrencyFormat value={item.total} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td className="align-right">{<CurrencyFormat value={item.interest} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td className="align-right">{<CurrencyFormat value={item.principle} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td className="align-right">{<CurrencyFormat value={item.balance} displayType={'text'} thousandSeparator={true}/>}</td>
                            </tr>
                          ))}
                          <tr>
                          <th>Total</th>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalPay()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateInterestTotal()} displayType={'text'} thousandSeparator={true}/>}</td> 
                          <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateScheduleTotal()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td></td>
                          </tr>

                          <tr>
                            <th colSpan={4}>Name of Applicant:</th>
                            <th colSpan={4}>Your Signature: </th>
                          </tr>

                          <tr>
                            <th colSpan={4}>RCSL Risk & Credit Rep:</th>
                            <th colSpan={4}>Our Signature: </th>
                          </tr>

                      </tbody>
                  </reactstrp.Table>

    </div>
    );
  }
}




class ApplicationSchedule extends React.Component {
  state = {
    userprofile:{},
    datarequested:true,
    loanschedule:[],
    application_obj:this.props.application_obj,
    current_selected_loan:0,
    loan_particular_data_loading:false,

  };


  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    
    }else{
       sacco= ''
       bizuserid= ''
    
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    let form_data_1 = new FormData();
    form_data_1.append('userid', bizuserid);
    
    //get the user profile here
    axios.post(serverconfig.backendserverurl+'/customqueries/get_user_profile',form_data_1,{
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res => {
        this.setState({
          userprofile:res.data
        })

        this.setState({datarequested:false})
    })


    //////////////////////////////////
    //get loan attributes
    //////////////////////////
    //////////////////
    //////////////////////////

    this.setState({loan_particular_data_loading:true})

    //set curent id
    this.setState({current_selected_loan:this.props.application_id})

    var applicationID=this.props.application_id

    axios.get(`${serverconfig.backendserverurl}/api/application_schedule/?application=${applicationID}`)
    .then(res => {  
        this.setState({loanschedule: res.data})
    })

    this.setState({loan_particular_data_loading:false})

  //end of loan atriburtes
}


  //calculate principleTotal
  calculateScheduleTotal=()=>{
    var principleTotal=0
    this.state.loanschedule.map(
      (item)=>{
        principleTotal+=Number(item.principle)
      })

    return principleTotal.toFixed(2);
  }

    //calculate principleTotal
    calculateInterestTotal=()=>{
      var InterestTotal=0
      this.state.loanschedule.map(
        (item)=>{
          InterestTotal+=Number(item.interest)
        })
  
      return InterestTotal.toFixed(2);
    }

    //calculate principleTotal
    calculateTotalPay=()=>{
      var TotalPay=0
      this.state.loanschedule.map(
        (item)=>{
          TotalPay+=Number(item.total)
        })
  
      return TotalPay.toFixed(2);
    }



      //calculate total fine paid
      convert_calinterest=()=>{
      var Val_u=0

      if(this.state.application_obj.loan_primary_schedule==="monthly"){
        Val_u=Number(this.state.application_obj.loan_annual_interestrate)/12
      }else if(this.state.application_obj.loan_primary_schedule==="weekly"){
        Val_u=Number(this.state.application_obj.loan_annual_interestrate)/52
      }else if(this.state.application_obj.loan_primary_schedule==="daily"){
        Val_u=Number(this.state.application_obj.loan_annual_interestrate)/365
      }

      return Val_u.toFixed(2);
      }




       


  render() {


    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )
      
    }else{
      return(
        <div>

          <div style={{display: "none"}}>
            &nbsp;&nbsp;
            <ReceiptToPrint
            loanschedule={this.state.loanschedule} 
            date={this.state.datetoday} 
            application_obj={this.state.application_obj}
            interest_rate={this.convert_calinterest()}
            ref={el => (this.componentRef = el)} /> 
            </div>


      {
              this.state.loan_particular_data_loading===true?
              <div style={{display:'flex',margin:20}}>
              <Spin indicator={antIcon} />
              </div>
              :

              <Tabs defaultActiveKey="03" onChange={callback}>

              <TabPane tab="Loan amortization" key="02" >
              <Card>
                <h4 style={{fontStyle:'oblique',fontWeight:'bolder'}}>LOAN SCHEDULE</h4>

                <br></br>
              <div style={{display:'flex',justifyContent:'space-evenly'}}>

              <a>
                  <ReactToPrint
                    trigger={() =>                     
                      <div style={{}}>
                        <Tooltip title="Click to print" placement="top">
                        <p style={{alignSelf:'center'}}><PrinterOutlined style={{ fontSize: '25px', color: '#08c' }}/></p>
                        <h6 style={{alignSelf:'center'}}>Print </h6>
                        </Tooltip>
                      </div>                          
                        }
                        content={() => this.componentRef}
                      />  
                      </a>

                      <ExcelFile filename={"Pisacco - loanschedule_ for_"+this.state.application_obj.accountName}  element={<Button type='primary' icon={<DownloadOutlined/>}>Download (Excel)</Button>}>
                          <ExcelSheet data={this.state.loanschedule} name={"Loan Schedule"}>
                              <ExcelColumn label="No" value="repayment_number"/>
                              <ExcelColumn label="Date" value="date"/>
                              <ExcelColumn label="Month" value="payment_month"/>
                              <ExcelColumn label="Principle Bf" value="principlebroughtforward"/>
                              <ExcelColumn label="Installment" value="total"/>
                              <ExcelColumn label="Interest" value="interest"/>
                              <ExcelColumn label="Principle" value="principle"/>
                              <ExcelColumn label="Principle Bal" value="balance"/>
                         </ExcelSheet>
                     
                     </ExcelFile>   


              </div>
              <br></br>
              <Divider></Divider>
              <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}>LOAN OPENING BALANCE: <CurrencyFormat value={Number(this.state.application_obj.topup_loanbalance).toFixed(2)} displayType={'text'} thousandSeparator={true}/> </h4>
              <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}>DISBURSED AMOUNT: <CurrencyFormat value={Number(this.state.application_obj.loan_topupamount)>0?Number(this.state.application_obj.loan_topupamount).toFixed(2):Number(this.state.application_obj.loan_amount).toFixed(2)} displayType={'text'} thousandSeparator={true}/> </h4>
              <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}>TOTAL LOAN AMOUNT: <CurrencyFormat value={Number(this.state.application_obj.loan_amount).toFixed(2)} displayType={'text'} thousandSeparator={true}/> </h4>

              <Divider></Divider>

                <reactstrp.Table bordered>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Date</th>
                        <th>Month</th>
                        <th>Principle Bf</th>
                        <th>Installment</th>
                        <th>Interest </th>
                        <th>Principle </th>
                        <th>Principle Bal</th>
                      </tr>
                    </thead>
                    <tbody>
                    {this.state.loanschedule.map(
                      (item)=>(
                        <tr>
                        <td>{item.repayment_number}</td>
                        <td>{item.date}</td>
                        <td>{item.payment_month}</td>
                        <td className="align-right">{<CurrencyFormat value={item.principlebroughtforward} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td className="align-right">{<CurrencyFormat value={item.total} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td className="align-right">{<CurrencyFormat value={item.interest} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td className="align-right">{<CurrencyFormat value={item.principle} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td className="align-right">{<CurrencyFormat value={item.balance} displayType={'text'} thousandSeparator={true}/>}</td>
                        </tr>
                      ))}
                      <tr>
                      <th>Total</th>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalPay()} displayType={'text'} thousandSeparator={true}/>}</td>
                      <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateInterestTotal()} displayType={'text'} thousandSeparator={true}/>}</td> 
                      <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateScheduleTotal()} displayType={'text'} thousandSeparator={true}/>}</td>
                      <td></td>
                      </tr>

                  </tbody>
              </reactstrp.Table>


              </Card>

              </TabPane>

              </Tabs>
          }

    </div>

    )}

  }
}

export default ApplicationSchedule; 
