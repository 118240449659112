import React from 'react';
import { Form, Input, Button,Spin } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;



var token= ''
var sacco= ''


class IDTypeForm extends React.Component {

  state = {
    idtypeName:'' ,  
    idtypeDescription:'',
    IDvaliditydays:0,
    datarequested:false,
  }

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }
  }


  //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})

    let form_data = new FormData();
    form_data.append('id_type_name', this.state.idtypeName);
    form_data.append('description', this.state.idtypeDescription);
    form_data.append('IDvaliditydays', this.state.IDvaliditydays);

    form_data.append('sacco', sacco);

      axios.post(serverconfig.backendserverurl+'/api/idtypes/', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
    .then(res =>     this.setState({datarequested:false})
,    window.location.reload(false)
    )
    .catch(error => console.log(error))

  }

  handleIdTypeNameChange= (e) => this.setState({ idtypeName: e.target.value});
  handleIDDescriptionChange= (e) => this.setState({ idtypeDescription: e.target.value});

  render(){

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return (
        <div>
    <Form
        onFinish={(event) => this.handleFormSubmit(event)}
    >
      <FormItem label="ID Name"
        name="idname"
        rules={[
          {
            required: true,
            message: 'Please input name',
          },
        ]}
      >
        <Input name="idtypename"  placeholder="Put a name here." value={this.state.idtypeName} onChange={this.handleIdTypeNameChange} />
      </FormItem>
      <FormItem label="Description"
        name="description"
        rules={[
          {
            required: true,
            message: 'Please input desc',
          },
        ]}    
      >
        <Input name="description"  placeholder="Description here" value={this.state.idtypeDescription} onChange={this.handleIDDescriptionChange} />
      </FormItem>
     

      <FormItem label="Validity days"
        name="IDvaliditydays"
        rules={[
          {
            required: true,
            message: 'Please input desc',
          },
        ]}    
      >
        <Input name="IDvaliditydays"  placeholder="ID validity days here" value={this.state.IDvaliditydays} onChange={(val)=>{this.setState({IDvaliditydays:val.target.value})}} />
      </FormItem>

      <FormItem>
        <Button  type="primary" htmlType="submit">{this.props.btnText}</Button>
      </FormItem>
    </Form>
        </div>
      );

    }

   
  }

}


export default IDTypeForm;



