import React from 'react';
import MemberForm from '../components/MemberForm'
import GroupForm from '../components/GroupForm'

import ActivateMemberForm from '../components/ActivateMemberForm'
import MemberImportForm from '../components/MemberImportForm'
import MemberTransactionsImportForm from '../components/MemberTransactionsmportForm'
import MembershipPaymentForm from '../components/MembershipPaymentForm'
import ApproveMemberForm from '../components/ApproveMemberForm'

import axios from 'axios'
import { Table, Input, Button,Collapse,Popover,Popconfirm,message,Descriptions,Modal,Spin,Card,Tabs,Tooltip,Form } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,RollbackOutlined,PrinterOutlined,DeleteOutlined,FundViewOutlined,EditOutlined,DownloadOutlined,WalletFilled,LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'

import {
  Col,
  Row,
} from 'reactstrap';
import ReactExport from "@ibrahimrahmani/react-export-excel";
import MemberDetail_InTab from './MemberDetailView_InTab';
import FinancialStatementReport_Member from './FinancialStatementReportView_Member';
import LoanIssuanceList_PerMember from './LoanIssuancesListView_PerMember';
import ReactToPrint from 'react-to-print';
import * as reactstrp from 'reactstrap';
import QuickActions from "../components/QuickActions";
import '../../App.css'; // Create this file for custom styles


var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { TabPane } = Tabs;
const { Search } = Input;
const FormItem=Form.Item;

function callback(key) {
  console.log(key);
}

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

var sacco= ''
var bizuserid= ''
var bizuserid= ''
var token= ''
var username=''

const rowClassName = (record) => {
  return record.account_status === "inactive" ? 'highlighted-row' : '';
};


class ReportToPrint extends React.Component {

  state = {
    companyprofile:{},
  };

  componentDidMount(){

    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)

    }else{
       sacco= ''
       bizuserid= ''
       username=''

    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })

  }


  render() {
    return (
      <div style={{padding:20}}>

          <style>
          {`@media print {
            @page { size: landscape; }
          }`}
          </style>


<Row> 
              <Col xs="2" sm="2" lg="2">
              <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.sacco_logo} />
            
                </Col>
                <Col xs="6" sm="6" lg="6">
                <h3>{this.state.companyprofile.sacco_name}</h3>
                <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                {
                this.state.companyprofile.RegisteredPhoneNumber2==""?
                "":","+this.state.companyprofile.RegisteredPhoneNumber2
                }
                {
                this.state.companyprofile.RegisteredPhoneNumber3==""?
                "":","+this.state.companyprofile.RegisteredPhoneNumber3
                }
                </h6>               
                <h5>Email: {this.state.companyprofile.company_email}</h5>
                <h5>Wesite: {this.state.companyprofile.website}</h5>
                <h5>{this.state.companyprofile.box_number}</h5>
              
                </Col>
     </Row>

           <h3 style={{display: 'flex',justifyContent:'center', alignItems:'center',fontWeight:'bolder'}}>REGISTERED MEMBERS LIST</h3>

           <reactstrp.Table bordered>
                    <thead>
                      <tr>
                        <th>Acc No</th>
                        <th>Member type</th>

                        <th>Acc Name</th>
                        <th>Birthdate</th>
                        <th>Sex/Gender</th>
                        <th>Address</th>
                        <th>Phone1</th>


                        <th>Email</th>
                        <th>ID/NIN</th>
                        <th>Occupation</th>
                        <th>Name of employer/business</th>
                        <th>Address of employer/business</th>
                        <th>Marital Status</th>
                        <th>Cell Location</th>
                        <th>Cell Leader</th>
                        <th>Recommender</th>
                        <th>NextofKin</th>

                        <th>KinAddress</th>
                        <th>Date of joining</th>

                      </tr>
                    </thead>
                    <tbody>
                    {this.props.all_members.map(
                      (item)=>(
                        <tr>
                         <td>{item.AccountNumber}</td>
                         <td>{item.member_type}</td>

                        <td>{item.FullName}</td>
                        <td>{item.DateOfBirth}</td>
                        <td>{item.gender}</td>
                        <td>{item.AreaOfResidence}</td>
                        <td>{item.RegisteredPhoneNumber1}</td>

                        <td>{item.EmailAddress}</td>
                        <td>{item.IDNumber}</td>
                        <td>{item.occupation}</td>
                        <td>{item.nameofemployer_business}</td>
                        <td>{item.addressofemployer_business}</td>
                        <td>{item.marital_status}</td>
                        <td>{item.homecelllocaltion}</td>
                        <td>{item.cellleader}</td>
                        <td>{item.recommender}</td>

                        <td>{item.next_of_kin}</td>
                        <td>{item.NextOfKinPhysicalAddress}</td>
                        <td>{item.DateOfJoining}</td>

                        </tr>
                      ))}
                     
                  </tbody>
              </reactstrp.Table> 
      </div>
    );
  }
}




class MemberList_Registered extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    members: [],
    all_members:[],
    all_members_placeholder:[],

    viewpaymentmodal:false,
    expensedetails:{},
    userprofile:{},
    datarequested:true,
    memberactivationformvisible:false,
    selectedmemberid:'',
    userrights:{},

    juniormembers: [],
    dormantaccounts: [],
    pendingaccounts:[],
    membsershippaymentvisible:false,
    memberaapprovalformvisible:false,
    approvedaccounts:[],
    rejectedaccounts:[],
    jointaccounts:[],
    ministryaccounts:[],

    inactiveaccounts:[]

  };

  componentDidMount(){

    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
       bizuserid= ''
    }
    
    if(localStorage.getItem("bizuserid")){
       bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
     }else{
        bizuserid= ''
     }
    
     if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

   this.componentmountactions();
}


//component mount method
componentmountactions=()=>{

  axios.get(serverconfig.backendserverurl+`/api/members_withaccounts/?sacco=${sacco}`)
  .then(res => {
      this.setState({
        all_members:res.data
      })

      this.setState({
        all_members_placeholder:res.data
      })
      
  })

  axios.get(serverconfig.backendserverurl+`/api/members_withaccounts/?sacco=${sacco}&account_status=${'inactive'}`)
  .then(res => {
      this.setState({
        inactiveaccounts:res.data
      })
      
  })


  


  axios.get(serverconfig.backendserverurl+`/api/members/?sacco=${sacco}&member_type=${'senior'}&account_status=${'active'}`)
  .then(res => {
      this.setState({
        members:res.data
      })
  })

  axios.get(serverconfig.backendserverurl+`/api/members/?sacco=${sacco}&member_type=${'junior'}&account_status=${'active'}`)
  .then(res => {
      this.setState({
        juniormembers:res.data
      })
  })

  axios.get(serverconfig.backendserverurl+`/api/members/?sacco=${sacco}&account_status=${'pending'}`)
  .then(res => {
      this.setState({
        pendingaccounts:res.data
      })
  })

  axios.get(serverconfig.backendserverurl+`/api/members/?sacco=${sacco}&account_status=${'approved'}`)
  .then(res => {
      this.setState({
        approvedaccounts:res.data
      })
  })

  axios.get(serverconfig.backendserverurl+`/api/members/?sacco=${sacco}&account_status=${'rejected'}`)
  .then(res => {
      this.setState({
        rejectedaccounts:res.data
      })
  })


  axios.get(serverconfig.backendserverurl+`/api/dormantaccounts/?sacco=${sacco}`)
  .then(res => {
      this.setState({
        dormantaccounts:res.data
      })
  })

  axios.get(serverconfig.backendserverurl+`/api/members/?sacco=${sacco}&member_type=${'joint'}&account_status=${'active'}`)
  .then(res => {
      this.setState({
        jointaccounts:res.data
      })
  })

  axios.get(serverconfig.backendserverurl+`/api/members/?sacco=${sacco}&member_type=${'ministry'}&account_status=${'active'}`)
  .then(res => {
      this.setState({
        ministryaccounts:res.data
      })
  })


  axios.get(serverconfig.backendserverurl+`/api/accountrights/?user=${bizuserid}`)
  .then(res => {
      this.setState({
        userrights:res.data[0]
      })
      this.setState({datarequested:false})
  })

  axios.get(serverconfig.backendserverurl+`/api/accounts/${bizuserid}`)
  .then(res => {
      this.setState({
        userprofile:res.data
      })
      this.setState({datarequested:false})
  })

}

    //////////////////////////////////////////////////
  //handle delete here
  handleDelete = id => {
    let form_data1 = new FormData();
    form_data1.append('itemid', id);
    form_data1.append('sacco', sacco);
    form_data1.append('table_model', "Member");

    axios.post(`${serverconfig.backendserverurl}/customqueries/puttorecyclebin`, form_data1, {
      headers: {
        'content-type': 'multipart/form-data'
      }
      }).then(res =>{
      message.info("successfully deleted") 
      this.componentmountactions();
     }
     );
  };
  

    //open mdi guarantor form
    openmemberactivationform=(val)=>{
      this.setState({selectedmemberid:val})
      this.setState({memberactivationformvisible:true})
    }
  
    closememberactivationform=()=>{
      this.setState({memberactivationformvisible:false})
    }


getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};

  render() {
    
      var columns = [
        {
          title: 'Account Number',
          dataIndex: 'AccountNumber',
          key: 'id',
          ...this.getColumnSearchProps('AccountNumber'),
        },
        {
          title: 'Account Name',
          dataIndex: 'FullName',
          key: 'id',
          ...this.getColumnSearchProps('FullName'),
        },
        {
          title: 'Gender',
          dataIndex: 'gender',
          key: 'id',
          ...this.getColumnSearchProps('gender'),
        },
        {
          title: 'Account Type',
          dataIndex: 'accounttypename',
          key: 'id',
          ...this.getColumnSearchProps('accounttypename'),
        },
        {
          title: 'Account Contact',
          dataIndex: 'RegisteredPhoneNumber1',
          key: 'id',
          ...this.getColumnSearchProps('RegisteredPhoneNumber1'),
        },
        {
          title: 'Account Status',
          dataIndex: 'account_status',
          key: 'id',
          ...this.getColumnSearchProps('account_status'),
          render: (text,record) =>

          <p>
          {
            record.account_status==="inactive"?
            <Tooltip 
              title={"Reason of deactivation: "+record.deactivation_reason}
              placement="top"
            >
            <span>{record.account_status}</span>
            </Tooltip>
              :
              <span>{record.account_status}</span>
            }
         </p>
        },
        

        {
          title: 'Action',
          dataIndex: 'id',
          key: 'id',
          render: text =>
          <p>
         

          {
            this.state.userrights.accountactivation?
            <Popover content={<p>Click here to activate account</p>} title="Activate">
            <EditOutlined style={{color:'green'}} onClick={()=>{this.openmemberactivationform(text)}}/>
            </Popover>
            :
            null
          }

          &nbsp;&nbsp;&nbsp;

          {
            /*
            this.state.userprofile.branch_manager===true?
            <Popover content={<p>Click here to delete</p>} title="Delete">
            <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(text)}>
              <DeleteOutlined style={{color:'red'}}/>
             </Popconfirm>
            </Popover>
            :
            null */
          }

         
          </p>,
        }
  
      ];
    

      var juniorcolumns = [
        {
          title: 'Account Number',
          dataIndex: 'AccountNumber',
          key: 'id',
          ...this.getColumnSearchProps('AccountNumber'),
        },
        {
          title: 'Account Name',
          dataIndex: 'FullName',
          key: 'id',
          ...this.getColumnSearchProps('FullName'),
        },
        {
          title: 'Gender',
          dataIndex: 'gender',
          key: 'id',
          ...this.getColumnSearchProps('gender'),
        },
        {
          title: 'Account Type',
          dataIndex: 'accounttypename',
          key: 'id',
          ...this.getColumnSearchProps('accounttypename'),
        },
        {
          title: 'Account Contact',
          dataIndex: 'RegisteredPhoneNumber1',
          key: 'id',
          ...this.getColumnSearchProps('RegisteredPhoneNumber1'),
        },
        {
          title: 'Account Status',
          dataIndex: 'account_status',
          key: 'id',
          ...this.getColumnSearchProps('account_status'),
        },
       
        {
          title: 'Parent Account Number',
          dataIndex: 'parentaccount_no',
          key: 'id',
          ...this.getColumnSearchProps('parentaccount_no'),
        },

        {
          title: 'Action',
          dataIndex: 'id',
          key: 'id',
          render: text =>
          <p>
          

          {
            this.state.userrights.accountactivation?
            <Popover content={<p>Click here to activate account</p>} title="Activate">
            <EditOutlined style={{color:'green'}} onClick={()=>{this.openmemberactivationform(text)}}/>
            </Popover>
            :
            null
          }

          &nbsp;&nbsp;&nbsp;
          {
            this.state.userprofile.branch_manager===true?

          <Popover content={<p>Click here to delete</p>} title="Delete">
          <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(text)}>
            <DeleteOutlined style={{color:'red'}}/>
           </Popconfirm>
          </Popover>
          :
          null}
          </p>,
        }
  
      ];


      var dormantcolumns = [
        {
          title: 'Account Number',
          dataIndex: 'accountno',
          key: 'id',
          ...this.getColumnSearchProps('accountno'),
        },
        {
          title: 'Account Name',
          dataIndex: 'accountname',
          key: 'id',
          ...this.getColumnSearchProps('accountname'),
        },
        {
          title: 'Date Of Dormancy',
          dataIndex: 'date_ordormancy',
          key: 'id',
        },
      
        {
          title: 'Action',
          dataIndex: 'account',
          key: 'id',
          render: text =>
          <p>
         
          {
            this.state.userrights.accountactivation?
            <Popover content={<p>Click here to activate account</p>} title="Activate">
            <EditOutlined style={{color:'green'}} onClick={()=>{this.openmemberactivationform(text)}}/>
            </Popover>
            :
            null
          }
          </p>,
        }
      ];



      var jointcolumns = [
        {
          title: 'Account Number',
          dataIndex: 'AccountNumber',
          key: 'id',
          ...this.getColumnSearchProps('AccountNumber'),
        },
        {
          title: 'Account Name',
          dataIndex: 'FullName',
          key: 'id',
          ...this.getColumnSearchProps('FullName'),
        },
        {
          title: 'Account Type',
          dataIndex: 'accounttypename',
          key: 'id',
          ...this.getColumnSearchProps('accounttypename'),
        },
        {
          title: 'Account Contact',
          dataIndex: 'RegisteredPhoneNumber1',
          key: 'id',
          ...this.getColumnSearchProps('RegisteredPhoneNumber1'),
        },
        {
          title: 'Account Status',
          dataIndex: 'account_status',
          key: 'id',
          ...this.getColumnSearchProps('account_status'),
        },
        
        {
          title: 'Action',
          dataIndex: 'id',
          key: 'id',
          render: text =>
          <p>
          <Popover content={<p>Click here to edit,view details</p>} title="Edit,View">
           <Link to={`/members/${text}`}><FundViewOutlined style={{color:'blue'}}/></Link>
          </Popover>
          &nbsp;&nbsp;&nbsp;
          {
            this.state.userrights.accountactivation?
            <Popover content={<p>Click here to activate account</p>} title="Activate">
            <EditOutlined style={{color:'green'}} onClick={()=>{this.openmemberactivationform(text)}}/>
            </Popover>
            :
            null
          }
          </p>,
        }
      ];


      var ministrycolumns = [
        {
          title: 'Account Number',
          dataIndex: 'AccountNumber',
          key: 'id',
          ...this.getColumnSearchProps('AccountNumber'),
        },
        {
          title: 'Account Name',
          dataIndex: 'FullName',
          key: 'id',
          ...this.getColumnSearchProps('FullName'),
        },
        {
          title: 'Account Type',
          dataIndex: 'accounttypename',
          key: 'id',
          ...this.getColumnSearchProps('accounttypename'),
        },
        {
          title: 'Account Contact',
          dataIndex: 'RegisteredPhoneNumber1',
          key: 'id',
          ...this.getColumnSearchProps('RegisteredPhoneNumber1'),
        },
        {
          title: 'Account Status',
          dataIndex: 'account_status',
          key: 'id',
          ...this.getColumnSearchProps('account_status'),
        },
        
        {
          title: 'Action',
          dataIndex: 'id',
          key: 'id',
          render: text =>
          <p>
        

          {
            this.state.userrights.accountactivation?
            <Popover content={<p>Click here to activate account</p>} title="Activate">
            <EditOutlined style={{color:'green'}} onClick={()=>{this.openmemberactivationform(text)}}/>
            </Popover>
            :
            null
          }
          </p>,
        }
      ];


    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )
    }else{
      return(
        <div>

            <div style={{display: "none"}}>
               &nbsp;&nbsp;
              <ReportToPrint
              all_members={this.state.all_members} 
              ref={el => (this.componentRef = el)} /> 
            </div>




        <Tabs defaultActiveKey="1" onChange={callback}>
        {/**All accounts */}

        <TabPane tab={"All Members ( "+this.state.all_members.length+" )"} key="3">

        <div style={{display:'flex',flexDirection:'row'}}>
      
        <ReactToPrint
          trigger={() => 
          <Button type="primary" 
          shape="round" 
          icon={<PrinterOutlined />} 
          size={this.state.size}>
          Print
          </Button>
          }
          content={() => this.componentRef}
          />
           &nbsp;&nbsp;


        <ExcelFile
           filename={"Pisys - All Members Report"} 
           element={<Button type='primary' icon={<DownloadOutlined/>}>Download Report</Button>}
           >
              <ExcelSheet data={this.state.all_members} name="All Members">
                  <ExcelColumn label="Acc No" value="AccountNumber"/>
                  <ExcelColumn label="Member Type" value="member_type"/>

                  <ExcelColumn label="Acc Name" value="FullName"/>
                  <ExcelColumn label="Birthdate" value="DateOfBirth"/>
                  <ExcelColumn label="Sex/Gender" value="gender"/>
                  <ExcelColumn label="Address" value="AreaOfResidence"/>
                  <ExcelColumn label="Phone1" value="RegisteredPhoneNumber1"/>
                  <ExcelColumn label="Email" value="EmailAddress"/>
                  <ExcelColumn label="ID/NIN" value="IDNumber"/>
                  <ExcelColumn label="Occupation" value="occupation"/>
                  <ExcelColumn label="name of employer/business" value="nameofemployer_business"/>
                  <ExcelColumn label="Address of employer/business" value="addressofemployer_business"/>
                  <ExcelColumn label="Marital Status" value="marital_status"/>
                  <ExcelColumn label="Cell Location" value="homecelllocaltion"/>
                  <ExcelColumn label="Cell Leader" value="cellleader"/>
                  <ExcelColumn label="Recommender" value="recommender"/>
                  <ExcelColumn label="NextofKin" value="next_of_kin"/>
                  <ExcelColumn label="KinAddress" value="NextOfKinPhysicalAddress"/>
                  <ExcelColumn label="Date of joining" value="DateOfJoining"/>
              </ExcelSheet>
          </ExcelFile>

        </div>

        

            <br />
            <br></br>
            <FormItem label="Search" >
            <Search
            placeholder="AccountNumber or AccountName"
            onChange={(value) => {
              var val=value.target.value
              console.log(val)
              if (val !== '') {
                const results = this.state.all_members.filter((request) => {
                  return request.FullName.toLowerCase().includes(val.toLowerCase());
                  // Use the toLowerCase() method to make it case-insensitive
                });
          
                if (results.length === 0) {
                  // this.setState({loanissues:this.state.loanissues_placeholder});

                  const results_2 = this.state.all_members.filter((request) => {
                    return request.AccountNumber.includes(val);
                    // Use the toLowerCase() method to make it case-insensitive
                  });

                  if (results_2.length === 0) {
                    this.setState({all_members:this.state.all_members_placeholder});

                  }else{
                    this.setState({all_members:results_2});

                  }

                } else {
                  this.setState({all_members:results});
                  console.log(results)
                }
          
              } else {
                this.setState({all_members:this.state.all_members_placeholder});
          
              }
          
            }}
            style={{
              width: 400,
            }}
            />
            </FormItem>
            <br />
            <br></br>


            <Table   
            columns={columns} 
            scroll={{ x: 1000 }}
            dataSource={this.state.all_members} 
            pagination={{showQuickJumper:true,showSizeChanger:true }}
            bordered
            
            expandable={{
              expandedRowRender: (record) =>{
              return (
                <div style={{margin: 0,}}>
                <Tabs defaultActiveKey="1" onChange={()=>{}}>
                <TabPane tab="View/Update Details" key="1">
                  <MemberDetail_InTab memberID={record.id} />
                </TabPane>

                <TabPane tab="Financial Statement" key="2">
                  <FinancialStatementReport_Member memberID={record.id} />
                </TabPane>

                <TabPane tab="Loans" key="3">
                  <LoanIssuanceList_PerMember memberID={record.id} />
                </TabPane>

                <TabPane tab="More Actions" key="04">
                    <QuickActions memberobj={record} onRefresh={this.componentmountactions}/>
                </TabPane>

                </Tabs>

                </div>
                );
               
              } ,
              rowExpandable: (record) => true,
              onExpand:(condition,record)=>{
              },
            }}
            size='small'
            rowClassName={rowClassName}
            
            />

          </TabPane>



            {/**Personal accounts */}

            <TabPane tab={"Personal Accounts ( "+this.state.members.length+" )"} key="4">
          
            <ExcelFile filename={"Pisys - Personal accounts report"}  element={<Button type='primary' icon={<DownloadOutlined/>}>Download Report</Button>}>
                <ExcelSheet data={this.state.members} name="Personal accounts">
                    <ExcelColumn label="Acc No" value="AccountNumber"/>
                    <ExcelColumn label="Acc Name" value="FullName"/>
                    <ExcelColumn label="Phone1" value="RegisteredPhoneNumber1"/>
                    <ExcelColumn label="Phone2" value="RegisteredPhoneNumber2"/>
                    <ExcelColumn label="Date of joining" value="DateOfJoining"/>
                    <ExcelColumn label="Gender" value="gender"/>
                </ExcelSheet>
            </ExcelFile>


              <br />
              <br></br>
  
              <Table   
              columns={columns} 
              scroll={{ x: 1000 }}
              dataSource={this.state.members} 
              pagination={{showQuickJumper:true,showSizeChanger:true }}
              bordered
              
              expandable={{
                expandedRowRender: (record) =>{
                return (
                  <div style={{margin: 0,}}>
                  <Tabs defaultActiveKey="1" onChange={()=>{}}>
                  <TabPane tab="View/Update Details" key="1">
                    <MemberDetail_InTab memberID={record.id} />
                  </TabPane>
  
                  <TabPane tab="Financial Statement" key="2">
                    <FinancialStatementReport_Member memberID={record.id} />
                  </TabPane>
  
                  <TabPane tab="Loans" key="3">
                    <LoanIssuanceList_PerMember memberID={record.id} />
  
                  </TabPane>

                  <TabPane tab="More Actions" key="04">
                    <QuickActions memberobj={record} onRefresh={this.componentmountactions}/>
                  </TabPane>
  
                  </Tabs>
  
                  </div>
                  );
                 
                } ,
                rowExpandable: (record) => true,
                onExpand:(condition,record)=>{
                },
              }}
              
              
              />


            </TabPane>

            {/**Joint accounts */}


          <TabPane tab={"Joint Accounts ( "+this.state.jointaccounts.length+" )"} key="5">

            <ExcelFile filename={"Pisys - Joint accounts report"}  element={<Button type='primary' icon={<DownloadOutlined/>}>Download Report</Button>}>
                <ExcelSheet data={this.state.jointaccounts} name="Joint accounts">
                    <ExcelColumn label="Acc No" value="AccountNumber"/>
                    <ExcelColumn label="Acc Name" value="FullName"/>
                    <ExcelColumn label="Phone1" value="RegisteredPhoneNumber1"/>
                    <ExcelColumn label="Date of joining" value="DateOfJoining"/>
                </ExcelSheet>
              </ExcelFile>

            <br></br>
            <br></br>
                <Table   
                  columns={jointcolumns} 
                  scroll={{ x: 1000 }}
                  dataSource={this.state.jointaccounts} 
                  pagination={{showQuickJumper:true,showSizeChanger:true }}
                  bordered
                  
                  expandable={{
                    expandedRowRender: (record) =>{
                    return (
                      <div style={{margin: 0,}}>
                      <Tabs defaultActiveKey="1" onChange={()=>{}}>
                      <TabPane tab="View/Update Details" key="1">
                        <MemberDetail_InTab memberID={record.id} />
                      </TabPane>
      
                      <TabPane tab="Financial Statement" key="2">
                        <FinancialStatementReport_Member memberID={record.id} />
                      </TabPane>
      
                      <TabPane tab="Loans" key="3">
                        <LoanIssuanceList_PerMember memberID={record.id} />
      
                      </TabPane>

                      <TabPane tab="More Actions" key="04">
                              <QuickActions memberobj={record} onRefresh={this.componentmountactions}/>
                         </TabPane>
      
                      </Tabs>
      
                      </div>
                      );
                     
                    } ,
                    rowExpandable: (record) => true,
                    onExpand:(condition,record)=>{
                    },
                  }}
                  
                  
                  />
           
            </TabPane>

            {/**Group accounts */}

            <TabPane tab={"Group Accounts ( "+this.state.ministryaccounts.length+" )"} key="6">
                   <ExcelFile filename={"Pisys - Group accounts report"}  element={<Button type='primary' icon={<DownloadOutlined/>}>Download Report</Button>}>
                      <ExcelSheet data={this.state.ministryaccounts} name="Group accounts">
                          <ExcelColumn label="Acc No" value="AccountNumber"/>
                          <ExcelColumn label="Acc Name" value="FullName"/>
                          <ExcelColumn label="Phone1" value="RegisteredPhoneNumber1"/>
                          <ExcelColumn label="Date of joining" value="DateOfJoining"/>
                      </ExcelSheet>
                    </ExcelFile>     
                   
                    <br></br>
                  <br></br>

                    <Table   
                      columns={ministrycolumns} 
                      scroll={{ x: 1000 }}
                      dataSource={this.state.ministryaccounts} 
                      pagination={{showQuickJumper:true,showSizeChanger:true }}
                      bordered
                      
                      expandable={{
                        expandedRowRender: (record) =>{
                        return (
                          <div style={{margin: 0,}}>
                          <Tabs defaultActiveKey="1" onChange={()=>{}}>
                          <TabPane tab="View/Update Details" key="1">
                            <MemberDetail_InTab memberID={record.id} />
                          </TabPane>
          
                          <TabPane tab="Financial Statement" key="2">
                            <FinancialStatementReport_Member memberID={record.id} />
                          </TabPane>
          
                          <TabPane tab="Loans" key="3">
                            <LoanIssuanceList_PerMember memberID={record.id} />
          
                          </TabPane>

                          <TabPane tab="More Actions" key="04">
                              <QuickActions memberobj={record} onRefresh={this.componentmountactions}/>
                         </TabPane>
          
                          </Tabs>
          
                          </div>
                          );
                         
                        } ,
                        rowExpandable: (record) => true,
                        onExpand:(condition,record)=>{
                        },
                      }}
                      
                      
                      
                      />
                  
            </TabPane>


            {/**Junior accounts */}

            <TabPane tab={"Junior Accounts ( "+this.state.juniormembers.length+" )"} key="7">
                <ExcelFile filename={"Pisys - Junior Members report"} element={<Button type='primary' icon={<DownloadOutlined/>}>Download Report</Button>}>
                      <ExcelSheet data={this.state.juniormembers} name="Junior Members Data">
                          <ExcelColumn label="Acc No" value="AccountNumber"/>
                          <ExcelColumn label="Acc Name" value="FullName"/>
                          <ExcelColumn label="Account Balance" value="UserBalance"/>
                          <ExcelColumn label="Saving Period" value="saving_period"/>
                          <ExcelColumn label="School" value="child_account_school"/>
                          <ExcelColumn label="Class" value="child_account_school_class"/>
                          <ExcelColumn label="Contact" value="RegisteredPhoneNumber1"/>
                      </ExcelSheet>
                  </ExcelFile>

                  <br></br>
                  <br></br>

                    <Table   
                    columns={juniorcolumns} 
                    scroll={{ x: 1000 }}
                    dataSource={this.state.juniormembers} 
                    pagination={{showQuickJumper:true,showSizeChanger:true }}
                    bordered
                    
                    expandable={{
                      expandedRowRender: (record) =>{
                      return (
                        <div style={{margin: 0,}}>
                        <Tabs defaultActiveKey="1" onChange={()=>{}}>
                        <TabPane tab="View/Update Details" key="1">
                          <MemberDetail_InTab memberID={record.id} />
                        </TabPane>
        
                        <TabPane tab="Financial Statement" key="2">
                          <FinancialStatementReport_Member memberID={record.id} />
                        </TabPane>
        
                        <TabPane tab="Loans" key="3">
                          <LoanIssuanceList_PerMember memberID={record.id} />
        
                        </TabPane>

                        <TabPane tab="More Actions" key="04">
                              <QuickActions memberobj={record} onRefresh={this.componentmountactions}/>
                         </TabPane>
        
                        </Tabs>
        
                        </div>
                        );
                       
                      } ,
                      rowExpandable: (record) => true,
                      onExpand:(condition,record)=>{
                      },
                    }}
                    
                    
                    />

            </TabPane>




            {/**Dormant accounts */}

            <TabPane tab={"Dormant Accounts ( "+this.state.dormantaccounts.length+" )" } key="8">
                  <ExcelFile filename={"Pisys - Dormant accounts report"}  element={<Button type='primary' icon={<DownloadOutlined/>}>Download  Excel</Button>}>
                    <ExcelSheet data={this.state.dormantaccounts} name="Dormant Accounts">
                        <ExcelColumn label="Acc No" value="accountno"/>
                        <ExcelColumn label="Acc Name" value="accountname"/>
                        <ExcelColumn label="Date Of Dormancy" value="date_ordormancy"/>
                    </ExcelSheet>
                  </ExcelFile>
                  <br></br>
                  <br></br>


                    <Table   
                    columns={dormantcolumns} 
                    scroll={{ x: 1000 }}
                    dataSource={this.state.dormantaccounts} 
                    pagination={{showQuickJumper:true,showSizeChanger:true }}
                    bordered
                    
                    expandable={{
                      expandedRowRender: (record) =>{
                      return (
                        <div style={{margin: 0,}}>
                        <Tabs defaultActiveKey="1" onChange={()=>{}}>
                        <TabPane tab="View/Update Details" key="1">
                          <MemberDetail_InTab memberID={record.id} />
                        </TabPane>
        
                        <TabPane tab="Financial Statement" key="2">
                          <FinancialStatementReport_Member memberID={record.id} />
                        </TabPane>
        
                        <TabPane tab="Loans" key="3">
                          <LoanIssuanceList_PerMember memberID={record.id} />
        
                        </TabPane>

                        <TabPane tab="More Actions" key="04">
                              <QuickActions memberobj={record} onRefresh={this.componentmountactions}/>
                         </TabPane>
        
                        </Tabs>
        
                        </div>
                        );
                       
                      } ,
                      rowExpandable: (record) => true,
                      onExpand:(condition,record)=>{
                      },
                    }}
                                        
                    />
                    <br />
                 
            </TabPane>
          
          {/**Inactive accounts */}

          <TabPane tab={"Inactive Accounts ( "+this.state.inactiveaccounts.length+" )" } key="9">
               
                  <br></br>
                  <ExcelFile filename={"Pisys - Inactive accounts report"}  
                  element={<Button type='primary' icon={<DownloadOutlined/>}>Download  Excel</Button>}>
                    <ExcelSheet data={this.state.inactiveaccounts} name="Inactive Accounts">
                        <ExcelColumn label="Acc No" value="AccountNumber"/>
                        <ExcelColumn label="Acc Name" value="FullName"/>
                        <ExcelColumn label="Reason of inactivity" value="deactivation_reason"/>
                    </ExcelSheet>
                  </ExcelFile>
                  
                  <br></br>
                  <br></br>

                    <Table   
                    columns={columns} 
                    scroll={{ x: 1000 }}
                    dataSource={this.state.inactiveaccounts} 
                    pagination={{showQuickJumper:true,showSizeChanger:true }}
                    bordered
                    
                    expandable={{
                      expandedRowRender: (record) =>{
                      return (
                        <div style={{margin: 0,}}>
                        <Tabs defaultActiveKey="1" onChange={()=>{}}>
                        <TabPane tab="View/Update Details" key="1">
                          <MemberDetail_InTab memberID={record.id} />
                        </TabPane>
        
                        <TabPane tab="Financial Statement" key="2">
                          <FinancialStatementReport_Member memberID={record.id} />
                        </TabPane>
        
                        <TabPane tab="Loans" key="3">
                          <LoanIssuanceList_PerMember memberID={record.id} />
        
                        </TabPane>

                        <TabPane tab="More Actions" key="04">
                              <QuickActions memberobj={record} onRefresh={this.componentmountactions}/>
                         </TabPane>
        
                        </Tabs>
        
                        </div>
                        );
                       
                      } ,
                      rowExpandable: (record) => true,
                      onExpand:(condition,record)=>{
                      },
                    }}

                    rowClassName={rowClassName}
                
                    />
                    <br />
                 
            </TabPane>
                   
          </Tabs>
 


            <Modal              
              visible={this.state.memberactivationformvisible}
              title={"Member Activation Form"}
              onCancel={this.closememberactivationform}
              style={{width:1000}}
              footer={[
                <Button key="back" onClick={this.closememberactivationform}>
                  Cancel
                </Button>
                ]}
                >
                <ActivateMemberForm memberid={this.state.selectedmemberid} />
            </Modal> 


            <Modal              
              visible={this.state.membsershippaymentvisible}
              title={"Membership Payment Form"}
              onCancel={()=>{this.setState({membsershippaymentvisible:false})}}
              style={{width:1000}}
              footer={[
                <Button key="back" onClick={()=>{this.setState({membsershippaymentvisible:false})}}>
                  Cancel
                </Button>
                ]}
                >
                <MembershipPaymentForm memberid={this.state.selectedmemberid} />

            </Modal> 


            <Modal              
              visible={this.state.memberaapprovalformvisible}
              title={"Membership Approval Form"}
              onCancel={()=>{this.setState({memberaapprovalformvisible:false})}}
              style={{width:1000}}
              footer={[
                <Button key="back" onClick={()=>{this.setState({memberaapprovalformvisible:false})}}>
                  Cancel
                </Button>
                ]}
                >
                <ApproveMemberForm memberid={this.state.selectedmemberid} />

            </Modal> 
        </div>
    )

    }

  }
}

export default MemberList_Registered; 
