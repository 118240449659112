import React from 'react';

import axios from 'axios'
import { Table, Input, Button,Collapse,Popover,Popconfirm,message,Card,Tooltip,Descriptions,Modal,Spin,Tabs, Divider } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,PrinterOutlined,DeleteOutlined,FundViewOutlined,EyeFilled,WalletFilled,DownloadOutlined,LoadingOutlined,FilePdfFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import  LoanImportForm from '../components/LoanImportForm'
import {
  Col,
  Row,
} from 'reactstrap';
import * as reactstrp from 'reactstrap';
import ReactToPrint from 'react-to-print';
import ReactExport from "@ibrahimrahmani/react-export-excel";
import '../tablestyle.css'; // Create this CSS file for custom styles
import  LoanCertificateGenerationForm from '../components/LoanCertificateGenerationForm'
import LoanQuickActions from './LoanQuickActions';
import LoanDetailsTab from '../components/LoanDetailsTab';

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

function callback(key) {
  console.log(key);
}

const { TabPane } = Tabs;


var sacco= ''
var bizuserid= ''
var token= ''

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class ReceiptToPrint extends React.Component {

  state = {
    companyprofile:{}
  };

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })

  

  }

//calculate principleTotal
calculateScheduleTotal=()=>{
  var principleTotal=0
  this.props.loanschedule.map(
    (item)=>{
      principleTotal+=Number(item.principle)
    })

  return principleTotal.toFixed(2);
}

  //calculate principleTotal
  calculateInterestTotal=()=>{
    var InterestTotal=0
    this.props.loanschedule.map(
      (item)=>{
        InterestTotal+=Number(item.interest)
      })

    return InterestTotal.toFixed(2);
  }

  //calculate principleTotal
  calculateTotalPay=()=>{
    var TotalPay=0
    this.props.loanschedule.map(
      (item)=>{
        TotalPay+=Number(item.total)
      })

    return TotalPay.toFixed(2);
  }



  render() {
    return (
      <div style={{padding:20}}>
        <style>
          {`@media print {
          @page { size: landscape; }
            }`}
        </style>
            
      <Row> 
                <Col xs="2" sm="2" lg="2">
                <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.sacco_logo} />
              
                  </Col>
                  <Col xs="6" sm="6" lg="6">
                  <h3>{this.state.companyprofile.sacco_name}</h3>
                  <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                  {
                  this.state.companyprofile.RegisteredPhoneNumber2==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber2
                  }
                  {
                  this.state.companyprofile.RegisteredPhoneNumber3==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber3
                  }
                  </h6>               
                  <h5>Email: {this.state.companyprofile.company_email}</h5>
                  <h5>Wesite: {this.state.companyprofile.website}</h5>
                  <h5>{this.state.companyprofile.box_number}</h5>

                  </Col>

              </Row>
                  <h3 style={{fontWeight:'bolder',display: 'flex',justifyContent:'center', alignItems:'center'}}> LOAN SCHEDULE </h3>
                 

                  <Divider></Divider>
                  <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-start'}}>
                      {/**Left */}
                    <div style={{width:'50%'}}>
                    <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> ACCOUNT NUMBER:  {this.props.loanissueobject.accountNumber} </h4>
                    <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> ACCOUNT NAME:  {this.props.loanissueobject.accountName} </h4>

                    <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}>LOAN OPENING BALANCE: <CurrencyFormat value={Number(this.props.loanissueobject.topup_loanbalance).toFixed(2)} displayType={'text'} thousandSeparator={true}/> </h4>
                    <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}>DISBURSED AMOUNT: <CurrencyFormat value={Number(this.props.loanissueobject.loan_topupamount)>0?Number(this.props.loanissueobject.loan_topupamount).toFixed(2):Number(this.props.loanissueobject.approved_amount).toFixed(2)} displayType={'text'} thousandSeparator={true}/> </h4>
                    <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}>TOTAL AMOUNT: <CurrencyFormat value={Number(this.props.loanissueobject.approved_amount).toFixed(2)} displayType={'text'} thousandSeparator={true}/> </h4>
                    </div>

                      {/**Right */}

                    <div style={{width:'50%'}}>
                     <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}>PERIOD: {this.props.loanissueobject.loan_period} {this.props.loanissueobject.loan_primary_schedule==="daily"?"Days":this.props.loanissueobject.loan_primary_schedule==="weekly"?"Weeks":"Months"}</h4>
                     <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}>ANNUAL INTEREST RATE: {this.props.interest_rate}</h4>
                     <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'end'}}> DATE OF ISSUANCE: {this.props.loanissueobject.date}</h4>

                    </div>

                  </div>
                  <Divider></Divider>



                  <reactstrp.Table bordered>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Date</th>
                            <th>Month</th>
                            <th>Principle Bf</th>
                            <th>Installment</th>
                            <th>Interest </th>
                            <th>Principle </th>
                            <th>Principle Bal</th>
                          </tr>
                        </thead>
                        <tbody>
                        {this.props.loanschedule.map(
                          (item)=>(
                            <tr>
                            <td>{item.repayment_number}</td>
                            <td>{item.date}</td>
                            <td>{item.payment_month}</td>
                            <td className="align-right">{<CurrencyFormat value={item.principlebroughtforward} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td className="align-right">{<CurrencyFormat value={item.total} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td className="align-right">{<CurrencyFormat value={item.interest} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td className="align-right">{<CurrencyFormat value={item.principle} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td className="align-right">{<CurrencyFormat value={item.balance} displayType={'text'} thousandSeparator={true}/>}</td>
                            </tr>
                          ))}
                          <tr>
                          <th>Total</th>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalPay()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateInterestTotal()} displayType={'text'} thousandSeparator={true}/>}</td> 
                          <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateScheduleTotal()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td></td>
                          </tr>

                      </tbody>
                  </reactstrp.Table>

    </div>
    );
  }
}


class StatementToPrint extends React.Component {

  state = {
    companyprofile:{}
  };

  componentDidMount(){
    if(localStorage.getItem("sacco")){
      sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
   }else{
      sacco= ''
   }
   
   if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })

  }

    //calculate principleTotal
    calculateTotalPay=()=>{
      var TotalPay=0
      this.state.loanschedule.map(
        (item)=>{
          TotalPay+=Number(item.total)
        })
  
      return TotalPay.toFixed(2);
    }


      //calculate principle paid
      calculateTotalInterestcharged=()=>{
      var TotalPay=0
      this.props.loanrepayments.map(
        (item)=>{
          TotalPay+=Number(item.interest_charged)
        })
  
      return TotalPay.toFixed(2);
     }
  
      //calculate Interest paid
      calculateTotalInterestPaid=()=>{
        var TotalPay=0
        this.props.loanrepayments.map(
          (item)=>{
            TotalPay+=Number(item.interest_paid)
          })
    
        return TotalPay.toFixed(2);
       }

             //calculate Total total paid
      calculateTotalprinciplepaid=()=>{
        var TotalPay=0
        this.props.loanrepayments.map(
          (item)=>{
            TotalPay+=Number(item.principle_paid)
          })
    
        return TotalPay.toFixed(2);
       }

       //calculate total fine paid
       calculateTotalFinePaid=()=>{
        var TotalPay=0
        this.props.loanrepayments.map(
          (item)=>{
            TotalPay+=Number(item.fine_paid)
          })
    
        return TotalPay.toFixed(2);
       }


  render() {
    return (
      <div style={{padding:20}}>      
      <style>
        {`@media print {
        @page { size: landscape; }
          }`}
      </style>

      <Row> 
            <Col xs="2" sm="2" lg="2">
            <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.sacco_logo} />
          
              </Col>
              <Col xs="6" sm="6" lg="6">
              <h3>{this.state.companyprofile.sacco_name}</h3>
              <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
              {
              this.state.companyprofile.RegisteredPhoneNumber2==""?
              "":","+this.state.companyprofile.RegisteredPhoneNumber2
              }
              {
              this.state.companyprofile.RegisteredPhoneNumber3==""?
              "":","+this.state.companyprofile.RegisteredPhoneNumber3
              }
              </h6>               
              <h5>Email: {this.state.companyprofile.company_email}</h5>
              <h5>Wesite: {this.state.companyprofile.website}</h5>
              <h5>{this.state.companyprofile.box_number}</h5>

              </Col>

          </Row>
              <h3 style={{fontWeight:'bolder',display: 'flex',justifyContent:'center', alignItems:'center'}}> LOAN TRACKING STATEMENT </h3>
              <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> ACCOUNT NUMBER:  {this.props.loanissueobject.accountNumber} </h4>
              <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> ACCOUNT NAME:  {this.props.loanissueobject.accountName} </h4>
              <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> AMOUNT: <CurrencyFormat value={this.props.loanissueobject.approved_amount} displayType={'text'} thousandSeparator={true}/> UGX</h4>
              <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> PERIOD: {this.props.loanissueobject.loan_period} {this.props.loanissueobject.loan_primary_schedule==="daily"?"Days":this.props.loanissueobject.loan_primary_schedule==="weekly"?"Weeks":"Months"}</h4>
              <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> INTEREST RATE: {this.props.interest_rate}</h4>
              <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'end'}}> DATE OF ISSUANCE: {this.props.loanissueobject.date}</h4>
              <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'end'}}> LOAN NUMBER: {this.props.loanissueobject.loan_number}</h4>

              <reactstrp.Table bordered>
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>No Of days</th>
                        <th>Open R/Ln Bal.</th>
                        <th>Installment Paid</th>
                        <th>Interest charged</th>
                        <th>Int Paid</th>
                        <th>Principle1 (Paid)</th>
                        <th>Unpaid Int</th>
                        <th>R/Int</th>
                        <th>R/Princ or Loan Issued</th>
                        <th>Outstanding Bal</th>
                        <th>Payment Details</th>
                      </tr>
                    </thead>
                    <tbody>
                    {this.props.loanrepayments.map(
                      (item)=>(
                        <tr>
                        <td>{item.date}</td>
                        <td>{item.no_ofdays}</td>
                        <td className="align-right">{<CurrencyFormat value={item.open_runningbal.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td className="align-right">{<CurrencyFormat value={item.installment_paid.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td className="align-right">{<CurrencyFormat value={item.interest_charged.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td className="align-right">{<CurrencyFormat value={item.interest_paid.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td className="align-right">{<CurrencyFormat value={item.principle_paid.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>

                        <td className="align-right">{<CurrencyFormat value={item.interest_unpaid.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td className="align-right">{<CurrencyFormat value={item.running_int.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                        
                        <td className="align-right">{<CurrencyFormat value={item.running_principle.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td className="align-right">{<CurrencyFormat value={item.outstanding_bal.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td>{item.description}</td>

                        </tr>
                      ))}
                      <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>

                      <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalInterestcharged()} displayType={'text'} thousandSeparator={true}/>}</td>
                      <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalInterestPaid()} displayType={'text'} thousandSeparator={true}/>}</td>
                      <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalprinciplepaid()} displayType={'text'} thousandSeparator={true}/>}</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      </tr>

                  </tbody>
            </reactstrp.Table>   
    </div>
    );
  }
}



class LoanExtras extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    viewpaymentmodal:false,
    expensedetails:{},
    userprofile:{},
    datarequested:true,

    loansecurites: [],
    security_percentages:{},

    loanschedule:[],
    loanissueobject:{},
    loanrepayments:[],
    loandailyschedules:[],

    deletelist:[],
    totaltobepaidobject:{},
    interestpay:0,

    newreschdate:'',
    newreschamt:0,

    current_selected_loan:0,
    loan_particular_data_loading:false,
    fullypaid_loans: [],


    show_details:false,
    selected_id:'',
    selected_record:{},

    security_totals:{}

  };


  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    
    }else{
       sacco= ''
       bizuserid= ''
    
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    let form_data_1 = new FormData();
    form_data_1.append('userid', bizuserid);
    
    //get the user profile here
    axios.post(serverconfig.backendserverurl+'/customqueries/get_user_profile',form_data_1,{
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res => {
        this.setState({
          userprofile:res.data
        })

        this.setState({datarequested:false})
    })


    //////////////////////////////////
    //get loan attributes
    //////////////////////////
    //////////////////
    //////////////////////////

    this.setState({loan_particular_data_loading:true})

    //set curent id
    this.setState({current_selected_loan:this.props.loan_id})

    var loanissueID=this.props.loan_id

    //Now submit sale data to database
    axios.get(serverconfig.backendserverurl+`/customqueries/get_allloansecurities/${this.props.loan_id}`,{
      headers: {
        'content-type': 'multipart/form-data'
      }})
    .then(res =>{
      this.setState({loansecurites:JSON.parse(res.data.report)})
      this.setState({security_percentages:res.data.percentages})

      this.setState({security_totals:res.data.totals})

    }).catch(error => console.log(error)) 




    //get other data
    axios.get(`${serverconfig.backendserverurl}/api/loanissues/${loanissueID}`)
    .then(res => {  
        this.setState({loanissueobject: res.data})

        this.setState({newreschdate:res.data.date})
        this.setState({newreschamt:res.data.approved_amount})

    })

    axios.get(`${serverconfig.backendserverurl}/api/loanschedules/?loanissue=${loanissueID}`)
    .then(res => {  
        this.setState({loanschedule: res.data})
    })

    axios.get(`${serverconfig.backendserverurl}/api/loanrepayments/?loanissue=${loanissueID}`)
    .then(res => {  
        this.setState({loanrepayments: res.data})
    })


    this.setState({loan_particular_data_loading:false})


  //end of loan atriburtes


}



getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};



  //calculate principleTotal
  calculateScheduleTotal=()=>{
    var principleTotal=0
    this.state.loanschedule.map(
      (item)=>{
        principleTotal+=Number(item.principle)
      })

    return principleTotal.toFixed(2);
  }

    //calculate principleTotal
    calculateInterestTotal=()=>{
      var InterestTotal=0
      this.state.loanschedule.map(
        (item)=>{
          InterestTotal+=Number(item.interest)
        })
  
      return InterestTotal.toFixed(2);
    }

    //calculate principleTotal
    calculateTotalPay=()=>{
      var TotalPay=0
      this.state.loanschedule.map(
        (item)=>{
          TotalPay+=Number(item.total)
        })
  
      return TotalPay.toFixed(2);
    }


      //calculate principle paid
      calculateTotalInterestcharged=()=>{
      var TotalPay=0
      this.state.loanrepayments.map(
        (item)=>{
          TotalPay+=Number(item.interest_charged)
        })
  
      return TotalPay.toFixed(2);
     }
  
      //calculate Interest paid
      calculateTotalInterestPaid=()=>{
        var TotalPay=0
        this.state.loanrepayments.map(
          (item)=>{
            TotalPay+=Number(item.interest_paid)
          })
    
        return TotalPay.toFixed(2);
       }

             //calculate Total total paid
      calculateTotalprinciplepaid=()=>{
        var TotalPay=0
        this.state.loanrepayments.map(
          (item)=>{
            TotalPay+=Number(item.principle_paid)
          })
    
        return TotalPay.toFixed(2);
       }

       //calculate total fine paid
       calculateTotalFinePaid=()=>{
        var TotalPay=0
        this.state.loanrepayments.map(
          (item)=>{
            TotalPay+=Number(item.fine_paid)
          })
    
        return TotalPay.toFixed(2);
       }


       //calculate total fine paid
       convert_calinterest=()=>{
        var Val_u=0

        if(this.state.loanissueobject.loan_primary_schedule==="monthly"){
          Val_u=Number(this.state.loanissueobject.loan_annual_interestrate)/12
        }else if(this.state.loanissueobject.loan_primary_schedule==="weekly"){
          Val_u=Number(this.state.loanissueobject.loan_annual_interestrate)/52
        }else if(this.state.loanissueobject.loan_primary_schedule==="daily"){
          Val_u=Number(this.state.loanissueobject.loan_annual_interestrate)/365
        }

        return Val_u.toFixed(2);
       }




       


  render() {


    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )
      
    }else{
      return(
        <div>

    <div style={{display: "none"}}>
      &nbsp;&nbsp;
      <ReceiptToPrint
      loanschedule={this.state.loanschedule} 
      date={this.state.datetoday} 
      loanissueobject={this.state.loanissueobject}
      interest_rate={this.convert_calinterest()}
      ref={el => (this.componentRef = el)} /> 
      </div>

      <div style={{display: "none"}}>
       &nbsp;&nbsp;
      <StatementToPrint
      loanrepayments={this.state.loanrepayments} 
      date={this.state.datetoday} 
      loanissueobject={this.state.loanissueobject}
      interest_rate={this.convert_calinterest()}
      ref={sel => (this.componentReftwo = sel)} /> 
      </div>


      {
                        this.state.loan_particular_data_loading===true?
                        <div style={{display:'flex',margin:20}}>
                        <Spin indicator={antIcon} />
                        </div>
                        :


                        <Tabs defaultActiveKey="03" onChange={callback}>
    

                      <TabPane tab="Loan tracker" key="03" >
                        
                        <Card>
                          <h4 style={{fontStyle:'oblique',fontWeight:'bolder'}}>LOAN TRACKING STATEMENT</h4>
                                
                          <br></br>
                        <div style={{display:'flex',justifyContent:'flex-start',alignSelf:'flex-start',alignItems:'flex-start'}}>
                        <a>
                        <ReactToPrint
                          trigger={() =>                     
                            <div style={{}}>
                              <Tooltip title="Click to print" placement="top">
                              <p style={{alignSelf:'center'}}><PrinterOutlined style={{ fontSize: '25px', color: '#08c' }}/></p>
                              <h6 style={{alignSelf:'center'}}>Print </h6>
                              </Tooltip>
                            </div>                          
                              }
                              content={() => this.componentReftwo}
                            />  
                      </a>
                          </div>
                          <Divider></Divider>
                          
                          <reactstrp.Table bordered>
                              <thead>
                                <tr>
                                  <th>Date</th>
                                  <th>No Of days</th>
                                  <th>Open R/Ln Bal.</th>
                                  <th>Installment Paid</th>
                                  <th>Interest charged</th>
                                  <th>Int Paid</th>
                                  <th>Principle1 (Paid)</th>
                                  <th>Unpaid Int</th>
                                  <th>R/Int</th>
                                  <th>R/Princ or Loan Issued</th>
                                  <th>Outstanding Bal</th>
                                  <th>Payment Details</th>
                                  <th>Journal Number</th>
                                </tr>
                              </thead>
                              <tbody>
                              {this.state.loanrepayments.map(
                                (item)=>(
                                  <tr>
                                  <td>{item.date}</td>
                                  <td>{item.no_ofdays}</td>
                                  <td className="align-right">{<CurrencyFormat value={item.open_runningbal.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td className="align-right">{<CurrencyFormat value={item.installment_paid.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td className="align-right">{<CurrencyFormat value={item.interest_charged.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td className="align-right">{<CurrencyFormat value={item.interest_paid.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td className="align-right">{<CurrencyFormat value={item.principle_paid.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>

                                  <td className="align-right">{<CurrencyFormat value={item.interest_unpaid.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td className="align-right">{<CurrencyFormat value={item.running_int.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                
                                  <td className="align-right">{<CurrencyFormat value={item.running_principle.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td className="align-right">{<CurrencyFormat value={item.outstanding_bal.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td>{item.description}</td>
                                  <td>{item.journal_no}</td>

                                  </tr>
                                ))}
                                <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>

                                <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalInterestcharged()} displayType={'text'} thousandSeparator={true}/>}</td>
                                <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalInterestPaid()} displayType={'text'} thousandSeparator={true}/>}</td>
                                <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalprinciplepaid()} displayType={'text'} thousandSeparator={true}/>}</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>

                                </tr>

                            </tbody>
                        </reactstrp.Table>

                          </Card>

                        </TabPane>


                        <TabPane tab="Loan amortization" key="02" >
                       <Card>
                          <h4 style={{fontStyle:'oblique',fontWeight:'bolder'}}>LOAN SCHEDULE</h4>
      
                          <br></br>
                        <div style={{display:'flex',justifyContent:'flex-start',alignSelf:'flex-start',alignItems:'flex-start'}}>
      
                        <a>
                            <ReactToPrint
                              trigger={() =>                     
                                <div style={{}}>
                                  <Tooltip title="Click to print" placement="top">
                                  <p style={{alignSelf:'center'}}><PrinterOutlined style={{ fontSize: '25px', color: '#08c' }}/></p>
                                  <h6 style={{alignSelf:'center'}}>Print </h6>
                                  </Tooltip>
                                </div>                          
                                  }
                                  content={() => this.componentRef}
                                />  
                                </a>
                        </div>
                        <br></br>
                        <Divider></Divider>
                        <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}>LOAN OPENING BALANCE: <CurrencyFormat value={Number(this.state.loanissueobject.topup_loanbalance).toFixed(2)} displayType={'text'} thousandSeparator={true}/> </h4>
                        <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}>DISBURSED AMOUNT: <CurrencyFormat value={Number(this.state.loanissueobject.loan_topupamount)>0?Number(this.state.loanissueobject.loan_topupamount).toFixed(2):Number(this.state.loanissueobject.approved_amount).toFixed(2)} displayType={'text'} thousandSeparator={true}/> </h4>
                        <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}>TOTAL AMOUNT: <CurrencyFormat value={Number(this.state.loanissueobject.approved_amount).toFixed(2)} displayType={'text'} thousandSeparator={true}/> </h4>

                        <Divider></Divider>
                        
                          <reactstrp.Table bordered>
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>Date</th>
                                  <th>Month</th>
                                  <th>Principle Bf</th>
                                  <th>Installment</th>
                                  <th>Interest </th>
                                  <th>Principle </th>
                                  <th>Principle Bal</th>
                                </tr>
                              </thead>
                              <tbody>
                              {this.state.loanschedule.map(
                                (item)=>(
                                  <tr>
                                  <td>{item.repayment_number}</td>
                                  <td>{item.date}</td>
                                  <td>{item.payment_month}</td>
                                  <td className="align-right">{<CurrencyFormat value={item.principlebroughtforward} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td className="align-right">{<CurrencyFormat value={item.total} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td className="align-right">{<CurrencyFormat value={item.interest} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td className="align-right">{<CurrencyFormat value={item.principle} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td className="align-right">{<CurrencyFormat value={item.balance} displayType={'text'} thousandSeparator={true}/>}</td>
                                  </tr>
                                ))}
                                <tr>
                                <th>Total</th>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalPay()} displayType={'text'} thousandSeparator={true}/>}</td>
                                <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateInterestTotal()} displayType={'text'} thousandSeparator={true}/>}</td> 
                                <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateScheduleTotal()} displayType={'text'} thousandSeparator={true}/>}</td>
                                <td></td>
                                </tr>
      
                            </tbody>
                        </reactstrp.Table>

       
                       </Card>
      
                        </TabPane>
      
                      
      
                        <TabPane tab="Loan Securities and guarantees" key="01" >
                          <reactstrp.Table bordered>
                              <thead>
                              <tr>
                                  <th>
                                    <div>
                                      <h5>Applicant Total:
                                      <CurrencyFormat value={this.state.security_percentages.totalAll_applicant} displayType={'text'} thousandSeparator={true}/>
                                       </h5>
                                      <h5>Guarantor Total:
                                      <CurrencyFormat value={this.state.security_percentages.totalAll_gurantor} displayType={'text'} thousandSeparator={true}/>
                                      </h5>
                                    </div>
                                  </th>

                                  <th>
                                  <div>
                                      <h5>Applicant %: {this.state.security_percentages.totalAll_applicant_percentage}</h5>
                                      <h5>Guarantor %: {this.state.security_percentages.totalAll_gurantor_percentage}</h5>
                                    </div>

                                  </th>


                                  <th>
                                    <ExcelFile filename={"Pisacco - loan_"+this.state.loanissueobject.loan_number+" securities"}  element={<Button type='primary' icon={<DownloadOutlined/>}>Download (Excel)</Button>}>
                                      <ExcelSheet data={this.state.loansecurites} name={"Loan securities and Guarantees"}>
                                          <ExcelColumn label="No" value="item_no"/>
                                          <ExcelColumn label="Security" value="security_type"/>
                                          <ExcelColumn label="Contribution by" value="contribution_by"/>
                                          <ExcelColumn label="Total security" value="security_amount"/>
                                          <ExcelColumn label="Limit % (85 0r 70)" value="guaranting_percentage_70_or_85"/>
                                          <ExcelColumn label="Percentage Off Total (Applicant or Guarantor)" value="percentage_off_guarantingtotal"/>
                                          <ExcelColumn label="Freeing Percentage" value="security_freeingpercentage"/>
                                          <ExcelColumn label="Total Paid" value="installment_paid"/>
                                          <ExcelColumn label="Total Freed" value="total_freed"/>
                                          <ExcelColumn label="Running Balance" value="running_balance"/>
                                      </ExcelSheet>

                                        {
                                          this.state.loansecurites.map(
                                            (item)=>(
                                              <ExcelSheet data={item.statement} name={item.security_type+"_"+item.accountname}>
                                              <ExcelColumn label="Date" value="date_payment"/>
                                              <ExcelColumn label="Principle Paid" value="principle_paid"/>
                                              <ExcelColumn label="Installment Paid" value="amount_paid"/>
                                              <ExcelColumn label="Amount Freed" value="amount_freed"/>
                                              <ExcelColumn label="Running Balance" value="amount_runningbalance"/>
                                              </ExcelSheet>
                                          ))

                                        }


                                    </ExcelFile>



                                  </th>

                                  <th></th>
                                  <th></th>
                                  <th></th>
                                  <th></th>
                                  <th></th>
                                  <th></th>
                                  <th></th>
                                  <th></th>

                                </tr>


                                <tr>
                                  <th>View track</th>
                                  <th>Security</th>

                                  <th>Contribution by</th>
                                  <th>Contributor name</th>
                                  <th>Total security</th>
                                  <th>Limit % (85 0r 70)</th>
                                  <th>Percentage Off Total (Applicant or Guarantor)</th>
                                  <th>Freeing Percentage</th>
                                  <th>Total Paid</th>
                                  <th>Total Freed</th>
                                  <th>Running Balance</th>

                                </tr>
                              </thead>
                              <tbody>
                              {this.state.loansecurites.map(
                                (item)=>(
                                  <React.Fragment key={item}>

                                  <tr>

                                  <td>
                                  <Tooltip title="Click view track" placement="top">
                                  <EyeFilled style={{color:'green'}}
                                    onClick={()=>{
                                      console.log("ID: "+String(item.id))
                                      this.setState({selected_id:Number(item.id)})
                                      this.setState({selected_record:item})
                                      this.setState({show_details:!this.state.show_details})
                                  }}
                                  />
                                  </Tooltip>
                                  </td>
                                  
                                  <td>{item.security_type}</td>
                                  <td>{item.contribution_by}</td>
                                  <td>{item.accountname}</td>
                                  <td className="align-right">{<CurrencyFormat value={item.security_amount} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td className="align-right">{<CurrencyFormat value={item.guaranting_percentage_70_or_85} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td>{item.percentage_off_guarantingtotal} %</td>
                                  <td>{item.security_freeingpercentage} %</td>

                                  <td className="align-right">{<CurrencyFormat value={item.installment_paid} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td className="align-right">{<CurrencyFormat value={item.total_freed} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td className="align-right">{<CurrencyFormat value={item.running_balance} displayType={'text'} thousandSeparator={true}/>}</td>

                                  </tr>


                                  {/**Individual tracker */}

                                  {
                                  this.state.show_details===true && Number(this.state.selected_id)===Number(item.id)?
                                  <tr >
                                    <td colSpan={11}>
                                      {/* Your expanded content goes here */}
                                      <div>

                                        <h4>{this.state.selected_record.security_type} Tracker</h4>

                                         <reactstrp.Table bordered>
                                        <thead>
                                          <th>Date</th>
                                          <th>Principle Paid</th>
                                          <th>Installment Paid</th>
                                          <th>Amount Freed</th>
                                          <th>Running Balance</th>

                                        </thead>
                                        <tbody>

                                        {this.state.selected_record.statement.map(
                                          (item)=>(
                                            <tr>
                                            <td>{item.date_payment}</td>  
                                            <td className="align-right">{<CurrencyFormat value={item.principle_paid} displayType={'text'} thousandSeparator={true}/>}</td>
                                            <td className="align-right">{<CurrencyFormat value={item.amount_paid} displayType={'text'} thousandSeparator={true}/>}</td>

                                            <td className="align-right">{<CurrencyFormat value={item.amount_freed} displayType={'text'} thousandSeparator={true}/>}</td>
                                            <td className="align-right">{<CurrencyFormat value={item.amount_runningbalance} displayType={'text'} thousandSeparator={true}/>}</td>

                                            </tr>
                                        ))}

                                        <tr>
                                          <th>Total</th>
                                          <th></th>
                                          <th className="align-right">{<CurrencyFormat value={this.state.selected_record.installment_paid} displayType={'text'} thousandSeparator={true}/>}</th>
                                          <th className="align-right">{<CurrencyFormat value={this.state.selected_record.total_freed} displayType={'text'} thousandSeparator={true}/>}</th>
                                          <th></th>

                                        </tr>


                                        </tbody>

                                      </reactstrp.Table>

                                      </div>
                                    </td>

                                  </tr>
                                  :
                                  null
                                  }

                                   {/**End of tracker */}

                                </React.Fragment>
                                ))}
                                <tr>
                                <th></th>
                                <th>Total</th>
                                <th></th>
                                <td></td>
                                <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.state.security_totals.security_amount_total} displayType={'text'} thousandSeparator={true}/>}</td>
                                <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.state.security_totals.guaranting_percentage_70_or_85_totals} displayType={'text'} thousandSeparator={true}/>}</td> 
                                <td></td>
                                <td></td>

                                <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.state.security_totals.total_paid} displayType={'text'} thousandSeparator={true}/>}</td>
                                <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.state.security_totals.total_freed} displayType={'text'} thousandSeparator={true}/>}</td> 
                                <td></td>

                                </tr>
                               
      
                            </tbody>
                        </reactstrp.Table>
                         
                        </TabPane>

                       {/* <TabPane tab="Certificate Generation" key="0001" >
                          <LoanCertificateGenerationForm loan_issuance={this.state.loanissueobject}/>
                        </TabPane> */}

                        <TabPane tab="Quick Actions" key="000111" >
                          <LoanQuickActions loan_id={this.props.loan_issueobj.id} />
                        </TabPane>

                        <TabPane tab="Application Details" key="0001222111" >
                          <LoanDetailsTab 
                           loanapplicationID={Number(this.props.loan_issueobj.loan)}
                          />
                        </TabPane>
              </Tabs>
          }

    </div>

    )}

  }
}

export default LoanExtras; 
