import React,{ useRef } from 'react';
import axios from 'axios'
import { Tooltip,Popover,Result,Table,Popconfirm,Input, Button,Collapse,Card,Avatar,Modal,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { PrinterOutlined, MinusOutlined, CloseCircleFilled, CheckCircleOutlined, UserOutlined } from '@ant-design/icons';
import { DeleteOutlined,SearchOutlined,CameraOutlined,PlusOutlined,EditOutlined,PlusSquareFilled,SaveOutlined,LoadingOutlined } from '@ant-design/icons';
import QrReader from 'react-qr-reader'
import uuid from 'react-uuid'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
import PrintTemplate from 'react-print';
import ReactToPrint from 'react-to-print';
import {
  Col,
  Row,
} from 'reactstrap';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import * as reactstrp from 'reactstrap';
import { ToWords } from 'to-words';

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { Title } = Typography;
const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

const toWords = new ToWords();

message.config({
  top: 200,
  duration: 2,
  maxCount: 3,
  rtl: true,
});


var sacco= ''
var username=''
var token= ''

class ShareTransferView extends React.Component {

  state = {
    date:moment().format(dateFormat).toString(),
    accountfrom:'',
    accountfromobject:{},
    accountto:'',
    accounttoobject:{},
    members:[],
    datarequested:true,
    datasubmittedsuccessfully:false,
    transferedshares:0,
    companyprofile:{}
  }

  callback(key) {
    console.log(key);
  }

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    
    }else{
       sacco= ''
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })

    axios.get(serverconfig.backendserverurl+`/api/members/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          members:res.data
        })

    })

    //get last slip number
    let form_data = new FormData();
    form_data.append('sacco', sacco);
    axios.post(serverconfig.backendserverurl+'/customqueries/getsaccolastslipnumber', form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res => {
        this.setState({lastslipnumber:res.data.lastnumber})
        this.setState({slipnumber:Number(res.data.lastnumber)+1})

        this.setState({datarequested:false})
    })
    .catch(error => console.log(error))


  }

  

  
  ////////////////////////////////////
  // PRODUCT SEARCH SELECT METHODS
   onBlur=()=> {
    console.log('blur');
  }
  
   onFocus=()=>{
    console.log('focus');
  }
  
  onSearch=(val)=> {
    console.log('search:', val);
  }

  //handle client change
  handleaccountfromChange= (e) =>{
    this.setState({ accountfrom: e});
    //make query for last transactions
    axios.get(`${serverconfig.backendserverurl}/api/members/${e}`)
      .then(res => {  
          this.setState({accountfromobject: res.data})
      })
  }

  //handle client change
  handleaccounttoChange= (e) =>{
    this.setState({ accountto: e});
    //make query for last transactions
    axios.get(`${serverconfig.backendserverurl}/api/members/${e}`)
      .then(res => {  
          this.setState({accounttoobject: res.data})
      })
  }



  handletransferedsharesChange= (e) => this.setState({ transferedshares: e.target.value});
  

  //submit the deposit now
  handleFormSubmit=(event) =>{

    if (this.state.transferedshares>Number(this.state.accountfromobject.shares)){
      message.info("Transfer Shares are not enough ")
    }else if (this.state.transferedshares===0){
      message.info("Transfered shares cant be zero")
    }else if(this.state.accountfrom===this.state.accountto){
      message.info("Share Transfer can`t happen on the same account")
    }else if(this.state.accountfrom==="" || this.state.accountto===""){
      message.info("One of the accounts is empty")
    }
    else{
      this.setState({datarequested:true})
      console.log(username)
  
      let form_data = new FormData();
      form_data.append('date',moment().format(dateFormat).toString());
      form_data.append('accountfrom', this.state.accountfrom);
      form_data.append('accountto', this.state.accountto);
      form_data.append('transferedshares', this.state.transferedshares);
      form_data.append('sacco', sacco);
      form_data.append('username', username);
  
      axios.post(serverconfig.backendserverurl+'/customqueries/sharesTransfer', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
        })
      .then(res => {
        this.setState({datarequested:false})
        message.info(res.data.message)
        if (res.data.message==="success"){
          this.setState({datasubmittedsuccessfully:true})
        }
        
      })
      .catch(error => console.log(error))
    }
    

  }



  ////////////////////////////////////////////
  // RENDERING METHOD HERE
  render() {

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      if(this.state.datasubmittedsuccessfully===true){
        return(
          <div>

            <Row >
                  <Col xs="12" sm="6" lg="6">
                    <Card>
                    <Result
                    status="success"
                    title="Successfully transfer "
                    subTitle="Successfull shares transfer"
                    extra={[
                      <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
                    ]}
                    />

                    </Card>

                    </Col>

            </Row>


          </div>

        )

      }else{
        return(
          <div>
            <div>
  
              <Row >
                  <Col xs="12" sm="6" lg="6">
                    <Card>
                    <h4 style={{fontStyle:'oblique',fontWeight:'bolder'}}>Share Transfer Form</h4>
                    <Form
                      onFinish={(event) => this.handleFormSubmit(event)}

                    >
                    <FormItem label="Account From">
                          <Select 
                          placeholder="Account No | Name" 
                          style={{  }} 
                          value={this.state.accountfrom}
                          onChange={this.handleaccountfromChange} 
                          showSearch
                          optionFilterProp="children"
                          onFocus={this.onFocus}
                          onBlur={this.onBlur}
                          onSearch={this.onSearch}
                                              
                          >
                            {this.state.members.map(
                              (accnt)=>(
                                <Option value={accnt.id}>{accnt.AccountNumber} | {accnt.FullName}</Option>
                              ))}
                          </Select>
                      </FormItem>
                      

                    <FormItem label="Account To">
                          <Select 
                          placeholder="Account No | Name" 
                          style={{  }} 
                          value={this.state.accountto}
                          onChange={this.handleaccounttoChange} 
                          showSearch
                          optionFilterProp="children"
                          onFocus={this.onFocus}
                          onBlur={this.onBlur}
                          onSearch={this.onSearch}
                                              
                          >
                            {this.state.members.map(
                              (accnt)=>(
                                <Option value={accnt.id}>{accnt.AccountNumber} | {accnt.FullName}</Option>
                              ))}
                          </Select>
                      </FormItem>

                      <FormItem label={<h4 style={{fontWeight:'bold'}}>Transfered Shares:</h4>} >
                      <Input name="transferedshares" type='number' placeholder="0" value={this.state.transferedshares} onChange={this.handletransferedsharesChange}/>
                      </FormItem>
                      
                      <FormItem label={<h4 style={{fontWeight:'bold'}}>Transfer Charges:</h4>} >
                       <h3><CurrencyFormat value={this.state.companyprofile.sharetransfercharge} displayType={'text'} thousandSeparator={true}  /></h3>
                      </FormItem>

                    <FormItem>
                      <Button  type="primary" htmlType="submit">Transfer</Button>
                    </FormItem>
                    </Form>
  
                    </Card>
                   
                  </Col>
  
                 <Col xs="12" sm="6" lg="6">
                   <Card>
                   <h3 style={{fontWeight:'bold',color:'#154360'}}>Account From Details</h3>
                   <h4>Account Number:{this.state.accountfromobject.AccountNumber}</h4>
                   <h4>Account Contact:{this.state.accountfromobject.RegisteredPhoneNumber1}</h4>
                   <h4>Account Name:{this.state.accountfromobject.FullName}</h4>
                   <h4 style={{fontWeight:'bolder'}}>Shares Balance:  {this.state.accountfromobject.shares}</h4>
                   <br></br>
                   <h3 style={{fontWeight:'bold',color:'#154360'}}>Account To Details</h3>
                   <h4>Account Number:{this.state.accounttoobject.AccountNumber}</h4>
                   <h4>Account Contact:{this.state.accounttoobject.RegisteredPhoneNumber1}</h4>
                   <h4>Account Name:{this.state.accounttoobject.FullName}</h4>
                   <h4 style={{fontWeight:'bolder'}}>Shares Balance:  {this.state.accounttoobject.shares}</h4>
                   </Card>

  
                </Col>
            </Row>
  
            </div>
  
  
             
       </div>              
                      
        )
      }





     
    
    }
   
  }
}

export default ShareTransferView; 
