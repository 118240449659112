import React from 'react';
import axios from 'axios';
import { Card, Button,Form,Input,message,Spin} from 'antd';
import IDTypeForm from '../components/IDTypeForm'
import { UserOutlined,LoadingOutlined } from '@ant-design/icons';
import * as serverconfig from '../serverconn'
import {
    Col,
    Row,
  } from 'reactstrap';
const FormItem=Form.Item;
const { Meta } = Card;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''

class IDTypeDetail extends React.Component{

    state={
        idtype: {},
        idtypeName:'' ,  
        idtypeDescription:'',
        datarequested:true,
        IDvaliditydays:0,

    }

    componentDidMount(){
        if(localStorage.getItem("token")){
          token= localStorage.getItem("token")
        }else{
          token= ''
        }

        axios.defaults.headers={
            "Content-Type":"application/json",
            Authorization:`Token ${token}`
          }

        const idtypeID=this.props.match.params.idtypeID;
        axios.get(`${serverconfig.backendserverurl}/api/idtypes/${idtypeID}`)
        .then(res => {  
            this.setState({
                idtype: res.data

            })

            this.setState({idtypeName:res.data.id_type_name})
            this.setState({idtypeDescription:res.data.description})
            this.setState({IDvaliditydays:res.data.IDvaliditydays})
            this.setState({datarequested:false})

        })
    }

    handleIdTypeNameChange= (e) => this.setState({ idtypeName: e.target.value});
    handleIDDescriptionChange= (e) => this.setState({ idtypeDescription: e.target.value});
  

    //submit button pressed
  handleFormSubmit=() =>{
    this.setState({datarequested:true})

    const idtypeID=this.props.match.params.idtypeID;

    let form_data = new FormData();
    form_data.append('id_type_name', this.state.idtypeName);
    form_data.append('description', this.state.idtypeDescription);
    form_data.append('IDvaliditydays', this.state.IDvaliditydays);

    axios.put(`${serverconfig.backendserverurl}/api/idtypes/${idtypeID}/`,form_data, {
        headers: {
        'content-type': 'multipart/form-data'
        }
    })
    .then(res =>this.setState({datarequested:false}),window.location.reload(false),message.info('Successfully updated ID'))
    .catch(error => console.log(error))


  }



    render(){

        if(this.state.datarequested===true){
            return(
              <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin indicator={antIcon} />
              </div>
            )
      
          }else{
            return(
                <div>
                    <Row>
                    <Col xs="12" sm="6" lg="4">
                    <Card title={this.state.idtype.id_type_name}>
                        <p>Desc: {this.state.idtype.description}</p>
                    </Card>
                    </Col>
                    <Col span={2}>
                 
                    </Col>
                    <Col xs="12" sm="6" lg="4">
                   
                    </Col>
    
                    </Row>
                    <br/>
    
                    <Row>
                    <Col xs="12" sm="6" lg="4">
                    <Card 
                    style={{padding:10}}
                    hoverable>
                        <h4>ID Update</h4>
                        <Form>
                            <FormItem label="Product Name">
                            <Input name="idtypename"  placeholder="Put a name here." value={this.state.idtypeName} onChange={this.handleIdTypeNameChange} />
                            </FormItem>
                            <FormItem label="Description">
                            <Input name="description"  placeholder="Description here" value={this.state.idtypeDescription} onChange={this.handleIDDescriptionChange} />
                            </FormItem>

                          <FormItem label="Validity days"  
                          >
                            <Input name="IDvaliditydays"  placeholder="ID validity days here" value={this.state.IDvaliditydays} onChange={(val)=>{this.setState({IDvaliditydays:val.target.value})}} />
                          </FormItem>
                        
                            <FormItem>
                            <Button onClick={(event) => this.handleFormSubmit()} type="primary" htmlType="button">Update</Button>
                            </FormItem>
                        </Form>
    
                    </Card>
    
                    </Col>
    
                  
                    </Row>
    
                    <br/>
    
                </div>
            )


          }

       
    }
}

export default IDTypeDetail; 