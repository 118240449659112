import React from 'react';
import { Form, Input, Button,Select,Spin } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var sacco= ''
var token= ''

class AccountTypeForm extends React.Component {

  state = {
    account_type_name:'',
    minimum_account_balance:0,
    deposit_charges:0,
    minimum_account_deposit:0,
    withdraw_charges:0,
    withdrawals_per_month:0,
    account_fine_fee:0,
    account_transfer_charge:0,
    account_monthly_charge:0,
    account_monthly_interest_rate:0,
    account_statement_fee:0,
    cheque_deposit_fee:0,
    account_reactivation_charge_afterdormancy:0,
    withholdingtax_percentage_oninterest:0,
    minimum_shares_foraccountactibation:0,
    monthlymandatorysaving:0,
    monthlymandatorysaving_fine:0,
    nooftimes_finedbfdormancy:0,

    setmonthsharestarget:0,
    maximumshares_bysetmonthsharestarget:0,
    maximumshare_percentage:0,
    agelimit_months_cap:0,
    nonrefund_membership:0

  }

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    this.setState({datarequested:false})
}


  //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})

    let form_data = new FormData();
    form_data.append('account_type_name', this.state.account_type_name);
    form_data.append('minimum_account_balance', this.state.minimum_account_balance);
    form_data.append('minimum_account_deposit', this.state.minimum_account_deposit);
    form_data.append('withdraw_charges', this.state.withdraw_charges);
    form_data.append('withdrawals_per_month', this.state.withdrawals_per_month);
    form_data.append('account_monthly_interest_rate', this.state.account_monthly_interest_rate);
    form_data.append('account_reactivation_charge_afterdormancy', this.state.account_reactivation_charge_afterdormancy);
    form_data.append('withholdingtax_percentage_oninterest', this.state.withholdingtax_percentage_oninterest);
    form_data.append('minimum_shares_foraccountactibation', this.state.minimum_shares_foraccountactibation);
    form_data.append('monthlymandatorysaving', this.state.monthlymandatorysaving);
    form_data.append('monthlymandatorysaving_fine', this.state.monthlymandatorysaving_fine);
    form_data.append('nooftimes_finedbfdormancy', this.state.nooftimes_finedbfdormancy);

    form_data.append('setmonthsharestarget', this.state.setmonthsharestarget);
    form_data.append('maximumshares_bysetmonthsharestarget', this.state.maximumshares_bysetmonthsharestarget);
    form_data.append('maximumshare_percentage', this.state.maximumshare_percentage);
    form_data.append('agelimit_months_cap', this.state.agelimit_months_cap);
    form_data.append('nonrefund_membership', this.state.nonrefund_membership);

    form_data.append('sacco', sacco);
    
    axios.post(serverconfig.backendserverurl+'/api/accounttypes/', form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  .then(res =>         this.setState({datarequested:false})
,  window.location.reload(false)
  )
  .catch(error => console.log(error))

  }


  handleaccount_type_nameChange= (e) => this.setState({ account_type_name: e.target.value});
  handleminimum_account_balanceChange= (e) => this.setState({ minimum_account_balance: e.target.value});
  handleminimum_account_depositChange= (e) => this.setState({ minimum_account_deposit: e.target.value});
  handlewithdraw_chargesChange= (e) => this.setState({ withdraw_charges: e.target.value});
  handlewithdrawals_per_monthChange= (e) => this.setState({ withdrawals_per_month: e.target.value});
  handleaccount_transfer_chargeChange= (e) => this.setState({ account_transfer_charge: e.target.value});
  handleaccount_monthly_interest_rateChange= (e) => this.setState({ account_monthly_interest_rate: e.target.value});


  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{

      return (
        <div>
          <Form
            onFinish={(event) => this.handleFormSubmit(event)} 
          >
            <FormItem label="Member Type Name"
              name="account_type_name"
              rules={[
                {
                  required: true,
                  message: 'Please enter name',
                },
              ]}             
            >
              <Input name="account_type_name"  placeholder="Put a name here." value={this.state.account_type_name} onChange={this.handleaccount_type_nameChange} />
            </FormItem>

            <FormItem label="Minimum Account Balance"
              name="minimum_account_balance"
              rules={[
                {
                  required: true,
                  message: 'Please enter values',
                },
              ]}           
            >
              <Input name="minimum_account_balance"  placeholder="0.00" value={this.state.minimum_account_balance} onChange={this.handleminimum_account_balanceChange} />
            </FormItem>
  

  
            <FormItem label="Minimum Account Deposit"
              name="minimum_account_deposit"
              rules={[
                {
                  required: true,
                  message: 'Please enter values',
                },
              ]}           
            >
              <Input name="minimum_account_deposit"  placeholder="0.00" value={this.state.minimum_account_deposit} onChange={this.handleminimum_account_depositChange} />
            </FormItem>


            <FormItem label="Withdraw Charges"
              name="withdraw_charges"
              rules={[
                {
                  required: true,
                  message: 'Please enter values',
                },
              ]}           
            >
              <Input name="withdraw_charges"  placeholder="0.00" value={this.state.withdraw_charges} onChange={this.handlewithdraw_chargesChange} />
            </FormItem>


            <FormItem label="Account Monthly Interest Rate"
              name="account_monthly_interest_rate"
              rules={[
                {
                  required: true,
                  message: 'Please enter values',
                },
              ]}           
            >
              <Input name="account_monthly_interest_rate"  placeholder="0.00" value={this.state.account_monthly_interest_rate} onChange={this.handleaccount_monthly_interest_rateChange} />
            </FormItem>

            <FormItem label="Withholding tax percentage"
              name="withholdingtax_percentage_oninterest"
              rules={[
                {
                  required: true,
                  message: 'Please enter values',
                },
              ]}           
            >
              <Input name="withholdingtax_percentage_oninterest"  placeholder="0.00" value={this.state.withholdingtax_percentage_oninterest} onChange={(val)=>{this.setState({withholdingtax_percentage_oninterest:val.target.value})}} />
            </FormItem>


            <FormItem label="Minimum Shares for Account Activation"
              name="minimum_shares_foraccountactibation"
              rules={[
                {
                  required: true,
                  message: 'Please enter values',
                },
              ]}           
            >
              <Input name="minimum_shares_foraccountactibation"  placeholder="0.00" value={this.state.minimum_shares_foraccountactibation} onChange={(val)=>{this.setState({minimum_shares_foraccountactibation:val.target.value})}} />
            </FormItem>

            <FormItem label="Monthly Mandatory Saving"
              name="monthlymandatorysaving"
              rules={[
                {
                  required: true,
                  message: 'Please enter values',
                },
              ]}           
            >
              <Input name="monthlymandatorysaving"  placeholder="0.00" value={this.state.monthlymandatorysaving} onChange={(val)=>{this.setState({monthlymandatorysaving:val.target.value})}} />
            </FormItem>

            <FormItem label="Monthly Mandatory Saving Fine"
              name="monthlymandatorysaving_fine"
              rules={[
                {
                  required: true,
                  message: 'Please enter values',
                },
              ]}           
            >
              <Input name="monthlymandatorysaving_fine"  placeholder="0.00" value={this.state.monthlymandatorysaving_fine} onChange={(val)=>{this.setState({monthlymandatorysaving_fine:val.target.value})}} />
            </FormItem>

            <FormItem label="No of times account fined before dormancy"
              name="nooftimes_finedbfdormancy"
              rules={[
                {
                  required: true,
                  message: 'Please enter values',
                },
              ]}           
            >
              <Input name="nooftimes_finedbfdormancy"  placeholder="0.00" value={this.state.nooftimes_finedbfdormancy} onChange={(val)=>{this.setState({nooftimes_finedbfdormancy:val.target.value})}} />
            </FormItem>

            <FormItem label="Account Reactivation Charge after dormancy."
              name="account_reactivation_charge_afterdormancy"
              rules={[
                {
                  required: true,
                  message: 'Please enter values',
                },
              ]}           
            >
              <Input name="account_reactivation_charge_afterdormancy"  placeholder="0.00" value={this.state.account_reactivation_charge_afterdormancy} onChange={(val)=>{this.setState({account_reactivation_charge_afterdormancy:val.target.value})}} />
            </FormItem>


            <FormItem label="Number of days for shares target."
              name="setmonthsharestarget"
              rules={[
                {
                  required: true,
                  message: 'Please enter values',
                },
              ]}           
            >
              <Input name="setmonthsharestarget"  placeholder="0" value={this.state.setmonthsharestarget} onChange={(val)=>{this.setState({setmonthsharestarget:val.target.value})}} />
            </FormItem>


            <FormItem label="Minimum shares by target days."
              name="maximumshares_bysetmonthsharestarget"
              rules={[
                {
                  required: true,
                  message: 'Please enter values',
                },
              ]}           
            >
              <Input name="maximumshares_bysetmonthsharestarget"  placeholder="0" value={this.state.maximumshares_bysetmonthsharestarget} onChange={(val)=>{this.setState({maximumshares_bysetmonthsharestarget:val.target.value})}} />
            </FormItem>


            <FormItem label="Account maximum share percentage"
              name="maximumshare_percentage"
              rules={[
                {
                  required: true,
                  message: 'Please enter values',
                },
              ]}           
            >
              <Input name="maximumshare_percentage"  placeholder="0" value={this.state.maximumshare_percentage} onChange={(val)=>{this.setState({maximumshare_percentage:val.target.value})}} />
            </FormItem>

            <FormItem label="Minimum membership age (days)"
              name="agelimit_months_cap"
              rules={[
                {
                  required: true,
                  message: 'Please enter months',
                },
              ]}           
            >
              <Input name="agelimit_months_cap"  placeholder="number of days" value={this.state.agelimit_months_cap} onChange={(val)=>{this.setState({agelimit_months_cap:val.target.value})}} />
            </FormItem>


            <FormItem label="Non refundable membership fee"
              name="nonrefund_membership"
              rules={[
                {
                  required: true,
                  message: 'Please enter values',
                },
              ]}           
            >
              <Input name="nonrefund_membership"  placeholder="0.00" value={this.state.nonrefund_membership} onChange={(val)=>{this.setState({nonrefund_membership:val.target.value})}} />
            </FormItem>


            <FormItem>
              <Button  type="primary" htmlType="submit">Create</Button>
            </FormItem>
          </Form>
        </div>
      );

    }

  }

}


export default AccountTypeForm;



