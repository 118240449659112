import React from 'react';
import { Form, Input, Button,Select,Spin,Switch,Tooltip,Tabs,Card,Image, message,Result,Modal,Popconfirm,Popover,DatePicker, Divider } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined,DownloadOutlined, MailOutlined,UploadOutlined,CloseCircleFilled } from '@ant-design/icons';
import moment from 'moment';
import {
  Col,
  Row,
} from 'reactstrap';
import * as reactstrp from 'reactstrap';
import uuid from 'react-uuid'
import CurrencyFormat from 'react-currency-format';


var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const dateFormat = 'DD/MM/YYYY';
const { TextArea } = Input;
const { TabPane } = Tabs;

var sacco= ''
var username=''
var token= ''

class LoanAssessmentForm extends React.Component {

  state = {
    loanapplications:[],
    date:moment().format(dateFormat).toString(),
    applicant_location:false,
    own_premises:false,
    legal_status_of_business:false,
    duration_in_business:false,
    monthly_net_profit:false,
    guarantors:false,
    consents:false,
    report_details:'',
    loan_checklist:null,
    collateral_one:'',
    collateral_one_value:0,
    collateral_one_image:null,
    datarequested:true,
    loanapplicationobject:{},
    datasubmittedsuccessfully:false,
    collateralformvisible:false,
    collaterallist:[],

    companyprofile:{},

  }

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
   
    }else{
       sacco= ''
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }


    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
      this.setState({
        companyprofile: res.data
      })

    const loanapplicationID=this.props.loanapplicationID;
    axios.get(`${serverconfig.backendserverurl}/api/loanapplications/${loanapplicationID}`)
    .then(res => {  
        this.setState({loanapplicationobject: res.data})

        this.setState({datarequested:false})

    })


  })

 
  }

  //submit button pressed
  handleFormSubmit=(event) =>{
    const loanapplicationID=this.props.loanapplicationID;

    this.setState({datarequested:true})

    let form_data = new FormData();
    form_data.append('loan_application_id', loanapplicationID);
    form_data.append('remarks', this.state.report_details);
    form_data.append('username', username);
    form_data.append('date', this.state.date);

    axios.post(serverconfig.backendserverurl+'/customqueries/pass_loan_for_approval ', form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  .then(res =>  {
    this.setState({datarequested:false})
    message.info(res.data.message)
    window.location.reload(false)

  }).catch(error => console.log(error))


  }

  handleapplicant_locationChange= (e) =>{
    console.log(e)
    this.setState({ applicant_location: e});
  } 
  handleown_premisesChange= (e) => this.setState({ own_premises: e});
  handlelegal_status_of_businessChange= (e) => this.setState({ legal_status_of_business: e});

  handleduration_in_businessChange= (e) => this.setState({ duration_in_business: e});
  handlemonthly_net_profitChange= (e) => this.setState({ monthly_net_profit: e});
  handleguarantorsChange= (e) => this.setState({ guarantors: e});
  handleconsentsChange= (e) => this.setState({ consents: e});

  handlereport_detailsChange= (e) => this.setState({ report_details: e.target.value});
  handlecollateral_oneChange= (e) => this.setState({ collateral_one: e.target.value});
  handlecollateral_one_valueChange= (e) => this.setState({ collateral_one_value: e.target.value});


  onBlur=()=> {
    console.log('blur');
  }
  
   onFocus=()=>{
    console.log('focus');
  }
  
  onSearch=(val)=> {
    console.log('search:', val);
  }


  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{


      if (this.state.datasubmittedsuccessfully===true){
        return(
          <Row >
            <Col xs="12" sm="6" lg="6">
            <Card>
            <Result
            status="success"
            title="Successfully Review Loan "
            subTitle="If Passed,Application will be ready for approval"
            extra={[
              <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
            ]}
            />
          </Card>
          </Col>
          </Row>
        );

      }else{

        return (
          <div style={{overflowX:'auto'}}>
             <Card 
                style={{padding:10}}
                hoverable>
                <h3 style={{color:'green'}}>Loan Review Form</h3>
  
              {
                this.state.loanapplicationobject.assessed===true?
                null
                :
                <Form
                  layout="inline"
                  onFinish={(event) => this.handleFormSubmit(event)} >

                {
                this.state.companyprofile.automated_transaction_date===false?
                <FormItem label={"Date of Review ("+this.state.date+" )"}>
                    <DatePicker onChange={(date, dateString) => this.setState({ date: dateString})} format={dateFormat} />
                </FormItem>                 
                :
                null
                }
              
    
              <FormItem label="Review Remarks"
                name="report_details"
                rules={[
                  {
                    required: true,
                    message: 'Please enter details',
                  },
                ]}            
              >
                <TextArea
                  value={this.state.report_details}
                  onChange={this.handlereport_detailsChange}
                  placeholder="Review Remarks"
                  autoSize={{ minRows: 2, maxRows: 6 }}
                />
             
              </FormItem>
             
             
              <Divider></Divider>
              <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
              <FormItem>
                
               
              <Tooltip title="Click here to pass application" placement="top">
                <Button  type="primary" htmlType="submit"> Pass For Approval </Button>
              </Tooltip>

              </FormItem>

              <FormItem>

              <Tooltip title="Click here to reverse application" placement="top">
                <Popconfirm 
                  title="Sure to reverse?" 
                  onConfirm={() => {
                    if (this.state.report_details!=""){
                      const loanapplicationID=this.props.loanapplicationID;
                      let form_data = new FormData();
                      form_data.append('loan_application_id', loanapplicationID);
                      form_data.append('remarks', this.state.report_details);
                      form_data.append('username', username);
                      form_data.append('date', this.state.date);

                      axios.post(serverconfig.backendserverurl+'/customqueries/reverse_loanto_verification', form_data, {
                        headers: {
                          'content-type': 'multipart/form-data'
                        }
                      })
                    .then(res =>  {
                      message.info(res.data.message)
                      window.location.reload(false)
                  
                    }).catch(error => console.log(error))


                    }else{
                      message.error("Please enter remarks")
                    }

                  }}>
                    <Button type='default' style={{color:'red'}}>Reverse to Verification</Button>

                </Popconfirm>
                </Tooltip>
              </FormItem>

              </div>

            </Form>
  
            }
                  
            </Card>

          </div>
        );

      }

    }

  }

}


export default LoanAssessmentForm;



