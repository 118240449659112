import React from 'react'
import { connect } from 'react-redux'
import { Form, Input, Button,Spin,Card,Avatar,message } from 'antd';
import { UserOutlined, LockOutlined, LoadingOutlined } from '@ant-design/icons';
import { NavLink } from 'react-router-dom'
import * as actions from '../../store/actions/auth'
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import * as serverconfig from '../serverconn'
var CryptoJS = require("crypto-js");

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;


var username= ''
var token= ''
var bizuserid=''


class PasswordResetForm extends React.Component {

  state={
    datarequested:false,
  }
  componentDidMount(){
    if(localStorage.getItem("username")){
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
       bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)

    }else{
       username= ''
       bizuserid=''

    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }
  }

  handleSubmit = values => {
       this.setState({datarequested:true})

      let form_data = new FormData();
      form_data.append('new_password1', values.new_password1);
      form_data.append('new_password2', values.new_password2);
      form_data.append('old_password', values.oldpassword);
      form_data.append('bizuserid', bizuserid);
      form_data.append('forced', "false");

      //Now send the post resquest
        axios.post(serverconfig.backendserverurl+'/customqueries/passwordchange', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
        })
      .then(res => {
        this.setState({datarequested:false})
        window.location.reload(false);
        message.info(res.data.message);
      }
      )
      .catch(error => console.log(error)) 

  }
  
  render(){

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{

      return (
      
        <div>
  
            <Card bordered={false} >
   
                <Form
                onFinish={this.handleSubmit}        
                name="passwordreset"
                className="login-form">
          
                <Form.Item
                    name="new_password1"
                    label="New Password one"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your password!',
                      },
                    ]}
                    hasFeedback
                  >
                    <Input.Password 
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="Password one"
                    />
  
  
                  </Form.Item>
  
            
                  <Form.Item
                    name="new_password2"
                    label="Confirm New Password"
                    dependencies={['new_password1']}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: 'Please confirm your password!',
                      },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (!value || getFieldValue('new_password1') === value) {
                            return Promise.resolve();
                          }
            
                          return Promise.reject('The two passwords that you entered do not match!');
                        },
                      }),
                    ]}
                  >
                    <Input.Password 
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="Confirm" />
  
                  </Form.Item>
  
  
                  <Form.Item
                    name="oldpassword"
                    label="Old Password"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your password!',
                      },
                    ]}
                    
                  >
                    <Input.Password 
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="Password"
                    />
  
                  </Form.Item>
          
                <Form.Item >
                    <Button type="primary" htmlType="submit" className="login-form-button">
                      Confirm Change
                    </Button>
                </Form.Item>
              </Form>
  
            </Card>
  
    </div>
  
        );
    }
  
  }
}

const WrappedPasswordResetForm= PasswordResetForm;

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    error: state.error
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onAuth: (username, password) => dispatch(actions.authLogin(username, password))
  }

}

export default withRouter (connect(mapStateToProps, mapDispatchToProps)(WrappedPasswordResetForm));
