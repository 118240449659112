import React from 'react';
import { Form, Input, Button,Select,DatePicker,Spin,Image, Card,Divider,Result,Popconfirm, message,Table } from 'antd';
import axios from 'axios'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import moment from 'moment';
import * as serverconfig from '../serverconn'
import {
  Col,
  Row,
} from 'reactstrap';
import { UserOutlined, LockOutlined, LoadingOutlined,MailOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx';

var CryptoJS = require("crypto-js");


const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''
var sacco= ''
var username=''


class TransactionsReset extends React.Component {

  state = {
    date:moment().format(dateFormat).toString(),
    excelfilefile:null,
    saccoboject:{},
    datalist:[],
    columns:[],
    
    message:'',
    companyprofile:{},
    task_name:'member_balances_reconciliation'

  }

  componentDidMount(){

    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
  
      }else{
       sacco= ''
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/saccos/${sacco}`)
    .then(res => {
        this.setState({
          saccoboject:res.data
        })
        this.setState({datarequested:false})
    })

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })
        this.setState({datarequested:false})

    })
}



  //submit button pressed
  handleFormSubmit=() =>{

      this.setState({datarequested:true})
      let form_data = new FormData();
      form_data.append('sacco', sacco);
      form_data.append('task_name', this.state.task_name);
      form_data.append('username', username);

      axios.post(serverconfig.backendserverurl+'/customqueries/reconciliation_tasks', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
    .then(res =>{
      this.setState({message:res.data.message})
      console.log(res.data)
      this.setState({datarequested:false})
      this.setState({datasubmittedsuccessfully:true})
    }    
    )
    .catch(error => console.log(error))

  }


  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{

      if (this.state.datasubmittedsuccessfully===true){
        return(
            <Card>
            <Result
            status="success"
            title="Successful"
            subTitle={this.state.message}
            extra={[
              <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
            ]}
            />
            </Card>
        );

      }else{

            return (
              <div>
                 <Card>
                  <h3>Reconcilation tasks</h3>

                  <FormItem label="Mass Task">
                        <Select placeholder="Reset type"
                        style={{ width: 400 }} 
                        value={this.state.task_name}
                        onChange={(val)=>{this.setState({task_name:val})}} >
                              <Option value="member_balances_reconciliation">Member balances reconciliation</Option>
                              <Option value="rearrange_loantrackers">Rearrange loan trackers</Option>
                              <Option value="loan_interest_reconciliation">Loan interest reconciliation</Option>
                              <Option value="saving_interest_reconciliation">Savings interest reconciliation</Option>
                        </Select>
                  </FormItem>


                <Popconfirm title="Sure to reset?"
                 onConfirm={() => {this.handleFormSubmit()}}>

                  <Button  
                  type='primary'
                    onClick={()=>{
                    

                  }}>Submit</Button>
                 </Popconfirm>
                 </Card>

          </div>
        );
      }

    }
    
  }

}

export default TransactionsReset;



