import React from 'react';
import axios from 'axios';
import { Card, Button, Avatar,Modal,Spin } from 'antd';
import AccountUpdateForm from '../components/AccountUpdateForm'
import { UserOutlined,LoadingOutlined } from '@ant-design/icons';
import { PlusOutlined } from '@ant-design/icons';
import { Drawer,Divider } from 'antd';
import * as serverconfig from '../serverconn'
import {
  Col,
  Row,
} from 'reactstrap';
import CurrencyFormat from 'react-currency-format';
import * as reactstrp from 'reactstrap';


const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''

class LoanApplication_Securities extends React.Component{
    state={
        datarequested:true,
        application_obj:{},
        memberperiodic_balanceobj:{}
    }

    componentDidMount(){
      if(localStorage.getItem("token")){
         token= localStorage.getItem("token")
      }else{
         token= ''
      }

      axios.defaults.headers={
        "Content-Type":"application/json",
        Authorization:`Token ${token}`
      }

      this.compo_nent_loade()

    }

    //component loaded
    compo_nent_loade=()=>{
      if(JSON.stringify(this.props.application_obj)!="{}"){
        this.setState({application_obj:this.props.application_obj})

        //get periodic balances
        let form_data1 = new FormData();
        form_data1.append('date',this.props.application_obj.date);
        form_data1.append('member_id', this.props.application_obj.account);
        axios.post(serverconfig.backendserverurl+`/customqueries/get_runningbalancesbydate`, form_data1, {
        headers: {
          'content-type': 'multipart/form-data'
        }
        })
        .then(res => {
          this.setState({
            memberperiodic_balanceobj:res.data.my_balance
          })

          this.setState({datarequested:false})

        })


      }


    }

  /// generate_collateral_list
    generate_collateral_list=()=>{
      var list=[]
      var item_no=1

      //Add project interest
      if(this.props.application_obj.projectinterestdetails!="{}"){
        var obj=JSON.parse(this.props.application_obj.projectinterestdetails)
        if (obj){

          var oth_ds=obj.projectint_noofblocks_units+" blocks, "+obj.projectint_valueper_blocks_units+" Value per block"
          var nu_data={
            "GuarantorType":"Applicant",
            "MemberName":this.props.application_obj.accountName,
            "accountNumber":this.props.application_obj.accountNumber,
            "Security":obj.projectint_proname,
            "Amount":obj.projectint_total,
            "item_no":item_no,
            "Otherdetails":oth_ds
  
          }
          list.push(nu_data)
          item_no+=1
        }

      }

      //other collaterals
      if(this.props.application_obj.othercollateraldetails!="{}"){
        var obj=JSON.parse(this.props.application_obj.othercollateraldetails)
        if (obj){

          var nu_data={
            "GuarantorType":"Applicant",
            "MemberName":this.props.application_obj.accountName,
            "accountNumber":this.props.application_obj.accountNumber,
            "Security":obj.othercol_details,
            "Amount":obj.othercol_value,
            "item_no":item_no,
            "Otherdetails":""
  
          }
          list.push(nu_data)
          item_no+=1
        }
        
      }


      //reapers title
      if(this.props.application_obj.reaperstitledetails!="{}"){
        var obj=JSON.parse(this.props.application_obj.reaperstitledetails)
        if (obj){

          var nu_data={
            "GuarantorType":"Applicant",
            "MemberName":this.props.application_obj.accountName,
            "accountNumber":this.props.application_obj.accountNumber,
            "Security":obj.rpt_details,
            "Amount":obj.rpt_value,
            "item_no":item_no,
            "Otherdetails":''
          }
          list.push(nu_data)
          item_no+=1

        }
        
      }

      //own title
      if(this.props.application_obj.owntitledetails!="{}"){
        var obj=JSON.parse(this.props.application_obj.owntitledetails)
        if (obj){

          var ot_dtls="valuer: "+obj.ownproperty_valuername+", Phone: "+obj.ownproperty_valuerphone+", Comapny: "+obj.ownproperty_valuercompany+", Address: "+obj.ownproperty_valuercompanyaddress
          var nu_data={
            "GuarantorType":"Applicant",
            "MemberName":this.props.application_obj.accountName,
            "accountNumber":this.props.application_obj.accountNumber,
            "Security":obj.propertydetails,
            "Amount":obj.ownproperty_value,
            "item_no":item_no,
            "Otherdetails":ot_dtls
          }
          list.push(nu_data)
          item_no+=1

        }

      }

      //collateral set
      if(this.props.application_obj.collateral_set!="{}"){
        var obj=JSON.parse(this.props.application_obj.collateral_set)

        if (obj){

        //check savings
        if (obj.savings===true){
          var nu_data={
            "GuarantorType":"Applicant",
            "MemberName":this.props.application_obj.accountName,
            "accountNumber":this.props.application_obj.accountNumber,
            "Security":"Savings",
            "Amount": Number(this.state.memberperiodic_balanceobj.savings_balance) ,
            "item_no":item_no,
            "Otherdetails":''
          }
          list.push(nu_data)
          item_no+=1

        }

        //supersave
   
        //check savings
        if (obj.supersave===true){
          var nu_data={
            "GuarantorType":"Applicant",
            "MemberName":this.props.application_obj.accountName,
            "accountNumber":this.props.application_obj.accountNumber,
            "Security":"SuperSave",
            "Amount":Number(this.state.memberperiodic_balanceobj.supersaver_balance),
            "item_no":item_no,
            "Otherdetails":''
          }
          list.push(nu_data)
          item_no+=1

        }


        if (obj.shares===true){
          var nu_data={
            "GuarantorType":"Applicant",
            "MemberName":this.props.application_obj.accountName,
            "accountNumber":this.props.application_obj.accountNumber,
            "Security":"Shares",
            "Amount":Number(this.state.memberperiodic_balanceobj.shareamount_balance),
            "item_no":item_no,
            "Otherdetails":''
          }
          list.push(nu_data)
          item_no+=1

        }



        if (obj.teebagoolo===true){
          var nu_data={
            "GuarantorType":"Applicant",
            "MemberName":this.props.application_obj.accountName,
            "accountNumber":this.props.application_obj.accountNumber,
            "Security":"TeebaGoolo",
            "Amount":Number(this.state.memberperiodic_balanceobj.teebagoolo_balance),
            "item_no":item_no,
            "Otherdetails":''
          }
          list.push(nu_data)
          item_no+=1

        }

        }



      }

       
      
        //////////////////////
        ////////////////////////
        //now look at the guarantors list
        //////////////////////////
        /////////////////////////
        if (this.props.application_obj.guarantorslist){
          JSON.parse(this.props.application_obj.guarantorslist).map((item)=>{
            //check savings
            if (Number(item.liguidity_items.savings)>0){
              var nu_data={
                "GuarantorType":"Guarantor",
                "MemberName":item.account_name,
                "accountNumber":item.account_no,
                "Security":"Savings",
                "Amount":Number(item.liguidity_items.savings),
                "item_no":item_no,
                "Otherdetails":''
              }
              list.push(nu_data)
              item_no+=1

            }

            //supersave

            //check savings
            if (Number(item.liguidity_items.supersave)>0){
              var nu_data={
                "GuarantorType":"Guarantor",
                "MemberName":item.account_name,
                "accountNumber":item.account_no,
                "Security":"SuperSave",
                "Amount":Number(item.liguidity_items.supersave),
                "item_no":item_no,
                "Otherdetails":''
              }
              list.push(nu_data)
              item_no+=1

            }

            //teeba goolo
            if (Number(item.liguidity_items.teebagoolo)>0){
              var nu_data={
                "GuarantorType":"Guarantor",
                "MemberName":item.account_name,
                "accountNumber":item.account_no,
                "Security":"TeebaGoolo",
                "Amount":Number(item.liguidity_items.teebagoolo),
                "item_no":item_no,
                "Otherdetails":''
              }
              list.push(nu_data)
              item_no+=1

            }

            //shares
            if (Number(item.liguidity_items.shares)>0){
              var nu_data={
                "GuarantorType":"Applicant",
                "MemberName":item.account_name,
                "accountNumber":item.account_no,
                "Security":"Shares",
                "Amount":Number(item.liguidity_items.shares),
                "item_no":item_no,
                "Otherdetails":''
              }
              list.push(nu_data)
              item_no+=1

            }

            /////////////////////////////////
            ///non liquid collaterals 
            ///////////////////////////////
            /////////////////////////////////////

            //Add project interest
            if(JSON.stringify(item.projectinterestdetails_guar)!="{}"){
              var obj=item.projectinterestdetails_guar
              var oth_ds=obj.projectint_noofblocks_units+" blocks, "+obj.projectint_valueper_blocks_units+" Value per block"

              var nu_data={
                "GuarantorType":"Guarantor",
                "MemberName":item.account_name,
                "accountNumber":item.account_no,
                "Security":obj.projectint_proname,
                "Amount":obj.projectint_total,
                "item_no":item_no,
                "Otherdetails":oth_ds

              }
              list.push(nu_data)
              item_no+=1
            }

            //other collaterals
            if(JSON.stringify(item.othercollateraldetails_guar)!="{}"){
              var obj=item.othercollateraldetails_guar
              var nu_data={
                "GuarantorType":"Guarantor",
                "MemberName":item.account_name,
                "accountNumber":item.account_no,
                "Security":obj.othercol_details,
                "Amount":obj.othercol_value,
                "item_no":item_no,
                "Otherdetails":""

              }
              list.push(nu_data)
              item_no+=1
            }


            //reapers title
            if(JSON.stringify(item.reaperstitledetails_guar)!="{}"){
              var obj=item.reaperstitledetails_guar
              var nu_data={
                "GuarantorType":"Guarantor",
                "MemberName":item.account_name,
                "accountNumber":item.account_no,
                "Security":obj.rpt_details,
                "Amount":obj.rpt_value,
                "item_no":item_no,
                "Otherdetails":""

              }
              list.push(nu_data)
              item_no+=1
            }

            //own title
            if(JSON.stringify(item.own_titledetails_guar)!="{}"){
              var obj=item.own_titledetails_guar
              var ot_dtls="valuer: "+obj.ownproperty_valuername+", Phone: "+obj.ownproperty_valuerphone+", Company: "+obj.ownproperty_valuercompany+", Address: "+obj.ownproperty_valuercompanyaddress

              var nu_data={
                "GuarantorType":"Guarantor",
                "MemberName":item.account_name,
                "accountNumber":item.account_no,
                "Security":obj.propertydetails,
                "Amount":obj.ownproperty_value,
                "item_no":item_no,
                "Otherdetails":ot_dtls
              }
              list.push(nu_data)
              item_no+=1
            }

        })


        }


       

        return list
    }


    total_ofsecurities=()=>{
      var total=0
      this.generate_collateral_list().map((item)=>{
        total+=Number(item.Amount)
      })

      return total.toFixed(2)

    }

    

    render(){

      if(this.state.datarequested===true){
        return(
          <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin  size="large">
                <div className="content" />
              </Spin>      
         </div>
        )
  
      }else{
        return(
          <div style={{overflowX:'auto'}}>
            <Card style={{overflowX:'auto'}}>
              <h4>Application Securities List</h4>
              {
                this.generate_collateral_list().length>0?
                <div>
                    <reactstrp.Table bordered>
                        <thead>
                          <tr>
                            <th>No</th>
                            <th>Type</th>
                            <th>Member</th>
                            <th>Account</th>
                            <th>Details</th>
                            <th>Value</th>
                            <th>Other details</th>
                          </tr>
                        </thead>
                        <tbody>
                        {this.generate_collateral_list().map(
                          (item)=>(
                            <tr>
                            <td>{item.item_no}</td>
                            <td>{item.GuarantorType}</td>
                            <td>{item.MemberName}</td>
                            <td>{item.accountNumber}</td>
                            <td>{item.Security}</td>
                            <td>{<CurrencyFormat value={Number(item.Amount).toFixed(2)} displayType={'text'} thousandSeparator={true}  />}</td>
                            <td>{item.Otherdetails}</td>

                            </tr>
                          ))}

                          <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th>
                            <CurrencyFormat value={this.total_ofsecurities()} displayType={'text'} thousandSeparator={true}  />
                            </th>
                            <th></th>

                          </tr>

                      </tbody>
                      </reactstrp.Table>                    

                </div>
                :
                null
              }

            </Card>
          </div>
      )
      }
       
    }
}

export default LoanApplication_Securities; 