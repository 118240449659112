import React,{ useRef } from 'react';
import axios from 'axios'
import { Tooltip,Popover,Result,Table,Popconfirm,Input, Button,Collapse,Card,Avatar,Modal,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { PrinterOutlined, MinusOutlined, CloseCircleFilled, CheckCircleOutlined, UserOutlined } from '@ant-design/icons';
import { DeleteOutlined,SearchOutlined,CameraOutlined,PlusOutlined,EditOutlined,PlusSquareFilled,SaveOutlined,LoadingOutlined } from '@ant-design/icons';
import QrReader from 'react-qr-reader'
import uuid from 'react-uuid'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
import PrintTemplate from 'react-print';
import ReactToPrint from 'react-to-print';
import {
  Col,
  Row,
} from 'reactstrap';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import * as reactstrp from 'reactstrap';
import { ToWords } from 'to-words';

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { Title } = Typography;
const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { RangePicker } = DatePicker;

const toWords = new ToWords();

message.config({
  top: 200,
  duration: 2,
  maxCount: 3,
  rtl: true,
});


var token= ''
var sacco= ''
var username=''
var bizuserid= ''




class SendButchEMailStatementView extends React.Component {

  state = {
    date:moment().format(dateFormat).toString(),
    selectedaccount:'',
    selectedaccountobject:{},
    members:[],
    datarequested:true,
    totalamount:0,
    slipnumber:'',
    paidinby:'',
    transactorcontact:'',
    previoustransactions:[],
    datasubmittedsuccessfully:false,
    lastslipnumber:0,
    savingsamount:0,
    sharesamount:0,
    welfareamount:0,
    emergencyamount:0,
    dreamamount:0,
    legacyamount:0,
    childrenamount:0,
    universityamount:0,
    companyprofile:{},
    accountchange:false,
    userprofile:{},
    manualreceipt:'',
    accounttype_object:{},

    companyaccounts: [],
    debitaccount:'',
    availableloans:[],
    selectedloan:'',
    selectedloan_obj:{},

    loan_amount:0,
    principleamount:0,
    interestamount:0,
    registration_fees:0,

    dateone:'',
    datetwo:'',

  }

  callback(key) {
    console.log(key);
  }


   //check empty objecty
 isEmptyObject=(obj)=>{
  for ( var name in obj ) {
    return false;
  }
  return true;
}

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    
    }else{
       sacco= ''
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
   }else{
      bizuserid= ''
   }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    this.setState({datarequested:false})
    
  }

  
  //submit the deposit now
  handleFormSubmit=(event) =>{

    if(this.state.dateone===''||this.state.datetwo===''){
      alert("Dates missing")
    }else{
      this.setState({datarequested:true})
  
      let form_data = new FormData();
      form_data.append('dateone', this.state.dateone);
      form_data.append('datetwo', this.state.datetwo);
      form_data.append('sacco', sacco);
      
      axios.post(serverconfig.backendserverurl+'/customqueries/send_bulk_email_statements', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
        })
      .then(res => {
        this.setState({datarequested:false})
        //message.info(res.data.message)
        if (res.data.message==="Success"){
          this.setState({datasubmittedsuccessfully:true})
        }
        
      })
      .catch(error => console.log(error))
    }
    
  }

  ////////////////////////////////////////////
  // RENDERING METHOD HERE
  render() {


    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      if(this.state.datasubmittedsuccessfully===true){
        return(
          <div>
          
          <Card>
            <Result
            status="success"
            title="Successfully sent request for batch email processing.You will be notified ounce job is finished "
            extra={[
              <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
            ]}
            />

            </Card>
          </div>

        )

      }else{
        return(
          <div>
            <div>
                  <Card>
                    <h3 style={{fontWeight:'bold',alignSelf:'center',color:'#154360'}}>Butch email statements form</h3>
                    <Form
                      onFinish={(event) => this.handleFormSubmit(event)}>

                   <FormItem label="Select date range (Period)">
                      <RangePicker defaultValue={moment()} onChange={
                        (date, dateString) =>{
                          this.setState({ dateone: dateString[0]});
                          this.setState({ datetwo: dateString[1]});
                        }
                      } format={dateFormat} />
                  </FormItem>

                  <FormItem>
                    <Button  type="primary" htmlType="submit">Send emails</Button>
                  </FormItem>
                  </Form>
                  </Card>
            </div>
  
       </div>              
                      
        )
      }

    }
   
  }
}

export default SendButchEMailStatementView; 
