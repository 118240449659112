import React,{ useRef } from 'react';
import axios from 'axios'
import { Tooltip,Popover,Result,Table,Popconfirm,Input, Button,Collapse,Card,Avatar,Modal,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { PrinterOutlined, MinusOutlined, CloseCircleFilled, CheckCircleOutlined, UserOutlined } from '@ant-design/icons';
import { DeleteOutlined,SearchOutlined,CameraOutlined,PlusOutlined,EditOutlined,PlusSquareFilled,SaveOutlined,LoadingOutlined } from '@ant-design/icons';
import QrReader from 'react-qr-reader'
import uuid from 'react-uuid'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
import PrintTemplate from 'react-print';
import ReactToPrint from 'react-to-print';
import {
  Col,
  Row,
} from 'reactstrap';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import * as reactstrp from 'reactstrap';
import { ToWords } from 'to-words';

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { Title } = Typography;
const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

const toWords = new ToWords();

message.config({
  top: 200,
  duration: 2,
  maxCount: 3,
  rtl: true,
});


var token= ''
var sacco= ''
var username=''
var bizuserid= ''


class WithdrawRequstionFormEdit extends React.Component {

  state = {
    date:moment().format(dateFormat).toString(),
    selectedaccount:'',
    selectedaccountobject:{},
    members:[],
    datarequested:true,
    totalamount:0,
    voucher_number:'',
    savingterm:'',
    transactorcontact:'',
    previoustransactions:[],
    datasubmittedsuccessfully:false,
    companyprofile:{},
    accountchange:false,
    userprofile:{},
    transaction_details:'',
    transferamount:0,
    transferfromaccount:'',
    supersaveaccounttype:'',
    supersavertypes:[],
    supersavertype:'',

    request_amount:0,
    withdraw_type:'',
    status:'',
    physical_form:null,
    initiator_remarks:'',
    memberperiodic_balanceobj:{}

  }

  callback(key) {
    console.log(key);
  }

  componentDidMount(){
    const requiID=this.props.match.params.requiID;

    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    
    }else{
       sacco= ''
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
   }else{
      bizuserid= ''
   }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    let form_data1 = new FormData();
    form_data1.append('userid', bizuserid);
    
    //get the user profile here
    axios.post(serverconfig.backendserverurl+'/customqueries/get_user_profile',form_data1,{
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res => {
        this.setState({
          userprofile:res.data
        })
    })


    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })

    axios.get(serverconfig.backendserverurl+`/api/members/?sacco=${sacco}`)
    .then(res => {

        var datalist=[]
        res.data.map((item)=>{
          if(item.AccountNumber=="null" || item.AccountNumber==null || item.AccountNumber==''){
          }else{
            datalist.push(item)
          }
        })

        this.setState({
          members:datalist
        })


    })

    axios.get(`${serverconfig.backendserverurl}/api/withdraw_requistion/${requiID}`)
    .then(res => {

        this.setState({request_amount: res.data.request_amount})
        this.setState({initiator_remarks: res.data.initiator_remarks})
        this.setState({withdraw_type: res.data.withdraw_type})
        this.setState({selectedaccount: res.data.account})

        axios.get(`${serverconfig.backendserverurl}/api/members/${res.data.account}`)
        .then(res => {  
            this.setState({selectedaccountobject: res.data})
        })

        this.setState({datarequested:false})

    })



  }

  
  ////////////////////////////////////
  // PRODUCT SEARCH SELECT METHODS
   onBlur=()=> {
    console.log('blur');
  }
  
   onFocus=()=>{
    console.log('focus');
  }
  
  onSearch=(val)=> {
    console.log('search:', val);
  }

  //handle client change
  handleselectedaccountChange= (e) =>{

    let form_data1 = new FormData();
    form_data1.append('date',this.state.date); 
    form_data1.append('member_id', e);
    axios.post(serverconfig.backendserverurl+`/customqueries/get_runningbalancesbydate`, form_data1, {
    headers: {
      'content-type': 'multipart/form-data'
    }
    })
    .then(res => {
        this.setState({
          memberperiodic_balanceobj:res.data.my_balance
        })
    })


    this.setState({accountchange:true})
    this.setState({ selectedaccount: e});
    //make query for last transactions
    axios.get(`${serverconfig.backendserverurl}/api/members/${e}`)
      .then(res => {  
          this.setState({selectedaccountobject: res.data})
          this.setState({accountchange:false})
      })

  }

  handlesavingtermChange= (e) => this.setState({ savingterm: e.target.value});
  handletransactorcontactChange= (e) => this.setState({ transactorcontact: e});
  handletransferamountChange= (e) => this.setState({ transferamount: e});


  //submit the deposit now
  handleFormSubmit=(event) =>{
    const requiID=this.props.match.params.requiID;

     if(this.state.withdraw_type==="ordinary" &&Number(this.state.request_amount)>Number(this.state.memberperiodic_balanceobj.savings_balance)){
      message.error("Savings Amount is less")
    }else if(this.state.withdraw_type==="supersave" &&Number(this.state.request_amount)>Number(this.state.memberperiodic_balanceobj.supersaver_balance)){
      message.error("Supersave balance is less")
    }else if(this.state.withdraw_type==="teebagoolo" &&Number(this.state.request_amount)>Number(this.state.memberperiodic_balanceobj.teebagoolo_balance)){
      message.error("Teebagoolo balance is low")
    }else{
      this.setState({datarequested:true})
      console.log(username)
  
      let form_data = new FormData();
      form_data.append('date',this.state.date);
      form_data.append('sacco', sacco);
      form_data.append('account', this.state.selectedaccountobject.id);
      form_data.append('user', bizuserid);
      form_data.append('request_amount', this.state.request_amount);
      form_data.append('withdraw_type', this.state.withdraw_type);
      //form_data.append('status', 'pending');
      form_data.append('initiator_remarks', this.state.initiator_remarks);

      this.state.physical_form==null?
      console.log("No physical file")
      :
      form_data.append('physical_form', this.state.physical_form, this.state.physical_form?this.state.physical_form.name:"");

      axios.put(serverconfig.backendserverurl+`/api/withdraw_requistion/${requiID}/`, form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
        })
      .then(res => {
        this.setState({datarequested:false})
        this.setState({datasubmittedsuccessfully:true})
        
      })
      .catch(error => console.log(error))
    }
    
  }


  ////////////////////////////////////////////
  // RENDERING METHOD HERE
  render() {

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      if(this.state.datasubmittedsuccessfully===true){
        return(
          <div>


            <Row >
                  <Col xs="12" sm="6" lg="6">
                    <Card>
                    <Result
                    status="success"
                    title="Successfully Account creation "
                    subTitle="Please finish off transaction"
                    extra={[

                      <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
                    ]}
                    />

                    </Card>

                    </Col>

            </Row>

          </div>

        )

      }else{
        return(
          <div>
            <div>
  
              <Row >
                  <Col xs="12" sm="6" lg="6">
                    <Card>
                      
                    <FormItem label="Select Member Ordinary Account">
                        <Select 
                        placeholder="Account No | Name" 
                        style={{  }} 
                        value={this.state.selectedaccount}
                        onChange={this.handleselectedaccountChange} 
                        showSearch
                        optionFilterProp="children"
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        onSearch={this.onSearch}                    
                        >
                          {this.state.members.map(
                            (accnt)=>(
                              <Option value={accnt.id}>{accnt.AccountNumber} | {accnt.FullName}</Option>
                            ))}
                        </Select>
                    </FormItem>

                    {
                      this.state.accountchange?
                      <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
                      <Spin indicator={antIcon} />
                      </div>
                      :
                      null
                    }

                    <br></br>
                    <h3 style={{fontWeight:'bold',alignSelf:'center',color:'#154360'}}>WITHDRAW REQUISTION FORM</h3>
                  <Form
                        onFinish={(event) => this.handleFormSubmit(event)}
                    >

                {
                    this.state.companyprofile.automated_transaction_date===false?
                    <FormItem label="Date"
                    >
                        <DatePicker onChange={(date, dateString) => this.setState({ date: dateString})} format={dateFormat} />
                    </FormItem>                 
                    :
                     null
                    }
                      

                  <FormItem label="Withdraw type">
                        <Select 
                        placeholder="Withdraw type" 
                        style={{  }} 
                        value={this.state.withdraw_type}
                        onChange={(val)=>{this.setState({withdraw_type:val})}} 
                        showSearch
                        optionFilterProp="children"
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        onSearch={this.onSearch}             
                        >
                              <Option value={'ordinary'}>Ordinary Savings</Option>
                              <Option value={'supersave'}>Super save</Option>
                              <Option value={'teebagoolo'}>Teebagoolo</Option>
                        </Select>
                    </FormItem>


                  <FormItem label={<h4 style={{fontWeight:'bold'}}>Request Amount ({this.state.request_amount})</h4>}
                    
                    >
                      <InputNumber
                        style={{ width: 200 }} 
                        defaultValue={0.0}
                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={value => value.replace(/\x\s?|(,*)/g, '')}
                        onChange={(val)=>{this.setState({request_amount:val})}}
                      />              
                    </FormItem>

                    <FormItem label={<h4 style={{fontWeight:'bold'}}
                  >Initiator remarks.</h4>} 
  
                  >
                  <TextArea
                      placeholder="Initiator remarks"
                      autoSize={{ minRows: 2, maxRows: 8 }}
                      value={this.state.initiator_remarks}
                      onChange={(val)=>{this.setState({initiator_remarks:val.target.value})}}
                    />
                
                  </FormItem>  

                  <FormItem label="Physical Application Form" style={{marginRight:3}}>
                        <Input type="file" accept="*" placeholder="Pysical Application Form" 
                        
                        onChange={(val)=>{
                          var kilbyes=val.target.files[0].size/1024
                          if (kilbyes>Number(this.state.companyprofile.maximum_upload_document_size)){
                            message.error("File is greater than "+String(this.state.companyprofile.maximum_upload_document_size)+" Kbs .It will not be uploaded.")
                          }else{
                            this.setState({physical_form:val.target.files[0]})

                          }
                          
                          }}/>
                  </FormItem> 
                  
                  <FormItem>
                    <Button  type="primary" htmlType="submit">Update</Button>
                  </FormItem>
                  </Form>
  
                  </Card>
                   
                  </Col>

                  <Col xs="12" sm="6" lg="6">
                    <Card>
                    <h3 style={{fontWeight:'bold',color:'#154360'}}>Account Details</h3>
                    <h4>Account Number:{this.state.selectedaccountobject.AccountNumber}</h4>
                    <h4>Account Contact:{this.state.selectedaccountobject.RegisteredPhoneNumber1}</h4>
                    <h4>Account Name:{this.state.selectedaccountobject.FullName}</h4>
                    <h4>Savings balance:<CurrencyFormat value={this.state.memberperiodic_balanceobj.savings_balance} displayType={'text'} thousandSeparator={true}  /> </h4>
                    <h4>Supersave balance:<CurrencyFormat value={this.state.memberperiodic_balanceobj.supersaver_balance} displayType={'text'} thousandSeparator={true}  /> </h4>
                    <h4>Teebagoolo balance:<CurrencyFormat value={this.state.memberperiodic_balanceobj.teebagoolo_balance} displayType={'text'} thousandSeparator={true}  /> </h4>

                  </Card></Col>
               </Row>
            </div>
      
         </div>                    
       )
      }
     
    }
   
  }
}

export default WithdrawRequstionFormEdit; 
