import React from 'react';
import axios from 'axios';
import { Card, Button, Avatar,Modal,Tabs,Form, Input,Select,Spin,Image, message,Switch } from 'antd';
import AccountUpdateForm from '../components/AccountUpdateForm'
import { UserOutlined,LoadingOutlined } from '@ant-design/icons';
import { PlusOutlined } from '@ant-design/icons';
import { Drawer,Divider } from 'antd';
import * as serverconfig from '../serverconn'
import {
  Col,
  Row,
} from 'reactstrap';
import PhoneInput from 'react-phone-number-input'
import CompanyProfile_autosettings from './companyProfile_autosettings'

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const { Meta } = Card;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { TabPane } = Tabs;

var token= ''
var sacco= ''
var bizuserid= ''

class CompanyProfile extends React.Component{

    state={
        profile: {},
        datarequested:true,
        sacco_name:'',
        sacco_logo:null,
        sacco_logo_file:'',
        company_email:'',
        RegisteredPhoneNumber1:'',
        RegisteredPhoneNumber2:'',
        RegisteredPhoneNumber3:'',

        box_number:'',
        address:'',
        sharevalue:0,
        website:'',
        loanrepaymentalertdays:0,
        automatedloanreminders:false,
        allowaccountmonthlyemailstatements:false,
        email_statements:false,
        attach_officer_to_loan:false,

        automated_accounts:false,
        account_concstring:'',
        last_accountnmumber:'',
        automated_transaction_date:false,

        maximum_upload_document_size:0,
        allow_transaction_reversal:false,
        showeditablewithdrawcharge:false,

        number_ofwithdraw_approvers:0,
        send_asssessmentalerts:false,
        send_approvalalert:false,
        companyaccounts:[],


        account_tocreditsavings_ondeposit:'',
        account_tocreditshares_ondeposit:'',
        account_tocreditsupersave_ondeposit:'',
        account_tocreditdeffered_ondeposit:'',
        account_tocreditteebagoolo_ondeposit:'',
        account_tocreditloanprinciple_ondeposit:'',
        account_tocreditloaninterest_ondeposit:'',

        account_todebit_onwithdraw:'',
        account_tocredit_onwithdrawcharge:'',
        account_tocredit_onwithdrawbankcharge:'',

        account_todebit_onloan_onloanissuance:'',
        account_tocredit_onloanfees_onloanissuance:'',
        account_tocredit_onbankcharge_onloanissuance:'',
        account_todebit_onloan_onallcharges:'',


        account_todebit_onsupersave_tosavings_transfer:'',
        account_tocredit_onsupersave_tosavings_transfer:'',
        account_todebit_onteebagoolo_tosavings_transfer:'',
        account_tocredit_onteebagoolo_tosavings_transfer:'',

        account_tocredit_onossavings_interest_awarded:'',
        account_tocredit_onteebagoolo_interest_awarded:'',

        account_todebit_onossavings_interest_expense:'',
        account_for_savingsinterest_taxes:'',


        account_fordividend_payable:'',
        account_for_retained_earnings:'',
        account_for_dividend_taxes:'',
        usesms:false,
        automated_endofmonth_interesttransfer:false,
        savingsinterest_taxed:false,
        savingsintest_taxpercentage:0,

        defaultaccount_for_officecollection_debiting:0,
        defaultaccount_for_savingswithdraw_crediting:0,
        defaultaccount_for_loanissuance_crediting:0,

        expense_account_forloanbaddebtclearance:0


    }


    componentDidMount(){
      if(localStorage.getItem("token")){
         token= localStorage.getItem("token")
      }else{
         token= ''
      }

      if(localStorage.getItem("sacco")){
        sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
        bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
     
     }else{
        sacco= ''
        bizuserid= ''
     }


      axios.defaults.headers={
        "Content-Type":"application/json",
        Authorization:`Token ${token}`
      }

        axios.get(`${serverconfig.backendserverurl}/api/saccosedit/${sacco}`)
        .then(res => {  
            this.setState({
              profile: res.data
            })

            this.setState({sacco_name:res.data.sacco_name})
            this.setState({company_email:res.data.company_email})
            this.setState({RegisteredPhoneNumber1:res.data.RegisteredPhoneNumber1})
            this.setState({RegisteredPhoneNumber2:res.data.RegisteredPhoneNumber2})
            this.setState({RegisteredPhoneNumber3:res.data.RegisteredPhoneNumber3})

            this.setState({box_number:res.data.box_number})
            this.setState({address:res.data.address})
            this.setState({sharevalue:res.data.sharevalue})
            this.setState({website:res.data.website})

            this.setState({sacco_logo_file:res.data.sacco_logo})

            this.setState({loanrepaymentalertdays:res.data.loanrepaymentalertdays})
            this.setState({automatedloanreminders:res.data.automatedloanreminders})
            this.setState({allowaccountmonthlyemailstatements:res.data.allowaccountmonthlyemailstatements})
            this.setState({email_statements:res.data.email_statements})
            this.setState({attach_officer_to_loan:res.data.attach_officer_to_loan})

            this.setState({automated_accounts:res.data.automated_accounts})
            this.setState({account_concstring:res.data.account_concstring})
            this.setState({last_accountnmumber:res.data.last_accountnmumber})
            this.setState({automated_transaction_date:res.data.automated_transaction_date})
            this.setState({maximum_upload_document_size:res.data.maximum_upload_document_size})
            this.setState({allow_transaction_reversal:res.data.allow_transaction_reversal})

            this.setState({showeditablewithdrawcharge:res.data.showeditablewithdrawcharge})

            this.setState({number_ofwithdraw_approvers:res.data.number_ofwithdraw_approvers})
            this.setState({send_asssessmentalerts:res.data.send_asssessmentalerts})
            this.setState({send_approvalalert:res.data.send_approvalalert})


            this.setState({account_tocreditsavings_ondeposit:res.data.account_tocreditsavings_ondeposit})
            this.setState({account_tocreditshares_ondeposit:res.data.account_tocreditshares_ondeposit})
            this.setState({account_tocreditsupersave_ondeposit:res.data.account_tocreditsupersave_ondeposit})
            this.setState({account_tocreditdeffered_ondeposit:res.data.account_tocreditdeffered_ondeposit})
            this.setState({account_tocreditteebagoolo_ondeposit:res.data.account_tocreditteebagoolo_ondeposit})
            this.setState({account_tocreditloanprinciple_ondeposit:res.data.account_tocreditloanprinciple_ondeposit})
            this.setState({account_tocreditloaninterest_ondeposit:res.data.account_tocreditloaninterest_ondeposit})
           
            this.setState({account_todebit_onwithdraw:res.data.account_todebit_onwithdraw})
            this.setState({account_tocredit_onwithdrawcharge:res.data.account_tocredit_onwithdrawcharge})
            this.setState({account_tocredit_onwithdrawbankcharge:res.data.account_tocredit_onwithdrawbankcharge})
            
            this.setState({account_todebit_onloan_onloanissuance:res.data.account_todebit_onloan_onloanissuance})
            this.setState({account_tocredit_onloanfees_onloanissuance:res.data.account_tocredit_onloanfees_onloanissuance})
            this.setState({account_tocredit_onbankcharge_onloanissuance:res.data.account_tocredit_onbankcharge_onloanissuance})
            this.setState({account_todebit_onloan_onallcharges:res.data.account_todebit_onloan_onallcharges})


            

            this.setState({account_todebit_onsupersave_tosavings_transfer:res.data.account_todebit_onsupersave_tosavings_transfer})
            this.setState({account_tocredit_onsupersave_tosavings_transfer:res.data.account_tocredit_onsupersave_tosavings_transfer})
            this.setState({account_todebit_onteebagoolo_tosavings_transfer:res.data.account_todebit_onteebagoolo_tosavings_transfer})
            this.setState({account_tocredit_onteebagoolo_tosavings_transfer:res.data.account_tocredit_onteebagoolo_tosavings_transfer})


            this.setState({account_tocredit_onossavings_interest_awarded:res.data.account_tocredit_onossavings_interest_awarded})
            this.setState({account_tocredit_onteebagoolo_interest_awarded:res.data.account_tocredit_onteebagoolo_interest_awarded})


            this.setState({account_todebit_onossavings_interest_expense:res.data.account_todebit_onossavings_interest_expense})
            this.setState({account_for_savingsinterest_taxes:res.data.account_for_savingsinterest_taxes})

            this.setState({account_fordividend_payable:res.data.account_fordividend_payable})
            this.setState({account_for_retained_earnings:res.data.account_for_retained_earnings})
            this.setState({account_for_dividend_taxes:res.data.account_for_dividend_taxes})

            this.setState({usesms:res.data.usesms})
            this.setState({automated_endofmonth_interesttransfer:res.data.automated_endofmonth_interesttransfer})
            this.setState({savingsinterest_taxed:res.data.savingsinterest_taxed})
            this.setState({savingsintest_taxpercentage:res.data.savingsintest_taxpercentage})



            this.setState({defaultaccount_for_officecollection_debiting:res.data.defaultaccount_for_officecollection_debiting==null?0:res.data.defaultaccount_for_officecollection_debiting})
            this.setState({defaultaccount_for_savingswithdraw_crediting:res.data.defaultaccount_for_savingswithdraw_crediting==null?0:res.data.defaultaccount_for_savingswithdraw_crediting})
            this.setState({defaultaccount_for_loanissuance_crediting:res.data.defaultaccount_for_loanissuance_crediting==null?0:res.data.defaultaccount_for_loanissuance_crediting})

            this.setState({expense_account_forloanbaddebtclearance:res.data.expense_account_forloanbaddebtclearance==null?0:res.data.expense_account_forloanbaddebtclearance})

            

            this.setState({datarequested:false})
        })


        axios.get(serverconfig.backendserverurl+`/api/companyaccounts/?sacco=${sacco}`)
        .then(res => {
            this.setState({
                companyaccounts:res.data
            })
        })
    }

    handleFormSubmit=(event) =>{
      this.setState({datarequested:true})
    
      let form_data = new FormData();
  
      this.state.sacco_logo==null?
      console.log("No sacco_logo file")
      :
      form_data.append('sacco_logo', this.state.sacco_logo,this.state.sacco_logo?this.state.sacco_logo.name:"");
  
      form_data.append('sacco_name', this.state.sacco_name);
      form_data.append('company_email', this.state.company_email);
      form_data.append('RegisteredPhoneNumber1', this.state.RegisteredPhoneNumber1);
      form_data.append('RegisteredPhoneNumber2', this.state.RegisteredPhoneNumber2);
      form_data.append('RegisteredPhoneNumber3', this.state.RegisteredPhoneNumber3);

      form_data.append('box_number', this.state.box_number);
      form_data.append('address', this.state.address);
      form_data.append('sharevalue', this.state.sharevalue);
      form_data.append('website', this.state.website);

      form_data.append('loanrepaymentalertdays', this.state.loanrepaymentalertdays);
      form_data.append('automatedloanreminders', this.state.automatedloanreminders);
      form_data.append('allowaccountmonthlyemailstatements', this.state.allowaccountmonthlyemailstatements);
      form_data.append('email_statements', this.state.email_statements);
      form_data.append('attach_officer_to_loan', this.state.attach_officer_to_loan);

      form_data.append('automated_accounts', this.state.automated_accounts);
      form_data.append('account_concstring', this.state.account_concstring);
      form_data.append('last_accountnmumber', this.state.last_accountnmumber);
      form_data.append('automated_transaction_date', this.state.automated_transaction_date);
      form_data.append('maximum_upload_document_size', this.state.maximum_upload_document_size);
      form_data.append('allow_transaction_reversal', this.state.allow_transaction_reversal);

      form_data.append('showeditablewithdrawcharge', this.state.showeditablewithdrawcharge);


      form_data.append('number_ofwithdraw_approvers', this.state.number_ofwithdraw_approvers);
      form_data.append('send_asssessmentalerts', this.state.send_asssessmentalerts);
      form_data.append('send_approvalalert', this.state.send_approvalalert);


      form_data.append('account_tocreditsavings_ondeposit', this.state.account_tocreditsavings_ondeposit);
      form_data.append('account_tocreditshares_ondeposit', this.state.account_tocreditshares_ondeposit);
      form_data.append('account_tocreditsupersave_ondeposit', this.state.account_tocreditsupersave_ondeposit);
      form_data.append('account_tocreditdeffered_ondeposit', this.state.account_tocreditdeffered_ondeposit);
      form_data.append('account_tocreditteebagoolo_ondeposit', this.state.account_tocreditteebagoolo_ondeposit);
      form_data.append('account_tocreditloanprinciple_ondeposit', this.state.account_tocreditloanprinciple_ondeposit);
      form_data.append('account_tocreditloaninterest_ondeposit', this.state.account_tocreditloaninterest_ondeposit);
     
      form_data.append('account_todebit_onwithdraw', this.state.account_todebit_onwithdraw);
      form_data.append('account_tocredit_onwithdrawcharge', this.state.account_tocredit_onwithdrawcharge);
      form_data.append('account_tocredit_onwithdrawbankcharge', this.state.account_tocredit_onwithdrawbankcharge);
      
      form_data.append('account_todebit_onloan_onloanissuance', this.state.account_todebit_onloan_onloanissuance);
      form_data.append('account_tocredit_onloanfees_onloanissuance', this.state.account_tocredit_onloanfees_onloanissuance);
      form_data.append('account_tocredit_onbankcharge_onloanissuance', this.state.account_tocredit_onbankcharge_onloanissuance);
      form_data.append('account_todebit_onloan_onallcharges', this.state.account_todebit_onloan_onallcharges);


      form_data.append('account_todebit_onsupersave_tosavings_transfer', this.state.account_todebit_onsupersave_tosavings_transfer);
      form_data.append('account_tocredit_onsupersave_tosavings_transfer', this.state.account_tocredit_onsupersave_tosavings_transfer);
      form_data.append('account_todebit_onteebagoolo_tosavings_transfer', this.state.account_todebit_onteebagoolo_tosavings_transfer);
      form_data.append('account_tocredit_onteebagoolo_tosavings_transfer', this.state.account_tocredit_onteebagoolo_tosavings_transfer);


      form_data.append('account_tocredit_onossavings_interest_awarded', this.state.account_tocredit_onossavings_interest_awarded);
      form_data.append('account_tocredit_onteebagoolo_interest_awarded', this.state.account_tocredit_onteebagoolo_interest_awarded);

      form_data.append('account_for_savingsinterest_taxes', this.state.account_for_savingsinterest_taxes);
      form_data.append('account_todebit_onossavings_interest_expense', this.state.account_todebit_onossavings_interest_expense);

      form_data.append('account_fordividend_payable', this.state.account_fordividend_payable);
      form_data.append('account_for_retained_earnings', this.state.account_for_retained_earnings);
      form_data.append('account_for_dividend_taxes', this.state.account_for_dividend_taxes);
      form_data.append('usesms', this.state.usesms);
      form_data.append('automated_endofmonth_interesttransfer', this.state.automated_endofmonth_interesttransfer);

      form_data.append('savingsinterest_taxed', this.state.savingsinterest_taxed);
      form_data.append('savingsintest_taxpercentage', this.state.savingsintest_taxpercentage);

      form_data.append('defaultaccount_for_officecollection_debiting', this.state.defaultaccount_for_officecollection_debiting);
      form_data.append('defaultaccount_for_savingswithdraw_crediting', this.state.defaultaccount_for_savingswithdraw_crediting);
      form_data.append('defaultaccount_for_loanissuance_crediting', this.state.defaultaccount_for_loanissuance_crediting);

      form_data.append('expense_account_forloanbaddebtclearance', this.state.expense_account_forloanbaddebtclearance);
      
      axios.put(`${serverconfig.backendserverurl}/api/saccosedit/${sacco}/`,form_data, {
        headers: {
        'content-type': 'multipart/form-data'
        }
      })
      .then(res =>this.setState({datarequested:false}), window.location.reload(false))
      .catch(error => console.log(error))
      
      }


    render(){

      if(this.state.datarequested===true){
        return(
          <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin  size="large">
                <div className="content" />
              </Spin>            </div>
        )
  
      }else{
        return(
          <div>

<Tabs defaultActiveKey="1" onChange={()=>{}}>
                <TabPane tab="Main profile settings" key="001" >
              <Card 
                style={{padding:10}}
                hoverable>
              <Form
                    onFinish={(event) => this.handleFormSubmit(event)}
                    >
                       <h3>Main profile settings</h3>

                        <FormItem label="Company Name">
                            <Input name="sacco_name"  placeholder="Company name." value={this.state.sacco_name} onChange={(val)=>{this.setState({sacco_name:val.target.value})}} />
                        </FormItem>

            
                        <FormItem label="Address">
                            <Input placeholder="Address" value={this.state.address} onChange={(val)=>{this.setState({address:val.target.value})}} />
                        </FormItem>

                        <div style={{display:'flex',flexDirection:'row'}}>

                        <FormItem label="Logo">
                            <Input name="companylogo" type="file" accept="image/png, image/jpeg" placeholder="Company logo" 
                            
                            onChange={(e) =>{
                            if(e.target.files[0]){
                              this.setState({
                                sacco_logo_file: URL.createObjectURL(e.target.files[0])
                              })
                            }
                            this.setState({ sacco_logo: e.target.files[0]})

                            } }/>
                        </FormItem> 

                        {this.state.sacco_logo!=null || this.state.sacco_logo_file!=""?
                          <Image
                          width={50}
                          height={50}
                          src={this.state.sacco_logo_file}    
                          />
                          :
                          null
                          }


                        </div>

                        <FormItem label="Company Email">
                            <Input name="companyemail" type="email"  placeholder="company@email.com" value={this.state.company_email} onChange={(val)=>{this.setState({company_email:val.target.value})}} />
                        </FormItem> 
 
        
                        <FormItem label="Phone Number one">
                        <PhoneInput
                                countrySelectProps={{ unicodeFlags: true }}
                                defaultCountry="UG"
                                placeholder="Enter phone number"
                                value={this.state.RegisteredPhoneNumber1} onChange={(val)=>{this.setState({RegisteredPhoneNumber1:val})}}/>
                        </FormItem> 


                        <FormItem label="Phone Number two">
                        <PhoneInput
                                countrySelectProps={{ unicodeFlags: true }}
                                defaultCountry="UG"
                                placeholder="Enter phone number"
                                value={this.state.RegisteredPhoneNumber2} onChange={(val)=>{this.setState({RegisteredPhoneNumber2:val})}}/>
                        </FormItem>

                        <FormItem label="Phone Number three">
                        <PhoneInput
                                countrySelectProps={{ unicodeFlags: true }}
                                defaultCountry="UG"
                                placeholder="Enter phone number"
                                value={this.state.RegisteredPhoneNumber3} onChange={(val)=>{this.setState({RegisteredPhoneNumber3:val})}}/>
                        </FormItem>
                        
        
                        <FormItem label="Box number">
                            <Input placeholder="Box number here" value={this.state.box_number} onChange={(val)=>{this.setState({box_number:val.target.value})}} />
                        </FormItem>

                        <FormItem label="Website">
                            <Input name="website"   placeholder="www.company.com" value={this.state.website} onChange={(val)=>{this.setState({website:val.target.value})}} />
                        </FormItem> 

                        <FormItem label="Share value">
                            <Input type='number' placeholder="Share value" value={this.state.sharevalue} onChange={(val)=>{this.setState({sharevalue:val.target.value})}} />
                        </FormItem>


                        <FormItem name="allowaccountmonthlyemailstatements" label="Allowaccountmonthlyemailstatements" >
                                <Switch
                                    checked={this.state.allowaccountmonthlyemailstatements}
                                    onChange={val=>{this.setState({allowaccountmonthlyemailstatements:!this.state.allowaccountmonthlyemailstatements})}}
                                    checkedChildren="ON"
                                    unCheckedChildren="OFF"
                                    />
                                
                        </FormItem>

                        <FormItem name="email_statements" label="Email_statements" >
                                <Switch
                                    checked={this.state.email_statements}
                                    onChange={val=>{this.setState({email_statements:!this.state.email_statements})}}
                                    checkedChildren="ON"
                                    unCheckedChildren="OFF"
                                    />
                                
                        </FormItem>

                        <FormItem name="attach_officer_to_loan" label="Attach_officer_to_loan" >
                                <Switch
                                    checked={this.state.attach_officer_to_loan}
                                    onChange={val=>{this.setState({attach_officer_to_loan:!this.state.attach_officer_to_loan})}}
                                    checkedChildren="ON"
                                    unCheckedChildren="OFF"
                                    />
                        </FormItem>

                        <FormItem name="automatedloanreminders" label="Automated sms loan reminders" >
                                <Switch
                                    checked={this.state.automatedloanreminders}
                                    onChange={val=>{this.setState({automatedloanreminders:!this.state.automatedloanreminders})}}
                                    checkedChildren="ON"
                                    unCheckedChildren="OFF"
                                    />
                        </FormItem>


                        <FormItem label="Loan repayment alert days">
                            <Input type='number' placeholder="Days" value={this.state.loanrepaymentalertdays} onChange={(val)=>{this.setState({loanrepaymentalertdays:val.target.value})}} />
                        </FormItem>

                        <FormItem name="automated_accounts" label="Automated account numbers" >
                                <Switch
                                    checked={this.state.automated_accounts}
                                    onChange={val=>{this.setState({automated_accounts:!this.state.automated_accounts})}}
                                    checkedChildren="ON"
                                    unCheckedChildren="OFF"
                                    />
                        </FormItem>

                        <FormItem label="Automated account concatination string">
                            <Input name="account_concstring"   placeholder="Account concatination string" value={this.state.account_concstring} onChange={(val)=>{this.setState({account_concstring:val.target.value})}} />
                        </FormItem> 

                        <FormItem label="Last account number">
                            <Input name="last_accountnmumber"   placeholder="Last account number" value={this.state.last_accountnmumber} onChange={(val)=>{this.setState({last_accountnmumber:val.target.value})}} />
                        </FormItem> 

                        <FormItem name="automated_transaction_date" label="Automated transaction date" >
                                <Switch
                                    checked={this.state.automated_transaction_date}
                                    onChange={val=>{this.setState({automated_transaction_date:!this.state.automated_transaction_date})}}
                                    checkedChildren="ON"
                                    unCheckedChildren="OFF"
                                    />
                        </FormItem>

                        <FormItem label="Maximum_upload_document_size">
                            <Input type='number' placeholder="size" value={this.state.maximum_upload_document_size} onChange={(val)=>{this.setState({maximum_upload_document_size:val.target.value})}} />
                        </FormItem>

                        <FormItem name="allow_transaction_reversal" label="Allow transaction reversal" >
                                <Switch
                                    checked={this.state.allow_transaction_reversal}
                                    onChange={val=>{this.setState({allow_transaction_reversal:!this.state.allow_transaction_reversal})}}
                                    checkedChildren="ON"
                                    unCheckedChildren="OFF"
                                    />
                        </FormItem>

                        <FormItem name="showeditablewithdrawcharge" label="Editable withdraw charge" >
                            <Switch
                                checked={this.state.showeditablewithdrawcharge}
                                onChange={val=>{this.setState({showeditablewithdrawcharge:!this.state.showeditablewithdrawcharge})}}
                                checkedChildren="ON"
                                unCheckedChildren="OFF"
                                />
                        </FormItem>


                        <FormItem name="send_asssessmentalerts" label="Send_asssessmentalerts" >
                            <Switch
                                checked={this.state.send_asssessmentalerts}
                                onChange={val=>{this.setState({send_asssessmentalerts:!this.state.send_asssessmentalerts})}}
                                checkedChildren="ON"
                                unCheckedChildren="OFF"
                                />
                        </FormItem>

                        <FormItem name="send_approvalalert" label="Send_approvalalert" >
                            <Switch
                                checked={this.state.send_approvalalert}
                                onChange={val=>{this.setState({send_approvalalert:!this.state.send_approvalalert})}}
                                checkedChildren="ON"
                                unCheckedChildren="OFF"
                                />
                        </FormItem>

                        <FormItem label="Number_ofwithdraw_approvers">
                            <Input type='number' placeholder="size" value={this.state.number_ofwithdraw_approvers} onChange={(val)=>{this.setState({number_ofwithdraw_approvers:val.target.value})}} />
                        </FormItem>

                        <h3>Ledger profile settings (Deposit)</h3>

                        <FormItem label={"Account_tocreditsavings_ondeposit"}>
                          <Select 
                          placeholder="Account" 
                          style={{  }} 
                          value={this.state.account_tocreditsavings_ondeposit}
                          onChange={(val)=>{this.setState({account_tocreditsavings_ondeposit:val})}} 
                          showSearch
                          optionFilterProp="children"
                          onFocus={this.onFocus}
                          onBlur={this.onBlur}
                          onSearch={this.onSearch}                    
                          >
                            {this.state.companyaccounts.map(
                              (accnt)=>(
                                <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
                              ))}
                          </Select>
                      </FormItem>

                      <FormItem label={"Account_tocreditshares_ondeposit"}>
                          <Select 
                          placeholder="Account" 
                          style={{  }} 
                          value={this.state.account_tocreditshares_ondeposit}
                          onChange={(val)=>{this.setState({account_tocreditshares_ondeposit:val})}} 
                          showSearch
                          optionFilterProp="children"
                          onFocus={this.onFocus}
                          onBlur={this.onBlur}
                          onSearch={this.onSearch}                    
                          >
                            {this.state.companyaccounts.map(
                              (accnt)=>(
                                <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
                              ))}
                          </Select>
                      </FormItem>


                      <FormItem label={"Account_tocreditsupersave_ondeposit"}>
                          <Select 
                          placeholder="Account" 
                          style={{  }} 
                          value={this.state.account_tocreditsupersave_ondeposit}
                          onChange={(val)=>{this.setState({account_tocreditsupersave_ondeposit:val})}} 
                          showSearch
                          optionFilterProp="children"
                          onFocus={this.onFocus}
                          onBlur={this.onBlur}
                          onSearch={this.onSearch}                    
                          >
                            {this.state.companyaccounts.map(
                              (accnt)=>(
                                <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
                              ))}
                          </Select>
                      </FormItem>


                      <FormItem label={"Account_tocreditdeffered_ondeposit"}>
                          <Select 
                          placeholder="Account" 
                          style={{  }} 
                          value={this.state.account_tocreditdeffered_ondeposit}
                          onChange={(val)=>{this.setState({account_tocreditdeffered_ondeposit:val})}} 
                          showSearch
                          optionFilterProp="children"
                          onFocus={this.onFocus}
                          onBlur={this.onBlur}
                          onSearch={this.onSearch}                    
                          >
                            {this.state.companyaccounts.map(
                              (accnt)=>(
                                <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
                              ))}
                          </Select>
                      </FormItem>


                      <FormItem label={"Account_tocreditteebagoolo_ondeposit"}>
                          <Select 
                          placeholder="Account" 
                          style={{  }} 
                          value={this.state.account_tocreditteebagoolo_ondeposit}
                          onChange={(val)=>{this.setState({account_tocreditteebagoolo_ondeposit:val})}} 
                          showSearch
                          optionFilterProp="children"
                          onFocus={this.onFocus}
                          onBlur={this.onBlur}
                          onSearch={this.onSearch}                    
                          >
                            {this.state.companyaccounts.map(
                              (accnt)=>(
                                <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
                              ))}
                          </Select>
                      </FormItem>


                      <FormItem label={"Account_tocreditloanprinciple_ondeposit"}>
                          <Select 
                          placeholder="Account" 
                          style={{  }} 
                          value={this.state.account_tocreditloanprinciple_ondeposit}
                          onChange={(val)=>{this.setState({account_tocreditloanprinciple_ondeposit:val})}} 
                          showSearch
                          optionFilterProp="children"
                          onFocus={this.onFocus}
                          onBlur={this.onBlur}
                          onSearch={this.onSearch}                    
                          >
                            {this.state.companyaccounts.map(
                              (accnt)=>(
                                <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
                              ))}
                          </Select>
                      </FormItem>


                      <FormItem label={"Account_tocreditloaninterest_ondeposit"}>
                          <Select 
                          placeholder="Account" 
                          style={{  }} 
                          value={this.state.account_tocreditloaninterest_ondeposit}
                          onChange={(val)=>{this.setState({account_tocreditloaninterest_ondeposit:val})}} 
                          showSearch
                          optionFilterProp="children"
                          onFocus={this.onFocus}
                          onBlur={this.onBlur}
                          onSearch={this.onSearch}                    
                          >
                            {this.state.companyaccounts.map(
                              (accnt)=>(
                                <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
                              ))}
                          </Select>
                      </FormItem>

                      <h3>Ledger profile settings (Withdraws)</h3>

                      <FormItem label={"Account_todebit_onwithdraw"}>
                          <Select 
                          placeholder="Account" 
                          style={{  }} 
                          value={this.state.account_todebit_onwithdraw}
                          onChange={(val)=>{this.setState({account_todebit_onwithdraw:val})}} 
                          showSearch
                          optionFilterProp="children"
                          onFocus={this.onFocus}
                          onBlur={this.onBlur}
                          onSearch={this.onSearch}                    
                          >
                            {this.state.companyaccounts.map(
                              (accnt)=>(
                                <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
                              ))}
                          </Select>
                      </FormItem>

                      <FormItem label={"Account_tocredit_onwithdrawcharge"}>
                          <Select 
                          placeholder="Account" 
                          style={{  }} 
                          value={this.state.account_tocredit_onwithdrawcharge}
                          onChange={(val)=>{this.setState({account_tocredit_onwithdrawcharge:val})}} 
                          showSearch
                          optionFilterProp="children"
                          onFocus={this.onFocus}
                          onBlur={this.onBlur}
                          onSearch={this.onSearch}                    
                          >
                            {this.state.companyaccounts.map(
                              (accnt)=>(
                                <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
                              ))}
                          </Select>
                      </FormItem>


                      <FormItem label={"Account_tocredit_onwithdrawbankcharge"}>
                          <Select 
                          placeholder="Account" 
                          style={{  }} 
                          value={this.state.account_tocredit_onwithdrawbankcharge}
                          onChange={(val)=>{this.setState({account_tocredit_onwithdrawbankcharge:val})}} 
                          showSearch
                          optionFilterProp="children"
                          onFocus={this.onFocus}
                          onBlur={this.onBlur}
                          onSearch={this.onSearch}                    
                          >
                            {this.state.companyaccounts.map(
                              (accnt)=>(
                                <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
                              ))}
                          </Select>
                      </FormItem>


                      <h3>Ledger profile settings (Loans)</h3>

                      <FormItem label={"Account_todebit_onloan_onloanissuance"}>
                          <Select 
                          placeholder="Account" 
                          style={{  }} 
                          value={this.state.account_todebit_onloan_onloanissuance}
                          onChange={(val)=>{this.setState({account_todebit_onloan_onloanissuance:val})}} 
                          showSearch
                          optionFilterProp="children"
                          onFocus={this.onFocus}
                          onBlur={this.onBlur}
                          onSearch={this.onSearch}                    
                          >
                            {this.state.companyaccounts.map(
                              (accnt)=>(
                                <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
                              ))}
                          </Select>
                      </FormItem>

                      <FormItem label={"Account_todebit_onloan_onallcharges"}>
                          <Select 
                          placeholder="Account" 
                          style={{  }} 
                          value={this.state.account_todebit_onloan_onallcharges}
                          onChange={(val)=>{this.setState({account_todebit_onloan_onallcharges:val})}} 
                          showSearch
                          optionFilterProp="children"
                          onFocus={this.onFocus}
                          onBlur={this.onBlur}
                          onSearch={this.onSearch}                    
                          >
                            {this.state.companyaccounts.map(
                              (accnt)=>(
                                <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
                              ))}
                          </Select>
                      </FormItem>


                      <FormItem label={"Account_tocredit_onloanfees_onloanissuance"}>
                          <Select 
                          placeholder="Account" 
                          style={{  }} 
                          value={this.state.account_tocredit_onloanfees_onloanissuance}
                          onChange={(val)=>{this.setState({account_tocredit_onloanfees_onloanissuance:val})}} 
                          showSearch
                          optionFilterProp="children"
                          onFocus={this.onFocus}
                          onBlur={this.onBlur}
                          onSearch={this.onSearch}                    
                          >
                            {this.state.companyaccounts.map(
                              (accnt)=>(
                                <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
                              ))}
                          </Select>
                      </FormItem>


                      <FormItem label={"Account_tocredit_onbankcharge_onloanissuance"}>
                          <Select 
                          placeholder="Account" 
                          style={{  }} 
                          value={this.state.account_tocredit_onbankcharge_onloanissuance}
                          onChange={(val)=>{this.setState({account_tocredit_onbankcharge_onloanissuance:val})}} 
                          showSearch
                          optionFilterProp="children"
                          onFocus={this.onFocus}
                          onBlur={this.onBlur}
                          onSearch={this.onSearch}                    
                          >
                            {this.state.companyaccounts.map(
                              (accnt)=>(
                                <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
                              ))}
                          </Select>
                      </FormItem>

                      <h3>Ledger profile settings (Transfers)</h3>

                      <FormItem label={"Account_todebit_onsupersave_tosavings_transfer"}>
                          <Select 
                          placeholder="Account" 
                          style={{  }} 
                          value={this.state.account_todebit_onsupersave_tosavings_transfer}
                          onChange={(val)=>{this.setState({account_todebit_onsupersave_tosavings_transfer:val})}} 
                          showSearch
                          optionFilterProp="children"
                          onFocus={this.onFocus}
                          onBlur={this.onBlur}
                          onSearch={this.onSearch}                    
                          >
                            {this.state.companyaccounts.map(
                              (accnt)=>(
                                <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
                              ))}
                          </Select>
                      </FormItem>


                      <FormItem label={"Account_tocredit_onsupersave_tosavings_transfer"}>
                          <Select 
                          placeholder="Account" 
                          style={{  }} 
                          value={this.state.account_tocredit_onsupersave_tosavings_transfer}
                          onChange={(val)=>{this.setState({account_tocredit_onsupersave_tosavings_transfer:val})}} 
                          showSearch
                          optionFilterProp="children"
                          onFocus={this.onFocus}
                          onBlur={this.onBlur}
                          onSearch={this.onSearch}                    
                          >
                            {this.state.companyaccounts.map(
                              (accnt)=>(
                                <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
                              ))}
                          </Select>
                      </FormItem>


                      <FormItem label={"Account_todebit_onteebagoolo_tosavings_transfer"}>
                          <Select 
                          placeholder="Account" 
                          style={{  }} 
                          value={this.state.account_todebit_onteebagoolo_tosavings_transfer}
                          onChange={(val)=>{this.setState({account_todebit_onteebagoolo_tosavings_transfer:val})}} 
                          showSearch
                          optionFilterProp="children"
                          onFocus={this.onFocus}
                          onBlur={this.onBlur}
                          onSearch={this.onSearch}                    
                          >
                            {this.state.companyaccounts.map(
                              (accnt)=>(
                                <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
                              ))}
                          </Select>
                      </FormItem>



                      <FormItem label={"Account_tocredit_onteebagoolo_tosavings_transfer"}>
                          <Select 
                          placeholder="Account" 
                          style={{  }} 
                          value={this.state.account_tocredit_onteebagoolo_tosavings_transfer}
                          onChange={(val)=>{this.setState({account_tocredit_onteebagoolo_tosavings_transfer:val})}} 
                          showSearch
                          optionFilterProp="children"
                          onFocus={this.onFocus}
                          onBlur={this.onBlur}
                          onSearch={this.onSearch}                    
                          >
                            {this.state.companyaccounts.map(
                              (accnt)=>(
                                <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
                              ))}
                          </Select>
                      </FormItem>

                      <h3>Ledger profile settings (Interest awarding)</h3>
                      <FormItem label={"Account_tocredit_onossavings_interest_awarded"}>
                          <Select 
                          placeholder="Account" 
                          style={{  }} 
                          value={this.state.account_tocredit_onossavings_interest_awarded}
                          onChange={(val)=>{this.setState({account_tocredit_onossavings_interest_awarded:val})}} 
                          showSearch
                          optionFilterProp="children"
                          onFocus={this.onFocus}
                          onBlur={this.onBlur}
                          onSearch={this.onSearch}                    
                          >
                            {this.state.companyaccounts.map(
                              (accnt)=>(
                                <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
                              ))}
                          </Select>
                      </FormItem>

                      <FormItem label={"Account_tocredit_onteebagoolo_interest_awarded"}>
                          <Select 
                          placeholder="Account" 
                          style={{  }} 
                          value={this.state.account_tocredit_onteebagoolo_interest_awarded}
                          onChange={(val)=>{this.setState({account_tocredit_onteebagoolo_interest_awarded:val})}} 
                          showSearch
                          optionFilterProp="children"
                          onFocus={this.onFocus}
                          onBlur={this.onBlur}
                          onSearch={this.onSearch}                    
                          >
                            {this.state.companyaccounts.map(
                              (accnt)=>(
                                <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
                              ))}
                          </Select>
                      </FormItem>



                      <FormItem label={"Account_todebit_onossavings_interest_expense"}>
                          <Select 
                          placeholder="Account" 
                          style={{  }} 
                          value={this.state.account_todebit_onossavings_interest_expense}
                          onChange={(val)=>{this.setState({account_todebit_onossavings_interest_expense:val})}} 
                          showSearch
                          optionFilterProp="children"
                          onFocus={this.onFocus}
                          onBlur={this.onBlur}
                          onSearch={this.onSearch}                    
                          >
                            {this.state.companyaccounts.map(
                              (accnt)=>(
                                <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
                              ))}
                          </Select>
                      </FormItem>



                      <FormItem label={"Account_for_savingsinterest_taxes"}>
                          <Select 
                          placeholder="Account" 
                          style={{  }} 
                          value={this.state.account_for_savingsinterest_taxes}
                          onChange={(val)=>{this.setState({account_for_savingsinterest_taxes:val})}} 
                          showSearch
                          optionFilterProp="children"
                          onFocus={this.onFocus}
                          onBlur={this.onBlur}
                          onSearch={this.onSearch}                    
                          >
                            {this.state.companyaccounts.map(
                              (accnt)=>(
                                <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
                              ))}
                          </Select>
                      </FormItem>

                      <FormItem name="automated_endofmonth_interesttransfer" label="Automated end of month savings interest transfer?" >
                        <Switch
                            checked={this.state.automated_endofmonth_interesttransfer}
                            onChange={val=>{this.setState({automated_endofmonth_interesttransfer:!this.state.automated_endofmonth_interesttransfer})}}
                            checkedChildren="ON"
                            unCheckedChildren="OFF"
                            />
                      </FormItem>

                      <FormItem name="savingsinterest_taxed" label="Savings interest taxed?" >
                        <Switch
                            checked={this.state.savingsinterest_taxed}
                            onChange={val=>{this.setState({savingsinterest_taxed:!this.state.savingsinterest_taxed})}}
                            checkedChildren="ON"
                            unCheckedChildren="OFF"
                            />
                      </FormItem>


                      <FormItem label="Savings intest tax percentage">
                            <Input type='number' placeholder="savingsintest_taxpercentage" value={this.state.savingsintest_taxpercentage} onChange={(val)=>{this.setState({savingsintest_taxpercentage:val.target.value})}} />
                        </FormItem>


                      <h3>Dividends Accounts</h3>
               
                      <FormItem label={"Account_fordividend_payable"}>
                        <Select 
                        placeholder="account_fordividend_payable" 
                        style={{  }} 
                        value={this.state.account_fordividend_payable}
                        onChange={(val)=>{this.setState({account_fordividend_payable:val})}} 
                        showSearch
                        optionFilterProp="children"
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        onSearch={this.onSearch}                    
                        >
                          {this.state.companyaccounts.map(
                            (accnt)=>(
                              <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
                            ))}
                        </Select>
                    </FormItem>

                    <FormItem label={"Account_for_retained_earnings"}>
                        <Select 
                        placeholder="account_for_retained_earnings" 
                        style={{  }} 
                        value={this.state.account_for_retained_earnings}
                        onChange={(val)=>{this.setState({account_for_retained_earnings:val})}} 
                        showSearch
                        optionFilterProp="children"
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        onSearch={this.onSearch}                    
                        >
                          {this.state.companyaccounts.map(
                            (accnt)=>(
                              <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
                            ))}
                        </Select>
                    </FormItem>


                    <FormItem label={"Account_for_dividend_taxes"}>
                        <Select 
                        placeholder="account_for_dividend_taxes" 
                        style={{  }} 
                        value={this.state.account_for_dividend_taxes}
                        onChange={(val)=>{this.setState({account_for_dividend_taxes:val})}} 
                        showSearch
                        optionFilterProp="children"
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        onSearch={this.onSearch}                    
                        >
                          {this.state.companyaccounts.map(
                            (accnt)=>(
                              <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
                            ))}
                        </Select>
                    </FormItem>

                    <h3>Sms</h3>
                      <FormItem name="usesms" label="use sms?" >
                        <Switch
                            checked={this.state.usesms}
                            onChange={val=>{this.setState({usesms:!this.state.usesms})}}
                            checkedChildren="ON"
                            unCheckedChildren="OFF"
                            />
                      </FormItem>

                      <h3>Default accounts</h3>
                      
                  <FormItem label={"Default account for officecollection debiting"}>
                      <Select 
                      placeholder="Account" 
                      style={{  }} 
                      value={this.state.defaultaccount_for_officecollection_debiting}
                      onChange={(val)=>{this.setState({defaultaccount_for_officecollection_debiting:val})}} 
                      showSearch
                      optionFilterProp="children"
                      onFocus={this.onFocus}
                      onBlur={this.onBlur}
                      onSearch={this.onSearch}                    
                      >
                        {this.state.companyaccounts.map(
                          (accnt)=>(
                            <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
                          ))}
                      </Select>
                  </FormItem>


                  <FormItem label={"Default account for savingswithdraw crediting"}>
                      <Select 
                      placeholder="Account" 
                      style={{  }} 
                      value={this.state.defaultaccount_for_savingswithdraw_crediting}
                      onChange={(val)=>{this.setState({defaultaccount_for_savingswithdraw_crediting:val})}} 
                      showSearch
                      optionFilterProp="children"
                      onFocus={this.onFocus}
                      onBlur={this.onBlur}
                      onSearch={this.onSearch}                    
                      >
                        {this.state.companyaccounts.map(
                          (accnt)=>(
                            <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
                          ))}
                      </Select>
                  </FormItem>

                  <FormItem label={"Default account for loan disbirsement crediting"}>
                      <Select 
                      placeholder="Account" 
                      style={{  }} 
                      value={this.state.defaultaccount_for_loanissuance_crediting}
                      onChange={(val)=>{this.setState({defaultaccount_for_loanissuance_crediting:val})}} 
                      showSearch
                      optionFilterProp="children"
                      onFocus={this.onFocus}
                      onBlur={this.onBlur}
                      onSearch={this.onSearch}                    
                      >
                        {this.state.companyaccounts.map(
                          (accnt)=>(
                            <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
                          ))}
                      </Select>
                  </FormItem>


                  <FormItem label={"Expense account for loanbaddebt clearance"}>
                      <Select 
                      placeholder="Account" 
                      style={{  }} 
                      value={this.state.expense_account_forloanbaddebtclearance}
                      onChange={(val)=>{this.setState({expense_account_forloanbaddebtclearance:val})}} 
                      showSearch
                      optionFilterProp="children"
                      onFocus={this.onFocus}
                      onBlur={this.onBlur}
                      onSearch={this.onSearch}                    
                      >
                        {this.state.companyaccounts.map(
                          (accnt)=>(
                            <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
                          ))}
                      </Select>
                </FormItem>


                <FormItem>
                    <Button type="primary" htmlType="submit" >Update Profile</Button>
                </FormItem>

                </Form>
              </Card>

              </TabPane>

              <TabPane tab="Special settings" key="002" >
                <CompanyProfile_autosettings />
             </TabPane>

            </Tabs>

          </div>
      )}
    }
}

export default CompanyProfile; 