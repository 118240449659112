import React from 'react';
import { Form, Input, Button,Select,Switch,Spin,DatePicker,Result,message,Card,InputNumber,Table } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import moment from 'moment';
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined,DownloadOutlined } from '@ant-design/icons';
import {
  Col,
  Row,
} from 'reactstrap';
import { number } from 'prop-types';
import CurrencyFormat from 'react-currency-format';
import * as reactstrp from 'reactstrap';
import ReactExport from "@ibrahimrahmani/react-export-excel";


var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const dateFormat = 'DD/MM/YYYY';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


var sacco= ''
var token= ''


class LoanCalculator extends React.Component {

  state = {
    datetoday:moment().format(dateFormat).toString(),
    loanamount:0,
    interestrate:0,
    interestratetype:'',
    loanperiod:0,
    datarequested:true,
    dateofissuance:moment(),
    dateofissuancestring:moment().format(dateFormat).toString(),
    proposedschedule:[],
    roundoffvalue:0,
    noofdaysinloan:0,
    pmt_onreducing_balance:false,
    dailyschedule:[],
    payment_type:'monthly'

  }

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }
    
    this.setState({datarequested:false})
  }


  //calculate principleTotal
  calculateScheduleTotal=()=>{
    var principleTotal=0
    this.state.proposedschedule.map(
      (item)=>{
        principleTotal+=Number(item.principle)
      })

    return principleTotal.toFixed(2);
  }

    //calculate principleTotal
    calculateInterestTotal=()=>{
      var InterestTotal=0
      this.state.proposedschedule.map(
        (item)=>{
          InterestTotal+=Number(item.interest)
        })
  
      return InterestTotal.toFixed(2);
    }


 
    //calculate principleTotal
    calculateTotalPay=()=>{
      var TotalPay=0
      this.state.proposedschedule.map(
        (item)=>{
          TotalPay+=Number(item.total)
        })
  
      return TotalPay.toFixed(2);
    }


    //calculate rate
    calCulateRate=()=>{
      var rate=0

      if (this.state.payment_type==="monthly"){
        rate=Number(this.state.interestrate/12)
      }

      if (this.state.payment_type==="weekly"){
        rate=Number(this.state.interestrate/52)

      }
      if (this.state.payment_type==="daily"){
        rate=Number(this.state.interestrate/365)

      }

      return rate
    }


    //calculate pmt installment now
    calculatePMT_installment=(amount,annualrate,period,payment_type)=>{
      var monthlypay=0

      if (payment_type=="monthly"){
        monthlypay=0
        var r = annualrate/(12*100)
        var emi = amount * r * ((1+r)**period)/((1+r)**period - 1)
        monthlypay=emi

      }


      if (payment_type=="weekly"){
        monthlypay=0
        var r = annualrate/(52*100)
        var emi = amount * r * ((1+r)**period)/((1+r)**period - 1)
        monthlypay=emi
      }
     

    if (payment_type=="daily"){
      monthlypay=0
      var r = annualrate/(365*100)
     var  emi = amount * r * ((1+r)**period)/((1+r)**period - 1)
      monthlypay=emi

    }
    

    return monthlypay.toFixed(2)

    }



    //calculte the pmt interets pay
    calcultePMT_interest=(p,R,p_type)=>{
      var intpay=0

      if (p_type=="monthly"){
       var calemi = p*(R/100)/12
       intpay=calemi

      }
         
      if (p_type=="weekly"){
        var calemi = p*(R/100)/52
        intpay=calemi

      }

      if (p_type=="daily"){
       var  calemi = p*(R/100)/365
        intpay=calemi

      }
         
 
     return intpay.toFixed(2)


    }





  //submit button pressed
  handleFormSubmit=(event) =>{

    if (this.state.roundoffvalue<=0){
      message.error("Roundoff value cant be less or equal to zero")

    }else if (this.state.loanamount<=0){
      message.error("Loan amount value cant be less or equal to zero")

    }else if (this.state.loanperiod<=0){
      message.error("Loan period value cant be less or equal to zero")

    }else if (this.state.interestrate<=0){
      message.error("Interest rate value cant be less or equal to zero")

    }else if (this.state.interestratetype===""){
      message.error("Interest rate type cant be blank")

    }

    else{

      //clear the schedule
      this.setState({proposedschedule:[]})
      this.setState({datarequested:true})

      //check payment type
      if (this.state.payment_type==="monthly"){
        if (this.state.interestratetype==="flat"){
          var totalprinciple=Number(this.state.loanamount)
          var principlepay=totalprinciple/Number(this.state.loanperiod)
          var interestpay=Number(totalprinciple)*(this.calCulateRate()/100)
          var period=Number(this.state.loanperiod)
    
          console.log(this.countDecimals(principlepay))
    
    
          //split issuance date to create loop
          var dataSplit = this.state.dateofissuancestring.split('/');
          var dateConverted;
          if (dataSplit[2].split(" ").length > 1) {
    
              var hora = dataSplit[2].split(" ")[1].split(':');
              dataSplit[2] = dataSplit[2].split(" ")[0];
              dateConverted = new Date(dataSplit[2], dataSplit[1]-1, dataSplit[0], hora[0], hora[1]);
    
          } else {
              dateConverted = new Date(dataSplit[2], dataSplit[1] - 1, dataSplit[0]);
          }
    
          var year = dateConverted.getFullYear();
          var month = dateConverted.getMonth();
          var date = dateConverted.getDate();
    
          for (var i=1;i<=period;i++){
            //set date now 
            var paydate=new Date(year,month+i, date).toLocaleDateString('en-GB', {day : 'numeric',month : 'numeric',year : 'numeric'}).split(' ').join('/');
    
            var scheduleitem={
              key:i,
              date:paydate,
              principlebf:Math.ceil(totalprinciple/this.state.roundoffvalue)*this.state.roundoffvalue,
              principle:Math.ceil(principlepay/this.state.roundoffvalue)*this.state.roundoffvalue,
              interest:Math.ceil(interestpay/this.state.roundoffvalue)*this.state.roundoffvalue,
              principlebalance:Math.ceil((totalprinciple-principlepay)/this.state.roundoffvalue)*this.state.roundoffvalue,
              total:Math.ceil((Number(principlepay)+Number(interestpay))/this.state.roundoffvalue)*this.state.roundoffvalue
            }
    
            //add to shedule object
              this.setState({
              proposedschedule: [...this.state.proposedschedule, scheduleitem]
              });
    
    
    
              //get the remaining principle balance
              totalprinciple-=Number(principlepay)
    
              }
    
              //total on schedule minus principle
              var diffinprincipleprinciple=Number(this.calculateScheduleTotal())-Number(this.state.loanamount)
    
              
              //now go ahead and edit last row
              const elementsIndex = this.state.proposedschedule.findIndex(element => element.key ==period)
    
              let newArray = [...this.state.proposedschedule]
          
              var lastpayableprinciple=Number(this.state.proposedschedule[elementsIndex].principle)-Number(diffinprincipleprinciple)
    
              newArray[elementsIndex] = {...newArray[elementsIndex], 
                principle:lastpayableprinciple,
                principlebalance:0,
                total:Number(lastpayableprinciple)+Math.ceil(interestpay/this.state.roundoffvalue)*this.state.roundoffvalue,
              }
          
              this.setState({
              proposedschedule: newArray,
              });
    
    
              this.setState({datarequested:false}) 
    
    
        }else{

          //check if PMT formula is enabled now

          if (this.state.pmt_onreducing_balance===true){
            var installment=this.calculatePMT_installment(this.state.loanamount,this.state.interestrate,this.state.loanperiod,this.state.payment_type)

            console.log("instalment: "+String(installment))

            var totalprinciple=Number(this.state.loanamount)
            var principlepay=totalprinciple/Number(this.state.loanperiod)
            var period=Number(this.state.loanperiod)

            var principlebefore=Number(this.state.loanamount)
      
            console.log(this.countDecimals(principlepay))
      
            //split issuance date to create loop
            var dataSplit = this.state.dateofissuancestring.split('/');
            var dateConverted;
            if (dataSplit[2].split(" ").length > 1) {
      
                var hora = dataSplit[2].split(" ")[1].split(':');
                dataSplit[2] = dataSplit[2].split(" ")[0];
                dateConverted = new Date(dataSplit[2], dataSplit[1]-1, dataSplit[0], hora[0], hora[1]);
      
            } else {
                dateConverted = new Date(dataSplit[2], dataSplit[1] - 1, dataSplit[0]);
            }
      
            var year = dateConverted.getFullYear();
            var month = dateConverted.getMonth();
            var date = dateConverted.getDate();
      
            for (var i=1;i<=period;i++){
              //set date now 
              var paydate=new Date(year,month+i, date).toLocaleDateString('en-GB', {day : 'numeric',month : 'numeric',year : 'numeric'}).split(' ').join('/');
      
              //now set interest in the loop only
              //var interestpay=Number(totalprinciple)*(this.calCulateRate()/100)
              var interestpay=this.calcultePMT_interest(Number(principlebefore),this.state.interestrate,this.state.payment_type)
              //get pmt installment
              var principlepay=installment-interestpay
              var balance=principlebefore-principlepay

              var scheduleitem={
                "key":i,
                "date":paydate,
                "principlebf":principlebefore.toFixed(2),
                "principle":principlepay.toFixed(2),
                "interest":interestpay,
                "principlebalance":balance.toFixed(2),
                "total":installment
              }
      
              //add to shedule object
                this.setState({
                proposedschedule: [...this.state.proposedschedule, scheduleitem]
                });
      
                //get the remaining principle balance
                principlebefore-=principlepay
      
                }
      
      
                this.setState({datarequested:false}) 




          }else{
            var totalprinciple=Number(this.state.loanamount)
            var principlepay=totalprinciple/Number(this.state.loanperiod)
            var period=Number(this.state.loanperiod)
      
            console.log(this.countDecimals(principlepay))
      
            //split issuance date to create loop
            var dataSplit = this.state.dateofissuancestring.split('/');
            var dateConverted;
            if (dataSplit[2].split(" ").length > 1) {
      
                var hora = dataSplit[2].split(" ")[1].split(':');
                dataSplit[2] = dataSplit[2].split(" ")[0];
                dateConverted = new Date(dataSplit[2], dataSplit[1]-1, dataSplit[0], hora[0], hora[1]);
      
            } else {
                dateConverted = new Date(dataSplit[2], dataSplit[1] - 1, dataSplit[0]);
            }
      
            var year = dateConverted.getFullYear();
            var month = dateConverted.getMonth();
            var date = dateConverted.getDate();
      
            for (var i=1;i<=period;i++){
              //set date now 
              var paydate=new Date(year,month+i, date).toLocaleDateString('en-GB', {day : 'numeric',month : 'numeric',year : 'numeric'}).split(' ').join('/');
      
              //now set interest in the loop only
              var interestpay=Number(totalprinciple)*(this.calCulateRate()/100)
      
      
              var scheduleitem={
                key:i,
                date:paydate,
                principlebf:Math.ceil(totalprinciple/this.state.roundoffvalue)*this.state.roundoffvalue,
                principle:Math.ceil(principlepay/this.state.roundoffvalue)*this.state.roundoffvalue,
                interest:Math.ceil(interestpay/this.state.roundoffvalue)*this.state.roundoffvalue,
                principlebalance:Math.ceil((totalprinciple-principlepay)/this.state.roundoffvalue)*this.state.roundoffvalue,
                total:Math.ceil((Number(principlepay)+Number(interestpay))/this.state.roundoffvalue)*this.state.roundoffvalue
              }
      
              //add to shedule object
                this.setState({
                proposedschedule: [...this.state.proposedschedule, scheduleitem]
                });
      
      
      
                //get the remaining principle balance
                totalprinciple-=Number(principlepay)
      
                }
      
                //total on schedule minus principle
                var diffinprincipleprinciple=Number(this.calculateScheduleTotal())-Number(this.state.loanamount)
      
                
                //now go ahead and edit last row
                const elementsIndex = this.state.proposedschedule.findIndex(element => element.key ==period)
      
                let newArray = [...this.state.proposedschedule]
            
                var lastpayableprinciple=Number(this.state.proposedschedule[elementsIndex].principle)-Number(diffinprincipleprinciple)
      
                newArray[elementsIndex] = {...newArray[elementsIndex], 
                  principle:lastpayableprinciple,
                  principlebalance:0,
                  total:Number(lastpayableprinciple)+Math.ceil(interestpay/this.state.roundoffvalue)*this.state.roundoffvalue,
                }
            
                this.setState({
                proposedschedule: newArray,
                });
      
      
                this.setState({datarequested:false}) 
      


          }


        }
  

      }else if (this.state.payment_type==="daily"){

        if (this.state.interestratetype==="flat"){
          var totalprinciple=Number(this.state.loanamount)
          var principlepay=totalprinciple/Number(this.state.loanperiod)
          var interestpay=Number(totalprinciple)*(this.calCulateRate()/100)
          var period=Number(this.state.loanperiod)
    
          console.log(this.countDecimals(principlepay))
    
    
          //split issuance date to create loop
          var dataSplit = this.state.dateofissuancestring.split('/');
          var dateConverted;
          if (dataSplit[2].split(" ").length > 1) {
    
              var hora = dataSplit[2].split(" ")[1].split(':');
              dataSplit[2] = dataSplit[2].split(" ")[0];
              dateConverted = new Date(dataSplit[2], dataSplit[1]-1, dataSplit[0], hora[0], hora[1]);
    
          } else {
              dateConverted = new Date(dataSplit[2], dataSplit[1] - 1, dataSplit[0]);
          }
    
          var year = dateConverted.getFullYear();
          var month = dateConverted.getMonth();
          var date = dateConverted.getDate();
    
          for (var i=1;i<=period;i++){
            //set date now 
            var paydate=new Date(year,month, date+i).toLocaleDateString('en-GB', {day : 'numeric',month : 'numeric',year : 'numeric'}).split(' ').join('/');
    
            var scheduleitem={
              key:i,
              date:paydate,
              principlebf:Math.ceil(totalprinciple/this.state.roundoffvalue)*this.state.roundoffvalue,
              principle:Math.ceil(principlepay/this.state.roundoffvalue)*this.state.roundoffvalue,
              interest:Math.ceil(interestpay/this.state.roundoffvalue)*this.state.roundoffvalue,
              principlebalance:Math.ceil((totalprinciple-principlepay)/this.state.roundoffvalue)*this.state.roundoffvalue,
              total:Math.ceil((Number(principlepay)+Number(interestpay))/this.state.roundoffvalue)*this.state.roundoffvalue
            }
    
            //add to shedule object
              this.setState({
              proposedschedule: [...this.state.proposedschedule, scheduleitem]
              });
    
    
    
              //get the remaining principle balance
              totalprinciple-=Number(principlepay)
    
              }
    
              //total on schedule minus principle
              var diffinprincipleprinciple=Number(this.calculateScheduleTotal())-Number(this.state.loanamount)
    
              
              //now go ahead and edit last row
              const elementsIndex = this.state.proposedschedule.findIndex(element => element.key ==period)
    
              let newArray = [...this.state.proposedschedule]
          
              var lastpayableprinciple=Number(this.state.proposedschedule[elementsIndex].principle)-Number(diffinprincipleprinciple)
    
              newArray[elementsIndex] = {...newArray[elementsIndex], 
                principle:lastpayableprinciple,
                principlebalance:0,
                total:Number(lastpayableprinciple)+Math.ceil(interestpay/this.state.roundoffvalue)*this.state.roundoffvalue,
              }
          
              this.setState({
              proposedschedule: newArray,
              });
    
    
              this.setState({datarequested:false}) 
    
    
        }else{



          //check if PMT formula is enabled now

          if (this.state.pmt_onreducing_balance===true){
            var installment=this.calculatePMT_installment(this.state.loanamount,this.state.interestrate,this.state.loanperiod,this.state.payment_type)

            console.log("instalment: "+String(installment))

            var totalprinciple=Number(this.state.loanamount)
            var principlepay=totalprinciple/Number(this.state.loanperiod)
            var period=Number(this.state.loanperiod)

            var principlebefore=Number(this.state.loanamount)
      
            console.log(this.countDecimals(principlepay))
      
            //split issuance date to create loop
            var dataSplit = this.state.dateofissuancestring.split('/');
            var dateConverted;
            if (dataSplit[2].split(" ").length > 1) {
      
                var hora = dataSplit[2].split(" ")[1].split(':');
                dataSplit[2] = dataSplit[2].split(" ")[0];
                dateConverted = new Date(dataSplit[2], dataSplit[1]-1, dataSplit[0], hora[0], hora[1]);
      
            } else {
                dateConverted = new Date(dataSplit[2], dataSplit[1] - 1, dataSplit[0]);
            }
      
            var year = dateConverted.getFullYear();
            var month = dateConverted.getMonth();
            var date = dateConverted.getDate();
      
            for (var i=1;i<=period;i++){
              //set date now 
              var paydate=new Date(year,month, date+i).toLocaleDateString('en-GB', {day : 'numeric',month : 'numeric',year : 'numeric'}).split(' ').join('/');
      
              //now set interest in the loop only
              //var interestpay=Number(totalprinciple)*(this.calCulateRate()/100)
              var interestpay=this.calcultePMT_interest(Number(principlebefore),this.state.interestrate,this.state.payment_type)
              //get pmt installment
              var principlepay=installment-interestpay
              var balance=principlebefore-principlepay

              var scheduleitem={
                "key":i,
                "date":paydate,
                "principlebf":principlebefore.toFixed(2),
                "principle":principlepay.toFixed(2),
                "interest":interestpay,
                "principlebalance":balance.toFixed(2),
                "total":installment
              }
      
              //add to shedule object
                this.setState({
                proposedschedule: [...this.state.proposedschedule, scheduleitem]
                });
      
                //get the remaining principle balance
                principlebefore-=principlepay
      
                }
      
      
                this.setState({datarequested:false}) 




          }else{


          var totalprinciple=Number(this.state.loanamount)
          var principlepay=totalprinciple/Number(this.state.loanperiod)
          var period=Number(this.state.loanperiod)
    
          console.log(this.countDecimals(principlepay))
    
          //split issuance date to create loop
          var dataSplit = this.state.dateofissuancestring.split('/');
          var dateConverted;
          if (dataSplit[2].split(" ").length > 1) {
    
              var hora = dataSplit[2].split(" ")[1].split(':');
              dataSplit[2] = dataSplit[2].split(" ")[0];
              dateConverted = new Date(dataSplit[2], dataSplit[1]-1, dataSplit[0], hora[0], hora[1]);
    
          } else {
              dateConverted = new Date(dataSplit[2], dataSplit[1] - 1, dataSplit[0]);
          }
    
          var year = dateConverted.getFullYear();
          var month = dateConverted.getMonth();
          var date = dateConverted.getDate();
    
          for (var i=1;i<=period;i++){
            //set date now 
            var paydate=new Date(year,month,date+i).toLocaleDateString('en-GB', {day : 'numeric',month : 'numeric',year : 'numeric'}).split(' ').join('/');
    
            //now set interest in the loop only
            var interestpay=Number(totalprinciple)*(this.calCulateRate()/100)
    
    
            var scheduleitem={
              key:i,
              date:paydate,
              principlebf:Math.ceil(totalprinciple/this.state.roundoffvalue)*this.state.roundoffvalue,
              principle:Math.ceil(principlepay/this.state.roundoffvalue)*this.state.roundoffvalue,
              interest:Math.ceil(interestpay/this.state.roundoffvalue)*this.state.roundoffvalue,
              principlebalance:Math.ceil((totalprinciple-principlepay)/this.state.roundoffvalue)*this.state.roundoffvalue,
              total:Math.ceil((Number(principlepay)+Number(interestpay))/this.state.roundoffvalue)*this.state.roundoffvalue
            }
    
            //add to shedule object
              this.setState({
              proposedschedule: [...this.state.proposedschedule, scheduleitem]
              });
    
    
    
              //get the remaining principle balance
              totalprinciple-=Number(principlepay)
    
              }
    
              //total on schedule minus principle
              var diffinprincipleprinciple=Number(this.calculateScheduleTotal())-Number(this.state.loanamount)
    
              
              //now go ahead and edit last row
              const elementsIndex = this.state.proposedschedule.findIndex(element => element.key ==period)
    
              let newArray = [...this.state.proposedschedule]
          
              var lastpayableprinciple=Number(this.state.proposedschedule[elementsIndex].principle)-Number(diffinprincipleprinciple)
    
              newArray[elementsIndex] = {...newArray[elementsIndex], 
                principle:lastpayableprinciple,
                principlebalance:0,
                total:Number(lastpayableprinciple)+Math.ceil(interestpay/this.state.roundoffvalue)*this.state.roundoffvalue,
              }
          
              this.setState({
              proposedschedule: newArray,
              });
    
              this.setState({datarequested:false}) 


          }


    
        }


      }else if (this.state.payment_type==="weekly"){

        if (this.state.interestratetype==="flat"){
          var totalprinciple=Number(this.state.loanamount)
          var principlepay=totalprinciple/Number(this.state.loanperiod)
          var interestpay=Number(totalprinciple)*(this.calCulateRate()/100)
          var period=Number(this.state.loanperiod)
    
          console.log(this.countDecimals(principlepay))
    
    
          //split issuance date to create loop
          var dataSplit = this.state.dateofissuancestring.split('/');
          var dateConverted;
          if (dataSplit[2].split(" ").length > 1) {
    
              var hora = dataSplit[2].split(" ")[1].split(':');
              dataSplit[2] = dataSplit[2].split(" ")[0];
              dateConverted = new Date(dataSplit[2], dataSplit[1]-1, dataSplit[0], hora[0], hora[1]);
    
          } else {
              dateConverted = new Date(dataSplit[2], dataSplit[1] - 1, dataSplit[0]);
          }
    
          var year = dateConverted.getFullYear();
          var month = dateConverted.getMonth();
          var date = dateConverted.getDate();
    
          for (var i=1;i<=period;i++){
            //set date now 
            var paydate=new Date(year,month, date+(i*7)).toLocaleDateString('en-GB', {day : 'numeric',month : 'numeric',year : 'numeric'}).split(' ').join('/');
    
            var scheduleitem={
              key:i,
              date:paydate,
              principlebf:Math.ceil(totalprinciple/this.state.roundoffvalue)*this.state.roundoffvalue,
              principle:Math.ceil(principlepay/this.state.roundoffvalue)*this.state.roundoffvalue,
              interest:Math.ceil(interestpay/this.state.roundoffvalue)*this.state.roundoffvalue,
              principlebalance:Math.ceil((totalprinciple-principlepay)/this.state.roundoffvalue)*this.state.roundoffvalue,
              total:Math.ceil((Number(principlepay)+Number(interestpay))/this.state.roundoffvalue)*this.state.roundoffvalue
            }
    
            //add to shedule object
              this.setState({
              proposedschedule: [...this.state.proposedschedule, scheduleitem]
              });
    
    
    
              //get the remaining principle balance
              totalprinciple-=Number(principlepay)
    
              }
    
              //total on schedule minus principle
              var diffinprincipleprinciple=Number(this.calculateScheduleTotal())-Number(this.state.loanamount)
    
              
              //now go ahead and edit last row
              const elementsIndex = this.state.proposedschedule.findIndex(element => element.key ==period)
    
              let newArray = [...this.state.proposedschedule]
          
              var lastpayableprinciple=Number(this.state.proposedschedule[elementsIndex].principle)-Number(diffinprincipleprinciple)
    
              newArray[elementsIndex] = {...newArray[elementsIndex], 
                principle:lastpayableprinciple,
                principlebalance:0,
                total:Number(lastpayableprinciple)+Math.ceil(interestpay/this.state.roundoffvalue)*this.state.roundoffvalue,
              }
          
              this.setState({
              proposedschedule: newArray,
              });
    
    
              this.setState({datarequested:false}) 
    
    
        }else{


          //check if PMT formula is enabled now

          if (this.state.pmt_onreducing_balance===true){
            var installment=this.calculatePMT_installment(this.state.loanamount,this.state.interestrate,this.state.loanperiod,this.state.payment_type)

            console.log("instalment: "+String(installment))

            var totalprinciple=Number(this.state.loanamount)
            var principlepay=totalprinciple/Number(this.state.loanperiod)
            var period=Number(this.state.loanperiod)

            var principlebefore=Number(this.state.loanamount)
      
            console.log(this.countDecimals(principlepay))
      
            //split issuance date to create loop
            var dataSplit = this.state.dateofissuancestring.split('/');
            var dateConverted;
            if (dataSplit[2].split(" ").length > 1) {
      
                var hora = dataSplit[2].split(" ")[1].split(':');
                dataSplit[2] = dataSplit[2].split(" ")[0];
                dateConverted = new Date(dataSplit[2], dataSplit[1]-1, dataSplit[0], hora[0], hora[1]);
      
            } else {
                dateConverted = new Date(dataSplit[2], dataSplit[1] - 1, dataSplit[0]);
            }
      
            var year = dateConverted.getFullYear();
            var month = dateConverted.getMonth();
            var date = dateConverted.getDate();
      
            for (var i=1;i<=period;i++){
              //set date now 
              var paydate=new Date(year,month, date+(i*7)).toLocaleDateString('en-GB', {day : 'numeric',month : 'numeric',year : 'numeric'}).split(' ').join('/');
      
              //now set interest in the loop only
              //var interestpay=Number(totalprinciple)*(this.calCulateRate()/100)
              var interestpay=this.calcultePMT_interest(Number(principlebefore),this.state.interestrate,this.state.payment_type)
              //get pmt installment
              var principlepay=installment-interestpay
              var balance=principlebefore-principlepay

              var scheduleitem={
                "key":i,
                "date":paydate,
                "principlebf":principlebefore.toFixed(2),
                "principle":principlepay.toFixed(2),
                "interest":interestpay,
                "principlebalance":balance.toFixed(2),
                "total":installment
              }
      
              //add to shedule object
                this.setState({
                proposedschedule: [...this.state.proposedschedule, scheduleitem]
                });
      
                //get the remaining principle balance
                principlebefore-=principlepay
      
                }
      
      
                this.setState({datarequested:false}) 




          }else{


          var totalprinciple=Number(this.state.loanamount)
          var principlepay=totalprinciple/Number(this.state.loanperiod)
          var period=Number(this.state.loanperiod)
    
          console.log(this.countDecimals(principlepay))
    
          //split issuance date to create loop
          var dataSplit = this.state.dateofissuancestring.split('/');
          var dateConverted;
          if (dataSplit[2].split(" ").length > 1) {
    
              var hora = dataSplit[2].split(" ")[1].split(':');
              dataSplit[2] = dataSplit[2].split(" ")[0];
              dateConverted = new Date(dataSplit[2], dataSplit[1]-1, dataSplit[0], hora[0], hora[1]);
    
          } else {
              dateConverted = new Date(dataSplit[2], dataSplit[1] - 1, dataSplit[0]);
          }
    
          var year = dateConverted.getFullYear();
          var month = dateConverted.getMonth();
          var date = dateConverted.getDate();
    
          for (var i=1;i<=period;i++){
            //set date now 
            var paydate=new Date(year,month,date+(i*7)).toLocaleDateString('en-GB', {day : 'numeric',month : 'numeric',year : 'numeric'}).split(' ').join('/');
    
            //now set interest in the loop only
            var interestpay=Number(totalprinciple)*(this.calCulateRate()/100)
    
    
            var scheduleitem={
              key:i,
              date:paydate,
              principlebf:Math.ceil(totalprinciple/this.state.roundoffvalue)*this.state.roundoffvalue,
              principle:Math.ceil(principlepay/this.state.roundoffvalue)*this.state.roundoffvalue,
              interest:Math.ceil(interestpay/this.state.roundoffvalue)*this.state.roundoffvalue,
              principlebalance:Math.ceil((totalprinciple-principlepay)/this.state.roundoffvalue)*this.state.roundoffvalue,
              total:Math.ceil((Number(principlepay)+Number(interestpay))/this.state.roundoffvalue)*this.state.roundoffvalue
            }
    
            //add to shedule object
              this.setState({
              proposedschedule: [...this.state.proposedschedule, scheduleitem]
              });
    
   
    
              //get the remaining principle balance
              totalprinciple-=Number(principlepay)
    
              }
    
              //total on schedule minus principle
              var diffinprincipleprinciple=Number(this.calculateScheduleTotal())-Number(this.state.loanamount)
    
              
              //now go ahead and edit last row
              const elementsIndex = this.state.proposedschedule.findIndex(element => element.key ==period)
    
              let newArray = [...this.state.proposedschedule]
          
              var lastpayableprinciple=Number(this.state.proposedschedule[elementsIndex].principle)-Number(diffinprincipleprinciple)
    
              newArray[elementsIndex] = {...newArray[elementsIndex], 
                principle:lastpayableprinciple,
                principlebalance:0,
                total:Number(lastpayableprinciple)+Math.ceil(interestpay/this.state.roundoffvalue)*this.state.roundoffvalue,
              }
          
              this.setState({
              proposedschedule: newArray,
              });
    
              this.setState({datarequested:false}) 



          }


        }

      }


    }



  }

  //count decimales
   countDecimals =(value) =>{
    if (Math.floor(value) !== value)
        return value.toString().split(".")[1].length || 0;
    return 0;
    }

    //check if its weekend
     isSunday=(date)=> {
      return date.getDay() === 0;
    }

    isSaturday=(date)=> {
      return date.getDay() === 6;
    }


  handleloanamountChange= (e) => this.setState({ loanamount: e});
  handleinterestrateChange= (e) => this.setState({ interestrate: e.target.value});
  handleinterestratetypeChange= (e) => this.setState({ interestratetype: e});
  handleloanperiodChange= (e) => this.setState({ loanperiod: e.target.value});
  handledateofissuanceChange= (date, dateString) =>{
    //console.log(date.format(dateFormat))
    this.setState({ dateofissuance: date.format(dateFormat)});
    this.setState({dateofissuancestring:dateString})
  } 

  handleroundoffvalueChange= (e) => this.setState({ roundoffvalue: e.target.value});

  render(){
   
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin size="large">
              <div className="content">
                <h3>Pisacco</h3>
              </div>
               </Spin> 
          </div>
      )

    }else{

      return (
        <div>
            <Row >
                  <Col xs="12" sm="6" lg="4">
                    <Card>
                    <h4 style={{fontStyle:'oblique',fontWeight:'bolder'}}>LOAN CALCULATOR FORM</h4>
                    <Form
                      onFinish={(event) => this.handleFormSubmit(event)} 
                    >

                      <FormItem label="Loan Amount"          
                      >
                        
                        <InputNumber
                        style={{ width: 200 }} 
                        defaultValue={0.0}
                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={value => value.replace(/\x\s?|(,*)/g, '')}
                        onChange={this.handleloanamountChange}
                      />  
                      
                      
                      </FormItem>

                      <FormItem label="Annual Interest Rate"          
                      >
                        <Input name="interestrate"  placeholder="0" value={this.state.interestrate} onChange={this.handleinterestrateChange} />
                      </FormItem>

                      <FormItem label="Interest Rate Type">
                          <Select placeholder="Interest Rate Type"  value={this.state.interestratetype} onChange={this.handleinterestratetypeChange} >
                                <Option value="flat">Flat Interest</Option>
                                <Option value="reducingbalance">Reducing</Option>
                          </Select>
                      </FormItem>

                    {
                      this.state.interestratetype==="reducingbalance"?
                      <FormItem label="PMT formula enabled?">

                      <Switch
                      checked={this.state.pmt_onreducing_balance}
                      onChange={val=>{this.setState({pmt_onreducing_balance:val})}}
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      />
                      </FormItem>
                      :
                      null
                    }


                      <FormItem label="Repayment type">
                          <Select placeholder="Payment type"  value={this.state.payment_type} 
                          onChange={(val)=>{this.setState({payment_type:val})}} >
                                <Option value="monthly">Monthly</Option>
                                <Option value="weekly">Weekly</Option>
                                <Option value="daily">Daily</Option>
                          </Select>
                      </FormItem>


                      <FormItem label="Loan Period(Months/Weeks/Days)"          
                      >
                        <Input name="loanperiod"  placeholder="0" value={this.state.loanperiod} onChange={this.handleloanperiodChange} />
                      </FormItem>  

                      <FormItem label="Date Of Issuance"
                        name="dateofissuance"          
                     >
                          <DatePicker onChange={this.handledateofissuanceChange} format={dateFormat} />
                      </FormItem>

                      <FormItem label="Round Off Value" >
                        <Input name="roundoffvalue"  placeholder="0" value={this.state.roundoffvalue} onChange={this.handleroundoffvalueChange} />
                      </FormItem>  

                  
                      <FormItem>
                        <Button  type="primary" htmlType="submit">Compute</Button>
                      </FormItem>
                    </Form>

                    </Card>
                    </Col>



                    <Col xs="12" sm="6" lg="8">
                    <Card style={{overflowX:'auto'}}>
                    <h4 style={{fontStyle:'oblique',fontWeight:'bolder'}}>Expected Loan  Schedule</h4>
                    <h4>Interest rate: {this.calCulateRate()} {this.state.payment_type}</h4>
                    <h4>Loan amount: {this.state.loanamount}</h4>
                    <h4>Loan period: {this.state.loanperiod}</h4>
                    <h4>Date of issuance: {this.state.dateofissuancestring}</h4>
                    
                    {
                      this.state.pmt_onreducing_balance===true?
                      <h4>Calculated Installment: {this.calculatePMT_installment(this.state.loanamount,this.state.interestrate,this.state.loanperiod,this.state.payment_type)}</h4>
                      :
                      null
                    }
                    <br></br>

                    <ExcelFile  
                      filename={"Pysy Expected schedule"}
                      element={<Button type='primary' 
                      icon={<DownloadOutlined/>} 
                      style={{margin:10}}>Download Excel report</Button>}>
                        <ExcelSheet data={this.state.proposedschedule} name={"LOAN SCHEDULE"}>
                            <ExcelColumn label="#" value="key"/>
                            <ExcelColumn label="Date" value="date"/>
                            <ExcelColumn label="Principle Bf" value="principlebf"/>
                            <ExcelColumn label="Principle Pay" value="principle"/>
                            <ExcelColumn label="Interest Pay" value="interest"/>
                            <ExcelColumn label="Total Pay" value="total"/>
                            <ExcelColumn label="Principle Bal" value="principlebalance"/>

                        </ExcelSheet>
                    </ExcelFile>
                    <br></br>


                    <reactstrp.Table bordered>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Date</th>
                            <th>Principle Bf</th>
                            <th>Principle Pay</th>
                            <th>Interest Pay</th>
                            <th>Total Pay</th>
                            <th>Principle Bal</th>
                          </tr>
                        </thead>
                        <tbody>
                        {this.state.proposedschedule.map(
                          (item)=>(
                            <tr>
                            <td>{item.key}</td>
                            <td>{item.date}</td>
                            <td>{<CurrencyFormat value={item.principlebf} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.principle} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.interest} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.total} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.principlebalance} displayType={'text'} thousandSeparator={true}/>}</td>
                            </tr>
                          ))}
                          <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateScheduleTotal()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateInterestTotal()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalPay()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td></td>
                          </tr>

                      </tbody>
                  </reactstrp.Table>

                
                  <h5 style={{fontWeight:'bolder',color:'red'}}>NOTES</h5>
                  <h6>Flat Interest gives more profits as compared to reducing balance</h6>
                  <h6>Incase we round,we make sure loan principle balances at the last payment</h6>

                
                  </Card>
                  </Col>
            </Row>

        </div>
      );

    }

  }

}


export default LoanCalculator;
