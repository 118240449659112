import React from 'react';
import AccountTypeForm from '../components/AccountTypeForm'

import axios from 'axios'
import { Table, Input,Tabs, Button,Collapse,Form,Popover,Select,Card,Popconfirm,message,Spin } from 'antd';

import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DeleteOutlined,FundViewOutlined,LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import * as serverconfig from '../serverconn'
var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { TabPane } = Tabs;
const { Option } = Select;
const FormItem=Form.Item;

function callback(key) {
  console.log(key);
}

var sacco= ''
var token= ''

class AccountTypeList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    accounttypes: [],
    datarequested:true,


    member_type:'',
    extra_deposit_list:[],
    companyaccounts:[],
    companyprofile:{}


  };

  componentDidMount(){

    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/accounttypes/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          accounttypes:res.data
        })

        this.setState({datarequested:false})

    })

    axios.get(serverconfig.backendserverurl+`/api/companyaccounts/?sacco=${sacco}`)
    .then(res => {
        this.setState({
            companyaccounts:res.data
        })
        this.setState({datarequested:false})
    })


    axios.get(`${serverconfig.backendserverurl}/api/saccosedit/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })
        console.log("List: "+String(res.data.extra_deposit_list))
        this.setState({extra_deposit_list:res.data.extra_deposit_list===undefined || res.data.extra_deposit_list===null?[]:JSON.parse(res.data.extra_deposit_list) })

    })


    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

        this.setState({default_debit:res.data.defaultaccount_for_officecollection_debiting})

        if (this.state.companyprofile.defaultaccount_for_officecollection_debiting!=null || this.state.companyprofile.defaultaccount_for_officecollection_debiting!=0){

          this.setState({finaldebit_account:this.state.companyprofile.defaultaccount_for_officecollection_debiting})

        }


        //set extra deposits
        this.setState({extra_deposit_list:res.data.extra_deposit_list===undefined || res.data.extra_deposit_list===null?[]:JSON.parse(res.data.extra_deposit_list) })


    })

}

    //////////////////////////////////////////////////
  //handle delete here
  handleDelete = id => {
    
     let form_data1 = new FormData();
     form_data1.append('itemid', id);
     form_data1.append('sacco', sacco);
     form_data1.append('table_model', "AccountType");
     axios.post(`${serverconfig.backendserverurl}/customqueries/puttorecyclebin`, form_data1, {
       headers: {
         'content-type': 'multipart/form-data'
       }
       }).then(res =>{
         window.location.reload(false)
         message.info('Operation successful')
      });

  };


getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};

  render() {
    const columns = [
      {
        title: 'Member type Name',
        dataIndex: 'account_type_name',
        key: 'id',
        ...this.getColumnSearchProps('account_type_name'),
      },
      {
        title: 'Minimum Account Balance',
        dataIndex: 'minimum_account_balance',
        key: 'id',
        ...this.getColumnSearchProps('minimum_account_balance'),
      },

      {
        title: 'Action',
        dataIndex: 'id',
        key: 'id',
        render: text =>
        <p>
        <Popover content={<p>Click here to edit,view details</p>} title="Edit,View">
         <Link to={`/accounttypes/${text}`}><FundViewOutlined style={{color:'blue'}}/></Link>
        </Popover>
        &nbsp;&nbsp;&nbsp;
        <Popover content={<p>Click here to view account opening items</p>} title="View Opening Items">
        <Link to={`/accountopeningitems/${text}`}><FundViewOutlined style={{color:'green'}}/></Link>
        </Popover>
        &nbsp;&nbsp;&nbsp;
        <Popover content={<p>Click here to delete client</p>} title="Delete">
        <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(text)}>
          <DeleteOutlined style={{color:'red'}}/>
         </Popconfirm>
        </Popover>
        </p>,
        
      }

    ];




    const extradeposit_columns = [
      {
        title: 'Item',
        dataIndex: 'account_tocredit',
        key: 'id',
      },
      {
        title: 'Member type',
        dataIndex: 'member_type',
        key: 'id',
      },
      {
        title: 'Minimum deposit',
        dataIndex: 'minimum_amount',
        key: 'id',
      },


      {
        title: 'Action',
        dataIndex: 'id',
        key: 'id',
        render: text =>
        <p>
        <Popover content={<p>Click here to delete item</p>} title="Delete">
        <Popconfirm title="Sure to delete?" 
        onConfirm={() =>{
        this.setState({ extra_deposit_list:  [...this.state.extra_deposit_list.filter(todo => todo.key
          !==text)]});

        }}>
          <DeleteOutlined style={{color:'red'}}/>
         </Popconfirm>
        </Popover>
        </p>,
        
      }

    ];

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return(
        <div>

          <Tabs defaultActiveKey="1" onChange={()=>{}}>
          <TabPane tab="Member types" key="001" >
          <Table 
            columns={columns} 
            scroll={{ x: 1000 }}
            pagination={{showQuickJumper:true,showSizeChanger:true }}
            dataSource={this.state.accounttypes} 
            bordered/>
            <br />
            <Collapse defaultActiveKey={['0']} onChange={callback}>
            <Panel header="Collapse Panel to open member type creation Form" key="1">
              <h2>Create new member type here</h2>
              <AccountTypeForm 
              requestType="post"
              assetID={null}
              btnText="Create"
              /> 
            </Panel>
            </Collapse>



            </TabPane>



          {/**other deposit form collection**/}

          <TabPane tab="Other deposit form collections" key="003" >
            <Card>
            <Form name="otherformdeposits">

            <FormItem label={"Member type"}>
                <Select 
                placeholder="Member type" 
                style={{  }} 
                value={this.state.member_type}
                onChange={(val)=>{this.setState({member_type:val})}} 
                showSearch
                optionFilterProp="children"
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onSearch={this.onSearch}                    
                >
                  {this.state.accounttypes.map(
                    (accnt)=>(
                      <Option value={accnt.account_type_name}>{accnt.account_type_name}</Option>
                    ))}
                </Select>
            </FormItem>



            <FormItem label={"Payment Item Account"}>
                <Select 
                placeholder="Account" 
                style={{  }} 
                value={this.state.account_tocredit}
                onChange={(val)=>{this.setState({account_tocredit:val})}} 
                showSearch
                optionFilterProp="children"
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onSearch={this.onSearch}                    
                >
                  {this.state.companyaccounts.map(
                    (accnt)=>(
                      <Option value={accnt.account_name}>{accnt.account_name} | {accnt.account_code}</Option>
                    ))}
                </Select>
            </FormItem>


            <FormItem label="Minimum amount">
            <Input name="totalpayment"  type='number' placeholder="Total pay." value={this.state.totalpayment} onChange={(e) => this.setState({ totalpayment: e.target.value})} />                          
            </FormItem>


            <FormItem>
              <Button  type="primary" htmlType='button' onClick={()=>{

                if (this.state.member_type===''){
                  message.info("Please select member type")
                } else if (this.state.totalpayment<=0){
                  message.info("Minimum cant be less or equal to zero")
                }else if (this.state.account_tocredit===''){
                  message.info("Please select payment account")
                }else{

                    var newitem={
                      'id':this.state.account_tocredit+this.state.member_type,
                      'key':this.state.account_tocredit+this.state.member_type,
                      'account_tocredit':this.state.account_tocredit,
                      'member_type':this.state.member_type,
                      'minimum_amount':this.state.totalpayment,
                      'amount_paid':0
                    }

                    //add to shedule object
                      this.setState({
                        extra_deposit_list: [...this.state.extra_deposit_list, newitem]
                      });

                      this.setState({account_tocredit:''})
                      this.setState({member_type:''})
                      this.setState({totalpayment:0})

                 
                  }

              }}>Add item</Button>
            </FormItem>

            </Form>
              <br></br>
            <Table 
            columns={extradeposit_columns} 
            scroll={{ x: 1000 }}
            pagination={{showQuickJumper:true,showSizeChanger:true }}
            dataSource={this.state.extra_deposit_list} 
            bordered/>
            <br></br>

          <Button  type="primary" htmlType='button'
          
          onClick={()=>{

          this.setState({datarequested:true})

          //get last slip number
          let form_data = new FormData();

          form_data.append('sacco', sacco);
          form_data.append('extra_deposit_list', JSON.stringify(this.state.extra_deposit_list));

          axios.post(serverconfig.backendserverurl+'/customqueries/createextra_deposits', form_data, {
            headers: {
              'content-type': 'multipart/form-data'
            }
            })
          .then(res => {
              window.location.reload(false)
              this.setState({datarequested:false})
          })
          .catch(error => console.log(error))

          }}
          
          >Update deposit extra list</Button>              

          </Card>
          </TabPane>

            </Tabs>

        </div>
    )

    }

    
  }
}

export default AccountTypeList; 
