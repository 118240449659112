import React,{ useRef } from 'react';
import axios from 'axios'
import { Tooltip,Popover,Result,Table,Tabs,Popconfirm,Input, Button,Collapse,Card,Avatar,Modal,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Spin,Switch } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { PrinterOutlined, MinusOutlined, CloseCircleFilled, CheckCircleOutlined, UserOutlined } from '@ant-design/icons';
import { DeleteOutlined,SearchOutlined,CameraOutlined,PlusOutlined,EditOutlined,PlusSquareFilled,SaveOutlined,LoadingOutlined } from '@ant-design/icons';
import QrReader from 'react-qr-reader'
import uuid from 'react-uuid'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
import PrintTemplate from 'react-print';
import ReactToPrint from 'react-to-print';
import {
  Col,
  Row,
} from 'reactstrap';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import * as reactstrp from 'reactstrap';
import { ToWords } from 'to-words';

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { Title } = Typography;
const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { TabPane } = Tabs;

const toWords = new ToWords();

message.config({
  top: 200,
  duration: 2,
  maxCount: 3,
  rtl: true,
});

var token= ''
var sacco= ''
var username=''
var bizuserid= ''

class MemberPaymentsEntryView extends React.Component {

  state = {
    date:moment().format(dateFormat).toString(),
    selectedmember_account:'',
    selectedmember_accountobject:{},
    members:[],
    datarequested:true,
    totalamount:0,
    fixedrate:0,
    previoustransactions:[],
    datasubmittedsuccessfully:false,
    fixedamount:0,
    companyprofile:{},
    accountchange:false,
    userprofile:{},
    fdperiod:0,
    fixedratetype:'simple',

    companyaccounts: [],
    income_account:'',
    transaction_description:'',
    transaction_amount:0,
    transaction_type:'',
    journal_list:[],
    itemnumber:0,
    debit_account:'',
    debit_savings:true,
    send_message:false,

    payment_mode:'single_member',
    reference:'',

    action:'make_payment',
    payment_list:[],
    unpaid_list:[]


  }

  callback(key) {
    console.log(key);
  }

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    
    }else{
       sacco= ''
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
   }else{
      bizuserid= ''
   }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    let form_data1 = new FormData();
    form_data1.append('userid', bizuserid);
    
    //get the user profile here
    axios.post(serverconfig.backendserverurl+'/customqueries/get_user_profile',form_data1,{
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res => {
        this.setState({
          userprofile:res.data
        })
    })


    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })
    })


    axios.get(serverconfig.backendserverurl+`/api/members/?sacco=${sacco}`)
    .then(res => {
      //console.log(res.data)
        this.setState({
          members:res.data
        })

    })

    axios.get(serverconfig.backendserverurl+`/api/companyaccounts/?sacco=${sacco}`)
    .then(res => {
        this.setState({
            companyaccounts:res.data
        })
        this.setState({datarequested:false})
    })

  }
  

  //submit the deposit now
  check_annualpayments=()=>{
    if (this.state.income_account===""){
      message.error("Income account cant be missing")
    }else{
      this.setState({datarequested:true})


      let form_data = new FormData();
      form_data.append('date',this.state.date);
      form_data.append('sacco', sacco);
      form_data.append('income_account',this.state.income_account);

      axios.post(serverconfig.backendserverurl+'/customqueries/check_memberpayments_status', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
        })
      .then(res => {
        this.setState({datarequested:false})
        message.info(res.data.message)

        this.setState({payment_list:JSON.parse(res.data.paid_report)})
        this.setState({unpaid_list:JSON.parse(res.data.unpaid_report)})
        
      })
      .catch(error => console.log(error))

    }

  }

  //make 
  make_paymants=()=>{
    if (this.state.income_account===""){
      message.error("Income account cant be missing")
    }else if(this.state.transaction_amount<=0){
      message.error("Transaction amount cant be less or equal to zero")

    }else if(this.state.transaction_description===""){
      message.error("Transaction details cant be missing")

    }else if(this.state.selectedmember_account==="" && this.state.payment_mode==="single_member"){
      message.error("Member account cant be missing")

    }else if (this.state.debit_account===""){
      message.error("Debit account cant be missing")

    }else if (this.state.reference===""){
      message.error("Refernce cant be missing")

    }
    
    else if (this.state.debit_savings===true && Number(this.state.selectedmember_accountobject.savings_balance)<this.state.transaction_amount)
    
    message.error("Amount not available on savings account ")

    else{


      this.setState({datarequested:true})

      let form_data = new FormData();
      form_data.append('date',this.state.date);
      form_data.append('sacco', sacco);
      form_data.append('username', username);
      form_data.append('selectedmember_account',this.state.selectedmember_account);

      form_data.append('transaction_amount',this.state.transaction_amount);
      form_data.append('transaction_description',this.state.transaction_description);
      form_data.append('income_account',this.state.income_account);
      form_data.append('debit_account',this.state.debit_account);
      form_data.append('debit_savings',this.state.debit_savings);
      form_data.append('send_message',this.state.send_message);
      form_data.append('payment_mode',this.state.payment_mode);
      form_data.append('reference',this.state.reference);

      axios.post(serverconfig.backendserverurl+'/customqueries/creatememberpayments', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
        })
      .then(res => {
        this.setState({datarequested:false})
        message.info(res.data.message)
        this.setState({datasubmittedsuccessfully:true})
        
      })
      .catch(error => console.log(error))

    }

  }


  calculateTotalDebit=()=>{
    var total=0

    this.state.payment_list.map((item)=>{
      total+=Number(item.Debit)

    })
    return total.toFixed(2);

  }


  calculateTotalCredit=()=>{
    var total=0

    this.state.payment_list.map((item)=>{
      total+=Number(item.Credit)

    })
    return total.toFixed(2);

  }

 

  ////////////////////////////////////////////
  // RENDERING METHOD HERE
  render() {

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      if(this.state.datasubmittedsuccessfully===true){
        return(
          <div>

            <Row >
                <Col xs="12" sm="6" lg="6">
                  <Card>
                  <Result
                  status="success"
                  title="Successful payment entry"
                  subTitle="Finish off transaction"
                  extra={[
                    <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
                  ]}
                  />

                  </Card>

                  </Col>

            </Row>
          </div>

        )

      }else{
        return(
          <div>

            <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-start',flexWrap:'wrap'}}>

            <div style={{width:'40%'}}>
            <Card>
         
         <h4>Member pay receiver form</h4>


         <Form 
           name="payment_form"
           onFinish={(event) => {
             if (this.state.action==="make_payment"){
               this.make_paymants()

             }else if (this.state.action==="check_memberpaymentstatus") {
               this.check_annualpayments()
             }
             
           }}
           >

        { /* <FormItem label={"Action"}>
              <Select 
              placeholder="Action" 
              style={{  }} 
              value={this.state.action}
              onChange={(val)=>{this.setState({action:val})}} 
              showSearch
              optionFilterProp="children"
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              onSearch={this.onSearch}                    
              >
                  <Option value={'make_payment'}>{"Make payment"} </Option>
                  <Option value={"check_memberpaymentstatus"}>{"Check member annual payment status"} </Option>
              </Select>
          </FormItem> */}

           {
            this.state.action==="make_payment"?
            <div>

            <FormItem label={"Payment mode"}>
                    <Select 
                    placeholder="Payment mode" 
                    style={{  }} 
                    value={this.state.payment_mode}
                    onChange={(val)=>{this.setState({payment_mode:val})}} 
                    showSearch
                    optionFilterProp="children"
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onSearch={this.onSearch}                    
                    >
                          <Option value={'single_member'}>{"Single member"} </Option>
                          <Option value={"all_members"}>{"All members"} </Option>

                    </Select>
          </FormItem>


           {
             this.state.companyprofile.automated_transaction_date===false?
             <FormItem label="Date of entry"
             name='date'
             rules={[
               {
                 required: true,
                 message: 'Please Select date',
               },
               ]}
             >
                 <DatePicker
                  onChange={(date, dateString) => this.setState({ date: dateString})}
                  format={dateFormat} />
             </FormItem>                 
             :
             null
           }


         <FormItem label={"Credit account"}>
           <Select 
           placeholder="Credit Account" 
           style={{  }} 
           value={this.state.income_account}
           onChange={(val)=>{this.setState({income_account:val})}} 
           showSearch
           optionFilterProp="children"
           onFocus={this.onFocus}
           onBlur={this.onBlur}
           onSearch={this.onSearch}                    
           >
             {this.state.companyaccounts.map(
               (accnt)=>(
                 <Option value={accnt.account_name}>{accnt.account_name} | {accnt.account_code} | {accnt.account_type}</Option>
               ))}
           </Select>
       </FormItem>

       <FormItem label={"Debit account"}>
           <Select 
           placeholder="Debit Account" 
           style={{  }} 
           value={this.state.debit_account}
           onChange={(val)=>{this.setState({debit_account:val})}} 
           showSearch
           optionFilterProp="children"
           onFocus={this.onFocus}
           onBlur={this.onBlur}
           onSearch={this.onSearch}                    
           >
             {this.state.companyaccounts.map(
               (accnt)=>(
                 <Option value={accnt.account_name}>{accnt.account_name} | {accnt.account_code} | {accnt.account_type}</Option>
               ))}
           </Select>
       </FormItem>


       <FormItem label="Amount">
           <Input name="transaction_amount" type="number" placeholder="Transaction amount" value={this.state.transaction_amount} onChange={(val)=>{this.setState({transaction_amount:val.target.value})}} />
         </FormItem>

         <FormItem label="Transaction details"
           
         >
               <TextArea
                     placeholder="Details."
                     autoSize={{ minRows: 2, maxRows: 6 }}
                     value={this.state.transaction_description}
                     onChange={(val)=>{this.setState({transaction_description:val.target.value})}}
                   />
         </FormItem>

           <FormItem label="Reference">
             <Input name="Reference" 
             placeholder="Reference (membership,annualsubscription etc)"
             value={this.state.reference} 
             onChange={(val)=>{this.setState({reference:val.target.value})}}
             />
           </FormItem>

                   {
                     this.state.payment_mode==="single_member"?
                     <FormItem label="Member account">
                     <Select 
                     placeholder="Account No | Name" 
                     style={{  }} 
                     value={this.state.selectedmember_account}
                     onChange={(e)=>{
                       this.setState({ selectedmember_account: e});
                       //make query for last transactions

                      /*
                       axios.get(`${serverconfig.backendserverurl}/api/members/${e}`)
                         .then(res => {  
                             this.setState({selectedmember_accountobject: res.data})
                         }) */


                         let form_data1 = new FormData();
                         form_data1.append('date',this.state.date);
                         form_data1.append('member_id', e);
                         axios.post(serverconfig.backendserverurl+`/customqueries/get_runningbalancesbydate`, form_data1, {
                         headers: {
                           'content-type': 'multipart/form-data'
                         }
                         })
                         .then(res => {
                             this.setState({
                              selectedmember_accountobject:res.data.my_balance
                             })
                         })


                     }} 
                     showSearch
                     optionFilterProp="children"
                     onFocus={this.onFocus}
                     onBlur={this.onBlur}
                     onSearch={this.onSearch}                    
                     >
                       {this.state.members.map(
                         (accnt)=>(
                           <Option value={accnt.id}>{accnt.AccountNumber} | {accnt.FullName}</Option>
                         ))}
                     </Select>
                    </FormItem>
                     :
                     null
                   }



{/*          <FormItem label="Debit member savings?">
             <Switch
                 checked={this.state.debit_savings}
                 onChange={val=>{this.setState({debit_savings:val})}}
                 checkedChildren="True"
                 unCheckedChildren="False"
                 />
         </FormItem>
         */
}

         <FormItem label="Send SMS message?">
           <Switch
               checked={this.state.send_message}
               onChange={val=>{this.setState({send_message:val})}}
               checkedChildren="True"
               unCheckedChildren="False"
               />
       </FormItem>


         <FormItem>
           <Button  type="primary" htmlType="submit">Create payment</Button>
         </FormItem>


            </div>
            :
            null
           }
           

           {
            this.state.action==="check_memberpaymentstatus"?
            <div>
            <FormItem label={"Income account"}>
           <Select 
           placeholder="Income Account" 
           style={{  }} 
           value={this.state.income_account}
           onChange={(val)=>{this.setState({income_account:val})}} 
           showSearch
           optionFilterProp="children"
           onFocus={this.onFocus}
           onBlur={this.onBlur}
           onSearch={this.onSearch}                    
           >
             {this.state.companyaccounts.map(
               (accnt)=>(
                 <Option value={accnt.account_name}>{accnt.account_name} | {accnt.account_code} | {accnt.account_type}</Option>
               ))}
           </Select>
       </FormItem>

         <FormItem>
           <Button  type="primary" htmlType="submit">Check payments</Button>
         </FormItem>

            </div>
            :
            null
           }



         </Form>

         </Card>
            </div>


            <div style={{width:'60%',overflowX:'auto'}}>
              {
                this.state.payment_list.length>0?
                <Card style={{overflowX:'auto'}}>
                <h3>Paid list</h3>

                 <reactstrp.Table bordered>
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Date</th>
                    <th>Account</th>
                    <th>Desc</th>
                    <th>Journal No</th>
                    <th>Voucher No</th>

                    <th>User</th>
                    <th>Debit</th>
                    <th>Credit</th>

                    <th>Member name</th>



                  </tr>
                </thead>
                <tbody>
                {this.state.payment_list.map(
                  (item)=>(
                    <tr>
                    <td>{item.item_no}</td>
                    <td>{item.date}</td>
                    <td>{item.account}</td>
                    <td>{item.description}</td>
                    <td>{item.journal_no}</td>
                    <td>{item.voucher_no}</td>

                    <td>{item.user}</td>
                    <td className="align-right">{<CurrencyFormat value={item.Debit} displayType={'text'} thousandSeparator={true}/>}</td>
                    <td className="align-right">{<CurrencyFormat value={item.Credit} displayType={'text'} thousandSeparator={true}/>}</td>
                    
                    <td>{item.member_name}</td>

                    </tr>
                  ))}
                  <tr>
                  <td style={{fontWeight:'bolder'}}>Total:  {this.state.payment_list.length}</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>

                  <td></td>
                  <td className="align-right"style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalDebit()} displayType={'text'} thousandSeparator={true}/>}</td>
                  <td className="align-right"style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalCredit()} displayType={'text'} thousandSeparator={true}/>}</td>

                  </tr>

              </tbody>
              </reactstrp.Table> 

                </Card>
                :
                null
              }

              <br></br>

            {
                this.state.unpaid_list.length>0?
                <Card style={{overflowX:'auto'}}>
                  <h3>Unpaid list</h3>
                  <reactstrp.Table bordered>
                    <thead>
                      <tr>
                      <th>Member name</th>
                      <th>Savings</th>
                    </tr>
                  </thead>
                  <tbody>
                  {this.state.unpaid_list.map(
                    (item)=>(
                      <tr>
                      <td>{item.member_name}</td>
                      <td>{item.savings_balance}</td>

                      </tr>
                    ))}
                    < tr>
                    <td style={{fontWeight:'bolder'}}>Total: {this.state.unpaid_list.length}</td>
                    <td></td>
                    </tr>

                </tbody>
                  </reactstrp.Table>


        <h3>Repayment form</h3>
        {/**Repayment form */}

         <Form 
           name="payment_form"
           onFinish={
            ()=>{
              if (this.state.income_account===""){
                message.error("Income account cant be missing")
              }else if(this.state.transaction_amount<=0){
                message.error("Transaction amount cant be less or equal to zero")
          
              }else if(this.state.transaction_description===""){
                message.error("Transaction details cant be missing")
          
              }else if (this.state.debit_account===""){
                message.error("Debit account cant be missing")
          
              }else if (this.state.reference===""){
                message.error("Refernce cant be missing")
          
              }else{
                this.setState({datarequested:true})
          
                let form_data = new FormData();
                form_data.append('date',this.state.date);
                form_data.append('sacco', sacco);
                form_data.append('username', username);
                form_data.append('transaction_amount',this.state.transaction_amount);
                form_data.append('transaction_description',this.state.transaction_description);
                form_data.append('income_account',this.state.income_account);
                form_data.append('debit_account',this.state.debit_account);
                form_data.append('debit_savings',this.state.debit_savings);
                form_data.append('send_message',this.state.send_message);
                form_data.append('reference',this.state.reference);
                form_data.append('unpaid_list',JSON.stringify(this.state.unpaid_list));

                axios.post(serverconfig.backendserverurl+'/customqueries/create_memberrepayments', form_data, {
                  headers: {
                    'content-type': 'multipart/form-data'
                  }
                  })
                .then(res => {
                  this.setState({datarequested:false})
                  message.info(res.data.message)
                  this.setState({datasubmittedsuccessfully:true})
                                    
                })
                .catch(error => console.log(error))
          
              }
          
            }


           }
           >


           {
             this.state.companyprofile.automated_transaction_date===false?
             <FormItem label="Date of entry"
             name='date'
             rules={[
               {
                 required: true,
                 message: 'Please Select date',
               },
               ]}
             >
                 <DatePicker onChange={(date, dateString) => this.setState({ date: dateString})} format={dateFormat} />
             </FormItem>                 
             :
             null
           }


         <FormItem label={"Income account"}>
           <Select 
           placeholder="Income Account" 
           style={{  }} 
           value={this.state.income_account}
           onChange={(val)=>{this.setState({income_account:val})}} 
           showSearch
           optionFilterProp="children"
           onFocus={this.onFocus}
           onBlur={this.onBlur}
           onSearch={this.onSearch}                    
           >
             {this.state.companyaccounts.map(
               (accnt)=>(
                 <Option value={accnt.account_name}>{accnt.account_name} | {accnt.account_code} | {accnt.account_type}</Option>
               ))}
           </Select>
       </FormItem>

       <FormItem label={"Debit account"}>
           <Select 
           placeholder="Debit Account" 
           style={{  }} 
           value={this.state.debit_account}
           onChange={(val)=>{this.setState({debit_account:val})}} 
           showSearch
           optionFilterProp="children"
           onFocus={this.onFocus}
           onBlur={this.onBlur}
           onSearch={this.onSearch}                    
           >
             {this.state.companyaccounts.map(
               (accnt)=>(
                 <Option value={accnt.account_name}>{accnt.account_name} | {accnt.account_code} | {accnt.account_type}</Option>
               ))}
           </Select>
       </FormItem>


       <FormItem label="Amount">
           <Input name="transaction_amount" type="number" placeholder="Transaction amount" value={this.state.transaction_amount} onChange={(val)=>{this.setState({transaction_amount:val.target.value})}} />
         </FormItem>

         <FormItem label="Transaction details"
           
         >
               <TextArea
                     placeholder="Details."
                     autoSize={{ minRows: 2, maxRows: 6 }}
                     value={this.state.transaction_description}
                     onChange={(val)=>{this.setState({transaction_description:val.target.value})}}
                   />
         </FormItem>

           <FormItem label="Reference">
             <Input name="Reference" 
             placeholder="Reference (membership,annualsubscription etc)"
             value={this.state.reference} 
             onChange={(val)=>{this.setState({reference:val.target.value})}}
             />
           </FormItem>


         <FormItem label="Send SMS message?">
           <Switch
               checked={this.state.send_message}
               onChange={val=>{this.setState({send_message:val})}}
               checkedChildren="True"
               unCheckedChildren="False"
               />
       </FormItem>


         <FormItem>
           <Button  type="primary" htmlType="submit">Create repayment</Button>
         </FormItem>


         </Form>


            </Card>
            :
            null
          }

            </div>

            </div>


        </div>                       
        )
      }
    }
   
  }
}

export default MemberPaymentsEntryView; 
