import React from 'react';
import { Form, Input, Button,Select,Spin,Image } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';
import PhoneInput from 'react-phone-number-input'

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var sacco= ''
var token= ''

class SaccoValuerForm extends React.Component {

  state = {
    full_name:'' ,  
    phone_no:'',
    Photo:null,
    datarequested:false,
    photofile:null,
  }


  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    if (this.props.action==="Update"){
      const valuerid=this.props.valuerid;
      axios.get(`${serverconfig.backendserverurl}/api/saccovaluers/${valuerid}`)
      .then(res => {  
          this.setState({full_name: res.data.full_name})
          this.setState({phone_no: res.data.phone_no})
      })
    }

  }


  //submit button pressed
  handleFormSubmit=(event) =>{
    if (this.props.action==="Create"){
      this.setState({datarequested:true})

      let form_data = new FormData();
      form_data.append('full_name', this.state.full_name);
      form_data.append('phone_no', this.state.phone_no);
      this.state.Photo==null?
      console.log("No profile file")
      :
      form_data.append('Photo', this.state.Photo, this.state.Photo?this.state.Photo.name:"");
      form_data.append('sacco', sacco);
      
      axios.post(serverconfig.backendserverurl+'/api/saccovaluers/', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
      .then(res =>    this.setState({datarequested:false})
  ,    window.location.reload(false)
      )
      .catch(error => console.log(error))

    }else{
      var valuerid=this.props.valuerid
      this.setState({datarequested:true})

      let form_data = new FormData();
      form_data.append('full_name', this.state.full_name);
      form_data.append('phone_no', this.state.phone_no);
      this.state.Photo==null?
      console.log("No profile file")
      :
      form_data.append('Photo', this.state.Photo, this.state.Photo?this.state.Photo.name:"");
      form_data.append('sacco', sacco);
      
      axios.put(serverconfig.backendserverurl+`/api/saccovaluers/${valuerid}/`, form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
      .then(res =>    this.setState({datarequested:false})
  ,    window.location.reload(false)
      )
      .catch(error => console.log(error))

    }

  }


  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return (
        <div>
          <Form
               onFinish={(event) => this.handleFormSubmit(event)} 
          >
            <FormItem label="* Full name"
            >
              <Input name="full_name"  placeholder="Put a name here." value={this.state.full_name} onChange={(val)=>{this.setState({full_name:val.target.value})}} />
            </FormItem>
            
            <FormItem label= { <h6 style={{display:'flex'}} >* Phone</h6>}>
              <PhoneInput
                countrySelectProps={{ unicodeFlags: true }}
                defaultCountry="UG"
                placeholder="Enter phone number"
                value={this.state.phone_no} onChange={(val)=>{this.setState({phone_no:val})}}/>
            </FormItem> 
  
            <div style={{display:'flex',flexDirection:'row'}}>
            <FormItem label="Photo" style={{marginRight:3}}>
                  <Input type="file" accept="image/png, image/jpeg" placeholder="Photo"  
                  onChange={(e)=>{   
                    this.setState({ Photo: e.target.files[0]});
                      if(e.target.files[0]){
                        this.setState({
                          photofile: URL.createObjectURL(e.target.files[0])
                        })
                      }}}/>
            </FormItem> 

            {this.state.Photo?
            <Image
            width={50}
            height={50}
            src={this.state.photofile}    
            />
            :
            null
            }

            </div>
          
            <FormItem>
              <Button  type="primary" htmlType="submit">Create</Button>
            </FormItem>
          </Form>
        </div>
      );

    }
    
  }

}


export default SaccoValuerForm;



