import React from 'react';
import LoanApplicationForm from '../components/LoanApplicationForm'
import GuarantorConsentForm from '../components/GuarantorConsentForm'
import MdiGuarantorForm from '../components/MdiGuarantorForm'

import axios from 'axios'
import { Table, Input,Dropdown,Tabs,Menu,Button,Collapse,Popover,Popconfirm,message,Descriptions,Modal,Spin,Tooltip } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DeleteOutlined,FundViewOutlined,WalletFilled,LoadingOutlined,PlusCircleFilled,DownloadOutlined } from '@ant-design/icons';
import { Link, NavLink } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import LoanDetailsTab from '../components/LoanDetailsTab';
import LoanAppovalForm from '../components/LoanApprovalForm';

import '../../App.css'; // Create this file for custom styles

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { TabPane } = Tabs;

function callback(key) {
  console.log(key);
}

var sacco= ''
var bizuserid= ''
var token= ''


const rowClassName = (record) => {
  return record.review_fail_reason != null ? 'highlighted-row' : '';
};

class LoanApplicationListView_ForApproval extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    loanapplications: [],
    viewpaymentmodal:false,
    expensedetails:{},
    userprofile:{},
    datarequested:true,
    guarantorconsentformvisible:false,
    selectedloanid:'',
    nextaction:'',
    mdiguarantorformvisible:false,
    userrights:{},

  };

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
       bizuserid= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }
    

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/loanapplications/?sacco=${sacco}&loan_status=${'approval'}`)
    .then(res => {
        this.setState({
          loanapplications:res.data
        })
    })

    axios.get(serverconfig.backendserverurl+`/api/accountrights/?user=${bizuserid}`)
    .then(res => {
        this.setState({
          userrights:res.data[0]
        })
    })

    let form_data = new FormData();
    form_data.append('userid', bizuserid);
    
    //get the user profile here
    axios.post(serverconfig.backendserverurl+'/customqueries/get_user_profile',form_data,{
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res => {
        this.setState({
          userprofile:res.data
        })
  
        this.setState({datarequested:false})
     
    })

}



getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};

//check message
check_if_message=(record)=>{
  var message=''
  if (record.assessment_application_reversalreason!=null &&  record.assessment_application_reversalreason!=''){
    message+=record.assessment_application_reversalreason
  }
  
  if (record.review_fail_reason!=null &&  record.review_fail_reason!=''){
    if (message==""){
      message+=record.review_fail_reason
    }else{
      message+=", "+record.review_fail_reason
    }
  }

  return message

}



  render() {

      var columns = [

        {
          title: 'Date',
          dataIndex: 'date',
          key: 'id',
          ...this.getColumnSearchProps('date'),
          render:(text,record)=>
            <div>
              <h6 style={{color:this.check_if_message(record)!=""?'orange':'black'}}>
              {this.check_if_message(record)!=""?<Tooltip title={this.check_if_message(record)}> {text}</Tooltip>:text}
            </h6>

           </div>
        },

        {
          title: 'Account Number',
          dataIndex: 'accountNumber',
          key: 'id',
          ...this.getColumnSearchProps('accountNumber'),
        },
        {
          title: 'Account Name',
          dataIndex: 'accountName',
          key: 'id',
          ...this.getColumnSearchProps('accountName'),
        },
        {
          title: 'Loan top app',
          dataIndex: 'loan_topup',
          key: 'id',
          ...this.getColumnSearchProps('accountNumber'),
          render:(text,record)=><h3>{record.loan_topup?"Yes":"No"}</h3>
        },
        {
          title: 'Topup amount',
          dataIndex: 'loan_topupamount',
          key: 'id',
          render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
        },
        {
          title: 'Topup loan balance',
          dataIndex: 'topup_loanbalance',
          key: 'id',
          render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
        },
        {
          title: 'Total Request Amount',
          dataIndex: 'loan_amount',
          key: 'id',
          render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
        },

      ];
      
    
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return(

            <Collapse defaultActiveKey={['1']} onChange={()=>{}}>
            <Panel header={<h4 style={{fontWeight:'bolder'}}>PENDING LOAN APPROVALS</h4>} key="1">
            <Table   
            columns={columns} 
            scroll={{ x: 1000 }}
            dataSource={this.state.loanapplications} 
            pagination={{showQuickJumper:true,showSizeChanger:true }}
            bordered
            
            expandable={{
              expandedRowRender: (record) =>{
                return (
                <div
                  style={{
                    margin: 0,
                  }}
                  >
                      <Tabs defaultActiveKey="1" onChange={()=>{}}>
                      <TabPane tab="Application Details" key="1">
                        <LoanDetailsTab 
                        loanapplicationID={record.id}
                        />
                     </TabPane>
                  
                      {
                        this.state.userrights.can_approve_loans?
                        <TabPane tab="Loan Approval Form" key="3">
                          <LoanAppovalForm loanapplicationID={record.id} />
                        </TabPane>
                        :null
                      }

                    </Tabs>

                </div>
                );
               
              } ,
              rowExpandable: (record) => true,
              onExpand:(condition,record)=>{
              },
            }}  
            size='small'
            rowClassName={rowClassName}
                      
            />
            <br />
            </Panel>
            </Collapse>
          
      )

    }

  }
}

export default LoanApplicationListView_ForApproval; 
