import React from 'react';
import SaccoValuerForm from '../components/SaccoValuerForm'
import axios from 'axios'
import { Table, Input, Button,Popconfirm,Popover,
  Collapse,Avatar,Drawer,Descriptions,message,Modal,Spin,Switch} from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,FundOutlined,FundViewOutlined,DeleteOutlined,LockFilled,LockOutlined,LoadingOutlined } from '@ant-design/icons';
import { UserOutlined } from '@ant-design/icons';
import { Form,Select,Checkbox,Upload } from 'antd';
import { Link } from 'react-router-dom';
import * as serverconfig from '../serverconn'
var CryptoJS = require("crypto-js");
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

const { Panel } = Collapse;
const FormItem=Form.Item;

function callback(key) {
  console.log(key);
}

message.config({
  top: 200,
  duration: 2,
  maxCount: 3,
  rtl: true,
});


var sacco= ''
var username=''
var token= ''

class SaccovaluersList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    saccovaluers: [],
    updatemodalvisible:false,
    rights: {},
    selectedid:'',
    datarequested:true,
    selectedvaluer:{}
  };


  //////////////////////////////////////////////////
  //handle delete here
  handleDelete = id => {

     let form_data1 = new FormData();
     form_data1.append('itemid', id);
     form_data1.append('sacco', sacco);
     form_data1.append('table_model', "SaccoValuer");
   
     axios.post(`${serverconfig.backendserverurl}/customqueries/puttorecyclebin`, form_data1, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }).then(res =>{
        window.location.reload(false)
        message.info('Operation successful')
      }
      );

  };


  componentDidMount(){

    if(localStorage.getItem("sacco")){
      sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    
    }else{
       sacco= ''
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }


    axios.get(serverconfig.backendserverurl+`/api/saccovaluers/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          saccovaluers:res.data
        })
        this.setState({datarequested:false})
    })

}


  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  render() {
    const columns = [
      {
        title: 'Photo',
        dataIndex: 'Photo',
        key: 'id',
        render: text => <Avatar
        size={60}
        icon={<UserOutlined />}
        alt="User Pic"
        src={text}
        />,
      },
      {
        title: 'Full names',
        dataIndex: 'full_name',
        key: 'id',
        ...this.getColumnSearchProps('full_name'),
      },
      {
        title: 'Phone',
        dataIndex: 'phone_no',
        key: 'id',
      },
      {
        title: 'Actions',
        dataIndex: 'id',
        key: 'id',
        render: (text,record) =>
        <p>
        <Popover content={<p>Click here to edit,view details</p>} title="Edit,View">
         <FundViewOutlined style={{color:'blue'}} onClick={()=>{
          this.setState({updatemodalvisible:true})
          this.setState({selectedvaluer:record})

         }} />
        </Popover>
        &nbsp;&nbsp;&nbsp;
        <Popover content={<p>Click here to delete client</p>} title="Delete">
        <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(text)}>
          <DeleteOutlined style={{color:'red'}}/>
         </Popconfirm>
        </Popover>
        </p>,
      },
    ];

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )
    }else{

      return(
        <div>
           <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header="ALL SACCO VALUERS" key="1">
            <Table 
             scroll={{ x: 1000 }}
             columns={columns}
             pagination={{showQuickJumper:true,showSizeChanger:true }}
             dataSource={this.state.saccovaluers} bordered/>
             <br></br>
             <h3>Register New Valuer</h3>
             <SaccoValuerForm action={'Create'} valuerid={null} />
            </Panel>
            </Collapse>

            <Modal              
              visible={this.state.updatemodalvisible}
              title={"Valuer Update Form"}
              onCancel={()=>{this.setState({updatemodalvisible:false})}}
              style={{width:1000}}
              footer={[
                <Button key="back" onClick={()=>{this.setState({updatemodalvisible:false})}}>
                  Cancel
                </Button>
                ]}
                >
                <SaccoValuerForm action={'Update'} valuerid={this.state.selectedvaluer.id} />

            </Modal> 

        </div>
    )
    }
   
  }
}

export default SaccovaluersList; 
