import React from 'react';
import {Form,Input,Button,Checkbox,message,Spin,Switch,Select,DatePicker} from 'antd';
import axios from 'axios';
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined } from '@ant-design/icons';
import * as serverconfig from '../serverconn'
import PhoneInput from 'react-phone-number-input'

var CryptoJS = require("crypto-js");

message.config({
  top: 200,
  duration: 2,
  maxCount: 3,
  rtl: true,
});

var token= ''
var sacco= ''
const dateFormat = 'DD/MM/YYYY';

const FormItem=Form.Item;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { Option } = Select;

class  UserCreationForm extends React.Component {

  state = {
    isactive:false,
    ismanager:false,
    profile_pic:null,  
    datarequested:false,
    saccopackageobj:{},

    username:'',
    email:'',
    password1:'',
    password2:'',


    sur_name:'',
    other_name:'',
    nationality:'',
    date_Of_Birth:'',
    Area_Of_Residence:'',
    gender:'',
    RegisteredPhoneNumber1:''


  }


  formRef = React.createRef();

  componentDidMount() {

    if(localStorage.getItem("sacco")){
      sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
   }else{
      sacco= ''
   }
   
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }


  }


   validatePassword = () => {
    // Check if the password meets the requirements
    const hasUpperCase = /[A-Z]/.test(this.state.password1);
    const hasLowerCase = /[a-z]/.test(this.state.password1);
    const hasNonAlphabetic = /[^A-Za-z]/.test(this.state.password1);
    const isLengthValid = this.state.password1.length >= 8;

    // Display error message if any requirement is not met
    if (!(hasUpperCase && hasLowerCase && hasNonAlphabetic && isLengthValid)) {
    
      return false
    }else{
      return true
    }

   
  };




  handleprofile_picChange= (e) => this.setState({ profile_pic: e.target.files[0]});


    // on form submit heree
    onFinish = values => {

      if (this.validatePassword()===false){
        message.error("Password must be at least 8 characters long and include upper and lower case letters and non-alphabetic characters")

      }else{

        this.setState({datarequested:true})

        let form_data = new FormData();
        form_data.append('username', this.state.username);
        form_data.append('email', this.state.email,);
        form_data.append('password1', this.state.password1);
        form_data.append('password2', this.state.password2);
        form_data.append('is_active', this.state.isactive);
        form_data.append('branch_manager', this.state.ismanager);
        form_data.append('sacco', sacco);
  
        form_data.append('nationality', this.state.nationality);
        form_data.append('sur_name', this.state.sur_name);
        form_data.append('other_name', this.state.other_name);
        form_data.append('date_Of_Birth', this.state.date_Of_Birth);
        form_data.append('RegisteredPhoneNumber1', this.state.RegisteredPhoneNumber1);
        form_data.append('gender', this.state.gender);
        form_data.append('Area_Of_Residence', this.state.Area_Of_Residence);
  
        this.state.profile_pic==null?
        console.log("No profile file")
        :
        form_data.append('profile_pic', this.state.profile_pic, this.state.profile_pic?this.state.profile_pic.name:"");
  
        //Register the account first
        axios.post(serverconfig.backendserverurl+'/customqueries/createsystem_user',form_data)
        .then(res =>{
          message.success(res.data.message)
  
          this.setState({datarequested:false})
          window.location.reload(false)
  
        })
      .catch(error => console.log(error))
  
      }

    };


  render(){

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin  size="large">
                <div className="content" />
              </Spin>  
                      </div>
      )

    }else{


        return (

          <div>
            <h3>Create new user account here</h3>
          <Form
            name="register"
            onFinish={this.onFinish}
          >
  
          <Form.Item
              label="User Name"
            >
              <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username"  value={this.state.username}  onChange={(val)=>{this.setState({username:val.target.value})}} />
            </Form.Item>
  
            <Form.Item
              label="E-mail"
              rules={[
                {
                  type: 'email',
                  message: 'The input is not valid E-mail!',
                },
                {
                  required: true,
                  message: 'Please input your E-mail!',
                },
              ]}
            >
              <Input type="mail" prefix={<MailOutlined className="site-form-item-icon" />} placeholder="Email" value={this.state.email} onChange={(val)=>{this.setState({email:val.target.value})}}  />
  
            </Form.Item>
      
            <Form.Item
              name="password"
              label="Password"
              rules={[
                {
                  required: true, message: 'Please input your password!',
                },

              ]}
              hasFeedback



            >
              <Input.Password 
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
              value={this.state.password1}
              onChange={(val)=>{this.setState({password1:val.target.value})}}
              />
  
  
            </Form.Item>
  
      
            <Form.Item
              name="confirm"
              label="Confirm Password"
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Please confirm your password!',
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
      
                    return Promise.reject('The two passwords that you entered do not match!');
                  },
                }),
              ]}
            >
              <Input.Password 
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password" 
              value={this.state.password2}
              onChange={(val)=>{this.setState({password2:val.target.value})}}
              />
  
            </Form.Item>
  
            <Form.Item
              label="Is Active"

            >
                <Switch
                    checked={this.state.isactive}
                    onChange={val=>{this.setState({isactive:val})}}
                    checkedChildren="True"
                    unCheckedChildren="False"
                    />
              </Form.Item>
  
              <Form.Item
              label="Is Manager"

            >
                <Switch
                    checked={this.state.ismanager}
                    onChange={val=>{this.setState({ismanager:val})}}
                    checkedChildren="True"
                    unCheckedChildren="False"
                    />            
             </Form.Item>
  
            <Form.Item label="Profile Image"
              name="profile_pic">
                <Input  type="file" accept="image/png, image/jpeg" placeholder="Profile Image" onChange={this.handleprofile_picChange}/>
            </Form.Item> 


            <h3>Other details</h3>

            <FormItem label="Sur Name"
                               
                  >
                    <Input  placeholder="Put a name here." value={this.state.sur_name} onChange={(val)=>{this.setState({sur_name:val.target.value})}} />
                  </FormItem>
        
               
        
              <FormItem label="Second Name"
                          
              >
                <Input  placeholder="Put a name here." value={this.state.other_name} onChange={(val)=>{this.setState({other_name:val.target.value})}} />
              </FormItem>


            <FormItem label="Area of Residence"
                               
                  >
                    <Input placeholder="Area of residence." value={this.state.Area_Of_Residence} onChange={(val)=>{this.setState({Area_Of_Residence:val.target.value})}} />
                  </FormItem>
        
                  <FormItem label="Gender"
                            
                  
                  >
                      <Select placeholder="Gender" style={{ width: 120 }} value={this.state.gender} onChange={(val)=>{this.setState({gender:val})}} >
                            <Option value="Male">Male</Option>
                            <Option value="Female">Female</Option>
                      </Select>
                  </FormItem>
      
        
                  <FormItem label="Nationality"
                              
                  >
                    <Input  placeholder="Nationality." value={this.state.nationality} onChange={(val)=>{this.setState({nationality:val.target.value})}} />
                  </FormItem>

                  <FormItem label="Date of Birth"
                             
                  >
                        <DatePicker onChange={(date, dateString) => this.setState({ date_Of_Birth: dateString})} format={dateFormat} />
                  </FormItem>

                  <FormItem label= {"Phone contact" }                   
                  >
                  <PhoneInput
                        countrySelectProps={{ unicodeFlags: true }}
                        defaultCountry="UG"
                        placeholder="Enter phone number"
                        value={this.state.RegisteredPhoneNumber1} onChange={(val)=>{this.setState({RegisteredPhoneNumber1:val})}}/>
                   </FormItem> 
 
  
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Create Account
              </Button>
            </Form.Item>
            
          </Form>

          </div>
        );

    }

  }

}


export default UserCreationForm;


