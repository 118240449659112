import React,{ useRef } from 'react';
import axios from 'axios'
import { Table, Input, Button,Collapse,Avatar,Modal,Form,DatePicker,Select,Switch,Space,Typography,Divider,InputNumber,message,Spin,Tooltip } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { PrinterOutlined, MinusOutlined, CloseCircleFilled, CheckCircleOutlined, UserOutlined } from '@ant-design/icons';
import { SearchOutlined,CameraOutlined,PlusOutlined,LoadingOutlined,MailFilled,FundViewOutlined } from '@ant-design/icons';
import QrReader from 'react-qr-reader'
import uuid from 'react-uuid'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
import PrintTemplate from 'react-print';
import ReactToPrint from 'react-to-print';
import * as serverconfig from '../serverconn'
import CurrencyFormat from 'react-currency-format';
import * as reactstrp from 'reactstrap';

import {
  Col,
  Row,
} from 'reactstrap';
import * as primarycolor from '../primarycolor'
import '../tablestyle.css'; // Create this CSS file for custom styles
import { Link } from 'react-router-dom';
import ReprintTransactionReceipt from './ReprintTransactionReceipt';


var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { Title } = Typography;
const { RangePicker } = DatePicker;
const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;


var sacco= ''
var bizuserid= ''
var token= ''
var username=''

class ReportToPrint extends React.Component {

  state = {
    companyprofile:{},
  };

  componentDidMount(){

    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)

    }else{
       sacco= ''
       bizuserid= ''
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })
    })

  }

  render() {
    return (


      <div style={{padding:20}}>

            <style>
            {`
              @media print {
              @page { 
              size: landscape; 
              }
               }

              .client-name {
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                text-align: center;
                font-weight: bold;
                background-color: white; /* Optional: add background to avoid content overlap */
              }

              .content {
                padding-top: 50px; /* Space for the fixed header */
              }



            
             `}
          </style>

              
          <div className="content">
            {/* Other content to print */}
            <Row> 
                  <Col xs="2" sm="2" lg="2">
                  <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.sacco_logo} />
                
                    </Col>
                    <Col xs="6" sm="6" lg="6">
                    <h3>{this.state.companyprofile.sacco_name}</h3>
                    <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                    {
                    this.state.companyprofile.RegisteredPhoneNumber2==""?
                    "":","+this.state.companyprofile.RegisteredPhoneNumber2
                    }
                    {
                    this.state.companyprofile.RegisteredPhoneNumber3==""?
                    "":","+this.state.companyprofile.RegisteredPhoneNumber3
                    }
                    </h6>               
                    <h5>Email: {this.state.companyprofile.company_email}</h5>
                    <h5>Wesite: {this.state.companyprofile.website}</h5>
                    <h5>{this.state.companyprofile.box_number}</h5>
                  
                    </Col>
        </Row>

              <h3 style={{display: 'flex',justifyContent:'center', alignItems:'center',fontWeight:'bolder'}}>FINANCIAL STATEMENT REPORT FOR {this.props.selectedmemberobject.AccountNumber} | {this.props.selectedmemberobject.FullName} FROM: {this.props.dateone}  TO: {this.props.datetwo}</h3>

              <reactstrp.Table bordered>
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Date</th>
                    <th>Details</th>

                    {
                    this.props.shareno===true?
                    <th>ShN</th>
                    :null
                    }

                    {
                    this.props.shareamount===true?
                    <th>ShA</th>
                    :null
                    }
                    {
                    this.props.savings===true?
                    <th>OS</th>
                    :null
                    }
                    {
                    this.props.savingsinterest===true?
                    <th>OSI</th>
                    :null
                    }      

                    {
                    this.props.supersave===true?
                    <th>SS</th>
                    :null
                    }  

                    {
                    this.props.supersaveinterest===true?
                    <th>SSI</th>
                    :null
                    }    

                    {
                    this.props.loan===true?
                    <th>Ln</th>
                    :null
                    }  

                    {
                      this.props.loan===true?
                      <th>Ln RB</th>
                      :null
                    } 

                    {
                    this.props.loaninterest===true?
                    <th>LnI</th>
                    :null
                    }  

                    {
                    this.props.loaninterest===true?
                    <th>LnI RB</th>
                    :null
                    } 

                    {
                    this.props.loansecurity===true?
                    <th>LG</th>
                    :null
                    }  

                    {
                    this.props.defdeposit===true?
                    <th>DD</th>
                    :null
                    }  

                    {
                    this.props.teebagoolo===true?
                    <th>TG</th>
                    :null
                    } 

                    {
                    this.props.teebagoolointerest===true?
                    <th>TGI</th>
                    :null
                    } 

                    {/*new enytries*/}
                    {
                    this.props.savingsloanscurity===true?
                    <th>SLS</th>
                    :null
                    }

                    {
                    this.props.supersaveloansecurity===true?
                    <th>SSLS</th>
                    :null
                    }

                    {
                    this.props.teebagoolo_loansecurity===true?
                    <th>TGLS</th>
                    :null
                    }

                    {
                    this.props.sharesloansecurity===true?
                    <th>ShLs</th>
                    :null
                    }

                    <th>Journal No</th>
                  </tr>
                </thead>
                <tbody>
                {this.props.sheetitems.map(
                  (item)=>(
                    <tr>
                    <td>{item.item_no}</td>
                    <td>{item.time_stamp}</td>
                    <td>{item.transaction_details}</td>

                    {
                      this.props.shareno===true?
                      <td className="align-right">
                      {
                        Number(item.sharenodebtotal)>Number(item.sharenocredtotal)?"-"+Number(item.sharenodebtotal):''+Number(item.sharenocredtotal)
                      }
                    </td>
                      :
                      null
                    }


                    {
                      this.props.shareamount===true?
                      <td className="align-right">
                      <CurrencyFormat value= {
                          Number(item.shareamtdebtotal)>Number(item.shareamtcredtotal)?"-"+Number(item.shareamtdebtotal).toFixed(2):''+Number(item.shareamtcredtotal).toFixed(2)
                        } displayType={'text'} thousandSeparator={true}/>
                      </td>
                      :
                      null}


                    {
                      this.props.savings===true?
                      <td className="align-right">
                      <CurrencyFormat value={
                          Number(item.savingdebtotal)>Number(item.savingcredtotal)?"-"+Number(item.savingdebtotal).toFixed(2):''+Number(item.savingcredtotal).toFixed(2)
                        } displayType={'text'} thousandSeparator={true}/>
                      </td>
                      :
                      null
                    }


                    {
                      this.props.savingsinterest===true?
                      <td className="align-right">
                      <CurrencyFormat value=                            {
                          Number(item.savingintdebtotal)>Number(item.savingintcredtotal)?"-"+Number(item.savingintdebtotal).toFixed(2):''+Number(item.savingintcredtotal).toFixed(2)
                          
                        } displayType={'text'} thousandSeparator={true}/>

                      </td>
                      :
                      null
                    }


                    {
                      this.props.supersave===true?
                      <td className="align-right">

                      <CurrencyFormat value= {
                          Number(item.supersavedebtotal)>Number(item.supersavecredtotal)?"-"+Number(item.supersavedebtotal).toFixed(2):''+Number(item.supersavecredtotal).toFixed(2)
                        
                        } displayType={'text'} thousandSeparator={true}/>

                      </td>                          
                      :
                      null
                    }


                    {
                      this.props.supersaveinterest===true?
                      <td className="align-right">

                      <CurrencyFormat value= {
                          
                          Number(item.supersaveintdebtotal)>Number(item.supersaveintcredtotal)?"-"+Number(item.supersaveintdebtotal).toFixed(2):''+Number(item.supersaveintcredtotal).toFixed(2)
                          
                        } displayType={'text'} thousandSeparator={true}/>

                      </td>                          
                      :
                      null

                    }

{
                      this.props.loan===true?
                      <td className="align-right">
                      <CurrencyFormat value= {
                          Number(item.loancredtotal)>Number(item.loandebtotal)?"-"+Number(item.loancredtotal).toFixed(2):''+Number(item.loandebtotal).toFixed(2)
                        } displayType={'text'} thousandSeparator={true}/>

                      </td>                          
                      :
                      null
                    }


                        {/**Loan principle RB */}
                        {
                          this.props.loan===true?
                          <td className="align-right">
                          <CurrencyFormat value= {
                              Number(item.loan_balance)
                            } displayType={'text'} thousandSeparator={true}/>
  
                          </td>                          
                          :
                          null
                        }


                    {
                      this.props.loaninterest===true?
                      <td className="align-right">
                      <CurrencyFormat value={

                          Number(item.loanintcredtotal)>Number(item.loanintdebtotal)?"-"+Number(item.loanintcredtotal).toFixed(2):''+Number(item.loanintdebtotal).toFixed(2)
                        } displayType={'text'} thousandSeparator={true}/>

                      </td>  
                      :
                      null
                    }


                      {/**Loan intst RB */}
                      {
                          this.props.loaninterest===true?
                          <td className="align-right">
                          <CurrencyFormat value= {
                              Number(item.loan_interestbalance)
                            } displayType={'text'} thousandSeparator={true}/>
  
                          </td>                          
                          :
                          null
                        }


                    
                    {
                      this.props.loansecurity===true?
                      <td className="align-right">

                      <CurrencyFormat value={
                          Number(item.loansecdebtotal)>Number(item.loanseccredtotal)?"-"+Number(item.loansecdebtotal).toFixed(2):''+Number(item.loanseccredtotal).toFixed(2)
                          
                        } displayType={'text'} thousandSeparator={true}/>

                      </td>  
                      :
                      null
                    }



                    {
                      this.props.defdeposit===true?
                      <td className="align-right">
                      <CurrencyFormat value=                            {
                          
                          Number(item.defdebtotal)>Number(item.defcredtotal)?"-"+Number(item.defdebtotal).toFixed(2):''+Number(item.defcredtotal).toFixed(2)
                          
                        } displayType={'text'} thousandSeparator={true}/>

                      </td>  
                      :
                      null
                    }


                    {
                      this.props.teebagoolo===true?
                      <td className="align-right">

                      <CurrencyFormat value={
                          Number(item.teebagoolodebtotal)>Number(item.teebagoolocredtotal)?"-"+Number(item.teebagoolodebtotal).toFixed(2):''+Number(item.teebagoolocredtotal).toFixed(2)
                          
                        } displayType={'text'} thousandSeparator={true}/>

                      </td> 
                      :
                      null
                    }

                    {
                      this.props.teebagoolointerest===true?
                      <td className="align-right">
                      <CurrencyFormat value= {
                          Number(item.teebagoolointdebtotal)>Number(item.teebagoolointcredtotal)?"-"+Number(item.teebagoolointdebtotal).toFixed(2):''+Number(item.teebagoolointcredtotal).toFixed(2)
                          
                        } displayType={'text'} thousandSeparator={true}/>

                      </td>   
                      :
                      null
                    }

                    {/*new enytries*/}
                    {
                    this.props.savingsloanscurity===true?
                    <td className="align-right">
                    <CurrencyFormat value= {
                        Number(item.savingloansecuritydebtotal)>Number(item.savingloansecuritycredtotal)?"-"+Number(item.savingloansecuritydebtotal).toFixed(2):''+Number(item.savingloansecuritycredtotal).toFixed(2)
                        
                      } displayType={'text'} thousandSeparator={true}/>

                    </td> 
                        :null
                    }

                    {
                    this.props.supersaveloansecurity===true?
                    <td className="align-right">
                    <CurrencyFormat value= {
                        Number(item.supersaveloansecuritydebtotal)>Number(item.supersaveloansecuritycredtotal)?"-"+Number(item.supersaveloansecuritydebtotal).toFixed(2):''+Number(item.supersaveloansecuritycredtotal).toFixed(2)
                        
                      } displayType={'text'} thousandSeparator={true}/>

                    </td>     
                        :null
                    }

                    {
                    this.props.teebagoolo_loansecurity===true?
                    <td className="align-right">
                    <CurrencyFormat value= {
                        Number(item.teebagoololoansecuritydebtotal)>Number(item.teebagoololoansecuritycredtotal)?"-"+Number(item.teebagoololoansecuritydebtotal).toFixed(2):''+Number(item.teebagoololoansecuritycredtotal).toFixed(2)
                        
                      } displayType={'text'} thousandSeparator={true}/>

                    </td> 
                        :null
                    }

                    {
                    this.props.sharesloansecurity===true?
                    <td className="align-right">
                    <CurrencyFormat value= {
                        Number(item.sharesloansecuritydebtotal)>Number(item.sharesloansecuritycredtotal)?"-"+Number(item.sharesloansecuritydebtotal).toFixed(2):''+Number(item.sharesloansecuritycredtotal).toFixed(2)
                        
                      } displayType={'text'} thousandSeparator={true}/>

                    </td>                       
                      :null
                    }


                    <td>
                      {item.jnlno}
                    </td>                                                                                                

                    </tr>
                  ))}

                  {/**Opening balances */}
            {/*  <tr>
               <td></td>

                <td>
                <h6>Opening Balances: </h6>

                </td>
                <td></td>

                {this.props.shareno===true?
                  <th className="align-right">{this.props.opening_balances.share_numberbalance}</th>
                :
                null
                }

                {
                  this.props.shareamount===true?
                  <th className="align-right">
                  <CurrencyFormat value={this.props.opening_balances.shareamount_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                  </th>
                  :
                  null
                }

                {
                  this.props.savings===true?
                  <th className="align-right">
                  <CurrencyFormat value={this.props.opening_balances.savings_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                  </th>
                  :
                  null
                }

                {
                  this.props.savingsinterest===true?
                  <th className="align-right">
                  <CurrencyFormat value={this.props.opening_balances.saving_interestbalance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                  </th>
                  :
                  null
                }

                {
                  this.props.supersave===true?
                  <th className="align-right">
                  <CurrencyFormat value={this.props.opening_balances.supersaver_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                  </th>
                  :
                  null
                }

                {
                  this.props.supersaveinterest===true?
                  <th className="align-right">
                  <CurrencyFormat value={this.props.opening_balances.supersave_interestbalance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                  </th>
                  :
                  null
                }

                {
                  this.props.loan===true?
                  <th className="align-right">
                  <CurrencyFormat value={this.props.opening_balances.loan_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                  </th>
                  :
                  null
                }

              {this.props.loan===true?<th></th>:null}


                {
                  this.props.loaninterest===true?
                  <th className="align-right">
                  <CurrencyFormat value={this.props.opening_balances.loan_interestbalance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                  </th>
                  :
                  null
                }

            {this.props.loaninterest===true?<th></th>:null}


                {
                  this.props.loansecurity===true?
                  <th className="align-right">
                  <CurrencyFormat value={this.props.opening_balances.loan_security_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                  </th>
                  :
                  null
                }

                {
                  this.props.defdeposit===true?
                  <th className="align-right">
                  <CurrencyFormat value={this.props.opening_balances.def_deposit_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                  </th>
                  :
                  null
                }

                {
                  this.props.teebagoolo===true?
                  <th className="align-right">
                  <CurrencyFormat value={this.props.opening_balances.teebagoolo_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                  </th>
                  :
                  null
                }

                {
                  this.props.teebagoolointerest===true?
                  <th className="align-right">
                  <CurrencyFormat value={this.props.opening_balances.teebagoolo_interestbalance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                  </th>
                  :
                  null
                }

              {
                  this.props.savingsloanscurity===true?
                  <th className="align-right">
                  <CurrencyFormat value={this.props.opening_balances.savingsloan_security_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                  </th>
                  :
                  null
                }

              {
                  this.props.supersaveloansecurity===true?
                  <th className="align-right">
                  <CurrencyFormat value={this.props.opening_balances.supersaveloan_security_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                  </th>
                  :
                  null
                }

                {
                  this.props.teebagoolo_loansecurity===true?
                  <th className="align-right">
                  <CurrencyFormat value={this.props.opening_balances.teebagoololoan_security_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                  </th>
                  :
                  null
                }

              {
                  this.props.sharesloansecurity===true?
                  <th className="align-right">
                  <CurrencyFormat value={this.props.opening_balances.shares_security_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                  </th>
                  :
                  null
                }

                <td> </td>

            

              </tr> */}


                {/**Running balance */}
            {/*  <tr>
                <td></td>
                <td>
                <h6>Transacted Totals:</h6>

                </td>
                <th></th>

                {this.props.shareno===true?
                  <th className="align-right">{this.props.selectedmemberobject.share_numberbalance}</th>
                :
                null
                }

                {
                  this.props.shareamount===true?
                  <th className="align-right">
                  <CurrencyFormat value={this.props.selectedmemberobject.shareamount_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                  </th>
                  :
                  null
                }

                {
                  this.props.savings===true?
                  <th className="align-right">
                  <CurrencyFormat value={this.props.selectedmemberobject.savings_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                  </th>
                  :
                  null
                }

                {
                  this.props.savingsinterest===true?
                  <th className="align-right">
                  <CurrencyFormat value={this.props.selectedmemberobject.saving_interestbalance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                  </th>
                  :
                  null
                }

                {
                  this.props.supersave===true?
                  <th className="align-right">
                  <CurrencyFormat value={this.props.selectedmemberobject.supersaver_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                  </th>
                  :
                  null
                }

                {
                  this.props.supersaveinterest===true?
                  <th className="align-right">
                  <CurrencyFormat value={this.props.selectedmemberobject.supersave_interestbalance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                  </th>
                  :
                  null
                }

                {
                  this.props.loan===true?
                  <th className="align-right">
                  <CurrencyFormat value={this.props.selectedmemberobject.loan_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                  </th>
                  :
                  null
                }

{this.props.loaninterest===true?<th></th>:null}


                {
                  this.props.loaninterest===true?
                  <th className="align-right">
                  <CurrencyFormat value={this.props.selectedmemberobject.loan_interestbalance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                  </th>
                  :
                  null
                }


{this.props.loaninterest===true?<th></th>:null}


                {
                  this.props.loansecurity===true?
                  <th className="align-right">
                  <CurrencyFormat value={this.props.selectedmemberobject.loan_security_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                  </th>
                  :
                  null
                }

                {
                  this.props.defdeposit===true?
                  <th className="align-right">
                  <CurrencyFormat value={this.props.selectedmemberobject.def_deposit_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                  </th>
                  :
                  null
                }

                {
                  this.props.teebagoolo===true?
                  <th className="align-right">
                  <CurrencyFormat value={this.props.selectedmemberobject.teebagoolo_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                  </th>
                  :
                  null
                }

                {
                  this.props.teebagoolointerest===true?
                  <th className="align-right">
                  <CurrencyFormat value={this.props.selectedmemberobject.teebagoolo_interestbalance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                  </th>
                  :
                  null
                }

{
                  this.props.savingsloanscurity===true?
                  <th className="align-right">
                  <CurrencyFormat value={this.props.selectedmemberobject.savingsloan_security_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                  </th>
                  :
                  null
                }

              {
                  this.props.supersaveloansecurity===true?
                  <th className="align-right">
                  <CurrencyFormat value={this.props.selectedmemberobject.supersaveloan_security_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                  </th>
                  :
                  null
                }

                {
                  this.props.teebagoolo_loansecurity===true?
                  <th className="align-right">
                  <CurrencyFormat value={this.props.selectedmemberobject.teebagoololoan_security_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                  </th>
                  :
                  null
                }

              {
                  this.props.sharesloansecurity===true?
                  <th className="align-right">
                  <CurrencyFormat value={this.props.selectedmemberobject.shares_security_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                  </th>
                  :
                  null
                }

                <td> </td>

              </tr> */}


                  {/**Closing balances */}
                  <tr>
                    <td></td>
                <td>
                <h6>Closing Balances: </h6>

                </td>
                <td></td>

                {this.props.shareno===true?
                  <th className="align-right">{this.props.closing_balances.share_numberbalance}</th>
                :
                null
                }

                {
                  this.props.shareamount===true?
                  <th className="align-right">
                  <CurrencyFormat value={this.props.closing_balances.shareamount_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                  </th>
                  :
                  null
                }

                {
                  this.props.savings===true?
                  <th className="align-right">
                  <CurrencyFormat value={this.props.closing_balances.savings_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                  </th>
                  :
                  null
                }

                {
                  this.props.savingsinterest===true?
                  <th className="align-right">
                  <CurrencyFormat value={this.props.closing_balances.saving_interestbalance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                  </th>
                  :
                  null
                }

                {
                  this.props.supersave===true?
                  <th className="align-right">
                  <CurrencyFormat value={this.props.closing_balances.supersaver_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                  </th>
                  :
                  null
                }

                {
                  this.props.supersaveinterest===true?
                  <th className="align-right">
                  <CurrencyFormat value={this.props.closing_balances.supersave_interestbalance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                  </th>
                  :
                  null
                }

                {
                  this.props.loan===true?
                  <th className="align-right">
                  <CurrencyFormat value={Math.abs(Number(this.props.closing_balances.loan_balance).toFixed(2))} displayType={'text'} thousandSeparator={true}/>
                  </th>
                  :
                  null
                }

{this.props.loan===true?<th></th>:null}


                {
                  this.props.loaninterest===true?
                  <th className="align-right">
                  <CurrencyFormat value={Math.abs(Number(this.props.closing_balances.loan_interestbalance).toFixed(2))} displayType={'text'} thousandSeparator={true}/>
                  </th>
                  :
                  null
                }
{this.props.loaninterest===true?<th></th>:null}

                {
                  this.props.loansecurity===true?
                  <th className="align-right">
                  <CurrencyFormat value={this.props.closing_balances.loan_security_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                  </th>
                  :
                  null
                }

                {
                  this.props.defdeposit===true?
                  <th className="align-right">
                  <CurrencyFormat value={this.props.closing_balances.def_deposit_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                  </th>
                  :
                  null
                }

                {
                  this.props.teebagoolo===true?
                  <th className="align-right">
                  <CurrencyFormat value={this.props.closing_balances.teebagoolo_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                  </th>
                  :
                  null
                }

                {
                  this.props.teebagoolointerest===true?
                  <th className="align-right">
                  <CurrencyFormat value={this.props.closing_balances.teebagoolo_interestbalance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                  </th>
                  :
                  null
                }

              {
                  this.props.savingsloanscurity===true?
                  <th className="align-right">
                  <CurrencyFormat value={this.props.closing_balances.savingsloan_security_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                  </th>
                  :
                  null
                }

              {
                  this.props.supersaveloansecurity===true?
                  <th className="align-right">
                  <CurrencyFormat value={this.props.closing_balances.supersaveloan_security_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                  </th>
                  :
                  null
                }

                {
                  this.props.teebagoolo_loansecurity===true?
                  <th className="align-right">
                  <CurrencyFormat value={this.props.closing_balances.teebagoololoan_security_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                  </th>
                  :
                  null
                }

              {
                  this.props.sharesloansecurity===true?
                  <th className="align-right">
                  <CurrencyFormat value={this.props.closing_balances.shares_security_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                  </th>
                  :
                  null
                }

                <td> </td>

              </tr>

              </tbody>
           </reactstrp.Table>  
              <h5> KEYS,  ShN: Share Number, ShA: Share Amount, OS: Savings, OSI: Savings Interest, SS: SuperSave, SSI: SuperSave Interest, Ln: Loan, LnI: Loan Interest, LG: Loan Guarantee, DD: Def Deposit, TG: TeebaGoolo, TGI: TeebaGoolo Interest,
              SLS: Savings loan security, SSLS: Supersave loan security, TGLS: Teebagoolo loan security, ShLS: Shares loan security

                </h5>
          </div>

          <div className="client-name">
           <h6 style={{display: 'flex',justifyContent:'center', alignItems:'center'}}>{this.props.selectedmemberobject.AccountNumber} | {this.props.selectedmemberobject.FullName} FROM: {this.props.dateone}  TO: {this.props.datetwo}</h6>
          </div>

         
      </div>
    );
  }
}


class FinancialStatementReport extends React.Component {

  state = {
    members:[],
    sheetitems:[],
    dateone:moment().format(dateFormat).toString(),
    datetwo:moment().format(dateFormat).toString(),
    selectedmemberobject:{},
    selectedmemberid:'',
    totaldebit:0,
    totalcredit:0,
    datarequested:false,
    companyprofile:{},
    statementtype:'General',

    shareno:false,
    shareamount:false,
    savings:false,
    savingsinterest:false,
    supersave:false,
    supersaveinterest:false,
    loan:false,
    loaninterest:false,
    loansecurity:false,
    defdeposit:false,
    teebagoolo:false,
    teebagoolointerest:false,

    savingsloanscurity:false,
    supersaveloansecurity:false,
    teebagoolo_loansecurity:false,
    sharesloansecurity:false,

    order_of_items:'descending',
    allitemson:false,
    reversalmodal_visible:false,
    selected_journal:{},
    tobereversed_transactions:[],
    loan_txn_report:[],
    general_ledger_entriesreport:[],
    txndetailsmodal_visible:false,
    userrights:{},

    opening_balances:{},
    closing_balances:{},


    show_printer_modal:false,
    selected_id:''


   };

   componentDidMount(){
      if(localStorage.getItem("sacco")){
        sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
        bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
        username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)

    }else{
        sacco= ''
        bizuserid= ''
        username=''
    }
    
    if(localStorage.getItem("token")){
        token= localStorage.getItem("token")
    }else{
        token= ''
    }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })

    axios.get(serverconfig.backendserverurl+`/api/members_withaccounts/?sacco=${sacco}`)
    .then(res => {

      this.setState({
        members:res.data
      })
      
      })


      axios.get(serverconfig.backendserverurl+`/api/accountrights/?user=${bizuserid}`)
      .then(res => {
          this.setState({
            userrights:res.data[0]
          })
      })

  }
 
   handledatechange= (date, dateString) =>{
     this.setState({ dateone: dateString[0]});
     this.setState({ datetwo: dateString[1]});
   } 

   handlememberChange= (e) =>{

    //get data now
    let form_data = new FormData();
    form_data.append('dateone', this.state.dateone);
    form_data.append('datetwo', this.state.datetwo);
    form_data.append('memberid', e);
    form_data.append('sacco', sacco);
    form_data.append('order_of_items', this.state.order_of_items);

     //subaccounts now
     form_data.append('shareno', this.state.shareno);
     form_data.append('shareamount', this.state.shareamount);
     form_data.append('savings', this.state.savings);
     form_data.append('savingsinterest', this.state.savingsinterest);
     form_data.append('supersave', this.state.supersave);
     form_data.append('supersaveinterest', this.state.supersaveinterest);
     form_data.append('loan', this.state.loan);
     form_data.append('loaninterest', this.state.loaninterest);
     form_data.append('loansecurity', this.state.loansecurity);
     form_data.append('defdeposit', this.state.defdeposit);
     form_data.append('teebagoolo', this.state.teebagoolo);
     form_data.append('teebagoolointerest', this.state.teebagoolointerest);
 
     form_data.append('savingsloanscurity', this.state.savingsloanscurity);
     form_data.append('supersaveloansecurity', this.state.supersaveloansecurity);
     form_data.append('teebagoolo_loansecurity', this.state.teebagoolo_loansecurity);
     form_data.append('sharesloansecurity', this.state.sharesloansecurity);
     form_data.append('allitemson', this.state.allitemson);

    if(e===''||this.state.dateone===''||this.state.datetwo===''){
      alert("Account or dates missing")
    }else{
        this.setState({datarequested:true})

        //Now submit sale data to database
        axios.post(serverconfig.backendserverurl+'/customqueries/getmemberfinancialstatement', form_data,{
        headers: {
          'content-type': 'multipart/form-data'
        }
        })
        .then(res =>{
          this.setState({datarequested:false})
          this.setState({sheetitems:JSON.parse(res.data.report)})
          this.setState({totaldebit:res.data.totalDebit})
          this.setState({totalcredit:res.data.totalCredit})

          this.setState({selectedmemberobject: res.data.p_balances})
          this.setState({opening_balances: res.data.opening_balances})
          this.setState({closing_balances: res.data.closing_balances})


        })
        .catch(error => console.log(error))   
    }

    //end of search

    /*
          axios.get(`${serverconfig.backendserverurl}/api/members/${e}`)
          .then(res => {  
              this.setState({selectedmemberobject: res.data})
          })*/

    this.setState({ selectedmemberid: e});

   } 

 
   //search sales method
   searchSales=()=>{

    let form_data = new FormData();
    form_data.append('dateone', this.state.dateone);
    form_data.append('datetwo', this.state.datetwo);
    form_data.append('memberid', this.state.selectedmemberid);
    form_data.append('sacco', sacco);
    form_data.append('order_of_items', this.state.order_of_items);

    //subaccounts now
    form_data.append('shareno', this.state.shareno);
    form_data.append('shareamount', this.state.shareamount);
    form_data.append('savings', this.state.savings);
    form_data.append('savingsinterest', this.state.savingsinterest);
    form_data.append('supersave', this.state.supersave);
    form_data.append('supersaveinterest', this.state.supersaveinterest);
    form_data.append('loan', this.state.loan);
    form_data.append('loaninterest', this.state.loaninterest);
    form_data.append('loansecurity', this.state.loansecurity);
    form_data.append('defdeposit', this.state.defdeposit);
    form_data.append('teebagoolo', this.state.teebagoolo);
    form_data.append('teebagoolointerest', this.state.teebagoolointerest);

    form_data.append('savingsloanscurity', this.state.savingsloanscurity);
    form_data.append('supersaveloansecurity', this.state.supersaveloansecurity);
    form_data.append('teebagoolo_loansecurity', this.state.teebagoolo_loansecurity);
    form_data.append('sharesloansecurity', this.state.sharesloansecurity);
    form_data.append('allitemson', this.state.allitemson);


    if(this.state.selectedmemberid===''||this.state.dateone===''||this.state.datetwo===''){
      alert("Account or dates missing")
    }else{
        this.setState({datarequested:true})

        //Now submit sale data to database
        axios.post(serverconfig.backendserverurl+'/customqueries/getmemberfinancialstatement', form_data,{
        headers: {
          'content-type': 'multipart/form-data'
        }
        })
        .then(res =>{
          this.setState({datarequested:false})
          this.setState({sheetitems:JSON.parse(res.data.report)})
          this.setState({totaldebit:res.data.totalDebit})
          this.setState({totalcredit:res.data.totalCredit})

          this.setState({selectedmemberobject: res.data.p_balances})
          this.setState({opening_balances: res.data.opening_balances})
          this.setState({closing_balances: res.data.closing_balances})

        })
        .catch(error => console.log(error))   
    }
  
  }

   ////////////////////////////////////
  // USER SEARCH SELECT METHODS
  onBlur=()=> {
    console.log('blur');
  }
  
   onFocus=()=>{
    console.log('focus');
  }
  
  onSearch=(val)=> {
    console.log('search:', val);
  }
 

   ////////////////////////////////////////////
   // RENDERING METHOD HERE
   render() {
       
       if(this.state.datarequested===true){
        return(
          <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
          <Spin indicator={antIcon} />
          </div>
        )
      }else{

        return(
          <div >
            <div style={{display: "none"}}>
               &nbsp;&nbsp;
              <ReportToPrint
              statementtype={this.state.statementtype} 
              totaldebit={this.state.totaldebit} 
              totalcredit={this.state.totalcredit} 
              dateone={this.state.dateone}
              datetwo={this.state.datetwo}
              sheetitems={this.state.sheetitems}
              selectedmemberobject={this.state.selectedmemberobject}

              savings={this.state.savings}
              savingsinterest={this.state.savingsinterest}
              shareamount={this.state.shareamount}
              shareno={this.state.shareno}
              supersave={this.state.supersave}
              supersaveinterest={this.state.supersaveinterest}
              teebagoolo={this.state.teebagoolo}
              teebagoolointerest={this.state.teebagoolointerest}
              loan={this.state.loan}
              loaninterest={this.state.loaninterest}
              loansecurity={this.state.loansecurity}
              defdeposit={this.state.defdeposit}

              savingsloanscurity={this.state.savingsloanscurity}
              supersaveloansecurity={this.state.supersaveloansecurity}
              teebagoolo_loansecurity={this.state.teebagoolo_loansecurity}
              sharesloansecurity={this.state.sharesloansecurity}

              opening_balances={this.state.opening_balances}
              closing_balances={this.state.closing_balances}

              ref={el => (this.componentRef = el)} /> 
            </div>
  
            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
              <Panel header="MEMBER FINANCIAL STATEMENT" key="1" >

              <div style={{display:'flex',justifyContent:'flex-start',flexDirection:'row'}}>
              <ReactToPrint
                trigger={() => 
                <Button type="primary"  shape="round" icon={<PrinterOutlined />} size={this.state.size}>
                Print
                </Button>
                }
                content={() => this.componentRef}
                />

                 &nbsp;
                 &nbsp;


               <Button type='primary'
                icon={<MailFilled/>}
                
                onClick={
                 ()=>{
                   let form_data = new FormData();
                   form_data.append('dateone', this.state.dateone);
                   form_data.append('datetwo', this.state.datetwo);
                   form_data.append('memberid', this.state.selectedmemberid);
                   form_data.append('sacco', sacco);

                    //subaccounts now
                    form_data.append('shareno', this.state.shareno);
                    form_data.append('shareamount', this.state.shareamount);
                    form_data.append('savings', this.state.savings);
                    form_data.append('savingsinterest', this.state.savingsinterest);
                    form_data.append('supersave', this.state.supersave);
                    form_data.append('supersaveinterest', this.state.supersaveinterest);
                    form_data.append('loan', this.state.loan);
                    form_data.append('loaninterest', this.state.loaninterest);
                    form_data.append('loansecurity', this.state.loansecurity);
                    form_data.append('defdeposit', this.state.defdeposit);
                    form_data.append('teebagoolo', this.state.teebagoolo);
                    form_data.append('teebagoolointerest', this.state.teebagoolointerest);

                    form_data.append('savingsloanscurity', this.state.savingsloanscurity);
                    form_data.append('supersaveloansecurity', this.state.supersaveloansecurity);
                    form_data.append('teebagoolo_loansecurity', this.state.teebagoolo_loansecurity);
                    form_data.append('sharesloansecurity', this.state.sharesloansecurity);
                    form_data.append('allitemson', this.state.allitemson);

                   
                   console.log(this.state.entrant)
                     
                   if(this.state.selectedmemberid===''||this.state.dateone===''||this.state.datetwo===''){
                     alert("Please select entrant or dates missing")
                   }else{
                       this.setState({datarequested:true})
               
                       //Now submit sale data to database
                       axios.post(serverconfig.backendserverurl+'/customqueries/emailmemberfinancialstatement', form_data,{
                       headers: {
                         'content-type': 'multipart/form-data'
                       }
                       })
                       .then(res =>{
                        
                         this.setState({datarequested:false})
                         message.info(res.data.message)

                       })
                       .catch(error => console.log(error))   
                   }
                 }
                }
                
                >
                 Send via eMail
               </Button>

                </div>
                
                <br></br>
                <br></br>

                   <Form  layout="inline" >
            
                   <FormItem label="Members">
                     <Select 
                     placeholder="Account"
                     style={{ width: 300 }} 
                     value={this.state.selectedmemberid} 
                     onChange={this.handlememberChange}
                      
                     showSearch
                     optionFilterProp="children"
                     onFocus={this.onFocus}
                     onBlur={this.onBlur}
                     onSearch={this.onSearch}                     
                      >
                       {this.state.members.map(
                         (member)=>(
                           <Option value={member.id}>{member.AccountNumber} | {member.FullName}</Option>
                         ))}
                     </Select>
                 </FormItem>


                 <FormItem label="Order Of Items">
                     <Select 
                     placeholder="Account"
                     style={{ width: 300 }} 
                     value={this.state.order_of_items} 
                     onChange={(val)=>{this.setState({order_of_items:val})}}
                      
                     showSearch
                     optionFilterProp="children"
                     onFocus={this.onFocus}
                     onBlur={this.onBlur}
                     onSearch={this.onSearch}                     
                      >
                      <Option value='descending'>Descending (Last In)</Option>
                      <Option value='ascending'>Ascending (First In)</Option>
                     </Select>
                 </FormItem>


                <FormItem label={<h4>All</h4>}>
                <Switch
                  checked={this.state.allitemson}
                  onChange={val=>{
                    
                    this.setState({allitemson:val})

                  if(val===true){
                    this.setState({shareno:true})
                    this.setState({shareamount:true})
                    this.setState({savings:true})
                    this.setState({savingsinterest:true})
                    this.setState({supersave:true})
                    this.setState({supersaveinterest:true})
                    this.setState({loan:true})
                    this.setState({loaninterest:true})
                    this.setState({loansecurity:true})
                    this.setState({defdeposit:true})
                    this.setState({teebagoolo:true})
                    this.setState({teebagoolointerest:true})

                    this.setState({savingsloanscurity:true})
                    this.setState({supersaveloansecurity:true})
                    this.setState({teebagoolo_loansecurity:true})
                    this.setState({sharesloansecurity:true})


                  }else{
                    this.setState({shareno:false})
                    this.setState({shareamount:false})
                    this.setState({savings:false})
                    this.setState({savingsinterest:false})
                    this.setState({supersave:false})
                    this.setState({supersaveinterest:false})
                    this.setState({loan:false})
                    this.setState({loaninterest:false})
                    this.setState({loansecurity:false})
                    this.setState({defdeposit:false})
                    this.setState({teebagoolo:false})
                    this.setState({teebagoolointerest:false})

                    this.setState({savingsloanscurity:false})
                    this.setState({supersaveloansecurity:false})
                    this.setState({teebagoolo_loansecurity:false})
                    this.setState({sharesloansecurity:false})
                  }
                  
                  }}
                  checkedChildren="True"
                  unCheckedChildren="False"
                  />
                </FormItem>


                <FormItem label="ShN">
                <Switch
                  checked={this.state.shareno}
                  onChange={val=>{this.setState({shareno:val})}}
                  checkedChildren="True"
                  unCheckedChildren="False"
                  />
                </FormItem>

                <FormItem label="ShA">
                <Switch
                  checked={this.state.shareamount}
                  onChange={val=>{this.setState({shareamount:val})}}
                  checkedChildren="True"
                  unCheckedChildren="False"
                  />
                </FormItem>

                <FormItem label="OS">
                <Switch
                  checked={this.state.savings}
                  onChange={val=>{this.setState({savings:val})}}
                  checkedChildren="True"
                  unCheckedChildren="False"
                  />
                </FormItem>

                <FormItem label="OSI">
                <Switch
                  checked={this.state.savingsinterest}
                  onChange={val=>{this.setState({savingsinterest:val})}}
                  checkedChildren="True"
                  unCheckedChildren="False"
                  />
                </FormItem>

                <FormItem label="SS">
                <Switch
                  checked={this.state.supersave}
                  onChange={val=>{this.setState({supersave:val})}}
                  checkedChildren="True"
                  unCheckedChildren="False"
                  />
                </FormItem>

                <FormItem label="SSI">
                <Switch
                  checked={this.state.supersaveinterest}
                  onChange={val=>{this.setState({supersaveinterest:val})}}
                  checkedChildren="True"
                  unCheckedChildren="False"
                  />
                </FormItem>

                <FormItem label="Ln">
                <Switch
                  checked={this.state.loan}
                  onChange={val=>{this.setState({loan:val})}}
                  checkedChildren="True"
                  unCheckedChildren="False"
                  />
                </FormItem>

                <FormItem label="LnI">
                <Switch
                  checked={this.state.loaninterest}
                  onChange={val=>{this.setState({loaninterest:val})}}
                  checkedChildren="True"
                  unCheckedChildren="False"
                  />
                </FormItem>

                <FormItem label="LG">
                <Switch
                  checked={this.state.loansecurity}
                  onChange={val=>{this.setState({loansecurity:val})}}
                  checkedChildren="True"
                  unCheckedChildren="False"
                  />
                </FormItem>

                <FormItem label="DD">
                <Switch
                  checked={this.state.defdeposit}
                  onChange={val=>{this.setState({defdeposit:val})}}
                  checkedChildren="True"
                  unCheckedChildren="False"
                  />
                </FormItem>

                <FormItem label="TG">
                <Switch
                  checked={this.state.teebagoolo}
                  onChange={val=>{this.setState({teebagoolo:val})}}
                  checkedChildren="True"
                  unCheckedChildren="False"
                  />
                </FormItem>

                <FormItem label="TGI">
                <Switch
                  checked={this.state.teebagoolointerest}
                  onChange={val=>{this.setState({teebagoolointerest:val})}}
                  checkedChildren="True"
                  unCheckedChildren="False"
                  />
                </FormItem>

                {/*new additions*/}
                <FormItem label="SLS">
                <Switch
                  checked={this.state.savingsloanscurity}
                  onChange={val=>{this.setState({savingsloanscurity:val})}}
                  checkedChildren="True"
                  unCheckedChildren="False"
                  />
                </FormItem>

                <FormItem label="SSLS">
                <Switch
                  checked={this.state.supersaveloansecurity}
                  onChange={val=>{this.setState({supersaveloansecurity:val})}}
                  checkedChildren="True"
                  unCheckedChildren="False"
                  />
                </FormItem>

                <FormItem label="TGLS">
                <Switch
                  checked={this.state.teebagoolo_loansecurity}
                  onChange={val=>{this.setState({teebagoolo_loansecurity:val})}}
                  checkedChildren="True"
                  unCheckedChildren="False"
                  />
                </FormItem>

                <FormItem label="ShLS">
                <Switch
                  checked={this.state.sharesloansecurity}
                  onChange={val=>{this.setState({sharesloansecurity:val})}}
                  checkedChildren="True"
                  unCheckedChildren="False"
                  />
                </FormItem>

 
                <FormItem label="Date Range">
                    <RangePicker defaultValue={moment()} onChange={this.handledatechange} format={dateFormat} />
                </FormItem>

                <FormItem>
                <Button onClick={this.searchSales} type="primary" htmlType="button">Search</Button>
                </FormItem> 
  
             </Form>
  
             <br></br>

            <div style={{display:'flex',overflow:'auto',flexDirection:'column'}}>
            <reactstrp.Table bordered>
                    <thead>
                      <tr>
                        <th>Reprint</th>
                        <th>No</th>
                        <th>Date</th>
                        <th>Details</th>

                        {
                        this.state.shareno===true?
                        <th>ShN</th>
                        :null
                        }

                        {
                        this.state.shareamount===true?
                        <th>ShA</th>
                        :null
                        }
                        {
                        this.state.savings===true?
                        <th>OS</th>
                        :null
                        }
                        {
                        this.state.savingsinterest===true?
                        <th>OSI</th>
                        :null
                        }      

                        {
                        this.state.supersave===true?
                        <th>SS</th>
                        :null
                        }  

                        {
                        this.state.supersaveinterest===true?
                        <th>SSI</th>
                        :null
                        }    

                        {
                        this.state.loan===true?
                        <th>Ln</th>
                        :null
                        }  

                        {
                        this.state.loan===true?
                        <th>Ln RB</th>
                        :null
                        } 

                        {
                        this.state.loaninterest===true?
                        <th>LnI</th>
                        :null
                        }  

                        {
                        this.state.loaninterest===true?
                        <th>LnI RB</th>
                        :null
                        } 

                        {
                        this.state.loansecurity===true?
                        <th>LG</th>
                        :null
                        }  

                        {
                        this.state.defdeposit===true?
                        <th>DD</th>
                        :null
                        }  

                        {
                        this.state.teebagoolo===true?
                        <th>TG</th>
                        :null
                        } 

                        {
                        this.state.teebagoolointerest===true?
                        <th>TGI</th>
                        :null
                        } 

                        {/*new enytries*/}
                        {
                        this.state.savingsloanscurity===true?
                        <th>SLS</th>
                        :null
                        }

                        {
                        this.state.supersaveloansecurity===true?
                        <th>SSLS</th>
                        :null
                        }

                        {
                        this.state.teebagoolo_loansecurity===true?
                        <th>TGLS</th>
                        :null
                        }

                        {
                        this.state.sharesloansecurity===true?
                        <th>ShLs</th>
                        :null
                        }

                        <th>Journal No</th>
                        
                        {
                          this.state.companyprofile.allow_transaction_reversal && this.state.userrights.can_make_transaction_reversals?
                          <th>Reverse</th>
                          :
                          null
                        }


                      </tr>
                    </thead>
                    <tbody>
                    {this.state.sheetitems.map(
                      (item)=>(
                        <React.Fragment key={item}>

                        <tr >

                        <td>
                          <Tooltip title="Click to print receipt" placement="top">
                          <PrinterOutlined style={{color:'blue'}}
                            onClick={()=>{
                              console.log("ID: "+String(item.id))
                              this.setState({selected_id:Number(item.id)})
                              this.setState({show_printer_modal:!this.state.show_printer_modal})
                          }}
                          />
                          </Tooltip>
                        </td>

                         <td>{item.item_no}</td>

                        <td>
                          {item.time_stamp} 
                          &nbsp;&nbsp;
                          <Tooltip title="Click to view all journal transactions" placement="top">
                          <FundViewOutlined style={{color:'blue',fontSize: '30px'}}
                           onClick={()=>{
                            this.setState({txndetailsmodal_visible:true})
                            this.setState({selected_journal:item})

                            //make qery now
                            let form_data = new FormData();
                            form_data.append('date',item.date);
                            form_data.append('selectedmemberid', this.state.selectedmemberid);
                            form_data.append('journalno', item.jnlno);
                            form_data.append('sacco', sacco);
                      
                            axios.post(serverconfig.backendserverurl+'/customqueries/queryreversal_transactions', form_data, {
                              headers: {
                                'content-type': 'multipart/form-data'
                              }
                              })
                            .then(res => {
                              this.setState({tobereversed_transactions:JSON.parse(res.data.txn_report)})
                              this.setState({loan_txn_report:JSON.parse(res.data.loan_txn_report)})
                              this.setState({general_ledger_entriesreport:JSON.parse(res.data.general_ledger_entriesreport)})

                            })
                            .catch(error => console.log(error))

                          }}
                          />
                        </Tooltip>
                        
                        </td>

                        <td>{item.transaction_details}</td>


                        {
                          this.state.shareno===true?
                          <td className="align-right">
                          {
                            Number(item.sharenodebtotal)>Number(item.sharenocredtotal)?"-"+Number(item.sharenodebtotal):''+Number(item.sharenocredtotal)
                          }
                         </td>
                          :
                          null
                        }


                        {
                          this.state.shareamount===true?
                          <td className="align-right">
                          <CurrencyFormat value= {
                              Number(item.shareamtdebtotal)>Number(item.shareamtcredtotal)?"-"+Number(item.shareamtdebtotal).toFixed(2):''+Number(item.shareamtcredtotal).toFixed(2)
                            } displayType={'text'} thousandSeparator={true}/>
                          </td>
                          :
                          null}


                        {
                          this.state.savings===true?
                          <td className="align-right">
                          <CurrencyFormat value={
                              Number(item.savingdebtotal)>Number(item.savingcredtotal)?"-"+Number(item.savingdebtotal).toFixed(2):''+Number(item.savingcredtotal).toFixed(2)
                            } displayType={'text'} thousandSeparator={true}/>
                          </td>
                          :
                          null
                        }


                        {
                          this.state.savingsinterest===true?
                          <td className="align-right">
                           <CurrencyFormat value=                            {
                              Number(item.savingintdebtotal)>Number(item.savingintcredtotal)?"-"+Number(item.savingintdebtotal).toFixed(2):''+Number(item.savingintcredtotal).toFixed(2)
                              
                            } displayType={'text'} thousandSeparator={true}/>
  
                          </td>
                          :
                          null
                        }


                        {
                          this.state.supersave===true?
                          <td className="align-right">

                          <CurrencyFormat value= {
                              Number(item.supersavedebtotal)>Number(item.supersavecredtotal)?"-"+Number(item.supersavedebtotal).toFixed(2):''+Number(item.supersavecredtotal).toFixed(2)
                             
                            } displayType={'text'} thousandSeparator={true}/>
  
                          </td>                          
                          :
                          null
                        }


                        {
                          this.state.supersaveinterest===true?
                          <td className="align-right">

                          <CurrencyFormat value= {
                              
                              Number(item.supersaveintdebtotal)>Number(item.supersaveintcredtotal)?"-"+Number(item.supersaveintdebtotal).toFixed(2):''+Number(item.supersaveintcredtotal).toFixed(2)
                              
                            } displayType={'text'} thousandSeparator={true}/>
  
                          </td>                          
                          :
                          null

                        }
                        {
                          this.state.loan===true?
                          <td className="align-right">
                          <CurrencyFormat 
                           value= {
                              Number(item.loancredtotal)>Number(item.loandebtotal)?"-"+Number(item.loancredtotal).toFixed(2):''+Number(item.loandebtotal).toFixed(2)
                            } displayType={'text'} thousandSeparator={true}/>
  
                          </td>                          
                          :
                          null
                        }


                        {/**Loan principle RB */}
                        {
                          this.state.loan===true?
                          <td className="align-right">
                          <CurrencyFormat value= {
                              Number(item.loan_balance)
                            } displayType={'text'} thousandSeparator={true}/>
  
                          </td>                          
                          :
                          null
                        }


                        {
                          this.state.loaninterest===true?
                          <td className="align-right">
                          <CurrencyFormat value={

                              Number(item.loanintcredtotal)>Number(item.loanintdebtotal)?"-"+Number(item.loanintcredtotal).toFixed(2):''+Number(item.loanintdebtotal).toFixed(2)
                            } displayType={'text'} thousandSeparator={true}/>
  
                          </td>  
                          :
                          null
                        }

                      {/**Loan intst RB */}
                      {
                          this.state.loaninterest===true?
                          <td className="align-right">
                          <CurrencyFormat value= {
                              Number(item.loan_interestbalance)
                            } displayType={'text'} thousandSeparator={true}/>
  
                          </td>                          
                          :
                          null
                        }

                         
                        {
                          this.state.loansecurity===true?
                          <td className="align-right">

                          <CurrencyFormat value={
                              Number(item.loansecdebtotal)>Number(item.loanseccredtotal)?"-"+Number(item.loansecdebtotal).toFixed(2):''+Number(item.loanseccredtotal).toFixed(2)
                              
                            } displayType={'text'} thousandSeparator={true}/>
  
                          </td>  
                          :
                          null
                        }



                        {
                          this.state.defdeposit===true?
                          <td className="align-right">
                          <CurrencyFormat value=                            {
                              
                              Number(item.defdebtotal)>Number(item.defcredtotal)?"-"+Number(item.defdebtotal).toFixed(2):''+Number(item.defcredtotal).toFixed(2)
                              
                            } displayType={'text'} thousandSeparator={true}/>
  
                          </td>  
                          :
                          null
                        }


                        {
                          this.state.teebagoolo===true?
                          <td className="align-right">

                          <CurrencyFormat value={
                              Number(item.teebagoolodebtotal)>Number(item.teebagoolocredtotal)?"-"+Number(item.teebagoolodebtotal).toFixed(2):''+Number(item.teebagoolocredtotal).toFixed(2)
                              
                            } displayType={'text'} thousandSeparator={true}/>
  
                          </td> 
                          :
                          null
                        }

                        {
                          this.state.teebagoolointerest===true?
                          <td className="align-right">
                          <CurrencyFormat value= {
                              Number(item.teebagoolointdebtotal)>Number(item.teebagoolointcredtotal)?"-"+Number(item.teebagoolointdebtotal).toFixed(2):''+Number(item.teebagoolointcredtotal).toFixed(2)
                              
                            } displayType={'text'} thousandSeparator={true}/>
  
                          </td>   
                          :
                          null
                        }

                        {/*new enytries*/}
                        {
                        this.state.savingsloanscurity===true?
                        <td className="align-right">
                        <CurrencyFormat value= {
                            Number(item.savingloansecuritydebtotal)>Number(item.savingloansecuritycredtotal)?"-"+Number(item.savingloansecuritydebtotal).toFixed(2):''+Number(item.savingloansecuritycredtotal).toFixed(2)
                            
                          } displayType={'text'} thousandSeparator={true}/>

                        </td> 
                            :null
                        }

                        {
                        this.state.supersaveloansecurity===true?
                        <td className="align-right">
                        <CurrencyFormat value= {
                            Number(item.supersaveloansecuritydebtotal)>Number(item.supersaveloansecuritycredtotal)?"-"+Number(item.supersaveloansecuritydebtotal).toFixed(2):''+Number(item.supersaveloansecuritycredtotal).toFixed(2)
                            
                          } displayType={'text'} thousandSeparator={true}/>

                        </td>     
                            :null
                        }

                        {
                        this.state.teebagoolo_loansecurity===true?
                        <td className="align-right">
                        <CurrencyFormat value= {
                            Number(item.teebagoololoansecuritydebtotal)>Number(item.teebagoololoansecuritycredtotal)?"-"+Number(item.teebagoololoansecuritydebtotal).toFixed(2):''+Number(item.teebagoololoansecuritycredtotal).toFixed(2)
                            
                          } displayType={'text'} thousandSeparator={true}/>

                        </td> 
                            :null
                        }

                        {
                        this.state.sharesloansecurity===true?
                        <td className="align-right">
                        <CurrencyFormat value= {
                            Number(item.sharesloansecuritydebtotal)>Number(item.sharesloansecuritycredtotal)?"-"+Number(item.sharesloansecuritydebtotal).toFixed(2):''+Number(item.sharesloansecuritycredtotal).toFixed(2)
                            
                          } displayType={'text'} thousandSeparator={true}/>

                        </td>                       
                          :null
                        }

    
                      <td>
                        {item.jnlno}
                    
                        
                       </td> 

                        {
                          this.state.companyprofile.allow_transaction_reversal && this.state.userrights.can_make_transaction_reversals?
                          <th><Button type='primary' onClick={()=>{
                            this.setState({reversalmodal_visible:true})
                            this.setState({selected_journal:item})

                            //make qery now
                            let form_data = new FormData();
                            form_data.append('date',item.date);
                            form_data.append('selectedmemberid', this.state.selectedmemberid);
                            form_data.append('journalno', item.jnlno);
                            form_data.append('sacco', sacco);
                      
                            axios.post(serverconfig.backendserverurl+'/customqueries/queryreversal_transactions', form_data, {
                              headers: {
                                'content-type': 'multipart/form-data'
                              }
                              })
                            .then(res => {
                              this.setState({tobereversed_transactions:JSON.parse(res.data.txn_report)})
                              this.setState({loan_txn_report:JSON.parse(res.data.loan_txn_report)})
                              this.setState({general_ledger_entriesreport:JSON.parse(res.data.general_ledger_entriesreport)})

                            })
                            .catch(error => console.log(error))


                          }}>Reverse</Button></th>
                          :
                          null
                        }
                                                                                               

                        </tr>


                        {
                          this.state.show_printer_modal===true && Number(this.state.selected_id)===Number(item.id)?
                          <tr >
                              <td colSpan={23}>
                              {/* Your expanded content goes here */}
                              <div>
                                
                                {
                                this.state.show_printer_modal===true?
                                  <ReprintTransactionReceipt
                                  transaction={item} 
                                  />
                                :
                                null
                                }

                              </div>
                            </td>

                          </tr>
                          :
                          null
                        }

                        </React.Fragment>
                      ))}


                {/**Opening balances */}
               
{ /*               <tr>
                    <td></td>
                    <td></td>

                    <td>
                    <h6>Opening Balances: </h6>

                    </td>
                    <td></td>


                    {this.state.shareno===true?
                      <th className="align-right">{this.state.opening_balances.share_numberbalance}</th>
                    :
                    null
                    }

                    {
                      this.state.shareamount===true?
                      <th className="align-right">
                      <CurrencyFormat value={this.state.opening_balances.shareamount_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                      </th>
                      :
                      null
                    }

                    {
                      this.state.savings===true?
                      <th className="align-right">
                      <CurrencyFormat value={this.state.opening_balances.savings_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                      </th>
                      :
                      null
                    }

                    {
                      this.state.savingsinterest===true?
                      <th className="align-right">
                      <CurrencyFormat value={this.state.opening_balances.saving_interestbalance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                      </th>
                      :
                      null
                    }

                    {
                      this.state.supersave===true?
                      <th className="align-right">
                      <CurrencyFormat value={this.state.opening_balances.supersaver_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                      </th>
                      :
                      null
                    }

                    {
                      this.state.supersaveinterest===true?
                      <th className="align-right">
                      <CurrencyFormat value={this.state.opening_balances.supersave_interestbalance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                      </th>
                      :
                      null
                    }

                    {
                      this.state.loan===true?
                      <th className="align-right">
                      <CurrencyFormat value={this.state.opening_balances.loan_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                      </th>
                      :
                      null
                    }
                    {this.state.loan===true?<th></th>:null}

                    {
                      this.state.loaninterest===true?
                      <th className="align-right">
                      <CurrencyFormat value={this.state.opening_balances.loan_interestbalance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                      </th>
                      :
                      null
                    }
                    {this.state.loaninterest===true?<th></th>:null}

                    {
                      this.state.loansecurity===true?
                      <th className="align-right">
                      <CurrencyFormat value={this.state.opening_balances.loan_security_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                      </th>
                      :
                      null
                    }

                    {
                      this.state.defdeposit===true?
                      <th className="align-right">
                      <CurrencyFormat value={this.state.opening_balances.def_deposit_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                      </th>
                      :
                      null
                    }

                    {
                      this.state.teebagoolo===true?
                      <th className="align-right">
                      <CurrencyFormat value={this.state.opening_balances.teebagoolo_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                      </th>
                      :
                      null
                    }

                    {
                      this.state.teebagoolointerest===true?
                      <th className="align-right">
                      <CurrencyFormat value={this.state.opening_balances.teebagoolo_interestbalance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                      </th>
                      :
                      null
                    }

                  {
                      this.state.savingsloanscurity===true?
                      <th className="align-right">
                      <CurrencyFormat value={this.state.opening_balances.savingsloan_security_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                      </th>
                      :
                      null
                   }
                   

                  {
                      this.state.supersaveloansecurity===true?
                      <th className="align-right">
                      <CurrencyFormat value={this.state.opening_balances.supersaveloan_security_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                      </th>
                      :
                      null
                    }

                    {
                      this.state.teebagoolo_loansecurity===true?
                      <th className="align-right">
                      <CurrencyFormat value={this.state.opening_balances.teebagoololoan_security_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                      </th>
                      :
                      null
                    }

                   {
                      this.state.sharesloansecurity===true?
                      <th className="align-right">
                      <CurrencyFormat value={this.state.opening_balances.shares_security_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                      </th>
                      :
                      null
                    }

                    <td> </td>

                    {
                      this.state.companyprofile.allow_transaction_reversal && this.state.userrights.can_make_transaction_reversals?
                      <td> </td>
                      :
                      null
                    }

                  </tr> */}

                      {/**Balances balances */}

{/*
                  <tr>
                    <td></td>
                    <td></td>

                    <td>
                    <h6>Transacted Totals: </h6>

                    </td>
                    <td></td>


                    {this.state.shareno===true?
                      <th className="align-right">{this.state.selectedmemberobject.share_numberbalance}</th>
                    :
                    null
                    }

                    {
                      this.state.shareamount===true?
                      <th className="align-right">
                      <CurrencyFormat value={this.state.selectedmemberobject.shareamount_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                      </th>
                      :
                      null
                    }

                    {
                      this.state.savings===true?
                      <th className="align-right">
                      <CurrencyFormat value={this.state.selectedmemberobject.savings_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                      </th>
                      :
                      null
                    }

                    {
                      this.state.savingsinterest===true?
                      <th className="align-right">
                      <CurrencyFormat value={this.state.selectedmemberobject.saving_interestbalance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                      </th>
                      :
                      null
                    }

                    {
                      this.state.supersave===true?
                      <th className="align-right">
                      <CurrencyFormat value={this.state.selectedmemberobject.supersaver_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                      </th>
                      :
                      null
                    }

                    {
                      this.state.supersaveinterest===true?
                      <th className="align-right">
                      <CurrencyFormat value={this.state.selectedmemberobject.supersave_interestbalance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                      </th>
                      :
                      null
                    }

                    {
                      this.state.loan===true?
                      <th className="align-right">
                      <CurrencyFormat value={this.state.selectedmemberobject.loan_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                      </th>
                      :
                      null
                    }
                    {this.state.loan===true?<th></th>:null}

                    {
                      this.state.loaninterest===true?
                      <th className="align-right">
                      <CurrencyFormat value={this.state.selectedmemberobject.loan_interestbalance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                      </th>
                      :
                      null
                    }
                    {this.state.loaninterest===true?<th></th>:null}

                    {
                      this.state.loansecurity===true?
                      <th className="align-right">
                      <CurrencyFormat value={this.state.selectedmemberobject.loan_security_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                      </th>
                      :
                      null
                    }

                    {
                      this.state.defdeposit===true?
                      <th className="align-right">
                      <CurrencyFormat value={this.state.selectedmemberobject.def_deposit_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                      </th>
                      :
                      null
                    }

                    {
                      this.state.teebagoolo===true?
                      <th className="align-right">
                      <CurrencyFormat value={this.state.selectedmemberobject.teebagoolo_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                      </th>
                      :
                      null
                    }

                    {
                      this.state.teebagoolointerest===true?
                      <th className="align-right">
                      <CurrencyFormat value={this.state.selectedmemberobject.teebagoolo_interestbalance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                      </th>
                      :
                      null
                    }

                  {
                      this.state.savingsloanscurity===true?
                      <th className="align-right">
                      <CurrencyFormat value={this.state.selectedmemberobject.savingsloan_security_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                      </th>
                      :
                      null
                    }

                  {
                      this.state.supersaveloansecurity===true?
                      <th className="align-right">
                      <CurrencyFormat value={this.state.selectedmemberobject.supersaveloan_security_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                      </th>
                      :
                      null
                    }

                    {
                      this.state.teebagoolo_loansecurity===true?
                      <th className="align-right">
                      <CurrencyFormat value={this.state.selectedmemberobject.teebagoololoan_security_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                      </th>
                      :
                      null
                    }

                   {
                      this.state.sharesloansecurity===true?
                      <th className="align-right">
                      <CurrencyFormat value={this.state.selectedmemberobject.shares_security_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                      </th>
                      :
                      null
                    }

                    <td> </td>

                    {
                      this.state.companyprofile.allow_transaction_reversal && this.state.userrights.can_make_transaction_reversals?
                      <td> </td>
                      :
                      null
                    }

                  </tr> */}





                    {/**Closing balance */}

                    <tr>
                    <td></td>
                    <td></td>

                    <td>
                    <h6>Closing Balances: </h6>
                    </td>
                    <td></td>


                    {this.state.shareno===true?
                      <th className="align-right">{this.state.closing_balances.share_numberbalance}</th>
                    :
                    null
                    }

                    {
                      this.state.shareamount===true?
                      <th className="align-right">
                      <CurrencyFormat value={this.state.closing_balances.shareamount_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                      </th>
                      :
                      null
                    }

                    {
                      this.state.savings===true?
                      <th className="align-right">
                      <CurrencyFormat value={this.state.closing_balances.savings_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                      </th>
                      :
                      null
                    }

                    {
                      this.state.savingsinterest===true?
                      <th className="align-right">
                      <CurrencyFormat value={this.state.closing_balances.saving_interestbalance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                      </th>
                      :
                      null
                    }

                    {
                      this.state.supersave===true?
                      <th className="align-right">
                      <CurrencyFormat value={this.state.closing_balances.supersaver_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                      </th>
                      :
                      null
                    }

                    {
                      this.state.supersaveinterest===true?
                      <th className="align-right">
                      <CurrencyFormat value={this.state.closing_balances.supersave_interestbalance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                      </th>
                      :
                      null
                    }

                    {
                      this.state.loan===true?
                      <th className="align-right">
                      <CurrencyFormat value={Math.abs(Number(this.state.closing_balances.loan_balance).toFixed(2))} displayType={'text'} thousandSeparator={true}/>
                      </th>
                      :
                      null
                    }
                    
                    {this.state.loan===true?<th></th>:null}

                    {
                      this.state.loaninterest===true?
                      <th className="align-right">
                      <CurrencyFormat value={Math.abs(Number(this.state.closing_balances.loan_interestbalance).toFixed(2))} displayType={'text'} thousandSeparator={true}/>
                      </th>
                      :
                      null
                    }

                  {this.state.loaninterest===true?<th></th>:null}


                    {
                      this.state.loansecurity===true?
                      <th className="align-right">
                      <CurrencyFormat value={this.state.closing_balances.loan_security_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                      </th>
                      :
                      null
                    }

                    {
                      this.state.defdeposit===true?
                      <th className="align-right">
                      <CurrencyFormat value={this.state.closing_balances.def_deposit_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                      </th>
                      :
                      null
                    }

                    {
                      this.state.teebagoolo===true?
                      <th className="align-right">
                      <CurrencyFormat value={this.state.closing_balances.teebagoolo_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                      </th>
                      :
                      null
                    }

                    {
                      this.state.teebagoolointerest===true?
                      <th className="align-right">
                      <CurrencyFormat value={this.state.closing_balances.teebagoolo_interestbalance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                      </th>
                      :
                      null
                    }

                  {
                      this.state.savingsloanscurity===true?
                      <th className="align-right">
                      <CurrencyFormat value={this.state.closing_balances.savingsloan_security_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                      </th>
                      :
                      null
                    }

                  {
                      this.state.supersaveloansecurity===true?
                      <th className="align-right">
                      <CurrencyFormat value={this.state.closing_balances.supersaveloan_security_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                      </th>
                      :
                      null
                    }

                    {
                      this.state.teebagoolo_loansecurity===true?
                      <th className="align-right">
                      <CurrencyFormat value={this.state.closing_balances.teebagoololoan_security_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                      </th>
                      :
                      null
                    }

                   {
                      this.state.sharesloansecurity===true?
                      <th className="align-right">
                      <CurrencyFormat value={this.state.closing_balances.shares_security_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                      </th>
                      :
                      null
                    }

                    <td> </td>

                    {
                      this.state.companyprofile.allow_transaction_reversal && this.state.userrights.can_make_transaction_reversals?
                      <td> </td>
                      :
                      null
                    }

                  </tr>

                  </tbody>
                </reactstrp.Table>  
                <h5> KEYS,  ShN: Share Number, ShA: Share Amount, OS: Savings, OSI: Savings Interest, SS: SuperSave, SSI: SuperSave Interest, Ln: Loan, LnI: Loan Interest, LG: Loan Guarantee, DD: Def Deposit, TG: TeebaGoolo, TGI: TeebaGoolo Interest,
                SLS: Savings loan security, SSLS: Supersave loan security, TGLS: Teebagoolo loan security, ShLS: Shares loan security

                </h5>

                </div>
                <br></br>
              
              

                <br></br>
               {/* <h3 style={{color:'green'}}>Upload Old system financial statements here.</h3>
                <UploadStatementsView />*/}
            
            </Panel>
            </Collapse>


            {
              this.state.reversalmodal_visible?
              <Modal              
              visible={this.state.reversalmodal_visible}
              title={"Transaction reversal details"}
              onCancel={()=>{this.setState({reversalmodal_visible:false})}}
              //style={{width:1000}}
              width={1500}
              footer={[
                <Button type='default' key="back" onClick={()=>{this.setState({reversalmodal_visible:false})}}>
                Cancel
               </Button>,
                <Button  type='primary' key="ok" onClick={()=>{

                  //make qery now
                  let form_data = new FormData();
                  form_data.append('date',this.state.selected_journal.date);
                  form_data.append('selectedmemberid', this.state.selectedmemberid);
                  form_data.append('journalno', this.state.selected_journal.jnlno);
                  form_data.append('sacco', sacco);
                  form_data.append('username', username);

                  axios.post(serverconfig.backendserverurl+'/customqueries/confirmtransaction_revsersal', form_data, {
                    headers: {
                      'content-type': 'multipart/form-data'
                    }
                    })
                  .then(res => {
                    message.info(res.data.message)

                    //get financial statement again
                    this.searchSales()

                  })
                  .catch(error => console.log(error))

                  this.setState({reversalmodal_visible:false})
                  
                  }}>
                  Confirm reversal
                </Button>
                ]}
                >
                  <div>
                    <h3>Member statement transactions</h3>
                  <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Desc</th>
                          <th>Sub account</th>
                          <th>Debit</th>
                          <th>Credit</th>
                          <th>Journal number</th>
                        </tr>
                      </thead>
                      <tbody>
                      {this.state.tobereversed_transactions.map(
                        (item)=>(
                          <tr>
                          <td>{item.time_stamp}</td>
                          <td>{item.description}</td>
                          <td>{item.subaccount}</td>
                          <td>{<CurrencyFormat value={Number(item.debit).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{<CurrencyFormat value={Number(item.credit).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{item.journal_no} </td>
                          </tr>
                          ))}

                    </tbody>
                </reactstrp.Table>
                <Divider />
                <h3>Loan track transactions</h3>
                <reactstrp.Table bordered>
                              <thead>
                                <tr>
                                  <th>Date</th>
                                  <th>No Of days</th>
                                  <th>Open R/Ln Bal.</th>
                                  <th>Installment Paid</th>
                                  <th>Interest charged</th>
                                  <th>Int Paid</th>
                                  <th>Principle1 (Paid)</th>
                                  <th>Unpaid Int</th>
                                  <th>R/Int</th>
                                  <th>R/Princ or Loan Issued</th>
                                  <th>Outstanding Bal</th>
                                  <th>Payment Details</th>
                                  <th>Journal No</th>

                                </tr>
                              </thead>
                              <tbody>
                              {this.state.loan_txn_report.map(
                                (item)=>(
                                  <tr>
                                  
                                  <td>
                                    <span style={{display:'flex',flexDirection:'column'}}>
                                        {item.date}
                                        <Link to={`/loanissues/${item.loan_reference}`}>View Loan</Link>
                                    </span>

                                  </td>
                                  <td>{item.no_ofdays}</td>
                                  <td>{<CurrencyFormat value={Number(item.open_runningbal).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td>{<CurrencyFormat value={Number(item.installment_paid).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td>{<CurrencyFormat value={Number(item.interest_charged).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td>{<CurrencyFormat value={Number(item.interest_paid).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td>{<CurrencyFormat value={Number(item.principle_paid).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
      
                                  <td>{<CurrencyFormat value={Number(item.interest_unpaid).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td>{<CurrencyFormat value={Number(item.running_int).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                 
                                  <td>{<CurrencyFormat value={Number(item.running_principle).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td>{<CurrencyFormat value={Number(item.outstanding_bal).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td>{item.description}</td>
                                  <td>{item.journal_no}</td>

                                  </tr>
                                ))}
                           
                            </tbody>
                        </reactstrp.Table>
                        <Divider />
                        <h3>General ledger transactions</h3>
                        <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Account</th>
                          <th>Desc</th>
                          <th>User</th>
                          <th>Debit</th>
                          <th>Credit</th>
                          <th>Journal No</th>

                        </tr>
                      </thead>
                      <tbody>
                      {this.state.general_ledger_entriesreport.map(
                        (item)=>(
                          <tr>
                          <td>{item.date}</td>
                          <td>{item.account}</td>
                          <td>{item.description}</td>
                          <td>{item.user}</td>
                          <td>{<CurrencyFormat value={Number(item.Debit).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{<CurrencyFormat value={Number(item.Credit).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{item.journal_no}</td>

                          </tr>
                        ))}
                       
                    </tbody>
                 </reactstrp.Table> 

                  </div>
               </Modal> 

              :
              null
            }

        

            {
              this.state.txndetailsmodal_visible?
              <Modal              
              visible={this.state.txndetailsmodal_visible}
              title={"Journal Transactions details"}
              onCancel={()=>{this.setState({txndetailsmodal_visible:false})}}
              //style={{width:1000}}
              width={1500}
              footer={[
                <Button type='default' key="back" onClick={()=>{this.setState({txndetailsmodal_visible:false})}}>
                Cancel
               </Button>
             
                ]}
                >
                  <div style={{overflowX:'auto'}}>
                    <h3>Journal transactions details</h3>
                    <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Desc</th>
                          <th>Sub account</th>
                          <th>Debit</th>
                          <th>Credit</th>
                          <th>Journal number</th>

                       {
                        this.state.shareno===true?
                        <th>ShN</th>
                        :null
                        }

                        {
                        this.state.shareamount===true?
                        <th>ShA</th>
                        :null
                        }
                        {
                        this.state.savings===true?
                        <th>OS</th>
                        :null
                        }
                        {
                        this.state.savingsinterest===true?
                        <th>OSI</th>
                        :null
                        }      

                        {
                        this.state.supersave===true?
                        <th>SS</th>
                        :null
                        }  

                        {
                        this.state.supersaveinterest===true?
                        <th>SSI</th>
                        :null
                        }    

                        {
                        this.state.loan===true?
                        <th>Ln</th>
                        :null
                        }  
                        {
                        this.state.loaninterest===true?
                        <th>LnI</th>
                        :null
                        }  

                        {
                        this.state.loansecurity===true?
                        <th>LG</th>
                        :null
                        }  

                        {
                        this.state.defdeposit===true?
                        <th>DD</th>
                        :null
                        }  

                        {
                        this.state.teebagoolo===true?
                        <th>TG</th>
                        :null
                        } 

                        {
                        this.state.teebagoolointerest===true?
                        <th>TGI</th>
                        :null
                        } 

                        {/*new enytries*/}
                        {
                        this.state.savingsloanscurity===true?
                        <th>SLS</th>
                        :null
                        }

                        {
                        this.state.supersaveloansecurity===true?
                        <th>SSLS</th>
                        :null
                        }

                        {
                        this.state.teebagoolo_loansecurity===true?
                        <th>TGLS</th>
                        :null
                        }

                        {
                        this.state.sharesloansecurity===true?
                        <th>ShLs</th>
                        :null
                        }

                        </tr>
                      </thead>
                      <tbody>
                      {this.state.tobereversed_transactions.map(
                        (item)=>(
                          <tr>
                          <td>{item.time_stamp}</td>
                          <td>{item.description}</td>
                          <td>{item.subaccount}</td>
                          <td>{<CurrencyFormat value={Number(item.debit).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{<CurrencyFormat value={Number(item.credit).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{item.journal_no} </td>


                          {this.state.shareno===true?
                      <th className="align-right">{item.share_numberbalance}</th>
                    :
                    null
                    }

                    {
                      this.state.shareamount===true?
                      <th className="align-right">
                      <CurrencyFormat value={item.shareamount_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                      </th>
                      :
                      null
                    }

                    {
                      this.state.savings===true?
                      <th className="align-right">
                      <CurrencyFormat value={item.savings_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                      </th>
                      :
                      null
                    }

                    {
                      this.state.savingsinterest===true?
                      <th className="align-right">
                      <CurrencyFormat value={item.saving_interestbalance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                      </th>
                      :
                      null
                    }

                    {
                      this.state.supersave===true?
                      <th className="align-right">
                      <CurrencyFormat value={item.supersaver_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                      </th>
                      :
                      null
                    }

                    {
                      this.state.supersaveinterest===true?
                      <th className="align-right">
                      <CurrencyFormat value={item.supersave_interestbalance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                      </th>
                      :
                      null
                    }

                    {
                      this.state.loan===true?
                      <th className="align-right">
                      <CurrencyFormat value={item.loan_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                      </th>
                      :
                      null
                    }

                    {
                      this.state.loaninterest===true?
                      <th className="align-right">
                      <CurrencyFormat value={item.loan_interestbalance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                      </th>
                      :
                      null
                    }

                    {
                      this.state.loansecurity===true?
                      <th className="align-right">
                      <CurrencyFormat value={item.loan_security_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                      </th>
                      :
                      null
                    }

                    {
                      this.state.defdeposit===true?
                      <th className="align-right">
                      <CurrencyFormat value={item.def_deposit_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                      </th>
                      :
                      null
                    }

                    {
                      this.state.teebagoolo===true?
                      <th className="align-right">
                      <CurrencyFormat value={item.teebagoolo_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                      </th>
                      :
                      null
                    }

                    {
                      this.state.teebagoolointerest===true?
                      <th className="align-right">
                      <CurrencyFormat value={item.teebagoolo_interestbalance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                      </th>
                      :
                      null
                    }

                  {
                      this.state.savingsloanscurity===true?
                      <th className="align-right">
                      <CurrencyFormat value={item.savingsloan_security_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                      </th>
                      :
                      null
                    }

                  {
                      this.state.supersaveloansecurity===true?
                      <th className="align-right">
                      <CurrencyFormat value={item.supersaveloan_security_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                      </th>
                      :
                      null
                    }

                    {
                      this.state.teebagoolo_loansecurity===true?
                      <th className="align-right">
                      <CurrencyFormat value={item.teebagoololoan_security_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                      </th>
                      :
                      null
                    }

                    {
                        this.state.sharesloansecurity===true?
                        <th className="align-right">
                        <CurrencyFormat value={item.shares_security_balance.toFixed(2)} displayType={'text'} thousandSeparator={true}/>
                        </th>
                        :
                        null
                      }


                          </tr>
                          ))}


                    </tbody>
                </reactstrp.Table>
                <Divider />
                <h3>Loan track transactions</h3>
                        <reactstrp.Table bordered>
                              <thead>
                                <tr>
                                  <th>Date</th>
                                  <th>No Of days</th>
                                  <th>Open R/Ln Bal.</th>
                                  <th>Installment Paid</th>
                                  <th>Interest charged</th>
                                  <th>Int Paid</th>
                                  <th>Principle1 (Paid)</th>
                                  <th>Unpaid Int</th>
                                  <th>R/Int</th>
                                  <th>R/Princ or Loan Issued</th>
                                  <th>Outstanding Bal</th>
                                  <th>Payment Details</th>
                                  <th>Journal No</th>

                                </tr>
                              </thead>
                              <tbody>
                              {this.state.loan_txn_report.map(
                                (item)=>(
                                  <tr>
                                    <td>
                                    <span style={{display:'flex',flexDirection:'column'}}>
                                        {item.date}
                                        <Link to={`/loanissues/${item.loan_reference}`}>View Loan</Link>
                                    </span>
                                    </td>


                                  <td>{item.no_ofdays}</td>
                                  <td>{<CurrencyFormat value={Number(item.open_runningbal).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td>{<CurrencyFormat value={Number(item.installment_paid).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td>{<CurrencyFormat value={Number(item.interest_charged).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td>{<CurrencyFormat value={Number(item.interest_paid).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td>{<CurrencyFormat value={Number(item.principle_paid).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
      
                                  <td>{<CurrencyFormat value={Number(item.interest_unpaid).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td>{<CurrencyFormat value={Number(item.running_int).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                 
                                  <td>{<CurrencyFormat value={Number(item.running_principle).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td>{<CurrencyFormat value={Number(item.outstanding_bal).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td>{item.description}</td>
                                  <td>{item.journal_no}</td>

                                  </tr>
                                ))}
                           
                            </tbody>
                        </reactstrp.Table>
                        <Divider />
                        <h3>General ledger transactions</h3>
                        <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Account</th>
                          <th>Desc</th>
                          <th>User</th>
                          <th>Debit</th>
                          <th>Credit</th>
                          <th>Journal No</th>

                        </tr>
                      </thead>
                      <tbody>
                      {this.state.general_ledger_entriesreport.map(
                        (item)=>(
                          <tr>
                          <td>{item.date}</td>
                          <td>{item.account}</td>
                          <td>{item.description}</td>
                          <td>{item.user}</td>
                          <td>{<CurrencyFormat value={Number(item.Debit).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{<CurrencyFormat value={Number(item.Credit).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{item.journal_no}</td>

                          </tr>
                        ))}
                       
                    </tbody>
                 </reactstrp.Table> 

                  </div>
               </Modal> 

              :
              null

            }
          
        </div>)        

      }
   
   }
}

export default FinancialStatementReport; 
