import React,{ useRef } from 'react';
import axios from 'axios'
import { Tooltip,Popover,Result,Table,Popconfirm,Input, Button,Collapse,Card,Avatar,Modal,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { PrinterOutlined, MinusOutlined, CloseCircleFilled, CheckCircleOutlined, UserOutlined } from '@ant-design/icons';
import { DeleteOutlined,SearchOutlined,CameraOutlined,PlusOutlined,EditOutlined,PlusSquareFilled,SaveOutlined,LoadingOutlined } from '@ant-design/icons';
import QrReader from 'react-qr-reader'
import uuid from 'react-uuid'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
import PrintTemplate from 'react-print';
import ReactToPrint from 'react-to-print';
import {
  Col,
  Row,
} from 'reactstrap';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import * as reactstrp from 'reactstrap';
import { ToWords } from 'to-words';

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { Title } = Typography;
const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

const toWords = new ToWords();

message.config({
  top: 200,
  duration: 2,
  maxCount: 3,
  rtl: true,
});




var token= ''
var sacco= ''
var username=''
var bizuserid= ''


class SuperSaveOpeningForm extends React.Component {

  state = {
    date:moment().format(dateFormat).toString(),
    selectedaccount:'',
    selectedaccountobject:{},
    members:[],
    datarequested:true,
    totalamount:0,
    voucher_number:'',
    savingterm:'',
    transactorcontact:'',
    previoustransactions:[],
    datasubmittedsuccessfully:false,
    companyprofile:{},
    accountchange:false,
    userprofile:{},
    transaction_details:'',
    transferamount:0,
    transferfromaccount:'',
    supersaveaccounttype:'',
    supersavertypes:[],
    supersavertype:'',
    supersavertype_obj:{},

    debitaccount:'',
    creditaccount:'',
    companyaccounts: [],
    physical_form:null,
    memberperiodic_balanceobj:{}


  }

  callback(key) {
    console.log(key);
  }

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    
    }else{
       sacco= ''
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
   }else{
      bizuserid= ''
   }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    let form_data1 = new FormData();
    form_data1.append('userid', bizuserid);
    
    //get the user profile here
    axios.post(serverconfig.backendserverurl+'/customqueries/get_user_profile',form_data1,{
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res => {
        this.setState({
          userprofile:res.data
        })
    })


    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })

    axios.get(serverconfig.backendserverurl+`/api/members/?sacco=${sacco}`)
    .then(res => {

        var datalist=[]
        res.data.map((item)=>{
          if(item.AccountNumber=="null" || item.AccountNumber==null || item.AccountNumber==''){
          }else{
            datalist.push(item)
          }
        })

        this.setState({
          members:datalist
        })

    })

    axios.get(serverconfig.backendserverurl+`/api/supersavertype/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          supersavertypes:res.data
        })

       // this.setState({datarequested:false})
    })


    axios.get(serverconfig.backendserverurl+`/api/companyaccounts/?sacco=${sacco}`)
    .then(res => {
        this.setState({
            companyaccounts:res.data
        })
        this.setState({datarequested:false})
    })

  }

  
  ////////////////////////////////////
  // PRODUCT SEARCH SELECT METHODS
   onBlur=()=> {
    console.log('blur');
  }
  
   onFocus=()=>{
    console.log('focus');
  }
  
  onSearch=(val)=> {
    console.log('search:', val);
  }

  //handle client change
  handleselectedaccountChange= (e) =>{

    let form_data1 = new FormData();
    form_data1.append('date',this.state.date); 
    form_data1.append('member_id', e);
    axios.post(serverconfig.backendserverurl+`/customqueries/get_runningbalancesbydate`, form_data1, {
    headers: {
      'content-type': 'multipart/form-data'
    }
    })
    .then(res => {
        this.setState({
          memberperiodic_balanceobj:res.data.my_balance
        })
    })


    this.setState({accountchange:true})
    this.setState({ selectedaccount: e});
    //make query for last transactions
    axios.get(`${serverconfig.backendserverurl}/api/members/${e}`)
      .then(res => {  
          this.setState({selectedaccountobject: res.data})
          this.setState({accountchange:false})
      })

  }

  handlesavingtermChange= (e) => this.setState({ savingterm: e.target.value});
  handletransactorcontactChange= (e) => this.setState({ transactorcontact: e});
  handletransferamountChange= (e) => this.setState({ transferamount: e});


  //submit the deposit now
  handleFormSubmit=(event) =>{
    if (this.checksavingperiod()==false){
      message.error("Saving period is less than the required period.")
    }else if(Number(this.state.transferamount)>this.calculateNetWithdraw()){
      message.error("Savings Amount is less")
    }else if (Number(this.state.memberperiodic_balanceobj.share_numberbalance)<Number(this.state.supersavertype_obj.minimum_ordinaryaccount_shares)){
      message.error("Minimum account shares for supersave is"+String(this.state.supersavertype_obj.minimum_ordinaryaccount_shares))

    }else if(this.state.supersavertype===""){
      message.error("Super save account type cant be empty")
    }else if (this.state.transferamount===0){
      message.error("Trsanfer AMount cant be zero")
    }
    else{
      this.setState({datarequested:true})
      console.log(username)
  
      let form_data = new FormData();
      form_data.append('date',this.state.date);
      form_data.append('transferamount', this.state.transferamount);
      form_data.append('sacco', sacco);
      form_data.append('account', this.state.selectedaccountobject.id);
      form_data.append('userid', bizuserid);
      form_data.append('username', username);
      form_data.append('supersavertype', this.state.supersavertype);
      form_data.append('maturity_date', this.calculate_maturitydate());

      form_data.append('creditaccount', this.state.creditaccount);
      form_data.append('debitaccount', this.state.debitaccount);

      axios.post(serverconfig.backendserverurl+'/customqueries/createasuperccountprofile', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
        })
      .then(res => {
        this.setState({datarequested:false})
        var profile_id=res.data.profile_id

        if(profile_id!=""){
          let form_data1 = new FormData();
          this.state.physical_form==null?
          console.log("No physical file")
          :
          form_data1.append('physical_form', this.state.physical_form, this.state.physical_form?this.state.physical_form.name:"");
        
          axios.put(serverconfig.backendserverurl+`/api/supersaveformuploadprofile/${profile_id}/`, form_data1, {
            headers: {
              'content-type': 'multipart/form-data'
            }
          })
        .then(res =>{console.log("Uploaded form")}).catch(error => console.log(error))
         //uploaded file
        }


        message.info(res.data.message)
        if (res.data.message==="success"){
          this.setState({datasubmittedsuccessfully:true})
        }
        
      })
      .catch(error => console.log(error))
    }
  }

//check empty objecty
isEmptyObject=(obj)=>{
  for ( var name in obj ) {
    return false;
  }
  return true;
}


  checksavingperiod=()=>{
    var m_savingperiod=Number(this.state.supersavertype_obj.minimum_ordinaryaccount_savingperiod)*30
    var dateof_joining=this.state.selectedaccountobject.DateOfJoining

    var datetwo=''
            
    var dateMomentObjectone = moment(dateof_joining, "DD/MM/YYYY"); // 1st argument - string, 2nd argument - format
    var dateObjectone = dateMomentObjectone.toDate();

    var dateMomentObjecttwo = new Date(); // 1st argument - string, 2nd argument - format
    var dateObjecttwo =new Date()

    var date1 = dateObjectone;
    var date2 = dateObjecttwo;

    var Difference_In_Time = date2.getTime() - date1.getTime();
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

    console.log(Difference_In_Days)

    //check if period is bigger than 
    if (Number(Difference_In_Days)<m_savingperiod){
      return false;
    }else{
      return true
    }

  }



  returnsavingsperiod=()=>{
    var Difference_In_Days=0
    var m_savingperiod=Number(this.state.supersavertype_obj.minimum_ordinaryaccount_savingperiod)*30
    var dateof_joining=this.state.selectedaccountobject.DateOfJoining

    var datetwo=''
            
    var dateMomentObjectone = moment(dateof_joining, "DD/MM/YYYY"); // 1st argument - string, 2nd argument - format
    var dateObjectone = dateMomentObjectone.toDate();

    var dateMomentObjecttwo = new Date(); // 1st argument - string, 2nd argument - format
    var dateObjecttwo =new Date()

    var date1 = dateObjectone;
    var date2 = dateObjecttwo;

    var Difference_In_Time = date2.getTime() - date1.getTime();
    Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

    console.log(Difference_In_Days)
    if(this.isEmptyObject(this.state.selectedaccountobject)===false || this.state.selectedaccountobject.DateOfJoining===null){
      return  Math.round(Difference_In_Days) ;
    }else{
      return 0;
    }
  }


    //calculate Netwithdraw
    calculateNetWithdraw=()=>{
      var netwithdraw=0
      var minimumaccountbal=0
      var loan_security=0

      if (this.isEmptyObject(this.state.selectedaccountobject)===false){
         minimumaccountbal=Number(this.state.selectedaccountobject.minimum_account_balance)
         loan_security=Number(this.state.memberperiodic_balanceobj.savingsloan_security_balance)
         netwithdraw=Number(this.state.memberperiodic_balanceobj.savings_balance)-(minimumaccountbal+loan_security)
      }

      return netwithdraw;
    }

    //calculate maturity date
    calculate_maturitydate=()=>{
      var days=Number(this.state.supersavertype_obj.number_of_years)*365
      var m_date=''

      //get the application date
      var dataSplit = this.state.date.split('/');
      var dateConverted;
      if (dataSplit[2].split(" ").length > 1) {

          var hora = dataSplit[2].split(" ")[1].split(':');
          dataSplit[2] = dataSplit[2].split(" ")[0];
          dateConverted = new Date(dataSplit[2], dataSplit[1]-1, dataSplit[0], hora[0], hora[1]);

      } else {
          dateConverted = new Date(dataSplit[2], dataSplit[1] - 1, dataSplit[0]);
      }

      var year = dateConverted.getFullYear();
      var month = dateConverted.getMonth();
      var date = dateConverted.getDate();

      var paydate=new Date(year,month, date+days).toLocaleDateString('en-GB', {day : 'numeric',month : 'numeric',year : 'numeric'}).split(' ').join('/');

      m_date=paydate

      return m_date;
    }
  

  ////////////////////////////////////////////
  // RENDERING METHOD HERE
  render() {

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      if(this.state.datasubmittedsuccessfully===true){
        return(
          <div>


            <Row >
                  <Col xs="12" sm="6" lg="6">
                    <Card>
                    <Result
                    status="success"
                    title="Successfully Account creation "
                    subTitle="Please finish off transaction"
                    extra={[
                      <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
                    ]}
                    />

                  </Card>
                  </Col>

            </Row>

          </div>

        )

      }else{
        return(
          <div>
            <div>
  
              <Row >
                  <Col xs="12" sm="6" lg="6">
                    <Card>
                    <h3>Super save account opening form</h3>

                    {
                      this.state.companyprofile.userbalancesfeature?
                      <h6>User Balance: <CurrencyFormat  value={this.state.userprofile.User_balance} displayType={'text'} thousandSeparator={true} /></h6>
                      :null
                    }

                    <FormItem label="Select Member Ordinary Account">
                        <Select 
                        placeholder="Account No | Name" 
                        style={{  }} 
                        value={this.state.selectedaccount}
                        onChange={this.handleselectedaccountChange} 
                        showSearch
                        optionFilterProp="children"
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        onSearch={this.onSearch}                    
                        >
                          {this.state.members.map(
                            (accnt)=>(
                              <Option value={accnt.id}>{accnt.AccountNumber} | {accnt.FullName}</Option>
                            ))}
                        </Select>
                    </FormItem>
                    {
                      this.state.accountchange?
                      <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
                      <Spin indicator={antIcon} />
                      </div>
                      :
                      null
                    }

  
                    <br></br>
                    <h3 style={{fontWeight:'bold',alignSelf:'center',color:'#154360'}}>SUPERSAVE ACCOUNT OPENING FORM</h3>
                <Form
                      onFinish={(event) => this.handleFormSubmit(event)}
                  >
                    <FormItem label="Date of Application"
                      name='date'
                      rules={[
                        {
                          required: true,
                          message: 'Please Select date',
                        },
                      ]}
                    >
                        <DatePicker value={moment()} onChange={(date, dateString)=>{this.setState({ date: dateString})}} format={dateFormat} />
                    </FormItem>

                  <FormItem label={<h6 style={{fontWeight:'bold'}}>Savings transfer amount </h6>}
                  name="transferamount"
                  rules={[
                    {
                      required: true,
                      message: 'Please input amount',
                    },
                  ]}
                  >

                      <Input name="transferamount" type={'number'} placeholder="transferamount" value={this.state.transferamount} onChange={(val)=>{this.setState({transferamount:val.target.value})}}/>

                  </FormItem>

                  <FormItem label={<h4 style={{fontWeight:'bold',color:'#154360'}}>Total Amount </h4>}
                  >
                    <h3 style={{fontWeight:'bolder'}}><CurrencyFormat  value={this.state.transferamount} displayType={'text'} thousandSeparator={true} /></h3>
                  
                  </FormItem>


                  <h3>Super Saver Account Type</h3>
                  <FormItem label="SuperSaver Account Type"
                      name="supersaveaccounttype"
                      rules={[
                        {
                          required: true,
                          message: 'Please input amount',
                        },
                      ]}
                  >
                      <Select placeholder="Super Saver Type" 
                      style={{  }}
                       value={this.state.supersavertype}
                        onChange={(val)=>{
                          this.setState({supersavertype:val})
                        
                          //get suprsave type object
                          axios.get(`${serverconfig.backendserverurl}/api/supersavertype/${val}`)
                          .then(res => {  
                              this.setState({supersavertype_obj: res.data})
                          })
                        
                        }}
                        showSearch
                        optionFilterProp="children"
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        onSearch={this.onSearch}
                        >
                        {this.state.supersavertypes.map(
                          (type)=>(
                            <Option value={type.id}>{type.account_type_name}</Option>
                          ))}
                      </Select>
                  </FormItem>
                  <h3>Maturity date: {this.calculate_maturitydate()}</h3>


                  <FormItem label={'Debited account'}
                    name="debitaccount"
                    rules={[
                      {
                        required: true,
                        message: 'Please select account',
                      },
                    ]}
                  >
                        <Select 
                        placeholder="Account" 
                        style={{  }} 
                        value={this.state.debitaccount}
                        onChange={(val)=>{this.setState({debitaccount:val})}} 
                        showSearch
                        optionFilterProp="children"
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        onSearch={this.onSearch}                    
                        >
                          {this.state.companyaccounts.map(
                            (accnt)=>(
                              <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
                            ))}
                        </Select>
                    </FormItem>

                    <FormItem label={'Credited account'}
                  name="creditaccount"
                  rules={[
                    {
                      required: true,
                      message: 'Please select account',
                    },
                  ]}
                >
                      <Select 
                      placeholder="Account" 
                      style={{  }} 
                      value={this.state.creditaccount}
                      onChange={(val)=>{this.setState({creditaccount:val})}} 
                      showSearch
                      optionFilterProp="children"
                      onFocus={this.onFocus}
                      onBlur={this.onBlur}
                      onSearch={this.onSearch}                    
                      >
                        {this.state.companyaccounts.map(
                          (accnt)=>(
                            <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
                          ))}
                      </Select>
                  </FormItem>

                  <FormItem label="Physical Application Form" style={{marginRight:3}}>
                  <Input type="file" accept=".pdf" placeholder="Physical Application Form" 
                  
                        onChange={(val)=>{
                          var kilbyes=val.target.files[0].size/1024
                          if (kilbyes>Number(this.state.companyprofile.maximum_upload_document_size)){
                            message.error("File is greater than "+String(this.state.companyprofile.maximum_upload_document_size)+" Kbs .It will not be uploaded.")
                          }else{
                            this.setState({physical_form:val.target.files[0]})
                          }
                          
                          }}/>
                  </FormItem> 


                  <FormItem>
                    <Button  type="primary" htmlType="submit">Submit</Button>
                  </FormItem>
                  </Form>
  
                  </Card>
                   
                  </Col>

                  <Col xs="12" sm="6" lg="6">
                    <Card>
                    <h3 style={{fontWeight:'bold',color:'#154360'}}>Account Details</h3>
                    <h4>Account Number:{this.state.selectedaccountobject.AccountNumber}</h4>
                    <h4>Account Contact:{this.state.selectedaccountobject.RegisteredPhoneNumber1}</h4>
                    <h4>Account Name:{this.state.selectedaccountobject.FullName}</h4>
                    <h4>Savings Balance:<CurrencyFormat value={this.state.memberperiodic_balanceobj.savings_balance} displayType={'text'} thousandSeparator={true}  /> </h4>
                    <h4>Net transfer:<CurrencyFormat value={this.calculateNetWithdraw()} displayType={'text'} thousandSeparator={true}  /> </h4>

                    <h4>Shares balance:<CurrencyFormat value={this.state.memberperiodic_balanceobj.share_numberbalance} displayType={'text'} thousandSeparator={true}  /> </h4>

                    <h4>Saving period: {this.returnsavingsperiod()} days</h4>
                  </Card></Col>
               </Row>
            </div>
      
         </div>                    
       )
      }
     
    }
   
  }
}

export default SuperSaveOpeningForm; 
