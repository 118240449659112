import React from 'react';
import WithdrawRequstionForm from './WithdrawRequistionForm'
import axios from 'axios'
import { Table, Input, Button,Collapse,Popover,Popconfirm,message,Spin,Tooltip,Modal,Select,Form } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DeleteOutlined,FundViewOutlined,LoadingOutlined,EditOutlined,CheckCircleFilled,DownloadOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import * as serverconfig from '../serverconn'
import CurrencyFormat from 'react-currency-format';

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { TextArea } = Input;
const FormItem=Form.Item;
const { Option } = Select;

function callback(key) {
  console.log(key);
}


var token= ''
var sacco= ''
var bizuserid= ''

class WithdrawRequistionsList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    withdraw_requistion: [],
    datarequested:true,
    reviewer_remarks:'',
    selected_requi:{},
    selected_requi_id:'',
    userrights: {},
    review_modal_visible:false,
    preview_action:'',
    approval_modal_visible:false,
    approval_remarks:'',


  };

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
   }else{
       bizuserid= ''
   }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/withdraw_requistion/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          withdraw_requistion:res.data
        })

        this.setState({datarequested:false})

    })


    axios.get(serverconfig.backendserverurl+`/api/accountrights/?user=${bizuserid}`)
    .then(res => {
        this.setState({
          userrights:res.data[0]
        })
    })
}

    //////////////////////////////////////////////////
  //handle delete here
  handleDelete = id => {

     let form_data1 = new FormData();
     form_data1.append('itemid', id);
     form_data1.append('sacco', sacco);
     form_data1.append('table_model', "StaffRole");
   
     axios.post(`${serverconfig.backendserverurl}/customqueries/puttorecyclebin`, form_data1, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }).then(res =>{
        window.location.reload(false)
        message.info('Operation successful')
      }
      );

  };

  

getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};

  render() {
    const columns = [
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'id',
        ...this.getColumnSearchProps('date'),
        render:(text,record)=>
        <h3 style={{color:
        record.status==="pending"?
        "yellow"
        :record.status==="reviewed"?
        "orange"
        :record.status==="declined"?
        "red": "green"}}
        >
          {
            record.status==="reviewed" || record.status==="declined"?
            <Tooltip title={record.reviewer_remarks}> {record.date}</Tooltip>
            :
            <Tooltip title={record.initiator_remarks}> {record.date}</Tooltip>
          }
        </h3>
          
      },
      {
        title: 'Account name',
        dataIndex: 'FullName',
        key: 'id',
        ...this.getColumnSearchProps('FullName'),
      },
      {
        title: 'Account number',
        dataIndex: 'AccountNumber',
        key: 'id',
        ...this.getColumnSearchProps('AccountNumber'),
      },
      {
        title: 'Request amount',
        dataIndex: 'request_amount',
        key: 'id',
        render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
      },
      {
        title: 'Withdraw type',
        dataIndex: 'withdraw_type',
        key: 'id',
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'id',
      },

      {
        title: 'Approvals',
        dataIndex: 'id',
        key: 'id',
        render: (text,record) =>
        <div>
          {
            record.approvals_list===null?
            null
            :
            JSON.parse(record.approvals_list).map((item)=>{
              return (<p>* {item.user} </p>)
            })
          }
        </div>
      },

      {
        title: 'Action',
        dataIndex: 'id',
        key: 'id',
        render: (text,record) =>
        <p>

      {
          this.state.userrights.can_initiate_withdraw===true?
        <Popover content={<p>Click here to edit,view details</p>} title="Edit,View">
         <Link to={`/withdraw_requistion_details/${text}`}><EditOutlined style={{color:'blue'}}/></Link>
        </Popover>:
        null
      }

        &nbsp;&nbsp;&nbsp;
        {
          this.state.userrights.can_review_withdraw===true && record.status!="reviewed" && record.status!="approved" ?
          <Popover content={<p>Click here to create review</p>} title="Review">
          <FundViewOutlined style={{color:'blue'}} 
            onClick={
            ()=>{
              this.setState({selected_requi_id:record.id})
              this.setState({selected_requi:record.id})
              this.setState({review_modal_visible:true})
            }
          }
          
          />
         </Popover>
          :
          null
        }

      &nbsp;&nbsp;&nbsp;
      {
          this.state.userrights.can_approve_withdraw===true && (record.status=="reviewed" || record.status=="approved")  ?
          <Popover content={<p>Click here to approve withdrwal</p>} title="Approve">
          <CheckCircleFilled style={{color:'green'}} 
            onClick={
            ()=>{
              var useralreay_aproved=false

                if (record.approvals_list===null){
                  useralreay_aproved=false
                }else{
                  JSON.parse(record.approvals_list).map((item)=>{
                    if(Number(item.user_id)===Number(bizuserid)){
                      useralreay_aproved=true
                    }else{
                      useralreay_aproved=false
                    }
                  })

                }

              if(useralreay_aproved===true ){
                message.error("You have already approved this withdrawal")
              }else{
                this.setState({selected_requi_id:record.id})
                this.setState({selected_requi:record.id})
                this.setState({approval_modal_visible:true})
              }
            }
          }
          
          />
         </Popover>
          :
          null
        }
      &nbsp;&nbsp;&nbsp;

      {
        record.physical_form?
        <Popover content={<p>Click here to download physical form</p>} title="Download">
        <a href={record.physical_form}><DownloadOutlined style={{color:'green',fontSize: '20px'}}/></a> 
        </Popover>
        :
        null
      }
      </p>,

        
      },
      {
        title: 'Withdrawn?',
        dataIndex: 'withdrawn',
        key: 'id',
        render: (text,record) =><p>
          {record.withdrawn===true?
          "Yes"
          :
          "No"
          }
        </p>

      },

      {
        title: 'Extra action',
        dataIndex: 'id',
        key: 'id',
        render: (text,record) =>
        <p>

          {
            record.withdrawn===false?
            <Popover content={<p>Click here to delete client</p>} title="Delete">
            <Popconfirm title="Sure to delete?"
             onConfirm={() => {
              axios.delete(`${serverconfig.backendserverurl}/api/withdraw_requistion/${text}`).then(res =>{
                window.location.reload(false)
                message.info("successfully deleted") 
               }
               );
    
             }}>
              <DeleteOutlined style={{color:'red'}}/>
             </Popconfirm>
            </Popover>
            :
            null

          }
  

        </p>,
      }


    ];

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )
    }else{
      return(
        <div>
            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header="WITHDRAW REQUISTIONS" key="1">
            <Table 
            columns={columns}
            pagination={{showQuickJumper:true,showSizeChanger:true }}
            dataSource={this.state.withdraw_requistion} 
            scroll={{ x: 1000 }}
            bordered/>
            </Panel>
            </Collapse>
            <br />

            {
              this.state.userrights.can_initiate_withdraw===true?
              <div>
              <h3 style={{color:'green'}}>Withdraw requistion form.</h3>
              <WithdrawRequstionForm /> 
              </div>
              :
              null
            }

            <Modal              
              visible={this.state.review_modal_visible}
              title={"Withdraw requistion review Form"}
              onCancel={()=>{this.setState({review_modal_visible:false})}}
              style={{width:1000}}
              footer={[
                <Button key="back" onClick={()=>{this.setState({review_modal_visible:false})}}>
                  Cancel
                </Button>,
                  <Button key="ok" type='primary' 
                    onClick={()=>{
                      let form_data1 = new FormData();
                      form_data1.append('requiid', this.state.selected_requi_id);
                      form_data1.append('reviewer_remarks', this.state.reviewer_remarks);
                      form_data1.append('preview_action', this.state.preview_action);

                      axios.post(`${serverconfig.backendserverurl}/customqueries/createwithdrawreq_review`, form_data1, {
                         headers: {
                           'content-type': 'multipart/form-data'
                         }
                       }).then(res =>{
                         window.location.reload(false)
                         message.info('Operation successful')
                       }
                       );

                      this.setState({review_modal_visible:false})
                    }}>
                  Submit review
                </Button>
                ]}
                >
                  
                <FormItem label="Review remarks">
                <TextArea
                      placeholder="review remarks"
                      autoSize={{ minRows: 2, maxRows: 8 }}
                      value={this.state.reviewer_remarks}
                      onChange={(val)=>{this.setState({reviewer_remarks:val.target.value})}}
                    />        
                </FormItem>   

                <FormItem label="Select preview action">
                  <Select 
                  placeholder="Select preview action" 
                  style={{  }} 
                  value={this.state.preview_action}
                  onChange={(val)=>{
                    this.setState({preview_action:val})
                  }} 
                  showSearch
                  optionFilterProp="children"
                                        
                  >
                  <Option value="sendforapproval"> Send for approval  </Option>
                  <Option value="decline_withdrawal"> Decline withdraw</Option>
                      
                  </Select>
                </FormItem>
      
               </Modal> 

               <Modal              
              visible={this.state.approval_modal_visible}
              title={"Withdraw requistion approval Form"}
              onCancel={()=>{this.setState({approval_modal_visible:false})}}
              style={{width:1000}}
              footer={[
                <Button key="back" onClick={()=>{this.setState({approval_modal_visible:false})}}>
                  Cancel
                </Button>,
                  <Button key="ok" type='primary' 
                    onClick={()=>{
                      let form_data1 = new FormData();
                      form_data1.append('requiid', this.state.selected_requi_id);
                      form_data1.append('approval_remarks', this.state.approval_remarks);
                      form_data1.append('bizuserid', bizuserid);

                      axios.post(`${serverconfig.backendserverurl}/customqueries/createwithdrawreq_approval`, form_data1, {
                         headers: {
                           'content-type': 'multipart/form-data'
                         }
                       }).then(res =>{
                         window.location.reload(false)
                         message.info('Operation successful')
                       }
                       );

                      this.setState({approval_modal_visible:false})
                    }}>
                  Approve
                </Button>
                ]}
                >
                  
                <FormItem label="Approval remarks">
                <TextArea
                      placeholder="approval remarks"
                      autoSize={{ minRows: 2, maxRows: 8 }}
                      value={this.state.approval_remarks}
                      onChange={(val)=>{this.setState({approval_remarks:val.target.value})}}
                    />        
                </FormItem>   

               </Modal> 
        </div>
    )

    }
 
  }
}

export default WithdrawRequistionsList; 
