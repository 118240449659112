import React,{useState,useEffect,lazy} from 'react';
import axios from 'axios'
import {  Slider,Statistic,Tabs,Modal } from 'antd';
import { ArrowUpOutlined,MinusCircleFilled,ToolOutlined, ArrowDownOutlined,FileFilled,WalletFilled,SearchOutlined,FundViewOutlined,
   UserOutlined, AccountBookOutlined,CalculatorFilled,SwapOutlined,GroupOutlined,AccountBookFilled, DollarCircleOutlined,EditFilled,MessageFilled,DollarOutlined,CarFilled,LoadingOutlined,PlusCircleFilled } from '@ant-design/icons';
import moment from 'moment';
import {withGetScreen} from 'react-getscreen'
import { Popover,Table,Popconfirm,Input, Button,Collapse,Card,Avatar,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Spin } from 'antd';
import {
  Col,
  Row,
} from 'reactstrap';
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';
import { getStyle, hexToRgba } from '@coreui/coreui/dist/js/coreui-utilities'
import * as serverconfig from '../serverconn'
import * as serversocketconfig from '../serversocketconfig'
import { MdShoppingCart } from 'react-icons/md';

import CurrencyFormat from 'react-currency-format';
import Highlighter from 'react-highlight-words';
import Websocket from 'react-websocket';
import { Link } from 'react-router-dom';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { NavLink } from 'react-router-dom'
import  MemberForm from '../components/MemberForm'
import  LoanCalculator from '../components/LoanCalculator'
import * as primarycolor from '../primarycolor'
import QuickMenu from './QuickMenu'

import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto'; // This imports everything you need automatically


var CryptoJS = require("crypto-js");

const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;


const style = { background: '#0092ff', padding: '8px 0',borderRadius: '7px',justifyContent:'center', alignItems:'center',display: 'flex', };
const dateFormat = 'DD/MM/YYYY';
const yeardateFormat = 'YYYY';
const monthdateFormat = 'MM/YYYY';

const { Meta } = Card;
const { Panel } = Collapse;
const { TabPane } = Tabs;
const FormItem=Form.Item;
const { Option } = Select;

var bizuserid= ''
var sacco= ''
var token= ''


class LoanAgingStatistics extends React.Component {

  state = {
    currentmonth:moment().format(monthdateFormat).toString(),
    companyprofile:{},
    datarequested:true,
    userprofile:{},
    categories_summary:[]

  };



  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
   }else{
      bizuserid= ''
   }
    
    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

   axios.get(serverconfig.backendserverurl+`/api/accounts/${bizuserid}`)
   .then(res => {
       this.setState({
        userprofile:res.data
       })
   })



   let form_data_1 = new FormData();
   form_data_1.append('sacco', sacco);
   form_data_1.append('date', moment().format(dateFormat).toString());

   //Now submit sale data to database
   axios.post(serverconfig.backendserverurl+'/customqueries/getLoanRepaymentStatusReport_byperiod', form_data_1,{
   headers: {
     'content-type': 'multipart/form-data'
   }
   })
   .then(res =>{
    console.log(JSON.stringify(JSON.parse(res.data.categories_summary)))
     this.setState({categories_summary:JSON.parse(res.data.categories_summary)})
     this.setState({datarequested:false})
   } 
   )
   .catch(error => console.log(error)) 

}




render() {


    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin size="large">
              <div className="content">
                <h3>Pisacco</h3>
              </div>
               </Spin>        
        </div>
      )
  
    }else{


            // Category labels for each key
            const categoryLabels = {
              A: 'Advance Payment',
              B: '0-29 Days',
              C: '30-59 Days',
              D: '60-89 Days',
              E: '90 Days and Above',
            };

            // Color codes for each category
            const colors = {
              A: 'green',
              B: 'lightgreen',
              C: 'yellow',
              D: 'orange',
              E: 'red',
            };

            var categories_summary=this.state.categories_summary



            const chartData = {
              labels: categories_summary.map(item => categoryLabels[item.Category]),
              datasets: [
                {
                  label: 'Total Principle',
                  data: categories_summary.map(item => item.total_principle),
                  backgroundColor: categories_summary.map(item => colors[item.Category]),
                },
                {
                  label: 'Total Arrears',
                  data: categories_summary.map(item => item.total_arrears),
                  backgroundColor: categories_summary.map(item => colors[item.Category]),
                },
                {
                  label: 'Total Members',
                  data: categories_summary.map(item => item.total_no),
                  backgroundColor: categories_summary.map(item => colors[item.Category]),
                  borderColor: categories_summary.map(item => colors[item.Category]),
                  type: 'line',
                  borderWidth: 2,
                  fill: false,
                },
              ],
            };
        
            const options = {
              responsive: true,
              plugins: {
                legend: {
                  position: 'top',
                },
                title: {
                  display: true,
                  text: 'Total Principle, Arrears, and Members by Aging Category',
                },
              },
              scales: {
                x: {
                  beginAtZero: true,
                },
                y: {
                  beginAtZero: true,
                },
              },
            };


      return (
      <div className="animated fadeIn"> 
        <Bar data={chartData} options={options} />

      </div>
      );

    }
    
  
 

}
}

export default LoanAgingStatistics; 
