import React from 'react';
import { Form, Input, Button,Select,Spin,Switch } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var sacco= ''
var token= ''


class LoanTypeForm extends React.Component {

  state = {
    loan_type_name:'',
    loan_form_fees:0,
    loan_assessment_fees:0,
    loan_insurance_fee:0,
    loan_commitment_fee:0,
    loan_agreement_fees:0,
    reserve_fees:0,
    interest_rate:0,
    maximum_loan_amount:0,
    maximum_loan_amount_first_time_borrower:0,
    maximum_period:0,
    maximum_period_first_time_borrower:0,
    percentage_payment_of_currentloan_before_top_up:0,

    interestrate_type:'flatinterest',
    loan_primary_schedule:'monthly',

    guarantor_totalpercentage:0,
    borrowing_percentagelimit_oftotal_liquidity:0,
    borrowing_percentagelimit_oftotal_nonliquidity:0,

    nooftimes_membergurantees:0,
    limit_on_friendguarantors:0,
    has_presetperiod:false,
    member_existence_days_bf_loanapllication:0,
    no_of_approvers:0,

    account_tocreditloanprinciple_ondeposit:'',
    account_tocreditloaninterest_ondeposit:'',
    account_todebitloaninterest_ondeposit:'',

    account_todebit_onloan_onloanissuance:'',
    account_tocredit_onloanfees_onloanissuance:'',
    account_tocredit_onbankcharge_onloanissuance:'',
    account_todebit_onloan_onallcharges:'',

    companyaccounts:[],


  }

  componentDidMount(){

    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }


    axios.get(serverconfig.backendserverurl+`/api/companyaccounts/?sacco=${sacco}`)
    .then(res => {
        this.setState({
            companyaccounts:res.data
        })
        this.setState({datarequested:false})
    })

    
}


  //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})

    let form_data = new FormData();
    form_data.append('loan_type_name', this.state.loan_type_name);
    form_data.append('loan_form_fees', this.state.loan_form_fees);
    form_data.append('loan_assessment_fees', this.state.loan_assessment_fees);
    form_data.append('loan_insurance_fee', this.state.loan_insurance_fee);
    form_data.append('loan_commitment_fee', this.state.loan_commitment_fee);
    form_data.append('loan_agreement_fees', this.state.loan_agreement_fees);
    form_data.append('reserve_fees', this.state.reserve_fees);
    form_data.append('interest_rate', this.state.interest_rate);
    form_data.append('maximum_loan_amount', this.state.maximum_loan_amount);
    form_data.append('maximum_loan_amount_first_time_borrower', this.state.maximum_loan_amount_first_time_borrower);
    form_data.append('maximum_period', this.state.maximum_period);
    form_data.append('maximum_period_first_time_borrower', this.state.maximum_period_first_time_borrower);
    form_data.append('percentage_payment_of_currentloan_before_top_up', this.state.percentage_payment_of_currentloan_before_top_up);
    form_data.append('sacco', sacco);

    form_data.append('interestrate_type', this.state.interestrate_type);
    form_data.append('loan_primary_schedule', this.state.loan_primary_schedule);

    form_data.append('guarantor_totalpercentage', this.state.guarantor_totalpercentage);
    form_data.append('borrowing_percentagelimit_oftotal_liquidity', this.state.borrowing_percentagelimit_oftotal_liquidity);
    form_data.append('borrowing_percentagelimit_oftotal_nonliquidity', this.state.borrowing_percentagelimit_oftotal_nonliquidity);

    form_data.append('nooftimes_membergurantees', this.state.nooftimes_membergurantees);
    form_data.append('limit_on_friendguarantors', this.state.limit_on_friendguarantors);
    form_data.append('has_presetperiod', this.state.has_presetperiod);
    form_data.append('member_existence_days_bf_loanapllication', this.state.member_existence_days_bf_loanapllication);
    form_data.append('no_of_approvers', this.state.no_of_approvers);

    form_data.append('account_tocreditloanprinciple_ondeposit', this.state.account_tocreditloanprinciple_ondeposit);
    form_data.append('account_tocreditloaninterest_ondeposit', this.state.account_tocreditloaninterest_ondeposit);
    form_data.append('account_todebitloaninterest_ondeposit', this.state.account_todebitloaninterest_ondeposit);

    form_data.append('account_todebit_onloan_onloanissuance', this.state.account_todebit_onloan_onloanissuance);
    form_data.append('account_tocredit_onloanfees_onloanissuance', this.state.account_tocredit_onloanfees_onloanissuance);
    form_data.append('account_tocredit_onbankcharge_onloanissuance', this.state.account_tocredit_onbankcharge_onloanissuance);
    form_data.append('account_todebit_onloan_onallcharges', this.state.account_todebit_onloan_onallcharges);


    axios.post(serverconfig.backendserverurl+'/api/loantypes/', form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  .then(res =>         this.setState({datarequested:false})
,  window.location.reload(false)
  )
  .catch(error => console.log(error))

  }

 
 
  handleloan_type_nameChange= (e) => this.setState({ loan_type_name: e.target.value});
  handleloan_form_feesChange= (e) => this.setState({ loan_form_fees: e.target.value});
  handleloan_assessment_feesChange= (e) => this.setState({ loan_assessment_fees: e.target.value});
  handleloan_insurance_feeChange= (e) => this.setState({ loan_insurance_fee: e.target.value});
  handleloan_commitment_feeChange= (e) => this.setState({ loan_commitment_fee: e.target.value});
  handleloan_agreement_feesChange= (e) => this.setState({ loan_agreement_fees: e.target.value});
  handlereserve_feesChange= (e) => this.setState({ reserve_fees: e.target.value});
  handleinterest_rateChange= (e) => this.setState({ interest_rate: e.target.value});
  handlemaximum_loan_amountChange= (e) => this.setState({ maximum_loan_amount: e.target.value});
  handlemaximum_loan_amount_first_time_borrowerChange= (e) => this.setState({ maximum_loan_amount_first_time_borrower: e.target.value});
  handlemaximum_periodChange= (e) => this.setState({ maximum_period: e.target.value});
  handlemaximum_period_first_time_borrowerChange= (e) => this.setState({ maximum_period_first_time_borrower: e.target.value});
  handlepercentage_payment_of_currentloan_before_top_upChange= (e) => this.setState({ percentage_payment_of_currentloan_before_top_up: e.target.value});


  onBlur=()=> {
    console.log('blur');
  }
  
   onFocus=()=>{
    console.log('focus');
  }
  
  onSearch=(val)=> {
    console.log('search:', val);
  }


  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{

      return (
        <div>
          <Form
            onFinish={(event) => this.handleFormSubmit(event)} 
          >
            <FormItem label="Loan Type Name"
              name="loan_type_name"
              rules={[
                {
                  required: true,
                  message: 'Please enter name',
                },
              ]}             
            >
              <Input name="loan_type_name"  placeholder="Put a name here." value={this.state.loan_type_name} onChange={this.handleloan_type_nameChange} />
            </FormItem>

            <FormItem label="Loan Form Fees"
              name="loan_form_fees"
              rules={[
                {
                  required: true,
                  message: 'Please enter values',
                },
              ]}           
            >
              <Input name="loan_form_fees"  placeholder="Block Figure eg 1000" value={this.state.loan_form_fees} onChange={this.handleloan_form_feesChange} />
            </FormItem>


            <FormItem label="Annual Interest Rate"
              name="interest_rate"
              rules={[
                {
                  required: true,
                  message: 'Please enter values',
                },
              ]}           
            >
              <Input name="interest_rate"  placeholder="Enter value" value={this.state.interest_rate} onChange={this.handleinterest_rateChange} />
            </FormItem>

            <FormItem label="Maximum Loan Amount"
              name="maximum_loan_amount"
              rules={[
                {
                  required: true,
                  message: 'Please enter values',
                },
              ]}           
            >
              <Input name="maximum_loan_amount"  placeholder="0.00" value={this.state.maximum_loan_amount} onChange={this.handlemaximum_loan_amountChange} />
            </FormItem>

            <FormItem label="Maximum Loan Amount (First Time Borrower)"
              name="maximum_loan_amount_first_time_borrower"
              rules={[
                {
                  required: true,
                  message: 'Please enter values',
                },
              ]}           
            >
              <Input name="maximum_loan_amount_first_time_borrower"  placeholder="0.00" value={this.state.maximum_loan_amount_first_time_borrower} onChange={this.handlemaximum_loan_amount_first_time_borrowerChange} />
            </FormItem>


            <FormItem label="Maximum Loan Period"
              name="maximum_period"
              rules={[
                {
                  required: true,
                  message: 'Please enter values',
                },
              ]}           
            >
              <Input name="maximum_period"  placeholder="0" value={this.state.maximum_period} onChange={this.handlemaximum_periodChange} />
            </FormItem>

            <FormItem label="Maximum Loan Period (First Time Borrower)"
              name="maximum_period_first_time_borrower"
              rules={[
                {
                  required: true,
                  message: 'Please enter values',
                },
              ]}           
            >
              <Input name="maximum_period_first_time_borrower"  placeholder="0.00" value={this.state.maximum_period_first_time_borrower} onChange={this.handlemaximum_period_first_time_borrowerChange} />
            </FormItem>


            <FormItem label="Percentage Payment Before Top App"
              name="percentage_payment_of_currentloan_before_top_up"
              rules={[
                {
                  required: true,
                  message: 'Please enter values',
                },
              ]}           
            >
              <Input name="percentage_payment_of_currentloan_before_top_up"  placeholder="Enter converted value eg 0.5 for 50%" value={this.state.percentage_payment_of_currentloan_before_top_up} onChange={this.handlepercentage_payment_of_currentloan_before_top_upChange} />
            </FormItem>


            <FormItem 
                label="Interest rate Type"
                >
                    <Select 
                    value={this.state.interestrate_type} 
                    onChange={(val)=>{this.setState({interestrate_type:val})}}
                    placeholder="Interest rate Type"
                    
                    showSearch
                    optionFilterProp="children"
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onSearch={this.onSearch}    
                    
                    >
                      <Option value='flatinterest'>Flat Interest</Option>
                      <Option value='reducingBalanceInterest'>Reducing Balance</Option>
                    </Select>
            </FormItem>

            <FormItem 
                label="Loan Primary Schedule (Interest per day/weekly/monthly)"
                >
                    <Select 
                    value={this.state.loan_primary_schedule} 
                    onChange={(val)=>{this.setState({loan_primary_schedule:val})}}
                    placeholder="Loan Primary Schedule"
                    
                    showSearch
                    optionFilterProp="children"
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onSearch={this.onSearch}    
                    
                    
                    >
                      <Option value='monthly'>Monthly</Option>
                      <Option value='weekly'>Weekly</Option>
                      <Option value='daily'>Daily</Option>
                    </Select>
            </FormItem>


            <FormItem label="Guarantor's total Percentage of owner's amount"
              name="guarantor_totalpercentage"
              rules={[
                {
                  required: true,
                  message: 'Please enter values',
                },
              ]}           
            >
              <Input name="guarantor_totalpercentage"  placeholder="0" value={this.state.guarantor_totalpercentage} onChange={(val)=>{this.setState({guarantor_totalpercentage:val.target.value})}} />
            </FormItem>


            <FormItem label="Percentage limit on borrowed amount or collateral (SS,OS, Shs and TB)"
              name="borrowing_percentagelimit_oftotal_liquidity"
              rules={[
                {
                  required: true,
                  message: 'Please enter values',
                },
              ]}           
            >
              <Input name="borrowing_percentagelimit_oftotal_liquidity"  placeholder="0" value={this.state.borrowing_percentagelimit_oftotal_liquidity} onChange={(val)=>{this.setState({borrowing_percentagelimit_oftotal_liquidity:val.target.value})}} />
            </FormItem>

            <FormItem label="Percentage limit on borrowed amount or collateral (Land and others)"
              name="borrowing_percentagelimit_oftotal_nonliquidity"
              rules={[
                {
                  required: true,
                  message: 'Please enter values',
                },
              ]}           
            >
              <Input name="borrowing_percentagelimit_oftotal_nonliquidity"  placeholder="0" value={this.state.borrowing_percentagelimit_oftotal_nonliquidity} onChange={(val)=>{this.setState({borrowing_percentagelimit_oftotal_nonliquidity:val.target.value})}} />
            </FormItem>



            <FormItem label="Number of times a member guarantees Others"
              name="nooftimes_membergurantees"
              rules={[
                {
                  required: true,
                  message: 'Please enter values',
                },
              ]}           
            >
              <Input name="nooftimes_membergurantees"  placeholder="0" value={this.state.nooftimes_membergurantees} onChange={(val)=>{this.setState({nooftimes_membergurantees:val.target.value})}} />
            </FormItem>


            <FormItem label="Limit on friends guarantors"
              name="limit_on_friendguarantors"
              rules={[
                {
                  required: true,
                  message: 'Please enter values',
                },
              ]}           
            >
              <Input name="limit_on_friendguarantors"  placeholder="0" value={this.state.limit_on_friendguarantors} onChange={(val)=>{this.setState({limit_on_friendguarantors:val.target.value})}} />
            </FormItem>

            <FormItem label="Preset Period (Months,days or weeks)"
              name="preset_period"
              rules={[
                {
                  required: true,
                  message: 'Please enter values',
                },
              ]}           
            >
              <Input name="preset_period"  placeholder="0" value={this.state.preset_period} onChange={(val)=>{this.setState({preset_period:val.target.value})}} />
            </FormItem>

            <FormItem label="Member existence period before loan application (days)"
              name="member_existence_days_bf_loanapllication"
              rules={[
                {
                  required: true,
                  message: 'Please enter values',
                },
              ]}           
            >
              <Input name="member_existence_days_bf_loanapllication"  placeholder="0" value={this.state.member_existence_days_bf_loanapllication} onChange={(val)=>{this.setState({member_existence_days_bf_loanapllication:val.target.value})}} />
            </FormItem>

            <FormItem label="Number of loan approvers"
              name="no_of_approvers"
              rules={[
                {
                  required: true,
                  message: 'Please enter values',
                },
              ]}           
            >
              <Input  placeholder="0" value={this.state.no_of_approvers} onChange={(val)=>{this.setState({no_of_approvers:val.target.value})}} />
            </FormItem>


            <h3>Ledger account settings</h3>


            <FormItem label={"Account_tocreditloanprinciple_ondeposit"}>
                  <Select 
                  placeholder="Account" 
                  style={{  }} 
                  value={this.state.account_tocreditloanprinciple_ondeposit}
                  onChange={(val)=>{this.setState({account_tocreditloanprinciple_ondeposit:val})}} 
                  showSearch
                  optionFilterProp="children"
                  onFocus={this.onFocus}
                  onBlur={this.onBlur}
                  onSearch={this.onSearch}                    
                  >
                    {this.state.companyaccounts.map(
                      (accnt)=>(
                        <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
                      ))}
                  </Select>
              </FormItem>


              <FormItem label={"Account_tocreditloaninterest_ondeposit"}>
                  <Select 
                  placeholder="Account" 
                  style={{  }} 
                  value={this.state.account_tocreditloaninterest_ondeposit}
                  onChange={(val)=>{this.setState({account_tocreditloaninterest_ondeposit:val})}} 
                  showSearch
                  optionFilterProp="children"
                  onFocus={this.onFocus}
                  onBlur={this.onBlur}
                  onSearch={this.onSearch}                    
                  >
                    {this.state.companyaccounts.map(
                      (accnt)=>(
                        <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
                      ))}
                  </Select>
              </FormItem>



              <FormItem label={"Account_todebitloaninterest_ondeposit"}>
                  <Select 
                  placeholder="Account" 
                  style={{  }} 
                  value={this.state.account_todebitloaninterest_ondeposit}
                  onChange={(val)=>{this.setState({account_todebitloaninterest_ondeposit:val})}} 
                  showSearch
                  optionFilterProp="children"
                  onFocus={this.onFocus}
                  onBlur={this.onBlur}
                  onSearch={this.onSearch}                    
                  >
                    {this.state.companyaccounts.map(
                      (accnt)=>(
                        <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
                      ))}
                  </Select>
              </FormItem>

            <FormItem label={"Account_todebit_onloan_onloanissuance"}>
                  <Select 
                  placeholder="Account" 
                  style={{  }} 
                  value={this.state.account_todebit_onloan_onloanissuance}
                  onChange={(val)=>{this.setState({account_todebit_onloan_onloanissuance:val})}} 
                  showSearch
                  optionFilterProp="children"
                  onFocus={this.onFocus}
                  onBlur={this.onBlur}
                  onSearch={this.onSearch}                    
                  >
                    {this.state.companyaccounts.map(
                      (accnt)=>(
                        <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
                      ))}
                  </Select>
              </FormItem>

              <FormItem label={"Account_todebit_onloan_onallcharges"}>
                  <Select 
                  placeholder="Account" 
                  style={{  }} 
                  value={this.state.account_todebit_onloan_onallcharges}
                  onChange={(val)=>{this.setState({account_todebit_onloan_onallcharges:val})}} 
                  showSearch
                  optionFilterProp="children"
                  onFocus={this.onFocus}
                  onBlur={this.onBlur}
                  onSearch={this.onSearch}                    
                  >
                    {this.state.companyaccounts.map(
                      (accnt)=>(
                        <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
                      ))}
                  </Select>
              </FormItem>


              <FormItem label={"Account_tocredit_onloanfees_onloanissuance"}>
                  <Select 
                  placeholder="Account" 
                  style={{  }} 
                  value={this.state.account_tocredit_onloanfees_onloanissuance}
                  onChange={(val)=>{this.setState({account_tocredit_onloanfees_onloanissuance:val})}} 
                  showSearch
                  optionFilterProp="children"
                  onFocus={this.onFocus}
                  onBlur={this.onBlur}
                  onSearch={this.onSearch}                    
                  >
                    {this.state.companyaccounts.map(
                      (accnt)=>(
                        <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
                      ))}
                  </Select>
              </FormItem>


              <FormItem label={"Account_tocredit_onbankcharge_onloanissuance"}>
                  <Select 
                  placeholder="Account" 
                  style={{  }} 
                  value={this.state.account_tocredit_onbankcharge_onloanissuance}
                  onChange={(val)=>{this.setState({account_tocredit_onbankcharge_onloanissuance:val})}} 
                  showSearch
                  optionFilterProp="children"
                  onFocus={this.onFocus}
                  onBlur={this.onBlur}
                  onSearch={this.onSearch}                    
                  >
                    {this.state.companyaccounts.map(
                      (accnt)=>(
                        <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
                      ))}
                  </Select>
              </FormItem>

            <FormItem>
              <Button  type="primary" htmlType="submit">Create</Button>
            </FormItem>
          </Form>
        </div>
      );

    }

  }

}


export default LoanTypeForm;

