import React from 'react';
import AccountTypeForm from '../components/AccountTypeForm'

import axios from 'axios'
import { Table,Tabs, Input, Button,Collapse,Popover,Popconfirm,message,Spin } from 'antd';

import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DownloadOutlined,DeleteOutlined,UndoOutlined,FundViewOutlined,LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import * as serverconfig from '../serverconn'
import ReactExport from "@ibrahimrahmani/react-export-excel";

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const { TabPane } = Tabs;

function callback(key) {
  console.log(key);
}

var sacco= ''
var token= ''

class SystemLogList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    systemactivitylogs: [],
    datarequested:true,

  };

  componentDidMount(){

    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/systemactivitylogs/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          systemactivitylogs:res.data
        })

        this.setState({datarequested:false})


    })

  

}


getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};

  render() {
    const columns = [
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'id',
        ...this.getColumnSearchProps('date'),
      },
      {
        title: 'Activity',
        dataIndex: 'activity_name',
        key: 'id',
        ...this.getColumnSearchProps('activity_name'),
      },
      {
        title: 'Time stamp',
        dataIndex: 'time_stamp',
        key: 'id',
        ...this.getColumnSearchProps('time_stamp'),
      },

    ];




    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return(
       <div>

<Tabs defaultActiveKey="1" onChange={callback}>
    <TabPane tab="System activity logs" key="1">

    <ExcelFile  element={<Button type='primary' icon={<DownloadOutlined/>}>Download Excel</Button>} >
        <ExcelSheet data={this.state.systemactivitylogs} name="System logs">
            <ExcelColumn label="Date" value="date"/>
            <ExcelColumn label="Activity" value="activity_name"/>
            <ExcelColumn label="Time" value="time_stamp"/>
        </ExcelSheet>
   </ExcelFile>
   <br></br>
   <br></br>

      
    <Collapse defaultActiveKey={['1']} onChange={this.callback}>
          <Panel header="SYSTEM ACTIVITY LOGS" key="1">
          <Table 
          columns={columns} 
          scroll={{ x: 1000 }}
          pagination={{showQuickJumper:true,showSizeChanger:true }}
          dataSource={this.state.systemactivitylogs} 
          bordered/>
          </Panel>
          </Collapse>

    </TabPane>

</Tabs>

</div>)

  }

    
  }
}

export default SystemLogList; 
