import React,{ useRef } from 'react';
import axios from 'axios'
import { Tooltip,Popover,Result,Table,Popconfirm,Switch,Input, Button,Collapse,Card,Avatar,Modal,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { PrinterOutlined, MinusOutlined, CloseCircleFilled, CheckCircleOutlined, UserOutlined } from '@ant-design/icons';
import { DeleteOutlined,SearchOutlined,CameraOutlined,PlusOutlined,EditOutlined,PlusSquareFilled,SaveOutlined,LoadingOutlined } from '@ant-design/icons';
import QrReader from 'react-qr-reader'
import uuid from 'react-uuid'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
import PrintTemplate from 'react-print';
import ReactToPrint from 'react-to-print';
import {
  Col,
  Row,
} from 'reactstrap';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import * as reactstrp from 'reactstrap';
import { ToWords } from 'to-words';
var converter = require('number-to-words');


var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { Title } = Typography;
const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

const toWords = new ToWords();

message.config({
  top: 200,
  duration: 2,
  maxCount: 3,
  rtl: true,
});

var token= ''
var sacco= ''
var username=''
var bizuserid= ''



class ReceiptToPrint extends React.Component {

  state = {
    companyprofile:{}
  };

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    
    }else{
       sacco= ''
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })
    })
  }


  render() {
    return (
      <div style={{padding:20}}>
        {
          this.props.thermalprint===true?
          <div >
          <p>*********************************************************</p>

          <h1 style={{fontWeight:'bolder',display:'flex',alignSelf:'flex-start'}}>  {this.state.companyprofile.sacco_name}</h1>
          <h2 style={{color:'black'}}>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
          {
          this.state.companyprofile.RegisteredPhoneNumber2==""?
          "":","+this.state.companyprofile.RegisteredPhoneNumber2
          }

          </h2>               
          <h2 style={{color:'black'}}>  Email: {this.state.companyprofile.company_email}</h2>
          <h2 style={{color:'black'}}>  Wesite: {this.state.companyprofile.website}</h2>
          <h2 style={{color:'black'}}>  {this.state.companyprofile.box_number}</h2>
          <h2 style={{color:'black'}}>  RECEIPT NO: {this.props.journal_number}</h2>
          <h2 style={{color:'black'}}>  DATE: {this.props.date}</h2>

          <p>*********************************************************</p>

          <h1 style={{fontWeight:'bolder',display:'flex',alignSelf:'flex-start'}}> PAYMENT RECEIPT </h1>

          <p>********************************</p>

          <h3 style={{color:'black'}}>
              ACC NUMBER: 
            {this.props.account.AccountNumber}
          </h3>

          <h3 style={{color:'black'}}>
              ACC NAME :
            {this.props.account.FullName}
          </h3>

          <h3 style={{color:'black'}}>
              PAID AMOUNT:
            <CurrencyFormat  value={this.props.totalamount} displayType={'text'} thousandSeparator={','} />
          </h3>

          <h3 style={{color:'black'}}>
              In Words: 
            ({ this.props.amount_inwords})
          </h3>
        
          <h3 style={{color:'black'}}>
              TELLER:  
            {username}
          </h3>                 

          <h3 style={{color:'black'}}>
              PAYMENT OF: {this.props.reason}
          </h3> 

          <p>********************************</p>

          <h4 style={{color:'black'}}>  Supported by PitechUgLtd </h4>
          <h4 style={{color:'black'}}>  ( www.pitech.co.ug ) </h4>

        </div>

          :

        <div>
            <Row> 
            <Col xs="2" sm="2" lg="2">
                <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.sacco_logo} />

              </Col>
              <Col xs="6" sm="6" lg="6">
              <h3>{this.state.companyprofile.sacco_name}</h3>
              <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
              {
              this.state.companyprofile.RegisteredPhoneNumber2==""?
              "":","+this.state.companyprofile.RegisteredPhoneNumber2
              }
        
              </h6>               
              <h5>Email: {this.state.companyprofile.company_email}</h5>
              <h5>Wesite: {this.state.companyprofile.website}</h5>
              <h5>{this.state.companyprofile.box_number}</h5>

              </Col>
            </Row>
            <h3 style={{fontWeight:'bolder',display:'flex',alignSelf:'center',alignContent:'center',justifyContent:'center'}}>PAYMENT RECEIPT</h3>

            <reactstrp.Table bordered>
          <thead>
            <tr>
            <th>  ACC NUMBER</th>
              <th>{this.props.account.AccountNumber}</th>
            </tr>

            <tr>
              <th>ACC NAME</th>
              <th>{this.props.account.FullName}</th>
            </tr>

            <tr>
              <th>PAID AMOUNT</th>
              <th><CurrencyFormat  value={this.props.totalamount} displayType={'text'} thousandSeparator={true} /></th>
            </tr>

            <tr>
              <th>In Words   </th>
              <th>({ this.props.amount_inwords})</th>
            </tr>

            <tr >
              <th >SLIP NUMBER</th>
              <th >{this.props.journal_number}</th>
            </tr> 

            <tr >
              <th >TELLER</th>
              <th >{username}</th>
            </tr>                 

            <tr >
              <th >PAYMENT OF</th>
              <th >{this.props.reason}</th>
            </tr> 

          </thead>
        </reactstrp.Table> 

        </div>
        }
          
    </div>
    );
  }
}




class JournalEntryView extends React.Component {

  state = {
    date:moment().format(dateFormat).toString(),
    selectedaccount:'',
    selectedaccountobject:{},
    members:[],
    datarequested:true,
    totalamount:0,
    fixedrate:0,
    previoustransactions:[],
    datasubmittedsuccessfully:false,
    fixedamount:0,
    companyprofile:{},
    accountchange:false,
    userprofile:{},
    fdperiod:0,
    fixedratetype:'simple',

    companyaccounts: [],
    selected_account:'',
    transaction_description:'',
    transaction_amount:0,
    transaction_type:'',
    journal_list:[],
    itemnumber:0,
    voucher_no:0,
    journal_no:0,


    thermalprint:false,
    transaction_channel:'',
    journal_number:'',
    transaction_details:''

  }

  callback(key) {
    console.log(key);
  }

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    
    }else{
       sacco= ''
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
   }else{
      bizuserid= ''
   }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }
    
    //get the user profile here
    axios.get(serverconfig.backendserverurl+`/api/accounts/${bizuserid}/`,{
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res => {
        this.setState({
          userprofile:res.data
        })
    })


    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })
    })


    axios.get(serverconfig.backendserverurl+`/api/members/?sacco=${sacco}`)
    .then(res => {
      //console.log(res.data)
        this.setState({
          members:res.data
        })
    })

    axios.get(serverconfig.backendserverurl+`/api/companyaccounts/?sacco=${sacco}`)
    .then(res => {
        this.setState({
            companyaccounts:res.data
        })
        this.setState({datarequested:false})
    })

  }

  //get debit account
  get_debit_account=()=>{
    var debit_account=''
    this.state.journal_list.map((item)=>{
      if (item.transaction_type==="Debit"){
        debit_account=item.selected_account
      }
    })
    return debit_account;

  }
  

  //submit the deposit now
  handleFormSubmit=(event) =>{
    if (this.state.journal_list<=0){
      message.info(" List of txns cant be zero ")
    } 
    
    else if (this.calculateTotalCredit()!=this.calculateTotalDebit() && this.state.companyprofile.allow_onesided_GL_entries===false){
    message.error("Debits should be equal to credits")} 

    else{

      //set transactional channel
        this.setState({transaction_channel:this.get_debit_account()})
       
      this.setState({datarequested:true})
  
      let form_data = new FormData();
      form_data.append('date',this.state.date);
      form_data.append('journal_list', JSON.stringify(this.state.journal_list));
      form_data.append('sacco', sacco);
      form_data.append('username', username);
      form_data.append('selectedaccount',this.state.selectedaccount);
      form_data.append('voucher_no', this.state.voucher_no);
      form_data.append('journal_no', this.state.journal_no);

      axios.post(serverconfig.backendserverurl+'/customqueries/createjournalentries', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
        })
      .then(res => {
        this.setState({journal_number:res.data.journalno})

        this.setState({datarequested:false})
        message.info(res.data.message)
        if (res.data.message==="success"){
          this.setState({datasubmittedsuccessfully:true})
        }
        
      })
      .catch(error => console.log(error))
    }
    
  }


  //calculate total debit and credit
  calculateTotalDebit=()=>{
    var total =0

    this.state.journal_list.map((item)=>{

      if (item.transaction_type==="Debit"){
        total+=Number(item.transaction_amount)
      }

    })

    return total;
  }

  calculateTotalCredit=()=>{
    var total =0

    this.state.journal_list.map((item)=>{

      if (item.transaction_type==="Credit"){
        total+=Number(item.transaction_amount)
      }

    })

    return total;
  }


  ////////////////////////////////////////////
  // RENDERING METHOD HERE
  render() {


    const journal_columns = [
      {
        title: '#',
        dataIndex: 'itemnumber',
        key: 'key',
      },
      {
        title: 'Account',
        dataIndex: 'selected_account',
        key: 'key',
      },

      {
        title: 'Transaction type',
        dataIndex: 'transaction_type',
        key: 'key',
      },
      {
        title: 'Amount',
        dataIndex: 'transaction_amount',
        key: 'key',
      },
      {
        title: 'Details',
        dataIndex: 'transaction_description',
        key: 'key',
      },
      {
        title: 'Action',
        dataIndex: 'key',
        key: 'key',
        render: (text,record) =>
        <p>
        <Popover content={<p>Click here to delete client</p>} title="Delete">
        <Popconfirm title="Sure to delete?" onConfirm={() => {
        this.setState({ journal_list:  [...this.state.journal_list.filter(todo => todo.key
          !==text)]});
        }}>
          <DeleteOutlined style={{color:'red'}} />
         </Popconfirm>
        </Popover>
        </p>,
      }

    ];

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      if(this.state.datasubmittedsuccessfully===true){
        return(
          <div>
         
         
          <div style={{display: "none"}}>
            &nbsp;&nbsp;
            <ReceiptToPrint
            account={this.state.selectedaccountobject} 
            totalamount={this.calculateTotalDebit()} 
            amount_inwords={converter.toWords(this.calculateTotalDebit())}
            thermalprint={this.state.thermalprint}
            date={this.state.date}

            reason={this.state.journal_list.length>0?this.state.journal_list[0].transaction_description:''}
            journal_number={this.state.journal_number}
            transaction_channel={this.state.transaction_channel}

            ref={el => (this.componentRef = el)} 
            /> 
            </div> 



            <Row >
                <Col xs="12" sm="6" lg="6">
                  <Card>
                  <Result
                  status="success"
                  title="Successful journal entries"
                  subTitle="Finish off transaction"
                  extra={[
                    <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignSelf:'center'}}>
                    <FormItem >
                    <Switch
                      checked={this.state.thermalprint}
                      onChange={val=>{this.setState({thermalprint:val})}}
                      checkedChildren="Thermal print"
                      unCheckedChildren="A4 print (Click to change to thermal printer)"
                      />
                    </FormItem>

                    </div>

                    ,
                    <a>
                    <ReactToPrint
                      trigger={() =>                     
                        <div style={{}}>
                          <Tooltip title="Click to print" placement="top">
                          <p ><PrinterOutlined style={{ fontSize: '40px', color: '#08c' }}/></p>
                          <h6 >Print Receipt</h6>
                          </Tooltip>
                        </div>                          
                          }
                          content={() => this.componentRef}
                        />  
                        </a>,
                    <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
                  ]}
                  />
                  </Card>
                  </Col>
            </Row>
          </div>
        )

      }else{
        return(
          <div>




        <Row >
        <Col xs="12" sm="6" lg="4">
        <Card>
        <h4>Journal entry form</h4>
        <Form 
          name="Ledger account"
          onFinish={(event) => {

            if (this.state.selected_account===""){
              message.error("Ledger account cant be missing")
            }else if(this.state.transaction_type===""){
              message.error("Transaction type cant be missing")

            }else if(this.state.transaction_amount===""){
              message.error("Transaction amount cant be missing")

            }else if(this.state.transaction_description===""){
              message.error("Transaction details cant be missing")

            }else{

              const newitem={
                key:uuid(),
                itemnumber:(Number(this.state.itemnumber)+1),
                selected_account:this.state.selected_account,
                transaction_type:this.state.transaction_type,
                transaction_amount:this.state.transaction_amount,
                transaction_description:this.state.transaction_description,
               }
          
              //add to the receipt item list
              this.setState({
                journal_list: [...this.state.journal_list, newitem]
              });
          
              this.setState({selected_account:''});
              this.setState({transaction_type:''})
              this.setState({transaction_amount:0})
              this.setState({transaction_description:''})
              
              this.setState({itemnumber:(Number(this.state.itemnumber)+1)})
              message.info("Added entry")

            }
            
          }}
          >
       
        <FormItem label={"Ledger account"}
       
        >
          <Select 
          placeholder="Account" 
          style={{  }} 
          value={this.state.selected_account}
          onChange={(val)=>{this.setState({selected_account:val})}} 
          showSearch
          optionFilterProp="children"
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onSearch={this.onSearch}                    
          >
            {this.state.companyaccounts.map(
              (accnt)=>(
                <Option value={accnt.account_name}>{accnt.account_name} | {accnt.account_code} | {accnt.account_type}</Option>
              ))}
          </Select>
      </FormItem>

      <FormItem 
        label="Transaction type"
      
        >
            <Select 
            value={this.state.transaction_type} 
            onChange={(val)=>{this.setState({transaction_type:val})}}
            placeholder="Transaction type"
            showSearch
            optionFilterProp="children"
            >
                  <Option value='Debit'>Debit</Option>
                  <Option value='Credit'>Credit</Option>
            </Select>
        </FormItem>


       <FormItem label="Amount">
          <Input name="transaction_amount" type="number" placeholder="Transaction amount" value={this.state.transaction_amount} onChange={(val)=>{this.setState({transaction_amount:val.target.value})}} />
        </FormItem>

        <FormItem label="Transaction details"
          
        >
              <TextArea
                    placeholder="Details."
                    autoSize={{ minRows: 2, maxRows: 6 }}
                    value={this.state.transaction_description}
                    onChange={(val)=>{this.setState({transaction_description:val.target.value})}}
                  />
        </FormItem>

        <FormItem>
          <Button  type="primary" htmlType="submit">Add to list</Button>
        </FormItem>

        </Form>

        </Card>

                    </Col>

                    <Col xs="12" sm="6" lg="7">
                      <Card>
                        <h4>Journal list</h4>
                        <Table 
                          size='small'
                          scroll={{ x: 1000 }}
                          columns={journal_columns}
                          pagination={{showQuickJumper:true,showSizeChanger:true }}
                          dataSource={this.state.journal_list} bordered/>
                         
                          <br></br>
                          <h3>List Total items: ( {this.state.journal_list.length} ) Entries</h3>

                          <h4>Total debit:  {this.calculateTotalDebit()}</h4>
                          <h4>Total credit: {this.calculateTotalCredit()}</h4>

                      {
                        this.state.companyprofile.automated_transaction_date===false?
                        <FormItem label="Date of entry"
                        name='date'
                        rules={[
                          {
                            required: true,
                            message: 'Please Select date',
                          },
                          ]}
                        >
                            <DatePicker value={moment()} onChange={(date, dateString) => this.setState({ date: dateString})} format={dateFormat} />
                        </FormItem>                 
                        :
                        null
                      }


                    <FormItem label="Attach member">
                        <Select 
                        placeholder="Account No | Name" 
                        style={{  }} 
                        value={this.state.selectedaccount}
                        onChange={(e)=>{
                          this.setState({ selectedaccount: e});
                          //make query for last transactions
                          axios.get(`${serverconfig.backendserverurl}/api/members/${e}`)
                            .then(res => {  
                                this.setState({selectedaccountobject: res.data})
                            })
                        }} 
                        showSearch
                        optionFilterProp="children"
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        onSearch={this.onSearch}                    
                        >
                          {this.state.members.map(
                            (accnt)=>(
                              <Option value={accnt.id}>{accnt.AccountNumber} | {accnt.FullName}</Option>
                            ))}
                        </Select>
                    </FormItem>

                    <FormItem label="Voucher no">
                         <Input name="voucher_no"
                          type='number'
                           placeholder="voucher_no."
                            value={this.state.voucher_no}
                             onChange={(val)=>{this.setState({voucher_no:val.target.value})}} />
                  </FormItem>


                  <FormItem label="Journal No">
                    <Input name="journal_no"
                    type='number'
                    placeholder="journal_no."
                    value={this.state.journal_no}
                    onChange={(val)=>{this.setState({journal_no:val.target.value})}} />
                  </FormItem>


                  <FormItem >
                    <Button type="primary" htmlType="button" onClick={()=>{
                      this.handleFormSubmit()

                    }}>Submit Entries </Button>
                  </FormItem>

                  </Card>

        </Col>

        </Row>

          </div>                       
        )
      }
    }
   
  }
}

export default JournalEntryView; 
