import React from 'react';
import axios from 'axios'
import { Table, Input, Button,Popconfirm,Popover,
  Collapse,Avatar,Drawer,Descriptions,message,Modal,Spin,Switch,Card} from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { PlusCircleFilled,MinusCircleFilled,SwapOutlined,MinusCircleOutlined,PlusCircleOutlined,SearchOutlined,FundOutlined,EyeOutlined,FundViewOutlined,DeleteOutlined,LockFilled,LockOutlined,LoadingOutlined } from '@ant-design/icons';
import { UserOutlined } from '@ant-design/icons';
import { Form,Select,Checkbox,Upload } from 'antd';
import { Link } from 'react-router-dom';
import * as serverconfig from '../serverconn'

import {
  Col,
  Row,
} from 'reactstrap';
import { NavLink } from 'react-router-dom'

import * as primarycolor from '../primarycolor'

var CryptoJS = require("crypto-js");
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

const { Panel } = Collapse;
const FormItem=Form.Item;

function callback(key) {
  console.log(key);
}

message.config({
  top: 200,
  duration: 2,
  maxCount: 3,
  rtl: true,
});

var sacco= ''
var username=''
var token= ''
var bizuserid= ''

class AllTransactionsActions extends React.Component {
  state = {
    userprofile:{},
    userrights:{},
  };

  componentDidMount(){

    if(localStorage.getItem("sacco")){
      sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    
    }else{
       sacco= ''
       username=''
    }

    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
   }else{
      bizuserid= ''
   }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/accountrights/?user=${bizuserid}`)
    .then(res => {
        this.setState({
          userrights:res.data[0]
        })
    })

    let form_data = new FormData();
    form_data.append('userid', bizuserid);
    
    //get the user profile here
    axios.post(serverconfig.backendserverurl+'/customqueries/get_user_profile',form_data,{
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res => {
        this.setState({
          userprofile:res.data
        })
     
    })

}

  render() {

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{

      return(
        <div>
          <Row> 

          {
            this.state.userrights.can_deposit?
          <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>

          <Card
            hoverable
            style={{ 
              padding:3,
              alignItems:'center',
              alignSelf:'center',
              display:'flex',
              justifyContent:'center',
              marginRight:6,
              flexDirection:'column'}}
          > 
          <NavLink to='/deposit/'>
          <div                  
           style={{ 
              padding:3,
              alignItems:'center',
              alignSelf:'center',
              display:'flex',
              justifyContent:'center',
              flexDirection:'column' }}>
              <PlusCircleFilled style={{color:primarycolor.primarycolor,fontSize: '50px',alignSelf:'center'}}/>
              <h6 style={{fontWeight:'bolder',color:'#15430'}}>ACCOUNT DEPOSIT </h6>
          </div>
          </NavLink>
    
          </Card>
        </Col>
        :
        null}

{
      this.state.userrights.can_initiate_withdraw?
                <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
                <Card
                  hoverable
                  style={{ 
                    padding:3,
                    alignItems:'center',
                    alignSelf:'center',
                    display:'flex',
                    justifyContent:'center',
                    marginRight:6,
                    flexDirection:'column'}}
                > 
                <NavLink to='/withdrawrequistion/'>
                <div                  
                style={{ 
                    padding:3,
                    alignItems:'center',
                    alignSelf:'center',
                    display:'flex',
                    justifyContent:'center',
                    flexDirection:'column' }}>
                    <MinusCircleFilled style={{color:primarycolor.primarycolor,fontSize: '50px',alignSelf:'center'}}/>
                    <h6 style={{fontWeight:'bolder',color:'#15430'}}> WITHDRAW REQUISTION</h6>
                </div>
                </NavLink>

                </Card>
                </Col>
            :
            null}

        {
            this.state.userrights.can_withdraw?
        <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
          <Card
              hoverable
              style={{ 
                padding:3,
                alignItems:'center',
                alignSelf:'center',
                display:'flex',
                justifyContent:'center',
                flexDirection:'column' }}
            > 
            <NavLink to='/withdrawform/'>
            <div                  
            style={{ 
                padding:3,
                alignItems:'center',
                alignSelf:'center',
                display:'flex',
                justifyContent:'center',
                flexDirection:'column' }}>
            <MinusCircleFilled style={{color:primarycolor.primarycolor,fontSize: '50px',alignSelf:'center'}}/>
            <h6 style={{fontWeight:'bolder',color:'#15430'}}>ACCOUNT WITHDRAW </h6>
    
            </div>
    
            </NavLink>
          </Card> 
          </Col>
        :
        null}


{
            this.state.userrights.can_opensupersave?
        <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
          <Card
              hoverable
              style={{ 
                padding:3,
                alignItems:'center',
                alignSelf:'center',
                display:'flex',
                justifyContent:'center',
                flexDirection:'column' }}
            > 
            <NavLink to='/supersaveaccountopening/'>
            <div                  
            style={{ 
                padding:3,
                alignItems:'center',
                alignSelf:'center',
                display:'flex',
                justifyContent:'center',
                flexDirection:'column' }}>
            <EyeOutlined style={{color:primarycolor.primarycolor,fontSize: '50px',alignSelf:'center'}}/>
            <h6 style={{fontWeight:'bolder',color:'#15430'}}>SUPERSAVE ACCOUNT OPENING </h6>

            </div>

            </NavLink>
          </Card> 

          </Col>
        :
        null}

        {
          this.state.userrights.can_openteebagoolo?
          <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
          <Card
              hoverable
              style={{ 
                padding:3,
                alignItems:'center',
                alignSelf:'center',
                display:'flex',
                justifyContent:'center',
                flexDirection:'column' }}
            > 
            <NavLink to='/teebagooloaccountopening/'>
            <div                  
            style={{ 
                padding:3,
                alignItems:'center',
                alignSelf:'center',
                display:'flex',
                justifyContent:'center',
                flexDirection:'column' }}>
            <EyeOutlined style={{color:primarycolor.primarycolor,fontSize: '50px',alignSelf:'center'}}/>
            <h6 style={{fontWeight:'bolder',color:'#15430'}}>TEEBA GOOLO ACCOUNT OPENING </h6>

            </div>
            </NavLink>
          </Card> 

          </Col>
        :
        null}


          {
            this.state.userrights.can_makesingleaccounttransfer?
            <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
            <Card
              hoverable
              style={{ 
                padding:3,
                alignItems:'center',
                alignSelf:'center',
                display:'flex',
                justifyContent:'center',
                marginRight:6,
                flexDirection:'column'}}
            > 
            <NavLink to='/memberinteraccountstransfers/'>
            <div                  
            style={{ 
                padding:3,
                alignItems:'center',
                alignSelf:'center',
                display:'flex',
                justifyContent:'center',
                flexDirection:'column' }}>
                <SwapOutlined style={{color:primarycolor.primarycolor,fontSize: '50px',alignSelf:'center'}}/>
                <h6 style={{fontWeight:'bolder',color:'#15430'}}>SINGLE MEMBER INTER-ACCOUNT TRANSFERS </h6>
            </div>
            </NavLink>

            </Card>
            </Col>
        :
        null}

            {/*
              this.state.userrights.can_award_osinterest?
  
            <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
            <Card
              hoverable
              style={{ 
                padding:3,
                alignItems:'center',
                alignSelf:'center',
                display:'flex',
                justifyContent:'center',
                marginRight:6,
                flexDirection:'column'}}
            > 
            <NavLink to='/savingscreateinterest/'>
            <div                  
            style={{ 
                padding:3,
                alignItems:'center',
                alignSelf:'center',
                display:'flex',
                justifyContent:'center',
                flexDirection:'column' }}>
                <PlusCircleFilled style={{color:primarycolor.primarycolor,fontSize: '50px',alignSelf:'center'}}/>
                <h6 style={{fontWeight:'bolder',color:'#15430'}}> ORDINARY SAVINGS AWARD INTEREST </h6>
            </div>
            </NavLink>

            </Card>
            </Col>
        :
            null}*/}



         {
              this.state.userrights.can_award_ssinterest?
            <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
           <Card
              hoverable
              style={{ 
                padding:3,
                alignItems:'center',
                alignSelf:'center',
                display:'flex',
                justifyContent:'center',
                flexDirection:'column' }}
            > 
            <NavLink to='/supersavecreateinterest/'>
            <div                  
            style={{ 
                padding:3,
                alignItems:'center',
                alignSelf:'center',
                display:'flex',
                justifyContent:'center',
                flexDirection:'column' }}>
            <PlusCircleFilled style={{color:primarycolor.primarycolor,fontSize: '50px',alignSelf:'center'}}/>
            <h6 style={{fontWeight:'bolder',color:'#15430'}}>SUPERSAVE INTEREST AWARD </h6>

            </div>

            </NavLink>
          </Card> 

          </Col>
        :
        null}


        {/*
        this.state.userrights.can_do_orginteraccount_transfers?
         <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
          <Card
              hoverable
              style={{ 
                padding:3,
                alignItems:'center',
                alignSelf:'center',
                display:'flex',
                justifyContent:'center',
                flexDirection:'column' }}
            > 
            <NavLink to='/org_interaccount_transfer/'>
            <div                  
            style={{ 
                padding:3,
                alignItems:'center',
                alignSelf:'center',
                display:'flex',
                justifyContent:'center',
                flexDirection:'column' }}>
            <SwapOutlined style={{color:primarycolor.primarycolor,fontSize: '50px',alignSelf:'center'}}/>
            <h6 style={{fontWeight:'bolder',color:'#15430'}}>ORG INTER ACCOUNT TRANSFER </h6>

            </div>
            </NavLink>
          </Card> 
          </Col>
        :
        null */
        }


      {
        this.state.userrights.can_make_journalentries?
         <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
          <Card
              hoverable
              style={{ 
                padding:3,
                alignItems:'center',
                alignSelf:'center',
                display:'flex',
                justifyContent:'center',
                flexDirection:'column' }}
            > 
            <NavLink to='/otherjournalentries/'>
            <div                  
            style={{ 
                padding:3,
                alignItems:'center',
                alignSelf:'center',
                display:'flex',
                justifyContent:'center',
                flexDirection:'column' }}>
            <PlusCircleFilled style={{color:primarycolor.primarycolor,fontSize: '50px',alignSelf:'center'}}/>
            <h6 style={{fontWeight:'bolder',color:'#15430'}}>MULTI JOURNAL ENTRY </h6>

            </div>
            </NavLink>
          </Card> 
          </Col>
        :
        null
        }



        {
          this.state.userrights.can_make_quick_transactions?
          <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
            <Card
                hoverable
                style={{ 
                  padding:3,
                  alignItems:'center',
                  alignSelf:'center',
                  display:'flex',
                  justifyContent:'center',
                  flexDirection:'column' }}
              > 
              <NavLink to='/quicktransact/'>
              <div                  
              style={{ 
                  padding:3,
                  alignItems:'center',
                  alignSelf:'center',
                  display:'flex',
                  justifyContent:'center',
                  flexDirection:'column' }}>
              <PlusCircleFilled style={{color:primarycolor.primarycolor,fontSize: '50px',alignSelf:'center'}}/>
              <h6 style={{fontWeight:'bolder',color:'#15430'}}>QUICK TRANSACT </h6>

              </div>
              </NavLink>
            </Card> 
            </Col>
          :
          null
          }


       {
          this.state.userrights.can_make_quick_transactions?
          <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
            <Card
                hoverable
                style={{ 
                  padding:3,
                  alignItems:'center',
                  alignSelf:'center',
                  display:'flex',
                  justifyContent:'center',
                  flexDirection:'column' }}
              > 
              <NavLink to='/dividendscalculator/'>
              <div                  
              style={{ 
                  padding:3,
                  alignItems:'center',
                  alignSelf:'center',
                  display:'flex',
                  justifyContent:'center',
                  flexDirection:'column' }}>
              <PlusCircleFilled style={{color:primarycolor.primarycolor,fontSize: '50px',alignSelf:'center'}}/>
              <h6 style={{fontWeight:'bolder',color:'#15430'}}>DIVIDENDS AWARD </h6>

              </div>
              </NavLink>
            </Card> 
            </Col>
          :
          null
          }


        {
          this.state.userrights.can_make_quick_transactions?
          <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
            <Card
                hoverable
                style={{ 
                  padding:3,
                  alignItems:'center',
                  alignSelf:'center',
                  display:'flex',
                  justifyContent:'center',
                  flexDirection:'column' }}
              > 
              <NavLink to='/inter_account_tranfer_betweenmembers/'>
              <div                  
              style={{ 
                  padding:3,
                  alignItems:'center',
                  alignSelf:'center',
                  display:'flex',
                  justifyContent:'center',
                  flexDirection:'column' }}>
              <SwapOutlined style={{color:primarycolor.primarycolor,fontSize: '50px',alignSelf:'center'}}/>
              <h6 style={{fontWeight:'bolder',color:'#15430'}}>MEMBER SAVINGS INTER ACCOUNT TRANSFER </h6>

              </div>
              </NavLink>
            </Card> 
            </Col>
          :
          null
          }



          
            <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
            <Card
            hoverable
            style={{ 
              padding:3,
              alignItems:'center',
              alignSelf:'center',
              display:'flex',
              justifyContent:'center',
              marginRight:6,
              flexDirection:'column'}}
          > 
          <NavLink to='/vouchers_list/'>
          <div                  
          style={{ 
              padding:3,
              alignItems:'center',
              alignSelf:'center',
              display:'flex',
              justifyContent:'center',
              flexDirection:'column' }}>
              <PlusCircleFilled style={{color:'#154030',fontSize: '50px',alignSelf:'center'}}/>
              <h6 style={{fontWeight:'bolder',color:'#154360'}}> PAYMENT VOUCHERS </h6>
          </div>
          </NavLink>
    
          </Card> 
          </Col>
           


          {
               this.state.userprofile.branch_manager?
                          <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>

                          <Card
                          hoverable
                          style={{ 
                            padding:3,
                            alignItems:'center',
                            alignSelf:'center',
                            display:'flex',
                            justifyContent:'center',
                            marginRight:6,
                            flexDirection:'column'}}
                        > 
                        <NavLink to='/dataonboarding/'>
                        <div                  
                        style={{ 
                            padding:3,
                            alignItems:'center',
                            alignSelf:'center',
                            display:'flex',
                            justifyContent:'center',
                            flexDirection:'column' }}>
                            <PlusCircleFilled style={{color:'#154030',fontSize: '50px',alignSelf:'center'}}/>
                            <h6 style={{fontWeight:'bolder',color:'#154360'}}>DATA UPLOADS </h6>
                        </div>
                        </NavLink>
                  
                        </Card> 
                        </Col>
                          :
                          null}



                        {
                          this.state.userrights.can_deposit?
                            <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>

                          <Card
                          hoverable
                          style={{ 
                            padding:3,
                            alignItems:'center',
                            alignSelf:'center',
                            display:'flex',
                            justifyContent:'center',
                            marginRight:6,
                            flexDirection:'column'}}
                        > 
                        <NavLink to='/member_payments/'>
                        <div                  
                        style={{ 
                            padding:3,
                            alignItems:'center',
                            alignSelf:'center',
                            display:'flex',
                            justifyContent:'center',
                            flexDirection:'column' }}>
                            <PlusCircleFilled style={{color:'#154030',fontSize: '50px',alignSelf:'center'}}/>
                            <h6 style={{fontWeight:'bolder',color:'#154360'}}>OTHER MEMBER PAYMENTS </h6>
                        </div>
                        </NavLink>
                  
                        </Card> 
                        </Col>
                          :
                          null}



                   {
                       this.state.userrights.can_withdraw?
                          <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>

                          <Card
                          hoverable
                          style={{ 
                            padding:3,
                            alignItems:'center',
                            alignSelf:'center',
                            display:'flex',
                            justifyContent:'center',
                            marginRight:6,
                            flexDirection:'column'}}
                        > 
                        <NavLink to='/membership_withdrawal/'>
                        <div                  
                        style={{ 
                            padding:3,
                            alignItems:'center',
                            alignSelf:'center',
                            display:'flex',
                            justifyContent:'center',
                            flexDirection:'column' }}>
                            <PlusCircleFilled style={{color:'#154030',fontSize: '50px',alignSelf:'center'}}/>
                            <h6 style={{fontWeight:'bolder',color:'#154360'}}>MEMBERHSIP WITHDRAWAL </h6>
                        </div>
                        </NavLink>
                  
                        </Card> 
                        </Col>
                    :
                    null}

          </Row>
        </div>
    )
   }
   
  }
}

export default AllTransactionsActions; 
