import React, { Component, createRef } from 'react';
import axios from 'axios';
import { Card,Avatar} from 'antd';
import { Form,Popover,Popconfirm,Table, Input, Button,Select,Modal,Spin,Image,DatePicker,Result,Switch,message,Tooltip, } from 'antd';
import {
    Col,
    Row,
  } from 'reactstrap';
import { UserOutlined,CloseCircleFilled,CheckCircleFilled,WarningFilled,EditOutlined,PlusCircleFilled,LoadingOutlined } from '@ant-design/icons';
import {  LockOutlined,MailOutlined,FundViewOutlined,CameraOutlined } from '@ant-design/icons';
import PhoneInput from 'react-phone-number-input'
import CurrencyFormat from 'react-currency-format';
import * as reactstrp from 'reactstrap';

import * as serverconfig from '../serverconn'
import moment from 'moment';
import * as primarycolor from '../primarycolor'
import Resizer from "react-image-file-resizer";
import uuid from 'react-uuid'
import { Link } from 'react-router-dom';
import Webcam from 'react-webcam';
import SignaturePad from './SignaturePad';


var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const dateFormat = 'DD/MM/YYYY';

var token= ''
var bizuserid= ''
var sacco= ''


class MemberDetail extends React.Component{

    state={
        date:moment().format(dateFormat).toString(),
        member: {},
        accounttypes:[],
        idtypes:[],
        sur_name:'' ,  
        middle_name:'',
        given_name:'',
        nationality:'',
        ID_type:'',
        ID_number:'',
        id_expirydate:'',
        date_Of_Birth:'',
        Email_Address:'',
        P_O_Box:'',
        Area_Of_Residence:'',
        District:'',
        RegisteredPhoneNumber1:'',
        RegisteredPhoneNumber2:'',
        next_of_kin:'',
        Next_Of_Kin_Physical_Address:'',
        relationship:'',
        Photo:null,
        Signature:null,
        thumbprintfile:null,

        Date_Of_Joining:'',
        account_type:'',
        date_of_appointment:'',
        status:'',
        staff_number:'',
        reference_one:'',
        Referee1PhoneNumber:'',
        datarequested:true,
        accountnumber:'',
        photofile:null,
        signaturefile:null,
        Thumbprint:null,

        marital_status:'',
        own_residence:'',
        tribe:'',
        recommender:'',
        language1:'',
        language2:'',
        gender:'',
        any_other_account:'',
        other_account_number:'',
        other_bank:'',
        title:'',
        datasubmittedsuccessfully:false,
        userrights:{},
        email_statements:false,
        user:'',
        users:[],
        how_did_you_know_sacco:'',
        supersavertypes:[],
        supersavertype:'',
        editform_visible:false,

        otheraccountslist:[],
        pysical_form:null,
        companyprofile:{},

        other_accounts_model_visible:false,

        other_account_type:'',
        other_account_bank_or_telecom:'',
        other_account_name:'',
        other_account_number_contact:'',
        ugdistricts:[],

        group_signitories:[],

        id_valid:false,
        age_valid:false,
        membertype:'',
        accounttypeobject:{},
        idtypeobject:{},


        isModalVisible: false,
        capturedImage: null,
        isImageModalVisible: false,

        show_sigpad:false,

    }


    componentDidMount(){
      this.webcamRef = createRef();


      if(localStorage.getItem("sacco")){
         sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
      }else{
         sacco= ''
      }
      
      if(localStorage.getItem("bizuserid")){
        bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
      }else{
         bizuserid= ''
      }
      
      if(localStorage.getItem("token")){
         token= localStorage.getItem("token")
      }else{
         token= ''
      }

        axios.defaults.headers={
          "Content-Type":"application/json",
          Authorization:`Token ${token}`
        }


        const memberID=this.props.match.params.memberID;
        axios.get(`${serverconfig.backendserverurl}/api/members/${memberID}`)
        .then(res => {  
            this.setState({member: res.data})

            //this.setState({date: res.data.date})
            this.setState({sur_name: res.data.SurName})
            this.setState({middle_name: res.data.MiddleName})
            this.setState({given_name: res.data.OtherName})
            this.setState({nationality: res.data.Nationality})
            this.setState({ID_type: res.data.IDType})

            axios.get(serverconfig.backendserverurl+`/api/idtypes/${res.data.IDType}`)
            .then(res => {
                this.setState({
                  idtypeobject:res.data
                })
            })


            this.setState({ID_number: res.data.IDNumber})
            this.setState({id_expirydate: res.data.id_expirydate})

            this.setState({date_Of_Birth: res.data.DateOfBirth})
            this.setState({Email_Address: res.data.EmailAddress})
            this.setState({P_O_Box: res.data.POBox})
            this.setState({Area_Of_Residence: res.data.AreaOfResidence})
            this.setState({District: res.data.District})
            this.setState({RegisteredPhoneNumber1: res.data.RegisteredPhoneNumber1})
            this.setState({RegisteredPhoneNumber2: res.data.RegisteredPhoneNumber2})
            this.setState({next_of_kin: res.data.next_of_kin})
            this.setState({Next_Of_Kin_Physical_Address: res.data.NextOfKinPhysicalAddress})
            this.setState({relationship: res.data.relationship})
            this.setState({Date_Of_Joining: res.data.DateOfJoining})
            this.setState({account_type: res.data.accountType})

            axios.get(serverconfig.backendserverurl+`/api/accounttypes/${res.data.accountType}`)
            .then(res => {
                this.setState({
                  accounttypeobject:res.data
                })
            })



            this.setState({gender: res.data.gender})
            this.setState({marital_status: res.data.marital_status})
            this.setState({title: res.data.title})
            this.setState({accountnumber: res.data.AccountNumber})
            this.setState({own_residence: res.data.own_residence})
            this.setState({tribe: res.data.tribe})
            this.setState({recommender: res.data.recommender})
            this.setState({language1: res.data.language1})
            this.setState({language2: res.data.language2})
            this.setState({any_other_account: res.data.any_other_account})
            this.setState({other_account_number: res.data.other_account_number})
            this.setState({other_bank: res.data.other_bank})
            this.setState({email_statements: res.data.email_statements})
            this.setState({user: res.data.user})
            this.setState({membertype: res.data.member_type})

            this.setState({how_did_you_know_sacco:res.data.how_did_you_know_sacco})
            this.setState({supersavertype:res.data.supersavertype})

            if (res.data.otheraccountslist!=null){
              this.setState({otheraccountslist:JSON.parse(res.data.otheraccountslist)})
            }

            //check if its a group acocount and get signitories
            if(res.data.groupAccount===true && res.data.AccountNumber!=null){
              axios.get(serverconfig.backendserverurl+`/api/groups/?group_account=${res.data.AccountNumber}`)
              .then(res => {
                var groupdata=res.data[0]
                //get signitories now

                axios.get(serverconfig.backendserverurl+`/api/group_signitories/?group=${groupdata.id}`)
                .then(res => {
                    this.setState({
                      group_signitories:res.data
                    })
                })

              })

            }


        })



        axios.get(serverconfig.backendserverurl+`/api/accounttypes/?sacco=${sacco}`)
        .then(res => {
            this.setState({
              accounttypes:res.data
            })
        })
    
        axios.get(serverconfig.backendserverurl+`/api/idtypes/?sacco=${sacco}`)
        .then(res => {
            this.setState({
              idtypes:res.data
            })
        })

        axios.get(serverconfig.backendserverurl+`/api/accounts/?sacco=${sacco}`)
        .then(res => {
            this.setState({
              users:res.data
            })
        })

        axios.get(serverconfig.backendserverurl+`/api/supersavertype/?sacco=${sacco}`)
        .then(res => {
            this.setState({
              supersavertypes:res.data
            })
        })


        axios.get(serverconfig.backendserverurl+`/api/accountrights/?user=${bizuserid}`)
        .then(res => {
            this.setState({
              userrights:res.data[0]
            })
        })

        axios.get(serverconfig.backendserverurl+`/api/saccos/${sacco}`)
        .then(res => {
            this.setState({
              companyprofile:res.data
            })
        })

         //get the sacco package here
        axios.get(serverconfig.backendserverurl+`/api/ugdistricts/?sacco=${sacco}`)
        .then(res => {
          this.setState({
            ugdistricts:res.data
          })
            this.setState({datarequested:false})
        })
    }




  openCamera = () => {
    this.setState({ isModalVisible: true });
  };

  closeCameraModal = () => {
    this.setState({ isModalVisible: false });
  };


  dataURLtoBlob = (dataURL) => {
    return fetch(dataURL)
      .then(res => res.blob());
  };

  captureImage = async() => {
    const imageSrc = this.webcamRef.current.getScreenshot();
    this.setState({
      capturedImage: imageSrc,
      isModalVisible: false,
      isImageModalVisible: true,
    });

    //set photo

    this.setState({
      photofile: imageSrc
    })

    const blob = await this.dataURLtoBlob(imageSrc);

    this.setState({ Photo: blob});


  };

  closeImageModal = () => {
    this.setState({ isImageModalVisible: false });
  };


    //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})

    const memberID=this.props.match.params.memberID;

    if (this.state.RegisteredPhoneNumber1===''){
      message.error("Phone Number missing")
    }
    
    /*
    else if(this.state.id_valid===false && this.state.membertype==="senior"){
      message.error("ID is invalid")

    }else if(this.state.age_valid===false){
      message.error("Age is invalid")
    }else if(  this.state.ID_number.length<14 && this.state.membertype==="senior"){
      message.error("Id length is less than 14")
    }  */
    
    
    else{

      let form_data = new FormData();
      form_data.append('date', this.state.date);
      form_data.append('SurName', this.state.sur_name);
      form_data.append('MiddleName', this.state.middle_name);
      form_data.append('OtherName', this.state.given_name);
      form_data.append('Nationality', this.state.nationality);
      form_data.append('IDType', this.state.ID_type);
      form_data.append('IDNumber', this.state.ID_number);
      form_data.append('id_expirydate', this.state.id_expirydate);
  
      form_data.append('DateOfBirth', this.state.date_Of_Birth);
  
      form_data.append('EmailAddress', this.state.Email_Address);
      form_data.append('POBox', this.state.P_O_Box);
      form_data.append('AreaOfResidence', this.state.Area_Of_Residence);
      form_data.append('District', this.state.District);
      form_data.append('RegisteredPhoneNumber1', this.state.RegisteredPhoneNumber1);
      form_data.append('RegisteredPhoneNumber2', this.state.RegisteredPhoneNumber2);
  
      form_data.append('next_of_kin', this.state.next_of_kin);
      form_data.append('NextOfKinPhysicalAddress', this.state.Next_Of_Kin_Physical_Address);
      form_data.append('relationship', this.state.relationship);
      form_data.append('DateOfJoining', this.state.Date_Of_Joining);
  
      form_data.append('accountType', this.state.account_type);
      form_data.append('gender', this.state.gender);
      form_data.append('marital_status', this.state.marital_status);
      form_data.append('title', this.state.title);
      form_data.append('AccountNumber', this.state.accountnumber);
  
      form_data.append('own_residence', this.state.own_residence);
      form_data.append('tribe', this.state.tribe);
      form_data.append('recommender', this.state.recommender);
      form_data.append('how_did_you_know_sacco', this.state.how_did_you_know_sacco);
  
      form_data.append('language1', this.state.language1);
      form_data.append('language2', this.state.language2);
      form_data.append('any_other_account', this.state.any_other_account);
      form_data.append('other_account_number', this.state.other_account_number);
      form_data.append('other_bank', this.state.other_bank);
      form_data.append('email_statements', this.state.email_statements);
      form_data.append('user', this.state.user);
      form_data.append('sacco', Number(sacco));
      form_data.append('memberID', memberID);
      form_data.append('otheraccountslist',JSON.stringify(this.state.otheraccountslist) );
  
      axios.post(serverconfig.backendserverurl+`/customqueries/updatememberaccountfromdashboard`, form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
    .then(res =>{
  
      let form_data1 = new FormData();
      this.state.Photo==null?
      console.log("No profile file")
      :
      form_data1.append('Photo', this.state.Photo, this.state.Photo?this.state.Photo.name:"");
  
      this.state.Signature==null?
      console.log("No signature file")
      :
      form_data1.append('Signature', this.state.Signature, this.state.Signature?this.state.Signature.name:"");
  
      this.state.Thumbprint==null?
      console.log("No thumb file")
      :
      form_data1.append('thumbprint', this.state.Thumbprint, this.state.Thumbprint?this.state.Thumbprint.name:"");
  
      this.state.pysical_form==null?
      console.log("No pysical_form file")
      :
      form_data1.append('pysical_form', this.state.pysical_form, this.state.pysical_form?this.state.pysical_form.name:"");
  
  
      axios.put(serverconfig.backendserverurl+`/api/membersignatureupdate/${memberID}/`, form_data1, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
    .then(res =>{
      console.log("successful image upload")
    }
  
    )
    .catch(error => console.log(error))
  
    this.setState({datarequested:false})
    this.setState({datasubmittedsuccessfully:true})
  
    }
      
    )
    .catch(error => console.log(error))



    }



  }


 
  handlesur_nameChange= (e) => this.setState({ sur_name: e.target.value});
  handlemiddle_nameChange= (e) => this.setState({ middle_name: e.target.value});
  handlegiven_nameChange= (e) => this.setState({ given_name: e.target.value});
  handlenationalityChange= (e) => this.setState({ nationality: e.target.value});
  
  handleID_typeChange= (e) => {
    this.setState({ ID_type: e});

    axios.get(serverconfig.backendserverurl+`/api/idtypes/${e}`)
    .then(res => {
        this.setState({
          idtypeobject:res.data
        })

        console.log(res.data)
    })
  }

  handletitleChange= (e) => this.setState({ title: e});

  handleID_numberChange= (e) => this.setState({ ID_number: e.target.value});
   
  handledate_Of_BirthChange= (date, dateString) =>{
    this.setState({ date_Of_Birth: dateString});
    this.checkagevalidity(dateString)
  } 



  handleEmail_AddressChange= (e) => this.setState({ Email_Address: e.target.value});
  handleP_O_BoxChange= (e) => this.setState({ P_O_Box: e.target.value});
  handleArea_Of_ResidenceChange= (e) => this.setState({ Area_Of_Residence: e.target.value});
  handleDistrictChange= (e) => this.setState({ District: e.target.value});
  handleRegisteredPhoneNumber1Change= (e) => this.setState({ RegisteredPhoneNumber1: e});

  handleRegisteredPhoneNumber2Change= (e) => this.setState({ RegisteredPhoneNumber2: e});
  handlenext_of_kinChange= (e) => this.setState({ next_of_kin: e.target.value});
  handleNext_Of_Kin_Physical_AddressChange= (e) => this.setState({ Next_Of_Kin_Physical_Address: e.target.value});
  handlerelationshipChange= (e) => this.setState({ relationship: e.target.value});
  handlePhotoChange= async(e) =>{
    //this.setState({ Photo: e.target.files[0]});

    if(e.target.files[0]){
      this.setState({
        photofile: URL.createObjectURL(e.target.files[0])
      })
    }

    const file = e.target.files[0];
    //send to resizer
    const image = await this.resizeFile(file);
    const newFile = this.dataURIToBlob(image);
    this.setState({ Photo: newFile});
    console.log(newFile);

  } 

  handleSignatureChange=async (e) =>{
    //this.setState({ Signature: e.target.files[0]});
    if(e.target.files[0]){
      this.setState({
        signaturefile: URL.createObjectURL(e.target.files[0])
      })
    }

    const file = e.target.files[0];
    //send to resizer
    const image = await this.resizeFile(file);
    const newFile = this.dataURIToBlob(image);
    this.setState({ Signature: newFile});
    console.log(newFile);

  } 

  handleDate_Of_JoiningChange= (date, dateString) => this.setState({ Date_Of_Joining: dateString});
  handleaccount_typeChange= (e) =>{
    axios.get(serverconfig.backendserverurl+`/api/accounttypes/${e}`)
    .then(res => {
        this.setState({
          accounttypeobject:res.data
        })
    })
    this.setState({ account_type: e});

  } 
  handledate_of_appointmentChange= (date, dateString) => this.setState({ date_of_appointment: dateString});

  handlestatusChange= (e) => this.setState({ status: e});
  handlemarital_statusChange= (e) => this.setState({ marital_status: e});
  handleown_residenceChange= (e) => this.setState({ own_residence: e});

  handlestaff_numberChange= (e) => this.setState({ staff_number: e.target.value});
  handlereference_oneChange= (e) => this.setState({ reference_one: e.target.value});
  handleReferee1PhoneNumberChange= (e) => this.setState({ Referee1PhoneNumber: e});

  handleaccountnumberChange= (e) => this.setState({ accountnumber: e.target.value});
  handletribeChange= (e) => this.setState({ tribe: e.target.value});
  handlerecommenderChange= (e) => this.setState({ recommender: e.target.value});
  handlelanguage1Change= (e) => this.setState({ language1: e.target.value});
  handlelanguage2Change= (e) => this.setState({ language2: e.target.value});
  handlegenderChange= (e) => this.setState({ gender: e});

  handleother_account_numberChange= (e) => this.setState({ other_account_number: e.target.value});
  handleany_other_accountChange= (e) => this.setState({ any_other_account: e});
  handleother_bankChange= (e) => this.setState({ other_bank: e.target.value});


  //check empty objecty
  isEmptyObject=(obj)=>{
    for ( var name in obj ) {
      return false;
    }
    return true;
  }


  //check agge validity
  checkagevalidity=(val)=>{

    if(this.isEmptyObject(this.state.accounttypeobject)===false){

      var agelimit_months_cap=Number(this.state.accounttypeobject.agelimit_months_cap)

      var dateMomentObjectone = moment(val, "DD/MM/YYYY"); // 1st argument - string, 2nd argument - format
      var dobobject = dateMomentObjectone.toDate();

      var datetoday=moment(this.state.date, "DD/MM/YYYY")

      var date1 = datetoday.toDate();
      var date2 = dobobject;

      var Difference_In_Time =  date1.getTime()-date2.getTime();
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

      console.log("diff days"+String(Difference_In_Days))

    if(Number(Difference_In_Days)>=Number(agelimit_months_cap)){
      this.setState({age_valid:true})
      console.log("Age is valid")
    }else{
      this.setState({age_valid:false})
      console.log("Age is not valid")
    }


    }else{
      this.setState({age_valid:false})

    }

  }

  //check agge validity
  checkidvalidity=(val)=>{

    if(this.isEmptyObject(this.state.idtypeobject)===false){

      console.log(val)
      var idvaliditydays=Number(this.state.idtypeobject.IDvaliditydays)
      console.log(idvaliditydays)

      var dateMomentObjectone = moment(val, "DD/MM/YYYY"); // 1st argument - string, 2nd argument - format
      var dobobject = dateMomentObjectone.toDate();
      console.log(dobobject)

      var datetoday=moment(this.state.date, "DD/MM/YYYY")
      console.log(datetoday)

      var date1 = datetoday.toDate();
      var date2 = dobobject;

      var Difference_In_Time =  date2.getTime()-date1.getTime();
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

      console.log("diff days"+String(Difference_In_Days))

    if(Number(Difference_In_Days)>=Number(idvaliditydays)){
      this.setState({id_valid:true})
      console.log("ID is valid")
    }else{
      this.setState({id_valid:false})
      console.log("ID is not valid")
    }


    }else{
      this.setState({id_valid:false})

    }





  }


//imgae resizer
resizeFile = (file) =>
new Promise((resolve) => {
  Resizer.imageFileResizer(
    file,
    300,
    400,
    "JPEG",
    50,
    0,
    (uri) => {
      resolve(uri);
    },
    "base64"
  );
});

//convert back to file
 dataURIToBlob = (dataURI) => {
  const splitDataURI = dataURI.split(",");
  const byteString =
    splitDataURI[0].indexOf("base64") >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(":")[1].split(";")[0];
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);
  return new Blob([ia], { type: mimeString });
};


onBlur=()=> {
  console.log('blur');
}

 onFocus=()=>{
  console.log('focus');
}

onSearch=(val)=> {
  console.log('search:', val);
}



    render(){
      const { isModalVisible, capturedImage, isImageModalVisible } = this.state;



      var columns = [
        {
          title: 'Full Name',
          dataIndex: 'FullName',
          key: 'id',
        },
        {
          title: 'Gender',
          dataIndex: 'gender',
          key: 'id',
        },
        {
          title: 'Account Contact',
          dataIndex: 'RegisteredPhoneNumber1',
          key: 'id',
        },
        {
          title: 'Email',
          dataIndex: 'EmailAddress',
          key: 'id',
        },
        
        {
          title: 'Action',
          dataIndex: 'id',
          key: 'id',
          render: text =>
          <p>
          <Popover content={<p>Click here to edit,view details</p>} title="Edit,View">
           <Link to={`/groupsignitorydetails/${text}`}><FundViewOutlined style={{color:'blue'}}/></Link>
          </Popover>
          </p>,
        }
  
      ];


        if(this.state.datarequested===true){
            return(
              <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin indicator={antIcon} />
              </div>
            )
      
          }else{

            if (this.state.datasubmittedsuccessfully===true){
              return(
                  <Card>
                  <Result
                  status="success"
                  title="Successfully Updated Member details "
                  subTitle="Member details can be updated any time"
                  extra={[
                    <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
                  ]}
                  />
                  </Card>
              );
      
            }else{

          return(
            <div>
              <div style={{display:'flex',flexDirection:'row'}}>
              <h2  style={{display:'flex',margin:4}}>{this.state.member.title} {this.state.member.FullName} | {this.state.member.RegisteredPhoneNumber1}</h2>
              
              <Tooltip title={"Click to edit member information"}>

              <EditOutlined style={{color:primarycolor.primarycolor,fontSize: '30px',}} onClick={()=>{this.setState({editform_visible:!this.state.editform_visible})}} />
              </Tooltip>
              </div>

              <div style={{display:'flex',overflowX:'auto'}}>
              <reactstrp.Table bordered >
                  <thead>
                  <tr>
                    {/**Images section */}
                  <th>
                        <Image
                        width={100}
                        height={100}
                        src={this.state.member.Photo}    
                        />
                        <br></br>
                        <br></br>

                        <Image
                        width={100}
                        height={100}
                        src={this.state.member.Signature}    
                        />                          
                        <br></br>
                        <br></br>
                        <Image
                        width={100}
                        height={100}
                        src={this.state.member.thumbprint}    
                        /> 

                        <br></br>

                          {
                            this.state.member.pysical_form===null?
                            null
                            :
                            <div>
                            <h5>Membership form.</h5>
                            <Button type='primary'>
                            <a href={this.state.member.pysical_form}>Download</a>
                            </Button>
                            </div>
                          }

                        </th>



                        <th>
                          <h3>Account Section</h3>
                          <p style={{fontWeight:'bolder',fontSize:15}}>Member Type: {this.state.member.member_type}</p>
                          <p style={{fontWeight:'bolder',fontSize:15}}>Account Number: {this.state.member.AccountNumber}</p>
                          <p style={{fontWeight:'bolder',fontSize:15}}>Account Name: {this.state.member.FullName}</p>
                          <p style={{fontWeight:'bolder',fontSize:15}}>Date Of Joining: {this.state.member.DateOfJoining}</p>


                          <h3>Bio Data</h3>

                        <p style={{fontWeight:'bolder',fontSize:15}}>Sur Name: {this.state.member.SurName}</p>
                        <p style={{fontWeight:'bolder',fontSize:15}}>Middle Name: {this.state.member.MiddleName}</p>
                        <p style={{fontWeight:'bolder',fontSize:15}}>Other Name: {this.state.member.OtherName}</p>
                        <p style={{fontWeight:'bolder',fontSize:15}}>Nationality: {this.state.member.Nationality}</p>
                        <p style={{fontWeight:'bolder',fontSize:15}}>Date Of Birth: {this.state.member.DateOfBirth}</p>
                        <p style={{fontWeight:'bolder',fontSize:15}}>Gender: {this.state.member.gender}</p>
                        <p style={{fontWeight:'bolder',fontSize:15}}>ID number: {this.state.member.IDNumber}</p>
                        <p style={{fontWeight:'bolder',fontSize:15}}>ID date of expiry: {this.state.member.id_expirydate}</p>


                        </th>

                        <th>
                        <h3>Employment Section</h3>

                        <p style={{fontWeight:'bolder',fontSize:15}}>Occupation: {this.state.member.occupation}</p>
                        <p style={{fontWeight:'bolder',fontSize:15}}>Name of Employer: {this.state.member.nameofemployer_business}</p>
                        <p style={{fontWeight:'bolder',fontSize:15}}>Address Of Employer: {this.state.member.addressofemployer_business}</p>

                        <h3>Contact Section</h3>

                        <p style={{fontWeight:'bolder',fontSize:15}}>Phone One: {this.state.member.RegisteredPhoneNumber1}</p>
                        <p style={{fontWeight:'bolder',fontSize:15}}>Phone Two: {this.state.member.RegisteredPhoneNumber2}</p>
                        <p style={{fontWeight:'bolder',fontSize:15}}>Email: {this.state.member.EmailAddress}</p>
                        <p style={{fontWeight:'bolder',fontSize:15}}>District: {this.state.member.District}</p>
                        <p style={{fontWeight:'bolder',fontSize:15}}>Physical Address: {this.state.member.AreaOfResidence}</p>
                        <p style={{fontWeight:'bolder',fontSize:15}}>Home Cell Location: {this.state.member.homecelllocaltion}</p>
                        <p style={{fontWeight:'bolder',fontSize:15}}>Cell: {this.state.member.cell}</p>
                        <p style={{fontWeight:'bolder',fontSize:15}}>Cell Leader: {this.state.member.cellleader}</p>

                        {
                        this.state.member.member_type==="junior"?
                        <div>
                          <h3>Junior Account details</h3>
                          <p style={{fontWeight:'bolder',fontSize:15}}>Parent Account: {this.state.member.parentaccount_no}</p>
                          <p style={{fontWeight:'bolder',fontSize:15}}>School: {this.state.member.child_account_school}</p>
                          <p style={{fontWeight:'bolder',fontSize:15}}>Class: {this.state.member.child_account_school_class}</p>

                        </div>
                        :
                        null
                      }

                        </th>
                        <th>
                        
                        {
                          this.state.member.AccountNumber=="null" || this.state.member.AccountNumber==null || this.state.member.AccountNumber==''?
                          null
                          :
                          <div>

                          <h3>Account Balances</h3>

                        <p style={{fontWeight:'bolder',fontSize:15}}>Saving Balance:<CurrencyFormat  value={this.state.member.savings_balance} displayType={'text'} thousandSeparator={true} />  </p>
                        <p style={{fontWeight:'bolder',fontSize:15}}>Saving Interest:<CurrencyFormat  value={this.state.member.saving_interestbalance} displayType={'text'} thousandSeparator={true} />  </p>

                        <p style={{fontWeight:'bolder',fontSize:15}}>Super Save Balance:<CurrencyFormat  value={this.state.member.supersaver_balance} displayType={'text'} thousandSeparator={true} />  </p>
                        <p style={{fontWeight:'bolder',fontSize:15}}>Shares Balance:<CurrencyFormat  value={this.state.member.shareamount_balance} displayType={'text'} thousandSeparator={true} />  </p>
                        <p style={{fontWeight:'bolder',fontSize:15}}>Shares No:{this.state.member.share_numberbalance}  </p>
                       
                        <p style={{fontWeight:'bolder',fontSize:15}}>Teeba Goolo:<CurrencyFormat  value={this.state.member.teebagoolo_balance} displayType={'text'} thousandSeparator={true} />  </p>
                        
                        <p style={{fontWeight:'bolder',fontSize:15}}>Loan:<CurrencyFormat  value={this.state.member.loan_balance} displayType={'text'} thousandSeparator={true} />  </p>
                        <p style={{fontWeight:'bolder',fontSize:15}}>Loan interest:<CurrencyFormat  value={this.state.member.loan_interestbalance} displayType={'text'} thousandSeparator={true} />  </p>

                        <p style={{fontWeight:'bolder',fontSize:15}}>Savings loan security:<CurrencyFormat  value={this.state.member.savingsloan_security_balance} displayType={'text'} thousandSeparator={true} />  </p>
                        <p style={{fontWeight:'bolder',fontSize:15}}>Supersave loan security:<CurrencyFormat  value={this.state.member.supersaveloan_security_balance} displayType={'text'} thousandSeparator={true} />  </p>
                        <p style={{fontWeight:'bolder',fontSize:15}}>Teebagoolo loan security:<CurrencyFormat  value={this.state.member.teebagoololoan_security_balance} displayType={'text'} thousandSeparator={true} />  </p>
                        <p style={{fontWeight:'bolder',fontSize:15}}>Shares loan security:<CurrencyFormat  value={this.state.member.shares_security_balance} displayType={'text'} thousandSeparator={true} />  </p>

                        <p style={{fontWeight:'bolder',fontSize:15}}>Teebagoolo Interest:<CurrencyFormat  value={this.state.member.teebagoolo_interestbalance} displayType={'text'} thousandSeparator={true} />  </p>
                        <p style={{fontWeight:'bolder',fontSize:15}}>Supersave Interest:<CurrencyFormat  value={this.state.member.supersave_interestbalance} displayType={'text'} thousandSeparator={true} />  </p>

                        </div> }
                    

                      

                      {
                        this.state.otheraccountslist.length>0?
                        <div>
                          <h3>Other Accounts</h3>
                          {
                            this.state.otheraccountslist.map((item)=>{
                            return (<p>{item.other_account_type} | {item.other_account_bank_or_telecom} | {item.other_account_number_contact} | &nbsp;
                            </p>);
                            })
                          }
                        </div>
                        :
                        null
                      }

                      </th>
                      </tr>
                    </thead>
              </reactstrp.Table>   

              {
                this.state.group_signitories.length>0?
                <div>
                  <h3>Group Signitories</h3>

                  <Table   
                  columns={columns} 
                  scroll={{ x: 1000 }}
                  dataSource={this.state.group_signitories} 
                  pagination={{showQuickJumper:true,showSizeChanger:true }}
                  bordered/>

                </div>
                :
                null
              }

              </div>

             
        <Modal              
        visible={this.state.editform_visible}
        title={"Member edit form"}
        onCancel={()=>{this.setState({editform_visible:false})}}
        width={1000}
        footer={[
          <Button key="back" onClick={()=>{this.setState({editform_visible:false})}}>
            Cancel
          </Button>
            
          ]}
          >
 <Card> 

<h4 style={{fontWeight:'bold'}}>Member Update Form</h4>
<Form
    onFinish={(event) => this.handleFormSubmit(event)} 
    >

  <FormItem label="Sur Name">
    <Input  placeholder="Put a name here." value={this.state.sur_name} onChange={this.handlesur_nameChange} />
  </FormItem>

  <FormItem label="Middle Name">
    <Input  placeholder="Put a name here." value={this.state.middle_name} onChange={this.handlemiddle_nameChange} />
  </FormItem>
  

  <FormItem label="Given Name">
    <Input  placeholder="Put a name here." value={this.state.given_name} onChange={this.handlegiven_nameChange} />
  </FormItem>

  <FormItem label="Gender">
      <Select placeholder="Gender" style={{ width: 120 }} value={this.state.gender} 
      onChange={this.handlegenderChange}
      showSearch
      optionFilterProp="children"
      onFocus={this.onFocus}
      onBlur={this.onBlur}
      onSearch={this.onSearch}   
      
      >
            <Option value="Male">Male</Option>
            <Option value="Female">Female</Option>
      </Select>
  </FormItem>

  <div style={{display:'flex',flexDirection:'row'}}>
  <FormItem label="Date of Birth"  
      style={{display:'flex',margin:3}}        
  >
        <DatePicker onChange={ this.handledate_Of_BirthChange} format={dateFormat} />
  </FormItem>
  {
  this.state.age_valid===false?
  <WarningFilled style={{ fontSize: 30,display:'flex',color:'red',alignSelf:'center' }}  />
  :
  <CheckCircleFilled style={{ fontSize: 30,display:'flex',color:'green',alignSelf:'center' }}  />
  }
  </div>


  <FormItem label="Nationality">
    <Input  placeholder="Nationality." value={this.state.nationality} onChange={this.handlenationalityChange} />
  </FormItem>


  {
    this.state.membertype==="junior"?
    null
    :
    <FormItem label="ID Type"          
>
    <Select placeholder="Id Type" 
    style={{  }} value={this.state.ID_type} 
    onChange={this.handleID_typeChange}
    
    showSearch
    optionFilterProp="children"
    onFocus={this.onFocus}
    onBlur={this.onBlur}
    onSearch={this.onSearch}
    
    >
      {this.state.idtypes.map(
        (type)=>(
          <Option value={type.id}>{type.id_type_name}</Option>
        ))}
    </Select>
</FormItem>
  }

{
    this.state.membertype==="junior"?
    null
    :
    <FormItem label="ID Number"                  
    
    >
    <Input maxLength={14}  placeholder="ID Number." value={this.state.ID_number} onChange={this.handleID_numberChange} />
    </FormItem>
  }


{
    this.state.membertype==="junior"?
    null
    :
    <div>

     <div style={{display:'flex',flexDirection:'row'}}>
      <FormItem label="ID date of expiry"
  
        style={{display:'flex',margin:3}} 
        >
            <DatePicker onChange={(date, dateString)=>{
              this.setState({id_expirydate:dateString})
              this.checkidvalidity(dateString)

            }} format={dateFormat} />
        </FormItem>
        {
        this.state.id_valid===false?
        <WarningFilled style={{ fontSize: 30,display:'flex',color:'red',alignSelf:'center' }} />
        :
        <CheckCircleFilled style={{ fontSize: 30,display:'flex',color:'green',alignSelf:'center' }} />
       }
    </div>
 </div>

  }

  
  <FormItem label="Tribe">
    <Input  placeholder="Tribe." value={this.state.tribe} onChange={this.handletribeChange} />
  </FormItem>

  <FormItem label="Language one">
    <Input  placeholder="Language one." value={this.state.language1} onChange={this.handlelanguage1Change} />
  </FormItem>

  <FormItem label="Language two">
    <Input  placeholder="Language two." value={this.state.language2} onChange={this.handlelanguage2Change} />
  </FormItem>

  <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',flexWrap:'wrap'}}>
    <FormItem label="Photo" style={{marginRight:3}}>
          <Input type="file"
          accept="image/*"
          placeholder="Member Photo"  
          onChange={this.handlePhotoChange}
          capture="environment"
          />
    </FormItem> 

    <Button icon={<CameraOutlined />}
      type="primary" onClick={this.openCamera}>
      Camera
    </Button>

      {this.state.Photo?
      <Image
      width={50}
      height={50}
      src={this.state.photofile}    
      />
      :
      null
      }

    </div>


    <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',flexWrap:'wrap'}}>
        <FormItem label="Member Signature" style={{marginRight:3}}>
            <Input type="file" accept="image/png, image/jpeg" placeholder="Member Signature"  onChange={this.handleSignatureChange}/>
        </FormItem> 


        <Button icon={<EditOutlined />}
          type="primary" 
          onClick={()=>{
          this.setState({show_sigpad:!this.state.show_sigpad})
          }}>
          Pad
        </Button>


          {this.state.Signature?
            <Image
            width={50}
            height={50}
            src={this.state.signaturefile}    
            />
            :
            null
            }


          </div>


          {/**Show signature pad here */}

          {
            this.state.show_sigpad===true?
            <div>
              <SignaturePad 
                setSignature={(val)=>{
                    this.setState({
                      signaturefile: val
                    })
                    //send to resizer
                    const newFile = this.dataURIToBlob(val);
                    this.setState({ Signature: newFile});
                    console.log(newFile);

                }}

              />
              {/*this.state.signature && <img src={this.state.signature} alt="signature preview" /> */}

            </div>
            :
            null
          }


<div style={{display:'flex',flexDirection:'row'}}>
    <FormItem label="Thumb Print" style={{marginRight:3}}>
        <Input type="file" accept="image/png, image/jpeg" placeholder="Member Thumb"  
      onChange={ async(e)=>{
        //this.setState({ Thumbprint: e.target.files[0]});
        if(e.target.files[0]){
          this.setState({
            thumbprintfile: URL.createObjectURL(e.target.files[0])
          })
        }

        const file = e.target.files[0];
        const image = await this.resizeFile(file);
        const newFile = this.dataURIToBlob(image);
        this.setState({ Thumbprint: newFile});
        console.log(file);

      }}/>
    </FormItem> 
  {this.state.Thumbprint?
    <Image
    width={50}
    height={50}
    src={this.state.thumbprintfile}    
    />
    :
    null
    }
  </div>


  <h3>Contact Section</h3>

  <FormItem label="Email Address">
  <Input  type="mail" 
          prefix={<MailOutlined className="site-form-item-icon" />} 
          placeholder="Email"
          value={this.state.Email_Address} 
          onChange={this.handleEmail_AddressChange} />
  </FormItem>

  <FormItem label="Box Number">
    <Input placeholder="box number." value={this.state.P_O_Box} onChange={this.handleP_O_BoxChange} />
  </FormItem>

  <FormItem label="Area of Residence">
    <Input placeholder="Area of residence." value={this.state.Area_Of_Residence} onChange={this.handleArea_Of_ResidenceChange} />
  </FormItem>

  <FormItem label="District">
  <Select
     placeholder="Ug District"
      style={{  }} 
      value={this.state.District}
       onChange={(val)=>{this.setState({District:val})}} 
       
       showSearch
       optionFilterProp="children"
       onFocus={this.onFocus}
       onBlur={this.onBlur}
       onSearch={this.onSearch}
       
       >
      {this.state.ugdistricts.map(
        (dist)=>(
          <Option value={dist.district_name}>{dist.district_name} | {dist.region}</Option>
        ))}
    </Select>                  </FormItem>

  <FormItem label="Phone One"                    
  >
  <PhoneInput
        countrySelectProps={{ unicodeFlags: true }}
        defaultCountry="UG"
        placeholder="Enter phone number"
        value={this.state.RegisteredPhoneNumber1} onChange={this.handleRegisteredPhoneNumber1Change}/>
  </FormItem> 

  <FormItem label="Phone Two">
  <PhoneInput
        countrySelectProps={{ unicodeFlags: true }}
        defaultCountry="UG"
        placeholder="Enter phone number"
        value={this.state.RegisteredPhoneNumber2} onChange={this.handleRegisteredPhoneNumber2Change}/>
  </FormItem>

  <h3>Personal Status Section</h3>
  <FormItem label="Title">
      <Select 
      placeholder="Title" 
      style={{ width: 120 }} 
      value={this.state.title}
       onChange={this.handletitleChange}
       showSearch
       optionFilterProp="children"
       onFocus={this.onFocus}
       onBlur={this.onBlur}
       onSearch={this.onSearch}   
       
       >
            <Option value="Mrs">Mrs</Option>
            <Option value="Mr">Mr</Option>
            <Option value="Miss">Miss</Option>
      </Select>
  </FormItem>

  <FormItem label="Marital Status">
      <Select                        showSearch
       optionFilterProp="children"
       onFocus={this.onFocus}
       onBlur={this.onBlur}
       onSearch={this.onSearch}    placeholder="Title" style={{ width: 120 }} value={this.state.marital_status} onChange={this.handlemarital_statusChange} >
            <Option value="Married">Married</Option>
            <Option value="Engaged">Engaged</Option>
            <Option value="Single">Single</Option>
      </Select>
  </FormItem>

  <FormItem label="Own Residence?">
      <Select                      
        showSearch
       optionFilterProp="children"
       onFocus={this.onFocus}
       onBlur={this.onBlur}
       onSearch={this.onSearch}  
         placeholder="Title" style={{ width: 120 }} value={this.state.own_residence} onChange={this.handleown_residenceChange} >
            <Option value="Yes">Yes</Option>
            <Option value="No">No</Option>
      </Select>
  </FormItem>


  <h3>Next of Kin Section</h3>

  <FormItem label="Next of kin">
    <Input placeholder="Next of kin." value={this.state.next_of_kin} onChange={this.handlenext_of_kinChange} />
  </FormItem>

  <FormItem label="Next of kin Address">
    <Input placeholder="Next of Kin Address." value={this.state.Next_Of_Kin_Physical_Address} onChange={this.handleNext_Of_Kin_Physical_AddressChange} />
  </FormItem>

  <FormItem label="Relationship">
    <Input placeholder="Next of kin Relationship." value={this.state.relationship} onChange={this.handlerelationshipChange} />
  </FormItem>


  <h3>Account Section</h3>
  <FormItem label="Account Type">
      <Select                        showSearch
       optionFilterProp="children"
       onFocus={this.onFocus}
       onBlur={this.onBlur}
       onSearch={this.onSearch}    placeholder="Account Type" style={{  }} value={this.state.account_type} onChange={this.handleaccount_typeChange} >
        {this.state.accounttypes.map(
          (stf)=>(
            <Option value={stf.id}>{stf.account_type_name}</Option>
          ))}
      </Select>
  </FormItem>

{/*                  <FormItem label="Account Number">
    <Input placeholder="Account Number." value={this.state.accountnumber} onChange={this.handleaccountnumberChange} />
          </FormItem> */}
  

  <FormItem label="Date of Joining">
        <DatePicker onChange={this.handleDate_Of_JoiningChange} format={dateFormat}/>
  </FormItem>


  <FormItem label="Recommender">
    <Input placeholder="Who recommended you to join sacco." value={this.state.recommender} onChange={this.handlerecommenderChange} />
  </FormItem>
  
  <div style={{display:'flex',flexDirection:'row',margin:3}}>

  <FormItem label="Any Other Account">
      <Select                        showSearch
       optionFilterProp="children"
       onFocus={this.onFocus}
       onBlur={this.onBlur}
       onSearch={this.onSearch}   
        placeholder="Any Other Account" 
        style={{ width: 120 }}
        value={this.state.any_other_account} 
        onChange={(e) => {
          this.setState({ any_other_account: e})

          if (e===false){
            this.setState({other_accounts_model_visible:false})
          }

        }} >
            <Option value="Yes">Yes</Option>
            <Option value="No">No</Option>
      </Select>            
  </FormItem>   
  {
    this.state.any_other_account==="Yes"?
    <PlusCircleFilled 
    style={{fontSize: '30px',margin:2}}
      onClick={()=>{
        this.setState({other_accounts_model_visible:true})
      }}
    />
    :
    null
  }

  </div>

  {
    this.state.otheraccountslist.length>0?
    <div>
      {
        this.state.otheraccountslist.map((item)=>{
         return (<p>{item.other_account_type} | {item.other_account_bank_or_telecom} | {item.other_account_number_contact} | &nbsp;
        <Popover content={<p>Click here to delete item</p>} title="Delete Item">
         <Popconfirm title="Sure to delete?" onConfirm={() => {
           this.setState({ otheraccountslist:  [...this.state.otheraccountslist.filter(todo => todo.key
             !==item.key)]});
         }}>
         <CloseCircleFilled style={{color:'red'}}  />
         </Popconfirm>
         </Popover>
         </p>);
        })
      }
    </div>
    :
    null
  }


  <FormItem name="Emailstatements" label="Email Monthly Financial Statements" >
      <Switch
          checked={this.state.email_statements}
          onChange={val=>{this.setState({email_statements:!this.state.email_statements})}}
          checkedChildren="ON"
          unCheckedChildren="OFF"
          />
      
  </FormItem>

  <FormItem label="Attached User">
      <Select                    
       showSearch
       optionFilterProp="children"
       onFocus={this.onFocus}
       onBlur={this.onBlur}
       onSearch={this.onSearch}   
        placeholder="Attached User" style={{  }} value={this.state.user} onChange={(val)=>{this.setState({user:val})}} >
        {this.state.users.map(
          (type)=>(
            <Option value={type.id}>{type.username}</Option>
          ))}
      </Select>
  </FormItem>

  <div style={{display:'flex',flexDirection:'row'}}>
    <FormItem label="Physical Application Form" style={{marginRight:3}}>
        <Input type="file" accept="*" placeholder="Pysical Application Form" 
        
        onChange={(val)=>{
          var kilbyes=val.target.files[0].size/1024
          if (kilbyes>Number(this.state.companyprofile.maximum_upload_document_size)){
            message.error("File is greater than "+String(this.state.companyprofile.maximum_upload_document_size)+" Kbs .It will not be uploaded.")
          }else{
            this.setState({pysical_form:val.target.files[0]})

          }
          
          }}/>
    </FormItem> 
    </div>

    {
      this.state.userrights.editaccounts?

      this.state.member.groupAccount?
        null
        :
        <FormItem>
        <Button  type="primary" htmlType="submit">Update</Button>
        </FormItem>
      :
      null
    }

</Form>

{
this.state.member.account_status==="rejected"?
<h4 style={{display:'flex',color:'green'}}>Reason For Rejection: {this.state.member.reason_for_rejection}</h4>
:
null
}

</Card>



          </Modal> 



          <Modal              
              visible={this.state.other_accounts_model_visible}
              title={"Personal account form"}
              onCancel={()=>{this.setState({other_accounts_model_visible:false})}}
              style={{width:1000}}
              footer={[
                <Button key="back" onClick={()=>{this.setState({other_accounts_model_visible:false})}}>
                  Cancel
                </Button>,
                  <Button key="ok" type='primary' 
                    onClick={()=>{
                        var intobj={
                          "key":uuid(),
                          'other_account_type':this.state.other_account_type,
                          'other_account_bank_or_telecom':this.state.other_account_bank_or_telecom,
                          'other_account_name':this.state.other_account_name,
                          'other_account_number_contact':this.state.other_account_number_contact,
                        }
                        this.setState({otheraccountslist: [...this.state.otheraccountslist, intobj]});

                       this.setState({other_accounts_model_visible:false})
                    }}>
                  Add Account
                </Button>
                ]}
                >

                <FormItem label="Account type">
                    <Select placeholder="Account type" 
                    style={{ width: 120 }} 
                    value={this.state.other_account_type} 
                    onChange={(val)=>{this.setState({other_account_type:val})}} 
                    showSearch
                    optionFilterProp="children"
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onSearch={this.onSearch}
                    >
                          <Option value="Bank">Bank</Option>
                          <Option value="Mobilemoney">Mobile money</Option>
                    </Select>            
                </FormItem>


                <FormItem label="Bank/Telecom name" >
                    <Input name="Bank/Telecom name"  placeholder="Bank/Telecom name" value={this.state.other_account_bank_or_telecom} onChange={(val)=>{this.setState({other_account_bank_or_telecom:val.target.value})}} />
                </FormItem>                  

                {
                  this.state.other_account_type==="Bank"?
                  <FormItem label="Accountname" >
                  <Input name="other_account_name"  placeholder="Acc name" value={this.state.other_account_name} onChange={(val)=>{this.setState({other_account_name:val.target.value})}} />
                 </FormItem> 

                  :null
                }

                <FormItem label="Account number / Mobile number" >
                    <Input name="other_account_name"  placeholder="Acc name" value={this.state.other_account_number_contact} onChange={(val)=>{this.setState({other_account_number_contact:val.target.value})}} />
                </FormItem> 

               </Modal> 




               <Modal
                    visible={isModalVisible}
                    footer={null}
                    onCancel={this.closeCameraModal}
                  >
                    <Webcam
                      audio={false}
                      ref={this.webcamRef}
                      screenshotFormat="image/jpeg"
                      width="100%"
                    />
                    <Button type="primary" onClick={this.captureImage} style={{ marginTop: '10px' }}>
                      Capture
                    </Button>
                  </Modal>

                  {capturedImage && (
                    <Modal
                      visible={isImageModalVisible}
                      footer={null}
                      onCancel={this.closeImageModal}
                    >
                      <img src={capturedImage} alt="Captured" style={{ width: '100%',margin:5 }} />
                    </Modal>
                  )}
            
                      
            </div>
            )

            }

          }
        
    }
}

export default MemberDetail; 