import React from 'react';
import { Form, Input, Button,Select,Spin,Result,message,Card,InputNumber,Modal,Popconfirm,Popover,Switch,Divider } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import moment from 'moment';
import { UserOutlined, LockOutlined,FundViewOutlined, LoadingOutlined,CloseCircleFilled, MailOutlined,UploadOutlined,PlusCircleOutlined,PlusCircleFilled } from '@ant-design/icons';
import {
  Col,
  Row,
} from 'reactstrap';
import * as reactstrp from 'reactstrap';
import uuid from 'react-uuid'
import CurrencyFormat from 'react-currency-format';

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const dateFormat = 'DD/MM/YYYY';

var sacco= ''
var token= ''

class LoanApplicationDetail extends React.Component {

  state = {
    date:moment().format(dateFormat).toString(),
    loan_type:'',
    account:'',
    savings_balance:0,
    loan_balance:0,
    loan_amount:0,
    loan_purpose:'',
    business_type:'',
    applicant_location:'',
    own_premises:'',
    legal_status_of_business:'',
    duration_in_business:'',
    loantypes:[],
    members:[],
    loantypeobj:{},
    accountobj:{},
    datasubmittedsuccessfully:false,
    datarequested:true,
    saccopackageobj:{},
    members:[],
    saccoboject:{},
    loanapplications:[],
    period_ofmembership:'',
    anymemberguaranteed:false,
    typeofguarantor:'Friends',

    guarantorslist:[],
    guarantorformvisible:false,
    selectedguarantor:'',
    selectedguarantorobj:{},
    guarantee_by:'',
    guarantee_value:0,
    collateraltype:'liquidity',
    liquiditycollateraltype:'savings',

    registeredbusinessdetails:{},
    regbusinessname:'',
    regbusinesscontact:'',
    regbusinesstinno:'',
    regbusinesslocation:'',
    regbizformvisible:false,

    projectinterestdetails:{},
    projintmodalvisible:false,
    projectint_proname:'',
    projectint_noofblocks_units:0,
    projectint_valueper_blocks_units:0,

    own_titledetails:{},
    propertydetails:'',
    ownproperty_valuername:'',
    ownproperty_valuerphone:'',
    ownproperty_valuercompany:'',
    ownproperty_valuercompanyaddress:'',
    ownproperty_value:0,
    ownpropertymodalvisible:false,

    totalofcollateral:0,
    g_ownsavings:false,
    g_ownsupersave:false,
    g_ownshares:false,
    g_teeba_goolo:false,
    g_ownproject_int:false,
    g_own_landtitle:false,
    g_own_reaperstitle:false,
    g_other_col:false,

    g_gua_savings:false,
    g_gua_supersave:false,
    g_gua_shares:false,
    g_gua_teeba_goolo:false,
    g_gua_project_int:false,
    g_gua_landtitle:false,
    g_gua_reaperstitle:false,
    g_gua_other_col:false,

    project_intTotal:0,

    reaperstitledetails:{},
    reaperstitle_model_visible:false,
    rpt_details:'',
    rpt_value:'',
    saccovaluers: [],
    selected_valuer:'',
    othercol_model_visible:false,
    othercol_details:'',
    othercol_value:'',
    othercollateraldetails:{},


    guar_savingsAmount:0,
    guar_supersavingsAmount:0,
    guar_sharesAmount:0,
    guar_TGAmount:0,
    guar_projintAmount:0,
    guar_owntAmount:0,
    guar_reaptAmount:0,
    guar_otherco_Amount:0,

    projectinterestdetails_guar:{},
    own_titledetails_guar:{},
    othercollateraldetails_guar:{},
    reaperstitledetails_guar:{},

    collateral_set:{},
    collateral_set_guarantor:{},
    selected_gaurantor_object:{},
    guarantor_details_model_visible:false,
    loanapplic_obj:{},
    physical_form:null,


  }
  

  componentDidMount(){

    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

      axios.defaults.headers={
        "Content-Type":"application/json",
        Authorization:`Token ${token}`
      }


      axios.get(serverconfig.backendserverurl+`/api/loantypes/?sacco=${sacco}`)
      .then(res => {
          this.setState({
            loantypes:res.data
          })
      })
  
      axios.get(serverconfig.backendserverurl+`/api/members/?sacco=${sacco}`)
      .then(res => {
          this.setState({
            members:res.data
          })
  
      })

      axios.get(serverconfig.backendserverurl+`/api/loanapplications/?sacco=${sacco}`)
      .then(res => {
          this.setState({
            loanapplications:res.data
          })
      })


      axios.get(serverconfig.backendserverurl+`/api/saccos/${sacco}`)
      .then(res => {
          this.setState({
            saccoboject:res.data
          })
  
          //get the sacco package here
          axios.get(serverconfig.backendserverurl+`/api/packages/${res.data.package}`)
          .then(res => {
              this.setState({
                saccopackageobj:res.data
              })
          })
      })

      axios.get(serverconfig.backendserverurl+`/api/saccovaluers/?sacco=${sacco}`)
      .then(res => {
          this.setState({
            saccovaluers:res.data
          })
      })


      const loanapplicationID=this.props.match.params.loanapplicationID;

      axios.get(serverconfig.backendserverurl+`/api/loanapplications/${loanapplicationID}`)
      .then(res => {
        this.setState({loanapplic_obj:res.data})

        this.setState({loan_type: res.data.loan_type})
        this.setState({account: res.data.account})
        this.setState({date: res.data.date})
        this.setState({savings_balance: res.data.savings_balance})
        this.setState({loan_balance: res.data.loan_balance})
        this.setState({loan_amount: res.data.loan_amount})
        this.setState({loan_purpose: res.data.loan_purpose})

        this.setState({business_type: res.data.business_type})
        this.setState({applicant_location: res.data.applicant_location})
        this.setState({own_premises: res.data.own_premises})
        this.setState({legal_status_of_business: res.data.legal_status_of_business})
        this.setState({collateraltype: res.data.collateraltype})
        this.setState({liquiditycollateraltype: res.data.liquiditycollateraltype})
        this.setState({typeofguarantor: res.data.typeofguarantor})

        if (res.data.guarantorslist){
          this.setState({guarantorslist: JSON.parse(res.data.guarantorslist) })

        }

        this.setState({registeredbusinessdetails:JSON.parse(res.data.registeredbusinessdetails) })
        this.setState({projectinterestdetails: JSON.parse(res.data.projectinterestdetails)})
        this.setState({owntitledetails:JSON.parse(res.data.owntitledetails) })
        this.setState({othercollateraldetails:JSON.parse(res.data.othercollateraldetails) })
        this.setState({reaperstitledetails:JSON.parse(res.data.reaperstitledetails) })

        //get collateral set
        if (res.data.collateral_set){
          this.setState({collateral_set:JSON.parse(res.data.collateral_set)})
          this.setState({g_ownsavings:JSON.parse(res.data.collateral_set).savings})
          this.setState({g_ownshares:JSON.parse(res.data.collateral_set).shares})
          this.setState({g_ownsupersave:JSON.parse(res.data.collateral_set).supersave})
          this.setState({g_teeba_goolo:JSON.parse(res.data.collateral_set).teebagoolo})
          this.setState({g_other_col:JSON.parse(res.data.collateral_set).othercolateral})
          this.setState({g_own_landtitle:JSON.parse(res.data.collateral_set).landtitle})
          this.setState({g_own_reaperstitle:JSON.parse(res.data.collateral_set).reaperstitle})
          this.setState({g_ownproject_int:JSON.parse(res.data.collateral_set).projectint})
  
        }

        //get the account and loan type
        this.handleloan_typeChange(res.data.loan_type)
        this.setState({ account: res.data.account});

        axios.get(`${serverconfig.backendserverurl}/api/members/${res.data.account}`)
        .then(res => {  
              this.setState({accountobj: res.data})
              this.setState({savings_balance:res.data.savings_balance})
              this.setState({loan_balance:res.data.loan_balance})
        })

        this.setState({datarequested:false})

      })

  }

  onBlur=()=> {
    console.log('blur');
  }
  
   onFocus=()=>{
    console.log('focus');
  }
  
  onSearch=(val)=> {
    console.log('search:', val);
  }

  //calculate member totals before savings
  calculatetotalofsavings_beforegauarator_liquidity=()=>{
    var accounttotal=0
    var availabletotal=0

    if (this.state.g_ownsavings){
      accounttotal+=(Number(this.state.accountobj.savings_balance)-Number(this.state.accountobj.savingsloan_security_balance))
    }

    if (this.state.g_ownsupersave){
      accounttotal+=(Number(this.state.accountobj.supersaver_balance)-Number(this.state.accountobj.supersaveloan_security_balance))
    }

    if (this.state.g_teeba_goolo){
      accounttotal+=(Number(this.state.accountobj.teebagoolo_balance)-Number(this.state.accountobj.teebagoololoan_security_balance))
    }

    if (this.state.g_ownshares){
      accounttotal+=(Number(this.state.accountobj.shareamount_balance)-Number(this.state.accountobj.shares_security_balance))
    }

    availabletotal=Number(accounttotal)

    return availabletotal;

  }

    //non liquiduty
    calculatetotalofsavings_beforegauarator_nonliquidity=()=>{

      var accounttotal=0
      var availabletotal=0

    if (this.state.g_ownproject_int){
      accounttotal+=Number(this.state.project_intTotal)
    }

    if (this.state.g_own_landtitle){
      accounttotal+=Number(this.state.ownproperty_value)
    }

    if (this.state.g_own_reaperstitle){
      accounttotal+=Number(this.state.rpt_value)
    }

    if (this.state.g_other_col){
      accounttotal+=Number(this.state.othercol_value)
    }

    availabletotal=Number(accounttotal)

    return availabletotal;

  }

  calculate_guarantorTotals=()=>{
    var tot=0
      //get the gurantor list
      this.state.guarantorslist.map((item)=>{
        tot+=Number(item.guarantee_value)
      })
  return tot;
  }



  //calculate the
  calculatetotalofsavingsavailable_liquidity=()=>{

    var guantortotal=0
    var availabletotal=0

    //get the gurantor list
    this.state.guarantorslist.map((item)=>{
      var liqobj=item.liguidity_items

      guantortotal+=Number(liqobj.savings)
      guantortotal+=Number(liqobj.supersave)
      guantortotal+=Number(liqobj.teebagoolo)
      guantortotal+=Number(liqobj.shares)

    })

    availabletotal=Number(this.calculatetotalofsavings_beforegauarator_liquidity())+Number(guantortotal)

    return availabletotal;
  }


 //check empty objecty
isEmptyObject=(obj)=>{
  for ( var name in obj ) {
    return false;
  }
  return true;
}


  calculatetotalofsavingsavailable_nonliquidity=()=>{

    var guantortotal=0
    var availabletotal=0

    //get the gurantor list
    this.state.guarantorslist.map((item)=>{

      if (this.isEmptyObject(item.projectinterestdetails_guar)===false){
        guantortotal+=Number(item.projectinterestdetails_guar.projectint_total)

      }

      if (this.isEmptyObject(item.own_titledetails_guar)===false){
        guantortotal+=Number(item.own_titledetails_guar.ownproperty_value)

      }

      if (this.isEmptyObject(item.othercollateraldetails_guar)===false){
        guantortotal+=Number(item.othercollateraldetails_guar.othercol_value)

      }

      if (this.isEmptyObject(item.reaperstitledetails_guar)===false){
        guantortotal+=Number(item.reaperstitledetails_guar.rpt_value)

      }

    })

    availabletotal=Number(this.calculatetotalofsavings_beforegauarator_nonliquidity())+Number(guantortotal)

    return availabletotal;

  }



// calculate total of guarantor
  calculatetotalof_guarantorsavingsavailable=()=>{
    var accounttotal=0
    var availabletotal=0

    if (this.state.g_gua_savings){
      accounttotal+=(Number(this.state.guar_savingsAmount))
    }

    if (this.state.g_gua_supersave){
      accounttotal+=(Number(this.state.guar_supersavingsAmount))
    }

    if (this.state.g_gua_teeba_goolo){
      accounttotal+=(Number(this.state.guar_TGAmount))
    }

    if (this.state.g_gua_shares){
      accounttotal+=(Number(this.state.guar_sharesAmount))
    }

    if (this.state.g_gua_project_int){
      accounttotal+=Number(this.state.guar_projintAmount)
    }

    if (this.state.g_gua_landtitle){
      accounttotal+=Number(this.state.guar_owntAmount)
    }

    if (this.state.g_gua_reaperstitle){
      accounttotal+=Number(this.state.guar_reaptAmount)
    }

    if (this.state.g_gua_other_col){
      accounttotal+=Number(this.state.guar_otherco_Amount)
    }

    availabletotal=Number(accounttotal)

    return availabletotal;

  }



  //calculate percsnteg
  calculatepercentageofavailable_liquidity=()=>{
    var perctgecal=0
    var pcnt=Number(this.state.loantypeobj.borrowing_percentagelimit_oftotal_liquidity)

    var calper=(pcnt/100)*Number(this.calculatetotalofsavingsavailable_liquidity())

    return calper;

  }

  calculatepercentageofavailable_nonliquidity=()=>{
    var perctgecal=0
    var pcnt=Number(this.state.loantypeobj.borrowing_percentagelimit_oftotal_nonliquidity)

    var calper=(pcnt/100)*Number(this.calculatetotalofsavingsavailable_nonliquidity())

    return calper;

  }


  //submit button pressed
  handleFormSubmit=(event) =>{
    var limit=this.calculatepercentageofavailable_liquidity()+this.calculatepercentageofavailable_nonliquidity()

    var newitems={
      "savings":this.state.g_ownsavings,
      "shares":this.state.g_ownshares,
      "supersave":this.state.g_ownsupersave,
      "teebagoolo":this.state.g_teeba_goolo,
      "othercolateral":this.state.g_other_col,
      "landtitle":this.state.g_own_landtitle,
      "reaperstitle":this.state.g_own_reaperstitle,
      "projectint":this.state.g_ownproject_int,
    }

    //create collateral set
    this.setState({collateral_set:newitems})

    const loanapplicationID=this.props.match.params.loanapplicationID;

    if ( Number(this.state.loan_amount)>limit){
      message.error("Entered Loan Amount should be less or equal to "+String(limit))
    }else if (Number(this.state.loan_amount)> Number(this.state.loantypeobj.maximum_loan_amount)){
      message.error("Entered Loan Amount should be less or equal to "+String(this.state.loantypeobj.maximum_loan_amount))
    }else if (this.state.g_other_col===true && Number(this.state.othercollateraldetails.othercol_value)===0)
    message.error("You have guaranteed with other collateral,of which value cant be 0, Please attach a value to submit")
    else if (this.state.g_own_reaperstitle===true && Number(this.state.reaperstitledetails.rpt_value)===0)
    message.error("You have guaranteed with reapers land title,of which value cant be 0, Please attach a value to submit")
    else if (this.state.g_own_landtitle===true && Number(this.state.own_titledetails.ownproperty_value)===0)
    message.error("You have guaranteed with own land title,of which value cant be 0, Please attach a value to submit")
    else if (this.state.g_ownproject_int===true && Number(this.state.projectinterestdetails.projectint_total)===0)
    message.error("You have guaranteed with project interest,of which value cant be 0, Please attach a value to submit")

    else{


    this.setState({datarequested:true})
    let form_data = new FormData();
    form_data.append('date', this.state.date);
    form_data.append('loan_type', this.state.loan_type);
    form_data.append('account', this.state.account);
    form_data.append('savings_balance', this.state.savings_balance);
    form_data.append('loan_balance', this.state.loan_balance);
    form_data.append('loan_amount', this.state.loan_amount);
    form_data.append('loan_purpose', this.state.loan_purpose);
    form_data.append('business_type', this.state.business_type);
    form_data.append('applicant_location', this.state.applicant_location);
    form_data.append('own_premises', this.state.own_premises);
    form_data.append('legal_status_of_business', this.state.legal_status_of_business);
    form_data.append('duration_in_business', this.state.duration_in_business);
    form_data.append('sacco', sacco);
    form_data.append('guarantorslist', JSON.stringify(this.state.guarantorslist));

    form_data.append('registeredbusinessdetails', JSON.stringify(this.state.registeredbusinessdetails));
    form_data.append('projectinterestdetails', JSON.stringify(this.state.projectinterestdetails));
    form_data.append('own_titledetails', JSON.stringify(this.state.own_titledetails));
    form_data.append('typeofguarantor', this.state.typeofguarantor);
    form_data.append('othercollateraldetails', JSON.stringify(this.state.othercollateraldetails));
    form_data.append('reaperstitledetails', JSON.stringify(this.state.reaperstitledetails));
    form_data.append('collateral_set', JSON.stringify(this.state.collateral_set));
    form_data.append('application_loan_limit', limit);

    this.state.physical_form==null?
    console.log("No physical file")
    :
    form_data.append('physical_form', this.state.physical_form, this.state.physical_form?this.state.physical_form.name:"");

    axios.put(serverconfig.backendserverurl+`/api/loanapplications/${loanapplicationID}/`, form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  .then(res =>         
    this.setState({datarequested:false})
,  this.setState({datasubmittedsuccessfully:true})
  )
  .catch(error => console.log(error))

    }

  }

  handleloan_typeChange= (e) => {
    this.setState({ loan_type: e});
    //get loan type details
    //get account details
    axios.get(`${serverconfig.backendserverurl}/api/loantypes/${e}`)
      .then(res => {  
          this.setState({loantypeobj: res.data})
      })
  }


 //check member valididity
 checkMembervalidity=(dateofentry)=>{

  var agelimit_months_cap=Number(this.state.loantypeobj.member_existence_days_bf_loanapllication)

   var dateMomentObjectone = moment(dateofentry, "DD/MM/YYYY"); // 1st argument - string, 2nd argument - format
   var dobobject = dateMomentObjectone.toDate();

   var datetoday=moment(this.state.date, "DD/MM/YYYY")

   var date1 = datetoday.toDate();
   var date2 = dobobject;

   var Difference_In_Time =  date1.getTime()-date2.getTime();
   var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

   if(Number(Difference_In_Days)>=Number(agelimit_months_cap)){
     return true;
   }else{
     return false;

   }
}


handleaccountChange= (e) =>{

 if (JSON.stringify(this.state.loantypeobj) === '{}'){
   message.info("Please select loan product first")
 }else{

 //get account details
 axios.get(`${serverconfig.backendserverurl}/api/members/${e}`)
 .then(res => {  

     if (this.checkMembervalidity(res.data.DateOfJoining)===true){
       this.setState({ account: e});
       this.setState({accountobj: res.data})
       this.setState({savings_balance:res.data.savings_balance})
       this.setState({loan_balance:res.data.loan_balance})

     }else{
       message.info("Member has not existed for at least "+String(Number(this.state.loantypeobj.member_existence_days_bf_loanapllication)/30)+" months")
     }

 })
 }

} 

  
  handlesavings_balanceChange= (e) => this.setState({ savings_balance: e.target.value});
  handleloan_balanceChange= (e) => this.setState({ loan_balance: e.target.value});
  handleloan_amountChange= (e) => this.setState({ loan_amount: e});
  handleloan_purposeChange= (e) => this.setState({ loan_purpose: e.target.value});
  handlebusiness_typeChange= (e) => this.setState({ business_type: e.target.value});
  handleapplicant_locationChange= (e) => this.setState({ applicant_location: e.target.value});
  handleown_premisesChange= (e) => this.setState({ own_premises: e});
  handlelegal_status_of_businessChange= (e) =>{

    if (e==="Registered"){
      this.setState({regbizformvisible:true})
    }else{
      this.setState({regbizformvisible:false})
    }

    this.setState({ legal_status_of_business: e});

  } 
  handleduration_in_businessChange= (e) => this.setState({ duration_in_business: e.target.value});
  handlecollateral_oneChange= (e) => this.setState({ collateral_one: e.target.value});
  handlecollateral_one_valueChange= (e) => this.setState({ collateral_one_value: e.target.value});
  handlecollateral_twoChange= (e) => this.setState({ collateral_two: e.target.value});
  handlecollateral_two_valueChange= (e) => this.setState({ collateral_two_value: e.target.value});

  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{

      if (this.state.datasubmittedsuccessfully===true){
        return(
          <Row >
            <Col xs="12" sm="6" lg="6">
            <Card>
            <Result
            status="success"
            title="Successfully Submitted loan Application "
            subTitle="Application will be ready for asessment"
            extra={[
              <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
            ]}
            />
            </Card>
            </Col>
            </Row>
        );

      }else{

        if(this.state.loanapplications.length>=this.state.saccopackageobj.loanslimit){
          return (
            <div>
              <h4 style={{color:'red'}}>You have reached loans limit for this package.Please contact adminstrators for upgrade of package.Thanx</h4>
            </div>
          );
        }else{
          return (
            <div>
                <Row >
                      <Col xs="12" sm="6" lg="6">
                        <Card>
                        <h4 style={{fontStyle:'oblique',fontWeight:'bolder'}}>LOAN APPLICATION DETAILS</h4>
                        <Form
                          onFinish={(event) => this.handleFormSubmit(event)} 
                        >
    
                        <FormItem label="Loan Type"
          
                          >
                              <Select 
                              placeholder="Loan Type" 
                              style={{  }} 
                              value={this.state.loan_type} 
                              onChange={this.handleloan_typeChange} 
                              showSearch
                              optionFilterProp="children"
                              onFocus={this.onFocus}
                              onBlur={this.onBlur}
                              onSearch={this.onSearch}
                              >
                                {this.state.loantypes.map(
                                  (stf)=>(
                                    <Option value={stf.id}>{stf.loan_type_name}</Option>
                                  ))}
                              </Select>
                          </FormItem>
    
    
                          <FormItem label="Account"
          
                          >
                              <Select 
                              placeholder="Account" 
                              style={{  }} value={this.state.account} 
                              onChange={this.handleaccountChange} 
                              showSearch
                              optionFilterProp="children"
                              onFocus={this.onFocus}
                              onBlur={this.onBlur}
                              onSearch={this.onSearch}
                              >
                                {this.state.members.map(
                                  (stf)=>(
                                    <Option value={stf.id}>{stf.AccountNumber} | {stf.FullName} </Option>
                                  ))}
                              </Select>
                          </FormItem>
  
    
                          <FormItem label="Loan Purpose"
        
                          >
                            <Input name="loan_purpose"  placeholder="Purpose" value={this.state.loan_purpose} onChange={this.handleloan_purposeChange} />
                          </FormItem>
    
                          <FormItem label="Business Type"          
                          >
                            <Input name="business_type"  placeholder="Type" value={this.state.business_type} onChange={this.handlebusiness_typeChange} />
                          </FormItem>
    
    
                          <FormItem label="Applicant Location"
           
                          >
                            <Input name="applicant_location"  placeholder="Location" value={this.state.applicant_location} onChange={this.handleapplicant_locationChange} />
                          </FormItem>
    
                          <FormItem label="Own Premises?"         
                          
                          >
                              <Select placeholder="Own Premises" style={{ width: 120 }} value={this.state.own_premises} onChange={this.handleown_premisesChange} >
                                    <Option value="Yes">Yes</Option>
                                    <Option value="No">No</Option>
                              </Select>
                          </FormItem>
    
                          <FormItem label="Legal Status Of Business"
          
                          >
                              <Select placeholder="Legal Status" style={{ width: 120 }} value={this.state.legal_status_of_business} onChange={this.handlelegal_status_of_businessChange} >
                                    <Option value="Registered">Registered</Option>
                                    <Option value="UnRegistered">UnRegistered</Option>
                              </Select>
                          </FormItem>

                          {
                            this.state.legal_status_of_business==="Registered"?
                             <h4 style={{color:'green'}}>&nbsp;(
                               Name: {this.state.registeredbusinessdetails.businessname} , 
                               Location: {this.state.registeredbusinessdetails.location} ,
                               Contact: {this.state.registeredbusinessdetails.contact} ,
                               TIN: {this.state.registeredbusinessdetails.tinno} )</h4>
                            :
                            null
                          }

    
                          <FormItem label="Duration In Business"           
                          >
                            <Input name="duration_in_business"  placeholder="Location" value={this.state.duration_in_business} onChange={this.handleduration_in_businessChange} />
                          </FormItem>

                          <h4>Collateral Set</h4>
                          
                          <div style={{display:'flex',flexDirection:'row'}}>
                          <FormItem label="Savings">
                          <Switch
                            checked={this.state.g_ownsavings}
                            onChange={val=>{this.setState({g_ownsavings:val})}}
                            checkedChildren="True"
                            unCheckedChildren="False"
                            />
                          </FormItem>

                          <FormItem label="Supersave">
                          <Switch
                            checked={this.state.g_ownsupersave}
                            onChange={val=>{this.setState({g_ownsupersave:val})}}
                            checkedChildren="True"
                            unCheckedChildren="False"
                            />
                          </FormItem>

                          <FormItem label="Shares">
                          <Switch
                            checked={this.state.g_ownshares}
                            onChange={val=>{this.setState({g_ownshares:val})}}
                            checkedChildren="True"
                            unCheckedChildren="False"
                            />
                          </FormItem> 
                          </div>


                          <div style={{display:'flex',flexDirection:'row'}}>
                        
                          <FormItem label="Teeba Goolo">
                          <Switch
                            checked={this.state.g_teeba_goolo}
                            onChange={val=>{this.setState({g_teeba_goolo:val})}}
                            checkedChildren="True"
                            unCheckedChildren="False"
                            />
                          </FormItem>

                          <FormItem label="Project Int">
                          <Switch
                            checked={this.state.g_ownproject_int}
                            onChange={val=>{
                              this.setState({g_ownproject_int:val})
                              if (val===true){
                                this.setState({projintmodalvisible:true})
                              }

                            }}
                            checkedChildren="True"
                            unCheckedChildren="False"
                            />
                          </FormItem>

                          <FormItem label="Own title">
                          <Switch
                            checked={this.state.g_own_landtitle}
                            onChange={val=>{
                              this.setState({g_own_landtitle:val})
                              if (val===true){
                                this.setState({ownpropertymodalvisible:true})
                              }
                            
                            }}
                            checkedChildren="True"
                            unCheckedChildren="False"
                            />
                          </FormItem>
                          </div>

                          <div style={{display:'flex',flexDirection:'row'}}>

                          <FormItem label="Reapers title">
                          <Switch
                            checked={this.state.g_own_reaperstitle}
                            onChange={val=>{
                              this.setState({g_own_reaperstitle:val})
                              if (val===true){
                                this.setState({reaperstitle_model_visible:true})
                              }
                              
                            }}
                            checkedChildren="True"
                            unCheckedChildren="False"
                            />
                          </FormItem>

                          <FormItem label="Other Collateral">
                          <Switch
                            checked={this.state.g_other_col}
                            onChange={val=>{
                              this.setState({g_other_col:val})
                              if (val===true){
                                this.setState({othercol_model_visible:true})
                              }
                              
                            }}
                            checkedChildren="True"
                            unCheckedChildren="False"
                            />
                          </FormItem>
                          </div>


                          {
                            this.state.g_own_landtitle===true?
                            <h4 style={{color:'green'}}>(Own Land Title details: 
                              Land Details {this.state.own_titledetails.propertydetails} ,
                              Land Value {this.state.own_titledetails.ownproperty_value},
                              Valuer name {this.state.own_titledetails.ownproperty_valuername} ,
                              valuer Phone {this.state.own_titledetails.ownproperty_valuerphone} ,
                              valuer Company {this.state.own_titledetails.ownproperty_valuercompany} ,
                              valuer Company Address {this.state.own_titledetails.ownproperty_valuercompanyaddress} 

                            )</h4>
                            :
                            null
                          }


                          {
                            this.state.g_ownproject_int===true ?
                            <h4 style={{color:'green'}}>(Project Interest details: 
                              Project Name {this.state.projectinterestdetails.projectint_proname} ,
                              No of blocks/units {this.state.projectinterestdetails.projectint_noofblocks_units} ,
                              value of block/unit {this.state.projectinterestdetails.projectint_valueper_blocks_units} ,
                              Total {this.state.projectinterestdetails.projectint_total} 
                            )</h4>
                            :
                            null
                          }

                          { 
                            this.state.g_own_reaperstitle===true ?
                            <h4 style={{color:'green'}}>(Reapers Title details: 
                              Details {this.state.reaperstitledetails.rpt_details} ,
                              Value {this.state.reaperstitledetails.rpt_value} ,
                            )</h4>
                            :
                            null
                          }


                          {
                            this.state.g_other_col===true ?
                            <h4 style={{color:'green'}}>(Other Collateral: 
                              Collateral details {this.state.othercollateraldetails.othercol_details} ,
                              Valuer {this.state.othercollateraldetails.othercol_value} 
                            )</h4>
                            :
                            null
                          }

                         <FormItem label={"Loan Request Amount ("+this.state.loan_amount+" )"}       
                          >
                          <InputNumber
                            style={{ width: 200 }} 
                            defaultValue={0.0}
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => value.replace(/\x\s?|(,*)/g, '')}
                            onChange={this.handleloan_amountChange}
                          />
                          </FormItem>

                          <div>
                          <h5>Applicant liquidity total amount (OS,SS,Shr and TG):   <CurrencyFormat value={this.calculatetotalofsavings_beforegauarator_liquidity()} displayType={'text'} thousandSeparator={true}  /> </h5>
                          <h5>Applicant non liquidity total amount (Land and others):   <CurrencyFormat value={this.calculatetotalofsavings_beforegauarator_nonliquidity()} displayType={'text'} thousandSeparator={true}  /> </h5>
                            <Divider></Divider>
                          <h5>Guarantor total amount:   <CurrencyFormat value={this.calculate_guarantorTotals()} displayType={'text'} thousandSeparator={true}  /> </h5>
                          <Divider></Divider>

                          <h5>Available total plus Guarantor amount (OS,SS,Shr and TG):   <CurrencyFormat value={this.calculatetotalofsavingsavailable_liquidity()} displayType={'text'} thousandSeparator={true}  /> </h5>
                          <h5>Available total plus Guarantor amount (Land and More):   <CurrencyFormat value={this.calculatetotalofsavingsavailable_nonliquidity()} displayType={'text'} thousandSeparator={true}  /> </h5>
                          <Divider></Divider>

                          <h5>Loan Amount Limited by percentage of available total:   ({this.state.loantypeobj.borrowing_percentagelimit_oftotal_liquidity} %) on liquid collateral (OS,SS,TG and Shrs) : <CurrencyFormat value={this.calculatepercentageofavailable_liquidity()} displayType={'text'} thousandSeparator={true}  /> </h5>
                          <h5>Loan Amount Limited by percentage of available total:   ({this.state.loantypeobj.borrowing_percentagelimit_oftotal_nonliquidity} %) on non liquid collateral (Land and others): <CurrencyFormat value={this.calculatepercentageofavailable_nonliquidity()} displayType={'text'} thousandSeparator={true}  /> </h5>
                          <h5>Total loan limit: <CurrencyFormat value={this.calculatepercentageofavailable_liquidity()+this.calculatepercentageofavailable_nonliquidity()} displayType={'text'} thousandSeparator={true}  /> </h5>

                          </div>

                          <FormItem label="Physical Application Form" style={{marginRight:3}}>
                            <Input type="file" accept=".pdf" placeholder="Physical Application Form" 
                      
                            onChange={(val)=>{
                              var kilbyes=val.target.files[0].size/1024
                              if (kilbyes>Number(this.state.companyprofile.maximum_upload_document_size)){
                                message.error("File is greater than "+String(this.state.companyprofile.maximum_upload_document_size)+" Kbs .It will not be uploaded.")
                              }else{
                                this.setState({physical_form:val.target.files[0]})
                              }
                              
                              }}/>
                          </FormItem> 
                    

                          {
                            this.state.loanapplic_obj.issued===true?
                            <h3 style={{color:'green'}}>Loan Already disbursed</h3>
                            :
                            <FormItem>
                            <Button  type="primary" htmlType="submit">Update Application</Button>
                           </FormItem>
                          }

                          
                          </Form>
  
                        </Card>
                        </Col>
    
                        <Col xs="12" sm="6" lg="6">
                        <Card>
                        <h2 style={{color:'green'}}>Totals</h2>

                        <h4>Savings Balance:<CurrencyFormat value={this.state.accountobj.savings_balance} displayType={'text'} thousandSeparator={true}  /> </h4>
                        <h4>Shares Amount Balance:<CurrencyFormat value={this.state.accountobj.shareamount_balance} displayType={'text'} thousandSeparator={true}  /> </h4>
                        <h4>Def Account Balance:<CurrencyFormat value={this.state.accountobj.def_deposit_balance} displayType={'text'} thousandSeparator={true}  /> </h4>
                        <h4>Teeba Goolo Balance:<CurrencyFormat value={this.state.accountobj.teebagoolo_balance} displayType={'text'} thousandSeparator={true}  /> </h4>
                        <h4>SuperSave Balance:<CurrencyFormat value={this.state.accountobj.supersaver_balance} displayType={'text'} thousandSeparator={true}  /> </h4>

                        <h4>Savings loan security balance:<CurrencyFormat value={this.state.accountobj.savingsloan_security_balance} displayType={'text'} thousandSeparator={true}  /> </h4>
                        <h4>Supersave loan security balance:<CurrencyFormat value={this.state.accountobj.supersaveloan_security_balance} displayType={'text'} thousandSeparator={true}  /> </h4>
                        <h4>Teebagoolo loan security balance:<CurrencyFormat value={this.state.accountobj.teebagoololoan_security_balance} displayType={'text'} thousandSeparator={true}  /> </h4>
                        <h4>Shares loan security balance:<CurrencyFormat value={this.state.accountobj.shares_security_balance} displayType={'text'} thousandSeparator={true}  /> </h4>


                        <h2 style={{color:'green'}}>Guarantors</h2>

                        <div style={{display:'flex',flexDirection:'row',margin:3}}>
                        <FormItem label="Type Of Guarantors"        
                          style={{margin:2}}
                        >
                            <Select placeholder="Type Of Guarantors" style={{ width: 120 }} value={this.state.typeofguarantor} onChange={(val)=>{this.setState({typeofguarantor:val})}} >
                                  <Option value="Friends">Friends</Option>
                                  <Option value="Family">Family</Option>
                            </Select>
                        </FormItem>

                        {
                          this.state.typeofguarantor==="Friends"&& Number(this.state.guarantorslist.length)>=Number(this.state.loantypeobj.limit_on_friendguarantors)?
                          null
                          :
                          <PlusCircleFilled 
                          style={{fontSize: '30px',margin:2}}
                           onClick={()=>{
                             this.setState({guarantorformvisible:true})
                           }}
                         />
                        }
                        </div>

                        {
                            this.state.guarantorslist.length>0?
                            <reactstrp.Table bordered>
                            <thead>
                              <tr>
                                <th>Account Name</th>
                                <th>Account Number</th>
                                <th>Value</th>
                                <th>Details</th>
                                <th>Delete</th>
                              </tr>
                            </thead>
                            <tbody>
                            {this.state.guarantorslist.map(
                              (item)=>(
                                <tr>
                                <td>{item.account_name}</td>
                                <td>{item.account_no}</td>
                                <td>{item.guarantee_value}</td>
                                <td>
                                <FundViewOutlined style={{color:'green'}} 
                                
                                onClick={()=>{
                                  console.log(item)
                                  this.setState({selected_gaurantor_object:item})
                                  this.setState({guarantor_details_model_visible:true})

                                }}
                                
                                />
                                </td>
                                <td>
                                <Popover content={<p>Click here to delete item</p>} title="Delete Item">
                                <Popconfirm title="Sure to delete?" onConfirm={() => {
                                  this.setState({ guarantorslist:  [...this.state.guarantorslist.filter(todo => todo.key
                                    !==item.key)]});
                                }}>
                                <CloseCircleFilled style={{color:'red'}} />
                                </Popconfirm>       
                                </Popover>
                                </td>
                                </tr>
                              ))}
                          </tbody>
                          </reactstrp.Table>  
                          :
                          null
                        }
                      </Card>
                      </Col>
                </Row>

            <Modal              
              visible={this.state.guarantorformvisible}
              title={"Guarantor Form"}
              onCancel={()=>{this.setState({guarantorformvisible:false})}}
              style={{width:1000}}
              footer={[
                <Button key="back" onClick={()=>{this.setState({guarantorformvisible:false})}}>
                  Cancel
                </Button>,
                  <Button key="ok" type='primary' 
                    onClick={()=>{

                    if(this.state.account===""){
                      message.info("Please first select member account")
                    }else if(this.state.selectedguarantor==="")
                      message.info("Please select guarantor account")
                    else{
                      var guarantor_savingstotal=Number(this.state.selectedguarantorobj.savings_balance)-Number(this.state.selectedguarantorobj.savingsloan_security_balance)
                      var guarantor_supersavetotal=Number(this.state.selectedguarantorobj.supersaver_balance)-Number(this.state.selectedguarantorobj.supersaveloan_security_balance)

                      var guarantor_totalpercentage=this.state.loantypeobj.guarantor_totalpercentage
                      var calpec=0

                      var limiiit=this.calculatetotalofsavings_beforegauarator_liquidity()+this.calculatetotalofsavings_beforegauarator_nonliquidity()
                      calpec=(guarantor_totalpercentage/100)*(limiiit)

                      if(Number(this.state.guar_savingsAmount)>guarantor_savingstotal){
                        message.info("Savings Account balance low")
                      }else if( Number(this.state.guar_supersavingsAmount)>guarantor_supersavetotal){
                        message.info("Super save Account balance low")
                      }else if(Number(this.calculatetotalof_guarantorsavingsavailable())> calpec){
                        message.info("Guarantor value cant be greater than "+String(guarantor_totalpercentage)+" % of "+String(this.calculatetotalofsavings_beforegauarator()) )
                      }else if (this.calculatetotalof_guarantorsavingsavailable()===0){
                        message.info("Guarantor total cant be a zero")
                      }
                      else if (this.state.g_gua_other_col===true && Number(this.state.othercollateraldetails_guar.othercol_value)===0){
                        message.error("You have guaranteed with other collateral,of which value cant be 0, Please attach a value to submit")
                      }
                      else if (this.state.g_gua_reaperstitle===true && Number(this.state.reaperstitledetails_guar.rpt_value)===0){
                        message.error("You have guaranteed with reapers land title,of which value cant be 0, Please attach a value to submit")
                      }
                      else if (this.state.g_gua_landtitle===true && Number(this.state.own_titledetails_guar.ownproperty_value)===0){
                        message.error("You have guaranteed with own land title,of which value cant be 0, Please attach a value to submit")
                      }
                      else if (this.state.g_gua_project_int===true && Number(this.state.projectinterestdetails_guar.projectint_total)===0){
                        message.error("You have guaranteed with project interest,of which value cant be 0, Please attach a value to submit")
                      }


                      else{
                        //get the collateral set for 
                        var newitems={
                          "savings":this.state.g_gua_savings,
                          "shares":this.state.g_gua_shares,
                          "supersave":this.state.g_gua_supersave,
                          "teebagoolo":this.state.g_gua_teeba_goolo,
                          "othercolateral":this.state.g_gua_other_col,
                          "landtitle":this.state.g_gua_landtitle,
                          "reaperstitle":this.state.g_gua_reaperstitle,
                          "projectint":this.state.g_gua_project_int,
                        }

                        var liqitems={
                          "savings":this.state.guar_savingsAmount,
                          "supersave":this.state.guar_supersavingsAmount,
                          "teebagoolo":this.state.guar_TGAmount,
                          "shares":this.state.guar_sharesAmount
                        }
                    
                        //create collateral set
                        this.setState({collateral_set_guarantor:newitems})

                        const gitem={
                          key:uuid(),
                          selectedguarantor:this.state.selectedguarantor,
                          guarantee_value:this.calculatetotalof_guarantorsavingsavailable(),
                          account_name:this.state.selectedguarantorobj.FullName,
                          account_no:this.state.selectedguarantorobj.AccountNumber,
                          projectinterestdetails_guar:this.state.projectinterestdetails_guar,
                          own_titledetails_guar:this.state.own_titledetails_guar,
                          othercollateraldetails_guar:this.state.othercollateraldetails_guar,
                          reaperstitledetails_guar:this.state.reaperstitledetails_guar,
                          collateral_set_guarantor:newitems,
                          liguidity_items:liqitems

                          }

                        this.setState({guarantorslist: [...this.state.guarantorslist, gitem]});
                        this.setState({guarantorformvisible:false})
                      }
                    }

                    }}>
                  Add Guarantor
                </Button>
                ]}
                >
                  <FormItem label="Account"
                      name="account"
                      rules={[
                        {
                          required: true,
                          message: 'Please select account',
                        },
                      ]}               
                  >
                      <Select 
                      placeholder="Account" 
                      style={{  }}
                      value={this.state.selectedguarantor} 
                      onChange={(val)=>{
                      this.setState({selectedguarantor:val})

                      axios.get(`${serverconfig.backendserverurl}/api/members/${val}`)
                      .then(res => {  
                          this.setState({selectedguarantorobj: res.data})
                      })

                      //do reset the previous data ////////////////////////////////////////
                      this.setState({g_gua_savings:false})
                      this.setState({g_gua_supersave:false})
                      this.setState({g_gua_shares:false})
                      this.setState({g_gua_teeba_goolo:false})

                      this.setState({guar_savingsAmount:0})
                      this.setState({guar_supersavingsAmount:0})
                      this.setState({guar_sharesAmount:0})
                      this.setState({guar_TGAmount:0})
                    
                      }} 
                      showSearch
                      optionFilterProp="children"
                      onFocus={this.onFocus}
                      onBlur={this.onBlur}
                      onSearch={this.onSearch}
                      >
                        {this.state.members.map(
                          (stf)=>{
                            if (Number(this.state.account)===Number(stf.id)){
                              return null
                            }else{
                              return (
                                <Option value={stf.id}>{stf.AccountNumber} | {stf.FullName} </Option>
                              )
                            }
                          }
                          )}
                      </Select>
                     </FormItem>

                     <br></br>
                      <h4>Savings Balance:<CurrencyFormat value={this.state.selectedguarantorobj.savings_balance} displayType={'text'} thousandSeparator={true}  /> </h4>
                      <h4>Shares Amount Balance:<CurrencyFormat value={this.state.selectedguarantorobj.shareamount_balance} displayType={'text'} thousandSeparator={true}  /> </h4>
                      <h4>Def Account Balance:<CurrencyFormat value={this.state.selectedguarantorobj.def_deposit_balance} displayType={'text'} thousandSeparator={true}  /> </h4>
                      <h4>Teeba Goolo Balance:<CurrencyFormat value={this.state.selectedguarantorobj.teebagoolo_balance} displayType={'text'} thousandSeparator={true}  /> </h4>
                      <h4>SuperSave Balance:<CurrencyFormat value={this.state.selectedguarantorobj.supersaver_balance} displayType={'text'} thousandSeparator={true}  /> </h4>

                      <h4>Savings loan security balance:<CurrencyFormat value={this.state.selectedguarantorobj.savingsloan_security_balance} displayType={'text'} thousandSeparator={true}  /> </h4>
                      <h4>Supersave loan security balance:<CurrencyFormat value={this.state.selectedguarantorobj.supersaveloan_security_balance} displayType={'text'} thousandSeparator={true}  /> </h4>
                      <h4>Teebagoolo loan security balance:<CurrencyFormat value={this.state.selectedguarantorobj.teebagoololoan_security_balance} displayType={'text'} thousandSeparator={true}  /> </h4>
                      <h4>Shares loan security balance:<CurrencyFormat value={this.state.selectedguarantorobj.shares_security_balance} displayType={'text'} thousandSeparator={true}  /> </h4>

                      <br></br>


                    <div style={{display:'flex',flexDirection:'row'}}>
                        <FormItem label="Savings">
                        <Switch
                          checked={this.state.g_gua_savings}
                          onChange={val=>{this.setState({g_gua_savings:val})}}
                          checkedChildren="True"
                          unCheckedChildren="False"
                          />
                        </FormItem>
                        
                        <FormItem label="Supersave">
                        <Switch
                          checked={this.state.g_gua_supersave}
                          onChange={val=>{this.setState({g_gua_supersave:val})}}
                          checkedChildren="True"
                          unCheckedChildren="False"
                          />
                        </FormItem>

                        <FormItem label="Shares">
                        <Switch
                          checked={this.state.g_gua_shares}
                          onChange={val=>{this.setState({g_gua_shares:val})}}
                          checkedChildren="True"
                          unCheckedChildren="False"
                          />
                        </FormItem> 
                        </div>


                        <div style={{display:'flex',flexDirection:'row'}}>
                      
                        <FormItem label="Teeba Goolo">
                        <Switch
                          checked={this.state.g_gua_teeba_goolo}
                          onChange={val=>{this.setState({g_gua_teeba_goolo:val})}}
                          checkedChildren="True"
                          unCheckedChildren="False"
                          />
                        </FormItem>

                        <FormItem label="Project Int">
                        <Switch
                          checked={this.state.g_gua_project_int}
                          onChange={val=>{
                            this.setState({g_gua_project_int:val})
                            if (val===true){
                              this.setState({projintmodalvisible:true})
                            }

                          }}
                          checkedChildren="True"
                          unCheckedChildren="False"
                          />
                        </FormItem>

                        <FormItem label="Own title">
                        <Switch
                          checked={this.state.g_gua_landtitle}
                          onChange={val=>{
                            this.setState({g_gua_landtitle:val})
                            if (val===true){
                              this.setState({ownpropertymodalvisible:true})
                            }
                          
                          }}
                          checkedChildren="True"
                          unCheckedChildren="False"
                          />
                        </FormItem>
                        </div>

                        <div style={{display:'flex',flexDirection:'row'}}>

                        <FormItem label="Reapers title">
                        <Switch
                          checked={this.state.g_gua_reaperstitle}
                          onChange={val=>{
                            this.setState({g_gua_reaperstitle:val})
                            if (val===true){
                              this.setState({reaperstitle_model_visible:true})
                            }
                            
                          }}
                          checkedChildren="True"
                          unCheckedChildren="False"
                          />
                        </FormItem>

                        <FormItem label="Other Collateral">
                        <Switch
                          checked={this.state.g_gua_other_col}
                          onChange={val=>{
                            this.setState({g_gua_other_col:val})
                            if (val===true){
                              this.setState({othercol_model_visible:true})
                            }
                            
                          }}
                          checkedChildren="True"
                          unCheckedChildren="False"
                          />
                        </FormItem>
                        </div>

                        {
                          this.state.g_gua_savings===true?
                          <FormItem label="Guarantor savings Value" >
                          <Input name="guar_savingsAmount" type='number'  placeholder="0.00" value={this.state.guar_savingsAmount} onChange={(val)=>{this.setState({guar_savingsAmount:val.target.value})}} />
                          </FormItem>     
                          :
                          null
                        }

                        {
                          this.state.g_gua_supersave===true?
                          <FormItem label="Guarantor super savings Value" >
                          <Input name="guar_supersavingsAmount" type='number'  placeholder="0.00" value={this.state.guar_supersavingsAmount} onChange={(val)=>{this.setState({guar_supersavingsAmount:val.target.value})}} />
                          </FormItem>     
                          :
                          null
                        }

                        {
                          this.state.g_gua_shares===true?
                          <FormItem label="Guarantor Shares Value" >
                          <Input name="guar_sharesAmount" type='number'  placeholder="0.00" value={this.state.guar_sharesAmount} onChange={(val)=>{this.setState({guar_sharesAmount:val.target.value})}} />
                          </FormItem>     
                          :
                          null
                        }                        


                        {
                          this.state.g_gua_teeba_goolo===true?
                          <FormItem label="Guarantor Teeba goolo Value" >
                          <Input name="guar_TGAmount" type='number'  placeholder="0.00" value={this.state.guar_TGAmount} onChange={(val)=>{this.setState({guar_TGAmount:val.target.value})}} />
                          </FormItem>     
                          :
                          null
                        } 


                        {
                            this.state.g_gua_landtitle===true?
                            <h4 style={{color:'green'}}>(Own Land Title details: 
                              Land Details {this.state.own_titledetails_guar.propertydetails} ,
                              Land Value {this.state.own_titledetails_guar.ownproperty_value},
                              Valuer name {this.state.own_titledetails_guar.ownproperty_valuername} ,
                              valuer Phone {this.state.own_titledetails_guar.ownproperty_valuerphone} ,
                              valuer Company {this.state.own_titledetails_guar.ownproperty_valuercompany} ,
                              valuer Company Address {this.state.own_titledetails_guar.ownproperty_valuercompanyaddress} 

                            )</h4>
                            :
                            null
                          }


                          {
                            this.state.g_gua_project_int===true ?
                            <h4 style={{color:'green'}}>(Project Interest details: 
                              Project Name {this.state.projectinterestdetails_guar.projectint_proname} ,
                              No of blocks/units {this.state.projectinterestdetails_guar.projectint_noofblocks_units} ,
                              value of block/unit {this.state.projectinterestdetails_guar.projectint_valueper_blocks_units} ,
                              Total {this.state.projectinterestdetails_guar.projectint_total} 
                            )</h4>
                            :
                            null
                          }

                          { 
                            this.state.g_gua_reaperstitle===true ?
                            <h4 style={{color:'green'}}>(Reapers Title details: 
                              Details {this.state.reaperstitledetails_guar.rpt_details} ,
                              Value {this.state.reaperstitledetails_guar.rpt_value} ,
                            )</h4>
                            :
                            null
                          }


                          {
                            this.state.g_gua_other_col===true ?
                            <h4 style={{color:'green'}}>(Other Collateral: 
                              Collateral details {this.state.othercollateraldetails_guar.othercol_details} ,
                              Valuer {this.state.othercollateraldetails_guar.othercol_value} 
                            )</h4>
                            :
                            null
                          }

                     <h3>Guarantor Total {this.calculatetotalof_guarantorsavingsavailable()}</h3>

               </Modal> 


               <Modal              
              visible={this.state.regbizformvisible}
              title={"Registered Business Details Form"}
              onCancel={()=>{this.setState({regbizformvisible:false})}}
              style={{width:1000}}
              footer={[
                <Button key="back" onClick={()=>{this.setState({regbizformvisible:false})}}>
                  Cancel
                </Button>,
                  <Button key="ok" type='primary' 
                    onClick={()=>{
                      var bizobj={
                        'businessname':this.state.regbusinessname,
                        'location':this.state.regbusinesslocation,
                        'contact':this.state.regbusinesscontact,
                        'tinno':this.state.regbusinesstinno,
                      }
                      
                      this.setState({registeredbusinessdetails:bizobj})
                      this.setState({regbizformvisible:false})
                    }}>
                  Add Details
                </Button>
                ]}
                >
                  
                <FormItem label="Business name" >
                    <Input name="regbusinessname"  placeholder="Business Name" value={this.state.regbusinessname} onChange={(val)=>{this.setState({regbusinessname:val.target.value})}} />
                </FormItem>                  

                <FormItem label="Business location" >
                    <Input name="regbusinesslocation"  placeholder="Business Location" value={this.state.regbusinesslocation} onChange={(val)=>{this.setState({regbusinesslocation:val.target.value})}} />
                </FormItem> 

                <FormItem label="Business contact" >
                    <Input name="regbusinesscontact"  placeholder="Business Contact" value={this.state.regbusinesscontact} onChange={(val)=>{this.setState({regbusinesscontact:val.target.value})}} />
                </FormItem> 

                <FormItem label="Business Tin Number" >
                    <Input name="regbusinesstinno"  placeholder="Business Tin No." value={this.state.regbusinesstinno} onChange={(val)=>{this.setState({regbusinesstinno:val.target.value})}} />
                </FormItem> 

               </Modal> 


               <Modal              
              visible={this.state.projintmodalvisible}
              title={"Project Interest Details Form"}
              onCancel={()=>{this.setState({projintmodalvisible:false})}}
              style={{width:1000}}
              footer={[
                <Button key="back" onClick={()=>{this.setState({projintmodalvisible:false})}}>
                  Cancel
                </Button>,
                  <Button key="ok" type='primary' 
                    onClick={()=>{

                      if (this.state.guarantorformvisible===true){
                        var intobj={
                          'projectint_proname':this.state.projectint_proname,
                          'projectint_noofblocks_units':this.state.projectint_noofblocks_units,
                          'projectint_valueper_blocks_units':this.state.projectint_valueper_blocks_units,
                          'projectint_total':(Number(this.state.projectint_noofblocks_units)*Number(this.state.projectint_valueper_blocks_units)),
                        }
  
                        this.setState({projectinterestdetails_guar:intobj})
                        //set project int total
                        this.setState({guar_projintAmount:(Number(this.state.projectint_noofblocks_units)*Number(this.state.projectint_valueper_blocks_units))})
  
                      }else{
                        var intobj={
                          'projectint_proname':this.state.projectint_proname,
                          'projectint_noofblocks_units':this.state.projectint_noofblocks_units,
                          'projectint_valueper_blocks_units':this.state.projectint_valueper_blocks_units,
                          'projectint_total':(Number(this.state.projectint_noofblocks_units)*Number(this.state.projectint_valueper_blocks_units)),
                        }
  
                        this.setState({projectinterestdetails:intobj})
  
                        //set project int total
                        this.setState({project_intTotal:(Number(this.state.projectint_noofblocks_units)*Number(this.state.projectint_valueper_blocks_units))})
  
                      }

                      this.setState({projintmodalvisible:false})
                    }}>
                  Add Details
                </Button>
                ]}
                >
                  
                <FormItem label="Project Name" >
                    <Input name="projectint_proname"  placeholder="Project Name" value={this.state.projectint_proname} onChange={(val)=>{this.setState({projectint_proname:val.target.value})}} />
                </FormItem>                  

                <FormItem label="Number of blocks/units" >
                    <Input name="projectint_noofblocks_units"  placeholder="Number of blocks/units" value={this.state.projectint_noofblocks_units} onChange={(val)=>{this.setState({projectint_noofblocks_units:val.target.value})}} />
                </FormItem> 

                <FormItem label="Value Per block/unit" >
                    <Input name="projectint_valueper_blocks_units"  placeholder="Value Per block/unit" value={this.state.projectint_valueper_blocks_units} onChange={(val)=>{this.setState({projectint_valueper_blocks_units:val.target.value})}} />
                </FormItem> 

               </Modal> 


              <Modal              
              visible={this.state.ownpropertymodalvisible}
              title={"Own Title Details Form"}
              onCancel={()=>{this.setState({ownpropertymodalvisible:false})}}
              style={{width:1000}}
              footer={[
                <Button key="back" onClick={()=>{this.setState({ownpropertymodalvisible:false})}}>
                  Cancel
                </Button>,
                  <Button key="ok" type='primary' 
                    onClick={()=>{

                      if (this.state.guarantorformvisible===true){
                        var intobj={
                          'propertydetails':this.state.propertydetails,
                          'ownproperty_valuername':this.state.ownproperty_valuername,
                          'ownproperty_valuerphone':this.state.ownproperty_valuerphone,
                          'ownproperty_valuercompany':this.state.ownproperty_valuercompany,
                          'ownproperty_valuercompanyaddress':this.state.ownproperty_valuercompanyaddress,
                          'ownproperty_value':this.state.ownproperty_value,
                        }

                        this.setState({guar_owntAmount:this.state.ownproperty_value})
                        this.setState({own_titledetails_guar:intobj})
                      }else{
                        var intobj={
                          'propertydetails':this.state.propertydetails,
                          'ownproperty_valuername':this.state.ownproperty_valuername,
                          'ownproperty_valuerphone':this.state.ownproperty_valuerphone,
                          'ownproperty_valuercompany':this.state.ownproperty_valuercompany,
                          'ownproperty_valuercompanyaddress':this.state.ownproperty_valuercompanyaddress,
                          'ownproperty_value':this.state.ownproperty_value,
                        }
  
                        this.setState({own_titledetails:intobj})
                      }


                      this.setState({ownpropertymodalvisible:false})
                    }}>
                  Add Details
                </Button>
                ]}
                >
                  
                <FormItem label="Land Details" >
                    <Input name="propertydetails"  placeholder="Land details" value={this.state.propertydetails} onChange={(val)=>{this.setState({propertydetails:val.target.value})}} />
                </FormItem>                  

                <FormItem label="Land Value" >
                    <Input name="ownproperty_value"  placeholder="Land Value" value={this.state.ownproperty_value} onChange={(val)=>{this.setState({ownproperty_value:val.target.value})}} />
                </FormItem> 

                <FormItem label="Valuer name" >
                    <Input name="ownproperty_valuername"  placeholder="Valuer name" value={this.state.ownproperty_valuername} onChange={(val)=>{this.setState({ownproperty_valuername:val.target.value})}} />
                </FormItem> 

                <FormItem label="Valuer Contact" >
                    <Input name="ownproperty_valuerphone"  placeholder="Valuer Contact" value={this.state.ownproperty_valuerphone} onChange={(val)=>{this.setState({ownproperty_valuerphone:val.target.value})}} />
                </FormItem> 

                <FormItem label="Valuer company name" >
                    <Input name="ownproperty_valuercompany"  placeholder="Valuer company name" value={this.state.ownproperty_valuercompany} onChange={(val)=>{this.setState({ownproperty_valuercompany:val.target.value})}} />
                </FormItem> 

                <FormItem label="Valuer company address" >
                    <Input name="ownproperty_valuercompanyaddress"  placeholder="Valuer company address" value={this.state.ownproperty_valuercompanyaddress} onChange={(val)=>{this.setState({ownproperty_valuercompanyaddress:val.target.value})}} />
                </FormItem> 

               </Modal> 



               <Modal              
              visible={this.state.reaperstitle_model_visible}
              title={"Reapes Title Details Form"}
              onCancel={()=>{this.setState({reaperstitle_model_visible:false})}}
              style={{width:1000}}
              footer={[
                <Button key="back" onClick={()=>{this.setState({reaperstitle_model_visible:false})}}>
                  Cancel
                </Button>,
                  <Button key="ok" type='primary' 
                    onClick={()=>{
                      if (this.state.guarantorformvisible===true){
                        var intobj={
                          'rpt_details':this.state.rpt_details,
                          'rpt_value':this.state.rpt_value,
                          'selected_valuer':this.state.selected_valuer
                        }
  
                        this.setState({guar_reaptAmount:this.state.rpt_value})
                        this.setState({reaperstitledetails_guar:intobj})
                      }else{
                        var intobj={
                          'rpt_details':this.state.rpt_details,
                          'rpt_value':this.state.rpt_value,
                          'selected_valuer':this.state.selected_valuer
                        }
  
                        this.setState({reaperstitledetails:intobj})
                      }

                      this.setState({reaperstitle_model_visible:false})
                    }}>
                  Add Details
                </Button>
                ]}
                >
                  
                <FormItem label="Land Details" >
                    <Input name="propertydetails"  placeholder="Land details" value={this.state.rpt_details} onChange={(val)=>{this.setState({rpt_details:val.target.value})}} />
                </FormItem>                  

                <FormItem label="Land Value" >
                    <Input name="ownproperty_value"  placeholder="Land Value" value={this.state.rpt_value} onChange={(val)=>{this.setState({rpt_value:val.target.value})}} type='number' />
                </FormItem> 

               <FormItem label="Sacco land valuer">
                <Select placeholder="Sacco land valuer" style={{  }} value={this.state.selected_valuer} onChange={(val)=>{this.setState({selected_valuer:val})}} >
                  {this.state.saccovaluers.map(
                    (stf)=>(
                      <Option value={stf.id}>{stf.full_name}</Option>
                    ))}
                </Select>
               </FormItem>

               </Modal> 


              <Modal              
              visible={this.state.othercol_model_visible}
              title={"Other Collateral Details Form"}
              onCancel={()=>{this.setState({othercol_model_visible:false})}}
              style={{width:1000}}
              footer={[
                <Button key="back" onClick={()=>{this.setState({othercol_model_visible:false})}}>
                  Cancel
                </Button>,
                  <Button key="ok" type='primary' 
                    onClick={()=>{
                      if (this.state.guarantorformvisible===true){
                        var intobj={
                          'othercol_details':this.state.othercol_details,
                          'othercol_value':this.state.othercol_value,
                        }
  
                        this.setState({othercollateraldetails_guar:intobj})
                        this.setState({guar_otherco_Amount:this.state.othercol_value})

                      }else{
                        var intobj={
                          'othercol_details':this.state.othercol_details,
                          'othercol_value':this.state.othercol_value,
                        }
  
                        this.setState({othercollateraldetails:intobj})
                      }

                      this.setState({othercol_model_visible:false})
                    }}>
                  Add Details
                </Button>
                ]}
                >
                  
                <FormItem label="Colateral Details" >
                    <Input name="othercol_details"  placeholder="colateral details" value={this.state.othercol_details} onChange={(val)=>{this.setState({othercol_details:val.target.value})}} />
                </FormItem>                  

                <FormItem label="Collateral Value" >
                    <Input name="othercol_value"  placeholder="Collateral Value" value={this.state.othercol_value} onChange={(val)=>{this.setState({othercol_value:val.target.value})}} type='number' />
                </FormItem> 
               </Modal> 


              <Modal              
              visible={this.state.guarantor_details_model_visible}
              title={"Guarantor Details"}
              onCancel={()=>{this.setState({guarantor_details_model_visible:false})}}
              style={{width:1000}}
              footer={[
                <Button key="back" onClick={()=>{this.setState({guarantor_details_model_visible:false})}}>
                  Cancel
                </Button>
                 
                ]}
                >
                  {
                    JSON.stringify(this.state.selected_gaurantor_object) === '{}'?
                    null
                    :
                    <div>
                      <h6> Account Name: {this.state.selected_gaurantor_object.account_name}</h6>
                      <h6> Account Number: {this.state.selected_gaurantor_object.account_no}</h6>
                     
                      {
                        this.state.selected_gaurantor_object.collateral_set_guarantor.savings===true?
                        <h6>Savings: {this.state.selected_gaurantor_object.liguidity_items.savings}</h6>
                        :
                        null
                      }

                      {
                        this.state.selected_gaurantor_object.collateral_set_guarantor.shares===true?
                        <h6>Shares: {this.state.selected_gaurantor_object.liguidity_items.shares}</h6>

                        :
                        null}

                      {
                        this.state.selected_gaurantor_object.collateral_set_guarantor.supersave===true?
                        <h6>Supersave: {this.state.selected_gaurantor_object.liguidity_items.supersave}</h6>

                        :
                        null}

                      {
                        this.state.selected_gaurantor_object.collateral_set_guarantor.teebagoolo===true?
                        <h6>Teebagoolo: {this.state.selected_gaurantor_object.liguidity_items.teebagoolo}</h6>

                        :
                        null}

                      {
                        this.state.selected_gaurantor_object.collateral_set_guarantor.landtitle===true?
                        <h4 style={{color:'green'}}>(Own Land Title details: 
                          Land Details {this.state.selected_gaurantor_object.own_titledetails_guar.propertydetails} ,
                          Land Value {this.state.selected_gaurantor_object.own_titledetails_guar.ownproperty_value},
                          Valuer name {this.state.selected_gaurantor_object.own_titledetails_guar.ownproperty_valuername} ,
                          valuer Phone {this.state.selected_gaurantor_object.own_titledetails_guar.ownproperty_valuerphone} ,
                          valuer Company {this.state.selected_gaurantor_object.own_titledetails_guar.ownproperty_valuercompany} ,
                          valuer Company Address {this.state.selected_gaurantor_object.own_titledetails_guar.ownproperty_valuercompanyaddress} 

                        )</h4>
                        :
                        null
                      }


                      {
                        this.state.selected_gaurantor_object.collateral_set_guarantor.projectint===true ?
                        <h4 style={{color:'green'}}>(Project Interest details: 
                          Project Name {this.state.selected_gaurantor_object.projectinterestdetails_guar.projectint_proname} ,
                          No of blocks/units {this.state.selected_gaurantor_object.projectinterestdetails_guar.projectint_noofblocks_units} ,
                          value of block/unit {this.state.selected_gaurantor_object.projectinterestdetails_guar.projectint_valueper_blocks_units} ,
                          Total {this.state.selected_gaurantor_object.projectinterestdetails_guar.projectint_total} 
                        )</h4>
                        :
                        null
                      }

                      { 
                        this.state.selected_gaurantor_object.collateral_set_guarantor.reaperstitle===true ?
                        <h4 style={{color:'green'}}>(Reapers Title details: 
                          Details {this.state.selected_gaurantor_object.reaperstitledetails_guar.rpt_details} ,
                          Value {this.state.selected_gaurantor_object.reaperstitledetails_guar.rpt_value} ,
                        )</h4>
                        :
                        null
                      }


                      {
                        this.state.selected_gaurantor_object.collateral_set_guarantor.othercolateral===true ?
                        <h4 style={{color:'green'}}>(Other Collateral: 
                          Collateral details {this.state.selected_gaurantor_object.othercollateraldetails_guar.othercol_details} ,
                          Valuer {this.state.selected_gaurantor_object.othercollateraldetails_guar.othercol_value} 
                        )</h4>
                        :
                        null
                      }

                    <h3> Total Value: {this.state.selected_gaurantor_object.guarantee_value}</h3>

                    </div>
                  }
               </Modal> 


            </div>
          );
        }

      }
    }

  }

}

export default LoanApplicationDetail;



