import React from 'react';

import axios from 'axios'
import { Table, Input, Button,Collapse,Popover,Form,Popconfirm,message,Tabs,Descriptions,Modal,Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DeleteOutlined,FundViewOutlined,RollbackOutlined,DownloadOutlined,WalletFilled,LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import moment from 'moment';

import ApplicationSchedule from './ApplicationSchedule';
import LoanDetailsTab from '../components/LoanDetailsTab';


var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { TextArea } = Input;
const FormItem=Form.Item;
const dateFormat = 'DD/MM/YYYY';
const { TabPane } = Tabs;


function callback(key) {
  console.log(key);
}

var sacco= ''
var bizuserid= ''
var token= ''
var username=''


class LoanApprovalList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    loanapplications: [],
    viewpaymentmodal:false,
    expensedetails:{},
    userprofile:{},
    datarequested:true,

    userrights:{},
    reversal_modalvisible:false,
    selected_aplication:{},
    reversal_reason:'',
    date:moment().format(dateFormat).toString(),


  };

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)

    }else{
       sacco= ''
       bizuserid= ''
       username=''

    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/loanapplications/?sacco=${sacco}&assessed=${true}&passed=${true}&approval_yet=${true}&issued=${false}`)
    .then(res => {
        this.setState({
          loanapplications:res.data
        })
    })


    axios.get(serverconfig.backendserverurl+`/api/accountrights/?user=${bizuserid}`)
    .then(res => {
        this.setState({
          userrights:res.data[0]
        })
        this.setState({datarequested:false})
    })
  

}


    //////////////////////////////////////////////////
  //handle delete here
  handleDelete = id => {
    axios.delete(`${serverconfig.backendserverurl}/api/loanapplications/${id}`).then(res =>{
      window.location.reload(false)
      message.info("successfully deleted") 
     }
     );
   
  };


getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};

  render() {

      var columns = [
        {
          title: 'Date of approval',
          dataIndex: 'date_of_approval',
          key: 'id',
          ...this.getColumnSearchProps('date_of_approval'),
        },
        {
          title: 'Account Number',
          dataIndex: 'accountNumber',
          key: 'id',
          ...this.getColumnSearchProps('accountNumber'),
        },
        {
          title: 'Account Name',
          dataIndex: 'accountName',
          key: 'id',
          ...this.getColumnSearchProps('accountName'),
        },
        {
          title: 'Loan top app',
          dataIndex: 'loan_topup',
          key: 'id',
          ...this.getColumnSearchProps('accountNumber'),
          render:(text,record)=><h3>{record.loan_topup?"Yes":"No"}</h3>
        },
        {
          title: 'Topup amount',
          dataIndex: 'loan_topupamount',
          key: 'id',
          render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
        },
        {
          title: 'Topup loan balance',
          dataIndex: 'topup_loanbalance',
          key: 'id',
          render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
        },
        {
          title: 'Total Request Amount',
          dataIndex: 'loan_amount',
          key: 'id',
          render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
        },
        {
          title: 'Approved Amount',
          dataIndex: 'approved_amount',
          key: 'id',
          render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
        },
        {
          title: 'Approved period',
          dataIndex: 'approved_period',
          key: 'id',
          ...this.getColumnSearchProps('approved_period'),
        },
        
        {
          title: 'Action',
          dataIndex: 'id',
          key: 'id',
          render: (text,record) =>
          <p>

          {
              this.state.userrights.can_reverse_loan_process===true?
              <Popover content={<p>Click here to rollback to assessment </p>} 
              title="Rollback">
                <Popconfirm title="Sure to rollback?" onConfirm={() => {
                    this.setState({selected_aplication:record})
                    this.setState({reversal_modalvisible:true})
                  }}>

                <RollbackOutlined style={{color:'blue'}} />
                </Popconfirm>
              </Popover>
              :
              null
            }

          &nbsp;&nbsp;&nbsp;
          {
           this.state.userrights.can_issue_loans===true?
          <Popover content={<p>Click here to Disburse Loan</p>} title="Loan Disbursement">
            <Link to={`/issueloan/${text}`}><FundViewOutlined style={{color:'green'}}/></Link>
          </Popover>
          :null}


          </p>,
        }
  
      ];
    
    
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return(
        <div>
            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header={<h4 style={{fontWeight:'bolder'}}>APPROVED LOAN APPLICATIONS</h4>} key="1">
            <Table   
            columns={columns} 
            scroll={{ x: 1000 }}
            dataSource={this.state.loanapplications} 
            pagination={{showQuickJumper:true,showSizeChanger:true }}
            bordered
            
            expandable={{
              expandedRowRender: (record) =>{
                return (
                <div
                  style={{
                    margin: 0,
                  }}
                  >

                      <Tabs defaultActiveKey="1" onChange={()=>{}}>

                      <TabPane tab="Application Details" key="1">
                      <LoanDetailsTab 
                        loanapplicationID={record.id}
                        />

                    </TabPane>

                    <TabPane tab="Schedule" key="2">
                      <ApplicationSchedule 
                      application_id={record.id} 
                      application_obj={record}
                      />
                    </TabPane>

                    </Tabs>

                    
                </div>
                );
               
              } ,
              rowExpandable: (record) => true,
              onExpand:(condition,record)=>{
              },
            }} 
            
            
            />
            <br />
            </Panel>
            </Collapse>


            <Modal              
              visible={this.state.reversal_modalvisible}
              title={"Reversal reason Form"}
              onCancel={()=>{this.setState({reversal_modalvisible:false})}}
              style={{width:1000}}
              footer={[
                <Button key="back" onClick={()=>{this.setState({reversal_modalvisible:false})}}>
                  Cancel
                </Button>,
                <Button key="ok" type='primary' onClick={()=>{

                  let form_data = new FormData();
                  form_data.append('applicationid', this.state.selected_aplication.id);
                  form_data.append('reversal_reason', this.state.reversal_reason);
                  form_data.append('username', username);
                  form_data.append('date', this.state.date);

                  //Now submit sale data to database
                  axios.post(serverconfig.backendserverurl+'/customqueries/loan_rollback_toassessment', form_data,{
                    headers: {
                      'content-type': 'multipart/form-data'
                    }})
                  .then(res =>{
                    this.setState({datarequested:false})
                    window.location.reload(false)
                  }).catch(error => console.log(error)) 

                  this.setState({reversal_modalvisible:false})

                  }}>
                Submit
              </Button>,
                ]}
                >
                  <FormItem 
                  label={<h4 style={{fontWeight:'bold'}}>Reason for reversal to assessment stage.</h4>}>
                  <TextArea
                  placeholder="Reason for reversal"
                  autoSize={{ minRows: 2, maxRows: 8 }}
                  value={this.state.reversal_reason}
                  onChange={(val)=>{this.setState({reversal_reason:val.target.value})}}
                  />

                  </FormItem>
            </Modal> 

        </div>
    )


    }


    
  }
}

export default LoanApprovalList; 
