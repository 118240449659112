import React,{ useRef } from 'react';
import axios from 'axios'
import { Table, Input, Button,Collapse,Avatar,Modal,Form,DatePicker,Select,Space,Popconfirm,Typography,Divider,Switch,InputNumber,message,Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { PrinterOutlined, MinusOutlined, CloseCircleFilled, CheckCircleOutlined, UserOutlined } from '@ant-design/icons';
import { SearchOutlined,CameraOutlined,PlusOutlined,DownloadOutlined,LoadingOutlined,DeleteOutlined } from '@ant-design/icons';
import QrReader from 'react-qr-reader'
import uuid from 'react-uuid'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
import PrintTemplate from 'react-print';
import ReactToPrint from 'react-to-print';
import * as serverconfig from '../serverconn'
import CurrencyFormat from 'react-currency-format';
import * as reactstrp from 'reactstrap';

import {
  Col,
  Row,
} from 'reactstrap';
import ReactExport from "@ibrahimrahmani/react-export-excel";
import '../tablestyle.css'; // Create this CSS file for custom styles

var CryptoJS = require("crypto-js");

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { Title } = Typography;
const { RangePicker } = DatePicker;
const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { Search } = Input;

var sacco= ''
var bizuserid= ''
var token= ''

class ReportToPrint extends React.Component {

    state = {
      companyprofile:{},
    };
  
    componentDidMount(){

      if(localStorage.getItem("sacco")){
         sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
         bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
      
      }else{
         sacco= ''
         bizuserid= ''
      }
      
      if(localStorage.getItem("token")){
         token= localStorage.getItem("token")
      }else{
         token= ''
      }

      axios.defaults.headers={
        "Content-Type":"application/json",
        Authorization:`Token ${token}`
      }

      axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
      .then(res => {  
          this.setState({
            companyprofile: res.data
          })
  
      })

    }

    calculateTotalDebit=()=>{
      var total=0
  
      this.props.sheetitems.map((item)=>{
        total+=Number(item.Debit)
  
      })
      return total;
  
    }
  
    calculateTotalCredit=()=>{
      var total=0
  
      this.props.sheetitems.map((item)=>{
        total+=Number(item.Credit)
  
      })
      return total;
  
    }
  
    render() {
      return (
        <div style={{padding:20}}>
 <Row> 
                <Col xs="2" sm="2" lg="2">
                <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.sacco_logo} />
              
                  </Col>
                  <Col xs="6" sm="6" lg="6">
                  <h3>{this.state.companyprofile.sacco_name}</h3>
                  <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                  {
                  this.state.companyprofile.RegisteredPhoneNumber2==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber2
                  }
                  {
                  this.state.companyprofile.RegisteredPhoneNumber3==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber3
                  }
                  </h6>               
                  <h5>Email: {this.state.companyprofile.company_email}</h5>
                  <h5>Wesite: {this.state.companyprofile.website}</h5>
                  <h5>{this.state.companyprofile.box_number}</h5>
                
                  </Col>

       </Row>

             <h3 style={{display: 'flex',justifyContent:'center', alignItems:'center',fontWeight:'bolder'}}>CASHFLOW REPORT FROM: {this.props.dateone}  TO: {this.props.datetwo}</h3>

             <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>No</th>
                          <th>Date</th>
                          <th>Account</th>
                          <th>Desc</th>
                          <th>Journal No</th>
                          <th>User</th>
                          <th>Debit (Receipts)</th>
                          <th>Credit (Payments) </th>
                          <th>Total cash Balance</th>
                          <th>Attached member</th>
                        </tr>
                      </thead>
                      <tbody>
                      {this.props.sheetitems.map(
                        (item)=>(
                          <tr>
                          <td>{item.item_no}</td>
                          <td>{item.date}</td>
                          <td>{item.account}</td>
                          <td>{item.description}</td>
                          <td>{item.journal_no}</td>
                          <td>{item.user}</td>
                          <td className="align-right">{<CurrencyFormat value={item.Debit} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td className="align-right">{<CurrencyFormat value={item.Credit} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td className="align-right">{<CurrencyFormat value={item.total_inst_balance} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td></td>
                          </tr>
                        ))}
                        <tr>
                        <td style={{fontWeight:'bolder'}}>Total</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>

                        <td></td>
                        <td className="align-right"style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalDebit()} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td className="align-right"style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalCredit()} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td></td>
                        <td></td>

                        </tr>
                    </tbody>
                </reactstrp.Table> 
        </div>
      );
    }
  }


class CashFlowReport extends React.Component {

  state = {
    users:[],
    sheetitems:[],
    sheetitems_placeholders:[],

    dateone:moment().format(dateFormat).toString(),
    datetwo:moment().format(dateFormat).toString(),
    entrant:'',
    entrantname:'',
    totaldebit:0,
    totalcredit:0,
    datarequested:false,
    searchbyjno:false,
    journalno:0,

    selected_jounal:'',
    selected_jounal_obj:{},
    jounalform_visible:false,
    journalcredit_amount:0,
    journaldebit_amount:0,
    journal_description:'',
    journal_date:'',
    companyprofile:{},
    searchbymemeber:false,
    selectedaccount:'',
    journal_account:'',
    userrights:{},
    total_inst_balance:0,
    cashflow_accounts_list:[],
    selected_laccount:''


   };

   componentDidMount(){
    if(localStorage.getItem("sacco")){
      sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
   
   }else{
      sacco= ''
      bizuserid= ''
   }
   
   if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/accounts/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          users:res.data
        })
      })


      axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
      .then(res => {  
          this.setState({
            companyprofile: res.data
          })

          this.setState({cashflow_accounts_list:res.data.cashflow_accounts_list!=null?JSON.parse(res.data.cashflow_accounts_list):[]})

      })



      axios.get(serverconfig.backendserverurl+`/api/accountrights/?user=${bizuserid}`)
      .then(res => {
          this.setState({
            userrights:res.data[0]
          })


          this.setState({datarequested:false})

      })

      //get initial report
      this.searchSales()

  }
 

   handleentrantChange= (e) => this.setState({ entrant: e});

   //search sales method
   searchSales=()=>{

      let form_data = new FormData();
      form_data.append('dateone', this.state.dateone);
      form_data.append('datetwo', this.state.datetwo);
      form_data.append('sacco', sacco);
      form_data.append('selected_laccount', this.state.selected_laccount);

      if(this.state.dateone===''||this.state.datetwo===''){
        alert("Dates missing")
      }else{
          this.setState({datarequested:true})
  
          //Now submit sale data to database
          axios.post(serverconfig.backendserverurl+'/customqueries/getCashFlowReport', form_data,{
          headers: {
            'content-type': 'multipart/form-data'
          }
          })
          .then(res =>{
            this.setState({datarequested:false})  
              this.setState({sheetitems:JSON.parse(res.data.report)})
              this.setState({sheetitems_placeholders:JSON.parse(res.data.report)})
              this.setState({total_inst_balance:Number(res.data.total_inst_balance)})
          } 
          )
          .catch(error => console.log(error))   
      }
    

    }

   ////////////////////////////////////
  // USER SEARCH SELECT METHODS
  onBlur=()=> {
    console.log('blur');
  }
  
   onFocus=()=>{
    console.log('focus');
  }
  
  onSearch=(val)=> {
    console.log('search:', val);
  }
 
  calculateTotalDebit=()=>{
    var total=0

    this.state.sheetitems.map((item)=>{
      total+=Number(item.Debit)

    })
    return total.toFixed(2);

  }

  calculateTotalCredit=()=>{
    var total=0

    this.state.sheetitems.map((item)=>{
      total+=Number(item.Credit)

    })
    return total.toFixed(2);

  }



   ////////////////////////////////////////////
   // RENDERING METHOD HERE
   render() {
     
       if(this.state.datarequested===true){
        return(
          <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
          <Spin indicator={antIcon} />
          </div>
        )
  
      }else{

        return(
          <div>
            <div style={{display: "none"}}>
               &nbsp;&nbsp;
              <ReportToPrint
              totaldebit={this.state.totaldebit} 
              totalcredit={this.state.totalcredit} 
              dateone={this.state.dateone}
              datetwo={this.state.datetwo}
              sheetitems={this.state.sheetitems}
              entrantname={this.state.entrantname}
              searchbyjno={this.state.searchbyjno}
              ref={el => (this.componentRef = el)} /> 
            </div>
  
              <Collapse defaultActiveKey={['1']} onChange={this.callback}>
              <Panel header="CASHFLOW / CASHBOOK REPORT" key="1">

              <div style={{display:'flex',justifyContent:'flex-start',flexDirection:'row'}}>
                <ReactToPrint
                  trigger={() => 
                  <Button type="primary"  shape="round" icon={<PrinterOutlined />} size={this.state.size}>
                  Print
                  </Button>
                  }
                  content={() => this.componentRef}
                  />
                &nbsp;&nbsp;

              <ExcelFile filename={"Pisacco - Cashflow report from "+this.state.dateone+" to "+this.state.datetwo}  element={<Button type='primary' icon={<DownloadOutlined/>}>Download (Excel)</Button>}>
                <ExcelSheet data={this.state.sheetitems} name={"Cashflow report"}>
                    <ExcelColumn label="No" value="item_no"/>
                    <ExcelColumn label="Date" value="date"/>
                    <ExcelColumn label="Account" value="account"/>
                    <ExcelColumn label="Details" value="description"/>
                    <ExcelColumn label="Journal no" value="journal_no"/>
                    <ExcelColumn label="User" value="user"/>
                    <ExcelColumn label="Debit" value="Debit"/>
                    <ExcelColumn label="Credit" value="Credit"/>
                    <ExcelColumn label="Total cashbalance" value="total_inst_balance"/>

                </ExcelSheet>
              </ExcelFile>
               </div>
               <br></br>
               <br></br>

                  <Form  layout="inline" >

                  <FormItem label="Accounts">
                     <Select 
                     placeholder="Account"
                     style={{ width: 200 }} 
                     value={this.state.selected_laccount} 
                     onChange={(val)=>{this.setState({selected_laccount:val})}}
                      
                     showSearch
                     optionFilterProp="children"
                     onFocus={this.onFocus}
                     onBlur={this.onBlur}
                     onSearch={this.onSearch}
                     filterOption={(input, option) =>
                       option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                     }                     
                      >
                        <Option value={''}>All Accounts</Option>
                       {this.state.cashflow_accounts_list.map(
                         (user)=>(
                           <Option key={user.key} value={user.account}>{user.account}</Option>
                         ))}
                     </Select>
                 </FormItem>

        
                  <FormItem label="Date Range">
                      <RangePicker onChange={
                        (date, dateString) =>{
                          this.setState({ dateone: dateString[0]});
                          this.setState({ datetwo: dateString[1]});
                        }
                      } format={dateFormat} />
                  </FormItem>


                  <FormItem>
                  <Button onClick={()=>{
                      this.searchSales()
                    
                  }} type="primary" htmlType="button">Search</Button>
                  </FormItem> 
  
             </Form>


          <br></br>
             <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>No</th>
                          <th>Date</th>
                          <th>Account</th>
                          <th>Details</th>
                          <th>Journal No</th>
                          <th>User</th>
                          <th>Debit (Receipts)</th>
                          <th>Credit (Payments) </th>
                          <th>Total cash Balance</th>
                          <th>Attached member</th>

                        </tr>
                      </thead>
                      <tbody>
                      {this.state.sheetitems.map(
                        (item)=>(
                          <tr>
                          <td>{item.item_no}</td>
                          <td>{item.date}</td>
                          <td>{item.account}</td>
                          <td>{item.description}</td>
                          <td>{item.journal_no}</td>
                          <td>{item.user}</td>
                          <td className="align-right">{<CurrencyFormat value={item.Debit} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td className="align-right">{<CurrencyFormat value={item.Credit} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td className="align-right">{<CurrencyFormat value={item.total_inst_balance} displayType={'text'} thousandSeparator={true}/>}</td>

                          <td>{item.member_name}</td>

                          </tr>
                        ))}
                        <tr>
                        <td style={{fontWeight:'bolder'}}>Total</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="align-right"style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalDebit()} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td className="align-right"style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalCredit()} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td></td>
                        <td></td>

                        </tr>

                    </tbody>
                </reactstrp.Table> 
                

              </Panel>
              </Collapse>


          </div>
      ) }

   }
}

export default CashFlowReport; 
