// SignaturePad.js
import React, { useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { Form, Input,Popover, Button,Select,DatePicker,Spin,Image, Card,Divider,Result, message,Modal,Popconfirm } from 'antd';

const SignaturePad = ({ setSignature }) => {
  const sigCanvas = useRef({});

  const clear = () => sigCanvas.current.clear();
  const save = () => {
    const dataUrl = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
    setSignature(dataUrl);
  };

  const canvasStyle = {
    border: '1px solid #000',  // Border width and color
    borderRadius: '15px',      // Border radius
    width: '100%',             // Full width
    height: '300px',           // Fixed height
    boxSizing: 'border-box'    // Ensure padding and border are included in the element's total width and height
  };

  return (
    <div style={{margin:5}}>
        <h1> Signature Pad</h1>

      <SignatureCanvas
        ref={sigCanvas}
        penColor="black"
        canvasProps={{ style: canvasStyle }}
      />
      <div style={{display:'flex',justifyContent:'space-evenly',margin:3}}>
      <Button onClick={clear} type='primary'>Clear</Button>
      <Button onClick={save} type='primary'>Save</Button>

      </div>
    </div>
  );
};

export default SignaturePad;
