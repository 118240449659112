import React from 'react';
import axios from 'axios';
import { Card, Button, Avatar,Modal,Form, Input,Select,Spin,Image,Result, message,Switch } from 'antd';
import AccountUpdateForm from '../components/AccountUpdateForm'
import { UserOutlined,LoadingOutlined,DeleteOutlined,MailOutlined } from '@ant-design/icons';
import { PlusOutlined } from '@ant-design/icons';
import { Drawer,Divider,Popover,Popconfirm } from 'antd';
import * as serverconfig from '../serverconn'
import {
  Col,
  Row,
} from 'reactstrap';
import PhoneInput from 'react-phone-number-input'
import uuid from 'react-uuid'

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const { Meta } = Card;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''
var sacco= ''
var bizuserid= ''

class CompanyProfile_autosettings extends React.Component{

    state={
        profile: {},
        datarequested:true,
        periodicperformancesummaryreceivers_list:[],
        automated_emailedstatement_subaccountlist:[],
        EmailAddress:'',
        subaccount:'',
        message:'',
        automatedendofmonth_periodicPSR_sending:false,


        firstname:'',
        secondname:'',
        phonecontact:'',
        cashflow_accounts_list:[],
        companyaccounts:[],
        selected_account:'',

    }


    componentDidMount(){
      if(localStorage.getItem("token")){
         token= localStorage.getItem("token")
      }else{
         token= ''
      }

      if(localStorage.getItem("sacco")){
        sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
        bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
     
     }else{
        sacco= ''
        bizuserid= ''
     }

      axios.defaults.headers={
        "Content-Type":"application/json",
        Authorization:`Token ${token}`
      }

      axios.get(`${serverconfig.backendserverurl}/api/saccosedit/${sacco}`)
      .then(res => {  
          this.setState({
            profile: res.data
          })
          console.log(res.data)

          //set list
          this.setState({periodicperformancesummaryreceivers_list:res.data.periodicperformancesummaryreceivers_list!=null?JSON.parse(res.data.periodicperformancesummaryreceivers_list):[]})
          this.setState({automated_emailedstatement_subaccountlist:res.data.automated_emailedstatement_subaccountlist!=null?JSON.parse(res.data.automated_emailedstatement_subaccountlist):[]})

          this.setState({automatedendofmonth_periodicPSR_sending:res.data.automatedendofmonth_periodicPSR_sending})
          this.setState({cashflow_accounts_list:res.data.cashflow_accounts_list!=null?JSON.parse(res.data.cashflow_accounts_list):[]})

      })


        axios.get(serverconfig.backendserverurl+`/api/companyaccounts/?sacco=${sacco}`)
        .then(res => {
            this.setState({
                companyaccounts:res.data
            })
            this.setState({datarequested:false})
        })

    }


    handleFormSubmit=() =>{
      this.setState({datarequested:true})
    
      let form_data = new FormData();
  
      form_data.append('sacco', sacco);
      form_data.append('periodicperformancesummaryreceivers_list',JSON.stringify(this.state.periodicperformancesummaryreceivers_list) );
      form_data.append('automated_emailedstatement_subaccountlist', JSON.stringify(this.state.automated_emailedstatement_subaccountlist) );
      form_data.append('automatedendofmonth_periodicPSR_sending', this.state.automatedendofmonth_periodicPSR_sending);
      form_data.append('cashflow_accounts_list', JSON.stringify(this.state.cashflow_accounts_list) );

      axios.post(`${serverconfig.backendserverurl}/customqueries/editprofile_autolists`,form_data, {
        headers: {
        'content-type': 'multipart/form-data'
        }
      })
      .then(res =>{
        console.log(res.data.message)
        this.setState({datarequested:false})
        this.setState({message:res.data.message})
        this.setState({datasubmittedsuccessfully:true})

      })       
     
      }


      //validate email
       validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      };

      //check email exitence
       isEmailAlreadyExists = (email, emailList) => {
        return emailList.some((item) => item.EmailAddress === email);
      };


      isSubaccountAlreadyExists = (subaccount, emailList) => {
        return emailList.some((item) => item.subaccount === subaccount);
      };


      //check if account already added
      isaccountAlreadyExists = (account, account_list) => {
        return account_list.some((item) => item.account === account);
      };



    render(){

      if(this.state.datarequested===true){
        return(
          <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin size="large">
              <div className="content">
                <h3>Pisacco</h3>
              </div>
               </Spin> 
          </div>
        )
  
      }else{
        return(
          <div>

          {
            this.state.datasubmittedsuccessfully===true?
            <Card>
            <Result
            status="success"
            subTitle={this.state.message}
            extra={[
              <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
            ]}
            />
            </Card>
            :
            <Card 
            style={{padding:10}}
            hoverable>
            <Form
                onFinish={(event) => {}}
                >

              <h3>Periodic summary report receivers list</h3>
              <FormItem label="First name">
                  <Input   placeholder="First name" value={this.state.firstname} onChange={(val)=>{this.setState({firstname:val.target.value})}} />
              </FormItem> 

              <FormItem label="Second name">
                   <Input   placeholder="Second name"
                    value={this.state.secondname}
                    onChange={(val)=>{this.setState({secondname:val.target.value})}} 
                   />
              </FormItem> 

              <FormItem label="Phone contact">
                    <Input   placeholder="Phone contact" value={this.state.phonecontact} onChange={(val)=>{this.setState({phonecontact:val.target.value})}} />
              </FormItem> 

              <Form.Item
              label="E-mail"
              >
              <Input type="mail" prefix={<MailOutlined className="site-form-item-icon" />} placeholder="Email" value={this.state.EmailAddress} onChange={(val)=>{this.setState({EmailAddress:val.target.value})}}  />
  
              </Form.Item>


              <FormItem>

              <Button type="primary" htmlType='button'
              onClick={()=>{

                if (this.validateEmail(this.state.EmailAddress)===false){
                  message.error("Email is invalid")

                }else if (this.isEmailAlreadyExists(this.state.EmailAddress,this.state.periodicperformancesummaryreceivers_list)===true){
                
                  message.error("Email already exists")
                
                }else if (this.state.firstname===""){
                  message.error("First name is mandatory")

                }else if (this.state.secondname===""){
                  message.error("Second name is mandatory")

                }else if (this.state.phonecontact===""){
                  message.error("Phone contact is mandatory")
                }
                
                else{

                  var intobj={
                    'key':this.state.periodicperformancesummaryreceivers_list.length+1,
                    'EmailAddress':this.state.EmailAddress,
                    'firstname':this.state.firstname,
                    'secondname':this.state.secondname,
                    'phonecontact':this.state.phonecontact,
                  }
  
                  this.setState({periodicperformancesummaryreceivers_list: [...this.state.periodicperformancesummaryreceivers_list,intobj]});
                  message.info("item added")
                  this.setState({EmailAddress:''})

                }

              }}>
                Add to list 
              </Button>
              </FormItem>

              <Divider></Divider>


            {
                this.state.periodicperformancesummaryreceivers_list.length>0?
                <div >
                  {
                    this.state.periodicperformancesummaryreceivers_list.map((item)=>{
                      return (
                      <p>{item.key} | {item.firstname} | {item.secondname} | {item.EmailAddress} | {item.phonecontact} &nbsp;&nbsp;
                      <Popover content={<p>Click here to delete item</p>} title="Delete">
                      <Popconfirm title="Sure to delete?" onConfirm={() => {
                        this.setState({ periodicperformancesummaryreceivers_list:  [...this.state.periodicperformancesummaryreceivers_list.filter(todo => todo.key
                          !==item.key)]});
                      }}>
                        <DeleteOutlined style={{color:'red'}}/>
                      </Popconfirm>
                      </Popover>
                      
                      </p>)
                    })
                  }

                </div>
                :
                null
              }

          <FormItem name="automatedendofmonth_periodicPSR_sending" 
          label="Automated end of month PSR sending?" >
            <Switch
                checked={this.state.automatedendofmonth_periodicPSR_sending}
                onChange={val=>{this.setState({automatedendofmonth_periodicPSR_sending:!this.state.automatedendofmonth_periodicPSR_sending})}}
                checkedChildren="ON"
                unCheckedChildren="OFF"
                />
          </FormItem>


            <Divider></Divider>

            <h3>Subaccounts on  automated  monthly statements to members</h3>

            <FormItem label="Select subaccount to add">
                <Select placeholder="Subaccount" style={{ width: 200 }}
                 value={this.state.subaccount} 
                 onChange={(val)=>{this.setState({subaccount:val})}} >
                      <Option value="Savings">Savings</Option>
                      <Option value="Savings_interest">Savings interest</Option>
                      <Option value="Savings_loan_security">Savings loan security</Option>
                      <Option value="Shares_loan_security">Shares loan security</Option>
                      <Option value="Shares">Shares</Option>
                      <Option value="Loan">Loan</Option>
                      <Option value="Loan_interest">Loan interest</Option>
                </Select>
            </FormItem>


            <FormItem>
            <Button type="primary" htmlType='button'
              onClick={()=>{

               if (this.isSubaccountAlreadyExists(this.state.subaccount,this.state.automated_emailedstatement_subaccountlist)===true){
                
                  message.error("Subaccount exists")
                
                }else{

                  var intobj={
                    'key':this.state.automated_emailedstatement_subaccountlist.length+1,
                    'subaccount':this.state.subaccount,
                  }
  
                  this.setState({automated_emailedstatement_subaccountlist: [...this.state.automated_emailedstatement_subaccountlist,intobj]});
                  message.info("item added")
                  this.setState({subaccount:''})

                }

              }}>
                Add to list 
              </Button>
              </FormItem>

              

              {
                this.state.automated_emailedstatement_subaccountlist.length>0?
                <div >
                  {
                    this.state.automated_emailedstatement_subaccountlist.map((item)=>{
                      return (
                      <p>{item.key} | {item.subaccount} &nbsp;&nbsp;
                      <Popover content={<p>Click here to delete item</p>} title="Delete">
                      <Popconfirm title="Sure to delete?" onConfirm={() => {
                        this.setState({ automated_emailedstatement_subaccountlist:  [...this.state.automated_emailedstatement_subaccountlist.filter(todo => todo.key
                          !==item.key)]});
                      }}>
                        <DeleteOutlined style={{color:'red'}}/>
                      </Popconfirm>
                      </Popover>
                      
                      </p>)
                    })
                  }

                </div>
                :
                null
              }

            <Divider></Divider>

            <h3>Cashflow ledger settings</h3>

           <FormItem label={"Select to add cashflow ledgers"}>
            <Select 
            placeholder="Account" 
            style={{  }} 
            value={this.state.selected_account}
            onChange={(val)=>{this.setState({selected_account:val})}} 
            showSearch
            optionFilterProp="children"
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onSearch={this.onSearch}                    
            >
              {this.state.companyaccounts.map(
                (accnt)=>(
                  <Option value={accnt.account_name}>{accnt.account_name} | {accnt.account_code}</Option>
                ))}
            </Select>
          </FormItem>

          <FormItem>
            <Button type="primary" htmlType='button'
              onClick={()=>{

               if (this.isaccountAlreadyExists(this.state.selected_account,this.state.cashflow_accounts_list)===true){
                  message.error("Accounts exists")
                }else{
                  var intobj={
                    'key':this.state.cashflow_accounts_list.length+1,
                    'account':this.state.selected_account,
                  }
  
                  this.setState({cashflow_accounts_list: [...this.state.cashflow_accounts_list,intobj]});
                  message.info("item added")
                  this.setState({selected_account:''})
                }

              }}>
                Add to list 
              </Button>
              </FormItem>
              

              {
                this.state.cashflow_accounts_list.length>0?
                <div >
                  {
                    this.state.cashflow_accounts_list.map((item)=>{
                      return (
                      <p>{item.key} | {item.account} &nbsp;&nbsp;
                      <Popover content={<p>Click here to delete item</p>} title="Delete">
                      <Popconfirm title="Sure to delete?" onConfirm={() => {
                        this.setState({ cashflow_accounts_list:  [...this.state.cashflow_accounts_list.filter(todo => todo.key
                          !==item.key)]});
                      }}>
                        <DeleteOutlined style={{color:'red'}}/>
                      </Popconfirm>
                      </Popover>
                      
                      </p>)
                    })
                  }

                </div>
                :
                null
              }

        <Divider></Divider>


      <FormItem>
          <Button type="primary" htmlType="submit"  
          onClick={()=>{
            this.handleFormSubmit()

          }}>Make update </Button>
      </FormItem>

      </Form>

       </Card>
          }
          

          </div>
      )}
       
    }
}

export default CompanyProfile_autosettings; 