import React,{ useRef } from 'react';
import axios from 'axios'
import { Table, Input, Button,Collapse,Tooltip,Avatar,Modal,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { PrinterOutlined, MinusOutlined, CloseCircleFilled, CheckCircleOutlined, UserOutlined } from '@ant-design/icons';
import { SearchOutlined,CameraOutlined,PlusOutlined,LoadingOutlined,DownloadOutlined } from '@ant-design/icons';
import QrReader from 'react-qr-reader'
import uuid from 'react-uuid'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
import PrintTemplate from 'react-print';
import ReactToPrint from 'react-to-print';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import * as reactstrp from 'reactstrap';

import {
  Col,
  Row,
} from 'reactstrap';
import ReactExport from "@ibrahimrahmani/react-export-excel";
import '../tablestyle.css'; // Create this CSS file for custom styles
import LedgerAccountDetails from "./LedgerAccountDetails";


var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { Title } = Typography;
const { RangePicker } = DatePicker;
const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


var token= ''
var sacco= ''
var bizuserid= ''


class ReportToPrint extends React.Component {

    state = {
      companyprofile:{}
    };
  
    componentDidMount(){
      if(localStorage.getItem("sacco")){
         sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
         bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
      
      }else{
         sacco= ''
         bizuserid= ''
      }
      
      if(localStorage.getItem("token")){
         token= localStorage.getItem("token")
      }else{
         token= ''
      }


      axios.defaults.headers={
        "Content-Type":"application/json",
        Authorization:`Token ${token}`
      }

      axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
      .then(res => {  
          this.setState({
            companyprofile: res.data
          })
  
      })
  
    }



  //calculate totals
  calculateTotalcredit_incomes=()=>{
    var total =0

    this.props.incomes.map((item)=>{
      total+=Number(item.credit)
    })

    return total.toFixed(2)
  }

  calculateTotaldebit_incomes=()=>{
    var total =0

    this.props.incomes.map((item)=>{
      total+=Number(item.debit)
    })

    return total.toFixed(2)
  }

  calculateTotalbalance_incomes=()=>{
    var total =0

    this.props.incomes.map((item)=>{
      total+=Number(item.balance)
    })

    return total.toFixed(2)
  }



  calculateTotalcredit_expenses=()=>{
    var total =0

    this.props.expenses.map((item)=>{
      total+=Number(item.credit)
    })

    return total.toFixed(2)
  }

  calculateTotaldebit_expenses=()=>{
    var total =0

    this.props.expenses.map((item)=>{
      total+=Number(item.debit)
    })

    return total.toFixed(2)
  }

  calculateTotalBalance_expenses=()=>{
    var total =0

    this.props.expenses.map((item)=>{
      total+=Number(item.balance)
    })

    return total.toFixed(2)
  }



  //calculate surplus
  CalculateSurplus=()=>{
    var total=0

    total=this.calculateTotalbalance_incomes()-this.calculateTotalBalance_expenses()

    return total.toFixed(2)
  }




  
    render() {
      return (
        <div style={{padding:20}}>
      <Row> 
                <Col xs="2" sm="2" lg="2">
                <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.sacco_logo} />
              
                  </Col>
                  <Col xs="6" sm="6" lg="6">
                  <h3>{this.state.companyprofile.sacco_name}</h3>
                  <h5>{this.state.companyprofile.companydealership}</h5>
                  <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                  {
                  this.state.companyprofile.RegisteredPhoneNumber2==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber2
                  }
              
                  </h6>               
                  <h5>Email: {this.state.companyprofile.company_email}</h5>
                  <h5>Wesite: {this.state.companyprofile.website}</h5>
                  <h5>{this.state.companyprofile.box_number}</h5>

                  </Col>

           </Row>

             <h3 style={{display: 'flex',justifyContent:'center', alignItems:'center',fontWeight:'bolder'}}>INCOME STATEMENT FROM: {this.props.dateone}  TO: {this.props.datetwo}</h3>

             <h4>INCOMES </h4>
            <reactstrp.Table bordered>
                      <thead>
                        <tr>
                        <th>No</th>
                          <th>Account</th>
                          <th>Debit</th>
                          <th>Credit</th>
                          <th>Balance</th>

                        </tr>
                      </thead>
                      <tbody>
                      {this.props.incomes.map(
                        (item)=>(
                          <tr>
                          <td>{item.no}</td>
                          <td>{item.account}</td>
                          <td className="align-right">{<CurrencyFormat value={item.debit} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td className="align-right">{<CurrencyFormat value={item.credit} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td className="align-right">{<CurrencyFormat value={item.balance} displayType={'text'} thousandSeparator={true}/>}</td>

                          </tr>
                        ))}
                        <tr>
                        <td>Total</td>
                        <td></td>

                        <td className="align-right"style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotaldebit_incomes()} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td className="align-right"style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalcredit_incomes()} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td className="align-right"style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalbalance_incomes()} displayType={'text'} thousandSeparator={true}/>}</td>

                        </tr>

                    </tbody>
                </reactstrp.Table>   

            <br></br> 
            <Divider style={{borderColor:'red'}} dashed />
            <h4>EXPENSES</h4>

            <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>No</th>
                          <th>Account</th>
                          <th>Debit</th>
                          <th>Credit</th>
                          <th>Balance</th>
                        </tr>
                      </thead>
                      <tbody>
                      {this.props.expenses.map(
                        (item)=>(
                          <tr>
                          <td>{item.no}</td>

                          <td>{item.account}</td>
                          <td className="align-right">{<CurrencyFormat value={item.debit} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td className="align-right">{<CurrencyFormat value={item.credit} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td className="align-right">{<CurrencyFormat value={item.balance} displayType={'text'} thousandSeparator={true}/>}</td>

                          </tr>
                        ))}
                        <tr>
                        <td>Total</td>
                        <td></td>

                        <td className="align-right"style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotaldebit_expenses()} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td className="align-right"style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalcredit_expenses()} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td className="align-right"style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalBalance_expenses()} displayType={'text'} thousandSeparator={true}/>}</td>

                        </tr>

                    </tbody>
                </reactstrp.Table> 

                <br></br> 
              <Divider style={{borderColor:'red'}} dashed />
              <h4>SURPLUS</h4>
              <reactstrp.Table bordered>
                      <thead>
                      <tr>
                        <td>Surplus</td>
                        <th></th>
                        <th></th>

                        <td className="align-right"style={{fontWeight:'bolder'}}></td>
                        <td className="align-right"style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.CalculateSurplus()} displayType={'text'} thousandSeparator={true}/>}</td>
                        </tr>
                      </thead>
                      
                </reactstrp.Table> 
        </div>
      );
    }
  }


class IncomeStatementReport extends React.Component {

  state = {
   expenses:[],
   incomes:[],
   dateone:'',
   datetwo:'',
   totalincomes:0,
   totalexpenses:0,
   surplus:0,
   datarequested:false,

   ledeger_details_model_visible:false,
   selected_record:{}

  };

  componentDidMount(){
      if(localStorage.getItem("sacco")){
        sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
        bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    
    }else{
        sacco= ''
        bizuserid= ''
    }
    
    if(localStorage.getItem("token")){
        token= localStorage.getItem("token")
    }else{
        token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

  }

  handledatechange= (date, dateString) =>{
    this.setState({ dateone: dateString[0]});
    this.setState({ datetwo: dateString[1]});
  } 

  //calculate totals
  calculateTotalcredit_incomes=()=>{
    var total =0

    this.state.incomes.map((item)=>{
      total+=Number(item.credit)
    })

    return total.toFixed(2)
  }

  calculateTotaldebit_incomes=()=>{
    var total =0

    this.state.incomes.map((item)=>{
      total+=Number(item.debit)
    })

    return total.toFixed(2)
  }


  calculateTotalbalance_incomes=()=>{
    var total =0

    this.state.incomes.map((item)=>{
      total+=Number(item.balance)
    })

    return total.toFixed(2)
  }



  calculateTotalcredit_expenses=()=>{
    var total =0

    this.state.expenses.map((item)=>{
      total+=Number(item.credit)
    })

    return total.toFixed(2)
  }

  calculateTotaldebit_expenses=()=>{
    var total =0

    this.state.expenses.map((item)=>{
      total+=Number(item.debit)
    })

    return total.toFixed(2)
  }


  calculateTotalBalance_expenses=()=>{
    var total =0

    this.state.expenses.map((item)=>{
      total+=Number(item.balance)
    })

    return total.toFixed(2)
  }


  //calculate surplus
  CalculateSurplus=()=>{
    var total=0

    total=this.calculateTotalbalance_incomes()-this.calculateTotalBalance_expenses()

    return total.toFixed(2)
  }




  //search incomes method
  getstatement=()=>{
   let form_data = new FormData();
   form_data.append('dateone', this.state.dateone);
   form_data.append('datetwo', this.state.datetwo);
   form_data.append('sacco', sacco);
   
   if(this.state.dateone===''||this.state.datetwo===''){
    alert("Please are dates missing")
   }else{
    this.setState({datarequested:true})

       //Now submit sale data to database
       axios.post(serverconfig.backendserverurl+'/customqueries/getincomestatement', form_data,{
        headers: {
          'content-type': 'multipart/form-data'
        }
         })
         .then(res =>{
           this.setState({datarequested:false})
 
            this.setState({expenses:JSON.parse(res.data.pandl_list_expense)})
            this.setState({incomes:JSON.parse(res.data.pandl_list_incomes)})
         } 
         )
         .catch(error => console.log(error)) 
   }

   }



  ////////////////////////////////////////////
  // RENDERING METHOD HERE
  render() {
      
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return(
        <div>
          <div style={{display: "none"}}>
             &nbsp;&nbsp;
            <ReportToPrint
            dateone={this.state.dateone}
            datetwo={this.state.datetwo}
            incomes={this.state.incomes}
            expenses={this.state.expenses}
            ref={el => (this.componentRef = el)} /> 

          </div>

          <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header="INCOME STATEMENT" key="1">


            <div style={{display:'flex',justifyContent:'flex-start',alignSelf:'flex-start',alignItems:'flex-start'}}>

              <ReactToPrint
              trigger={() => 
              <Button type="primary"  shape="round" icon={<PrinterOutlined />} size={this.state.size}>
              Print
              </Button>
              }
              content={() => this.componentRef}
              />
              &nbsp;&nbsp;
              <ExcelFile filename={"Pisacco - Incomestatement report from "+this.state.dateone+" to "+this.state.datetwo}   element={<Button type='primary' icon={<DownloadOutlined/>}>Download report (Excel)</Button>}>
                <ExcelSheet data={this.state.incomes} name="Incomes">
                    <ExcelColumn label="No" value="no"/>
                    <ExcelColumn label="Account" value="account"/>
                    <ExcelColumn label="Debit" value="debit"/>
                    <ExcelColumn label="Credit" value="credit"/>
                    <ExcelColumn label="Balance" value="balance"/>

                </ExcelSheet>

                <ExcelSheet data={this.state.expenses} name="Expenses">
                    <ExcelColumn label="No" value="no"/>
                    <ExcelColumn label="Account" value="account"/>
                    <ExcelColumn label="Debit" value="debit"/>
                    <ExcelColumn label="Credit" value="credit"/>
                    <ExcelColumn label="Balance" value="balance"/>

                </ExcelSheet>
            </ExcelFile>
            &nbsp;&nbsp;

              <Form  layout="inline" >
                  <FormItem label="Date Range">
                      <RangePicker defaultValue={moment()} onChange={this.handledatechange} format={dateFormat} />
                  </FormItem>

                  <FormItem>
                  <Button onClick={this.getstatement} type="primary" htmlType="button">Search</Button>
                  </FormItem> 

              </Form>

              </div>


           <br></br>
           <h4>INCOMES </h4>
            <reactstrp.Table bordered>
                      <thead>
                        <tr>
                           <th>No</th>
                          <th>Account</th>
                          <th>Debit</th>
                          <th>Credit</th>
                          <th>Balance</th>

                        </tr>
                      </thead>
                      <tbody>
                      {this.state.incomes.map(
                        (item)=>(
                          <Tooltip title="Click to view details" placement="top">
                          <tr onClick={()=>{
                            this.setState({selected_record:item})
                            this.setState({ledeger_details_model_visible:true})
                            }}>
                           <td>{item.no}</td>
                          <td>{item.account}</td>
                          <td className="align-right">{<CurrencyFormat value={item.debit} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td className="align-right">{<CurrencyFormat value={item.credit} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td className="align-right">{<CurrencyFormat value={item.balance} displayType={'text'} thousandSeparator={true}/>}</td>

                          </tr>
                          </Tooltip>
                        ))}
                        <tr>
                        <td>Total</td>
                        <td></td>

                        <td className="align-right"style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotaldebit_incomes()} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td className="align-right"style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalcredit_incomes()} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td className="align-right"style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalbalance_incomes()} displayType={'text'} thousandSeparator={true}/>}</td>

                        </tr>

                    </tbody>
                </reactstrp.Table>   

            <br></br> 
            <Divider style={{borderColor:'red'}} dashed />
            <h4>EXPENSES</h4>

            <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>No</th>
                        
                          <th>Account</th>
                          <th>Debit</th>
                          <th>Credit</th>
                          <th>Balance</th>

                        </tr>
                      </thead>
                      <tbody>
                      {this.state.expenses.map(
                        (item)=>(
                          <Tooltip title="Click to view details" placement="top">
                          <tr onClick={()=>{
                            this.setState({selected_record:item})
                            this.setState({ledeger_details_model_visible:true})
                            }}>
                           <td>{item.no}</td>
                          <td>{item.account}</td>
                          <td className="align-right">{<CurrencyFormat value={item.debit} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td className="align-right">{<CurrencyFormat value={item.credit} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td className="align-right">{<CurrencyFormat value={item.balance} displayType={'text'} thousandSeparator={true}/>}</td>

                          </tr>
                          </Tooltip>
                        ))}
                        <tr>
                        <td>Total</td>
                        <td></td>

                        <td className="align-right"style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotaldebit_expenses()} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td className="align-right"style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalcredit_expenses()} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td className="align-right"style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalBalance_expenses()} displayType={'text'} thousandSeparator={true}/>}</td>

                        </tr>



                    </tbody>
                </reactstrp.Table> 

                <br></br> 
              <Divider style={{borderColor:'red'}} dashed />

                        {
                          this.CalculateSurplus()>0?
                          <div>
                            <h4>SURPLUS</h4>
                          <reactstrp.Table bordered>
                                  <thead>
                                  <tr>
                                    <td>Surplus</td>
                                    <td></td>
                                    <td></td>
            
                                    <td className="align-right"style={{fontWeight:'bolder'}}></td>
                                    <td className="align-right"style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.CalculateSurplus()} displayType={'text'} thousandSeparator={true}/>}</td>
                                    </tr>
                                  </thead>
                                  
                            </reactstrp.Table> 
                          </div>
                        
            
                          :
                          <div>

                          <h4>DEFICIT</h4>
                          <reactstrp.Table bordered>
                                  <thead>
                                  <tr>
                                    <td>Surplus</td>
                                    <td></td>
                                    <td></td>
            
                                    <td className="align-right"style={{fontWeight:'bolder'}}></td>
                                    <td className="align-right"style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.CalculateSurplus()} displayType={'text'} thousandSeparator={true}/>}</td>
                                    </tr>
                                  </thead>
                                  
                            </reactstrp.Table> 
                          </div>
                          
            
                        }


        </Panel>
        </Collapse>


        {
        this.state.ledeger_details_model_visible===true?

        <Modal    
              visible={this.state.ledeger_details_model_visible}
              title="Account details view"
              onCancel={()=>{this.setState({ledeger_details_model_visible:false})}}
              footer={[
                <Button key="back" onClick={()=>{this.setState({ledeger_details_model_visible:false})}}>
                  Cancel
                </Button>
                ]}
                width={1000}
                >
                    <LedgerAccountDetails
                    dateone={this.state.dateone}
                    datetwo={this.state.datetwo}
                    account={this.state.selected_record.account}
                    />
                  
            </Modal>
              :
              null
            }


        </div>
    )}
    
  }
}

export default IncomeStatementReport; 
