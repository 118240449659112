import React,{ useRef } from 'react';
import axios from 'axios'
import { Tooltip,Popover,Result,Table,Popconfirm,Input, Button,Collapse,Card,Avatar,Modal,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { PrinterOutlined, MinusOutlined, CloseCircleFilled, CheckCircleOutlined, UserOutlined } from '@ant-design/icons';
import { DeleteOutlined,SearchOutlined,CameraOutlined,PlusOutlined,EditOutlined,PlusSquareFilled,SaveOutlined,LoadingOutlined } from '@ant-design/icons';
import QrReader from 'react-qr-reader'
import uuid from 'react-uuid'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
import PrintTemplate from 'react-print';
import ReactToPrint from 'react-to-print';
import {
  Col,
  Row,
} from 'reactstrap';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import * as reactstrp from 'reactstrap';
import { ToWords } from 'to-words';

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { Title } = Typography;
const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

const toWords = new ToWords();

message.config({
  top: 200,
  duration: 2,
  maxCount: 3,
  rtl: true,
});




var token= ''
var sacco= ''
var username=''
var bizuserid= ''

class ReceiptToPrint extends React.Component {

  state = {
    companyprofile:{}
  };

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    
    }else{
       sacco= ''
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })

  }

  render() {
    return (
      <div style={{padding:20}}>
            
          <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.sacco_logo} />
          <h3 style={{color:'black'}}>{this.state.companyprofile.sacco_name}-{this.state.companyprofile.branchname}</h3>
          <h6 style={{color:'black'}}>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
          {
          this.state.companyprofile.RegisteredPhoneNumber2==""?
          "":","+this.state.companyprofile.RegisteredPhoneNumber2
          }
          {
          this.state.companyprofile.RegisteredPhoneNumber3==""?
          "":","+this.state.companyprofile.RegisteredPhoneNumber3
          }
          </h6>               
          <h5 style={{color:'black'}}>Email: {this.state.companyprofile.company_email}</h5>
          <h5 style={{color:'black'}}>Wesite: {this.state.companyprofile.website}</h5>
          <h5 style={{color:'black'}}>{this.state.companyprofile.box_number}</h5>
           <h4 style={{color:'black'}}>SLIP NO: {this.props.voucher_number}</h4>
           <h4 style={{color:'black'}}>DATE: {this.props.date}</h4>
           <h5 style={{color:'black'}}>Country: {this.state.companyprofile.country} || Currency: {this.state.companyprofile.currency}</h5>
           <h3 style={{strong:true,color:'black',fontWeight:'bolder'}}>DEPOSIT RECEIPT</h3>

           <table cellpadding="2" cellSpacing="2" border="1">
            <tr style={{height:40,padding:1}} key={1}>
              <td style={{fontWeight:'bolder'}}>ACC NUMBER</td>
              <td style={{fontWeight:'bold'}}>{this.props.account.AccountNumber}</td>
            </tr>

            <tr style={{height:40,padding:1}} key={1}>
              <td style={{fontWeight:'bolder'}}>ACC NAME</td>
              <td style={{fontWeight:'bold'}}>{this.props.account.FullName}</td>
            </tr>  

            <tr style={{height:40,padding:1}} key={1}>
              <td style={{fontWeight:'bolder'}}>DEPOSITED AMOUNT</td>
              <td style={{fontWeight:'bold'}}><CurrencyFormat  value={this.props.totalamount} displayType={'text'} thousandSeparator={true} /></td>
            </tr> 

            <tr style={{height:40,padding:1}} key={1}>
              <td style={{fontWeight:'bolder'}}>In Words</td>
              <td style={{fontWeight:'bold'}}>{ toWords.convert(this.props.totalamount)}</td>
            </tr> 

            <tr style={{height:40,padding:1}} key={1}>
              <td style={{fontWeight:'bolder'}}>PAID IN BY</td>
              <td style={{fontWeight:'bold'}}>{this.props.savingterm}</td>
            </tr> 

            <tr style={{height:40,padding:1}} key={1}>
              <td style={{fontWeight:'bolder'}}>SLIP NUMBER</td>
              <td style={{fontWeight:'bold'}}>{this.props.voucher_number}</td>
            </tr> 

            <tr style={{height:40,padding:1}} key={1}>
              <td style={{fontWeight:'bolder'}}>CONTACT</td>
              <td style={{fontWeight:'bold'}}>{this.props.transactorcontact}</td>
            </tr> 

            <tr style={{height:40,padding:1}} key={1}>
              <td style={{fontWeight:'bolder'}}>TELLER</td>
              <td style={{fontWeight:'bold'}}>{username}</td>
            </tr> 

           </table>

      </div>
    );
  }
}


class TeebaGooloOpeningForm extends React.Component {

  state = {
    date:moment().format(dateFormat).toString(),
    selectedaccount:'',
    selectedaccountobject:{},
    members:[],
    datarequested:true,
    totalamount:0,
    voucher_number:'',
    savingterm:'',
    transactorcontact:'',
    previoustransactions:[],
    datasubmittedsuccessfully:false,
    companyprofile:{},
    accountchange:false,
    userprofile:{},
    transaction_details:'',
    transferamount:0,
    supersaveaccounttype:'',
    teebagoolotypes:[],
    teebagoolotype:'',
    target_savingfor:'',
    special_instructiononmaturity:'',
    term_maturitydate:moment().format(dateFormat).toString(),
    delinqency_date:moment().format(dateFormat).toString(),
    guarantee_guarantor_details:'',

    teebagoolotype_obj:{},

    debitaccount:'',
    creditaccount:'',
    companyaccounts: [],
    physical_form:null,
    memberperiodic_balanceobj:{}



  }

  callback(key) {
    console.log(key);
  }

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    
    }else{
       sacco= ''
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
   }else{
      bizuserid= ''
   }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    let form_data1 = new FormData();
    form_data1.append('userid', bizuserid);
    
    //get the user profile here
    axios.post(serverconfig.backendserverurl+'/customqueries/get_user_profile',form_data1,{
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res => {
        this.setState({
          userprofile:res.data
        })
    })

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })
    })

    axios.get(serverconfig.backendserverurl+`/api/members/?sacco=${sacco}`)
    .then(res => {
      var datalist=[]
      res.data.map((item)=>{
        if(item.AccountNumber=="null" || item.AccountNumber==null || item.AccountNumber==''){
        }else{
          datalist.push(item)
        }
      })

      this.setState({
        members:datalist
      })
    })

    axios.get(serverconfig.backendserverurl+`/api/teebagoolotypes/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          teebagoolotypes:res.data
        })

      //  this.setState({datarequested:false})
    })


    axios.get(serverconfig.backendserverurl+`/api/companyaccounts/?sacco=${sacco}`)
    .then(res => {
        this.setState({
            companyaccounts:res.data
        })
        this.setState({datarequested:false})
    })
  }

  ////////////////////////////////////
  // PRODUCT SEARCH SELECT METHODS
   onBlur=()=> {
    console.log('blur');
  }
  
   onFocus=()=>{
    console.log('focus');
  }
  
  onSearch=(val)=> {
    console.log('search:', val);
  }

  //handle client change
  handleselectedaccountChange= (e) =>{

    let form_data1 = new FormData();
    form_data1.append('date',this.state.date); 
    form_data1.append('member_id', e);
    axios.post(serverconfig.backendserverurl+`/customqueries/get_runningbalancesbydate`, form_data1, {
    headers: {
      'content-type': 'multipart/form-data'
    }
    })
    .then(res => {
        this.setState({
          memberperiodic_balanceobj:res.data.my_balance
        })
    })



    this.setState({accountchange:true})
    this.setState({ selectedaccount: e});
    //make query for last transactions
    axios.get(`${serverconfig.backendserverurl}/api/members/${e}`)
      .then(res => {  
          this.setState({selectedaccountobject: res.data})
          this.setState({accountchange:false})
      })
  }


  handlesavingtermChange= (e) => this.setState({ savingterm: e.target.value});
  handletransactorcontactChange= (e) => this.setState({ transactorcontact: e});
  handletransferamountChange= (e) => this.setState({ transferamount: e});


  //submit the deposit now
  handleFormSubmit=(event) =>{
    if (this.checksavingperiod()==false){
      message.error("Saving period is less than the required period.")
    }else if(Number(this.state.transferamount)>this.calculateNetWithdraw()){
      message.error("Savings Amount is less")
    }else if (Number(this.state.memberperiodic_balanceobj.share_numberbalance)<Number(this.state.teebagoolotype_obj.minimum_ordinaryaccount_shares)){
      message.error("Minimum account shares for teebagoolo is"+String(this.state.teebagoolotype_obj.minimum_ordinaryaccount_shares))
    }else if(this.state.teebagoolotype===""){
      message.info("Teeba Goolo account type cant be empty")
    }else if (this.state.transferamount===0){
      message.info("Transfer AMount cant be zero")
    }
    else{
      this.setState({datarequested:true})
      console.log(username)
  
      let form_data = new FormData();
      form_data.append('date',this.state.date);
      form_data.append('savingterm', this.state.savingterm);
      form_data.append('transferamount', this.state.transferamount);
      form_data.append('sacco', sacco);
      form_data.append('account', this.state.selectedaccountobject.id);
      form_data.append('username', username);
      form_data.append('userid', bizuserid);
      form_data.append('teebagoolotype', this.state.teebagoolotype);
      form_data.append('target_savingfor', this.state.target_savingfor);
      form_data.append('special_instructiononmaturity', this.state.special_instructiononmaturity);
      form_data.append('term_maturitydate', this.calculate_maturitydate());
      form_data.append('halfterm_date', this.calculate_halftermdate());

      form_data.append('creditaccount', this.state.creditaccount);
      form_data.append('debitaccount', this.state.debitaccount);

      axios.post(serverconfig.backendserverurl+'/customqueries/createteebagooloaccountprofile', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
        })
      .then(res => {
        this.setState({datarequested:false})

        var profile_id=res.data.profile_id

        if(profile_id!=""){
          let form_data1 = new FormData();
          this.state.physical_form==null?
          console.log("No physical file")
          :
          form_data1.append('physical_form', this.state.physical_form, this.state.physical_form?this.state.physical_form.name:"");
        
          axios.put(serverconfig.backendserverurl+`/api/teebagooloformuploadprofile/${profile_id}/`, form_data1, {
            headers: {
              'content-type': 'multipart/form-data'
            }
          })
        .then(res =>{console.log("Uploaded form")}).catch(error => console.log(error))
         //uploaded file
        }


        message.info(res.data.message)
        if (res.data.message==="success"){
          this.setState({datasubmittedsuccessfully:true})
        }
        
      })
      .catch(error => console.log(error))
    }
    
  }


//check empty objecty
isEmptyObject=(obj)=>{
  for ( var name in obj ) {
    return false;
  }
  return true;
}


  checksavingperiod=()=>{
    var m_savingperiod=Number(this.state.teebagoolotype_obj.minimum_ordinaryaccount_savingperiod)*30
    var dateof_joining=this.state.selectedaccountobject.DateOfJoining

    var datetwo=''
            
    var dateMomentObjectone = moment(dateof_joining, "DD/MM/YYYY"); // 1st argument - string, 2nd argument - format
    var dateObjectone = dateMomentObjectone.toDate();

    var dateMomentObjecttwo = new Date(); // 1st argument - string, 2nd argument - format
    var dateObjecttwo =new Date()

    var date1 = dateObjectone;
    var date2 = dateObjecttwo;

    var Difference_In_Time = date2.getTime() - date1.getTime();
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

    console.log(Difference_In_Days)

    //check if period is bigger than 
    if (Number(Difference_In_Days)<m_savingperiod){
      return false;
    }else{
      return true
    }

  }


      //calculate Netwithdraw
      calculateNetWithdraw=()=>{
        var netwithdraw=0
        var minimumaccountbal=Number(this.state.selectedaccountobject.minimum_account_balance)
        var loan_security=Number(this.state.memberperiodic_balanceobj.savingsloan_security_balance)
    
        netwithdraw=Number(this.state.memberperiodic_balanceobj.savings_balance)-(minimumaccountbal+loan_security)
    
        return netwithdraw;
      }
  
      //calculate maturity date
      calculate_maturitydate=()=>{
        var months=Number(this.state.savingterm)
        var m_date=''
  
        //get the application date
        var dataSplit = this.state.date.split('/');
        var dateConverted;
        if (dataSplit[2].split(" ").length > 1) {
  
            var hora = dataSplit[2].split(" ")[1].split(':');
            dataSplit[2] = dataSplit[2].split(" ")[0];
            dateConverted = new Date(dataSplit[2], dataSplit[1]-1, dataSplit[0], hora[0], hora[1]);
  
        } else {
            dateConverted = new Date(dataSplit[2], dataSplit[1] - 1, dataSplit[0]);
        }
  
        var year = dateConverted.getFullYear();
        var month = dateConverted.getMonth();
        var date = dateConverted.getDate();
  
        var paydate=new Date(year,month+months, date).toLocaleDateString('en-GB', {day : 'numeric',month : 'numeric',year : 'numeric'}).split(' ').join('/');
  
        m_date=paydate
  
        return m_date;
      }



      //calculate maturity date
      calculate_halftermdate=()=>{
        var days_add=(this.state.savingterm*30)/2
        var m_date=''
  
        //get the application date
        var dataSplit = this.state.date.split('/');
        var dateConverted;
        if (dataSplit[2].split(" ").length > 1) {
  
            var hora = dataSplit[2].split(" ")[1].split(':');
            dataSplit[2] = dataSplit[2].split(" ")[0];
            dateConverted = new Date(dataSplit[2], dataSplit[1]-1, dataSplit[0], hora[0], hora[1]);
  
        } else {
            dateConverted = new Date(dataSplit[2], dataSplit[1] - 1, dataSplit[0]);
        }
  
        var year = dateConverted.getFullYear();
        var month = dateConverted.getMonth();
        var date = dateConverted.getDate();
  
        var paydate=new Date(year,month, date+days_add).toLocaleDateString('en-GB', {day : 'numeric',month : 'numeric',year : 'numeric'}).split(' ').join('/');
  
        m_date=paydate
  
        return m_date;
      }



  returnsavingsperiod=()=>{
    var Difference_In_Days=0
    var m_savingperiod=Number(this.state.teebagoolotype_obj.minimum_ordinaryaccount_savingperiod)*30
    var dateof_joining=this.state.selectedaccountobject.DateOfJoining

    var datetwo=''
            
    var dateMomentObjectone = moment(dateof_joining, "DD/MM/YYYY"); // 1st argument - string, 2nd argument - format
    var dateObjectone = dateMomentObjectone.toDate();

    var dateMomentObjecttwo = new Date(); // 1st argument - string, 2nd argument - format
    var dateObjecttwo =new Date()

    var date1 = dateObjectone;
    var date2 = dateObjecttwo;

    var Difference_In_Time = date2.getTime() - date1.getTime();
    Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

    console.log(Difference_In_Days)
    if(this.isEmptyObject(this.state.selectedaccountobject)===false || this.state.selectedaccountobject.DateOfJoining===null){
      return  Math.round(Difference_In_Days) ;
    }else{
      return 0;
    }
  }



  ////////////////////////////////////////////
  // RENDERING METHOD HERE
  render() {

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      if(this.state.datasubmittedsuccessfully===true){
        return(
          <div>
            <div style={{display: "none"}}>
            &nbsp;&nbsp;
            <ReceiptToPrint
            account={this.state.selectedaccountobject} 
            totalamount={this.state.transferamount} 
            voucher_number={this.state.voucher_number} 
            savingterm={this.state.savingterm} 
            transactorcontact={this.state.transactorcontact}
            date={this.state.date}
            ref={el => (this.componentRef = el)} /> 
            </div>

            <Row >
                  <Col xs="12" sm="6" lg="6">
                    <Card>
                    <Result
                    status="success"
                    title="Successfully Account creation "
                    subTitle="Please finish off transaction"
                    extra={[
                      <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
                    ]}
                    />

                  </Card>
                  </Col>
            </Row>
          </div>

        )

      }else{
        return(
          <div>
            <div>
  
              <Row >
                  <Col xs="12" sm="6" lg="6">
                    <Card>

                    <h3 style={{fontWeight:'bold',alignSelf:'center',color:'#154360'}}>TEEBA GOOLO ACCOUNT OPENING FORM</h3>
                    <FormItem label="Select Member Ordinary Account">
                        <Select 
                        placeholder="Account No | Name" 
                        style={{  }} 
                        value={this.state.selectedaccount}
                        onChange={this.handleselectedaccountChange} 
                        showSearch
                        optionFilterProp="children"
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        onSearch={this.onSearch}                    
                        >
                          {this.state.members.map(
                            (accnt)=>(
                              <Option value={accnt.id}>{accnt.AccountNumber} | {accnt.FullName}</Option>
                            ))}
                        </Select>
                    </FormItem>
                    {
                      this.state.accountchange?
                      <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
                      <Spin indicator={antIcon} />
                      </div>
                      :
                      null
                    }


                    <br></br>
                <Form
                      onFinish={(event) => this.handleFormSubmit(event)}
                  >

                <h3>Teeba Goolo Account Type</h3>
                <FormItem label="Teeba Goolo Account Type">
                    <Select placeholder="Teeba Goolo Type" 
                    style={{  }} 
                    value={this.state.teebagoolotype}
                    onChange={(val)=>{
                      this.setState({teebagoolotype:val})
                    
                      //get suprsave type object
                      axios.get(`${serverconfig.backendserverurl}/api/teebagoolotypes/${val}`)
                      .then(res => {  
                          this.setState({teebagoolotype_obj: res.data})
                      })
                    
                    }}
                    showSearch
                    optionFilterProp="children"
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onSearch={this.onSearch} 
                    
                    
                    >
                      {this.state.teebagoolotypes.map(
                        (type)=>(
                          <Option value={type.id}>{type.product_term}</Option>
                        ))}
                    </Select>
                </FormItem>


              <FormItem label="Date of Application"
                name='date'
                rules={[
                  {
                    required: true,
                    message: 'Please Select date',
                  },
                ]}
              >
                  <DatePicker value={moment()} onChange={(date, dateString)=>{this.setState({ date: dateString})}} format={dateFormat} />
              </FormItem>

              <FormItem label={<h4 style={{fontWeight:'bold'}}>Target Saving For?</h4>} >
          
                <TextArea
                    placeholder="Target Saving For"
                    autoSize={{ minRows: 2, maxRows: 8 }}
                    value={this.state.target_savingfor}
                    onChange={(val)=>{this.setState({target_savingfor:val.target.value})}}
                  />
              </FormItem>


                <FormItem label={<h4 style={{fontWeight:'bold'}}>Special Instructions At Maturity</h4>} >
          
                <TextArea
                    placeholder="Special Instructions At Maturity"
                    autoSize={{ minRows: 2, maxRows: 8 }}
                    value={this.state.special_instructiononmaturity}
                    onChange={(val)=>{this.setState({special_instructiononmaturity:val.target.value})}}
                  />
                </FormItem>


                  <FormItem label={<h6 style={{fontWeight:'bold'}}>Savings transfer amount </h6>}
                  name="transferamount"
                  rules={[
                    {
                      required: true,
                      message: 'Please input amount',
                    },
                  ]}
                  >

                      <Input name="transferamount" type={'number'} placeholder="transferamount" value={this.state.transferamount} onChange={(val)=>{this.setState({transferamount:val.target.value})}}/>

                  </FormItem>

                  <FormItem label={<h4 style={{fontWeight:'bold',color:'#154360'}}>Total Amount </h4>}
                  >
                    <h3 style={{fontWeight:'bolder'}}><CurrencyFormat  value={this.state.transferamount} displayType={'text'} thousandSeparator={true} /></h3>
                  
                  </FormItem>

                  <FormItem label={<h4 style={{fontWeight:'bold'}}>Approved Saving Term  (Months):</h4>}
                  name="savingterm"
                  rules={[
                    {
                      required: true,
                      message: 'Please input name',
                    },
                  ]}
                  
                  >
                    <Input name="savingterm" type='number' placeholder="Term" value={this.state.savingterm} onChange={this.handlesavingtermChange}/>
                  </FormItem>



                <h3>Maturity date: {this.calculate_maturitydate()}</h3>
                <h3>Halfterm date: {this.calculate_halftermdate()}</h3>


                <FormItem label={'Debited account'}
                    name="debitaccount"
                    rules={[
                      {
                        required: true,
                        message: 'Please select account',
                      },
                    ]}
                  >
                        <Select 
                        placeholder="Account" 
                        style={{  }} 
                        value={this.state.debitaccount}
                        onChange={(val)=>{this.setState({debitaccount:val})}} 
                        showSearch
                        optionFilterProp="children"
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        onSearch={this.onSearch}                    
                        >
                          {this.state.companyaccounts.map(
                            (accnt)=>(
                              <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
                            ))}
                        </Select>
                    </FormItem>

                    <FormItem label={'Credited account'}
                  name="creditaccount"
                  rules={[
                    {
                      required: true,
                      message: 'Please select account',
                    },
                  ]}
                >
                      <Select 
                      placeholder="Account" 
                      style={{  }} 
                      value={this.state.creditaccount}
                      onChange={(val)=>{this.setState({creditaccount:val})}} 
                      showSearch
                      optionFilterProp="children"
                      onFocus={this.onFocus}
                      onBlur={this.onBlur}
                      onSearch={this.onSearch}                    
                      >
                        {this.state.companyaccounts.map(
                          (accnt)=>(
                            <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
                          ))}
                      </Select>
                  </FormItem>


                  <FormItem label="Physical Application Form" style={{marginRight:3}}>
                      <Input type="file" accept=".pdf" placeholder="Physical Application Form" 
                        onChange={(val)=>{
                          var kilbyes=val.target.files[0].size/1024
                          if (kilbyes>Number(this.state.companyprofile.maximum_upload_document_size)){
                            message.error("File is greater than "+String(this.state.companyprofile.maximum_upload_document_size)+" Kbs .It will not be uploaded.")
                          }else{
                            this.setState({physical_form:val.target.files[0]})
                          }
                          
                          }}/>
                </FormItem> 


                <FormItem>
                  <Button  type="primary" htmlType="submit">Submit</Button>
                </FormItem>
                  </Form>
  
                  </Card>
                   
                  </Col>

                  <Col xs="12" sm="6" lg="6">
                    <Card>
                    <h3 style={{fontWeight:'bold',color:'#154360'}}>Account Details</h3>
                    <h4>Account Number:{this.state.selectedaccountobject.AccountNumber}</h4>
                    <h4>Account Contact:{this.state.selectedaccountobject.RegisteredPhoneNumber1}</h4>
                    <h4>Account Name:{this.state.selectedaccountobject.FullName}</h4>
                    <h4>Savings Balance:<CurrencyFormat value={this.state.memberperiodic_balanceobj.savings_balance} displayType={'text'} thousandSeparator={true}  /> UGX</h4>
                    <h4>Net transfer:<CurrencyFormat value={this.calculateNetWithdraw()} displayType={'text'} thousandSeparator={true}  /> </h4>

                    <h4>Shares balance:<CurrencyFormat value={this.state.memberperiodic_balanceobj.share_numberbalance} displayType={'text'} thousandSeparator={true}  /> </h4>
                    <h4>Saving period: {this.returnsavingsperiod()} days</h4>
                  </Card>
                  </Col>
               </Row>
            </div>
      
         </div>              
                      
       )
      }
     
    }
   
  }
}

export default TeebaGooloOpeningForm; 
