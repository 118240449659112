import React,{ useRef } from 'react';
import axios from 'axios'
import { Tooltip,Popover,Result,Table,Popconfirm,Input, Button,Collapse,Card,Avatar,Modal,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { PrinterOutlined, MinusOutlined, CloseCircleFilled, CheckCircleOutlined, UserOutlined } from '@ant-design/icons';
import { DeleteOutlined,SearchOutlined,PlusCircleFilled,CameraOutlined,PlusOutlined,EditOutlined,PlusSquareFilled,SaveOutlined,LoadingOutlined } from '@ant-design/icons';
import QrReader from 'react-qr-reader'
import uuid from 'react-uuid'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
import PrintTemplate from 'react-print';
import ReactToPrint from 'react-to-print';
import {
  Col,
  Row,
} from 'reactstrap';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import * as reactstrp from 'reactstrap';
import { ToWords } from 'to-words';

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { Title } = Typography;
const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

const toWords = new ToWords();

message.config({
  top: 200,
  duration: 2,
  maxCount: 3,
  rtl: true,
});


var sacco= ''
var username=''
var token= ''

class SendSMSMessageView extends React.Component {

  state = {
    date:moment().format(dateFormat).toString(),
    accountfrom:'',
    accountfromobject:{},
    members:[],
    datarequested:true,
    datasubmittedsuccessfully:false,
    companyprofile:{},
    subaccount:'',
    messagemode:'',
    specialnumber:'',
    smsmessage:'',

    selected_accounts:[],
    slected_account:''
    
  }

  callback(key) {
    console.log(key);
  }

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    
    }else{
       sacco= ''
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }
    

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })

    axios.get(serverconfig.backendserverurl+`/api/members/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          members:res.data
        })

        this.setState({datarequested:false})

    })


  }

  

  ////////////////////////////////////
  // PRODUCT SEARCH SELECT METHODS
   onBlur=()=> {
    console.log('blur');
  }
  
   onFocus=()=>{
    console.log('focus');
  }
  
  onSearch=(val)=> {
    console.log('search:', val);
  }

  //handle client change
  handleaccountfromChange= (e) =>{
    this.setState({ accountfrom: e});
    //make query for last transactions
    axios.get(`${serverconfig.backendserverurl}/api/members/${e}`)
      .then(res => {  
          this.setState({accountfromobject: res.data})
      })
  }


  handletransferedamountChange= (e) => this.setState({ transferedamount: e});
  handlemessagemodeChange= (e) => this.setState({ messagemode: e});
  handlespecialnumberChange= (e) => this.setState({ specialnumber: e});
  handlesmsmessageChange= (e) => this.setState({ smsmessage: e.target.value});


  //submit the deposit now
  handleFormSubmit=(event) =>{

      if (this.state.messagemode==="selectedaccounts" && this.state.selected_accounts.length<=0){
        message.error("Please select account to add to receivers")
      }else{

        this.setState({datarequested:true})
        console.log(username)
    
        let form_data = new FormData();
        form_data.append('date',moment().format(dateFormat).toString());
        form_data.append('accountfrom', this.state.accountfrom);
        form_data.append('messagemode', this.state.messagemode);
        form_data.append('specialnumber', this.state.specialnumber);
        form_data.append('smsmessage', this.state.smsmessage);
        form_data.append('selected_accounts',JSON.stringify(this.state.selected_accounts) );
  
        form_data.append('sacco', sacco);
    
        axios.post(serverconfig.backendserverurl+'/customqueries/sendcustomizedmessage', form_data, {
          headers: {
            'content-type': 'multipart/form-data'
          }
          })
        .then(res => {
          console.log(res.data)
          this.setState({datarequested:false})
          message.info(res.data.message)
          window.location.reload(false)
          /*if (res.data.message==="success"){
            this.setState({datasubmittedsuccessfully:true})
          } */
          
        })
        .catch(error => console.log(error))


      }

  }

  //find here
   findDictionaryByKeyValue=(list, key, value) =>{
    return list.find(item => item[key] === value);
  }


  ////////////////////////////////////////////
  // RENDERING METHOD HERE
  render() {

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      if(this.state.datasubmittedsuccessfully===true){
        return(
          <div>
            <Row >
                  <Col xs="12" sm="6" lg="6">
                    <Card>
                    <Result
                    status="success"
                    title="Successful Message "
                    subTitle="Successfully sent SMS Message"
                    extra={[
                      <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
                    ]}
                    />
                    </Card>
                    </Col>
            </Row>

          </div>

        )

      }else{
        return(
          <div>
            <div>
  
              <Row >
                  <Col xs="12" sm="6" lg="6">
                    <Card>
                    <h4 style={{fontStyle:'oblique',fontWeight:'bolder'}}>Customized Message Form</h4>
                    <Form
                      onFinish={(event) => this.handleFormSubmit(event)}
                    >
                   
                    <FormItem 
                        label="Message Mode"
                        name="messagemode"
                        rules={[
                          {
                            required: true,
                            message: 'Please select message mode',
                          },
                        ]}
                        >
                            <Select 
                            value={this.state.messagemode} 
                            onChange={this.handlemessagemodeChange}
                            placeholder="Messaging Mode" >
                              <Option value='specialnumber'>SPECIAL NUMBER</Option>
                              <Option value='specialaccount'>SPECIAL ACCOUNT</Option>
                              <Option value='allaccounts'>ALL ACCOUNTS</Option>
                              <Option value='selectedaccounts'>SELECTED ACCOUNTS</Option>

                            </Select>
                      </FormItem>


                      {
                        this.state.messagemode==="specialaccount"?
                        <FormItem label="Account">
                        <Select 
                        placeholder="Account No | Name" 
                        style={{  }} 
                        value={this.state.accountfrom}
                        onChange={this.handleaccountfromChange} 
                        showSearch
                        optionFilterProp="children"
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        onSearch={this.onSearch}                 
                          >
                            {this.state.members.map(
                              (accnt)=>(
                                <Option value={accnt.id}>{accnt.AccountNumber} | {accnt.FullName}</Option>
                              ))}
                          </Select>
                      </FormItem>
                        :
                        null
                      }

                      
                      {
                        this.state.messagemode==="specialnumber"?
                        <FormItem 
                        label="Special Number"
                        
                        >
                              <PhoneInput
                                    onChange={this.handlespecialnumberChange}
                                    countrySelectProps={{ unicodeFlags: true }}
                                    defaultCountry="UG"
                                    placeholder="Enter phone"
                                    />
                        </FormItem>    
                        :
                        null
                      }


                      {
                        this.state.messagemode==="selectedaccounts"?
                        <div style={{display:'flex',flexDirection:'row'}}>
                          <FormItem label="Add to list of receivers">
                            <Select 
                            placeholder="Account No | Name" 
                            style={{  }} 
                            value={this.state.slected_account}
                            onChange={(val)=>{
                              this.setState({slected_account:val})

                            }} 
                            showSearch
                            optionFilterProp="children"
                            onFocus={this.onFocus}
                            onBlur={this.onBlur}
                            onSearch={this.onSearch}                 
                              >
                                {this.state.members.map(
                                  (accnt)=>(
                                    <Option value={accnt.id}>{accnt.AccountNumber} | {accnt.FullName}</Option>
                                  ))}
                              </Select>
                          </FormItem>


                          &nbsp;
                          <FormItem label=  {''}>
                          <PlusCircleFilled 
                          style={{fontSize: '35px',margin:2,color:'#9B116F'}}
                          onClick={()=>{


             
                              //create list here
                              axios.get(`${serverconfig.backendserverurl}/api/members/${this.state.slected_account}/`)
                              .then(res => { 
                                
                                //check existence
                                let foundDictionary = this.findDictionaryByKeyValue(this.state.selected_accounts, 'memberid', this.state.slected_account);

                                if (foundDictionary!=undefined && foundDictionary.memberid===this.state.slected_account){
                                  message.error("Account exists already")

                                }else{
                                  if(res.data.RegisteredPhoneNumber1!="" || res.data.RegisteredPhoneNumber1!="null"){

                                    var intobj={
                                      'key':uuid(),
                                      'memberid':this.state.slected_account,
                                      'member_name':res.data.FullName,
                                      'RegisteredPhoneNumber1':res.data.RegisteredPhoneNumber1,
                                    }
    
                                    this.setState({selected_accounts: [...this.state.selected_accounts,intobj]});
                                      message.info("item added")
                                  }else{
                                    message.error("No phone contact")
                                  }

                                }

                              })
                              
                        
                          }}
                          />
                          </FormItem>
                        </div>
                        :
                        null
                      }

                      {
                        this.state.selected_accounts.length>0?
                        <div >
                          {
                            this.state.selected_accounts.map((item)=>{
                              return (
                              <p>{item.member_name} | {item.RegisteredPhoneNumber1} &nbsp;&nbsp;
                              
                              <Popover content={<p>Click here to delete item</p>} title="Delete">
                              <Popconfirm title="Sure to delete?" onConfirm={() => {
                                this.setState({ selected_accounts:  [...this.state.selected_accounts.filter(todo => todo.key
                                  !==item.key)]});
                              }}>
                                <DeleteOutlined style={{color:'red'}}/>
                              </Popconfirm>
                              </Popover>
                              
                              </p>)
                            })
                          }

                        
                        </div>
                        :
                        null
                      }
                   

                      <FormItem label="Message"
                      name="message"
                      rules={[
                        {
                          required: true,
                          message: 'Please input message',
                        },
                      ]}
                      
                      >
                          <TextArea
                            placeholder="Message"
                            autoSize={{ minRows: 2, maxRows: 6 }}
                            onChange={this.handlesmsmessageChange}
                            value={this.state.smsmessage}
                            maxlength="160"
                          />
                      </FormItem>

             
                    <FormItem>
                      <Button  type="primary" htmlType="submit">Send</Button>
                    </FormItem>
                    </Form>
  
                    </Card>
                   
                  </Col>
  
                {

                  this.state.messagemode==="specialaccount"?
                  <Col xs="12" sm="6" lg="6">
                  <Card>
                  <h3 style={{fontWeight:'bold',color:'#154360'}}>Account Phone Contact Details</h3>
                  <h4>Phone One: {this.state.accountfromobject.RegisteredPhoneNumber1}</h4>
                  <h4>Phone Two: {this.state.accountfromobject.RegisteredPhoneNumber2}</h4>
                  <h4>Phone Three: {this.state.accountfromobject.RegisteredPhoneNumber3}</h4>
                  </Card>
               </Col>
                  :
                  null
                }

            </Row>
  
            </div>
  
       </div>              
                      
        )
      }

    }
   
  }
}

export default SendSMSMessageView; 
