import React from 'react';
import { Form, Input, Button,Spin,DatePicker, Card,Result,Tabs,Switch,message,Select, Divider } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined,DownloadOutlined } from '@ant-design/icons';
import moment from 'moment';
import * as reactstrp from 'reactstrap';
import CurrencyFormat from 'react-currency-format';
import ReactExport from "@ibrahimrahmani/react-export-excel";

var CryptoJS = require("crypto-js");

const { TabPane } = Tabs;

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const { Option } = Select;


const FormItem=Form.Item;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''
var sacco= ''
var username=''

const dateFormat = 'DD/MM/YYYY';

class SavingsInterestPayOffForm_SingleMember extends React.Component {

  state = {
    datarequested:false,
    date:moment().format(dateFormat).toString(),
    datasubmittedsuccessfully:false,
    payofflist:[],
    overalltotalpay:0,

    deduct_tax:false,
    tax_percentage:0,
    members:[],
    selectedaccount:''
  
  }

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)

      }else{
       sacco= ''
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }


    axios.get(serverconfig.backendserverurl+`/api/members_withaccounts/?sacco=${sacco}`)
    .then(res => {

      //console.log(res.data)
      this.setState({
        members:res.data
      })

    })


  }


  //submit button pressed

  render(){

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return (
        <div>

<Tabs defaultActiveKey="1" onChange={()=>{}}>
   <TabPane tab="Autogenerated interest award" key="001" >

                {
  this.state.datasubmittedsuccessfully===true?
  <Card>
        <Result
        status="success"
        title="Interest awarding task in progress"
        extra={[
          <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
        ]}
        />
  </Card>
  :
  <div>
    <Card>

     <h5>All accounts savings interest transfer to savings 
      (All accumulated interest already on members' interest subaccount)</h5>
        <Form >

        <FormItem label="Date of transfer"
          name='date'
          rules={[
            {
              required: true,
              message: 'Please Select date',
            },
          ]}
        >
            <DatePicker 
            value={moment()} 
            onChange={(date, dateString) => this.setState({ date: dateString})} 
            format={dateFormat} />
        </FormItem>



        <FormItem label="Account">
            <Select 
            placeholder="Account No | Name" 
            style={{  }} 
            value={this.state.selectedaccount}
            onChange={(val)=>{this.setState({selectedaccount:val})}} 
            showSearch
            optionFilterProp="children"
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onSearch={this.onSearch}                    
            >
              {this.state.members.map(
                (accnt)=>(
                  <Option value={accnt.id}>{accnt.AccountNumber} | {accnt.FullName}</Option>
                ))}
            </Select>
        </FormItem>


        <div style={{display:'flex',flexDirection:'row'}}>

        <FormItem>
            <Button  type="primary" htmlType='button' 
            onClick={(event) =>{

              if (this.state.selectedaccount===""){
                message.error("Please select member")

              }else{

                this.setState({datarequested:true})

                let form_data = new FormData();
                form_data.append('date', this.state.date);
                form_data.append('sacco', sacco);
                form_data.append('username', username);
                form_data.append('selectedaccount', this.state.selectedaccount);

                axios.post(serverconfig.backendserverurl+'/customqueries/computerTransferInterest', form_data, {
                  headers: {
                    'content-type': 'multipart/form-data'
                  }
                })
              .then(res =>{
                 this.setState({overalltotalpay:res.data.overalltotalpay})
                 this.setState({payofflist:JSON.parse(res.data.payment_list)})

                  this.setState({datarequested:false})
                  
              })
                .catch(error => console.log(error))



              }



              }}>Compute total interest pay</Button>
             </FormItem>

             &nbsp;&nbsp;


<FormItem>
<Button  type="primary" htmlType='button' onClick={(event) =>{

if (this.state.selectedaccount===""){
  message.error("Please select member")

}else{
    this.setState({datarequested:true})

    let form_data = new FormData();
    form_data.append('date', this.state.date);
    form_data.append('sacco', sacco);
    form_data.append('username', username);
    form_data.append('selectedaccount', this.state.selectedaccount);

    axios.post(serverconfig.backendserverurl+'/customqueries/clear_os_interestpayment', form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  .then(res =>{

      this.setState({datarequested:false})
      
    })
    .catch(error => console.log(error))

  }


  }}>Clear to recompute</Button>
</FormItem>




             </div>


            <FormItem label="Deduct tax?" >
                <Switch
                    checked={this.state.deduct_tax}
                    onChange={val=>{this.setState({deduct_tax:val})}}
                    checkedChildren="True"
                    unCheckedChildren="False"
                    />
            </FormItem>

            {
              this.state.deduct_tax?
              <FormItem label={<h6 style={{fontWeight:'bold'}}>Tax percentage </h6>}>
                  <Input name="tax_percentage" 
                    placeholder="0"
                      value={this.state.tax_percentage}
                    onChange={(e) =>{
                      this.setState({ tax_percentage: e.target.value})  
                    }
                  } />
              </FormItem>
              :
              null
            }


        {
          this.state.payofflist.length>0?
          <FormItem>
            <Button  type="primary" htmlType="button" onClick={(event) =>{
                if (this.state.payofflist.length<=0){
                  message.error("Paylist cant be empty")
                }else if (this.state.deduct_tax===true && this.state.tax_percentage===0){
                  message.error("Tax percentage cant be zero")
                }else  if (this.state.selectedaccount===""){
                  message.error("Please select member")
  
                } else{

                  this.setState({datarequested:true})

                  let form_data = new FormData();
                  form_data.append('date', this.state.date);
                  form_data.append('sacco', sacco);
                  form_data.append('username', username);
  
                  form_data.append('deduct_tax', this.state.deduct_tax);
                  form_data.append('tax_percentage', this.state.tax_percentage);
                  form_data.append('selectedaccount', this.state.selectedaccount);

                  axios.post(serverconfig.backendserverurl+'/customqueries/mass_transfersavings_interest_tosavings', form_data, {
                    headers: {
                      'content-type': 'multipart/form-data'
                    }
                  })
                .then(res =>{
                    this.setState({datarequested:false})
                    if (res.data.message==="interest awarding task in progress"){
                      this.setState({datasubmittedsuccessfully:true})
                    }
                })
                  .catch(error => console.log(error))

                }


              }}  >Payoff interest</Button>
          </FormItem>
          :
          null
        }

      <Divider></Divider>

        {
          this.state.payofflist.length>0?
          <ExcelFile filename={"Pisacco - Savings interest transfer to savings report on "+this.state.date}  element={<Button type='primary' icon={<DownloadOutlined/>}>Download (Excel)</Button>}>
            <ExcelSheet data={this.state.payofflist} name={"Savings Interest award report"}>
                <ExcelColumn label="Name" value="FullName"/>
                <ExcelColumn label="Account Number" value="AccountNumber"/>
                <ExcelColumn label="Current accumulated balance" value="current_accumulated_balance"/>
                <ExcelColumn label="Savings interest balance" value="account_balance"/>
                <ExcelColumn label="Total pay" value="member_total"/>
            </ExcelSheet>
          </ExcelFile>
          :
          null
        }

       </Form>

       <br></br>

    {
                this.state.payofflist.length>0?
                <reactstrp.Table bordered>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Account</th>
                    <th>Current accumulated balance</th>
                    <th>Savings interest balance</th>
                    <th>Total Pay amount</th>
                  </tr>
                </thead>
                <tbody>
                {this.state.payofflist.map(
                  (item)=>(
                    <tr>
                    <td>{item.FullName}</td>
                    <td>{item.AccountNumber}</td>

                    <td>{<CurrencyFormat value={item.current_accumulated_balance} displayType={'text'} thousandSeparator={true}/>}</td>
                    <td>{<CurrencyFormat value={item.account_balance} displayType={'text'} thousandSeparator={true}/>}</td>

                    <td>{<CurrencyFormat value={item.member_total} displayType={'text'} thousandSeparator={true}/>}</td>

                    </tr>
                  ))}
                  <tr>
                  <td style={{fontWeight:'bolder'}}>Total</td>
                  <td style={{fontWeight:'bolder'}}></td>
                  <td style={{fontWeight:'bolder'}}></td>
                  <td style={{fontWeight:'bolder'}}></td>

                  <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.state.overalltotalpay} displayType={'text'} thousandSeparator={true}/>}</td>
                  </tr>

         
                 </tbody>
                </reactstrp.Table> 

                :
                null
              }


    </Card>


  </div>
}
</TabPane>

</Tabs>

   </div>
     );

    }

  }

}


export default SavingsInterestPayOffForm_SingleMember;



