import React from 'react';
import CompanyAccountForm from '../components/CompanyAccountForm'
import ChartofAccountssubcategoryForm from '../components/ChartofAccountssubcategoryForm'

import axios from 'axios'
import { Table, Input, Button,Collapse,Popover,Popconfirm,message,Spin,Modal,Form,Select,Tabs } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DeleteOutlined,FundViewOutlined,LoadingOutlined,DownloadOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import * as serverconfig from '../serverconn'
import CurrencyFormat from 'react-currency-format';
import ReactExport from "@ibrahimrahmani/react-export-excel";
import CurrenciesList from './CurrenciesListView'
import CurrencyConverterList from './CurrencyConverterListView'

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const FormItem=Form.Item;
const { Option } = Select;
const { TabPane } = Tabs;

function callback(key) {
  console.log(key);
}

var token= ''
var sacco= ''

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


class CompanyAccountList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    companyaccounts: [],
    datarequested:true,
    account_code:'' ,  
    account_name:'',
    selectedaccountobj:{},
    showupdateform:false,
    chartofaccounts_subcategories: [],

    currency:'',
    chatofaccounts_subcategory:'',
    currencies: [],



  };


  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/companyaccounts/?sacco=${sacco}`)
    .then(res => {
        this.setState({
            companyaccounts:res.data
        })
       // this.setState({datarequested:false})
    })

    axios.get(serverconfig.backendserverurl+`/api/chartofaccounts_subcategories/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          chartofaccounts_subcategories:res.data
        })
       // this.setState({datarequested:false})
    })

    axios.get(serverconfig.backendserverurl+`/api/currencies/?sacco=${sacco}`)
    .then(res => {
        this.setState({
            currencies:res.data
        })
        this.setState({datarequested:false})

    })


}

getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};

    //////////////////////////////////////////////////
  //handle delete here
  handleDelete = id => {

        axios.delete(`${serverconfig.backendserverurl}/api/companyaccounts/${id}`).then(res =>{
      window.location.reload(false)
      message.info("successfully deleted") 
     }
     );

  };

  render() {

    const columns = [
      {
        title: 'Account Name',
        dataIndex: 'account_name',
        key: 'id',
        ...this.getColumnSearchProps('account_name'),
      },
      {
        title: 'Account Code',
        dataIndex: 'account_code',
        key: 'id',
        ...this.getColumnSearchProps('account_code'),

      },
      {
        title: 'Chart of account category',
        dataIndex: 'chartofaccount_category',
        key: 'id',
        ...this.getColumnSearchProps('chartofaccount_category'),
      },
      
      {
        title: 'Chart of accounts subcategory',
        dataIndex: 'chatofaccounts_subcategory_name',
        key: 'id',
        ...this.getColumnSearchProps('chatofaccounts_subcategory_name'),
      },
      {
        title: 'Currency',
        dataIndex: 'currency',
        key: 'id',
      },

     
      {
        title: 'Action',
        dataIndex: 'id',
        key: 'id',
        render: (text,record) =>
        <p>
        <Popover content={<p>Click here to edit,view details</p>} title="Edit,View">
         <FundViewOutlined style={{color:'blue'}} onClick={()=>{
          this.setState({selectedaccountobj:record})
          this.setState({account_code:record.account_code})
          this.setState({account_name:record.account_name})

          this.setState({currency:record.currency})
          this.setState({chatofaccounts_subcategory:record.chatofaccounts_subcategory})
          this.setState({chartofaccount_category:record.chartofaccount_category})


          this.setState({showupdateform:true})

          }}/>
        </Popover>
        &nbsp;&nbsp;&nbsp;
        <Popover content={<p>Click here to delete </p>} title="Delete">
        <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(text)}>
          <DeleteOutlined style={{color:'red'}} />
         </Popconfirm>
        </Popover>
        </p>,
      }

    ];


    const columns_type = [
      {
        title: 'Subcategory name',
        dataIndex: 'category_name',
        key: 'id',
        ...this.getColumnSearchProps('category_name'),
      },
      {
        title: 'Chart of account category',
        dataIndex: 'chartofaccount_category',
        key: 'id',
        ...this.getColumnSearchProps('chartofaccount_category'),
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'id',
      },

    ];

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return(
        <div>


<Tabs defaultActiveKey="1" onChange={()=>{}}>
<TabPane tab="COA (ALL ACCOUNTS)" key="001" >
<Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header="LEDEGER ACCOUNTS" key="1">

            <ExcelFile filename={"Pysys - Chart of accounts"}  element={<Button type='primary' icon={<DownloadOutlined/>}>Download Excel</Button>}>
                <ExcelSheet data={this.state.companyaccounts} name="Chart of accounts">
                    <ExcelColumn label="Account name" value="account_name"/>
                    <ExcelColumn label="Acc code" value="account_code"/>
                    <ExcelColumn label="Chart of account category" value="chartofaccount_category"/>
                    <ExcelColumn label="Chart of account subcategory" value="chatofaccounts_subcategory_name"/>
                    <ExcelColumn label="Currency" value="currency"/>
                </ExcelSheet>
            </ExcelFile>
            <br></br>
            <br></br>

            <Table 
            columns={columns} 
            scroll={{ x: 1000 }}
            pagination={{showQuickJumper:true,showSizeChanger:true }}
            dataSource={this.state.companyaccounts}
            bordered/>
            <br></br>

              <h2>Create Account here</h2>
              <CompanyAccountForm /> 

             </Panel>
             </Collapse>

</TabPane>


<TabPane tab="COA SUBCATEGORIES" key="002" >
<Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header="CHART OF ACCOUNTS SUBCATEGORIES" key="1">
            <Table 
            columns={columns_type} 
            scroll={{ x: 1000 }}
            pagination={{showQuickJumper:true,showSizeChanger:true }}
            dataSource={this.state.chartofaccounts_subcategories}
            bordered/>

            <br></br>
            <h2>Create Chart of account subcategory here</h2>
            <ChartofAccountssubcategoryForm /> 
             </Panel>
             </Collapse>


</TabPane>


<TabPane tab="CURRENCIES" key="003" >
   <CurrenciesList /> 
</TabPane>

<TabPane tab="CURRENCY CONVERTER" key="004" >
  <CurrencyConverterList />
</TabPane>


  </Tabs>



            <Modal              
                visible={this.state.showupdateform}
                title="Account Update Form"
                width={600}
                onCancel={()=>{this.setState({showupdateform:false})}}

                footer={[
                  <Button key="back" type='primary' onClick={()=>{

                    let form_data = new FormData();
                    form_data.append('account_code', this.state.account_code);
                    form_data.append('account_name', this.state.account_name);
                    form_data.append('chatofaccounts_subcategory', this.state.chatofaccounts_subcategory);
                    form_data.append('currency', this.state.currency);

                    form_data.append('sacco', sacco);
                
                      axios.put(serverconfig.backendserverurl+`/api/companyaccounts/${this.state.selectedaccountobj.id}/`, form_data, {
                        headers: {
                          'content-type': 'multipart/form-data'
                        }
                      })
                    .then(res => {

                      ////////////////////////
                      ////// update account txns 


                      //update previous transactions already in journals
                      let form_data_1 = new FormData();
                      form_data_1.append('old_name', this.state.selectedaccountobj.account_name);
                      form_data_1.append('new_name', this.state.account_name);
                      form_data_1.append('sacco', sacco);

                      axios.post(serverconfig.backendserverurl+'/customqueries/updateAccount_txnstonew_nameonupdate', form_data_1, {
                        headers: {
                          'content-type': 'multipart/form-data'
                        }
                      })
                      .then(res =>  {
                      console.log(res.data.message)

                      })
                      .catch(error => console.log(error))
                      //end of update of old txns here


                      axios.get(serverconfig.backendserverurl+`/api/companyaccounts/?sacco=${sacco}`)
                      .then(res => {
                          this.setState({
                              companyaccounts:res.data
                          })
                      this.setState({showupdateform:false})
                      }   
                      )
                      .catch(error => console.log(error))




                      ////////////////////////
                      ////// get all accounts


                      axios.get(serverconfig.backendserverurl+`/api/companyaccounts/?sacco=${sacco}`)
                      .then(res => {
                          this.setState({
                              companyaccounts:res.data
                          })
                      this.setState({showupdateform:false})
                    }   
                    )
                    .catch(error => console.log(error))


                    //end of txns gotten
                    

                    })}}>
                    Update Account
                  </Button>,
                  <Button key="ok" type='text' onClick={()=>{this.setState({showupdateform:false})}}>
                  Cancel
                </Button>
                  ]}
                  >

              <FormItem label="Account Name">
                  <Input name="account_name"  placeholder="Put name here." value={this.state.account_name} onChange={(val)=>{this.setState({account_name:val.target.value})}} />
              </FormItem>

                <FormItem label="Account Code" >
                  <Input name="account_code"  placeholder="Put code here" value={this.state.account_code} onChange={(val)=>{this.setState({account_code:val.target.value})}} />
                </FormItem>
              

                <FormItem label="Chart of account subcategory">
                    <Select  name="chatofaccounts_subcategory" placeholder="Sub category" 
                     value={this.state.chatofaccounts_subcategory}
                      onChange={(val)=>{this.setState({chatofaccounts_subcategory:val})}} >
                      {this.state.chartofaccounts_subcategories.map(
                        (ty_pe)=>(
                          <Option value={ty_pe.id}>{ty_pe.category_name}</Option>
                          ))}
                    </Select>
                </FormItem>

                <FormItem label="Currency">
                    <Select  name="currency" placeholder="Currency"  value={this.state.currency} onChange={(val)=>{this.setState({currency:val})}} >

                    {
                    this.state.currencies.map((item)=>{
                      return (<Option  value={item.currency_name}>{item.currency_name}</Option>)
                    })
                   }

                    </Select>
                </FormItem>

            </Modal>

        </div>
    )
    }

  }
}

export default CompanyAccountList; 
