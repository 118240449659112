import React from 'react';
import { Form, Input, Button,Select,InputNumber,DatePicker,message,Card,Spin,Result, Divider } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import moment from 'moment';
import PhoneInput from 'react-phone-number-input'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';

import {
  Col,
  Row,
} from 'reactstrap';
var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const { TextArea } = Input;
const dateFormat = 'DD/MM/YYYY';
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var sacco= ''
var username=''
var token= ''
var bizuserid=''


class QuickActions extends React.Component {
  state = {
    memberid:'',  
    action:'',
    datarequested:false,
    datasubmittedsuccessfully:false,
    date:moment().format(dateFormat).toString(),  
    userprofile:{},
    deactivation_reason:''
  }


  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
       username=''
    }

    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    }else{
       bizuserid= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    const memberid=this.props.memberid;
    this.setState({memberid:memberid})

    axios.get(serverconfig.backendserverurl+`/api/accounts/${bizuserid}`)
    .then(res => {
        this.setState({
          userprofile:res.data
        })
        this.setState({datarequested:false})
    })



  }


  //submit button pressed
  onFinish=(event) =>{
    if (this.state.date===""){
      message.error("Date is missing")
    }else if (this.state.action==="deactivate_account"  && this.state.deactivation_reason===""){
      message.error("Please enter reason for account deactivation")
    }
    else{

      this.setState({datarequested:true})

      let form_data = new FormData();
      form_data.append('action', this.state.action);
      form_data.append('sacco', sacco);
      form_data.append('username', username);
      form_data.append('member_id', this.props.memberobj.id);
      
      form_data.append('deactivation_reason', this.state.deactivation_reason);

      ///make a post request now
        axios.post(serverconfig.backendserverurl+'/customqueries/createquick_actions', form_data, {
          headers: {
            'content-type': 'multipart/form-data'
          }
        })
      .then(res => {
        message.info(res.data.message)
        this.setState({datarequested:false})

     

      })
      .catch(error => console.log(error))
    }


    }

    handleactionChange= (e) => {
       this.setState({action:e})
    }



  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{

      if (this.state.datasubmittedsuccessfully===true){
        return(
            <Card>
            <Result
            status="success"
            title="Successful"
            subTitle="Operation successful"
            extra={[
              <Button  type='primary' onClick={()=>{
                this.setState({datasubmittedsuccessfully:false})
                this.props.onRefresh()
              
              }}>Finish</Button>,
            ]}
            />
            </Card>
        );

      }else{

        return (
          <div>
            <Card>
    
            <Form
                  name="activationform"
                  onFinish={(event) => this.onFinish(event)}
              >

                <FormItem 
                      label="Action"
                      name="action"
                      rules={[
                        {
                          required: true,
                          message: 'Please select action',
                        },
                      ]}
                      >
                          <Select 
                          value={this.state.action} 
                          onChange={this.handleactionChange}
                          placeholder=" Action " >
                                <Option value='alignstatement'>Align Statement</Option>

                                <Option value='recalculate_savings_interest'>Recalculate savings interest</Option>
                                <Option value='recalculate_teebagoolo_interest'>Recalculate teebagoolo interest</Option>
                                <Option value='loaninterest_monthlyalignment'>Loan interest recalculation</Option>
               
                                {
                                  this.state.userprofile.branch_manager===true?
                                  <Option value='deactivate_account'>Deactivate account</Option>
                                  :null
                                }

                          </Select>
                </FormItem>


                {
                  this.state.action==="deactivate_account"?
                  <FormItem label="Deactivation Reason" >
                     <Input
                      name="deactivation_reason" 
                      placeholder="Deactivation Reason" 
                      value={this.state.deactivation_reason} 
                      onChange={(val)=>{this.setState({deactivation_reason:val.target.value})}} />
                  </FormItem> 
                  :
                  null
                }


    
              
                <FormItem>
                  <Button  type="primary" htmlType="submit">Submit</Button>
                </FormItem>
                </Form>


            </Card>
    
     
          </div>
        );

      }

    }

    
  }

}


export default QuickActions;



