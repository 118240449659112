import React,{ useRef } from 'react';
import axios from 'axios'
import { Tooltip,Popover,Result,Table,Popconfirm,Input,Switch, Button,Collapse,Card,Avatar,Modal,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { PrinterOutlined, MinusOutlined, CloseCircleFilled, CheckCircleOutlined, UserOutlined } from '@ant-design/icons';
import { DeleteOutlined,SearchOutlined,CameraOutlined,PlusOutlined,EditOutlined,PlusSquareFilled,SaveOutlined,LoadingOutlined } from '@ant-design/icons';
import QrReader from 'react-qr-reader'
import uuid from 'react-uuid'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
import PrintTemplate from 'react-print';
import ReactToPrint from 'react-to-print';
import {
  Col,
  Row,
} from 'reactstrap';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import * as reactstrp from 'reactstrap';
import { ToWords } from 'to-words';
import * as primarycolor from '../primarycolor'

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { Title } = Typography;
const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

const toWords = new ToWords();

message.config({
  top: 200,
  duration: 2,
  maxCount: 3,
  rtl: true,
});


var sacco= ''
var username=''
var token= ''
var bizuserid= ''

class ReceiptToPrint extends React.Component {

  state = {
    companyprofile:{}
  };

  componentDidMount(){

    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    
    }else{
       sacco= ''
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })

 

  }

  render() {
    return (
      <div style={{padding:20}}>
            
          <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.sacco_logo} />
          <h3 style={{color:'black'}}>{this.state.companyprofile.sacco_name}-{this.state.companyprofile.branchname}</h3>
          <h6 style={{color:'black'}}>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
          {
          this.state.companyprofile.RegisteredPhoneNumber2==""?
          "":","+this.state.companyprofile.RegisteredPhoneNumber2
          }
          {
          this.state.companyprofile.RegisteredPhoneNumber3==""?
          "":","+this.state.companyprofile.RegisteredPhoneNumber3
          }
          </h6>               
          <h5 style={{color:'black'}}>Email: {this.state.companyprofile.company_email}</h5>
          <h5 style={{color:'black'}}>Wesite: {this.state.companyprofile.website}</h5>
          <h5 style={{color:'black'}}>{this.state.companyprofile.box_number}</h5>
           <h4 style={{color:'black'}}>SLIP NO: {this.props.slipnumber}</h4>
           <h4 style={{color:'black'}}>DATE: {this.props.date}</h4>
           <h5 style={{color:'black'}}>Country: {this.state.companyprofile.country} || Currency: {this.state.companyprofile.currency}</h5>
           <h3 style={{strong:true,color:'black',fontWeight:'bolder'}}>WITHDRAW RECEIPT</h3>

           <table cellpadding="2" cellSpacing="2" border="1">
            <tr style={{height:40,padding:1}} key={1}>
              <td style={{fontWeight:'bolder'}}>ACC NUMBER</td>
              <td style={{fontWeight:'bold'}}>{this.props.account.AccountNumber}</td>
            </tr>

            <tr style={{height:40,padding:1}} key={1}>
              <td style={{fontWeight:'bolder'}}>ACC NAME</td>
              <td style={{fontWeight:'bold'}}>{this.props.account.FullName}</td>
            </tr>  

            <tr style={{height:40,padding:1}} key={1}>
              <td style={{fontWeight:'bolder'}}>WITHDRAWN AMOUNT</td>
              <td style={{fontWeight:'bold'}}><CurrencyFormat  value={this.props.paidamount} displayType={'text'} thousandSeparator={true} /></td>
            </tr> 

            <tr style={{height:40,padding:1}} key={1}>
              <td style={{fontWeight:'bolder'}}>In Words</td>
              <td style={{fontWeight:'bold'}}>{ toWords.convert(this.props.paidamount)}</td>
            </tr> 

            <tr style={{height:40,padding:1}} key={1}>
              <td style={{fontWeight:'bolder'}}>WITHDRAWN BY</td>
              <td style={{fontWeight:'bold'}}>{this.props.paidinby}</td>
            </tr> 

            <tr style={{height:40,padding:1}} key={1}>
              <td style={{fontWeight:'bolder'}}>SLIP NUMBER</td>
              <td style={{fontWeight:'bold'}}>{this.props.slipnumber}</td>
            </tr> 

            <tr style={{height:40,padding:1}} key={1}>
              <td style={{fontWeight:'bolder'}}>CONTACT</td>
              <td style={{fontWeight:'bold'}}>{this.props.transactorcontact}</td>
            </tr> 

            <tr style={{height:40,padding:1}} key={1}>
              <td style={{fontWeight:'bolder'}}>TELLER</td>
              <td style={{fontWeight:'bold'}}>{username}</td>
            </tr> 

           </table>


        
    </div>
    );
  }
}



class AccountWithdrawSuperSave extends React.Component {

  state = {
    date:moment().format(dateFormat).toString(),
    selectedaccount:'',
    selectedaccountobject:{},
    members:[],
    datarequested:true,
    paidamount:0,
    slipnumber:'',
    paidinby:'',
    transactorcontact:'',
    previoustransactions:[],
    datasubmittedsuccessfully:false,
    withdrawcharges:0,
    lastslipnumber:0,
    accountchange:false,
    companyprofile:{},
    userprofile:{},
    manualreceipt:'',
    transaction_details:'',
    voucher_number:'',
    supersaveprofile:{},

    companyaccounts: [],
    creditaccount:'',
    chargedebitaccount:'',

    withdraw_requistion: [],
    approved_withdrawal:'',
    debitaccount:'',
    creditaccount_charge:'',

    bankcharges:0,

    companyaccounts_new:[],
    default_credit:0,
    change_paying_account:false,
    mini_statement:[],
    voucher_no:0,
    finaldebit_account:'',

    memberperiodic_balanceobj:{}



  }

  callback(key) {
    console.log(key);
  }

  componentDidMount(){
      if(localStorage.getItem("sacco")){
        sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
        username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    
    }else{
        sacco= ''
        username=''
    }
    
    if(localStorage.getItem("token")){
        token= localStorage.getItem("token")
    }else{
        token= ''
    }

    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
   }else{
      bizuserid= ''
   }
    
    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    let form_data1 = new FormData();
    form_data1.append('userid', bizuserid);
    
    //get the user profile here
    axios.post(serverconfig.backendserverurl+'/customqueries/get_user_profile',form_data1,{
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res => {
        this.setState({
          userprofile:res.data
        })
     
    })


    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })


    axios.get(serverconfig.backendserverurl+`/api/supersaveprofile/?sacco=${sacco}`)
    .then(res => {

      var datalist=[]
      res.data.map((item)=>{
        if(item.AccountNumber=="null" || item.AccountNumber==null || item.AccountNumber==''){
        }else{
          datalist.push(item)
        }
      })

        this.setState({
          members:datalist
        })

    })

    axios.get(serverconfig.backendserverurl+`/api/companyaccounts/?sacco=${sacco}`)
    .then(res => {
        this.setState({
            companyaccounts:res.data
        })
        this.setState({datarequested:false})
    })




    ////////////////////////////////////////////////
    //////new entries here
    //////////////////////////////////////

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

        this.setState({default_credit:res.data.defaultaccount_for_savingswithdraw_crediting})

        if (this.state.companyprofile.defaultaccount_for_savingswithdraw_crediting!=null || this.state.companyprofile.defaultaccount_for_savingswithdraw_crediting!=0){

          this.setState({finaldebit_account:this.state.companyprofile.defaultaccount_for_savingswithdraw_crediting})

        }
    })


      //all company acocunts
      axios.get(serverconfig.backendserverurl+`/api/companyaccounts/?sacco=${sacco}`)
      .then(res => {
          this.setState({
            companyaccounts_new:res.data
          })
        })







  }


  
  ////////////////////////////////////
  // PRODUCT SEARCH SELECT METHODS
   onBlur=()=> {
    console.log('blur');
  }
  
   onFocus=()=>{
    console.log('focus');
  }
  
  onSearch=(val)=> {
    console.log('search:', val);
  }

  //handle client change
  handleselectedaccountChange= (val) =>{
    this.setState({accountchange:true})





    this.setState({selectedaccountobject:{}})
    this.setState({withdrawcharges:0})
    this.setState({previoustransactions: []})
    this.setState({supersaveprofile:{}})
    this.setState({ selectedaccount: val});

    axios.get(`${serverconfig.backendserverurl}/api/supersaveprofile/${val}`)
      .then(res => {  
        this.setState({supersaveprofile: res.data})
        var e=res.data.accountid
    
        //make othe rqueries
        //make query for last transactions
        axios.get(`${serverconfig.backendserverurl}/api/members/${e}`)
          .then(res => {  
              this.setState({selectedaccountobject: res.data})
              this.setState({withdrawcharges:res.data.withdraw_charges})
          })

        axios.get(`${serverconfig.backendserverurl}/api/transactions/?transaction_account=${e}`)
        .then(res => {  
            this.setState({previoustransactions: res.data.slice(0, 3)})
        })


        //get requsitions
        axios.get(serverconfig.backendserverurl+`/api/withdraw_requistion/?account=${e}&withdrawn=${false}&withdraw_type=${'supersave'}&status=${'approved'}`)
        .then(res => {
            this.setState({
              withdraw_requistion:res.data
            })

            this.setState({accountchange:false});
        })



        let form_data1 = new FormData();
        form_data1.append('date',this.state.date);
        form_data1.append('member_id', e);
        axios.post(serverconfig.backendserverurl+`/customqueries/get_runningbalancesbydate`, form_data1, {
        headers: {
          'content-type': 'multipart/form-data'
        }
        })
        .then(res => {
            this.setState({
              memberperiodic_balanceobj:res.data.my_balance
            })

            console.log(res.data.my_balance)
        })

        })
  }


  handlepaidinbyChange= (e) => this.setState({ paidinby: e.target.value});
  handleamountChange= (e) => this.setState({ paidamount: e});
  handleslipnumberChange= (e) => this.setState({ slipnumber: e.target.value});
  handletransactorcontactChange= (e) => this.setState({ transactorcontact: e});



  //submit the deposit now
  handleFormSubmit=(event) =>{

      if(Number(this.state.paidamount)>this.calculateNetWithdraw()){
        message.error("Amount cant be greater than net withdrawal")
      }else if (Number(this.state.paidamount)<=0){
        message.error("Amount cant be less or equal to zero")
      }else{
      
        this.setState({datarequested:true})
        console.log(username)
  
        let form_data = new FormData();
      form_data.append('date',this.state.date);
      form_data.append('paidinby', this.state.paidinby);
      form_data.append('paidamount', this.state.paidamount);
      form_data.append('slipnumber', this.state.slipnumber);
      form_data.append('transactorcontact', this.state.transactorcontact);
      form_data.append('sacco', sacco);
      form_data.append('account', this.state.selectedaccountobject.id);
      form_data.append('username', username);
      form_data.append('charges', this.calculateperntagecharge());
      form_data.append('userid', bizuserid);
      form_data.append('voucher_number', this.state.voucher_number);
      form_data.append('transaction_details', this.state.transaction_details);
      form_data.append('journaltotal', this.state.paidamount);
      form_data.append('approved_withdrawal', this.state.approved_withdrawal);
      form_data.append('creditaccount', this.state.finaldebit_account);
      form_data.append('bankcharges', this.state.bankcharges);
      form_data.append('voucher_no', this.state.voucher_no);

      axios.post(serverconfig.backendserverurl+'/customqueries/createaccountwithdrawsupersave', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
        })
      .then(res => {
        this.setState({datarequested:false})
        message.info(res.data.message)
        if (res.data.message==="success"){
          this.setState({datasubmittedsuccessfully:true})
        }
        
      })
      .catch(error => console.log(error))
      }
  }


  //calculate Netwithdraw
  calculateperntagecharge=()=>{
    if (JSON.stringify(this.state.supersaveprofile)==='{}'){
      return 0;

    }else{
      var withdrawcharge=(Number(this.state.supersaveprofile.percentagecharge_foruntimelywithdrawal)/100)*Number(this.state.paidamount)
      return withdrawcharge;
    }
  }
  

  calculateNetWithdraw=()=>{
    var netwithdraw=0
    var withdrawcharge=this.calculateperntagecharge()
    var loan_security=Number(this.state.memberperiodic_balanceobj.supersaveloan_security_balance)

    netwithdraw=Number(this.state.memberperiodic_balanceobj.supersaver_balance)-(withdrawcharge+loan_security)

    return netwithdraw;
  }


  ////////////////////////////////////////////
  // RENDERING METHOD HERE
  render() {


    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      if(this.state.datasubmittedsuccessfully===true){
        return(
          <div>
            <div style={{display: "none"}}>
            &nbsp;&nbsp;
            <ReceiptToPrint
            account={this.state.selectedaccountobject} 
            paidamount={this.state.paidamount} 
            slipnumber={this.state.slipnumber} 
            paidinby={this.state.paidinby} 
            transactorcontact={this.state.transactorcontact}
            date={this.state.date}
            ref={el => (this.componentRef = el)} /> 
            </div>

            <Row >
                  <Col xs="12" sm="6" lg="6">
                    <Card>
                    <Result
                    status="success"
                    title="Successfully tranfer from supersave to ordinary"
                    extra={[
                      <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
                    ]}
                    />
                  </Card>
                  </Col>
            </Row>

          </div>
        )

      }else{
        return(
          <div>
            <div>
  
              <Row >
                  <Col xs="12" sm="6" lg="6">
                    <Card style={{display:'flex',justifyContent:'center',alignSelf:'center'}}>

                   {
                    this.state.companyprofile.automated_transaction_date===false?
                    <FormItem label="Date">
                        <DatePicker 
                        onChange={(date, dateString) => this.setState({ date: dateString})} 
                        format={dateFormat} />
                    </FormItem>                 
                      :
                      null
                    }

                  <h4 style={{fontStyle:'oblique',fontWeight:'bolder'}}>Account To Withdraw From</h4>

                  <FormItem label="Account">
                      <Select 
                      placeholder="Account No | Name" 
                      style={{  }} 
                      value={this.state.selectedaccount}
                      onChange={this.handleselectedaccountChange} 
                      showSearch
                      optionFilterProp="children"
                      onFocus={this.onFocus}
                      onBlur={this.onBlur}
                      onSearch={this.onSearch}               
                      >
                        {this.state.members.map(
                          (accnt)=>(
                            <Option value={accnt.id}>{accnt.AccountNumber} | {accnt.FullName}</Option>
                          ))}
                      </Select>
                  </FormItem>

                    {
                      this.state.accountchange?
                      <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
                      <Spin indicator={antIcon} />
                      </div>
                      :
                      null
                    }

                    <h3 style={{fontWeight:'bold',alignSelf:'center',color:'#154360'}}>SUPERSAVE TO ORDINARY TRANSFER FORM</h3>


                  <Form
                      onFinish={(event) => this.handleFormSubmit(event)}
                  >



                {
                  this.state.withdraw_requistion.length>0?
                  <FormItem label={<h3>Approved withdrawal</h3>} >
                  <Select 
                  placeholder="Approved withdrawal" 
                  style={{ }} 
                  value={this.state.approved_withdrawal}
                  onChange={(val)=>{
                    this.setState({approved_withdrawal:val})
                    //get the amount here
                    axios.get(`${serverconfig.backendserverurl}/api/withdraw_requistion/${val}`)
                    .then(res => {
                      this.setState({paidamount:res.data.request_amount})
                    })

                  }} 
                  showSearch
                  optionFilterProp="children"
                  onFocus={this.onFocus}
                  onBlur={this.onBlur}
                  onSearch={this.onSearch}
                                        
                  >
                    {this.state.withdraw_requistion.map(
                      (accnt)=>(
                        <Option value={accnt.id}> {accnt.date} | {accnt.request_amount}</Option>
                      ))}
                  </Select>
                 </FormItem>
                  :
                  null
                }
                
                <h3>Withdraw amount:
                <CurrencyFormat value={this.state.paidamount} displayType={'text'} thousandSeparator={true}  />
                </h3>
                <br></br>


                    <FormItem label={<h4 style={{fontWeight:'bold'}}>Withdrawn By:</h4>}
                    name="paidinby"
                    rules={[
                      {
                        required: true,
                        message: 'Please input name',
                      },
                    ]}
                    
                    >
                      <Input name="paidinby"  placeholder="Name" value={this.state.paidinby} onChange={this.handlepaidinbyChange}/>
     
                    </FormItem>
  
                  <FormItem 
                      label={<h4 style={{fontWeight:'bold'}}>Transactor Contact:</h4>}
                      >
                    <PhoneInput
                      countrySelectProps={{ unicodeFlags: true }}
                      defaultCountry="UG"
                      placeholder="Enter phone"
                      value={this.state.transactorcontact}
                      onChange={this.handletransactorcontactChange}
                      />
                  </FormItem> 

                  <FormItem label={<h4 style={{fontWeight:'bold'}}>Transaction details.</h4>}
                  name="transaction_details"
                  rules={[
                    {
                      required: true,
                      message: 'Please input voucher',
                    },
                  ]}                      
                  >
                  <TextArea
                      placeholder="Transaction Details"
                      autoSize={{ minRows: 2, maxRows: 8 }}
                      value={this.state.transaction_details}
                      onChange={(val)=>{this.setState({transaction_details:val.target.value})}}
                    />
                  </FormItem>   


                  <FormItem label={<h4 style={{fontWeight:'bold'}}>Bank Charges:</h4>}>
                  <Input name="bankcharges"  placeholder="0" value={this.state.bankcharges} onChange={(val)=>{this.setState({bankcharges:val.target.value})}}/>
                  </FormItem>
                            
                  {
                    this.state.companyprofile.defaultaccount_for_savingswithdraw_crediting!=0 ?
                   
                   <div>

                  <FormItem 
                    label={<h4 style={{fontWeight:'bold'}}>Auto Credit A/C {this.state.companyprofile.defaultaccount_for_savingswithdraw_crediting}</h4>}
                    >

                      <Select 
                        placeholder="Account" 
                        style={{  }} 
                        value={this.state.default_credit}
                        showSearch
                        optionFilterProp="children"
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        onSearch={this.onSearch}                    
                        >
                          {this.state.companyaccounts_new.map(
                            (accnt)=>{

                              if (this.state.default_credit===accnt.id){
                                return (
                                  <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
                                );
                              }

                            }
                            
                            )}
                        </Select>
                    </FormItem>

                    <FormItem label={<h4 style={{fontWeight:'bold'}}>Change Account</h4>} >
                    <Switch
                      checked={this.state.change_paying_account}
                      onChange={val=>{this.setState({change_paying_account:val})}}
                      checkedChildren="Changed to other"
                      unCheckedChildren="Click to change"
                      />

                    </FormItem>

                    {
                    this.state.change_paying_account?
                    <FormItem label={<h4 style={{fontWeight:'bold'}}>Pay A/C (Credit A/C).</h4>}
                    name="creditaccount"
                    rules={[
                      {
                        required: true,
                        message: 'Please select account',
                      },
                    ]}
                  >
                        <Select 
                        placeholder="Account" 
                        style={{  }} 
                        value={this.state.creditaccount}
                        onChange={(val)=>{
                          
                          this.setState({creditaccount:val})
                        
                          //get final debit account
                          axios.get(`${serverconfig.backendserverurl}/api/user_saccoaccounts/${val}`)
                          .then(res => {  
                              this.setState({
                                  finaldebit_account: res.data.company_account
                              })

                              console.log("deb acbt: "+res.data.company_account)
                                
                          })
                        
                        }} 
                        showSearch
                        optionFilterProp="children"
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        onSearch={this.onSearch}                    
                        >
                          {this.state.companyaccounts.map(
                            (accnt)=>(
                              <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
                            ))}
                        </Select>
                    </FormItem>
                    :
                    null
                  }   


                   </div>
                   
                   
                    :
                    <FormItem label={<h4 style={{fontWeight:'bold'}}>Pay A/C (Credit A/C).</h4>}
                    name="creditaccount"
                    rules={[
                      {
                        required: true,
                        message: 'Please select account',
                      },
                    ]}
                  >
                        <Select 
                        placeholder="Account" 
                        style={{  }} 
                        value={this.state.creditaccount}
                        onChange={(val)=>{
                          
                          this.setState({creditaccount:val})
                        
                          //get final debit account
                          axios.get(`${serverconfig.backendserverurl}/api/user_saccoaccounts/${val}`)
                          .then(res => {  
                              this.setState({
                                  finaldebit_account: res.data.company_account
                              })
                              console.log("deb acbt: "+res.data.company_account)
                                
                          })
                        
                        }} 
                        showSearch
                        optionFilterProp="children"
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        onSearch={this.onSearch}                    
                        >
                          {this.state.companyaccounts.map(
                            (accnt)=>(
                              <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
                            ))}
                        </Select>
                    </FormItem>
                  } 


                  <FormItem label="Voucher no">
                    <Input name="voucher_no"
                    type='number'
                     placeholder="voucher_no."
                     value={this.state.voucher_no}
                     onChange={(val)=>{this.setState({voucher_no:val.target.value})}} />
                   </FormItem>


                    {
                    this.state.selectedaccountobject.account_status==="active" && this.state.withdraw_requistion.length>0?

                    <FormItem style={{alignSelf:'flex-end'}}>
                      <Button  type="primary" htmlType="submit">Transfer and withdraw</Button>
                    </FormItem>
                      :
                      null
                    }
                    
                    </Form>
  
                  </Card>
                   
                  </Col>

                  <Col xs="12" sm="6" lg="6" style={{padding: '5px'}}>

                    <h3 style={{fontWeight:'bold',color:'#154360'}}>Account Details</h3>
                    <h4>Account Number:{this.state.selectedaccountobject.AccountNumber}</h4>
                    <h4>Account Name:{this.state.selectedaccountobject.FullName}</h4>
                    <h4>Account Contact:{this.state.selectedaccountobject.RegisteredPhoneNumber1}</h4>
                    <h4>SuperSave Balance:<CurrencyFormat value={this.state.memberperiodic_balanceobj.supersaver_balance} displayType={'text'} thousandSeparator={true}  /> UGX</h4>
                    <h4>Percentage Charge for untimely withdraw:<CurrencyFormat value={this.calculateperntagecharge()} displayType={'text'} thousandSeparator={true}  /> UGX</h4>
                    <h4>Net Withdraw:<CurrencyFormat value={this.calculateNetWithdraw()} displayType={'text'} thousandSeparator={true}  /> </h4>
                    <h4>Supersave Loan Security:<CurrencyFormat value={this.state.memberperiodic_balanceobj.supersaveloan_security_balance} displayType={'text'} thousandSeparator={true}  /> </h4>

                    <br></br>
                    <h4  style={{display:'flex',color:'red'}}>NOTE: </h4>
                    <h4  style={{display:'flex',color:'red'}}>1. Member should have supersave account profile</h4>
                    <h4  style={{display:'flex',color:'red'}}>2. Supersave untimely withdrawals are subjected to {this.state.supersaveprofile.percentagecharge_foruntimelywithdrawal} as set at {this.state.supersaveprofile.account_type_name}</h4>
                   
                   </Col>
               </Row>
  
            </div>
       </div>              
                      
      )
      }

    }
   
  }
}

export default AccountWithdrawSuperSave; 
