import React from 'react';
import { Form, Input, Button,Select,Spin,DatePicker,Tooltip,Popover,Popconfirm,Result,message,Card,InputNumber,Table, Divider } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import moment from 'moment';
import { UserOutlined, LockOutlined,DeleteOutlined,PlusOutlined, LoadingOutlined,PrinterOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';
import {
  Col,
  Row,
} from 'reactstrap';
import { number } from 'prop-types';
import CurrencyFormat from 'react-currency-format';
import * as reactstrp from 'reactstrap';
import ReactToPrint from 'react-to-print';
import '../tablestyle.css'; // Create this CSS file for custom styles
import * as primarycolor from '../primarycolor'


var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const dateFormat = 'DD/MM/YYYY';

var token= ''
var sacco= ''
var bizuserid= ''
var username=''
message.config({duration:3000})

class ReceiptToPrint extends React.Component {

  state = {
    companyprofile:{}
  };

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })

  

  }

//calculate principleTotal
calculateScheduleTotal=()=>{
  var principleTotal=0
  this.props.loanschedule.map(
    (item)=>{
      principleTotal+=Number(item.principle)
    })

  return principleTotal.toFixed(2);
}

  //calculate principleTotal
  calculateInterestTotal=()=>{
    var InterestTotal=0
    this.props.loanschedule.map(
      (item)=>{
        InterestTotal+=Number(item.interest)
      })

    return InterestTotal.toFixed(2);
  }

  //calculate principleTotal
  calculateTotalPay=()=>{
    var TotalPay=0
    this.props.loanschedule.map(
      (item)=>{
        TotalPay+=Number(item.total)
      })

    return TotalPay.toFixed(2);
  }



  render() {
    return (
      <div style={{padding:20}}>
        <style>
          {`@media print {
          @page { size: landscape; }
            }`}
        </style>
            
      <Row> 
                <Col xs="2" sm="2" lg="2">
                <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.sacco_logo} />
              
                  </Col>
                  <Col xs="6" sm="6" lg="6">
                  <h3>{this.state.companyprofile.sacco_name}</h3>
                  <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                  {
                  this.state.companyprofile.RegisteredPhoneNumber2==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber2
                  }
                  {
                  this.state.companyprofile.RegisteredPhoneNumber3==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber3
                  }
                  </h6>               
                  <h5>Email: {this.state.companyprofile.company_email}</h5>
                  <h5>Wesite: {this.state.companyprofile.website}</h5>
                  <h5>{this.state.companyprofile.box_number}</h5>

                  </Col>

              </Row>
                  <h3 style={{fontWeight:'bolder',display: 'flex',justifyContent:'center', alignItems:'center'}}> LOAN SCHEDULE </h3>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> ACCOUNT NUMBER:  {this.props.loanissueobject.accountNumber} </h4>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> ACCOUNT NAME:  {this.props.loanissueobject.accountName} </h4>

                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> AMOUNT: <CurrencyFormat value={this.props.loanissueobject.approved_amount} displayType={'text'} thousandSeparator={true}/> </h4>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> PERIOD: {this.props.loanissueobject.loan_period} {this.props.loanissueobject.loan_primary_schedule==="daily"?"Days":this.props.loanissueobject.loan_primary_schedule==="weekly"?"Weeks":"Months"}</h4>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> INTEREST RATE: {this.props.interest_rate}</h4>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'end'}}> DATE OF ISSUANCE: {this.props.loanissueobject.date}</h4>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'end'}}> LOAN NUMBER: {this.props.loanissueobject.loan_number}</h4>

                  <reactstrp.Table bordered>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Date</th>
                            <th>Month</th>
                            <th>Principle Bf</th>
                            <th>Installment</th>
                            <th>Interest </th>
                            <th>Principle </th>
                            <th>Principle Bal</th>
                          </tr>
                        </thead>
                        <tbody>
                        {this.props.loanschedule.map(
                          (item)=>(
                            <tr>
                            <td>{item.repayment_number}</td>
                            <td>{item.date}</td>
                            <td>{item.payment_month}</td>
                            <td className="align-right">{<CurrencyFormat value={item.principlebroughtforward} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td className="align-right">{<CurrencyFormat value={item.total} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td className="align-right">{<CurrencyFormat value={item.interest} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td className="align-right">{<CurrencyFormat value={item.principle} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td className="align-right">{<CurrencyFormat value={item.balance} displayType={'text'} thousandSeparator={true}/>}</td>
                            </tr>
                          ))}
                          <tr>
                          <th>Total</th>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalPay()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateInterestTotal()} displayType={'text'} thousandSeparator={true}/>}</td> 
                          <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateScheduleTotal()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td></td>
                          </tr>

                      </tbody>
                  </reactstrp.Table>

    </div>
    );
  }
}


class StatementToPrint extends React.Component {

  state = {
    companyprofile:{}
  };

  componentDidMount(){
    if(localStorage.getItem("sacco")){
      sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
   }else{
      sacco= ''
   }
   
   if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })

  }

    //calculate principleTotal
    calculateTotalPay=()=>{
      var TotalPay=0
      this.state.loanschedule.map(
        (item)=>{
          TotalPay+=Number(item.total)
        })
  
      return TotalPay.toFixed(2);
    }


      //calculate principle paid
      calculateTotalInterestcharged=()=>{
      var TotalPay=0
      this.props.loanrepayments.map(
        (item)=>{
          TotalPay+=Number(item.interest_charged)
        })
  
      return TotalPay.toFixed(2);
     }
  
      //calculate Interest paid
      calculateTotalInterestPaid=()=>{
        var TotalPay=0
        this.props.loanrepayments.map(
          (item)=>{
            TotalPay+=Number(item.interest_paid)
          })
    
        return TotalPay.toFixed(2);
       }

             //calculate Total total paid
      calculateTotalprinciplepaid=()=>{
        var TotalPay=0
        this.props.loanrepayments.map(
          (item)=>{
            TotalPay+=Number(item.principle_paid)
          })
    
        return TotalPay.toFixed(2);
       }

       //calculate total fine paid
       calculateTotalFinePaid=()=>{
        var TotalPay=0
        this.props.loanrepayments.map(
          (item)=>{
            TotalPay+=Number(item.fine_paid)
          })
    
        return TotalPay.toFixed(2);
       }


  render() {
    return (
      <div style={{padding:20}}>      
      <style>
        {`@media print {
        @page { size: landscape; }
          }`}
      </style>

      <Row> 
            <Col xs="2" sm="2" lg="2">
            <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.sacco_logo} />
          
              </Col>
              <Col xs="6" sm="6" lg="6">
              <h3>{this.state.companyprofile.sacco_name}</h3>
              <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
              {
              this.state.companyprofile.RegisteredPhoneNumber2==""?
              "":","+this.state.companyprofile.RegisteredPhoneNumber2
              }
              {
              this.state.companyprofile.RegisteredPhoneNumber3==""?
              "":","+this.state.companyprofile.RegisteredPhoneNumber3
              }
              </h6>               
              <h5>Email: {this.state.companyprofile.company_email}</h5>
              <h5>Wesite: {this.state.companyprofile.website}</h5>
              <h5>{this.state.companyprofile.box_number}</h5>

              </Col>

          </Row>
              <h3 style={{fontWeight:'bolder',display: 'flex',justifyContent:'center', alignItems:'center'}}> LOAN TRACKING STATEMENT </h3>
              <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> ACCOUNT NUMBER:  {this.props.loanissueobject.accountNumber} </h4>
              <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> ACCOUNT NAME:  {this.props.loanissueobject.accountName} </h4>
              <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> AMOUNT: <CurrencyFormat value={this.props.loanissueobject.approved_amount} displayType={'text'} thousandSeparator={true}/> UGX</h4>
              <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> PERIOD: {this.props.loanissueobject.loan_period} {this.props.loanissueobject.loan_primary_schedule==="daily"?"Days":this.props.loanissueobject.loan_primary_schedule==="weekly"?"Weeks":"Months"}</h4>
              <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> INTEREST RATE: {this.props.interest_rate}</h4>
              <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'end'}}> DATE OF ISSUANCE: {this.props.loanissueobject.date}</h4>
              <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'end'}}> LOAN NUMBER: {this.props.loanissueobject.loan_number}</h4>

              <reactstrp.Table bordered>
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>No Of days</th>
                        <th>Open R/Ln Bal.</th>
                        <th>Installment Paid</th>
                        <th>Interest charged</th>
                        <th>Int Paid</th>
                        <th>Principle1 (Paid)</th>
                        <th>Unpaid Int</th>
                        <th>R/Int</th>
                        <th>R/Princ or Loan Issued</th>
                        <th>Outstanding Bal</th>
                        <th>Payment Details</th>
                      </tr>
                    </thead>
                    <tbody>
                    {this.props.loanrepayments.map(
                      (item)=>(
                        <tr>
                        <td>{item.date}</td>
                        <td>{item.no_ofdays}</td>
                        <td className="align-right">{<CurrencyFormat value={Number(item.open_runningbal).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td className="align-right">{<CurrencyFormat value={Number(item.installment_paid).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td className="align-right">{<CurrencyFormat value={Number(item.interest_charged).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td className="align-right">{<CurrencyFormat value={Number(item.interest_paid).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td className="align-right">{<CurrencyFormat value={Number(item.principle_paid).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>

                        <td className="align-right">{<CurrencyFormat value={Number(item.interest_unpaid).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td className="align-right">{<CurrencyFormat value={Number(item.running_int).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                        
                        <td className="align-right">{<CurrencyFormat value={Number(item.running_principle).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td className="align-right">{<CurrencyFormat value={Number(item.outstanding_bal).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td>{item.description}</td>

                        </tr>
                      ))}
                      <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>

                      <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalInterestcharged()} displayType={'text'} thousandSeparator={true}/>}</td>
                      <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalInterestPaid()} displayType={'text'} thousandSeparator={true}/>}</td>
                      <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalprinciplepaid()} displayType={'text'} thousandSeparator={true}/>}</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      </tr>

                  </tbody>
              </reactstrp.Table>   
    </div>
    );
  }
}



class LoanIssueDetail extends React.Component {

  state = {
    datetoday:moment().format(dateFormat).toString(),
    loanamount:0,
    interestrate:0,
    interestratetype:'',
    loanperiod:0,
    datarequested:true,
    dateofissuance:moment(),
    dateofissuancestring:moment().format(dateFormat).toString(),
    loanschedule:[],
    loanissueobject:{},
    loanrepayments:[],
    loandailyschedules:[],
    companyprofile:{},

    userprofile:{},
    deletelist:[],
    interestpay:0,

    newreschdate:'',
    newreschamt:0,
    creditaccount:'',
    companyaccounts:[],
    bankcharges:0


  }

  componentDidMount(){

      if(localStorage.getItem("sacco")){
          sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
      }else{
          sacco= ''
      }
      
      if(localStorage.getItem("token")){
          token= localStorage.getItem("token")
      }else{
          token= ''
      }

      if(localStorage.getItem("sacco")){
        bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
        username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)

      }else{
        bizuserid= ''
        username=''

      }

      axios.defaults.headers={
        "Content-Type":"application/json",
        Authorization:`Token ${token}`
      }

    const loanissueID=this.props.match.params.loanissueID;
    axios.get(`${serverconfig.backendserverurl}/api/loanissues/${loanissueID}`)
    .then(res => {  
        this.setState({loanissueobject: res.data})

        this.setState({newreschdate:res.data.date})
        this.setState({newreschamt:res.data.approved_amount})

    })

    axios.get(`${serverconfig.backendserverurl}/api/loanschedules/?loanissue=${loanissueID}`)
    .then(res => {  
        this.setState({loanschedule: res.data})
    })

    axios.get(`${serverconfig.backendserverurl}/api/loanrepayments/?loanissue=${loanissueID}`)
    .then(res => {  
        this.setState({loanrepayments: res.data})
    })

    axios.get(`${serverconfig.backendserverurl}/api/loandailyscheduledate/?loanissue=${loanissueID}`)
    .then(res => {  
        this.setState({loandailyschedules: res.data})
    })




    axios.get(`${serverconfig.backendserverurl}/api/accounts/${bizuserid}`)
    .then(res => {  
        this.setState({
          userprofile: res.data
        })
    })

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

        //this.setState({datarequested:false})

    })



      axios.get(serverconfig.backendserverurl+`/api/companyaccounts/?sacco=${sacco}`)
      .then(res => {
          this.setState({
              companyaccounts:res.data
          })
          this.setState({datarequested:false})
      })

  }


  //calculate principleTotal
  calculateScheduleTotal=()=>{
    var principleTotal=0
    this.state.loanschedule.map(
      (item)=>{
        principleTotal+=Number(item.principle)
      })

    return principleTotal.toFixed(2);
  }

    //calculate principleTotal
    calculateInterestTotal=()=>{
      var InterestTotal=0
      this.state.loanschedule.map(
        (item)=>{
          InterestTotal+=Number(item.interest)
        })
  
      return InterestTotal.toFixed(2);
    }

    //calculate principleTotal
    calculateTotalPay=()=>{
      var TotalPay=0
      this.state.loanschedule.map(
        (item)=>{
          TotalPay+=Number(item.total)
        })
  
      return TotalPay.toFixed(2);
    }


      //calculate principle paid
      calculateTotalInterestcharged=()=>{
      var TotalPay=0
      this.state.loanrepayments.map(
        (item)=>{
          TotalPay+=Number(item.interest_charged)
        })
  
      return TotalPay.toFixed(2);
     }
  
      //calculate Interest paid
      calculateTotalInterestPaid=()=>{
        var TotalPay=0
        this.state.loanrepayments.map(
          (item)=>{
            TotalPay+=Number(item.interest_paid)
          })
    
        return TotalPay.toFixed(2);
       }

             //calculate Total total paid
      calculateTotalprinciplepaid=()=>{
        var TotalPay=0
        this.state.loanrepayments.map(
          (item)=>{
            TotalPay+=Number(item.principle_paid)
          })
    
        return TotalPay.toFixed(2);
       }

       //calculate total fine paid
       calculateTotalFinePaid=()=>{
        var TotalPay=0
        this.state.loanrepayments.map(
          (item)=>{
            TotalPay+=Number(item.fine_paid)
          })
    
        return TotalPay.toFixed(2);
       }


       //calculate total fine paid
       convert_calinterest=()=>{
        var Val_u=0

        if(this.state.loanissueobject.loan_primary_schedule==="monthly"){
          Val_u=Number(this.state.loanissueobject.loan_annual_interestrate)/12
        }else if(this.state.loanissueobject.loan_primary_schedule==="weekly"){
          Val_u=Number(this.state.loanissueobject.loan_annual_interestrate)/52
        }else if(this.state.loanissueobject.loan_primary_schedule==="daily"){
          Val_u=Number(this.state.loanissueobject.loan_annual_interestrate)/365
        }

        return Val_u.toFixed(2);
       }



  render(){
   
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{

      return (
        <div>
            <div style={{display: "none"}}>
            &nbsp;&nbsp;
            <ReceiptToPrint
            loanschedule={this.state.loanschedule} 
            date={this.state.datetoday} 
            loanissueobject={this.state.loanissueobject}
            interest_rate={this.convert_calinterest()}
            ref={el => (this.componentRef = el)} /> 
            </div>

            <div style={{display: "none"}}>
            &nbsp;&nbsp;
            <StatementToPrint
            loanrepayments={this.state.loanrepayments} 
            date={this.state.datetoday} 
            loanissueobject={this.state.loanissueobject}
            interest_rate={this.convert_calinterest()}
            ref={sel => (this.componentReftwo = sel)} /> 
            </div>

            <Divider></Divider>
            <Row >
                    <Col xs="12" sm="6" lg="4">
                    <h4 style={{fontStyle:'oblique',fontWeight:'bolder',color:primarycolor.primarycolor}}>ISSUED LOAN DETAILS</h4>
                    <h6 style={{fontWeight:'bold'}}>Loan Number:{this.state.loanissueobject.loan_number} </h6>

                    <h6 style={{fontWeight:'bold'}}>Loan Amount:<CurrencyFormat value={this.state.loanissueobject.approved_amount} displayType={'text'} thousandSeparator={true}/> UGX </h6>
                    <h6 style={{fontWeight:'bold'}}>Issuance Date: {this.state.loanissueobject.date}</h6>
                    <h6 style={{fontWeight:'bold'}}>Loan Period: {this.state.loanissueobject.loan_period}</h6>
                    <h6 style={{fontWeight:'bold'}}>Interest Rate: {this.convert_calinterest()}</h6>
                    <h6 style={{fontWeight:'bold'}}>Interest Rate Type: {this.state.loanissueobject.interest_rate_type}</h6>

                    </Col>
                    <Col xs="12" sm="6" lg="4">
                    <h4 style={{fontStyle:'oblique',fontWeight:'bolder',color:primarycolor.primarycolor}}>ACCOUNT DETAILS</h4>

                    <h6 style={{fontWeight:'bold'}}>Account Number: {this.state.loanissueobject.accountNumber}</h6>
                    <h6 style={{fontWeight:'bold'}}>Account Name: {this.state.loanissueobject.accountName}</h6>
                    <h6 style={{fontWeight:'bold'}}>Loan Type: {this.state.loanissueobject.loantypename}</h6>
                    <h6 style={{fontWeight:'bold'}}>Loan Repayment Mode: {this.state.loanissueobject.loan_repaymentmode}</h6>
                    <h6 style={{fontWeight:'bold'}}>Loan Primary Schedule: {this.state.loanissueobject.loan_primary_schedule}</h6>

                    </Col>
                    <Col xs="12" sm="6" lg="4">

                    <h5 style={{color:'red'}}>NOTE</h5>
                    <h6>Flat Interest gives more profits as compared to reducing balance</h6>
                    <h6>Incase we round,we make sure loan principle balances at the last payment</h6>
                    </Col>
            </Row>
            <Divider></Divider>

            <br></br>
            <Card>
                    <h4 style={{fontStyle:'oblique',fontWeight:'bolder'}}>LOAN SCHEDULE</h4>

                    <reactstrp.Table bordered>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Date</th>
                            <th>Month</th>
                            <th>Principle Bf</th>
                            <th>Installment</th>
                            <th>Interest </th>
                            <th>Principle </th>
                            <th>Principle Bal</th>
                          </tr>
                        </thead>
                        <tbody>
                        {this.state.loanschedule.map(
                          (item)=>(
                            <tr>
                            <td>{item.repayment_number}</td>
                            <td>{item.date}</td>
                            <td>{item.payment_month}</td>
                            <td className="align-right">{<CurrencyFormat value={item.principlebroughtforward} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td className="align-right">{<CurrencyFormat value={item.total} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td className="align-right">{<CurrencyFormat value={item.interest} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td className="align-right">{<CurrencyFormat value={item.principle} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td className="align-right">{<CurrencyFormat value={item.balance} displayType={'text'} thousandSeparator={true}/>}</td>
                            </tr>
                          ))}
                          <tr>
                          <th>Total</th>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td  className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalPay()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateInterestTotal()} displayType={'text'} thousandSeparator={true}/>}</td> 
                          <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateScheduleTotal()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td></td>
                          </tr>

                      </tbody>
                  </reactstrp.Table>
                  <br></br>
                  <div style={{display:'flex',justifyContent:'flex-end',alignSelf:'flex-end',alignItems:'flex-end'}}>

                  <a>
                      <ReactToPrint
                        trigger={() =>                     
                          <div style={{}}>
                            <Tooltip title="Click to print" placement="top">
                            <p style={{alignSelf:'center'}}><PrinterOutlined style={{ fontSize: '40px', color: '#08c' }}/></p>
                            <h6 style={{alignSelf:'center'}}>Print Schedule</h6>
                            </Tooltip>
                          </div>                          
                            }
                            content={() => this.componentRef}
                          />  
                          </a>
                  </div>
 
              </Card>



            <br></br>
 
                    <Card>
                    <h4 style={{fontStyle:'oblique',fontWeight:'bolder'}}>LOAN TRACKING STATEMENT</h4>
                <div style={{overflowX:'auto'}}>
                <reactstrp.Table bordered>
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>No Of days</th>
                            <th>Open R/Ln Bal.</th>
                            <th>Installment Paid</th>
                            <th>Interest charged</th>
                            <th>Int Paid</th>
                            <th>Principle1 (Paid)</th>
                            <th>Unpaid Int</th>
                            <th>R/Int</th>
                            <th>R/Princ or Loan Issued</th>
                            <th>Outstanding Bal</th>
                            <th>Payment Details</th>
                          </tr>
                        </thead>
                        <tbody>
                        {this.state.loanrepayments.map(
                          (item)=>(
                            <tr>
                            <td>{item.date}</td>
                            <td>{item.no_ofdays}</td>
                            <td className="align-right">{<CurrencyFormat value={Number(item.open_runningbal).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td className="align-right">{<CurrencyFormat value={Number(item.installment_paid).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td className="align-right">{<CurrencyFormat value={Number(item.interest_charged).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td className="align-right">{<CurrencyFormat value={Number(item.interest_paid).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td className="align-right">{<CurrencyFormat value={Number(item.principle_paid).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>

                            <td className="align-right">{<CurrencyFormat value={Number(item.interest_unpaid).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td className="align-right">{<CurrencyFormat value={Number(item.running_int).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                           
                            <td className="align-right">{<CurrencyFormat value={Number(item.running_principle).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td className="align-right">{<CurrencyFormat value={Number(item.outstanding_bal).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{item.description}</td>

                            </tr>
                          ))}
                          <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>

                          <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalInterestcharged()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalInterestPaid()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalprinciplepaid()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          </tr>

                      </tbody>
                  </reactstrp.Table>

                </div>
                
                

                  

                  <a>
                  <ReactToPrint
                    trigger={() =>                     
                      <div style={{}}>
                        <Tooltip title="Click to print" placement="top">
                        <p style={{alignSelf:'center'}}><PrinterOutlined style={{ fontSize: '40px', color: '#08c' }}/></p>
                        <h6 style={{alignSelf:'center'}}>Print Statement</h6>
                        </Tooltip>
                      </div>                          
                        }
                        content={() => this.componentReftwo}
                      />  
                 </a>

              </Card>

           

        </div>
      );

    }

  }

}

export default LoanIssueDetail;

