// src/components/PrintComponent.js
import React, { Component } from 'react';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import CurrencyFormat from 'react-currency-format';

import { ToWords } from 'to-words';

var converter = require('number-to-words');
var CryptoJS = require("crypto-js");


var token= ''
var sacco= ''
var username=''
var bizuserid= ''

class PrintComponent extends Component {

    state = {
        companyprofile:{}
      };


      componentDidMount(){
        if(localStorage.getItem("sacco")){
           sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
           username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
        
        }else{
           sacco= ''
           username=''
        }
        
        if(localStorage.getItem("token")){
           token= localStorage.getItem("token")
        }else{
           token= ''
        }
    
    
        axios.defaults.headers={
          "Content-Type":"application/json",
          Authorization:`Token ${token}`
        }
    
        axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
        .then(res => {  
            this.setState({
              companyprofile: res.data
            })
        })
      }


  render() {
    const styles = {
      width: '80mm',
      fontSize: '12px',
      padding: '10px',
      margin: '0'
    };

    return (
      <div ref={this.props.forwardedRef} style={styles}>
          <div >
          <p>*********************************************************</p>

          <h1 style={{color:'black'}}>  {this.state.companyprofile.sacco_name}</h1>
          <h2 style={{color:'black'}}>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
          {
          this.state.companyprofile.RegisteredPhoneNumber2==""?
          "":","+this.state.companyprofile.RegisteredPhoneNumber2
          }

          </h2>               
          <h2 style={{color:'black'}}>  Email: {this.state.companyprofile.company_email}</h2>
          <h2 style={{color:'black'}}>  Wesite: {this.state.companyprofile.website}</h2>
          <h2 style={{color:'black'}}>  {this.state.companyprofile.box_number}</h2>
          <h2 style={{color:'black'}}>  RECEIPT NO: {this.props.journal_number}</h2>
          <h2 style={{color:'black'}}>  DATE: {this.props.date}</h2>

          <p>*********************************************************</p>

          <h1 style={{fontWeight:'bolder',display:'flex',alignSelf:'flex-start'}}>  DEPOSIT RECEIPT</h1>

          <p>********************************</p>

          <h3 style={{color:'black'}}>
              ACC NUMBER: 
            {this.props.account.AccountNumber}
          </h3>

          <h3 style={{color:'black'}}>
              ACC NAME :
            {this.props.account.FullName}
          </h3>

          <h3 style={{color:'black'}}>
              DEPOSITED AMOUNT:
            <CurrencyFormat  value={this.props.totalamount} displayType={'text'} thousandSeparator={','} />
          </h3>

          <h3 style={{color:'black'}}>
              IN WORDS: 
            ({ this.props.amount_inwords})
          </h3>


          <h3 style={{color:'black'}} >
              PAID IN BY: 
            {this.props.paidinby}
          </h3> 

          <h3 style={{color:'black'}} >
              CONTACT:
            {this.props.transactorcontact}
          </h3> 

          <h3 style={{color:'black'}}>
              TELLER:  
            {username}
          </h3>                 

          <h3 style={{color:'black'}}>
              PAYMENT OF: {this.props.reason}
          </h3> 


          <p>********************************</p>

          <h4 style={{color:'black'}}>  Supported by PitechUgLtd </h4>
          <h4 style={{color:'black'}}>  ( www.pitech.co.ug ) </h4>

        </div>




      </div>
    );
  }
}

const forwardRef = (Component) => {
  return React.forwardRef((props, ref) => {
    return <Component {...props} forwardedRef={ref} />;
  });
};

export default forwardRef(PrintComponent);
