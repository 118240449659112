import React from 'react';
import { Form, Input,message,Card,Result, Button,Tooltip,Select,Switch,Divider,Table,DatePicker,Spin,Modal,Collapse,Popconfirm,Popover } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import * as reactstrp from 'reactstrap';
import { primarycolor } from '../primarycolor';

import { 
  DeleteOutlined,
  FundViewOutlined,
  LoadingOutlined,
  PlusCircleFilled,
  PrinterOutlined
  
} from '@ant-design/icons';
import uuid from 'react-uuid'
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import {
  Col,
  Row,
} from 'reactstrap';
import CurrencyFormat from 'react-currency-format';
import { number } from 'prop-types';
import '../tablestyle.css'; // Create this CSS file for custom styles

var CryptoJS = require("crypto-js");
var converter = require('number-to-words');


const FormItem=Form.Item;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const { Panel } = Collapse;
const dateFormat = 'DD/MM/YYYY';
const { TextArea } = Input;

var sacco= ''
var username=''
var token=''


class ReportToPrint extends React.Component {

  state = {
    companyprofile:{},
  };

  componentDidMount(){

    if(localStorage.getItem("sacco")){
      sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
      username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
   
   }else{
      sacco= ''
      username=''
   }

       
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })

    }

  //clactulate totla of items
  calculateTotalofItems=()=>{
    var total=0

    JSON.parse(this.props.selected_voucher.items_list).map(
      (item)=>{
        total+=Number(item.amount)
      })

    return  total.toFixed(2);  

  }


  render() {
    return (
      <div style={{padding:20}}>
      <div>
      <Row> 
                <Col xs="2" sm="2" lg="2">
                <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.sacco_logo} />
              
                  </Col>
                  <Col xs="6" sm="6" lg="6">
                  <h3>{this.state.companyprofile.sacco_name}</h3>
                  <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                  {
                  this.state.companyprofile.RegisteredPhoneNumber2==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber2
                  }
                  {
                  this.state.companyprofile.RegisteredPhoneNumber3==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber3
                  }
                  </h6>               
                  <h5>Email: {this.state.companyprofile.company_email}</h5>
                  <h5>Wesite: {this.state.companyprofile.website}</h5>
                  <h5>{this.state.companyprofile.box_number}</h5>
                
                  </Col>
       </Row>
      <h2 style={{fontWeight:'bolder',display:'flex',alignSelf:'center',alignContent:'center',justifyContent:'center'}}>PAYMENT VOUCHER</h2>

      </div>


        <reactstrp.Table bordered>



            <thead>
                <tr scope="row">
              <td>
              <h4 style={{fontWeight:'bolder'}}align='left'>{' NUMBER: '+this.props.selected_voucher.voucher_no}</h4>
              <h4 style={{fontWeight:'bolder'}}align='left'>{' DATE: '+this.props.selected_voucher.date}</h4>
              <h4 style={{fontWeight:'bolder'}}align='left'>{' PAY CHANNEL: '+this.props.selected_voucher.payment_channel}</h4>
              <h4 style={{fontWeight:'bolder'}}align='left'>{' STATUS: '+this.props.selected_voucher.status}</h4>

              </td>
              </tr>


              <tr scope="row">
                <th><h2>#</h2></th>
                <th><h2>ITEM</h2></th>
                <th><h2>AMOUNT</h2></th>
                
              </tr>
            </thead>
            <tbody>
            { JSON.parse(this.props.selected_voucher.items_list).map(
              (item)=>(
                <tr scope="row">
                  <td><h2>{item.itemnumber}</h2></td>
                  <td><h2>{item.item_name}</h2></td>
                  <td><h2>{<CurrencyFormat value={item.amount} displayType={'text'} thousandSeparator={true}/>}</h2></td>

                </tr>
              ))}
                  <tr scope="row">
                  <td></td>
                  <td></td>
                  <th>
                    <h2>
                    {<CurrencyFormat value={this.calculateTotalofItems()} displayType={'text'} thousandSeparator={true}/>}
                    </h2>
                    </th>
                </tr>


                <tr scope="row">
                  <td>
                  <h3 style={{fontWeight:'bolder'}}align='left'>{' RECEIVED BY: '+this.props.selected_voucher.received_by}</h3>
                  <h3 style={{fontWeight:'bolder'}}align='left'>{' PAY TOTAL: '} <CurrencyFormat value={this.props.selected_voucher.totalAmount} displayType={'text'} thousandSeparator={true}/> </h3>
                  <h3 style={{fontWeight:'bolder'}}align='left'>{' PAY TOTAL (IN WORDS): '} {converter.toWords(Number(this.props.selected_voucher.totalAmount))} </h3>

                  <h3 style={{fontWeight:'bolder'}}align='left'> APPROVERS: 
                  {JSON.parse(this.props.selected_voucher.approvers_list)
                  .map((item)=>
                  {
                    return (item.approver_name+" ,")
                  
                  }
                  
                  )
                  }
                  
                  
                  </h3>                        

                  </td>
                </tr>
              
          </tbody>
          </reactstrp.Table>

          <br></br>
            <h3 style={{fontWeight:'bolder'}}>Generated by: {CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)}</h3>
            <h3 style={{fontWeight:'bolder'}}>Powered by: www.pitech.co.ug</h3>   

  </div>

    );
  }
}





class VoucherCreateForm extends React.Component {

  state = {
    item_type:'' ,  
    item_name:'' ,  
    unit:'',
    amount:0,
    unit_buying_price:0,
    discount_percentage:0,

    productDescription:'',
    productSerial:'',
    productImage:null,   
    productunits:[],
    products:[],
    ingredientslist:[],
    ingredientslistnames:[],

    dependantproduct:false,
    hassubproducts:false,

    quantity:0,
    items_list:[],
    itemupdate:false,
    selectedupdateitem:{},
    requestsent:true,
    itemnumber:0,
    service_product:false,

    approvers_list:[],
    received_by:'',
    approver_name:'',
    date:moment().format(dateFormat).toString(),
    selected_voucher:{},
    status_message:'',
    datasubmittedsuccessfully:false,

    cashflow_accounts_list:[],
    payment_channel:'',
    companyaccounts:[],
    selected_expense_ledger:'',
    members:[],
    selectedmemberid:'',
    selectedmemberobj:{},
  }


  componentDidMount(){

    if(localStorage.getItem("sacco")){
      sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
      username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
   
   }else{
      sacco= ''
      username=''
   }
   
   axios.get(serverconfig.backendserverurl+`/api/members/?sacco=${sacco}`)
   .then(res => {
       this.setState({
         members:res.data
       })
       this.setState({requestsent:false})
   })   

   axios.get(serverconfig.backendserverurl+`/api/saccos/${sacco}`)
   .then(res => {  
   this.setState({cashflow_accounts_list:res.data.cashflow_accounts_list!=null?JSON.parse(res.data.cashflow_accounts_list):[]})

   })

   axios.get(serverconfig.backendserverurl+`/api/companyaccounts/?chartofaccount_category=${'Expense'}&sacco=${sacco}`)
   .then(res => {
       this.setState({
           companyaccounts:res.data
       })
   })



  }

  //remove item
  removeItem=(id)=>{
    console.log(id)
    this.setState({ items_list:  [...this.state.items_list.filter(todo => todo.key
      !==id)]});
  }


    //clactulate totla of items
    calculateTotalofItems=()=>{
      var total=0
        this.state.items_list.map(
        (item)=>{
          total+=Number(item.amount)
        })
  
      return  total.toFixed(2);  
  
    }



  //submit button pressed
  additemtolist=(event) =>{

    var itm_nam=''
    if (this.state.item_type==="Savings_withdraw"){
      itm_nam=this.state.selectedmemberobj.FullName+" Savings withdraw"
    }else if (this.state.item_type==="Loan_disbursement"){
      itm_nam=this.state.selectedmemberobj.FullName+" Loan disbursement"
    } else{
      itm_nam=this.state.item_name
    }


    if(this.state.item_name==="" && this.state.item_type=="Other"){
      alert("Enter Item Name Please")
    } else if (this.state.item_type==="Other" && this.state.selected_expense_ledger===""){
      message.error("Please select expense account.")
    }else if (this.state.item_type==="Savings_withdraw" && JSON.stringify(this.state.selectedmemberobj)==="{}"){
      message.error("Please select member.")
    }else if (this.state.item_type==="Loan_disbursement" && JSON.stringify(this.state.selectedmemberobj)==="{}"){
      message.error("Please select member.")
    }
    
    else{
      if (this.state.itemupdate===true){
        this.setState({requestsent:true})
  
        const elementsIndex = this.state.items_list.findIndex(element => element.key == this.state.selectedupdateitem.key )
  
        let newArray = [...this.state.items_list]
  
        newArray[elementsIndex] = {...newArray[elementsIndex], 
          item_name:itm_nam,
          amount:this.state.amount,
          member_id:this.state.selectedmemberid,
          selected_expense_ledger:this.state.selected_expense_ledger,
          item_type:this.state.item_type,

        }
  
         this.setState({
          items_list: newArray,
          });
  
          this.setState({item_name:''});
          this.setState({amount:0});

          this.setState({itemupdate:false})
          this.setState({requestsent:false})

  
      }else{
        this.setState({requestsent:true})
  
        const newreceiptitem={
          key:Number(this.state.itemnumber)+1,
          itemnumber:(Number(this.state.itemnumber)+1),
          item_name:itm_nam,
          amount:this.state.amount,
          member_id:this.state.selectedmemberid,
          selected_expense_ledger:this.state.selected_expense_ledger,
          item_type:this.state.item_type,

         }
    
        //add to the receipt item list
        this.setState({
          items_list: [...this.state.items_list, newreceiptitem]
        });
    
        this.setState({item_name:''});
        this.setState({amount:0});
        this.setState({itemnumber:(Number(this.state.itemnumber)+1)})
  
        this.setState({requestsent:false})
  
      }
    }

  }

  handleitem_nameChange= (e) => this.setState({ item_name: e.target.value});
  handleamountChange= (e) => this.setState({ amount: e.target.value});


 onBlur=()=> {
  console.log('blur');
}

 onFocus=()=>{
  console.log('focus');
}

onSearch=(val)=> {
  console.log('search:', val);
}


//handle data submission
handleDatasubmission=()=>{

  if (this.state.items_list.length===0){
    message.error("There are no items in the list")

  }else if (this.state.approvers_list.length===0){
    message.error("There are no approvers")

  }else if (this.state.received_by==="") 
    message.error("Receiver missing")
  else{

    this.setState({requestsent:true})

    let form_data = new FormData();
    form_data.append('date', this.state.date);
    form_data.append('items_list', JSON.stringify(this.state.items_list));
    form_data.append('received_by', this.state.received_by);
    form_data.append('approvers_list', JSON.stringify(this.state.approvers_list));
    form_data.append('username', username);
    form_data.append('sacco', sacco);
    form_data.append('totalAmount', this.calculateTotalofItems());
    form_data.append('payment_channel', this.state.payment_channel);

    axios.post(serverconfig.backendserverurl+'/customqueries/createpayment_voucher', form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
    .then(res =>{
      this.setState({requestsent:false})
      this.setState({status_message:res.data.message})
      console.log(res.data.v_diction)
      this.setState({selected_voucher:res.data.v_diction})

      this.setState({datasubmittedsuccessfully:true})


    })
  .catch(error => console.log(error))

  }


}


  render(){

    if(this.state.requestsent===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} size={200} />
        </div>
      );

    }else{

      const items_listcolumns = [
        {
          title: '#',
          dataIndex: 'itemnumber',
          key: 'key',
        },
        {
          title: 'Item Name',
          dataIndex: 'item_name',
          key: 'key',
        },
        {
          title: 'Amount ',
          dataIndex: 'amount',
          key: 'key',
        },
       

        {
          title: 'Action',
          dataIndex: 'key',
          key: 'key',
          render: (text,record) =>
          <p>
          <Popover content={<p>Click here to edit</p>} title="Edit,View">
           <FundViewOutlined style={{color:'blue'}}  onClick={()=>{
              this.setState({itemupdate:true})
              this.setState({requestsent:true})
              this.setState({item_name:record.item_name})
              this.setState({amount:record.amount})
              this.setState({selectedupdateitem:record})

              this.setState({requestsent:false})

            }}/>
          </Popover>
          &nbsp;&nbsp;&nbsp;
          <Popover content={<p>Click here to delete </p>} title="Delete">
          <Popconfirm title="Sure to delete?" onConfirm={() => this.removeItem(text)}>
            <DeleteOutlined style={{color:'red'}} />
           </Popconfirm>
          </Popover>
          </p>,
        }
  
      ];

      if(this.state.datasubmittedsuccessfully===true){
        return(
          <div>
            <div style={{display: "none"}}>
            &nbsp;&nbsp;
            <ReportToPrint
            selected_voucher={this.state.selected_voucher} 
            date={moment().format(dateFormat).toString()}
            ref={el => (this.componentRef = el)} 
            /> 
            </div>

                    <Card>
                    <Result
                    status="success"
                    subTitle={this.state.status_message}
                    extra={[

                      <a>
                      <ReactToPrint
                        trigger={() =>                     
                          <div style={{}}>
                            <Tooltip title="Click to print" placement="top">
                            <p ><PrinterOutlined style={{ fontSize: '40px', color: '#08c' }}/></p>
                            <h6 >Print Voucher</h6>
                            </Tooltip>
                          </div>                          
                            }
                            content={() => this.componentRef}
                          />  
                          </a>,
                      <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
                    ]}
                    />
                   </Card>
          </div>

        )

      }else{

      return (
        <div>
          <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header="ITEMS LIST" key="1">
            <Table 
              scroll={{ x: 1000 }}
              columns={items_listcolumns}
              pagination={{showQuickJumper:true,showSizeChanger:true }}
              dataSource={this.state.items_list} bordered/>
              <h3>Voucher List Total : ( {this.state.items_list.length} ) Items</h3>
            </Panel>
            </Collapse>

            <br></br>
          <reactstrp.Table bordered>
          <thead>
            <tr>

            <th>
            <FormItem label="Received by">
              <Input name="received_by"  placeholder="Put a name here." value={this.state.received_by} onChange={(val)=>{this.setState({received_by:val.target.value})}} />
            </FormItem>
            </th>

            <th>
            <FormItem label=  {"Click to add approver"}>
                <PlusCircleFilled 
                style={{fontSize: '35px',margin:2,color:primarycolor}}
                onClick={()=>{
                  this.setState({approver_model:true})
                }}
                />
                </FormItem>

                {
                this.state.approvers_list.length>0?
                <div >
                  {
                    this.state.approvers_list.map((item)=>{
                      return (
                      <p>{item.key} | {item.approver_name} &nbsp;&nbsp;
                      <Popover content={<p>Click here to delete item</p>} title="Delete">
                      <Popconfirm title="Sure to delete?" onConfirm={() => {
                        this.setState({ approvers_list:  [...this.state.approvers_list.filter(todo => todo.key
                          !==item.key)]});
                      }}>
                        <DeleteOutlined style={{color:'red'}}/>
                      </Popconfirm>
                      </Popover>
                      
                      </p>)
                    })
                  }

                </div>
                :
                null
              }

            </th>

            <th>

            <FormItem label="Payment channel">
            <Select  name="Paymentchannel"
              placeholder="Payment channel" 
              value={this.state.payment_channel} 
              onChange={(val)=>{this.setState({payment_channel:val})}} >

              {
              this.state.cashflow_accounts_list.map((item)=>{
                return (<Option  value={item.account}>{item.account}</Option>)
              })
            }

              </Select>
            </FormItem>

            <h3>Total: {<CurrencyFormat value={this.calculateTotalofItems()} displayType={'text'} thousandSeparator={true}/>} </h3>

            <FormItem label={"Date: ( "+this.state.date+" )"}>
                <DatePicker onChange={(date, dateString) => this.setState({ date: dateString})} format={dateFormat} />
            </FormItem>   


            <Button  type="primary" htmlType='button'  onClick={()=>{
              this.handleDatasubmission()

            }}>SUBMIT VOUCHER</Button>
            </th>

            </tr>
          </thead>
           </reactstrp.Table>


           <br></br>
          <h3>{this.state.itemupdate?"Update "+this.state.item_name : "Add New Item To List"}</h3>
           <br></br>

           
           <Form
            onFinish={(event) => this.additemtolist(event)}>


        <FormItem label="Pay Item type">
          <Select placeholder="Item type"
            style={{ width: 300 }} 
           value={this.state.item_type}
            onChange={
            (val)=>{
            this.setState({item_type:val})
          }}
            >
              <Option value={'Savings_withdraw'}>Savings withdraw</Option>
              <Option value={'Loan_disbursement'}>Loan disbursement</Option>
              <Option value={'Other'}>Other expense</Option>
          </Select>
      </FormItem>


        {
            this.state.item_type==="Loan_disbursement" || this.state.item_type==="Savings_withdraw"?
            <FormItem label="Members">
            <Select 
            placeholder="Member Account"
            style={{ width: 300 }} 
            value={this.state.selectedmemberid} 
            onChange={
             (e) =>{
               this.setState({ selectedmemberid: e});
           
               axios.get(`${serverconfig.backendserverurl}/api/members/${e}`)
               .then(res => {  
                   this.setState({selectedmemberobj: res.data})
               })
           
              } 
            }
             
            showSearch
            optionFilterProp="children"
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onSearch={this.onSearch}                     
             >
              {this.state.members.map(
                (mmbr)=>{
     
                   return (
                     <Option value={mmbr.id}>{mmbr.AccountNumber} | {mmbr.FullName}</Option>
                   );
            
                })}
            </Select>
          </FormItem>
            :

            <FormItem label="Item">
            <Input name="item_name"  placeholder="Put an item here." value={this.state.item_name} onChange={this.handleitem_nameChange} />
            </FormItem>
          }


      {
          JSON.stringify(this.state.selectedmemberobj)==="{}"?
          null
          :
          <div style={{display:'flex'}}>

        <reactstrp.Table bordered>
          <thead>
            <tr>
              <th>
              <h4>Account Number:{this.state.selectedmemberobj.AccountNumber}</h4>
              <h4>Member Contact:{this.state.selectedmemberobj.RegisteredPhoneNumber1}</h4>
              <h4>Account Name:{this.state.selectedmemberobj.FullName}</h4>

              </th>
              <th>
              <h4>Savings balance:<CurrencyFormat value={Number(this.state.selectedmemberobj.savings_balance)} displayType={'text'} thousandSeparator={true}  /> </h4>

              </th>
            </tr>
          </thead>
          </reactstrp.Table>

           </div>    
          }
          <Divider></Divider>

  
      <FormItem label="Amount">
              <Input name="amount" type="number"  placeholder="Amount" value={this.state.amount} onChange={this.handleamountChange}/>
      </FormItem>
         
      {
           this.state.item_type==="Other"?
           <FormItem label={"Select expense account"}>
           <Select 
           placeholder="Account" 
           style={{  }} 
           value={this.state.selected_expense_ledger}
           onChange={(val)=>{this.setState({selected_expense_ledger:val})}} 
           showSearch
           optionFilterProp="children"
           onFocus={this.onFocus}
           onBlur={this.onBlur}
           onSearch={this.onSearch}                    
           >
           {this.state.companyaccounts.map(
             (accnt)=>(
               <Option value={accnt.account_name}>{accnt.account_name} | {accnt.account_code}</Option>
             ))}
         </Select>
          </FormItem>
           :
           null
          }


      <FormItem >
        <Button  type="primary" htmlType="submit">{this.state.itemupdate?" Update":" Add"} </Button>
      </FormItem>
       </Form>

       <Modal              
              visible={this.state.approver_model}
              title={" Approver addition Form"}
              onCancel={()=>{this.setState({approver_model:false})}}
              style={{width:1000}}
              footer={[
                <Button key="back" onClick={()=>{this.setState({approver_model:false})}}>
                  Cancel
                </Button>,
                  <Button key="ok" type='primary' 
                    onClick={()=>{  

                      var intobj={
                        'key':(Number(this.state.approvers_list.length)+1),
                        'approver_name':this.state.approver_name,
                      }

                      this.setState({approvers_list: [...this.state.approvers_list, intobj]});
                      this.setState({approver_name:''})
                      this.setState({approver_model:false})
                      message.info("Approver added")

                    }}>
                  Add Approver
                </Button>
                ]}
                >
   
                  <FormItem label="Approver name " >
                      <Input name="approver_name"  placeholder="Approver name" value={this.state.approver_name} onChange={(val)=>{this.setState({approver_name:val.target.value})}} />
                  </FormItem>                  

               </Modal> 

        </div>
      );
            }

    }

  }

}


export default VoucherCreateForm;



