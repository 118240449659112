import React,{useState,useEffect,lazy} from 'react';
import axios from 'axios'
import {  Slider,Statistic,Tabs,Modal } from 'antd';
import { ArrowUpOutlined,MinusCircleFilled,ToolOutlined, ArrowDownOutlined,FileFilled,WalletFilled,SearchOutlined,FundViewOutlined,
   UserOutlined, AccountBookOutlined,CalculatorFilled,SwapOutlined,GroupOutlined,AccountBookFilled, DollarCircleOutlined,EditFilled,MessageFilled,DollarOutlined,CarFilled,LoadingOutlined,PlusCircleFilled } from '@ant-design/icons';
import moment from 'moment';
import { Line,Pie,Bar } from 'react-chartjs-2';
import {withGetScreen} from 'react-getscreen'
import { Tooltip,Popover,Table,Popconfirm,Input, Button,Collapse,Card,Avatar,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Spin } from 'antd';
import {
  Col,
  Row,
} from 'reactstrap';
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';
import { getStyle, hexToRgba } from '@coreui/coreui/dist/js/coreui-utilities'
import * as serverconfig from '../serverconn'
import * as serversocketconfig from '../serversocketconfig'
import { MdShoppingCart } from 'react-icons/md';

import CurrencyFormat from 'react-currency-format';
import Highlighter from 'react-highlight-words';
import Websocket from 'react-websocket';
import { Link } from 'react-router-dom';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { NavLink } from 'react-router-dom'
import  MemberForm from '../components/MemberForm'
import  LoanCalculator from '../components/LoanCalculator'
import * as primarycolor from '../primarycolor'
import QuickMenu from './QuickMenu'
import LoanAgingStatistics from './LoanAgingStatistics';

var CryptoJS = require("crypto-js");

const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;


const style = { background: '#0092ff', padding: '8px 0',borderRadius: '7px',justifyContent:'center', alignItems:'center',display: 'flex', };
const dateFormat = 'DD/MM/YYYY';
const yeardateFormat = 'YYYY';
const monthdateFormat = 'MM/YYYY';

const { Meta } = Card;
const { Panel } = Collapse;
const { TabPane } = Tabs;
const FormItem=Form.Item;
const { Option } = Select;

var bizuserid= ''
var sacco= ''
var token= ''


class DashBoard extends React.Component {

  state = {
    currentmonth:moment().format(monthdateFormat).toString(),
    companyprofile:{},
    datarequested:true,
    memberformvisible:false,
    transactformvisible:false,
    calculatorformvisible:false,
    members:[],
    loansformvisible:false,
    dashboardlocked:false,
    utilitiesmodalvisible:false,
    userprofile:{},
    userrights:{},
    nonworkingday:{},
    depositsno:0,
    withdrawsno:0,
    depositvolume:0,
    withdrawvolume:0,
    tranquerymode:'Daily',
    expensevolumes:0,
    incomevolumes:0,
    datareload:false,
    withdrawpercentage:0,
    withdrwdiff:0,
    depositpercentage:0,
    depositdiff:0,
    percenategincomes:0,
    incomediff:0,
    percenategexpnses:0,
    expensdiff:0,
    weeklydays:[],
    weeklydeposits:[],
    weeklywithdraws:[],
    weeklyincomes:[],
    weeklyexpenses:[],

    weeklychartdata:[],
    weeklychartitems:[],

    graphtranquerymode:'weekly',
    loanproccessuerymode:'daily',

    totalapplications:0,
    totalapplicationamounts:0,
    totalassessments:0,
    totalassessmentsamounts:0,
    totalapprovals:0,
    totalapprovalsamounts:0,
    totaldisbursements:0,
    totaldisbursementsamounts:0,

    projectedmonths:"three",
    monthsprojected:[],
    projectedinterest:[],
    projectedprinciple:[],

    applicationvsdates:[],
    applicationvs:[],
    issuancesvs:[],
    applicvsmode:'weekly',

    totalmemberaccounts:0,
    loan_portfolio:0,
    totalmembershares:0,
    totalmembersavings:0,

    totalfemaleaccounts:0,
    totalmaleaccounts:0,
    groupaccounts:0,
    extra_data:{},
    total_inst_balance:0


  };


  callback =(key) =>{
    console.log(key);
    console.log(this.state.yearlymonthlysales)
  }

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
   }else{
      bizuserid= ''
   }
    
    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

   axios.get(serverconfig.backendserverurl+`/api/accounts/${bizuserid}`)
   .then(res => {
       this.setState({
        userprofile:res.data
       })
   })

   axios.get(serverconfig.backendserverurl+`/api/members/?sacco=${sacco}`)
   .then(res => {
       this.setState({
         totalmemberaccounts:res.data.length
       })

   })

   axios.get(serverconfig.backendserverurl+`/api/members/?sacco=${sacco}&groupAccount=${true}`)
   .then(res => {
       this.setState({
        groupaccounts:res.data.length
       })

   })


   let form_data_1 = new FormData();
   form_data_1.append('sacco', sacco);
   form_data_1.append('date', moment().format(dateFormat).toString());

   //Now submit sale data to database
   axios.post(serverconfig.backendserverurl+'/customqueries/getLoanAgingReport', form_data_1,{
   headers: {
     'content-type': 'multipart/form-data'
   }
   })
   .then(res =>{
     this.setState({loan_portfolio:res.data.portifoliototal})

   } 
   )
   .catch(error => console.log(error)) 


   //get other balances
   let form_data_2 = new FormData();
   form_data_2.append('sacco', sacco);
   form_data_2.append('date', moment().format(dateFormat).toString());

   //Now submit sale data to database
   axios.post(serverconfig.backendserverurl+'/customqueries/calculate_balances', form_data_2,{
   headers: {
     'content-type': 'multipart/form-data'
   }
   })
   .then(res =>{
     this.setState({totalmembersavings:res.data.savings_balance})
     this.setState({totalmembershares:res.data.sharesamount_balance})

   } 
   )
   .catch(error => console.log(error)) 



    axios.get(serverconfig.backendserverurl+`/api/saccos/${sacco}`)
    .then(res => {
        this.setState({
          companyprofile:res.data
        })
        if (res.data.activated===false){
          this.setState({dashboardlocked:true})
        }
    })


    //get first statistics
    let form_data = new FormData();
    form_data.append('sacco', sacco);
    form_data.append('tranquerymode', this.state.tranquerymode);

    //Now send the post resquest
      axios.post(serverconfig.backendserverurl+'/customqueries/gettransactiontatistics', form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res => {
      this.setState({withdrawsno:JSON.parse(res.data.withdraws).length})
      this.setState({depositsno:JSON.parse(res.data.deposits).length})

      var totwh=0
      var todp=0
      JSON.parse(res.data.withdraws).map((item)=>{
        totwh+=Number(item.amount)
      })

      JSON.parse(res.data.deposits).map((item)=>{
        todp+=Number(item.amount)
      })

      this.setState({withdrawvolume:totwh})
      this.setState({depositvolume:todp})


      var totincmes=0
      var toxps=0
      JSON.parse(res.data.incomes).map((item)=>{
        totincmes+=Number(item.amount)
      })

      JSON.parse(res.data.expenses).map((item)=>{
        toxps+=Number(item.amount)
      })

      this.setState({incomevolumes:totincmes})
      this.setState({expensevolumes:toxps})

      this.setState({withdrawpercentage:res.data.percenategwith})
      this.setState({withdrwdiff:res.data.withdrwdiff})

      this.setState({depositpercentage:res.data.percenategdepos})
      this.setState({depositdiff:res.data.depositdiff})

      this.setState({percenategincomes:res.data.percenategincomes})
      this.setState({incomediff:res.data.incomediff})

      this.setState({percenategexpnses:res.data.percenategexpnses})
      this.setState({expensdiff:res.data.expensdiff})

    })
    .catch(error => console.log(error)) 


    //get graph data
    let form_data1 = new FormData();
    form_data1.append('sacco', sacco);
    form_data1.append('tranquerymode', this.state.graphtranquerymode);

    //Now send the post resquest
      axios.post(serverconfig.backendserverurl+'/customqueries/getdashboardchartdata', form_data1, {
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res => {
      var wdepos=[]
      var wwthds=[]
      var wincs=[]
      var wexps=[]
      var wdys=[]

      console.log(res.data.alltransactions)

      JSON.parse(res.data.alltransactions).map((item)=>{
        wdepos.push(item.deposit)
        wdys.push(item.date)
        wwthds.push(item.withdaw)
      })

      JSON.parse(res.data.allincomes_exps).map((item)=>{
        wincs.push(item.totalinc)
        wexps.push(item.totexpes)
      })

      this.setState({weeklydays:wdys})
      this.setState({weeklydeposits:wdepos})
      this.setState({weeklywithdraws:wwthds})

      this.setState({weeklyincomes:wincs})
      this.setState({weeklyexpenses:wexps})

    })
    .catch(error => console.log(error)) 



    //get graph data
    let form_data2 = new FormData();
    form_data2.append('sacco', sacco);
    form_data2.append('tranquerymode', this.state.loanproccessuerymode);

    //Now send the post resquest
      axios.post(serverconfig.backendserverurl+'/customqueries/getloanprocesschartdata', form_data2, {
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res => {
      
      var wkcha=[]
      var wchitems=[]

      wchitems.push('Applications')
      wchitems.push('Assessments')
      wchitems.push('Approval')
      wchitems.push('Issuances')

      wkcha.push(JSON.parse(res.data.piechartdata).totapplications)
      wkcha.push(JSON.parse(res.data.piechartdata).totassessments)
      wkcha.push(JSON.parse(res.data.piechartdata).totapproval)
      wkcha.push(JSON.parse(res.data.piechartdata).totissuances)
      this.setState({weeklychartdata:wkcha})
      this.setState({weeklychartitems:wchitems})

      this.setState({totalapplications:JSON.parse(res.data.piechartdata).totapplications})
      this.setState({totalassessments:JSON.parse(res.data.piechartdata).totassessments})
      this.setState({totalapprovals:JSON.parse(res.data.piechartdata).totapproval})
      this.setState({totaldisbursements:JSON.parse(res.data.piechartdata).totissuances})

      this.setState({totalapplicationamounts:JSON.parse(res.data.piechartdata).totalapplicationamounts})
      this.setState({totalassessmentsamounts:JSON.parse(res.data.piechartdata).totalassessmentsamounts})
      this.setState({totalapprovalsamounts:JSON.parse(res.data.piechartdata).totalapprovalsamounts})
      this.setState({totaldisbursementsamounts:JSON.parse(res.data.piechartdata).totaldisbursementsamounts})

    })
    .catch(error => console.log(error)) 

    //get graph data
    let form_data3 = new FormData();
    form_data3.append('sacco', sacco);
    form_data3.append('projectedmonths', this.state.projectedmonths);

    //Now send the post resquest
      axios.post(serverconfig.backendserverurl+'/customqueries/getloanprojections', form_data3, {
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res => {

      var projdmonths=[]
      var projectedintesrt=[]
      var projectpribncip=[]

      JSON.parse(res.data.datalist).map((item)=>{
        projdmonths.push(item.month)
        projectedintesrt.push(item.totalinterest)
        projectpribncip.push(item.totalprinciple)
      })

      this.setState({monthsprojected:projdmonths})
      this.setState({projectedinterest:projectedintesrt})
      this.setState({projectedprinciple:projectpribncip})
      
    })
    .catch(error => console.log(error)) 



    let form_data4 = new FormData();
    form_data4.append('sacco', sacco);
    form_data4.append('tranquerymode', this.state.applicvsmode);

    //Now send the post resquest
      axios.post(serverconfig.backendserverurl+'/customqueries/getaplicationsvsissuances', form_data4, {
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res => {
      var dts=[]
      var isunces=[]
      var aplcs=[]

      JSON.parse(res.data.alltransactions).map((item)=>{
        aplcs.push(item.totapplications)
        isunces.push(item.totissuances)
        dts.push(item.date)
      })

      this.setState({applicationvsdates:dts})
      this.setState({applicationvs:aplcs})
      this.setState({issuancesvs:isunces})


      //dataloading
      this.setState({datarequested:false})


    })
    .catch(error => console.log(error)) 


    axios.get(serverconfig.backendserverurl+`/api/members/?sacco=${sacco}&gender=${'Female'}`)
    .then(res => {
        this.setState({
          totalfemaleaccounts:res.data.length
        })

    })

    axios.get(serverconfig.backendserverurl+`/api/members/?sacco=${sacco}&gender=${'Male'}`)
    .then(res => {
        this.setState({
          totalmaleaccounts:res.data.length
        })

    })



    //get other balances
    let form_data_5 = new FormData();
    form_data_5.append('sacco', sacco);
    form_data_5.append('date', moment().format(dateFormat).toString());

    //Now submit sale data to database
    axios.post(serverconfig.backendserverurl+'/customqueries/calculate_balances', form_data_5,{
    headers: {
      'content-type': 'multipart/form-data'
    }
    })
    .then(res =>{
      this.setState({totalmembersavings:res.data.savings_balance})
      this.setState({totalmembershares:res.data.sharesamount_balance})

      //dat
      this.setState({datarequested:false})

    } 
    )
    .catch(error => console.log(error)) 



    //LET ME GET THE LOAN PAYMENTS ,SHARES AND MEMBERS
  ///////////////////////////
  ////  get sahres and loand
  ////////////////////////\
  ///////////////////////////
  let form_data_1_1 = new FormData();
  form_data_1_1.append('sacco', sacco);
  form_data_1_1.append('tranquerymode', this.state.tranquerymode);

  //Now send the post resquest
    axios.post(serverconfig.backendserverurl+'/customqueries/getincomestatement_periodic_summary_dashboard', form_data_1_1, {
    headers: {
      'content-type': 'multipart/form-data'
    }
    })
  .then(res => {
      //get the extar data
      this.setState({
      extra_data:{
        "new_members_total":res.data.new_members_total,
        "total_savings_withdrawn":res.data.total_savings_withdrawn,
        "total_loan_repayment_rinciple":res.data.total_loan_repayment_rinciple,
        "total_loan_issuances":res.data.total_loan_issuances,
        "total_shares":res.data.total_shares,
        "total_savings":res.data.total_savings,                
      }
      })

  })
  .catch(error => console.log(error)) 


  let form_data_1_1_1 = new FormData();
  form_data_1_1_1.append('sacco', sacco);
  form_data_1_1_1.append('dateone', moment().format(dateFormat).toString());
  form_data_1_1_1.append('datetwo', moment().format(dateFormat).toString());
  form_data_1_1_1.append('selected_laccount', '');

  //Now send the post resquest
    axios.post(serverconfig.backendserverurl+'/customqueries/getCashFlowReport', form_data_1_1_1, {
    headers: {
      'content-type': 'multipart/form-data'
    }
    })
  .then(res => {
      //get the extar data
    this.setState({total_inst_balance:Number(res.data.total_inst_balance)})

  })
  .catch(error => console.log(error)) 




}


//get transactions
gettransactiondata=(val)=>{
  this.setState({datareload:true})

  let form_data = new FormData();
  form_data.append('sacco', sacco);
  form_data.append('tranquerymode', val);

  //Now send the post resquest
    axios.post(serverconfig.backendserverurl+'/customqueries/gettransactiontatistics', form_data, {
    headers: {
      'content-type': 'multipart/form-data'
    }
    })
  .then(res => {
    this.setState({withdrawsno:JSON.parse(res.data.withdraws).length})
    this.setState({depositsno:JSON.parse(res.data.deposits).length})

    var totwh=0
    var todp=0
    JSON.parse(res.data.withdraws).map((item)=>{
      totwh+=Number(item.amount)
    })

    JSON.parse(res.data.deposits).map((item)=>{
      todp+=Number(item.amount)
    })

    this.setState({withdrawvolume:totwh})
    this.setState({depositvolume:todp})


    var totincmes=0
    var toxps=0
    JSON.parse(res.data.incomes).map((item)=>{
      totincmes+=Number(item.amount)
    })

    JSON.parse(res.data.expenses).map((item)=>{
      toxps+=Number(item.amount)
    })

    this.setState({incomevolumes:totincmes})
    this.setState({expensevolumes:toxps})

    this.setState({withdrawpercentage:res.data.percenategwith})
    this.setState({withdrwdiff:res.data.withdrwdiff})

    this.setState({depositpercentage:res.data.percenategdepos})
    this.setState({depositdiff:res.data.depositdiff})

    this.setState({percenategincomes:res.data.percenategincomes})
    this.setState({incomediff:res.data.incomediff})

    this.setState({percenategexpnses:res.data.percenategexpnses})
    this.setState({expensdiff:res.data.expensdiff})

    this.setState({datareload:false})

  }
  )
  .catch(error => console.log(error)) 



  ///////////////////////////
  ////  get sahres and loand
  ////////////////////////\
  ///////////////////////////
  let form_data_1 = new FormData();
  form_data_1.append('sacco', sacco);
  form_data_1.append('tranquerymode', val);

  //Now send the post resquest
    axios.post(serverconfig.backendserverurl+'/customqueries/getincomestatement_periodic_summary_dashboard', form_data_1, {
    headers: {
      'content-type': 'multipart/form-data'
    }
    })
  .then(res => {
      //get the extar data
      this.setState({
      extra_data:{
        "new_members_total":res.data.new_members_total,
        "total_savings_withdrawn":res.data.total_savings_withdrawn,
        "total_loan_repayment_rinciple":res.data.total_loan_repayment_rinciple,
        "total_loan_issuances":res.data.total_loan_issuances,
        "total_shares":res.data.total_shares,
        "total_savings":res.data.total_savings,                
      }
      })

  })
  .catch(error => console.log(error)) 



}


//get transactions
gettransactionchartdata=(val)=>{

  let form_data1 = new FormData();
  form_data1.append('sacco', sacco);
  form_data1.append('tranquerymode', val);

  //Now send the post resquest
    axios.post(serverconfig.backendserverurl+'/customqueries/getdashboardchartdata', form_data1, {
    headers: {
      'content-type': 'multipart/form-data'
    }
    })
  .then(res => {
    var wdepos=[]
    var wwthds=[]
    var wincs=[]
    var wexps=[]
    var wdys=[]

    console.log(res.data.alltransactions)

    JSON.parse(res.data.alltransactions).map((item)=>{
      wdepos.push(item.deposit)
      wdys.push(item.date)
      wwthds.push(item.withdaw)
    })

    JSON.parse(res.data.allincomes_exps).map((item)=>{
      wincs.push(item.totalinc)
      wexps.push(item.totexpes)
    })
    
    this.setState({weeklydays:wdys})
    this.setState({weeklydeposits:wdepos})
    this.setState({weeklywithdraws:wwthds})

    this.setState({weeklyincomes:wincs})
    this.setState({weeklyexpenses:wexps})

  })
  .catch(error => console.log(error)) 

}


//get loan process pie data
getloanprocesspiedata=(val)=>{
  let form_data2 = new FormData();
  form_data2.append('sacco', sacco);
  form_data2.append('tranquerymode', val);

  //Now send the post resquest
    axios.post(serverconfig.backendserverurl+'/customqueries/getloanprocesschartdata', form_data2, {
    headers: {
      'content-type': 'multipart/form-data'
    }
    })
  .then(res => {
    
    var wkcha=[]
    var wchitems=[]

    wchitems.push('Applications')
    wchitems.push('Assessments')
    wchitems.push('Approval')
    wchitems.push('Issuances')

    wkcha.push(JSON.parse(res.data.piechartdata).totapplications)
    wkcha.push(JSON.parse(res.data.piechartdata).totassessments)
    wkcha.push(JSON.parse(res.data.piechartdata).totapproval)
    wkcha.push(JSON.parse(res.data.piechartdata).totissuances)

    this.setState({weeklychartdata:wkcha})
    this.setState({weeklychartitems:wchitems})

    this.setState({totalapplications:JSON.parse(res.data.piechartdata).totapplications})
    this.setState({totalassessments:JSON.parse(res.data.piechartdata).totassessments})
    this.setState({totalapprovals:JSON.parse(res.data.piechartdata).totapproval})
    this.setState({totaldisbursements:JSON.parse(res.data.piechartdata).totissuances})

    this.setState({totalapplicationamounts:JSON.parse(res.data.piechartdata).totalapplicationamounts})
    this.setState({totalassessmentsamounts:JSON.parse(res.data.piechartdata).totalassessmentsamounts})
    this.setState({totalapprovalsamounts:JSON.parse(res.data.piechartdata).totalapprovalsamounts})
    this.setState({totaldisbursementsamounts:JSON.parse(res.data.piechartdata).totaldisbursementsamounts})


  })
  .catch(error => console.log(error)) 

}


render() {

  const transchartdata = {
    labels: this.state.weeklydays,
    datasets: [
      {
        label: 'Deposits',
        data: this.state.weeklydeposits,
        fill: false,
        backgroundColor: 'rgb(255, 99, 132)',
        borderColor: 'rgba(255, 99, 132, 0.2)',
        yAxisID: 'y-axis-1',
      },
      {
        label: 'Withdraws',
        data: this.state.weeklywithdraws,
        fill: false,
        backgroundColor: 'rgb(54, 162, 235)',
        borderColor: 'rgba(54, 162, 235, 0.2)',
        yAxisID: 'y-axis-2',
      },
    ],
  };


  const projectionchartdata = {
    labels: this.state.monthsprojected,
    datasets: [
      {
        label: 'Projected Interest',
        data: this.state.projectedinterest,
        fill: false,
        backgroundColor: 'rgb(255, 99, 132)',
        borderColor: 'rgba(255, 99, 132, 0.2)',
        yAxisID: 'y-axis-1',
      },
      {
        label: 'Projected Principle',
        data: this.state.projectedprinciple,
        fill: false,
        backgroundColor: 'rgb(54, 162, 235)',
        borderColor: 'rgba(54, 162, 235, 0.2)',
        yAxisID: 'y-axis-2',
      },
    ],
  };
  
  const transchartoptions = {
    scales: {
      yAxes: [
        {
          type: 'linear',
          display: true,
          position: 'left',
          id: 'y-axis-1',
        },
        {
          type: 'linear',
          display: true,
          position: 'right',
          id: 'y-axis-2',
          gridLines: {
            drawOnArea: false,
          },
        },
      ],
    },
  };


  const inc_expchartdata = {
    labels: this.state.weeklydays,
    datasets: [
      {
        label: 'Incomes',
        data: this.state.weeklyincomes,
        fill: false,
        backgroundColor: 'rgb(54, 162, 235)',
        borderColor: 'rgba(54, 162, 235, 0.2)',    
        yAxisID: 'y-axis-1',
      },
      {
        label: 'Expenses',
        data: this.state.weeklyexpenses,
        fill: false,
        backgroundColor: 'rgb(255, 99, 132)',
        borderColor: 'rgba(255, 99, 132, 0.2)',
        yAxisID: 'y-axis-2',
      },
    ],
  };
  
  const inc_expchartoptions = {
    scales: {
      yAxes: [
        {
          type: 'linear',
          display: true,
          position: 'left',
          id: 'y-axis-1',
        },
        {
          type: 'linear',
          display: true,
          position: 'right',
          id: 'y-axis-2',
          gridLines: {
            drawOnArea: false,
          },
        },
      ],
    },
  };


  const weeklyscaharrdata = {
    labels: this.state.weeklychartitems,
    datasets: [
      {
        label: 'loan processes',
        data: this.state.weeklychartdata,
        fill: false,
        backgroundColor:[
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)'
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)'
      ],
        yAxisID: 'y-axis-1',
      }

    ],
  };


  const aplic_vs_issdata = {
    labels: this.state.applicationvsdates,
    datasets: [
      {
        label: 'Application',
        data: this.state.applicationvs,
        fill: false,
        backgroundColor: 'rgb(54, 162, 235)',
        borderColor: 'rgba(54, 162, 235, 0.2)',    
        yAxisID: 'y-axis-1',
      },
      {
        label: 'Issuances',
        data: this.state.issuancesvs,
        fill: false,
        backgroundColor: 'rgb(255, 99, 132)',
        borderColor: 'rgba(255, 99, 132, 0.2)',
        yAxisID: 'y-axis-2',
      },
    ],
  };




if(this.state.dashboardlocked===true){
  return(
    <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
    <h3 style={{fontWeight:'bolder',color:'red'}}>NOTE: Your dashboard is currently locked. Contact system adminstrators.Your Licence may be expired or some thing else. Thanks</h3>
    </div>
  )
}else{

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin size="large">
              <div className="content">
                <h3>Pisacco</h3>
              </div>
               </Spin>        
        </div>
      )
  
    }else{


      const  memberchartData={
        labels:['Single Male','Single Female','Group/Joint'],
        datasets:[{
          label:'Members by category',
          data:[this.state.totalmaleaccounts,this.state.totalfemaleaccounts,this.state.groupaccounts],
          backgroundColor:[
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)'
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)'
        ],
        borderWidth: 1
        }]
      }
  



      if (this.state.userprofile.branch_manager===true){
        return(

          <div className="animated fadeIn">  
          <h3 style={{fontStyle:'oblique',fontWeight:'bolder',color:primarycolor.primarycolor}}>{this.state.companyprofile.sacco_name}</h3>

              <Row>
              <Col xs="12" sm="6" lg="6" style={{padding: '5px'}}>
                <Card
                    //onClick={()=>{}}
                    hoverable
                    style={{ 
                      background:"white",
                      display: 'flex',
                      justifyContent:'center',
                      padding:4,
                      alignItems:'center' }}
                  > 
                  <NavLink to='/members/'>
                  <h5 style={{fontWeight:'bolder',color:primarycolor.primarycolor}}>MEMBERS: {this.state.totalmemberaccounts}</h5>

                  </NavLink>

                      
                    <Bar
                        data={memberchartData}
                        options={{
                          maintainAspectRatio:true,
                          title:{
                            display:true,
                            text:'Members by category',
                            fontSize:10
                          }
                        }}
                        />
                      

                  </Card>   
              </Col>
          

              <Col xs="12" sm="6" lg="6" style={{padding: '5px'}}>
                <Card
                    //onClick={()=>{}}
                    hoverable
                    style={{ 
                      background:"white",
                      display: 'flex',
                      justifyContent:'center',
                      padding:4,
                      alignItems:'center' }}
                  > 
                  <h5 style={{fontWeight:'bolder',color:primarycolor.primarycolor}}>SAVINGS:  <strong><CurrencyFormat value={this.state.totalmembersavings} displayType={'text'} thousandSeparator={true}  /></strong>  </h5>
                      <Divider></Divider>
                  <h5 style={{fontWeight:'bolder',color:primarycolor.primarycolor}}> SHARES:   <strong><CurrencyFormat value={this.state.totalmembershares} displayType={'text'} thousandSeparator={true}  /></strong> </h5>
                      <Divider></Divider>

                  <h5 style={{fontWeight:'bolder',color:primarycolor.primarycolor}}>LOAN PORTFOLIO:   <strong><CurrencyFormat value={this.state.loan_portfolio} displayType={'text'} thousandSeparator={true}  /></strong></h5>
                        <Divider></Divider>
                   <h5 style={{fontWeight:'bolder',color:primarycolor.primarycolor}}>TOTAL CASH BALANCE:   <strong><CurrencyFormat value={this.state.total_inst_balance} displayType={'text'} thousandSeparator={true}  /></strong></h5>

                  </Card>   
              </Col>

              </Row>  
              <br></br>   
              <Card>
              <LoanAgingStatistics />

              </Card>    
               <br></br>       


          <Row>
            <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
              <Card style={{display:'flex',alignItems:'center',justifyContent:'center'}}>

            <div style={{display:'flex',alignItems:'flex-end',justifyContent:'flex-end',flexDirection:'row'}}>
            <Select 
              value={this.state.tranquerymode} 
              onChange={(val)=>{this.setState({tranquerymode:val})
              //query data
               this.gettransactiondata(val)
            
            }}
              placeholder="Query Mode" 
              style={{display:'flex',marginRight:3}}
              >
                <Option value='Daily'>Daily</Option>
                <Option value='Weekly'>Weekly</Option>
                <Option value='Monthly'>Monthly</Option>
              </Select>

              {
                this.state.datareload===true?
                <Spin indicator={antIcon} />
                :null
              }

            </div>

            <div style={{display:'flex',alignItems:'center',justifyContent:'center',alignContent:'center',flexDirection:'column'}}>
            <h5 style={{display:'flex',marginRight:5,fontSize:16,color:primarycolor.primarycolor}}> {this.state.tranquerymode} Deposits </h5>
                <h6 style={{display:'flex',fontSize:22,fontWeight:'bold'}}>{this.state.depositsno}</h6>

          </div>


              </Card>
            </Col>

            <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
              <Card style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
              <div style={{display:'flex',alignItems:'flex-end',justifyContent:'flex-end',flexDirection:'row'}}>
            <Select 
              value={this.state.tranquerymode} 
              onChange={(val)=>{this.setState({tranquerymode:val})
              //query data
               this.gettransactiondata(val)
            
            }}
              placeholder="Query Mode" 
              style={{display:'flex',marginRight:3}}
              >
                <Option value='Daily'>Daily</Option>
                <Option value='Weekly'>Weekly</Option>
                <Option value='Monthly'>Monthly</Option>
              </Select>

              {
                this.state.datareload===true?
                <Spin indicator={antIcon} />
                :null
              }

            </div>

            <div style={{display:'flex',alignItems:'center',justifyContent:'center',alignContent:'center',flexDirection:'column'}}>
            <h5 style={{display:'flex',marginRight:5,fontSize:16,color:primarycolor.primarycolor}}>  {this.state.tranquerymode} Withdraws</h5>
              <h6 style={{display:'flex',fontSize:22,fontWeight:'bold'}}>{this.state.withdrawsno}</h6>

            </div>


              </Card>
            </Col>

            <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
            <Card style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
            <div style={{display:'flex',alignItems:'flex-end',justifyContent:'flex-end',flexDirection:'row'}}>
            <Select 
              value={this.state.tranquerymode} 
              onChange={(val)=>{this.setState({tranquerymode:val})
              //query data
               this.gettransactiondata(val)
            
            }}
              placeholder="Query Mode" 
              style={{display:'flex',marginRight:3}}
              >
                <Option value='Daily'>Daily</Option>
                <Option value='Weekly'>Weekly</Option>
                <Option value='Monthly'>Monthly</Option>
              </Select>

              {
                this.state.datareload===true?
                <Spin indicator={antIcon} />
                :null
              }

            </div>

            <div style={{display:'flex',alignItems:'center',justifyContent:'center',alignContent:'center',flexDirection:'column'}}>
            <h5 style={{display:'flex',marginRight:5,fontSize:16,color:primarycolor.primarycolor}}>  {this.state.tranquerymode} Deposit Volume</h5>
            <h6 style={{display:'flex',fontSize:22,fontWeight:'bold'}}><CurrencyFormat value={this.state.depositvolume.toFixed(2)} displayType={'text'} thousandSeparator={true}  /></h6>

            </div>
            

            </Card>

            </Col>
            <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
            <Card style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
            <div style={{display:'flex',alignItems:'flex-end',justifyContent:'flex-end',flexDirection:'row'}}>
            <Select 
              value={this.state.tranquerymode} 
              onChange={(val)=>{this.setState({tranquerymode:val})
              //query data
               this.gettransactiondata(val)
            
            }}
              placeholder="Query Mode" 
              style={{display:'flex',marginRight:3}}
              >
                <Option value='Daily'>Daily</Option>
                <Option value='Weekly'>Weekly</Option>
                <Option value='Monthly'>Monthly</Option>
              </Select>

              {
                this.state.datareload===true?
                <Spin indicator={antIcon} />
                :null
              }

            </div>
            <div style={{display:'flex',alignItems:'center',justifyContent:'center',alignContent:'center',flexDirection:'column'}}>
            <h5 style={{display:'flex',marginRight:5,fontSize:16,color:primarycolor.primarycolor}}> {this.state.tranquerymode} Withdraw Volume</h5>
            <h6 style={{display:'flex',fontSize:22,fontWeight:'bold'}}><CurrencyFormat value={this.state.withdrawvolume.toFixed(2)} displayType={'text'} thousandSeparator={true}  /></h6>

           </div>

           </Card>

          </Col>
        </Row>

        <br></br>

      <Row>
        <Col xs="12" sm="6" lg="6" style={{padding: '5px'}}>
          <Card style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
          <div style={{display:'flex',alignItems:'center',justifyContent:'center',alignContent:'center',flexDirection:'column'}}>

          <h5 style={{display:'flex',marginRight:5,fontSize:16,color:primarycolor.primarycolor}}> {this.state.tranquerymode} Incomes </h5>
            <h6 style={{display:'flex',fontSize:22,fontWeight:'bold'}}><CurrencyFormat value={this.state.incomevolumes.toFixed(2)} displayType={'text'} thousandSeparator={true}  /></h6>

          </div>
          </Card>
        </Col>

        <Col xs="12" sm="6" lg="6" style={{padding: '5px'}}>
          <Card style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
          <div style={{display:'flex',alignItems:'center',justifyContent:'center',alignContent:'center',flexDirection:'column'}}>
          <h5 style={{display:'flex',marginRight:5,fontSize:16,color:primarycolor.primarycolor}}> {this.state.tranquerymode} Expenses</h5>
          <h6 style={{display:'flex',fontSize:22,fontWeight:'bold'}}><CurrencyFormat value={this.state.expensevolumes.toFixed(2)} displayType={'text'} thousandSeparator={true}  /></h6>

         </div>

          </Card>
        </Col>

      </Row>

     <br></br>

      <Row>

            <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
              <Card style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
              <div style={{display:'flex',alignItems:'center',justifyContent:'center',alignContent:'center',flexDirection:'column'}}>
              <h5 style={{display:'flex',marginRight:5,fontSize:16,color:primarycolor.primarycolor,alignSelf:'center'}}> + {this.state.depositpercentage} % </h5>
              <h6 style={{display:'flex',fontSize:16,fontWeight:'bold',alignSelf:'center'}}>{this.state.depositdiff}</h6>
              <p style={{display:'flex',fontSize:16,alignSelf:'center'}}>New deposits</p>
              </div>
              
              </Card>

            </Col>



            <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
              <Card style={{display:'flex',alignItems:'center',justifyContent:'center',alignSelf:'center'}}>
              <div style={{display:'flex',alignItems:'center',justifyContent:'center',alignContent:'center',flexDirection:'column'}}>
              <h5 style={{display:'flex',marginRight:5,fontSize:16,color:primarycolor.primarycolor,alignSelf:'center'}}> + {this.state.withdrawpercentage} % </h5>
                <h6 style={{display:'flex',fontSize:16,fontWeight:'bold',alignSelf:'center'}}>{this.state.withdrwdiff}</h6>
                <p style={{display:'flex',fontSize:16,alignSelf:'center'}}>New Withdraws</p>


</div>
               
              </Card>
            </Col>


            <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
            <Card style={{display:'flex',alignItems:'center',justifyContent:'center',alignSelf:'center'}}>
               
            <div style={{display:'flex',alignItems:'center',justifyContent:'center',alignContent:'center',flexDirection:'column'}}>
            <h5 style={{display:'flex',marginRight:5,fontSize:16,color:primarycolor.primarycolor,alignSelf:'center'}}> + {this.state.percenategincomes} % </h5>
                <h6 style={{display:'flex',fontSize:16,fontWeight:'bold',alignSelf:'center'}}> <CurrencyFormat value={this.state.incomediff} displayType={'text'} thousandSeparator={true}  /></h6>
                <p style={{display:'flex',fontSize:16,alignSelf:'center'}}>New Incomes</p>


</div> 
            </Card>

            </Col>
            <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
            <Card style={{display:'flex',alignItems:'center',justifyContent:'center',alignSelf:'center'}}>
            <div style={{display:'flex',alignItems:'center',justifyContent:'center',alignContent:'center',flexDirection:'column'}}>
            <h5 style={{display:'flex',marginRight:5,fontSize:16,color:primarycolor.primarycolor,alignSelf:'center'}}> + {this.state.percenategexpnses} % </h5>
                <h6 style={{display:'flex',fontSize:16,fontWeight:'bold',alignSelf:'center'}}> <CurrencyFormat value={this.state.expensdiff} displayType={'text'} thousandSeparator={true}  /></h6>
                <p style={{display:'flex',fontSize:16,alignSelf:'center'}}>New Expenses</p>


</div>
               
            </Card>
          </Col>

        </Row>



        <br></br>
        <Row>
        <Col xs="12" sm="6" lg="6" style={{padding: '5px'}}>
          <Card style={{display:'flex',alignItems:'center',justifyContent:'center'}}>

          <div style={{display:'flex',alignItems:'flex-end',justifyContent:'flex-end',flexDirection:'row'}}>
            <Select 
              value={this.state.tranquerymode} 
              onChange={(val)=>{this.setState({tranquerymode:val})
              //query data
               this.gettransactiondata(val)
            
            }}
              placeholder="Query Mode" 
              style={{display:'flex',marginRight:3}}
              >
                <Option value='Daily'>Daily</Option>
                <Option value='Weekly'>Weekly</Option>
                <Option value='Monthly'>Monthly</Option>
              </Select>

              {
                this.state.datareload===true?
                <Spin indicator={antIcon} />
                :null
              }

            </div>



          <div style={{display:'flex',alignItems:'center',justifyContent:'center',alignContent:'center',flexDirection:'column'}}>

           <h5 style={{display:'flex',marginRight:5,fontSize:16,color:primarycolor.primarycolor}}> {this.state.tranquerymode} Share amount </h5>
            <h6 style={{display:'flex',fontSize:22,fontWeight:'bold'}}><CurrencyFormat value={this.state.extra_data.total_shares} displayType={'text'} thousandSeparator={true}  /></h6>

            <h5 style={{display:'flex',marginRight:5,fontSize:16,color:primarycolor.primarycolor}}> {this.state.tranquerymode} New members </h5>
            <h6 style={{display:'flex',fontSize:22,fontWeight:'bold'}}>{this.state.extra_data.new_members_total}</h6>

          </div>
          </Card>
        </Col>

        <Col xs="12" sm="6" lg="6" style={{padding: '5px'}}>
          <Card style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
          <div style={{display:'flex',alignItems:'flex-end',justifyContent:'flex-end',flexDirection:'row'}}>
            <Select 
              value={this.state.tranquerymode} 
              onChange={(val)=>{this.setState({tranquerymode:val})
              //query data
               this.gettransactiondata(val)
            
            }}
              placeholder="Query Mode" 
              style={{display:'flex',marginRight:3}}
              >
                <Option value='Daily'>Daily</Option>
                <Option value='Weekly'>Weekly</Option>
                <Option value='Monthly'>Monthly</Option>
              </Select>

              {
                this.state.datareload===true?
                <Spin indicator={antIcon} />
                :null
              }

            </div>
          
          
          <div style={{display:'flex',alignItems:'center',justifyContent:'center',alignContent:'center',flexDirection:'column'}}>
          <h5 style={{display:'flex',marginRight:5,fontSize:16,color:primarycolor.primarycolor}}> {this.state.tranquerymode} Loan issuance</h5>
          <h6 style={{display:'flex',fontSize:22,fontWeight:'bold'}}><CurrencyFormat value={this.state.extra_data.total_loan_issuances} displayType={'text'} thousandSeparator={true}  /></h6>

          <h5 style={{display:'flex',marginRight:5,fontSize:16,color:primarycolor.primarycolor}}> {this.state.tranquerymode} Loan repayments(Principle)</h5>
          <h6 style={{display:'flex',fontSize:22,fontWeight:'bold'}}><CurrencyFormat value={this.state.extra_data.total_loan_repayment_rinciple} displayType={'text'} thousandSeparator={true}  /></h6>

         </div>

          </Card>
        </Col>

      </Row>

        <br></br>



        <Row>
        <Col xs="12" sm="6" lg="4" style={{padding: '5px'}}>
        <Card style={{display:'flex',alignItems:'center',justifyContent:'center',alignSelf:'center'}}>

        <div style={{display:'flex',alignItems:'flex-end',justifyContent:'flex-end',flexDirection:'row'}}>
          <Select 
            value={this.state.graphtranquerymode} 
            onChange={(val)=>{this.setState({graphtranquerymode:val})
            //query data
              this.gettransactionchartdata(val)
          }}
            placeholder="Query Mode" 
            style={{display:'flex',marginRight:3}}
            >
              <Option value='weekly'>Weekly</Option>
              <Option value='monthly'>Monthly</Option>
            </Select>
        </div>

        <h4>Deposits Against Withdraws</h4>
        <Line data={transchartdata} options={transchartoptions} />
        </Card>
        </Col>

        <Col xs="12" sm="6" lg="4" style={{padding: '5px'}}>
        <Card style={{display:'flex',alignItems:'center',justifyContent:'center',alignSelf:'center'}}>

        <div style={{display:'flex',alignItems:'flex-end',justifyContent:'flex-end',flexDirection:'row'}}>
          <Select 
            value={this.state.graphtranquerymode} 
            onChange={(val)=>{this.setState({graphtranquerymode:val})
            //query data
              this.gettransactionchartdata(val)
          }}
            placeholder="Query Mode" 
            style={{display:'flex',marginRight:3}}
            >
              <Option value='weekly'>Weekly</Option>
              <Option value='monthly'>Monthly</Option>
            </Select>
        </div>

        <h4>Incomes Against Expenses</h4>
        <Line data={inc_expchartdata} options={inc_expchartoptions} />
        </Card>
        </Col>


        <Col xs="12" sm="6" lg="4" style={{padding: '5px'}}>
        <Card style={{display:'flex',alignItems:'center',justifyContent:'center',alignSelf:'center'}}>

        <div style={{display:'flex',alignItems:'flex-end',justifyContent:'flex-end',flexDirection:'row'}}>
          <Select 
            value={this.state.loanproccessuerymode} 
            onChange={(val)=>{this.setState({loanproccessuerymode:val})
            //query data
              this.getloanprocesspiedata(val)
          }}
            placeholder="Query Mode" 
            style={{display:'flex',marginRight:3}}
            >
              <Option value='daily'>Today</Option>
              <Option value='weekly'>Current Week</Option>
              <Option value='monthly'>Current Month</Option>
            </Select>
        </div>

        <Pie data={weeklyscaharrdata}
        
        options={{
          maintainAspectRatio:true,
          title:{
            display:true,
            text:`Loan Processes by Number (${this.state.loanproccessuerymode})`,
            fontSize:20
          }
        }}
        
        />
        </Card>
        </Col>
        </Row>

        <br></br>

        <Row>
            <Col xs="12" sm="6" lg="4" style={{padding: '5px'}}>
              <Card style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
              <div style={{display:'flex',alignItems:'center',justifyContent:'center',alignContent:'center',flexDirection:'column'}}>
              <h5> Pending applications</h5>
                  <p style={{display:'flex',fontSize:20,fontWeight:'bolder',alignSelf:'center'}}>{this.state.totalapplications}</p>
                  <h6 style={{display:'flex',fontSize:22,fontWeight:'bold',color:primarycolor.primarycolor}}><CurrencyFormat value={this.state.totalapplicationamounts} displayType={'text'} thousandSeparator={true}  /> &nbsp;</h6>
              </div>
              </Card>
            </Col>

{ /*           <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
              <Card style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
              <div style={{display:'flex',alignItems:'center',justifyContent:'center',alignContent:'center',flexDirection:'column'}}>
              <h5>  Assessments</h5>
              <p style={{display:'flex',fontSize:20,fontWeight:'bolder',alignSelf:'center'}}>{this.state.totalassessments}</p>
                  <h6 style={{display:'flex',fontSize:22,fontWeight:'bold',color:primarycolor.primarycolor}}><CurrencyFormat value={this.state.totalassessmentsamounts} displayType={'text'} thousandSeparator={true}  />  &nbsp;</h6>
             </div>
            </Card>
            </Col> */}

            <Col xs="12" sm="6" lg="4" style={{padding: '5px'}}>
            <Card style={{display:'flex',alignItems:'center',justifyContent:'center',alignContent:'center'}}>
            <div style={{display:'flex',alignItems:'center',justifyContent:'center',alignContent:'center',flexDirection:'column'}}>
            <h5>  Pending issuances</h5>
            <p style={{display:'flex',fontSize:20,fontWeight:'bolder',alignSelf:'center'}}>{this.state.totalapprovals}</p>
                  <h6 style={{display:'flex',fontSize:22,fontWeight:'bold',color:primarycolor.primarycolor}}><CurrencyFormat value={this.state.totalapprovalsamounts} displayType={'text'} thousandSeparator={true}  /> &nbsp;</h6>
            </div>

            </Card>

            </Col>
            <Col xs="12" sm="6" lg="4" style={{padding: '5px'}}>
            <Card style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
           <div style={{display:'flex',alignItems:'center',justifyContent:'center',alignContent:'center',flexDirection:'column'}}>
             <h5>  Disbursement</h5>
            <p style={{display:'flex',fontSize:20,fontWeight:'bolder',alignSelf:'center'}}>{this.state.totaldisbursements}</p>
            <h6 style={{display:'flex',fontSize:22,fontWeight:'bold',color:primarycolor.primarycolor}}><CurrencyFormat value={this.state.totaldisbursementsamounts} displayType={'text'} thousandSeparator={true}  /> &nbsp;</h6>

           </div>
           </Card>
          </Col>
        </Row>


        <br></br>
        <Row>
        <Col xs="12" sm="6" lg="6" style={{padding: '5px'}}>
        <Card style={{display:'flex',alignItems:'center',justifyContent:'center',alignSelf:'center'}}>

        <div style={{display:'flex',alignItems:'flex-end',justifyContent:'flex-end',flexDirection:'row'}}>
          <Select 
            value={this.state.projectedmonths} 
            onChange={(val)=>{this.setState({projectedmonths:val})

            //get graph data
            let form_data3 = new FormData();
            form_data3.append('sacco', sacco);
            form_data3.append('projectedmonths', val);

            //Now send the post resquest
              axios.post(serverconfig.backendserverurl+'/customqueries/getloanprojections', form_data3, {
              headers: {
                'content-type': 'multipart/form-data'
              }
              })
            .then(res => {

              var projdmonths=[]
              var projectedintesrt=[]
              var projectpribncip=[]
        
              JSON.parse(res.data.datalist).map((item)=>{
                projdmonths.push(item.month)
                projectedintesrt.push(item.totalinterest)
                projectpribncip.push(item.totalprinciple)
              })
        
              this.setState({monthsprojected:projdmonths})
              this.setState({projectedinterest:projectedintesrt})
              this.setState({projectedprinciple:projectpribncip})

            })
            .catch(error => console.log(error)) 
            
          }}
            placeholder="Projected Period" 
            style={{display:'flex',marginRight:3}}
            >
              <Option value='three'>Next 3 Months</Option>
              <Option value='six'>Next 6 Months</Option>
              <Option value='nine'>Next 9 Months</Option>
              <Option value='twelve'>Next 12 Months</Option>
            </Select>
         </div>
         <h5>  Loan Interest & Principle Projections</h5>

         <Line data={projectionchartdata} options={inc_expchartoptions} />

        </Card>
        </Col>

        <Col xs="12" sm="6" lg="6" style={{padding: '5px'}}>
        <Card style={{display:'flex',alignItems:'center',justifyContent:'center',alignSelf:'center'}}>
       
        <div style={{display:'flex',alignItems:'flex-end',justifyContent:'flex-end',flexDirection:'row'}}>
          <Select 
            value={this.state.applicvsmode} 
            onChange={(val)=>{this.setState({applicvsmode:val})
            //query data
            let form_data4 = new FormData();
            form_data4.append('sacco', sacco);
            form_data4.append('tranquerymode', val);
        
            //Now send the post resquest
              axios.post(serverconfig.backendserverurl+'/customqueries/getaplicationsvsissuances', form_data4, {
              headers: {
                'content-type': 'multipart/form-data'
              }
              })
            .then(res => {
              var dts=[]
              var isunces=[]
              var aplcs=[]
        
              JSON.parse(res.data.alltransactions).map((item)=>{
                aplcs.push(item.totapplications)
                isunces.push(item.totissuances)
                dts.push(item.date)
              })
        
              this.setState({applicationvsdates:dts})
              this.setState({applicationvs:aplcs})
              this.setState({issuancesvs:isunces})
        
            })
            .catch(error => console.log(error)) 


          }}
            placeholder="Query Mode" 
            style={{display:'flex',marginRight:3}}
            >
              <Option value='weekly'>Weekly</Option>
              <Option value='monthly'>Monthly</Option>
            </Select>
        </div>

        <h5>  Applications Vs Disbursement</h5>
        <Line data={aplic_vs_issdata} options={inc_expchartoptions} />

        </Card>
        </Col>
        </Row>

       </div>




      )

        }else{
          return (<div className="animated fadeIn">  

              <QuickMenu />

             </div>
          );

        }
    }
  
 }

}
}

export default DashBoard; 
