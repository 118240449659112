import React,{ useRef } from 'react';
import axios from 'axios'
import { Table, Input, Button,Collapse,Avatar,Modal,Form,DatePicker,Tooltip,Select,Space,Popconfirm,Typography,Divider,Switch,InputNumber,message,Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { PrinterOutlined, MinusOutlined, CloseCircleFilled,FundViewOutlined, CheckCircleOutlined, UserOutlined } from '@ant-design/icons';
import { SearchOutlined,CameraOutlined,PlusOutlined,PlusCircleFilled,DownloadOutlined,LoadingOutlined,DeleteOutlined } from '@ant-design/icons';
import QrReader from 'react-qr-reader'
import uuid from 'react-uuid'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
import PrintTemplate from 'react-print';
import ReactToPrint from 'react-to-print';
import * as serverconfig from '../serverconn'
import CurrencyFormat from 'react-currency-format';
import * as reactstrp from 'reactstrap';

import {
  Col,
  Row,
} from 'reactstrap';
import ReactExport from "@ibrahimrahmani/react-export-excel";
import '../tablestyle.css'; // Create this CSS file for custom styles

var CryptoJS = require("crypto-js");

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { Title } = Typography;
const { RangePicker } = DatePicker;
const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { Search } = Input;

var sacco= ''
var bizuserid= ''
var token= ''
var username=''


class JounaldetailsView extends React.Component {

  state = {
    users:[],
    sheetitems:[],
    sheetitems_placeholders:[],

    dateone:'',
    datetwo:'',
    entrant:'',
    entrantname:'',
    totaldebit:0,
    totalcredit:0,
    datarequested:false,
    searchbyjno:false,
    journalno:0,

    selected_jounal:'',
    selected_jounal_obj:{},
    jounalform_visible:false,
    journalcredit_amount:0,
    journaldebit_amount:0,
    journal_description:'',
    journal_date:'',
    companyprofile:{},
    searchbymemeber:false,
    members:[],
    selectedaccount:'',
    companyaccounts: [],
    journal_account:'',
    userrights:{},

    show_details:false,
    selected_jnal:''


   };

   componentDidMount(){
    if(localStorage.getItem("sacco")){
      sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
      username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)

   }else{
      sacco= ''
      bizuserid= ''
      username=''
   }
   
   if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/accounts/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          users:res.data
        })
      })


      axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
      .then(res => {  
          this.setState({
            companyprofile: res.data
          })
      })

      axios.get(serverconfig.backendserverurl+`/api/members/?sacco=${sacco}`)
      .then(res => {
  
        //console.log(res.data)
        this.setState({
          members:res.data
        })
  
      })

      axios.get(serverconfig.backendserverurl+`/api/companyaccounts/?sacco=${sacco}`)
      .then(res => {
          this.setState({
              companyaccounts:res.data
          })
      })
  

      axios.get(serverconfig.backendserverurl+`/api/accountrights/?user=${bizuserid}`)
      .then(res => {
          this.setState({
            userrights:res.data[0]
          })
      })


      let form_data = new FormData();
      form_data.append('journalno', this.props.jounal_no);
      form_data.append('sacco', sacco);
        
      //Now submit sale data to database
      axios.post(serverconfig.backendserverurl+'/customqueries/get_jnaldetails', form_data,{
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
      .then(res =>{
        this.setState({datarequested:false})
          this.setState({sheetitems:JSON.parse(res.data.report)})
          this.setState({sheetitems_placeholders:JSON.parse(res.data.report)})
      } 
      )
      .catch(error => console.log(error))   
      


  }
 
   handledatechange= (date, dateString) =>{
     this.setState({ dateone: dateString[0]});
     this.setState({ datetwo: dateString[1]});
   } 

   handleentrantChange= (e) => this.setState({ entrant: e});




   ////////////////////////////////////
  // USER SEARCH SELECT METHODS
  onBlur=()=> {
    console.log('blur');
  }
  
   onFocus=()=>{
    console.log('focus');
  }
  
  onSearch=(val)=> {
    console.log('search:', val);
  }
 
  calculateTotalDebit=()=>{
    var total=0

    this.state.sheetitems.map((item)=>{
      total+=Number(item.Debit)

    })
    return total.toFixed(2);

  }

  calculateTotalCredit=()=>{
    var total=0

    this.state.sheetitems.map((item)=>{
      total+=Number(item.Credit)

    })
    return total.toFixed(2);

  }



   ////////////////////////////////////////////
   // RENDERING METHOD HERE
   render() {
     
       if(this.state.datarequested===true){
        return(
          <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
          <Spin indicator={antIcon} />
          </div>
        )
  
      }else{

        return(
          <div>
             
              <Collapse defaultActiveKey={['1']} onChange={this.callback}>
              <Panel header="JOURNAL DETAILS" key="1">
             
              <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>No</th>
                          <th>Date</th>
                          <th>Account </th>
                          <th>Desc </th>
                          <th>Journal No</th>
                          <th>User</th>
                          <th>Debit</th>
                          <th>Credit</th>
                          <th>Member name</th>

                        </tr>
                      </thead>
                      <tbody>
                      {this.state.sheetitems.map(
                        (item)=>(

                          <React.Fragment key={item}>

                          <tr>

                          <td>{item.item_no}</td>
                          <td>{item.date}</td>
                          <td>{item.account}</td>
                          <td>{item.description}</td>
                          <td>
                            {item.journal_no}
                          </td>
                          <td>{item.user}</td>
                          <td className="align-right">{<CurrencyFormat value={item.Debit} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td className="align-right">{<CurrencyFormat value={item.Credit} displayType={'text'} thousandSeparator={true}/>}</td>
                          
                          <td>{item.member_name}</td>
                          </tr>

                          </React.Fragment>

                        ))}
                        <tr>
                        <td style={{fontWeight:'bolder'}}>Total</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>

                        <td></td>
                        <td className="align-right"style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalDebit()} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td className="align-right"style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalCredit()} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td></td>

                        </tr>

                    </tbody>
                </reactstrp.Table> 

              </Panel>
              </Collapse>

          </div>
      ) }

   }
}

export default JounaldetailsView; 
