import React from 'react';
import { Form, Input, Button,Select,InputNumber,DatePicker,message,Card,Spin,Result,Switch } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import moment from 'moment';
import PhoneInput from 'react-phone-number-input'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';
import CurrencyFormat from 'react-currency-format';

import {
  Col,
  Row,
} from 'reactstrap';
var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const { TextArea } = Input;
const dateFormat = 'DD/MM/YYYY';
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var sacco= ''
var username=''
var token= ''


class ApproveMemberForm extends React.Component {
  state = {
    memberid:'',  
    approvalmode:'',
    datarequested:true,
    datasubmittedsuccessfully:false,
    member: {},
    companyaccounts: [],
    debitaccount:'',
    transfertonewaccount:false,
    reasonforrejection:'',
    AccountNumber:'',
    companyprofile:{},
    reasonfor_review:'',


  }

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    const memberid=this.props.memberid;
    this.setState({memberid:memberid})

    axios.get(`${serverconfig.backendserverurl}/api/members/${memberid}`)
    .then(res => {  
        this.setState({member: res.data})
    })

    axios.get(serverconfig.backendserverurl+`/api/companyaccounts/?sacco=${sacco}`)
    .then(res => {
        this.setState({
            companyaccounts:res.data
        })
        this.setState({datarequested:false})
    })

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })

  }


  //submit button pressed
  onFinish=(event) =>{
    this.setState({datarequested:true})

    let form_data = new FormData();
    form_data.append('memberid', this.state.memberid);
    form_data.append('approvalmode', this.state.approvalmode);
    form_data.append('sacco', sacco);
    form_data.append('username', username);
    form_data.append('reasonforrejection', this.state.reasonforrejection);
    form_data.append('debitaccount', this.state.debitaccount);
    form_data.append('transfertonewaccount', this.state.transfertonewaccount);
    form_data.append('reasonfor_review', this.state.reasonfor_review);
    form_data.append('AccountNumber', this.state.AccountNumber);

    ///make a post request now
      axios.post(serverconfig.backendserverurl+'/customqueries/createmembershipapproval', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
    .then(res => {
      message.info(res.data.message)
      this.setState({datarequested:false})
      if (res.data.message==="success"){
        this.setState({datasubmittedsuccessfully:true})
      }
    })
    .catch(error => console.log(error))

    }


    handleapprovalmodeChange= (e) => {
       this.setState({approvalmode:e})
    }


  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )
    }else{

      if (this.state.datasubmittedsuccessfully===true){
        return(
            <Card>
            <Result
            status="success"
            title="Successfully Approved Member "
            subTitle="Account fully approved now"
            extra={[
              <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
            ]}
            />
            </Card>
        );
        
      }else{

        return (
          <div>
            <Card>
    
            <Form
                  name="activationform"
                  onFinish={(event) => this.onFinish(event)}
              >
                <h4>Membership Fee Paid: <CurrencyFormat value={this.state.member.paid_membershipfee} displayType={'text'} thousandSeparator={true}  /></h4>
                <h4>Payment ReceiptNo / JournalNo: {this.state.member.membershippayt_receipt} </h4>
                <h4>Membership Fee Receving Account: {this.state.member.membershiprec_account} </h4>

                <FormItem 
                    label=" Approval Mode"
                    name="approvalmode"
                    rules={[
                      {
                        required: true,
                        message: 'Please select approval mode',
                      },
                    ]}
                    >
                    <Select 
                    value={this.state.approvalmode} 
                    onChange={this.handleapprovalmodeChange}
                    placeholder="Approval Mode" >
                          <Option value='accept'>Approve</Option>
                          <Option value='reject'>Reject</Option>
                          <Option value='forreview'>Should be reviewed</Option>
                    </Select>
                </FormItem>

                  {
                    this.state.approvalmode==="accept"?
                    this.state.companyprofile.automated_accounts===true?
                    null
                    :
                    <FormItem label="Account Number"
                    name="AccountNumber"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter account number',
                      },
                    ]}           
                  >
                    <Input name="AccountNumber"  placeholder="Account Number" value={this.state.AccountNumber} onChange={(val)=>{this.setState({AccountNumber:val.target.value})}} />
                  </FormItem>
                    :
                    null
                  }

                {
                  this.state.approvalmode!="forreview"?
                  <FormItem 
                  label="Transfer to new account">
                <Switch
                    checked={this.state.transfertonewaccount}
                    onChange={val=>{this.setState({transfertonewaccount:val})}}
                    checkedChildren="True"
                    unCheckedChildren="False"
                    />
                </FormItem>
                  :
                  null

                }



                  {
                    this.state.approvalmode==="reject"?

                    <FormItem label="Reason for rejection"
                    name="reason"
                    rules={[
                      {
                        required: true,
                        message: 'Please input reason',
                      },
                    ]}
                    
                    >
                        <TextArea
                          placeholder="Reason for rejection"
                          autoSize={{ minRows: 2, maxRows: 6 }}
                          value={this.state.reasonforrejection} 
                          onChange={(val)=>{this.setState({reasonforrejection:val.target.value})}}
                        />
                    </FormItem>
                    :
                    null
                  }

                {
                this.state.transfertonewaccount===true && this.state.approvalmode!="forreview"?
                <div>
                <h4>Transfer Membership fees to new Account</h4>
                <FormItem label={<h4 style={{fontWeight:'bold'}}>Receiving (Debit A/C).</h4>}
                  name="debitaccount"
                  rules={[
                    {
                      required: true,
                      message: 'Please select account',
                    },
                  ]}
                >
                      <Select 
                      placeholder="Account" 
                      style={{  }} 
                      value={this.state.debitaccount}
                      onChange={(val)=>{this.setState({debitaccount:val})}} 
                      showSearch
                      optionFilterProp="children"
                      onFocus={this.onFocus}
                      onBlur={this.onBlur}
                      onSearch={this.onSearch}                    
                      >
                       <Option value={''}>-----------------------</Option>
                        {this.state.companyaccounts.map(
                          (accnt)=>(
                            <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
                          ))}
                      </Select>
                   </FormItem>
                    </div>
                    :
                    null
                  }

                  {
                    this.state.approvalmode==="forreview"?

                    <FormItem label="Reason for Review"
                    name="reason"
                    rules={[
                      {
                        required: true,
                        message: 'Please input reason',
                      },
                    ]}
                    
                    >
                        <TextArea
                          placeholder="Reason for review"
                          autoSize={{ minRows: 2, maxRows: 6 }}
                          value={this.state.reasonfor_review} 
                          onChange={(val)=>{this.setState({reasonfor_review:val.target.value})}}
                        />
                    </FormItem>
                    :
                    null
                  }



                <FormItem>
                  <Button  type="primary" htmlType="submit">Submit</Button>
                </FormItem>
                </Form>
            </Card>

          </div>
        );

      }
    }
  }

}

export default ApproveMemberForm;
